import React, { Component } from 'react'
import Loader from '../UI/Loader'
import tools from '../../tools'
import M from 'materialize-css'

class ImportKoban extends Component {
	constructor(props) {
		super(props)
		this.state = {
			attempt_submit: false,
			mode: null,
			confirm: false,
			form1: {
				client: "",
				firstname: "",
				lastname: "",
				arrival: "",
				departure: "",
				email: "",
				phone: "",
				file: null
			},
			success: null,
			error: this._initErrors()
		}
		console.log("clients", this.props.clients)
	}

	componentDidUpdate(prevProps, prevState) {
		const elems = document.querySelectorAll('select')
		M.FormSelect.init(elems)
	}

	_initErrors = () => {
		const errors = {
			global: null,
			client: null,
			firstname: null,
			lastname: null,
			arrival: null,
			departure: null,
			email: null,
			phone: null,
			file: null
		}
		return errors
	}

	setErrorMsg = (key, msg, id = null) => {
		if (key === "error_import_file")
		{
			key = "global"
			msg = tools.default_error_msg("Impossible d'importer un fichier csv", msg)
		}
		let error = this._initErrors()
		if (id !== null)
			error[key][id] = msg
		else
			error[key] = msg
		this.setState({ success: null, error: error })
	}

	setSuccessMsg = (msg) => {
		this.setState({ error: this._initErrors(), success: msg })
	}

	clearFlash = () => {
		this.setState({ error: this._initErrors(), success: null })
	}

	handleClient = (event) => {
		const form1 = {
			...this.state.form1,
			client: event.target.value
		}
		this.setState({ form1: form1 })
	}

	handleMode = (mode) => {
		this.setState({ mode: mode })
	}

	roomlistFirstname = (event) => {
		const form1 = {
			...this.state.form1,
			firstname: event.target.value
		}
		this.setState({ form1: form1 })
	}

	roomlistLastname = (event) => {
		const form1 = {
			...this.state.form1,
			lastname: event.target.value
		}
		this.setState({ form1: form1 })
	}

	roomlistEmail = (event) => {
		const form1 = {
			...this.state.form1,
			email: event.target.value
		}
		this.setState({ form1: form1 })
	}

	roomlistPhone = (event) => {
		const form1 = {
			...this.state.form1,
			phone: event.target.value
		}
		this.setState({ form1: form1 })
	}

	roomlistArrival = (event) => {
		const form1 = {
			...this.state.form1,
			arrival: event.target.value
		}
		this.setState({ form1: form1 })
	}

	roomlistDeparture = (event) => {
		const form1 = {
			...this.state.form1,
			departure: event.target.value
		}
		this.setState({ form1: form1 })
	}

	handleFile = (event) => {
		const reader = new FileReader()
		const file = event.target.files[0]
		reader.addEventListener("load", () => {
			const form1 = {
				...this.state.form1,
				file: reader.result
			}
			this.setState({ form1: form1 })
		}, false)
		if (file)
			reader.readAsText(file)
	}

	_validateForm1 = () => {
		let error_obj = this._initErrors()
		let error = false
		let mode = this.state.mode
		const client = this.state.form1.client
		const firstname = this.state.form1.firstname
		const lastname = this.state.form1.lastname
		const email = this.state.form1.email
		const arrival = this.state.form1.arrival
		const departure = this.state.form1.departure
		const phone = this.state.form1.phone
		const file = this.state.form1.file
		if (mode === "edit")
		{
			if (!client || !client.length)
			{
				error_obj.client = "Veuillez sélectionner un client"
				error = true
			}
			if (!arrival || !arrival.length)
			{
				error_obj.arrival = "Veuillez saisir une date d'arrivée"
				error = true
			}
			else if (!/^[0-9]{1,2}\/[0-9]{1,2}\/[0-9]{2,4}$/.test(arrival))
			{
				error_obj.arrival = "Date d'arrivée mal formatée : dd/mm/yyyy"
				error = true
			}
			if (!departure || !departure.length)
			{
				error_obj.departure = "Veuillez saisir une date de départ"
				error = true
			}
			else if (!/^[0-9]{1,2}\/[0-9]{1,2}\/[0-9]{2,4}$/.test(departure))
			{
				error_obj.departure = "Date d'arrivée mal formatée : dd/mm/yyyy"
				error = true
			}
		}
		else
		{
			if (!file)
			{
				error_obj.file = "Veuillez importer un fichier CSV"
				error = true
			}
			if (!lastname || !lastname.length)
			{
				error_obj.lastname = 'Veuillez renseigner un nom'
				error = true
			}
			else if (/[@./\\[\]#'"%*&!?;:{}0-9_]/.test(lastname))
			{
				error_obj.lastname = 'Ce nom n\'est pas valide, veuillez réessayer'
				error = true
			}
			else if (lastname.length > this.length_max)
			{
				error_obj.lastname = 'Le nom dépasse le nombre de charactère maximum (' + this.length_max + ' caractères max.)'
				error = true
			}
			if (!firstname || !firstname.length)
			{
				error_obj.firstname = 'Veuillez saisir un prénom'
				error = true
			}
			else if (/[@./\\[\]#'"%*&!?;:{}0-9_]/.test(firstname))
			{
				error_obj.firstname = 'Ce prénom n\'est pas valide, veuillez réessayer'
				error = true
			}
			else if (!firstname || firstname.length < 2)
			{
				error_obj.firstname = 'Un peu court pour un prénom !'
				error = true
			}
			else if (firstname.length > this.length_max)
			{
				error_obj.firstname = 'Ce prénom dépasse le nombre de charactère maximum (' + this.length_max + ' caractères max.)'
				error = true
			}
			if (!email || !email.length)
			{
				error_obj.email = "Veuillez saisir une adresse e-mail"
				error = true
			}
			else if (email.length > this.length_max)
			{
				error_obj.email = 'Cette adresse e-mail est trop longue (' + this.length_max + ' caractères max.)'
				error = true
			}
			else if (/^[\w!#$%&'*+-/=?^_`{|}~]+(\.[\w!#$%&'*+-/=?^_`{|}~]+)*@((([-\w]+\.)+[a-zA-Z]{2,4})|(([0-9]{1,3}\.){3}[0-9]{1,3}))$/.test(email) === false)
			{
				error_obj.email = 'Cette adresse e-mail semble incorrecte'
				error = true
			}
			if (!phone || !phone.length)
			{
				error_obj.phone = "Veuillez saisir un numéro de téléphone"
				error = true
			}
			else if (phone.length > 0 && /[@/\\[\]#'"%*&!?;:{}a-zA-Z_]/.test(phone))
			{
				error_obj.phone = 'Ce numéro de téléphone n\'est pas valide, veuillez réessayer'
				error = true
			}
			else if (phone.length > 0 && phone.length < 9)
			{
				error_obj.phone = "Ce numéro de téléphone ne semble pas valide"
				error = true
			}
			else if (phone.length > this.length_max)
			{
				error_obj.phone = 'Le numéro de téléphone dépasse le nombre de charactère maximum (' + this.length_max + ' caractères max.)'
				error = true
			}
			if (!arrival || !arrival.length)
			{
				error_obj.arrival = "Veuillez saisir une date d'arrivée"
				error = true
			}
			else if (!/^[0-9]{1,2}\/[0-9]{1,2}\/[0-9]{2,4}$/.test(arrival))
			{
				error_obj.arrival = "Date d'arrivée mal formatée : dd/mm/yyyy"
				error = true
			}
			if (!departure || !departure.length)
			{
				error_obj.departure = "Veuillez saisir une date de départ"
				error = true
			}
			else if (!/^[0-9]{1,2}\/[0-9]{1,2}\/[0-9]{2,4}$/.test(departure))
			{
				error_obj.departure = "Date d'arrivée mal formatée : dd/mm/yyyy"
				error = true
			}
		}
		if (error === true)
		{
			error_obj.global = "Il y a une ou plusieurs erreur(s) dans ce formulaire"
			this.setState({ success: null, error: error_obj })
		}
		return (!error) ? true : false
	}

	submitRoomlist = (event) => {
		event.preventDefault()
		if (this._validateForm1())
		{
			const csv = tools.parseCSV(this.state.form1.file, ',')
			if (!isNaN(csv))
			{
				const error_obj = this._initErrors()
				error_obj.file = "Erreur de parsing dans le fichier CSV à la ligne " + csv
				error_obj.global = "Il y a une ou plusieurs erreur(s) dans ce formulaire"
				this.setState({ error: error_obj })
			}
			else
			{
				const roomlist = {
					client: this.state.form1.client,
					firstname: this.state.form1.firstname,
					lastname: this.state.form1.lastname,
					email: this.state.form1.email,
					arrival: this.state.form1.arrival,
					departure: this.state.form1.departure,
					phone: this.state.form1.phone,
					file: csv
				}
				this.props.insertRoomlist(roomlist)
				.then(ret => {
					if (ret !== false)
						this.setSuccessMsg("La roomlist a bien été importée !")
					else
						this.setErrorMsg("error_import_file", 2)
				})
				.catch(err => {
					this.setErrorMsg("error_import_file", 1)
				})
			}
		}
	}
	
	confirmAction = (event) => {
		event.preventDefault()
		const form1 = {
			client: "",
			firstname: "",
			lastname: "",
			arrival: "",
			departure: "",
			email: "",
			phone: "",
			file: null
		}
		if (this.state.mode === "edit" || this.state.mode === "insert")
			this.setState({ confirm: true, errors: this._initErrors(), form1: form1 })
		else
			this.setState({ mode: null, confirm: false })
	}

	cancelAction = () => {
		this.setState({ confirm: false, error: this._initErrors() })
	}

	render() {
		let sendbtn = (<button type="submit" className="waves-effect waves-light btn-small">OK</button>)
		// let prevbtn = (<button type="button" onClick={this.cancelAction} className="waves-effect waves-light btn-small">Précédent</button>)
		if (this.state.attempt_submit === true)
		{
			sendbtn = (
				<div className="center">
					<Loader />
				</div>
			)
			// prevbtn = null
		}
		const edit = (this.state.mode === "edit") ? true : false
		const insert = (this.state.mode === "insert") ? true : false
		if (this.state.mode === null || this.state.confirm === false)
		{
			return (
				<div className="module col s12">
					<h2 className="title-description">Import Koban</h2>
					<form className="module-form" onSubmit={this.confirmAction}>
						<label>
							<input type="radio" className="with-gap" name="type" value="insert" checked={insert} onChange={this.handleMode.bind(this, "insert")} /> <span>Insérer une roomlist</span>
						</label>
						<label>
							<input type="radio" className="with-gap" name="type" value="edit" checked={edit} onChange={this.handleMode.bind(this, "edit")} /> <span>Mettre à jour une roomlist</span>
						</label>
						{sendbtn}
					</form>
				</div>
			)
		}
		let client_list = null
		let title = "Importer une roomlist"
		if (this.state.mode === "edit")
		{
			title = "Mettre à jour une roomlist"
			client_list = (
				<label>
					<p className="column-20">Client :</p>
					<select value={this.state.form1.client} onChange={this.handleClient}>
						<option value="">-- Sélectionez un client --</option>
						{this.props.clients.map(({ client_number, firstname, lastname, email1 }) => {
							return (
								<option value={client_number} key={client_number}>{firstname} {lastname} ({email1})</option>
							)
						})}
					</select>
				</label>
			)
		}
		return (
			<div className="module col s12">
				<h2 className="title-description">{title}</h2>
				<form className="module-form" onSubmit={this.submitRoomlist}>
					<p className="error">{this.state.error.client}</p>
					{client_list}
					<label className="short-label">
						<p className="column-20">Prénom :</p>
						<input type="text" value={this.state.form1.firstname} onChange={this.roomlistFirstname} />
						<p className="error">{this.state.error.firstname}</p>
					</label>
					<label className="short-label">
						<p className="column-20">Nom :</p>
						<input type="text" value={this.state.form1.lastname} onChange={this.roomlistLastname} />
						<p className="error">{this.state.error.lastname}</p>
					</label>
					<label className="short-label">
						<p className="column-20">E-mail :</p>
						<input type="text" value={this.state.form1.email} onChange={this.roomlistEmail} />
						<p className="error">{this.state.error.email}</p>
					</label>
					<label className="short-label">
						<p className="column-20">Téléphone :</p>
						<input type="text" value={this.state.form1.phone} onChange={this.roomlistPhone} />
						<p className="error">{this.state.error.phone}</p>
					</label>
					<label className="short-label">
						<p className="column-20">Date d'arrivée :</p>
						<input type="text" value={this.state.form1.arrival} onChange={this.roomlistArrival} />
						<p className="error">{this.state.error.arrival}</p>
					</label>
					<label className="short-label">
						<p className="column-20">Date de départ :</p>
						<input type="text" value={this.state.form1.departure} onChange={this.roomlistDeparture} />
						<p className="error">{this.state.error.departure}</p>
					</label>
					<label>
						<p className="column-20">Fichier :</p>
						<input type="file" onChange={this.handleFile} />
						<p className="error">{this.state.error.file}</p>
					</label>
					<p className="error">{this.state.error.global}</p>
					<p className="success">{this.state.success}</p>
					{sendbtn}
					{/* {prevbtn} */}
				</form>
			</div>
		)
	}
}

export default ImportKoban

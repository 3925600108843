import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import connexion from '../../connexion'
import { parseDate } from '../../tools'
import Loader from '../UI/Loader'
import user from '../../images/user.png'
import tent from '../../images/tent.png'
import '../Core/Main.css'

class Clients extends Component {
	constructor(props) {
		super(props)
		this.search = (this.props.location.clients !== undefined) ? true : false
		this.state = {
			client: null,
			id: (this.props.match.params.id !== undefined) ? this.props.match.params.id : null,
			clients: (this.props.location.clients !== undefined) ? this.props.location.clients : null
		}
		this.props.updateNavbarTitle('Fiches clients')
	}

	componentDidMount() {
		if (this.state.clients === null)
			this.getClientList()
		this.getClient(this.state.id)
	}

	getClientList = () => {
		fetch(connexion.connect.url + 'dashboard/clients/list')
			.then(res => res.json())
			.then(clients => {
				this.setState({ clients: clients })
			})
			.catch(err => console.log(err))
	}

	getClient = (id) => {
		if (id !== null)
		{
			fetch(connexion.connect.url + 'dashboard/clients/find', {
				method: 'POST',
				body: JSON.stringify({
					client_number: id
				})
			})
			.then(res => res.json())
			.then(client => {
				fetch(connexion.connect.url + 'webapp/users/find-by-client', {
					method: 'POST',
					body: JSON.stringify({
						client_number: id
					})
				})
					.then(res => res.json())
					.then(users => {
						client.users = users
						fetch(connexion.connect.url + 'dashboard/stays/find-by-client', {
							method: 'POST',
							body: JSON.stringify({
								client_number: id
							})
						})
							.then(res => res.json())
							.then(stays => {
								client.stays = stays
								this.setState({ client: client })
							})
					})
					.catch(err => console.log(err))
			})
			.catch(err => console.log(err))
		}
	}

	handleLink = (id) => {
		this.getClient(id)
		this.setState({ id: id })
	}

	render() {
		const id = this.state.id
		const client = this.state.client
		const clients = this.state.clients
		if ((id !== null && client === null) || (id === null && clients === null))
		{
			return (
				<div className="loader">
					<Loader />
				</div>
			)
		}
		else if (id !== null)
		{
			const civility = (client.civility === null) ? (<i>Non renseigné</i>) : client.civility
			const birthday = (client.birthday === null) ? (<i>Non renseigné</i>) : client.birthday
			const email1 = (client.email1 === null) ? (<i>Non renseigné</i>) : client.email1
			const email2 = (client.email2 === null) ? null : (<span><b>E-mail 2 :</b> {client.email2}<br /></span>)
			const phone1 = (client.phone1 === null) ? (<i>Non renseigné</i>) : client.phone1
			const phone2 = (client.phone2 === null) ? null : (<span><b>Téléphone 2 :</b> {client.phone2}<br /></span>)
			const phone3 = (client.phone3 === null) ? null : (<span><b>Téléphone 3 :</b> {client.phone3}<br /></span>)
			const create = parseDate(client.created_time)
			let edit = (client.edited_time !== null) ? parseDate(client.edited_time) : null
			let clientJSX = (
				<p>
					<b>N° Client :</b> {client.client_number}<br />
					<b>Nom :</b> {client.lastname}<br />
					<b>Prénom :</b> {client.firstname}<br />
					<b>Civilité :</b> {civility}<br />
					<b>Date de naissance :</b> {birthday}<br />
					<b>Langue :</b> {client.langage}<br />
					<b>E-mail 1 :</b> {email1}<br />
					{email2}
					<b>Téléphone 1 :</b> {phone1}<br />
					{phone2}
					{phone3}
					<b>Code d'activation :</b> {client.activation_code}<br />
					<b>Date de création :</b> {create}<br />
					<b>Date de modification :</b> {edit}
				</p>
			)
			let users_k = -1
			let stays_k = -1
			client.users.sort((a, b) => {
				return a.created_time - b.created_time
			})
			client.stays.sort((a, b) => {
				return a.stay_number - b.stay_number
			})
			client.stays.sort((a, b) => {
				return a.arrival_time - b.arrival_time
			})
			return (
				<div className="wrapper-content">
					<p><button type="button" className="waves-effect waves-light btn-small" onClick={this.handleLink.bind(this, null)}>Retour à la liste</button></p>
					<div className="row">
						<div className="module col s4 quickview">
							<p className="text">
								Identité<br />
								<strong>
									{client.firstname}<br />
									{client.lastname}
								</strong>
							</p>
							<p className="icon"><img src={user} alt="Identité" /></p>
						</div>
						<div className="module col s4 quickview">
							<p className="text">
								Code d'activation<br />
								<strong>{client.activation_code}</strong><br />
								&nbsp;
							</p>
							<p className="icon"><img src={tent} alt="Code d'activation" /></p>
						</div>
					</div>
					<div className="row">
						<div className="module col s4">
							<h2 className="title-description">Détails</h2>
							{clientJSX}
						</div>
						<div className="module col s4">
							<h2 className="title-description">Utilisateurs</h2>
							{client.users.map(({ _id, activate, birthday, civility, lastname, firstname, email, stay }) => {
								users_k++
								const path = '/users/webapp/' + _id
								const active = (activate === true) ? 'Oui' : 'Non'
								const birthdayJSX = (birthday !== null) ? (<span><b>Date de naissance :</b> {birthday}<br /></span>) : null
								if (civility === "homme")
									civility = "Monsieur"
								else if (civility === "femme")
									civility = "Madame"
								else if (civility === "other")
									civility = "Autre"
								const civilityJSX = (civility !== null) ? (<span><b>Civilité :</b> {civility}<br /></span>) : null
								let stayJSX = null
								if (stay !== null)
								{
									const stay_path = '/users/sejours/' + stay.stay_number
									stayJSX = (<span><br /><b>Séjour :</b> <Link to={stay_path}>{stay.stay_number}</Link></span>)
								}
								return (
									<p key={users_k}>
										<b>Nom complet :</b> <Link to={path}>{firstname} {lastname}</Link><br />
										{civilityJSX}
										{birthdayJSX}
										<b>E-mail :</b> {email}<br />
										<b>Compte activé :</b> {active}
										{stayJSX}
									</p>
								)
							})}
						</div>
						<div className="module col s4">
							<h2 className="title-description">Séjours</h2>
							{client.stays.map(({ stay_number, location, arrival, departure, }) => {
								stays_k++
								const path = '/users/sejours/' + stay_number
								return (
									<p key={stays_k}>
										<b>N° Séjour :</b> <Link to={path}>{stay_number}</Link><br />
										<b>Emplacement :</b> {location}<br />
										<b>Date d'arrivée :</b> {arrival}<br />
										<b>Date de départ :</b> {departure}
									</p>
								)
							})}
						</div>
					</div>
				</div>
			)
		}
		else
		{
			clients.sort((a, b) => {
				return a.client_number - b.client_number
			})
			let key = -1
			const search = (this.search === true) ? 'Résultats de votre recherche' : 'Liste de toutes les fiches clients'
			return (
				<div className="wrapper-content">
					<div className="row">
						<div className="module col s12">
							<h2 className="title-description">{search}</h2>
							<p>Nombre de lignes : {clients.length}</p>
							<table>
								<thead>
									<tr>
										<td>N° Client</td>
										<td>Civilité</td>
										<td>Nom</td>
										<td>Prénom</td>
										<td>Date de naissance</td>
										<td>E-mail 1</td>
										<td>E-mail 2</td>
										<td>Tél. 1</td>
										<td>Tél. 2</td>
										<td>Tél. 3</td>
										<td>Langue</td>
										<td>Code</td>
										<td>Création</td>
										<td>Édition</td>
									</tr>
								</thead>
								<tbody>
									{clients.map(({ _id, activation_code, birthday, civility, client_number, created_by, created_time, edited_by, edited_time, email1, email2, firstname, langage, lastname, phone1, phone2, phone3 }) => {
										let create = parseDate(created_time)
										let edited = parseDate(edited_time)
										key++
										return (
											<tr key={key}>
												<td><button type="button" className="waves-effect waves-light btn-small" onClick={this.handleLink.bind(this, client_number)}>{ client_number }</button></td>
												<td>{ civility }</td>
												<td>{ lastname }</td>
												<td>{ firstname }</td>
												<td>{ birthday }</td>
												<td>{ email1 }</td>
												<td>{ email2 }</td>
												<td>{ phone1 }</td>
												<td>{ phone2 }</td>
												<td>{ phone3 }</td>
												<td>{ langage }</td>
												<td>{ activation_code }</td>
												<td>{ create }</td>
												<td>{ edited }</td>
											</tr>
										)
									})}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			)
		}
	}
}

export default Clients
import React, { Component } from 'react'
import Cookies from 'js-cookie'
import SidebarTailwind from '../UI/SidebarTailwind'
import Signin from '../Home/Signin'
import connexion from '../../connexion'
import Loader from '../UI/Loader'
import 'tailwindcss/tailwind.css'
import 'materialize-css/dist/css/materialize.min.css'
import './App.css'
import Main from './Main'
import tools from '../../tools'

class App extends Component {
	constructor(props) {
		super(props)
		this.state = {
			// config
			background_img: null,
			emails_nbdays: null,
			emails_activate: null,
			establishment: null,
			features: null,
			langs: null,
			lang_default: null,
			webapp_connexion: null,
			config_deposit: null,
			config_inventory: null,
			config_pms: null,
			welcome_txt: [],

			// objects from database
			// these states parameters are managed via fetch, insert, update and delete methods
			accommodations: null,
			animations: null,
			animation_dates: null,
			animation_categories: null,
			categories: null,
			clients: null,
			conversations: null,
			cron_notifications: null,
			dashboard_config: null,
			dashboard_users: null,
			deposits: null,
			emails: null,
			files: null,
			groups: null,
			icons: null,
			inventories: null,
			lists: null,
			menu: null,
			modules: null,
			messages: null,
			notifications: null,
			pages: null,
			review_models: null,
			reviews: null,
			service_benefits: null,
			service_products: null,
			service_workers: null,
			service_reservations: null,
			services: null,
			subscriptions: null,
			theme: null,
			webapp_config: null,
			webapp_users: null,

			// others
			user: null,
			navbar: null,
			session: undefined
		}
	}

	componentDidMount() {
        // M.AutoInit()
		this.fetchCookie()
		this.fetchConfig()
	}

	/*
	** FETCH METHODS
	*/

	fetchAccommodations = () => {
		console.log("fetch accommodations")
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'dashboard/accommodations/list')
			.then(res => res.json())
			.then(accommodations => {
				accommodations.sort((a, b) => {
					return a.name.localeCompare(b.name)
				})
				this.setState({ accommodations: accommodations })
				resolve(accommodations)
			})
			.catch(err => {
				console.log(err)
				reject(err)
			})
		})
	}

	fetchAccommodationByID = (id) => {
		console.log('fetch accommodation by id', id)
		return new Promise((resolve, reject) => {
			const requrl = tools.queryString(connexion.connect.url + 'dashboard/accommodations/find', {
				id: id
			})
			fetch(requrl)
			.then(res => res.json())
			.then(ret => {
				if (ret)
				{
					let accommodations = (this.state.accommodations) ? this.state.accommodations : []
					let found = false
					let i = 0
					while (i < accommodations.length)
					{
						if (accommodations[i]._id === ret._id)
						{
							found = true
							break
						}
						i++
					}
					if (!found)
						accommodations.push(ret)
					else
						accommodations[i] = ret
					this.setState({ accommodations: accommodations })
				}
				resolve(ret)
			})
			.catch(err => {
				console.log(err)
				reject(err)
			})
		})
	}

	fetchAnimations = (nocover = false) => {
		console.log("fetch animations")
		return new Promise((resolve, reject) => {
			let params = {}
			if (nocover !== false)
				params.nocover = 1
			const requrl = tools.queryString(connexion.connect.url + 'webapp/animations/list', params)
			fetch(requrl)
			.then(res => res.json())
			.then(animations => {
				animations.sort((a, b) => {
					let name_a = null
					let name_b = null
					for (let i = 0; i < a.translations.length; i++)
					{
						if (a.translations[i].lang === this.state.lang_default)
						{
							name_a = a.translations[i].name
							break
						}
					}
					for (let i = 0; i < b.translations.length; i++)
					{
						if (b.translations[i].lang === this.state.lang_default)
						{
							name_b = b.translations[i].name
							break
						}
					}
					if (!name_a && !name_b)
						return 0
					else if (!name_a)
						return 1
					else if (!name_b)
						return -1
					else
						return name_a.localeCompare(name_b)
				})
				this.setState({ animations: animations })
				resolve(animations)
			})
			.catch(err => {
				console.log(err)
				reject(err)
			})
		})
	}

	fetchAnimationByID = (id) => {
		console.log('fetch animation by id', id)
		return new Promise((resolve, reject) => {
			const requrl = tools.queryString(connexion.connect.url + 'webapp/animations/find', {
				id: id
			})
			fetch(requrl)
			.then(res => res.json())
			.then(ret => {
				if (ret)
				{
					let animations = (this.state.animations) ? this.state.animations : []
					let found = false
					let i = 0
					while (i < animations.length)
					{
						if (animations[i]._id === ret._id)
						{
							found = true
							break
						}
						i++
					}
					if (!found)
						animations.push(ret.date)
					else
						animations[i] = ret
					this.setState({ animations: animations })
				}
				resolve(ret)
			})
			.catch(err => {
				console.log(err)
				reject(err)
			})
		})
	}

	fetchAnimationDates = () => {
		console.log("fetch animation dates")
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'webapp/animation-dates/list')
			.then(res => res.json())
			.then(dates => {
				dates.sort((a, b) => {
					return a.start - b.start
				})
				this.setState({ animation_dates: dates })
				resolve(dates)
			})
			.catch(err => {
				console.log(err)
				reject(err)
			})
		})
	}

	fetchAnimationCategories = () => {
		console.log("fetch animation categories")
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'webapp/animation-categories/list')
			.then(res => res.json())
			.then(categories => {
				this.setState({ animation_categories: categories })
				resolve(categories)
			})
			.catch(err => {
				console.log(err)
				reject(err)
			})
		})
	}

	fetchCategories = () => {
		console.log("fetch categories")
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'webapp/categories/list')
			.then(res => res.json())
			.then(categories => {
				this.setState({ categories: categories })
				resolve(categories)
			})
			.catch(err => {
				console.log(err)
				reject(err)
			})
		})
	}

	promiseCategoriesByGroup = {}

	fetchCategoriesByGroup = (group) => {
		if (Object.keys(this.promiseCategoriesByGroup).indexOf(group) !== -1)
			return this.promiseCategoriesByGroup[group]
		else
		{
			const requrl = tools.queryString(connexion.connect.url + 'webapp/categories/find-by-group', {
				group: group
			})
			console.log("fetch categories by group", group)
			this.promiseCategoriesByGroup[group] = new Promise((resolve, reject) => {
				fetch(requrl)
				.then(res => res.json())
				.then(cats => {
					let categories = (this.state.categories) ? this.state.categories : []
					for (let i = 0; i < cats.length; i++)
					{
						let j = 0
						let found = false
						while (j < categories.length)
						{
							if (cats[i]._id === categories[j]._id)
							{
								found = true
								break
							}
							j++
						}
						if (!found)
							categories.push(cats[i])
						else
							categories[j] = cats[i]
					}
					categories.sort((a, b) => {
						return a.order - b.order
					})
					this.setState({ categories: categories })
					resolve(categories)
				})
				.catch(err => {
					reject(err)
					console.log(err)
				})
			})
			const ret = this.promiseCategoriesByGroup[group]
			delete this.promiseCategoriesByGroup[group]
			return ret
		}
	}

	fetchClients = () => {
		console.log("fetch clients")
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'dashboard/clients/list')
			.then(res => res.json())
			.then(clients => {
				this.setState({ clients: clients })
				resolve(clients)
			})
			.catch(err => {
				reject(err)
				console.log(err)
			})
		})
	}

	fetchCookie = () => {
		const session = Cookies.get('session-dashboard')
		if (session !== undefined)
		{
			const requrl = tools.queryString(connexion.connect.url + 'dashboard/users/session', {
				token: session
			})
			fetch(requrl)
			.then(res => res.json())
			.then(session => {
				if (session !== false)
					this.setState({ user: session.user, session: session })
				else
				{
					Cookies.remove('session', { path: '/dashboard' })
					this.setState({ user: null, session: null })
				}
			})
			.catch(err => console.log(err))
		}
		else
			this.setState({ session: null })
	}

	fetchConfig = () => {
		console.log("fetch config")
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'dashboard/config/global/list')
			.then(res => res.json())
			.then(config => {
				let background = null
				let establishment = ''
				let features = null
				let lang_default = null
				let langs = null
				let connexion = false
				let nbdays = 0
				let sendmail = false
				let welcome = []
				for (let i = 0; i < config.length; i++)
				{
					if (config[i].key === "establishment")
						establishment = config[i].value
					else if (config[i].key === "features")
						features = config[i].value
					else if (config[i].key === "webapp-connexion")
						connexion = config[i].value
					else if (config[i].key === "lang-default")
						lang_default = config[i].value
					else if (config[i].key === "langs")
						langs = config[i].value
					else if (config[i].key === "send-mail-cron")
						sendmail = config[i].value
					else if (config[i].key === "nb-days-mail-cron")
						nbdays = config[i].value.join(', ')
					else if (config[i].key === "background-img")
						background = config[i].value
					else if (config[i].key === "welcome-txt")
						welcome = config[i].value
				}
				this.setState({
					establishment: establishment,
					features: features,
					lang_default: lang_default,
					langs: langs,
					webapp_connexion: connexion,
					emails_nbdays: nbdays,
					emails_activate: sendmail,
					background_img: background,
					welcome_txt: welcome
				})
				resolve(config)
			})
			.catch(err => {
				reject(err)
				console.log(err)
			})
		})
	}

	fetchConfigDeposit = () => {
		console.log("fetch config deposit")
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'webapp/config/deposit')
			.then(res => res.json())
			.then(config => {
				this.setState({ config_deposit: config })
				resolve(config)
			})
			.catch(err => {
				reject(err)
				console.log(err)
			})
		})
	}

	fetchConfigInventory = () => {
		console.log("fetch config inventory")
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'webapp/config/inventory/list')
			.then(res => res.json())
			.then(config => {
				this.setState({ config_inventory: config })
				resolve(config)
			})
			.catch(err => {
				reject(err)
				console.log(err)
			})
		})
	}

	fetchConfigPms = () => {
		console.log("fetch config pms")
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'webapp/config/pms')
			.then(res => res.json())
			.then(config => {
				this.setState({ config_pms: config })
				resolve(config)
			})
			.catch(err => {
				reject(err)
				console.log(err)
			})
		})
	}

	fetchConversations = () => {
		console.log("fetch conversations")
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'webapp/conversations/list')
			.then(res => res.json())
			.then(conversations => {
				conversations.sort((a, b) => {
					return a.created_time - b.created_time
				})
				this.setState({ conversations: conversations })
				resolve(conversations)
			})
			.catch(err => {
				console.log(err)
				reject(err)
			})
		})
	}

	fetchDashboardConfig = () => {
		console.log("fetch dashboard config")
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'dashboard/config/admin/list')
			.then(res => res.json())
			.then(dashboard_config => {
				this.setState({ dashboard_config: dashboard_config })
				resolve(dashboard_config)
			})
			.catch(err => {
				console.log(err)
				reject(err)
			})
		})
	}

	fetchDashboardUsers = () => {
		console.log("fetch dashboard users")
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'dashboard/users/list')
			.then(res => res.json())
			.then(users => {
				this.setState({ dashboard_users: users })
				resolve(users)
			})
			.catch(err => {
				reject(err)
				console.log(err)
			})
		})
	}

	fetchDeposits = () => {
		console.log("fetch deposits")
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'webapp/deposits/list')
			.then(res => res.json())
			.then(deposits => {
				this.setState({ deposits: deposits })
				resolve(deposits)
			})
			.catch(err => {
				console.log(err)
				reject(err)
			})
		})
	}

	fetchEmails = () => {
		console.log("fetch emails")
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'dashboard/emails/list')
			.then(res => res.json())
			.then(emails => {
				this.setState({ emails: emails })
				resolve(emails)
			})
			.catch(err => {
				reject(err)
				console.log(err)
			})
		})
	}

	fetchFiles = () => {
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'dashboard/files/list')
			.then(res => res.json())
			.then(files => {
				this.setState({ files: files })
				resolve(files)
			})
			.catch(err => {
				reject(err)
				console.log(err)
			})
		})
	}

	fetchGroups = () => {
		console.log("fetch groups")
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'webapp/groups/list')
			.then(res => res.json())
			.then(groups => {
				this.setState({ groups: groups })
				resolve(groups)
			})
			.catch(err => {
				console.log(err)
				reject(err)
			})
		})
	}

	fetchIcons = () => {
		console.log("fetch icons")
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'dashboard/icons/list')
			.then(res => res.json())
			.then(icons => {
				icons.sort((a, b) => {
					return a.name.localeCompare(b.name)
				})
				this.setState({ icons: icons })
				resolve(icons)
			})
			.catch(err => {
				reject(err)
				console.log(err)
			})
		})
	}

	fetchInventories = () => {
		console.log("fetch inventories")
		return new Promise((resolve, reject) => {
			const requrl = tools.queryString(connexion.connect.url + 'dashboard/inventories/list', {
				complete: "true",
				pictures: "false"
			})
			fetch(requrl)
			.then(res => res.json())
			.then(inventories => {
				this.setState({ inventories: inventories })
				resolve(inventories)
			})
			.catch(err => {
				console.log(err)
				reject(err)
			})
		})
	}

	promiseInventoryBySearch = {}

	fetchInventoryBySearch = (stay_number) => {
		if (Object.keys(this.promiseInventoryBySearch).indexOf(stay_number) !== -1)
			return this.promiseInventoryBySearch[stay_number]
		else
		{
			const requrl = tools.queryString(connexion.connect.url + 'dashboard/inventories/search', {
				stay_number: stay_number,
				complete: true
			})
			console.log("fetch inventory by search", stay_number)
			this.promiseInventoryBySearch[stay_number] = new Promise((resolve, reject) => {
				fetch(requrl)
				.then(res => res.json())
				.then(inventory => {
					if (Array.isArray(inventory))
					{
						let inventories = (this.state.inventories) ? this.state.inventories : []
						for (let j = 0; j < inventory.length; j++)
						{
							let found = false
							let i = 0
							while (i < inventories.length)
							{
								if (inventories[i]._id === inventory[j]._id)
								{
									found = true
									break
								}
								i++
							}
							if (!found)
								inventories.push(inventory[j])
							else
								inventories[i] = inventory[j]
						}
					}
					resolve(inventory)
				})
				.catch(err => {
					reject(err)
					console.log(err)
				})
			})
			const ret = this.promiseInventoryBySearch[stay_number]
			delete this.promiseInventoryBySearch[stay_number]
			return ret
		}
	}

	promiseInventoryByStayNb = {}

	fetchInventoryByStayNb = (stay_number) => {
		if (Object.keys(this.promiseInventoryByStayNb).indexOf(stay_number) !== -1)
			return this.promiseInventoryByStayNb[stay_number]
		else
		{
			const requrl = tools.queryString(connexion.connect.url + 'dashboard/inventories/find-by-stay-number', {
				stay_number: stay_number,
				complete: true
			})
			console.log("fetch inventory by search", stay_number)
			this.promiseInventoryByStayNb[stay_number] = new Promise((resolve, reject) => {
				fetch(requrl)
				.then(res => res.json())
				.then(inventory => {
					if (Array.isArray(inventory))
					{
						let inventories = (this.state.inventories) ? this.state.inventories : []
						for (let j = 0; j < inventory.length; j++)
						{
							let found = false
							let i = 0
							while (i < inventories.length)
							{
								if (inventories[i]._id === inventory[j]._id)
								{
									found = true
									break
								}
								i++
							}
							if (!found)
								inventories.push(inventory[j])
							else
								inventories[i] = inventory[j]
						}
					}
					resolve(inventory)
				})
				.catch(err => {
					reject(err)
					console.log(err)
				})
			})
			const ret = this.promiseInventoryByStayNb[stay_number]
			delete this.promiseInventoryByStayNb[stay_number]
			return ret
		}
	}

	promiseListByCategory = {}

	fetchListByCategory = (category) => {
		if (Object.keys(this.promiseListByCategory).indexOf(category) !== -1)
			return this.promiseListByCategory[category]
		else
		{
			const requrl = tools.queryString(connexion.connect.url + 'webapp/lists/find-by-category', {
				category: category
			})
			console.log("fetch list by category", category)
			this.promiseListByCategory[category] = new Promise((resolve, reject) => {
				fetch(requrl)
				.then(res => res.json())
				.then(list => {
					if (list)
					{
						let found = false
						let lists = (this.state.lists) ? this.state.lists : []
						let i = 0
						while (i < lists.length)
						{
							if (lists[i]._id === list._id)
							{
								found = true
								break
							}
							i++
						}
						if (!found)
							lists.push(list)
						else
							lists[i] = list
						this.setState({ lists: lists })
					}
					resolve(list)
				})
				.catch(err => {
					reject(err)
					console.log(err)
				})
			})
			const ret = this.promiseListByCategory[category]
			delete this.promiseListByCategory[category]
			return ret
		}
	}

	fetchLists = (nocover = false) => {
		console.log("fetch lists", nocover)
		return new Promise((resolve, reject) => {
			let params = {}
			if (nocover !== false)
				params.nocover = 1
			const requrl = tools.queryString(connexion.connect.url + 'webapp/lists/list', params)
			fetch(requrl)
			.then(res => res.json())
			.then(ret => {
				let lists = (this.state.lists) ? this.state.lists : []
				for (let i = 0; i < ret.length; i++)
				{
					let j = 0
					let found = false
					while (j < lists.length)
					{
						if (ret[i]._id === lists[j]._id)
						{
							found = true
							break
						}
						j++
					}
					if (!found)
						lists.push(ret[i])
					else if (nocover === false)
						lists[j] = ret[i]
					else
					{
						ret[i].cover = lists[j].cover
						lists[j] = ret[i]
					}
				}
				this.setState({ lists: lists })
				resolve(lists)
			})
			.catch(err => {
				console.log(err)
				reject(err)
			})
		})
	}

	fetchMenu = () => {
		console.log("fetch menu")
		return new Promise((resolve, reject) => {
			resolve({})
			/*fetch(connexion.connect.url + 'webapp/config/menu')
			.then(res => res.json())
			.then(menu => {
				this.setState({ menu: menu })
				resolve(menu)
			})
			.catch(err => {
				reject(err)
				console.log(err)
			})
			*/
		})
	}

	promiseMessagesByConversation = {}

	fetchMessagesByConversation = (conversation) => {
		if (Object.keys(this.promiseMessagesByConversation).indexOf(conversation) !== -1)
			return this.promiseMessagesByConversation[conversation]
		else
		{
			console.log("fetch messages by conversation", conversation)
			this.promiseMessagesByConversation[conversation] = new Promise((resolve, reject) => {
				fetch(connexion.connect.url + 'webapp/messages/find-by-conversation', {
					method: 'POST',
					body: JSON.stringify({
						conversation: conversation
					})
				})
				.then(res => res.json())
				.then(ret => {
					if (ret)
					{
						let messages = (this.state.messages) ? this.state.messages : []
						for (let i = 0; i < ret.length; i++)
						{
							let found = false
							for (let j = 0; j < messages.length; j++)
							{
								if (ret[i]._id === messages[j]._id)
								{
									found = true
									break
								}
							}
							if (!found)
								messages.push(ret[i])
						}
						this.setState({ messages: messages })
					}
					resolve(ret)
				})
				.catch(err => {
					reject(err)
					console.log(err)
				})
			})
			const ret = this.promiseMessagesByConversation[conversation]
			delete this.promiseMessagesByConversation[conversation]
			return ret
		}
	}

	fetchModules = () => {
		console.log("fetch modules")
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'webapp/modules/list')
			.then(res => res.json())
			.then(modules => {
				this.setState({ modules: modules })
				resolve(modules)
			})
			.catch(err => {
				reject(err)
				console.log(err)
			})
		})
	}

	fetchNotifications = () => {
		console.log("fetch notifications")
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'dashboard/notifications/list')
			.then(res => res.json())
			.then(notifications => {
				this.setState({ notifications: notifications })
				resolve(notifications)
			})
			.catch(err => {
				reject(err)
				console.log(err)
			})
		})
	}

	fetchNotificationSubscriptions = () => {
		console.log("fetch notification subscriptions")
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'webapp/notifications/list')
			.then(res => res.json())
			.then(subscriptions => {
				this.setState({ subscriptions: subscriptions })
				resolve(subscriptions)
			})
			.catch(err => {
				reject(err)
				console.log(err)
			})
		})
	}

	promisePageByID = {}

	fetchPageByID = (id) => {
		if (Object.keys(this.promisePageByID).indexOf(id) !== -1)
			return this.promisePageByID[id]
		else
		{
			const requrl = tools.queryString(connexion.connect.url + 'webapp/pages/find', {
				id: id
			})
			console.log("fetch page by id", id)
			this.promisePageByID[id] = new Promise((resolve, reject) => {
				fetch(requrl)
				.then(res => res.json())
				.then(page => {
					if (page)
					{
						let found = false
						let pages = (this.state.pages) ? this.state.pages : []
						for (let i = 0; i < pages.length; i++)
						{
							if (pages[i]._id === page._id)
							{
								found = i
								break
							}
						}
						if (found === false)
						{
							pages.push(page)
							this.setState({ pages: pages })
						}
						else if (found !== false && !pages[found].cover)
						{
							pages[found].cover = page.cover
							this.setState({ pages: pages })
						}
					}
					resolve(page)
				})
				.catch(err => {
					reject(err)
					console.log(err)
				})
			})
			const ret = this.promisePageByID[id]
			delete this.promisePageByID[id]
			return ret
		}
	}

	promisePageByCategory = {}

	fetchPageByCategory = (category) => {
		if (Object.keys(this.promisePageByCategory).indexOf(category) !== -1)
			return this.promisePageByCategory[category]
		else
		{
			const requrl = tools.queryString(connexion.connect.url + 'webapp/pages/find-by-category', {
				category: category
			})
			console.log("fetch page by category", category)
			this.promisePageByCategory[category] = new Promise((resolve, reject) => {
				fetch(requrl)
				.then(res => res.json())
				.then(page => {
					if (page)
					{
						let found = false
						let pages = (this.state.pages) ? this.state.pages : []
						for (let i = 0; i < pages.length; i++)
						{
							if (pages[i]._id === page._id)
							{
								found = i
								break
							}
						}
						if (found === false)
						{
							pages.push(page)
							this.setState({ pages: pages })
						}
						else if (found !== false && !pages[found].cover)
						{
							pages[found].cover = page.cover
							this.setState({ pages: pages })
						}
					}
					resolve(page)
				})
				.catch(err => {
					reject(err)
					console.log(err)
				})
			})
			const ret = this.promisePageByCategory[category]
			delete this.promisePageByCategory[category]
			return ret
		}
	}

	fetchPages = (nocover = false, nosections = false) => {
		console.log("fetch pages", nocover, nosections)
		return new Promise((resolve, reject) => {
			let params = {}
			if (nocover !== false)
				params.nocover = 1
			if (nosections !== false)
				params.nosections = 1
			const requrl = tools.queryString(connexion.connect.url + 'webapp/pages/list', params)
			fetch(requrl)
			.then(res => res.json())
			.then(ret => {
				let pages = (this.state.pages) ? this.state.pages : []
				for (let i = 0; i < ret.length; i++)
				{
					let j = 0
					let found = false
					while (j < pages.length)
					{
						if (ret[i]._id === pages[j]._id)
						{
							found = true
							break
						}
						j++
					}
					if (!found)
						pages.push(ret[i])
					else if (nocover === false && nosections === false)
						pages[j] = ret[i]
					else
					{
						ret[i].cover = pages[j].cover
						pages[j] = ret[i]
					}
				}
				this.setState({ pages: pages })
				resolve(pages)
			})
			.catch(err => {
				console.log(err)
				reject(err)
			})
		})
	}

	fetchReviewModels = () => {
		console.log("fetch review_models")
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'dashboard/review_models/list')
			.then(res => res.json())
			.then(review_models => {
				this.setState({ review_models: review_models })
				resolve(review_models)
			})
			.catch(err => {
				console.log(err)
				reject(err)
			})
		})
	}

	promiseReviewsByType = {}

	fetchReviewsByType = (type) => {
		if (Object.keys(this.promiseReviewsByType).indexOf(type) !== -1)
			return this.promiseReviewsByType[type]
		else
		{
			const requrl = tools.queryString(connexion.connect.url + 'webapp/reviews/find-by-type', {
				type: type
			})
			console.log("fetch review by type", type)
			this.promiseReviewsByType[type] = new Promise((resolve, reject) => {
				fetch(requrl)
				.then(res => res.json())
				.then(ret => {
					if (ret)
					{
						let reviews = (this.state.reviews) ? this.state.reviews : []
						for (let i = 0; i < ret.length; i++)
						{
							let found = false
							let j = 0
							while (j < reviews.length)
							{
								if (ret[i]._id === reviews[j]._id)
								{
									found = true
									break
								}
								j++
							}
							if (found === false)
								reviews.push(ret[i])
							else
								reviews[j] = ret[i]
						}
					}
					resolve(ret)
				})
				.catch(err => {
					reject(err)
					console.log(err)
				})
			})
			const ret = this.promiseReviewsByType[type]
			delete this.promiseReviewsByType[type]
			return ret
		}
	}

	fetchServiceBenefits = () => {
		console.log("fetch service_benefits")
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'dashboard/service_benefits/list')
			.then(res => res.json())
			.then(service_benefits => {
				this.setState({ service_benefits: service_benefits })
				resolve(service_benefits)
			})
			.catch(err => {
				console.log(err)
				reject(err)
			})
		})
	}

	promiseServiceBenefitsByService = {}

	fetchServiceBenefitsByService = (service) => {
		if (Object.keys(this.promiseServiceBenefitsByService).indexOf(service) !== -1)
			return this.promiseServiceBenefitsByService[service]
		else
		{
			const requrl = tools.queryString(connexion.connect.url + 'dashboard/service_benefits/find-by-service', {
				service: service
			})
			console.log("fetch service benefits by service", service)
			this.promiseServiceBenefitsByService[service] = new Promise((resolve, reject) => {
				fetch(requrl)
				.then(res => res.json())
				.then(ret => {
					if (ret)
					{
						let service_benefits = (this.state.service_benefits) ? this.state.service_benefits : []
						for (let i = 0; i < ret.length; i++)
						{
							let found = false
							let j = 0
							while (j < service_benefits.length)
							{
								if (ret[i]._id === service_benefits[j]._id)
								{
									found = true
									break
								}
								j++
							}
							if (found === false)
								service_benefits.push(ret[i])
							else
								service_benefits[j] = ret[i]
						}
						this.setState({ service_benefits: service_benefits })
					}
					resolve(ret)
				})
				.catch(err => {
					reject(err)
					console.log(err)
				})
			})
			const ret = this.promiseServiceBenefitsByService[service]
			delete this.promiseServiceBenefitsByService[service]
			return ret
		}
	}

	promiseServiceProductsByService = {}

	fetchServiceProductsByService = (service) => {
		if (Object.keys(this.promiseServiceProductsByService).indexOf(service) !== -1)
			return this.promiseServiceProductsByService[service]
		else
		{
			const requrl = tools.queryString(connexion.connect.url + 'dashboard/service_products/find-by-service', {
				service: service
			})
			console.log("fetch service products by service", service)
			this.promiseServiceProductsByService[service] = new Promise((resolve, reject) => {
				fetch(requrl)
				.then(res => res.json())
				.then(ret => {
					if (ret)
					{
						let service_products = (this.state.service_products) ? this.state.service_products : []
						for (let i = 0; i < ret.length; i++)
						{
							let found = false
							let j = 0
							while (j < service_products.length)
							{
								if (ret[i]._id === service_products[j]._id)
								{
									found = true
									break
								}
								j++
							}
							if (found === false)
								service_products.push(ret[i])
							else
								service_products[j] = ret[i]
						}
						this.setState({ service_products: service_products })
					}
					resolve(ret)
				})
				.catch(err => {
					reject(err)
					console.log(err)
				})
			})
			const ret = this.promiseServiceProductsByService[service]
			delete this.promiseServiceProductsByService[service]
			return ret
		}
	}

	promiseServiceReservationsByService = {}

	fetchServiceReservationsByService = (service) => {
		if (Object.keys(this.promiseServiceReservationsByService).indexOf(service) !== -1)
			return this.promiseServiceReservationsByService[service]
		else
		{
			const requrl = tools.queryString(connexion.connect.url + 'webapp/service_reservations/find-by-service', {
				service: service
			})
			console.log("fetch service reservations by service", service)
			this.promiseServiceReservationsByService[service] = new Promise((resolve, reject) => {
				fetch(requrl)
				.then(res => res.json())
				.then(ret => {
					if (ret)
					{
						let service_reservations = (this.state.service_reservations) ? this.state.service_reservations : []
						for (let i = 0; i < ret.length; i++)
						{
							let found = false
							let j = 0
							while (j < service_reservations.length)
							{
								if (ret[i]._id === service_reservations[j]._id)
								{
									found = true
									break
								}
								j++
							}
							if (found === false)
								service_reservations.push(ret[i])
							else
								service_reservations[j] = ret[i]
						}
						this.setState({ service_reservations: service_reservations })
					}
					resolve(ret)
				})
				.catch(err => {
					reject(err)
					console.log(err)
				})
			})
			const ret = this.promiseServiceReservationsByService[service]
			delete this.promiseServiceReservationsByService[service]
			return ret
		}
	}

	fetchServiceWorkers = () => {
		console.log("fetch service_workers")
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'dashboard/service_workers/list')
			.then(res => res.json())
			.then(service_workers => {
				this.setState({ service_workers: service_workers })
				resolve(service_workers)
			})
			.catch(err => {
				console.log(err)
				reject(err)
			})
		})
	}

	promiseServiceWorkersByService = {}

	fetchServiceWorkersByService = (service) => {
		if (Object.keys(this.promiseServiceWorkersByService).indexOf(service) !== -1)
			return this.promiseServiceWorkersByService[service]
		else
		{
			const requrl = tools.queryString(connexion.connect.url + 'dashboard/service_workers/find-by-service', {
				service: service
			})
			console.log("fetch service workers by service", service)
			this.promiseServiceWorkersByService[service] = new Promise((resolve, reject) => {
				fetch(requrl)
				.then(res => res.json())
				.then(ret => {
					if (ret)
					{
						let service_workers = (this.state.service_workers) ? this.state.service_workers : []
						for (let i = 0; i < ret.length; i++)
						{
							let found = false
							let j = 0
							while (j < service_workers.length)
							{
								if (ret[i]._id === service_workers[j]._id)
								{
									found = true
									break
								}
								j++
							}
							if (found === false)
								service_workers.push(ret[i])
							else
								service_workers[j] = ret[i]
						}
						this.setState({ service_workers: service_workers })
					}
					resolve(ret)
				})
				.catch(err => {
					reject(err)
					console.log(err)
				})
			})
			const ret = this.promiseServiceWorkersByService[service]
			delete this.promiseServiceWorkersByService[service]
			return ret
		}
	}

	fetchServices = () => {
		console.log("fetch services")
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'dashboard/services/list')
			.then(res => res.json())
			.then(services => {
				this.setState({ services: services })
				resolve(services)
			})
			.catch(err => {
				console.log(err)
				reject(err)
			})
		})
	}

	fetchTheme = () => {
		console.log("fetch theme")
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'webapp/config/theme')
			.then(res => res.json())
			.then(theme => {
				this.setState({ theme: theme })
				resolve(theme)
			})
			.catch(err => {
				reject(err)
				console.log(err)
			})
		})
	}

	fetchVersion = () => {
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'webapp/config/version')
			.then(res => res.json())
			.then(version => {
				console.log('fetch version', version)
				let localVersion = localStorage.getItem("version")
				if (localVersion === null || localVersion !== version)
				{
					window.location.reload()
					localStorage.setItem("version", version)
				}
				resolve(version)
			})
			.catch(err => {
				console.log(err)
				reject(err)
			})
		})
	}

	fetchWebappConfig = () => {
		console.log("fetch config")
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'webapp/logs/count')
			.then(res => res.json())
			.then(logs => {
				fetch(connexion.connect.url + 'webapp/notifications/count')
				.then(res => res.json())
				.then(notifications => {
					fetch(connexion.connect.url + 'webapp/users/count-actives')
					.then(res => res.json())
					.then(users => {
						console.log('nb_users', logs.installed, logs.nb_users, users)
						let downloads = 100 * (logs.installed / logs.nb_users)
						let precision = 2
						while (downloads >= 10)
						{
							downloads /= 10
							precision++
						}
						if (logs.nb_users > 0)
							downloads = (100 * (logs.installed / logs.nb_users)).toPrecision(precision) + "%"
						else
							downloads = 0
						const webapp_config = {
							downloads: downloads,
							logs: logs.count,
							notifications: notifications,
							users: users
						}
						this.setState({ webapp_config: webapp_config })
						resolve(webapp_config)
					})
					.catch(err => {
						reject(err)
					})
				})
				.catch(err => {
					reject(err)
				})
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	fetchWebappUsers = () => {
		console.log("fetch webapp users")
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'webapp/users/list')
			.then(res => res.json())
			.then(users => {
				this.setState({ webapp_users: users })
				resolve(users)
			})
			.catch(err => {
				reject(err)
				console.log(err)
			})
		})
	}

	/*
	** INSERT METHODS
	*/

	insertAccommodation = (accommodation) => {
		if (!accommodation.name)
		{
			return new Promise((resolve, reject) => {
				console.log("miss argument in insert accommodation", accommodation)
				reject(false)
			})
		}
		console.log("insert accommodation", accommodation)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'dashboard/accommodations/insert', {
				method: 'POST',
				body: JSON.stringify({
					name: accommodation.name
				})
			})
			.then(res => res.json())
			.then(ret => {
				if (ret !== false)
				{
					let accommodations = (this.state.accommodations) ? tools.clone(this.state.accommodations) : []
					accommodations.push(ret)
					this.setState({ accommodations: accommodations })
				}
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	insertAccommodationDuplicate = (accommodation) => {
		if (!accommodation.name || !accommodation.duplicate)
		{
			return new Promise((resolve, reject) => {
				console.log("miss argument in insert accommodation duplicate", accommodation)
				reject(false)
			})
		}
		console.log("insert accommodation", accommodation)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'dashboard/accommodations/duplicate', {
				method: 'POST',
				body: JSON.stringify({
					name: accommodation.name,
					duplicate: accommodation.duplicate
				})
			})
			.then(res => res.json())
			.then(ret => {
				if (ret !== false)
				{
					let accommodations = (this.state.accommodations) ? tools.clone(this.state.accommodations) : []
					accommodations.push(ret)
					this.setState({ accommodations: accommodations })
				}
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	insertAnimation = (animation) => {
		if (!animation.category || (animation.front === true && !animation.cover) || !animation.translations || (!animation.service_benefit && animation.service_benefit !== null))
		{
			return new Promise((resolve, reject) => {
				console.log("miss argument in insert animation", animation)
				reject(false)
			})
		}
		console.log("insert animation", animation)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'webapp/animations/insert', {
				method: 'POST',
				body: JSON.stringify({
					category: animation.category,
					cover: animation.cover,
					front: animation.front,
					translations: animation.translations,
					service_benefit: animation.service_benefit
				})
			})
			.then(res => res.json())
			.then(ret => {
				if (ret !== false)
				{
					let animations = (this.state.animations) ? tools.clone(this.state.animations) : []
					animations.push(ret)
					this.setState({ animations: animations })
				}
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	insertAnimationCategory = (category) => {
		if (!category.color || !category.translations)
		{
			return new Promise((resolve, reject) => {
				console.log("miss argument in insert animation category", category)
				reject(false)
			})
		}
		console.log("insert animation category", category)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'webapp/animation-categories/insert', {
				method: 'POST',
				body: JSON.stringify({
					color: category.color,
					translations: category.translations
				})
			})
			.then(res => res.json())
			.then(ret => {
				if (ret !== false)
				{
					let animation_categories = (this.state.animation_categories) ? tools.clone(this.state.animation_categories) : []
					animation_categories.push(ret)
					this.setState({ animation_categories: animation_categories })
				}
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	insertAnimationDates = (obj) => {
		if (!obj.animation || !obj.dates)
		{
			return new Promise((resolve, reject) => {
				console.log("miss argument in insert animation dates", obj)
				reject(false)
			})
		}
		console.log("insert animation dates", obj)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'webapp/animation-dates/insert', {
				method: 'POST',
				body: JSON.stringify({
					animation: obj.animation,
					dates: obj.dates
				})
			})
			.then(res => res.json())
			.then(ret => {
				if (ret !== false)
				{
					let animation_dates = (this.state.animation_dates) ? tools.clone(this.state.animation_dates) : []
					for (let i = 0; i < ret.length; i++)
						animation_dates.push(ret[i])
					this.setState({ animation_dates: animation_dates })
				}
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	insertCategory = (category) => {
		if (!category.icon || !category.type || !category.group || !category.translations)
		{
			return new Promise((resolve, reject) => {
				console.log("miss argument in insert category", category)
				reject(false)
			})
		}
		console.log("insert category", category)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'webapp/categories/insert', {
				method: 'POST',
				body: JSON.stringify({
					icon: category.icon,
					type: category.type,
					group: category.group,
					translations: category.translations
				})
			})
			.then(res => res.json())
			.then(ret => {
				if (ret !== false)
				{
					let categories = (this.state.categories) ? tools.clone(this.state.categories) : []
					categories.push(ret)
					this.setState({ categories: categories })
				}
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	insertCronNotification = (cron_notification) => {
		if (!cron_notification.timestamp || !cron_notification.user || !cron_notification.redirect || !cron_notification.translations || !cron_notification.user_list)
		{
			return new Promise((resolve, reject) => {
				console.log("miss argument in insert cron_notification", cron_notification)
				reject(false)
			})
		}
		console.log("insert cron_notification", cron_notification)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'dashboard/cron_notification/insert', {
				method: 'POST',
				body: JSON.stringify({
					timestamp: cron_notification.timestamp,
					user: cron_notification.user,
					redirect: cron_notification.redirect,
					translations: cron_notification.translations,
					user_list: cron_notification.user_list
				})
			})
			.then(res => res.json())
			.then(ret => {
				if (ret !== false)
				{
					let cron_notifications = (this.state.cron_notifications) ? tools.clone(this.state.cron_notifications) : []
					cron_notifications.push(ret)
					this.setState({ cron_notifications: cron_notifications })
				}
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	insertEmail = (email) => {
		if (!email.name || !email.name || !email.translations)
		{
			return new Promise((resolve, reject) => {
				console.log("miss argument in insert email", email)
				reject(false)
			})
		}
		console.log("insert email", email)
		return new Promise((resolve, reject) => {
			let order = 0
			for (let i = 0; i < this.state.emails.length; i++)
			{
				if (this.state.emails[i].order >= order)
					order = this.state.emails[i].order + 1
			}
			fetch(connexion.connect.url + 'dashboard/emails/insert', {
				method: 'POST',
				body: JSON.stringify({
					name: email.name,
					translations: email.translations
				})
			})
			.then(res => res.json())
			.then(ret => {
				if (ret !== false)
				{
					let emails = (this.state.emails) ? tools.clone(this.state.emails) : []
					emails.push(ret)
					this.setState({ emails: emails })
				}
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	insertGroup = (group) => {
		if (!group.translations || !group.icon)
		{
			return new Promise((resolve, reject) => {
				console.log("miss argument in insert group", group)
				reject(false)
			})
		}
		console.log("insert group", group)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'webapp/groups/insert', {
				method: 'POST',
				body: JSON.stringify({
					translations: group.translations,
					icon: group.icon
				})
			})
			.then(res => res.json())
			.then(ret => {
				if (ret !== false)
				{
					let groups = (this.state.groups) ? tools.clone(this.state.groups) : []
					groups.push(ret)
					this.setState({ groups: groups })
				}
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	insertInventory = (inventory) => {
		console.log("send inventory", inventory)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'dashboard/inventories/insert', {
				method: 'POST',
				body: JSON.stringify({
					answers: inventory.answers,
					type: inventory.type,
					user: inventory.user,
					stay_number: inventory.stay_number
				})
			})
			.then(res => res.json())
			.then(inventory => {
				if (inventory !== false)
				{
					let inventories = (this.state.inventories) ? tools.clone(this.state.inventories) : []
					inventories.push(inventory)
					this.setState({ inventories: inventories })
				}
				resolve(inventory)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	insertInventoryCategory = (category) => {
		if (!category.translations || !category.accommodation)
		{
			return new Promise((resolve, reject) => {
				console.log("miss argument in insert inventory category", category)
				reject(false)
			})
		}
		console.log("insert inventory category", category)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'dashboard/accommodations/insert-category', {
				method: 'POST',
				body: JSON.stringify({
					accommodation: category.accommodation,
					translations: category.translations
				})
			})
			.then(res => res.json())
			.then(ret => {
				if (ret !== false)
				{
					let accommodations = (this.state.accommodations) ? tools.clone(this.state.accommodations) : []
					for (let i = 0; i < accommodations.length; i++)
					{
						if (accommodations[i]._id === ret._id)
						{
							accommodations[i] = ret
							break
						}
					}
					this.setState({ accommodations: accommodations })
				}
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	insertInventoryObjects = (category) => {
		if (!category.accommodation || !category.translations || !category.lang || !category.objects || !category.reviews)
		{
			return new Promise((resolve, reject) => {
				console.log("miss argument in insert inventory category", category)
				reject(false)
			})
		}
		console.log("insert category", category)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'dashboard/accommodations/insert-objects', {
				method: 'POST',
				body: JSON.stringify({
					accommodation: category.accommodation,
					translations: category.translations,
					lang: category.lang,
					objects: category.objects,
					reviews: category.reviews
				})
			})
			.then(res => res.json())
			.then(ret => {
				if (ret !== false)
				{
					let accommodations = (this.state.accommodations) ? tools.clone(this.state.accommodations) : []
					for (let i = 0; i < accommodations.length; i++)
					{
						if (accommodations[i]._id === ret._id)
						{
							accommodations[i] = ret
							break
						}
					}
					this.setState({ accommodations: accommodations })
				}
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	insertList = (list) => {
		if (!list.elements || !list.category || !list.translations)
		{
			return new Promise((resolve, reject) => {
				console.log("miss argument in insert list", list)
				reject(false)
			})
		}
		console.log("insert list", list)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'webapp/lists/insert', {
				method: 'POST',
				body: JSON.stringify({
					elements: list.elements,
					category: list.category,
					translations: list.translations
				})
			})
			.then(res => res.json())
			.then(ret => {
				if (ret !== false)
				{
					let lists = (this.state.lists) ? tools.clone(this.state.lists) : []
					lists.push(ret)
					this.setState({ lists: lists })
				}
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	insertListElement = (element) => {
		if (!element.list_id || !element.page || !element.icon || !element.translations)
		{
			return new Promise((resolve, reject) => {
				console.log("miss argument in insert list element", element)
				reject(false)
			})
		}
		console.log("insert list element", element)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'webapp/lists/insert-element', {
				method: 'POST',
				body: JSON.stringify({
					id: element.list_id,
					page: element.page,
					icon: element.icon,
					translations: element.translations
				})
			})
			.then(res => res.json())
			.then(ret => {
				if (ret !== false)
				{
					let lists = (this.state.lists) ? tools.clone(this.state.lists) : []
					for (let i = 0; i < lists.length; i++)
					{
						if (lists[i]._id === ret._id)
						{
							lists[i] = ret
							break
						}
					}
					this.setState({ lists: lists })
				}
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	insertModule = (module) => {
		if (!module.name || !module.type || !module.values || !module.translations)
		{
			return new Promise((resolve, reject) => {
				console.log("miss argument in insert module", module)
				reject(false)
			})
		}
		console.log("insert module", module)
		return new Promise((resolve, reject) => {
			let order = 0
			for (let i = 0; i < this.state.modules.length; i++)
			{
				if (this.state.modules[i].order >= order)
					order = this.state.modules[i].order + 1
			}
			fetch(connexion.connect.url + 'webapp/modules/insert', {
				method: 'POST',
				body: JSON.stringify({
					name: module.name,
					type: module.type,
					values: module.values,
					translations: module.translations,
					order: order
				})
			})
			.then(res => res.json())
			.then(ret => {
				if (ret !== false)
				{
					let modules = (this.state.modules) ? tools.clone(this.state.modules) : []
					modules.push(ret)
					this.setState({ modules: modules })
				}
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	insertNotification = (notification) => {
		if (!notification.name || !notification.redirect || !notification.translations || !notification.user)
		{
			return new Promise((resolve, reject) => {
				console.log("miss argument in insert notification", notification)
				reject(false)
			})
		}
		console.log("insert notification", notification)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'dashboard/notifications/insert', {
				method: 'POST',
				body: JSON.stringify({
					name: notification.name,
					redirect: JSON.parse(notification.redirect),
					translations: notification.translations,
					user: notification.user
				})
			})
			.then(res => res.json())
			.then(ret => {
				if (ret !== false)
				{
					let notifications = (this.state.notifications) ? tools.clone(this.state.notifications) : []
					notifications.push(ret)
					this.setState({ notifications: notifications })
				}
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	insertPage = (page) => {
		if (!page.translations || (!page.category && page.category !== null) || (!page.cover && page.cover !== null) || !page.sections)
		{
			return new Promise((resolve, reject) => {
				console.log("miss argument in insert page", page)
				reject(false)
			})
		}
		console.log("insert page", page)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'webapp/pages/insert', {
				method: 'POST',
				body: JSON.stringify({
					category: page.category,
					cover: page.cover,
					sections: page.sections,
					translations: page.translations
				})
			})
			.then(res => res.json())
			.then(ret => {
				if (ret !== false)
				{
					let pages = (this.state.pages) ? tools.clone(this.state.pages) : []
					pages.push(ret)
					this.setState({ pages: pages })
				}
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	insertPageSection = (section) => {
		if (!section.page_id || !section.icon || !section.translations ||
			(!section.phone && section.phone.length !== 0) || (!section.email && section.email.length !== 0) ||
			(!section.website && section.website.length !== 0) || (!section.localisation && section.localisation.length !== 0) ||
			(!section.facebook && section.facebook.length !== 0) || (!section.instagram && section.instagram.length !== 0))
		{
			return new Promise((resolve, reject) => {
				console.log("miss argument in insert page section", section)
				reject(false)
			})
		}
		console.log("insert page section", section)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'webapp/pages/insert-section', {
				method: 'POST',
				body: JSON.stringify({
					id: section.page_id,
					icon: section.icon,
					phone: section.phone,
					email: section.email,
					website: section.website,
					localisation: section.localisation,
					facebook: section.facebook,
					instagram: section.instagram,
					translations: section.translations
				})
			})
			.then(res => res.json())
			.then(ret => {
				if (ret !== false)
				{
					let pages = (this.state.pages) ? tools.clone(this.state.pages) : []
					for (let i = 0; i < pages.length; i++)
					{
						if (pages[i]._id === ret._id)
						{
							pages[i] = ret
							break
						}
					}
					this.setState({ pages: pages })
				}
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	insertPdf = (pdf) => {
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'dashboard/files/add-pdf', {
				method: 'POST',
				body: pdf
			})
			.then(res => res.json())
			.then(ret => {
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	insertPicture = (picture) => {
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'dashboard/files/add-image', {
				method: 'POST',
				body: picture
			})
			.then(res => res.json())
			.then(ret => {
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	insertReviewModel = (review_model) => {
		if (!review_model.name || !review_model.questions || !review_model.user || (!review_model.type && review_model.type !== "") ||
			!review_model.translations || (!review_model.notification_time && review_model.notification_time !== 0))
		{
			return new Promise((resolve, reject) => {
				console.log("miss argument in insert review_model", review_model)
				reject(false)
			})
		}
		console.log("insert review_model", review_model)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'dashboard/review_models/insert', {
				method: 'POST',
				body: JSON.stringify({
					name: review_model.name,
					type: review_model.type,
					notification_time: review_model.notification_time,
					translations: review_model.translations,
					questions: review_model.questions,
					user: review_model.user
				})
			})
			.then(res => res.json())
			.then(ret => {
				if (ret !== false && !ret.err_code)
				{
					let review_models = (this.state.review_models) ? tools.clone(this.state.review_models) : []
					review_models.push(ret)
					this.setState({ review_models: review_models })
				}
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	insertRoomlist = (roomlist) => {
		console.log("insert roomlist", roomlist)
		return new Promise((resolve, reject) => {
			if (roomlist.client || roomlist.firstname || roomlist.lastname || roomlist.email || roomlist.phone || roomlist.arrival || roomlist.departure)
			{
				fetch(connexion.connect.url + 'dashboard/clients/import', {
					method: 'POST',
					body: JSON.stringify({
						client: roomlist.client,
						firstname: roomlist.firstname,
						lastname: roomlist.lastname,
						email: roomlist.email,
						arrival: roomlist.arrival,
						departure: roomlist.departure,
						phone: roomlist.phone,
						file: roomlist.file
					})
				})
				.then(res => res.json())
				.then(ret => {
					resolve(ret)
				})
				.catch(err => {
					reject(err)
				})
			}
			else
			{
				fetch(connexion.connect.url + 'dashboard/clients/import', {
					method: 'POST',
					body: JSON.stringify({
						clients: roomlist.clients,
						reservations: roomlist.reservations
					})
				})
				.then(res => res.json())
				.then(ret => {
					resolve(ret)
				})
				.catch(err => {
					reject(err)
				})
			}
		})
	}

	insertService = (service) => {
		if (!service.name || !service.cover || !service.type || !service.user || !service.translations ||
			(!service.list_mode && service.list_mode !== false) || (!service.comment && service.comment !== null))
		{
			return new Promise((resolve, reject) => {
				console.log("miss argument in insert service", service)
				reject(false)
			})
		}
		console.log("insert service", service)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'dashboard/services/insert', {
				method: 'POST',
				body: JSON.stringify({
					name: service.name,
					cover: service.cover,
					type: service.type,
					translations: service.translations,
					comment: service.comment,
					list_mode: service.list_mode,
					user: service.user
				})
			})
			.then(res => res.json())
			.then(ret => {
				if (ret !== false)
				{
					let services = (this.state.services) ? tools.clone(this.state.services) : []
					services.push(ret)
					this.setState({ services: services })
				}
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	insertServiceBenefit = (service_benefit) => {
		if (!service_benefit.service || !service_benefit.icon || !service_benefit.open_dates || !service_benefit.open_hours || (!service_benefit.simultaneous && service_benefit.simultaneous !== null) || (!service_benefit.price && service_benefit.price !== 0) ||
			(!service_benefit.duration_hour && service_benefit.duration_hour !== 0) || (!service_benefit.duration_minute && service_benefit.duration_minute !== 0) ||
			(!service_benefit.confirmation && service_benefit.confirmation !== false) || !service_benefit.translations || !service_benefit.questions ||
			(!service_benefit.client_number && service_benefit.client_number !== false) || (!service_benefit.identity && service_benefit.identity !== false) ||
			(!service_benefit.age && service_benefit.age !== false) || (!service_benefit.phone && service_benefit.phone !== false) ||
			(!service_benefit.interval_hour && service_benefit.interval_hour !== 0) || (!service_benefit.show_price && service_benefit.show_price !== false) || !service_benefit.user)
		{
			return new Promise((resolve, reject) => {
				console.log("miss argument in insert service_benefit", service_benefit)
				reject(false)
			})
		}
		console.log("insert service_benefit", service_benefit)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'dashboard/service_benefits/insert', {
				method: 'POST',
				body: JSON.stringify({
					service: service_benefit.service,
					icon: service_benefit.icon,
					show_price: service_benefit.show_price,
					price: service_benefit.price,
					duration_hour: service_benefit.duration_hour,
					duration_minute: service_benefit.duration_minute,
					interval_hour: service_benefit.interval_hour,
					interval_minute: service_benefit.interval_minute,
					confirmation: service_benefit.confirmation,
					simultaneous: service_benefit.simultaneous,
					open_dates: service_benefit.open_dates,
					open_hours: service_benefit.open_hours,
					translations: service_benefit.translations,
					client_number: service_benefit.client_number,
					identity: service_benefit.identity,
					age: service_benefit.age,
					phone: service_benefit.phone,
					questions: service_benefit.questions,
					user: service_benefit.user
				})
			})
			.then(res => res.json())
			.then(ret => {
				if (ret !== false)
				{
					let service_benefits = (this.state.service_benefits) ? tools.clone(this.state.service_benefits) : []
					service_benefits.push(ret)
					this.setState({ service_benefits: service_benefits })
				}
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	insertServiceProduct = (service_product) => {
		if (!service_product.service || !service_product.icon || (!service_product.price && service_product.price !== 0) ||
			(!service_product.stock && service_product.stock !== null) || !service_product.translations || !service_product.user)
		{
			return new Promise((resolve, reject) => {
				console.log("miss argument in insert service_product", service_product)
				reject(false)
			})
		}
		console.log("insert service_product", service_product)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'dashboard/service_products/insert', {
				method: 'POST',
				body: JSON.stringify({
					service: service_product.service,
					icon: service_product.icon,
					price: service_product.price,
					stock: service_product.stock,
					translations: service_product.translations,
					user: service_product.user
				})
			})
			.then(res => res.json())
			.then(ret => {
				if (ret !== false)
				{
					let service_products = (this.state.service_products) ? tools.clone(this.state.service_products) : []
					service_products.push(ret)
					this.setState({ service_products: service_products })
				}
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	insertServiceReservation = (service_reservation) => {
		if (!service_reservation.service || !service_reservation.service_worker || !service_reservation.service_benefit || !service_reservation.answers ||
			!service_reservation.start || !service_reservation.end || (!service_reservation.user && service_reservation.user !== null) ||
			(!service_reservation.client_number && service_reservation.client_number !== "") || (!service_reservation.identity && service_reservation.identity !== "") ||
			(!service_reservation.age && service_reservation.age !== "") || (!service_reservation.phone && service_reservation.phone !== ""))
		{
			return new Promise((resolve, reject) => {
				console.log("miss argument in insert service_reservation", service_reservation)
				reject(false)
			})
		}
		console.log("insert service_reservation", service_reservation)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'webapp/service_reservations/submit', {
				method: 'POST',
				body: JSON.stringify({
					from: "dashboard",
					service: service_reservation.service,
					service_worker: service_reservation.service_worker,
					service_benefit: service_reservation.service_benefit,
					start: service_reservation.start,
					end: service_reservation.end,
					answers: service_reservation.answers,
					user: service_reservation.user,
					client_number: service_reservation.client_number,
					identity: service_reservation.identity,
					age: service_reservation.age,
					phone: service_reservation.phone,
					self: (service_reservation.user) ? true : false
				})
			})
			.then(res => res.json())
			.then(ret => {
				if (ret !== false)
				{
					let service_reservations = (this.state.service_reservations) ? tools.clone(this.state.service_reservations) : []
					service_reservations.push(ret)
					this.setState({ service_reservations: service_reservations })
				}
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	insertServiceWorker = (service_worker) => {
		if (!service_worker.service || !service_worker.firstname || !service_worker.lastname || !service_worker.working_dates ||
			!service_worker.working_hours || !service_worker.services || !service_worker.user)
		{
			return new Promise((resolve, reject) => {
				console.log("miss argument in insert service_worker", service_worker)
				reject(false)
			})
		}
		console.log("insert service_worker", service_worker)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'dashboard/service_workers/insert', {
				method: 'POST',
				body: JSON.stringify({
					service: service_worker.service,
					firstname: service_worker.firstname,
					lastname: service_worker.lastname,
					confirmation: service_worker.confirmation,
					working_dates: service_worker.working_dates,
					working_hours: service_worker.working_hours,
					services: service_worker.services,
					user: service_worker.user
				})
			})
			.then(res => res.json())
			.then(ret => {
				if (ret !== false)
				{
					let service_workers = (this.state.service_workers) ? tools.clone(this.state.service_workers) : []
					service_workers.push(ret)
					this.setState({ service_workers: service_workers })
				}
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	/*
	** UPDATE METHODS
	*/

	updateAccommodation = (accommodation) => {
		if (!accommodation._id || !accommodation.name)
		{
			return new Promise((resolve, reject) => {
				console.log("miss argument in update accommodation", accommodation)
				reject(false)
			})
		}
		console.log("update accommodation", accommodation)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'dashboard/accommodations/update', {
				method: 'POST',
				body: JSON.stringify({
					id: accommodation._id,
					name: accommodation.name
				})
			})
			.then(res => res.json())
			.then(ret => {
				if (ret !== false)
				{
					let accommodations = (this.state.accommodations) ? tools.clone(this.state.accommodations) : []
					for (let i = 0; i < accommodations.length; i++)
					{
						if (accommodations[i]._id === ret._id)
						{
							accommodations[i] = ret
							break
						}
					}
					this.setState({ accommodations: accommodations })
				}
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	updateAccommodationDeposit = (accommodation) => {
		if (!accommodation._id || (!accommodation.deposit && accommodation.deposit !== false))
		{
			return new Promise((resolve, reject) => {
				console.log("miss argument in update accommodation deposit", accommodation)
				reject(false)
			})
		}
		console.log("update accommodation deposit", accommodation)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'dashboard/accommodations/update-deposit', {
				method: 'POST',
				body: JSON.stringify({
					id: accommodation._id,
					deposit: accommodation.deposit
				})
			})
			.then(res => res.json())
			.then(ret => {
				if (ret !== false)
				{
					let accommodations = (this.state.accommodations) ? tools.clone(this.state.accommodations) : []
					for (let i = 0; i < accommodations.length; i++)
					{
						if (accommodations[i]._id === ret._id)
						{
							accommodations[i] = ret
							break
						}
					}
					this.setState({ accommodations: accommodations })
				}
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	updateAnimation = (animation) => {
		if (!animation._id || !animation.category || (animation.front === true && !animation.cover) || !animation.translations ||
			(!animation.service_benefit && animation.service_benefit !== null))
		{
			return new Promise((resolve, reject) => {
				console.log("miss argument in update animation", animation)
				reject(false)
			})
		}
		console.log("update animation", animation)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'webapp/animations/update', {
				method: 'POST',
				body: JSON.stringify({
					id: animation._id,
					category: animation.category,
					cover: animation.cover,
					front: animation.front,
					translations: animation.translations,
					service_benefit: animation.service_benefit
				})
			})
			.then(res => res.json())
			.then(ret => {
				if (ret !== false)
				{
					let animations = (this.state.animations) ? tools.clone(this.state.animations) : []
					for (let i = 0; i < animations.length; i++)
					{
						if (animations[i]._id === ret._id)
						{
							animations[i] = ret
							break
						}
					}
					this.setState({ animations: animations })
				}
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}


	updateAnimationCategory = (category) => {
		if (!category._id || !category.color || !category.translations)
		{
			return new Promise((resolve, reject) => {
				console.log("miss argument in update animation category", category)
				reject(false)
			})
		}
		console.log("update animation category", category)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'webapp/animation-categories/update', {
				method: 'POST',
				body: JSON.stringify({
					id: category._id,
					color: category.color,
					translations: category.translations
				})
			})
			.then(res => res.json())
			.then(ret => {
				if (ret !== false)
				{
					let animation_categories = (this.state.animation_categories) ? tools.clone(this.state.animation_categories) : []
					for (let i = 0; i < animation_categories.length; i++)
					{
						if (animation_categories[i]._id === ret._id)
						{
							animation_categories[i] = ret
							break
						}
					}
					this.setState({ animation_categories: animation_categories })
				}
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	updateCategoriesOrder = (categories) => {
		if (!categories)
		{
			return new Promise((resolve, reject) => {
				console.log("miss argument in update categories order", categories)
				reject(false)
			})
		}
		console.log("update categories order", categories)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'webapp/categories/order', {
				method: 'POST',
				body: JSON.stringify({
					categories: categories
				})
			})
			.then(res => res.json())
			.then(ret => {
				if (ret !== false)
				{
					let categories = (this.state.categories) ? tools.clone(this.state.categories) : []
					for (let i = 0; i < categories.length; i++)
					{
						for (let j = 0; j < ret.length; j++)
						{
							if (categories[i]._id === ret[j]._id)
							{
								categories[i] = ret[j]
								break
							}
						}
					}
					this.setState({ categories: categories })
				}
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	updateCategory = (category) => {
		if (!category._id || !category.icon || !category.type || !category.group || !category.translations)
		{
			return new Promise((resolve, reject) => {
				console.log("miss argument in update category", category)
				reject(false)
			})
		}
		console.log("update category", category)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'webapp/categories/update', {
				method: 'POST',
				body: JSON.stringify({
					id: category._id,
					icon: category.icon,
					type: category.type,
					group: category.group,
					translations: category.translations
				})
			})
			.then(res => res.json())
			.then(ret => {
				if (ret !== false)
				{
					let categories = (this.state.categories) ? tools.clone(this.state.categories) : []
					for (let i = 0; i < categories.length; i++)
					{
						if (categories[i]._id === ret._id)
						{
							categories[i] = ret
							break
						}
					}
					this.setState({ categories: categories })
				}
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	updateCategoryDuplicate = (category) => {
		if (!category._id)
		{
			return new Promise((resolve, reject) => {
				console.log("miss argument in update duplicate category", category)
				reject(false)
			})
		}
		console.log("update duplicate category", category)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'webapp/categories/duplicate', {
				method: 'POST',
				body: JSON.stringify({
					id: category._id
				})
			})
			.then(res => res.json())
			.then(ret => {
				if (ret !== false)
				{
					let categories = (this.state.categories) ? tools.clone(this.state.categories) : []
					categories.push(ret)
					this.setState({ categories: categories })
				}
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	updateConfig = (config) => {
		if ((!config.sendmail && config.sendmail !== false) || !config.establishment || !config.nbdays ||
			!config.langs || (!config.background && config.background !== null) || !config.welcome)
		{
			return new Promise((resolve, reject) => {
				console.log("miss argument in update config", config)
				reject(false)
			})
		}
		let langs = []
		if (config.langs.fr === true)
			langs.push('fr')
		if (config.langs.en === true)
			langs.push('en')
		if (config.langs.de === true)
			langs.push('de')
		if (config.langs.nl === true)
			langs.push('nl')
		if (config.langs.it === true)
			langs.push('it')
		if (config.langs.es === true)
			langs.push('es')
		if (config.langs.pt === true)
			langs.push('pt')
		return new Promise((resolve, reject) => {
			console.log("update config")
			fetch(connexion.connect.url + 'dashboard/config/global/update', {
				method: 'POST',
				body: JSON.stringify({
					sendmail: config.sendmail,
					establishment: config.establishment,
					nbdays: config.nbdays,
					langs: langs,
					background: config.background,
					welcome: config.welcome
				})
			})
			.then(res => res.json())
			.then(config => {
				let establishment = ''
				let langs = null
				let nbdays = 0
				let sendmail = false
				let background = null
				let welcome = []
				for (let i = 0; i < config.length; i++)
				{
					if (config[i].key === "establishment")
						establishment = config[i].value
					else if (config[i].key === "langs")
						langs = config[i].value
					else if (config[i].key === "send-mail-cron")
						sendmail = config[i].value
					else if (config[i].key === "nb-days-mail-cron")
						nbdays = config[i].value.join(', ')
					else if (config[i].key === "background-img")
						background = config[i].value
					else if (config[i].key === "welcome-txt")
						welcome = config[i].value
				}
				this.setState({
					establishment: establishment,
					langs: langs,
					emails_nbdays: nbdays,
					emails_activate: sendmail,
					background_img: background,
					welcome_txt: welcome
				})
				resolve(config)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	updateConfigDashboard = (config) => {
		if (!config.config_pms || !config.features || !config.config_emails)
		{
			return new Promise((resolve, reject) => {
				console.log("miss argument in update config dashboard", config)
				reject(false)
			})
		}
		return new Promise((resolve, reject) => {
			console.log("update config dashboard", config)
			fetch(connexion.connect.url + 'dashboard/config/admin/update', {
				method: 'POST',
				body: JSON.stringify({
					config_pms: config.config_pms,
					features: config.features,
					active_connexion: config.active_connexion,
					application_ouverte: config.application_ouverte,
					show_stay_dates: config.show_stay_dates,
					show_stay_location: config.show_stay_location,
					config_emails: config.config_emails
				})
			})
			.then(res => res.json())
			.then(config => {
				this.setState({
					config_pms: config.config_pms,
					features: config.features
				})
				resolve(config)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	updateConfigDeposit = (config) => {
		if ((!config.activate && config.activate !== false) || (!config.amount && config.amount !== 0) ||
			!config.userId || !config.secret_swikly)
		{
			return new Promise((resolve, reject) => {
				console.log("miss argument in update config deposit", config)
				reject(false)
			})
		}
		return new Promise((resolve, reject) => {
			console.log("update config deposit", config)
			fetch(connexion.connect.url + 'webapp/config/deposit/update', {
				method: 'POST',
				body: JSON.stringify({
					activate: config.activate,
					amount: config.amount,
					userId: config.userId,
					secret_swikly: config.secret_swikly
				})
			})
			.then(res => res.json())
			.then(config => {
				this.setState({ config_deposit: config })
				resolve(config)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	updateConfigInventory = (config) => {
		if ((!config.arrival && config.arrival !== false) || (!config.departure && config.departure !== false) ||
			(!config.delay && config.delay !== 0) || !config.translations ||
			(!config.arrival_staff && config.arrival_staff !== false) || (!config.arrival_user && config.arrival_user !== false) ||
			(!config.departure_staff && config.departure_staff !== false) || (!config.departure_user && config.departure_user !== false))
		{
			return new Promise((resolve, reject) => {
				console.log("miss argument in update config inventory", config)
				reject(false)
			})
		}
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'webapp/config/inventory/update', {
				method: 'POST',
				body: JSON.stringify({
					arrival: config.arrival,
					departure: config.departure,
					delay: config.delay,
					translations: config.translations,
					arrival_staff: config.arrival_staff,
					arrival_user: config.arrival_user,
					departure_staff: config.departure_staff,
					departure_user: config.departure_user
				})
			})
			.then(res => res.json())
			.then(config => {
				this.setState({ config_inventory: config })
				resolve(config)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	updateEmail = (email) => {
		if (!email._id || !email.name || !email.translations)
		{
			return new Promise((resolve, reject) => {
				console.log("miss argument in update email", email)
				reject(false)
			})
		}
		console.log("update email", email)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'dashboard/emails/update', {
				method: 'POST',
				body: JSON.stringify({
					id: email._id,
					name: email.name,
					translations: email.translations
				})
			})
			.then(res => res.json())
			.then(ret => {
				if (ret !== false)
				{
					let emails = (this.state.emails) ? tools.clone(this.state.emails) : []
					for (let i = 0; i < emails.length; i++)
					{
						if (emails[i]._id === ret._id)
						{
							emails[i] = ret
							break
						}
					}
					this.setState({ emails: emails })
				}
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	updateGroup = (group) => {
		if (!group._id || !group.translations || !group.icon)
		{
			return new Promise((resolve, reject) => {
				console.log("miss argument in update group", group)
				reject(false)
			})
		}
		console.log("update group", group)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'webapp/groups/update', {
				method: 'POST',
				body: JSON.stringify({
					id: group._id,
					translations: group.translations,
					icon: group.icon
				})
			})
			.then(res => res.json())
			.then(ret => {
				if (ret !== false)
				{
					let groups = (this.state.groups) ? tools.clone(this.state.groups) : []
					for (let i = 0; i < groups.length; i++)
					{
						if (groups[i]._id === ret._id)
						{
							groups[i] = ret
							break
						}
					}
					this.setState({ groups: groups })
				}
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	updateInventoryCategory = (category) => {
		if (!category.accommodation || !category.translations || (!category.id && category.id !== 0))
		{
			return new Promise((resolve, reject) => {
				console.log("miss argument in update inventory category", category)
				reject(false)
			})
		}
		console.log("update inventory category", category)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'dashboard/accommodations/update-category', {
				method: 'POST',
				body: JSON.stringify({
					accommodation: category.accommodation,
					translations: category.translations,
					id: category.id
				})
			})
			.then(res => res.json())
			.then(ret => {
				if (ret !== false)
				{
					let accommodations = (this.state.accommodations) ? tools.clone(this.state.accommodations) : []
					for (let i = 0; i < accommodations.length; i++)
					{
						if (accommodations[i]._id === ret._id)
						{
							accommodations[i] = ret
							break
						}
					}
					this.setState({ accommodations: accommodations })
				}
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	updateInventoryComment = (inventory) => {
		if (!inventory.stay_number || !inventory.type || !inventory.comment)
		{
			return new Promise((resolve, reject) => {
				console.log("miss argument in update inventory comments", inventory)
				reject(false)
			})
		}
		console.log("update inventory comments", inventory)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'dashboard/inventories/update-comments', {
				method: 'POST',
				body: JSON.stringify({
					inventory: inventory.inventory,
					stay_number: inventory.stay_number,
					type: inventory.type,
					comment: inventory.comment
				})
			})
			.then(res => res.json())
			.then(ret => {
				if (ret !== false)
				{
					let find = false
					let inventories = (this.state.inventories) ? tools.clone(this.state.inventories) : []
					for (let i = 0; i < inventories.length; i++)
					{
						if (inventories[i]._id === ret._id)
						{
							find = true
							inventories[i] = ret
							break
						}
					}
					if (!find)
						inventories.push(ret)
					this.setState({ inventories: inventories })
				}
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	updateInventoryPictures = (inventory) => {
		if (!inventory.stay_number || !inventory.type || !inventory.name || !inventory.cover)
		{
			return new Promise((resolve, reject) => {
				console.log("miss argument in update inventory pictures", inventory)
				reject(false)
			})
		}
		console.log("update inventory pictures", inventory)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'dashboard/inventories/update-pictures', {
				method: 'POST',
				body: JSON.stringify({
					inventory: inventory.inventory,
					stay_number: inventory.stay_number,
					type: inventory.type,
					name: inventory.name,
					cover: inventory.cover
				})
			})
			.then(res => res.json())
			.then(ret => {
				if (ret !== false)
				{
					let find = false
					let inventories = (this.state.inventories) ? tools.clone(this.state.inventories) : []
					for (let i = 0; i < inventories.length; i++)
					{
						if (inventories[i]._id === ret._id)
						{
							find = true
							inventories[i] = ret
							break
						}
					}
					if (!find)
						inventories.push(ret)
					this.setState({ inventories: inventories })
				}
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	updateListCover = (list) => {
		if (!list._id || !list.cover)
		{
			return new Promise((resolve, reject) => {
				console.log("miss argument in update list cover", list)
				reject(false)
			})
		}
		console.log("update list cover", list)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'webapp/lists/update-cover', {
				method: 'POST',
				body: JSON.stringify({
					id: list._id,
					cover: list.cover
				})
			})
			.then(res => res.json())
			.then(ret => {
				if (ret !== false)
				{
					let lists = (this.state.lists) ? tools.clone(this.state.lists) : []
					for (let i = 0; i < lists.length; i++)
					{
						if (lists[i]._id === ret._id)
						{
							lists[i] = ret
							break
						}
					}
					this.setState({ lists: lists })
				}
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	updateListElement = (element) => {
		if (!element.list_id || (!element.key && element.key !== 0) || !element.icon || !element.translations)
		{
			return new Promise((resolve, reject) => {
				console.log("miss argument in update list element", element)
				reject(false)
			})
		}
		console.log("update list element", element)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'webapp/lists/update-element', {
				method: 'POST',
				body: JSON.stringify({
					id: element.list_id,
					key: element.key,
					icon: element.icon,
					translations: element.translations
				})
			})
			.then(res => res.json())
			.then(ret => {
				if (ret !== false)
				{
					let lists = (this.state.lists) ? tools.clone(this.state.lists) : []
					for (let i = 0; i < lists.length; i++)
					{
						if (lists[i]._id === ret._id)
						{
							lists[i] = ret
							break
						}
					}
					this.setState({ lists: lists })
				}
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	updateListOrderElements = (list) => {
		if (!list._id || !list.elements)
		{
			return new Promise((resolve, reject) => {
				console.log("miss argument in update list order elements", list)
				reject(false)
			})
		}
		console.log("update list order elements", list)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'webapp/lists/order-elements', {
				method: 'POST',
				body: JSON.stringify({
					id: list._id,
					elements: list.elements
				})
			})
			.then(res => res.json())
			.then(ret => {
				if (ret !== false)
				{
					let lists = (this.state.lists) ? tools.clone(this.state.lists) : []
					for (let i = 0; i < lists.length; i++)
					{
						if (lists[i]._id === ret._id)
						{
							lists[i] = ret
							break
						}
					}
					this.setState({ lists: lists })
				}
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	updateListTransformSubcategory = (list, page) => {
		if (!list._id || !page)
		{
			return new Promise((resolve, reject) => {
				console.log("miss argument in update list transform subcategory", list, page)
				reject(false)
			})
		}
		console.log("update list transform subcategory", list, page)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'webapp/lists/transform-subcategory', {
				method: 'POST',
				body: JSON.stringify({
					id: list._id,
					elements: list.elements,
					page: page
				})
			})
			.then(res => res.json())
			.then(ret => {
				if (ret !== false)
				{
					const list = ret.list
					const category = ret.category
					let categories = (this.state.categories) ? tools.clone(this.state.categories) : []
					categories.push(category)
					let lists = (this.state.lists) ? tools.clone(this.state.lists) : []
					for (let i = 0; i < lists.length; i++)
					{
						if (lists[i]._id === list._id)
						{
							lists[i] = list
							break
						}
					}
					this.setState({ lists: lists, categories: categories })
				}
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	updateModule = (module) => {
		if (!module._id || !module.name || !module.type || !module.values || !module.translations)
		{
			return new Promise((resolve, reject) => {
				console.log("miss argument in update module", module)
				reject(false)
			})
		}
		console.log("update module", module)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'webapp/modules/update', {
				method: 'POST',
				body: JSON.stringify({
					id: module._id,
					name: module.name,
					type: module.type,
					values: module.values,
					translations: module.translations
				})
			})
			.then(res => res.json())
			.then(ret => {
				if (ret !== false)
				{
					let modules = (this.state.modules) ? tools.clone(this.state.modules) : []
					for (let i = 0; i < modules.length; i++)
					{
						if (modules[i]._id === ret._id)
						{
							modules[i] = ret
							break
						}
					}
					this.setState({ modules: modules })
				}
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	updateModulesOrder = (modules) => {
		if (!modules || modules.length === 0)
		{
			return new Promise((resolve, reject) => {
				console.log("miss argument in update module", module)
				reject(false)
			})
		}
		console.log("update module", module)
		for (let i = 0; i < modules.length; i++)
			modules[i].order = i
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'webapp/modules/order', {
				method: 'POST',
				body: JSON.stringify({
					modules: modules
				})
			})
			.then(res => res.json())
			.then(modules => {
				if (modules !== false)
					this.setState({ modules: modules })
				resolve(modules)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	updateNotification = (notification) => {
		if (!notification._id || !notification.name || !notification.redirect || !notification.translations || !notification.user)
		{
			return new Promise((resolve, reject) => {
				console.log("miss argument in update notification", notification)
				reject(false)
			})
		}
		console.log("update notification", notification)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'dashboard/notifications/update', {
				method: 'POST',
				body: JSON.stringify({
					id: notification._id,
					name: notification.name,
					redirect: JSON.parse(notification.redirect),
					translations: notification.translations,
					user: notification.user
				})
			})
			.then(res => res.json())
			.then(ret => {
				if (ret !== false)
				{
					let notifications = (this.state.notifications) ? tools.clone(this.state.notifications) : []
					for (let i = 0; i < notifications.length; i++)
					{
						if (notifications[i]._id === ret._id)
						{
							notifications[i] = ret
							break
						}
					}
					this.setState({ notifications: notifications })
				}
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	updatePageCover = (page) => {
		if (!page._id || !page.cover)
		{
			return new Promise((resolve, reject) => {
				console.log("miss argument in update page cover", page)
				reject(false)
			})
		}
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'webapp/pages/update-cover', {
				method: 'POST',
				body: JSON.stringify({
					id: page._id,
					cover: page.cover
				})
			})
			.then(res => res.json())
			.then(ret => {
				if (ret !== false)
				{
					let pages = (this.state.pages) ? tools.clone(this.state.pages) : []
					for (let i = 0; i < pages.length; i++)
					{
						if (pages[i]._id === ret._id)
						{
							pages[i] = ret
							break
						}
					}
					this.setState({ pages: pages })
				}
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	updatePageDuplicateSection = (section) => {
		if (!section.page_id || (!section.key && section.key !== 0))
		{
			return new Promise((resolve, reject) => {
				console.log("miss argument in update page duplicate section", section)
				reject(false)
			})
		}
		console.log("update page section", section)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'webapp/pages/update-duplicate-section', {
				method: 'POST',
				body: JSON.stringify({
					id: section.page_id,
					key: section.key
				})
			})
			.then(res => res.json())
			.then(ret => {
				if (ret !== false)
				{
					let pages = (this.state.pages) ? tools.clone(this.state.pages) : []
					for (let i = 0; i < pages.length; i++)
					{
						if (pages[i]._id === ret._id)
						{
							pages[i] = ret
							break
						}
					}
					this.setState({ pages: pages })
				}
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	updatePageName = (page) => {
		if (!page._id || !page.translations)
		{
			return new Promise((resolve, reject) => {
				console.log("miss argument in update page cover", page)
				reject(false)
			})
		}
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'webapp/pages/update-name', {
				method: 'POST',
				body: JSON.stringify({
					id: page._id,
					translations: page.translations
				})
			})
			.then(res => res.json())
			.then(ret => {
				if (ret !== false)
				{
					let pages = (this.state.pages) ? tools.clone(this.state.pages) : []
					for (let i = 0; i < pages.length; i++)
					{
						if (pages[i]._id === ret._id)
						{
							pages[i] = ret
							break
						}
					}
					this.setState({ pages: pages })
				}
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	updatePageOrderSections = (page) => {
		if (!page._id || !page.sections)
		{
			return new Promise((resolve, reject) => {
				console.log("miss argument in update page order sections", page)
				reject(false)
			})
		}
		console.log("update page order sections", page)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'webapp/pages/order-sections', {
				method: 'POST',
				body: JSON.stringify({
					id: page._id,
					sections: page.sections
				})
			})
			.then(res => res.json())
			.then(ret => {
				if (ret !== false)
				{
					let pages = (this.state.pages) ? tools.clone(this.state.pages) : []
					for (let i = 0; i < pages.length; i++)
					{
						if (pages[i]._id === ret._id)
						{
							pages[i] = ret
							break
						}
					}
					this.setState({ pages: pages })
				}
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	updatePageSection = (section) => {
		if (!section.page_id || !section.icon || !section.translations || (!section.key && section.key !== 0) ||
			(!section.phone && section.phone.length !== 0) || (!section.email && section.email.length !== 0) ||
			(!section.website && section.website.length !== 0) || (!section.localisation && section.localisation.length !== 0) ||
			(!section.facebook && section.facebook.length !== 0) || (!section.instagram && section.instagram.length !== 0))
		{
			return new Promise((resolve, reject) => {
				console.log("miss argument in update page section", section)
				reject(false)
			})
		}
		console.log("update page section", section)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'webapp/pages/update-section', {
				method: 'POST',
				body: JSON.stringify({
					id: section.page_id,
					icon: section.icon,
					phone: section.phone,
					email: section.email,
					website: section.website,
					localisation: section.localisation,
					facebook: section.facebook,
					instagram: section.instagram,
					translations: section.translations,
					key: section.key
				})
			})
			.then(res => res.json())
			.then(ret => {
				if (ret !== false)
				{
					let pages = (this.state.pages) ? tools.clone(this.state.pages) : []
					for (let i = 0; i < pages.length; i++)
					{
						if (pages[i]._id === ret._id)
						{
							pages[i] = ret
							break
						}
					}
					this.setState({ pages: pages })
				}
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	updatePageService = (service) => {
		if (!service.page_id || (!service.service && !service.link) || !service.translations)
		{
			return new Promise((resolve, reject) => {
				console.log("miss argument in update page service", service)
				reject(false)
			})
		}
		console.log("update page service", service)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'webapp/pages/update-service', {
				method: 'POST',
				body: JSON.stringify({
					id: service.page_id,
					service: service.service,
					link: service.link,
					translations: service.translations
				})
			})
			.then(res => res.json())
			.then(ret => {
				if (ret !== false)
				{
					let pages = (this.state.pages) ? tools.clone(this.state.pages) : []
					for (let i = 0; i < pages.length; i++)
					{
						if (pages[i]._id === ret._id)
						{
							pages[i] = ret
							break
						}
					}
					this.setState({ pages: pages })
				}
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	updateReviewModel = (review_model) => {
		if (!review_model._id || !review_model.name || !review_model.questions || !review_model.user || (!review_model.type && review_model.type !== "") ||
			!review_model.translations || (!review_model.notification_time && review_model.notification_time !== 0))
		{
			return new Promise((resolve, reject) => {
				console.log("miss argument in update review_model", review_model)
				reject(false)
			})
		}
		console.log("update review_model", review_model)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'dashboard/review_models/update', {
				method: 'POST',
				body: JSON.stringify({
					id: review_model._id,
					name: review_model.name,
					type: review_model.type,
					notification_time: review_model.notification_time,
					questions: review_model.questions,
					translations: review_model.translations,
					user: review_model.user
				})
			})
			.then(res => res.json())
			.then(ret => {
				if (ret !== false && !ret.err_code)
				{
					let review_models = (this.state.review_models) ? tools.clone(this.state.review_models) : []
					for (let i = 0; i < review_models.length; i++)
					{
						if (review_models[i]._id === ret._id)
						{
							review_models[i] = ret
							break
						}
					}
					this.setState({ review_models: review_models })
				}
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	updateService = (service) => {
		if (!service._id || !service.cover || !service.name || !service.type || !service.translations ||
			(!service.list_mode && service.list_mode !== false) || (!service.comment && service.comment !== null) || !service.user)
		{
			return new Promise((resolve, reject) => {
				console.log("miss argument in update service", service)
				reject(false)
			})
		}
		console.log("update service", service)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'dashboard/services/update', {
				method: 'POST',
				body: JSON.stringify({
					id: service._id,
					cover: service.cover,
					name: service.name,
					type: service.type,
					translations: service.translations,
					list_mode: service.list_mode,
					user: service.user
				})
			})
			.then(res => res.json())
			.then(ret => {
				if (ret !== false)
				{
					let services = (this.state.services) ? tools.clone(this.state.services) : []
					for (let i = 0; i < services.length; i++)
					{
						if (services[i]._id === ret._id)
						{
							services[i] = ret
							break
						}
					}
					this.setState({ services: services })
				}
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	updateServiceBenefit = (service_benefit) => {
		if (!service_benefit._id || !service_benefit.service || !service_benefit.open_dates || !service_benefit.open_hours || (!service_benefit.simultaneous && service_benefit.simultaneous !== null) || !service_benefit.icon || (!service_benefit.price && service_benefit.price !== 0) ||
			(!service_benefit.duration_hour && service_benefit.duration_hour !== 0) || (!service_benefit.duration_minute && service_benefit.duration_minute !== 0) ||
			(!service_benefit.confirmation && service_benefit.confirmation !== false) || !service_benefit.translations || !service_benefit.questions ||
			(!service_benefit.client_number && service_benefit.client_number !== false) || (!service_benefit.identity && service_benefit.identity !== false) ||
			(!service_benefit.age && service_benefit.age !== false) || (!service_benefit.phone && service_benefit.phone !== false) ||
			(!service_benefit.interval_hour && service_benefit.interval_hour !== 0) || (!service_benefit.show_price && service_benefit.show_price !== false) || !service_benefit.user)
		{
			return new Promise((resolve, reject) => {
				console.log("miss argument in update service_benefit", service_benefit)
				reject(false)
			})
		}
		console.log("update service_benefit cover", service_benefit)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'dashboard/service_benefits/update', {
				method: 'POST',
				body: JSON.stringify({
					id: service_benefit._id,
					service: service_benefit.service,
					icon: service_benefit.icon,
					show_price: service_benefit.show_price,
					price: service_benefit.price,
					duration_hour: service_benefit.duration_hour,
					duration_minute: service_benefit.duration_minute,
					interval_hour: service_benefit.interval_hour,
					interval_minute: service_benefit.interval_minute,
					confirmation: service_benefit.confirmation,
					simultaneous: service_benefit.simultaneous,
					open_hours: service_benefit.open_hours,
					open_dates: service_benefit.open_dates,
					translations: service_benefit.translations,
					client_number: service_benefit.client_number,
					identity: service_benefit.identity,
					age: service_benefit.age,
					phone: service_benefit.phone,
					questions: service_benefit.questions,
					user: service_benefit.user
				})
			})
			.then(res => res.json())
			.then(ret => {
				if (ret !== false)
				{
					let service_benefits = (this.state.service_benefits) ? tools.clone(this.state.service_benefits) : []
					for (let i = 0; i < service_benefits.length; i++)
					{
						if (service_benefits[i]._id === ret._id)
						{
							service_benefits[i] = ret
							break
						}
					}
					this.setState({ service_benefits: service_benefits })
				}
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	updateServiceDuration = (service) => {
		if (!service.id || !service.open_dates || !service.open_hours ||
			(!service.duration_hour && service.duration_hour !== 0) ||
			(!service.duration_minute && service.duration_minute !== 0) ||
			(!service.interval_minute && service.interval_minute !== 0) ||
			(!service.interval_hour && service.interval_hour !== 0) ||
			(!service.limit_end && service.limit_end !== 0))
		{
			return new Promise((resolve, reject) => {
				console.log("miss argument in update service duration", service)
				reject(false)
			})
		}
		console.log("update service duration", service)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'dashboard/services/update-duration', {
				method: 'POST',
				body: JSON.stringify({
					id: service.id,
					open_dates: service.open_dates,
					open_hours: service.open_hours,
					duration_hour: service.duration_hour,
					duration_minute: service.duration_minute,
					interval_hour: service.interval_hour,
					interval_minute: service.interval_minute,
					limit_end: service.limit_end,
					translations: (service.translations) ? service.translations : null
				})
			})
			.then(res => res.json())
			.then(ret => {
				if (ret !== false)
				{
					let services = (this.state.services) ? tools.clone(this.state.services) : []
					for (let i = 0; i < services.length; i++)
					{
						if (services[i]._id === ret._id)
						{
							services[i] = ret
							break
						}
					}
					this.setState({ services: services })
				}
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	updateServiceProduct = (service_product) => {
		if (!service_product._id || !service_product.service || !service_product.icon || (!service_product.price && service_product.price !== 0) ||
			(!service_product.stock && service_product.stock !== null) || !service_product.translations || !service_product.user)
		{
			return new Promise((resolve, reject) => {
				console.log("miss argument in update service_product", service_product)
				reject(false)
			})
		}
		console.log("update service_product cover", service_product)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'dashboard/service_products/update', {
				method: 'POST',
				body: JSON.stringify({
					id: service_product._id,
					service: service_product.service,
					icon: service_product.icon,
					price: service_product.price,
					stock: service_product.stock,
					confirmation: service_product.confirmation,
					simultaneous: service_product.simultaneous,
					translations: service_product.translations,
					client_number: service_product.client_number,
					identity: service_product.identity,
					age: service_product.age,
					phone: service_product.phone,
					questions: service_product.questions,
					user: service_product.user
				})
			})
			.then(res => res.json())
			.then(ret => {
				if (ret !== false)
				{
					let service_products = (this.state.service_products) ? tools.clone(this.state.service_products) : []
					for (let i = 0; i < service_products.length; i++)
					{
						if (service_products[i]._id === ret._id)
						{
							service_products[i] = ret
							break
						}
					}
					this.setState({ service_products: service_products })
				}
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	updateServiceWorker = (service_worker) => {
		if (!service_worker._id || !service_worker.service || !service_worker.firstname || !service_worker.lastname || !service_worker.working_dates ||
			!service_worker.working_hours || !service_worker.services || !service_worker.user)
		{
			return new Promise((resolve, reject) => {
				console.log("miss argument in update service_worker", service_worker)
				reject(false)
			})
		}
		console.log("update service_worker cover", service_worker)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'dashboard/service_workers/update', {
				method: 'POST',
				body: JSON.stringify({
					id: service_worker._id,
					service: service_worker.service,
					firstname: service_worker.firstname,
					lastname: service_worker.lastname,
					working_dates: service_worker.working_dates,
					working_hours: service_worker.working_hours,
					services: service_worker.services,
					user: service_worker.user
				})
			})
			.then(res => res.json())
			.then(ret => {
				if (ret !== false)
				{
					let service_workers = (this.state.service_workers) ? tools.clone(this.state.service_workers) : []
					for (let i = 0; i < service_workers.length; i++)
					{
						if (service_workers[i]._id === ret._id)
						{
							service_workers[i] = ret
							break
						}
					}
					this.setState({ service_workers: service_workers })
				}
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	/*
	** DELETE METHODS
	*/

	deleteAccommodation = (id) => {
		if (!id)
		{
			return new Promise((resolve, reject) => {
				console.log("miss ID in delete accommodation", id)
				reject(false)
			})
		}
		console.log("delete accommodation", id)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'dashboard/accommodations/delete', {
				method: 'POST',
				body: JSON.stringify({
					id: id
				})
			})
			.then(res => res.json())
			.then(ret => {
				if (ret !== false)
				{
					let accommodations = (this.state.accommodations) ? tools.clone(this.state.accommodations) : []
					for (let i = 0; i < accommodations.length; i++)
					{
						if (accommodations[i]._id === id)
						{
							accommodations.splice(i, 1)
							break
						}
					}
					this.setState({ accommodations: accommodations })
				}
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	deleteAnimation = (id) => {
		if (!id)
		{
			return new Promise((resolve, reject) => {
				console.log("miss ID in delete animation", id)
				reject(false)
			})
		}
		console.log("delete animation", id)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'webapp/animation-dates/delete-by-animation', {
				method: 'POST',
				body: JSON.stringify({
					animation: id
				})
			})
			.then(res => res.json())
			.then(ret => {
				if (ret === true)
				{
					let i = 0
					let dates = (this.state.animation_dates) ? tools.clone(this.state.animation_dates) : []
					while (i < dates.length)
					{
						if (dates[i].animation === id)
							dates.splice(i, 1)
						else
							i++
					}
					this.setState({ animation_dates: dates })
					fetch(connexion.connect.url + 'webapp/animations/delete', {
						method: 'POST',
						body: JSON.stringify({
							id: id
						})
					})
					.then(res => res.json())
					.then(ret => {
						if (ret !== false)
						{
							let animations = (this.state.animations) ? tools.clone(this.state.animations) : []
							for (let i = 0; i < animations.length; i++)
							{
								if (animations[i]._id === id)
								{
									animations.splice(i, 1)
									break
								}
							}
							this.setState({ animations: animations })
						}
						resolve(ret)
					})
					.catch(err => {
						reject(err)
					})
				}
				else
					reject("could not delete dates by animation")
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	deleteAnimationCategory = (id) => {
		if (!id)
		{
			return new Promise((resolve, reject) => {
				console.log("miss ID in delete animation category", id)
				reject(false)
			})
		}
		console.log("delete animation category", id)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'webapp/animation-categories/delete', {
				method: 'POST',
				body: JSON.stringify({
					id: id
				})
			})
			.then(res => res.json())
			.then(ret => {
				if (ret !== false)
				{
					let animation_categories = (this.state.animation_categories) ? tools.clone(this.state.animation_categories) : []
					for (let i = 0; i < animation_categories.length; i++)
					{
						if (animation_categories[i]._id === id)
						{
							animation_categories.splice(i, 1)
							break
						}
					}
					this.setState({ animation_categories: animation_categories })
				}
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	deleteAnimationDate = (id) => {
		if (!id)
		{
			return new Promise((resolve, reject) => {
				console.log("miss ID in delete animation dates", id)
				reject(false)
			})
		}
		console.log("delete animation dates", id)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'webapp/animation-dates/delete', {
				method: 'POST',
				body: JSON.stringify({
					id: id
				})
			})
			.then(res => res.json())
			.then(ret => {
				if (ret !== false)
				{
					let animation_dates = (this.state.animation_dates) ? tools.clone(this.state.animation_dates) : []
					for (let i = 0; i < animation_dates.length; i++)
					{
						if (animation_dates[i]._id === id)
						{
							animation_dates.splice(i, 1)
							break
						}
					}
					this.setState({ animation_dates: animation_dates })
				}
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	deleteCategory = (id) => {
		if (!id)
		{
			return new Promise((resolve, reject) => {
				console.log("miss ID in delete category", id)
				reject(false)
			})
		}
		console.log("delete category", id)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'webapp/categories/delete', {
				method: 'POST',
				body: JSON.stringify({
					id: id
				})
			})
			.then(res => res.json())
			.then(ret => {
				if (ret !== false)
				{
					let categories = (this.state.categories) ? tools.clone(this.state.categories) : []
					for (let i = 0; i < categories.length; i++)
					{
						if (categories[i]._id === id)
						{
							categories.splice(i, 1)
							break
						}
					}
					this.setState({ categories: categories })
				}
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	deleteEmail = (id) => {
		if (!id)
		{
			return new Promise((resolve, reject) => {
				console.log("miss ID in delete email", id)
				reject(false)
			})
		}
		console.log("delete email", id)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'dashboard/emails/delete', {
				method: 'POST',
				body: JSON.stringify({
					id: id
				})
			})
			.then(res => res.json())
			.then(ret => {
				if (ret !== false)
				{
					let emails = (this.state.emails) ? tools.clone(this.state.emails) : []
					for (let i = 0; i < emails.length; i++)
					{
						if (emails[i]._id === id)
						{
							emails.splice(i, 1)
							break
						}
					}
					this.setState({ emails: emails })
				}
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	deleteGroup = (id) => {
		if (!id)
		{
			return new Promise((resolve, reject) => {
				console.log("miss ID in delete group", id)
				reject(false)
			})
		}
		console.log("delete group", id)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'webapp/groups/delete', {
				method: 'POST',
				body: JSON.stringify({
					id: id
				})
			})
			.then(res => res.json())
			.then(ret => {
				if (ret !== false)
				{
					let groups = (this.state.groups) ? tools.clone(this.state.groups) : []
					for (let i = 0; i < groups.length; i++)
					{
						if (groups[i]._id === id)
						{
							groups.splice(i, 1)
							break
						}
					}
					this.setState({ groups: groups })
				}
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	deleteInventoryCategory = (category) => {
		if ((!category.id && category.id !== 0) || !category.accommodation)
		{
			return new Promise((resolve, reject) => {
				console.log("miss argument in delete inventory category", category)
				reject(false)
			})
		}
		console.log("delete inventory category", category.id)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'dashboard/accommodations/delete-category', {
				method: 'POST',
				body: JSON.stringify({
					id: category.id,
					accommodation: category.accommodation
				})
			})
			.then(res => res.json())
			.then(ret => {
				if (ret !== false)
				{
					let accommodations = (this.state.accommodations) ? tools.clone(this.state.accommodations) : []
					for (let i = 0; i < accommodations.length; i++)
					{
						if (accommodations[i]._id === ret._id)
						{
							accommodations[i] = ret
							break
						}
					}
					this.setState({ accommodations: accommodations })
				}
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	deleteInventoryPicture = (inventory) => {
		if ((!inventory.picture && inventory.picture !== 0) || !inventory.inventory)
		{
			return new Promise((resolve, reject) => {
				console.log("miss argument in delete inventory picture", inventory)
				reject(false)
			})
		}
		console.log("delete inventory inventory", inventory.id)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'dashboard/inventories/delete-picture', {
				method: 'POST',
				body: JSON.stringify({
					picture: inventory.picture,
					inventory: inventory.inventory
				})
			})
			.then(res => res.json())
			.then(ret => {
				if (ret !== false)
				{
					let inventories = (this.state.inventories) ? tools.clone(this.state.inventories) : []
					for (let i = 0; i < inventories.length; i++)
					{
						if (inventories[i]._id === ret._id)
						{
							inventories[i] = ret
							break
						}
					}
					this.setState({ inventories: inventories })
				}
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	deleteListByCategory = (id) => {
		if (!id)
		{
			return new Promise((resolve, reject) => {
				console.log("miss ID in delete list", id)
				reject(false)
			})
		}
		console.log("delete list by category", id)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'webapp/lists/delete-by-category', {
				method: 'POST',
				body: JSON.stringify({
					category: id
				})
			})
			.then(res => res.json())
			.then(ret => {
				if (ret !== false)
				{
					let lists = (this.state.lists) ? tools.clone(this.state.lists) : []
					for (let i = 0; i < lists.length; i++)
					{
						if (lists[i]._id === id)
						{
							lists.splice(i, 1)
							break
						}
					}
					this.setState({ lists: lists })
				}
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	deleteListElement = (element) => {
		if (!element.list_id || (!element.key && element.key !== 0))
		{
			return new Promise((resolve, reject) => {
				console.log("miss argument in delete list element", element)
				reject(false)
			})
		}
		console.log("delete list element", element)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'webapp/lists/delete-element', {
				method: 'POST',
				body: JSON.stringify({
					id: element.list_id,
					key: element.key
				})
			})
			.then(res => res.json())
			.then(ret => {
				if (ret !== false)
				{
					let lists = (this.state.lists) ? tools.clone(this.state.lists) : []
					for (let i = 0; i < lists.length; i++)
					{
						if (lists[i]._id === ret._id)
						{
							lists[i] = ret
							break
						}
					}
					this.setState({ lists: lists })
				}
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	deleteModule = (id) => {
		if (!id)
		{
			return new Promise((resolve, reject) => {
				console.log("miss ID in delete module", id)
				reject(false)
			})
		}
		console.log("delete module", id)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'webapp/modules/delete', {
				method: 'POST',
				body: JSON.stringify({
					id: id
				})
			})
			.then(res => res.json())
			.then(ret => {
				if (ret !== false)
				{
					let modules = (this.state.modules) ? tools.clone(this.state.modules) : []
					for (let i = 0; i < modules.length; i++)
					{
						if (modules[i]._id === id)
						{
							modules.splice(i, 1)
							break
						}
					}
					this.setState({ modules: modules })
				}
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	deleteNotification = (id) => {
		if (!id)
		{
			return new Promise((resolve, reject) => {
				console.log("miss ID in delete notification", id)
				reject(false)
			})
		}
		console.log("delete notification", id)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'dashboard/notifications/delete', {
				method: 'POST',
				body: JSON.stringify({
					id: id
				})
			})
			.then(res => res.json())
			.then(ret => {
				if (ret !== false)
				{
					let notifications = (this.state.notifications) ? tools.clone(this.state.notifications) : []
					for (let i = 0; i < notifications.length; i++)
					{
						if (notifications[i]._id === id)
						{
							notifications.splice(i, 1)
							break
						}
					}
					this.setState({ notifications: notifications })
				}
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	deletePage = (id) => {
		if (!id)
		{
			return new Promise((resolve, reject) => {
				console.log("miss ID in delete page", id)
				reject(false)
			})
		}
		console.log("delete page", id)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'webapp/pages/delete', {
				method: 'POST',
				body: JSON.stringify({
					id: id
				})
			})
			.then(res => res.json())
			.then(ret => {
				if (ret !== false)
				{
					let pages = (this.state.pages) ? tools.clone(this.state.pages) : []
					for (let i = 0; i < pages.length; i++)
					{
						if (pages[i]._id === id)
						{
							pages.splice(i, 1)
							break
						}
					}
					this.setState({ pages: pages })
				}
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	deletePageByCategory = (id) => {
		if (!id)
		{
			return new Promise((resolve, reject) => {
				console.log("miss ID in delete page", id)
				reject(false)
			})
		}
		console.log("delete page by category", id)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'webapp/pages/delete-by-category', {
				method: 'POST',
				body: JSON.stringify({
					category: id
				})
			})
			.then(res => res.json())
			.then(ret => {
				if (ret !== false)
				{
					let pages = (this.state.pages) ? tools.clone(this.state.pages) : []
					for (let i = 0; i < pages.length; i++)
					{
						if (pages[i]._id === id)
						{
							pages.splice(i, 1)
							break
						}
					}
					this.setState({ pages: pages })
				}
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	deletePageSection = (section) => {
		if (!section.page_id || (!section.key && section.key !== 0))
		{
			return new Promise((resolve, reject) => {
				console.log("miss argument in delete page section", section)
				reject(false)
			})
		}
		console.log("delete page section", section)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'webapp/pages/delete-section', {
				method: 'POST',
				body: JSON.stringify({
					id: section.page_id,
					key: section.key
				})
			})
			.then(res => res.json())
			.then(ret => {
				if (ret !== false)
				{
					let pages = (this.state.pages) ? tools.clone(this.state.pages) : []
					for (let i = 0; i < pages.length; i++)
					{
						if (pages[i]._id === ret._id)
						{
							pages[i] = ret
							break
						}
					}
					this.setState({ pages: pages })
				}
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	deletePageService = (service) => {
		if (!service.page_id)
		{
			return new Promise((resolve, reject) => {
				console.log("miss argument in delete page service", service)
				reject(false)
			})
		}
		console.log("delete page service", service)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'webapp/pages/delete-service', {
				method: 'POST',
				body: JSON.stringify({
					id: service.page_id
				})
			})
			.then(res => res.json())
			.then(ret => {
				if (ret !== false)
				{
					let pages = (this.state.pages) ? tools.clone(this.state.pages) : []
					for (let i = 0; i < pages.length; i++)
					{
						if (pages[i]._id === ret._id)
						{
							pages[i] = ret
							break
						}
					}
					this.setState({ pages: pages })
				}
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	deletePdf = (id) => {
		if (!id)
		{
			return new Promise((resolve, reject) => {
				console.log("miss ID in delete pdf", id)
				reject(false)
			})
		}
		console.log("delete pdf", id)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'dashboard/files/delete-pdf', {
				method: 'POST',
				body: JSON.stringify({
					id: id
				})
			})
			.then(res => res.json())
			.then(ret => {
				if (ret !== false)
				{
					let files = (this.state.files) ? tools.clone(this.state.files) : []
					for (let i = 0; i < files.length; i++)
					{
						if (files[i]._id === id)
						{
							files.splice(i, 1)
							break
						}
					}
					this.setState({ files: files })
				}
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	deletePicture = (id) => {
		if (!id)
		{
			return new Promise((resolve, reject) => {
				console.log("miss ID in delete picture", id)
				reject(false)
			})
		}
		console.log("delete picture", id)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'dashboard/files/delete-image', {
				method: 'POST',
				body: JSON.stringify({
					id: id
				})
			})
			.then(res => res.json())
			.then(ret => {
				if (ret !== false)
				{
					let files = (this.state.files) ? tools.clone(this.state.files) : []
					for (let i = 0; i < files.length; i++)
					{
						if (files[i]._id === id)
						{
							files.splice(i, 1)
							break
						}
					}
					this.setState({ files: files })
				}
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	deleteReviewModel = (id) => {
		if (!id)
		{
			return new Promise((resolve, reject) => {
				console.log("miss ID in delete review_model", id)
				reject(false)
			})
		}
		console.log("delete review_model", id)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'dashboard/review_models/delete', {
				method: 'POST',
				body: JSON.stringify({
					id: id
				})
			})
			.then(res => res.json())
			.then(ret => {
				if (ret !== false)
				{
					let review_models = (this.state.review_models) ? tools.clone(this.state.review_models) : []
					for (let i = 0; i < review_models.length; i++)
					{
						if (review_models[i]._id === id)
						{
							review_models.splice(i, 1)
							break
						}
					}
					this.setState({ review_models: review_models })
				}
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	deleteService = (id) => {
		if (!id)
		{
			return new Promise((resolve, reject) => {
				console.log("miss ID in delete service", id)
				reject(false)
			})
		}
		console.log("delete service", id)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'dashboard/services/delete', {
				method: 'POST',
				body: JSON.stringify({
					id: id
				})
			})
			.then(res => res.json())
			.then(ret => {
				if (ret !== false)
				{
					let services = (this.state.services) ? tools.clone(this.state.services) : []
					for (let i = 0; i < services.length; i++)
					{
						if (services[i]._id === id)
						{
							services.splice(i, 1)
							break
						}
					}
					this.setState({ services: services })
				}
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	deleteServiceBenefit = (id) => {
		if (!id)
		{
			return new Promise((resolve, reject) => {
				console.log("miss ID in delete service_benefit", id)
				reject(false)
			})
		}
		console.log("delete service_benefit", id)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'dashboard/service_benefits/delete', {
				method: 'POST',
				body: JSON.stringify({
					id: id
				})
			})
			.then(res => res.json())
			.then(ret => {
				if (ret !== false)
				{
					let service_benefits = (this.state.service_benefits) ? tools.clone(this.state.service_benefits) : []
					for (let i = 0; i < service_benefits.length; i++)
					{
						if (service_benefits[i]._id === id)
						{
							service_benefits.splice(i, 1)
							break
						}
					}
					this.setState({ service_benefits: service_benefits })
				}
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	deleteServiceBenefitsByService = (service) => {
		if (!service)
		{
			return new Promise((resolve, reject) => {
				console.log("miss service in delete service_benefit", service)
				reject(false)
			})
		}
		console.log("delete service benefits by service", service)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'dashboard/service_benefits/delete-by-service', {
				method: 'POST',
				body: JSON.stringify({
					service: service
				})
			})
			.then(res => res.json())
			.then(ret => {
				if (ret !== false)
				{
					let service_benefits = (this.state.service_benefits) ? tools.clone(this.state.service_benefits) : []
					let i = 0
					while (i < service_benefits.length)
					{
						if (service_benefits[i].service === service)
						{
							service_benefits.splice(i, 1)
							continue
						}
						i++
					}
					this.setState({ service_benefits: service_benefits })
				}
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	deleteServiceProduct = (id) => {
		if (!id)
		{
			return new Promise((resolve, reject) => {
				console.log("miss ID in delete service_product", id)
				reject(false)
			})
		}
		console.log("delete service_product", id)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'dashboard/service_products/delete', {
				method: 'POST',
				body: JSON.stringify({
					id: id
				})
			})
			.then(res => res.json())
			.then(ret => {
				if (ret !== false)
				{
					let service_products = (this.state.service_products) ? tools.clone(this.state.service_products) : []
					for (let i = 0; i < service_products.length; i++)
					{
						if (service_products[i]._id === id)
						{
							service_products.splice(i, 1)
							break
						}
					}
					this.setState({ service_products: service_products })
				}
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	deleteServiceReservation = (id) => {
		if (!id)
		{
			return new Promise((resolve, reject) => {
				console.log("miss ID in delete service_reservation", id)
				reject(false)
			})
		}
		console.log("delete service_reservation", id)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'webapp/service_reservations/delete', {
				method: 'POST',
				body: JSON.stringify({
					id: id
				})
			})
			.then(res => res.json())
			.then(ret => {
				if (ret !== false)
				{
					let service_reservations = (this.state.service_reservations) ? tools.clone(this.state.service_reservations) : []
					for (let i = 0; i < service_reservations.length; i++)
					{
						if (service_reservations[i]._id === id)
						{
							service_reservations.splice(i, 1)
							break
						}
					}
					this.setState({ service_reservations: service_reservations })
				}
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	deleteServiceWorker = (id) => {
		if (!id)
		{
			return new Promise((resolve, reject) => {
				console.log("miss ID in delete service_worker", id)
				reject(false)
			})
		}
		console.log("delete service_worker", id)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'dashboard/service_workers/delete', {
				method: 'POST',
				body: JSON.stringify({
					id: id
				})
			})
			.then(res => res.json())
			.then(ret => {
				if (ret !== false)
				{
					let service_workers = (this.state.service_workers) ? tools.clone(this.state.service_workers) : []
					for (let i = 0; i < service_workers.length; i++)
					{
						if (service_workers[i]._id === id)
						{
							service_workers.splice(i, 1)
							break
						}
					}
					this.setState({ service_workers: service_workers })
				}
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	deleteServiceWorkersByService = (service) => {
		if (!service)
		{
			return new Promise((resolve, reject) => {
				console.log("miss service in delete service_worker", service)
				reject(false)
			})
		}
		console.log("delete service workers by service", service)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'dashboard/service_workers/delete-by-service', {
				method: 'POST',
				body: JSON.stringify({
					service: service
				})
			})
			.then(res => res.json())
			.then(ret => {
				if (ret !== false)
				{
					let service_workers = (this.state.service_workers) ? tools.clone(this.state.service_workers) : []
					let i = 0
					while (i < service_workers.length)
					{
						if (service_workers[i].service === service)
						{
							service_workers.splice(i, 1)
							continue
						}
						i++
					}
					this.setState({ service_workers: service_workers })
				}
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	/*
	** OTHERS METHODS
	*/

	postExtractReviews = (review_id_list) => {
		if (!review_id_list)
		{
			return new Promise((resolve, reject) => {
				console.log("miss id_list in post extract reviews", review_id_list)
				reject(false)
			})
		}
		console.log("post extract reviews", review_id_list)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'webapp/reviews/extract_reviews.csv', {
				method: 'POST',
				body: JSON.stringify({
					review_id_list: review_id_list
				})
			})
			.then(res => res.json())
			.then(ret => {
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})

	}

	sendEmail = (email) => {
		if (!email._id || !email.destinataire || !email.lang)
		{
			return new Promise((resolve, reject) => {
				console.log("miss argument in send email", email)
				reject(false)
			})
		}
		console.log("send email", email)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'dashboard/emails/test', {
				method: 'POST',
				body: JSON.stringify({
					id: email._id,
					lang: email.lang,
					destinataire: email.destinataire,
					expediteur: email.expediteur,
					nom: email.nom,
					prenom: email.prenom,
					code: email.code,
					lien: email.lien
				})
			})
			.then(res => res.json())
			.then(ret => {
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	sendNotifications = (notification) => {
		if ((!notification.user_list && notification.user_list !== null) || !notification.translations || !notification.redirect)
		{
			return new Promise((resolve, reject) => {
				console.log("miss argument in send notification", notification)
				reject(false)
			})
		}
		console.log("send notification", notification)
		return new Promise((resolve, reject) => {
			fetch(connexion.connect.url + 'webapp/notifications/send', {
				method: 'POST',
				body: JSON.stringify({
					id: notification._id,
					translations: notification.translations,
					user_list: notification.user_list,
					redirect: notification.redirect
				})
			})
			.then(res => res.json())
			.then(ret => {
				resolve(ret)
			})
			.catch(err => {
				reject(err)
			})
		})
	}

	updateMenu = (menu) => {
		this.setState({ menu: menu })
	}

	updateUser = (user, userOnly = false) => {
		if (user === null)
		{
			Cookies.remove('session-dashboard')
			this.setState({ user: null, session: null })
		}
		else
		{
			if (userOnly === false)
			{
				Cookies.set('session-dashboard', user.session, { sameSite: 'strict' })
				this.setState({ user: user, session: user.session })
			}
			else
				this.setState({ user: user })
		}
	}

	updateNavbarTitle = (title) => {
		this.fetchVersion()
		this.setState({ navbar: title })
	}

	render() {
		if (this.state.session === undefined || !this.state.features === undefined || !this.state.lang_default || !this.state.langs)
		{
			return (
				<div className="loader">
					<Loader />
				</div>
			)
		}
		else if (this.state.session === null)
		{
			return (
				<div className="App">
					<Signin updateUser={this.updateUser} />
				</div>
			)
		}
		else
		{
			return (
				<div className="App">
					<div className="row">
						<SidebarTailwind user={this.state.user} updateUser={this.updateUser} title={this.state.navbar} features={this.state.features}/>
						<div className="py-6">
							<div className="max-w-fit lg:ml-56 px-4 sm:px-6 md:px-8 Main">
								<div className="row">
									<Main
										// config
										config_deposit={this.state.config_deposit}
										config_inventory={this.state.config_inventory}
										config_pms={this.state.config_pms}
										establishment={this.state.establishment}
										emails_activate={this.state.emails_activate}
										emails_nbdays={this.state.emails_nbdays}
										features={this.state.features}
										langs={this.state.langs}
										lang_default={this.state.lang_default}
										webapp_connexion={this.state.webapp_connexion}

										// properties
										accommodations={this.state.accommodations}
										animations={this.state.animations}
										animationDates={this.state.animation_dates}
										animationCategories={this.state.animation_categories}
										background_img={this.state.background_img}
										categories={this.state.categories}
										clients={this.state.clients}
										cron_notifications={this.state.cron_notifications}
										conversations={this.state.conversations}
										dashboard_config={this.state.dashboard_config}
										dashboard_users={this.state.dashboard_users}
										deposits={this.state.deposits}
										emails={this.state.emails}
										files={this.state.files}
										groups={this.state.groups}
										inventories={this.state.inventories}
										lists={this.state.lists}
										menu={this.state.menu}
										modules={this.state.modules}
										messages={this.state.messages}
										notifications={this.state.notifications}
										pages={this.state.pages}
										review_models={this.state.review_models}
										reviews={this.state.reviews}
										service_benefits={this.state.service_benefits}
										service_products={this.state.service_products}
										service_workers={this.state.service_workers}
										service_reservations={this.state.service_reservations}
										services={this.state.services}
										subscriptions={this.state.subscriptions}
										user={this.state.user}
										webapp_config={this.state.webapp_config}
										webapp_users={this.state.webapp_users}
										welcome_txt={this.state.welcome_txt}

										// delete methods
										deleteAccommodation={this.deleteAccommodation}
										deleteAnimation={this.deleteAnimation}
										deleteAnimationCategory={this.deleteAnimationCategory}
										deleteAnimationDate={this.deleteAnimationDate}
										deleteCategory={this.deleteCategory}
										deleteEmail={this.deleteEmail}
										deleteGroup={this.deleteGroup}
										deleteInventoryCategory={this.deleteInventoryCategory}
										deleteInventoryPicture={this.deleteInventoryPicture}
										deleteListByCategory={this.deleteListByCategory}
										deleteListElement={this.deleteListElement}
										deleteModule={this.deleteModule}
										deleteNotification={this.deleteNotification}
										deletePage={this.deletePage}
										deletePageByCategory={this.deletePageByCategory}
										deletePageSection={this.deletePageSection}
										deletePageService={this.deletePageService}
										deletePdf={this.deletePdf}
										deletePicture={this.deletePicture}
										deleteReviewModel={this.deleteReviewModel}
										deleteService={this.deleteService}
										deleteServiceBenefit={this.deleteServiceBenefit}
										deleteServiceBenefitsByService={this.deleteServiceBenefitsByService}
										deleteServiceProduct={this.deleteServiceProduct}
										deleteServiceReservation={this.deleteServiceReservation}
										deleteServiceWorker={this.deleteServiceWorker}
										deleteServiceWorkersByService={this.deleteServiceWorkersByService}

										// insert methods
										insertAccommodation={this.insertAccommodation}
										insertAccommodationDuplicate={this.insertAccommodationDuplicate}
										insertAnimation={this.insertAnimation}
										insertAnimationCategory={this.insertAnimationCategory}
										insertAnimationDates={this.insertAnimationDates}
										insertCategory={this.insertCategory}
										insertCronNotification={this.insertCronNotification}
										insertEmail={this.insertEmail}
										insertGroup={this.insertGroup}
										insertInventory={this.insertInventory}
										insertInventoryCategory={this.insertInventoryCategory}
										insertInventoryObjects={this.insertInventoryObjects}
										insertList={this.insertList}
										insertListElement={this.insertListElement}
										insertModule={this.insertModule}
										insertNotification={this.insertNotification}
										insertPage={this.insertPage}
										insertPageSection={this.insertPageSection}
										insertPdf={this.insertPdf}
										insertPicture={this.insertPicture}
										insertReviewModel={this.insertReviewModel}
										insertRoomlist={this.insertRoomlist}
										insertService={this.insertService}
										insertServiceBenefit={this.insertServiceBenefit}
										insertServiceProduct={this.insertServiceProduct}
										insertServiceReservation={this.insertServiceReservation}
										insertServiceWorker={this.insertServiceWorker}

										// update methods
										updateAccommodation={this.updateAccommodation}
										updateAccommodationDeposit={this.updateAccommodationDeposit}
										updateAnimation={this.updateAnimation}
										updateAnimationCategory={this.updateAnimationCategory}
										updateCategoriesOrder={this.updateCategoriesOrder}
										updateCategory={this.updateCategory}
										updateCategoryDuplicate={this.updateCategoryDuplicate}
										updateConfig={this.updateConfig}
										updateConfigDashboard={this.updateConfigDashboard}
										updateConfigDeposit={this.updateConfigDeposit}
										updateConfigInventory={this.updateConfigInventory}
										updateEmail={this.updateEmail}
										updateGroup={this.updateGroup}
										updateInventoryCategory={this.updateInventoryCategory}
										updateInventoryComment={this.updateInventoryComment}
										updateInventoryPictures={this.updateInventoryPictures}
										updateListCover={this.updateListCover}
										updateListElement={this.updateListElement}
										updateListOrderElements={this.updateListOrderElements}
										updateListTransformSubcategory={this.updateListTransformSubcategory}
										updateModule={this.updateModule}
										updateModulesOrder={this.updateModulesOrder}
										updateMenu={this.updateMenu}
										updateNotification={this.updateNotification}
										updatePageCover={this.updatePageCover}
										updatePageName={this.updatePageName}
										updatePageOrderSections={this.updatePageOrderSections}
										updatePageDuplicateSection={this.updatePageDuplicateSection}
										updatePageSection={this.updatePageSection}
										updatePageService={this.updatePageService}
										updateReviewModel={this.updateReviewModel}
										updateService={this.updateService}
										updateServiceBenefit={this.updateServiceBenefit}
										updateServiceDuration={this.updateServiceDuration}
										updateServiceProduct={this.updateServiceProduct}
										updateServiceWorker={this.updateServiceWorker}

										// fetch methods
										fetchAccommodations={this.fetchAccommodations}
										fetchAccommodationByID={this.fetchAccommodationByID}
										fetchAnimations={this.fetchAnimations}
										fetchAnimationByID={this.fetchAnimationByID}
										fetchAnimationDates={this.fetchAnimationDates}
										fetchAnimationCategories={this.fetchAnimationCategories}
										fetchCategories={this.fetchCategories}
										fetchCategoriesByGroup={this.fetchCategoriesByGroup}
										fetchClients={this.fetchClients}
										fetchConfigDeposit={this.fetchConfigDeposit}
										fetchConfigInventory={this.fetchConfigInventory}
										fetchConfigPms={this.fetchConfigPms}
										fetchConversations={this.fetchConversations}
										fetchDashboardConfig={this.fetchDashboardConfig}
										fetchDashboardUsers={this.fetchDashboardUsers}
										fetchDeposits={this.fetchDeposits}
										fetchEmails={this.fetchEmails}
										fetchFiles={this.fetchFiles}
										fetchGroups={this.fetchGroups}
										fetchIcons={this.fetchIcons}
										fetchInventories={this.fetchInventories}
										fetchInventoryBySearch={this.fetchInventoryBySearch}
										fetchInventoryByStayNb={this.fetchInventoryByStayNb}
										fetchListByCategory={this.fetchListByCategory}
										fetchLists={this.fetchLists}
										// fetchMenu={this.fetchMenu}
										fetchModules={this.fetchModules}
										fetchMessagesByConversation={this.fetchMessagesByConversation}
										fetchNotifications={this.fetchNotifications}
										fetchNotificationSubscriptions={this.fetchNotificationSubscriptions}
										fetchPageByID={this.fetchPageByID}
										fetchPageByCategory={this.fetchPageByCategory}
										fetchPages={this.fetchPages}
										fetchReviewModels={this.fetchReviewModels}
										fetchReviewsByType={this.fetchReviewsByType}
										fetchServiceBenefits={this.fetchServiceBenefits}
										fetchServiceBenefitsByService={this.fetchServiceBenefitsByService}
										fetchServiceProductsByService={this.fetchServiceProductsByService}
										fetchServiceReservationsByService={this.fetchServiceReservationsByService}
										fetchServiceWorkers={this.fetchServiceWorkers}
										fetchServiceWorkersByService={this.fetchServiceWorkersByService}
										fetchServices={this.fetchServices}
										fetchTheme={this.fetchTheme}
										fetchWebappConfig={this.fetchWebappConfig}
										fetchWebappUsers={this.fetchWebappUsers}

										// others
										postExtractReviews={this.postExtractReviews}
										sendEmail={this.sendEmail}
										sendNotifications={this.sendNotifications}
										updateUser={this.updateUser}
										updateNavbarTitle={this.updateNavbarTitle}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			)
		}
	}
}

export default App


import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import Modal from '../UI/Modal'
import Loader from '../UI/Loader'
import LangSelector from '../UI/LangSelector'
import M from 'materialize-css'
import tools from '../../tools'
import '../Core/Main.css'
import EmptyFolder from '../UI/EmptyFolder'

class Categories extends Component {
	constructor(props) {
		super(props)
		this.state = {
			category: null,
			categories: null,
			edit: false,
			modal_delete: false,
			modal_edit: false,
			modal_msg: null,
			delete: null,
			attempt_submit: false,
			form1: {
				color: '',
				translations: []
			},
			current_lang: this.props.lang_default,
			error: this._initErrors(),
			success: null
		}
		this.length_max = 40
		this.props.updateNavbarTitle('Animations')
	}

	componentDidMount() {
		this.loadAnimationCategories()
	}

	componentDidUpdate(prevProps) {
		const elems = document.querySelectorAll('select')
		M.FormSelect.init(elems)
		if (prevProps.categories !== this.props.categories)
			this.loadAnimationCategories(true)
	}

	loadAnimationCategories = (fromProps = false) => {
		if (fromProps === true)
			this.setState({ categories: this.props.categories })
		else
		{
			this.props.fetchAnimationCategories()
			.then(categories => {
				this.setState({ categories: categories })
			})
			.catch(err => {
				this.setErrorMsg("error_fetch_categories", 1)
				console.log(err)
			})
		}
	}

	setErrorMsg = (key, msg, id = null) => {
		if (key === "error_create_category")
		{
			key = "global"
			msg = tools.default_error_msg("Impossible de créer une catégorie", msg)
		}
		else if (key === "error_update_category")
		{
			key = "global"
			msg = tools.default_error_msg("Impossible de mettre à jour une catégorie", msg)
		}
		else if (key === "error_delete_category")
		{
			key = "global"
			msg = tools.default_error_msg("Impossible de supprimer une catégorie", msg)
		}
		else if (key === "error_fetch_categories")
		{
			key = "global"
			msg = tools.default_error_msg("Impossible de récupérer les catégories", msg)
		}
		let error = this._initErrors()
		if (id !== null)
			error[key][id] = msg
		else
			error[key] = msg
		this.setState({ success: null, error: error })
	}

	setSuccessMsg = (msg) => {
		this.setState({ error: this._initErrors(), success: msg })
	}

	clearFlash = () => {
		this.setState({ error: this._initErrors(), success: null })
	}

	_initErrors = () => {
		return {
			global: null,
			name: null,
			color: null
		}
	}

	clickCreate = () => {
		const form1 = {
			color: '',
			translations: []
		}
		this.setState({ form1: form1, edit: true })
	}

	clickEdit = (id) => {
		let category = null
		let form1 = {
			color: '',
			translations: []
		}
		const categories = this.state.categories
		for (let i = 0; i < categories.length; i++)
		{
			if (categories[i]._id === id)
			{
				category = categories[i]
				form1 = {
					color: category.color,
					translations: category.translations
				}
				break
			}
		}
		this.setState({ form1: form1, category: category, edit: true })
	}

	_validateForm1 = () => {
		let error = false
		let error_obj = this._initErrors()
		const color = this.state.form1.color.toUpperCase()
		const translations = this.state.form1.translations
		if (color.length === 0)
		{
			error = true
			error_obj.color = "Veuillez indiquer la couleur"
		}
		else if (!/^#?[0-9A-F]{6}$/.test(color))
		{
			error = true
			error_obj.color = "Veuillez indiquer un code couleur valide"
		}
		let found_default_name = false
		for (let i = 0; i < translations.length; i++)
		{
			const lang = translations[i].lang
			const name = translations[i].name
			if (name.length === 0 && lang === this.props.lang_default)
			{
				error = true
				error_obj.name = "Vous devez indiquer le titre (version " + lang.toUpperCase() + ")"
			}
			else if (name.length > this.length_max)
			{
				error = true
				error_obj.name = "Le titre ne doit pas dépasser " + this.length_max + " caractères (version " + lang.toUpperCase() + ")"
			}
			else if (lang === this.props.lang_default)
				found_default_name = true
		}
		if (!found_default_name && !error_obj.name)
		{
			error = true
			error_obj.name = 'Vous devez indiquer le titre (version ' + this.props.lang_default.toUpperCase() + ')'
		}
		if (error === true)
		{
			error_obj.global = "Il y a une ou plusieurs erreur(s) dans le formulaire"
			this.setState({ success: null, error: error_obj, attempt_submit: false })
		}
		return (!error) ? true : false
	}

	submitCategory = (event) => {
		event.preventDefault()
		this.setState({ attempt_submit: true })
		if (this._validateForm1())
		{
			const translations = this.state.form1.translations
			let langs_not_found = []
			for (let i = 0; i < this.props.langs.length; i++)
			{
				let found = false
				for (let j = 0; j < translations.length; j++)
				{
					if (this.props.langs[i] === translations[j].lang && translations[j].name.length > 0)
					{
						found = true
						break
					}
				}
				if (!found)
					langs_not_found.push(this.props.langs[i])
			}
			if (langs_not_found.length > 0)
			{
				let msg = ""
				for (let i = 0; i < langs_not_found.length; i++)
				{
					if (i > 0)
						msg += ', '
					msg += langs_not_found[i].toUpperCase()
				}
				this.setState({ modal_msg: msg, modal_edit: true })
			}
			else
				this._insertCategory()
		}
	}

	_insertCategory = () => {
		let color = this.state.form1.color
		const translations = this.state.form1.translations
		if (this.state.category === null)
		{
			if (color.substring(0, 1) === '#')
				color = color.substring(1, 7)
			const category = {
				color: color,
				translations: translations
			}
			this.props.insertAnimationCategory(category)
			.then(ret => {
				if (ret === false)
				{
					this.setState({ attempt_submit: false, modal_edit: false })
					this.setErrorMsg('error_create_category', 1)
				}
				else
				{
					const form1 = {
						color: '',
						translations: []
					}
					this.setState({ form1: form1, edit: false, attempt_submit: false, modal_edit: false })
					this.setSuccessMsg("La catégorie a bien été créée")
				}
			})
			.catch(err => {
				this.setState({ attempt_submit: false, modal_edit: false })
				this.setErrorMsg('error_create_category', 2)
				console.log(err)
			})
		}
		else
		{
			const category = {
				_id: this.state.category._id,
				color: color,
				translations: translations
			}
			this.props.updateAnimationCategory(category)
			.then(ret => {
				if (ret === false)
				{
					this.setState({ attempt_submit: false, modal_edit: false })
					this.setErrorMsg('error_update_category', 1)
				}
				else
				{
					const form1 = {
						color: '',
						translations: []
					}
					this.setState({ form1: form1, category: null, edit: false, attempt_submit: false, modal_edit: false })
					this.setSuccessMsg("La catégorie a bien été modifiée")
				}
			})
			.catch(err => {
				this.setState({ attempt_submit: false, modal_edit: false })
				this.setErrorMsg('error_update_category', 2)
				console.log(err)
			})
		}
	}

	clickDelete = (id) => {
		this.setState({ delete: id, modal_delete: true })
	}

	_deleteCategory = () => {
		const categories = this.state.categories
		let category = null
		for (let i = 0; i < categories.length; i++)
		{
			if (categories[i]._id === this.state.delete)
			{
				category = categories[i]
				break
			}
		}
		if (category !== null)
		{
			this.props.deleteAnimationCategory(this.state.delete)
			.then(ret => {
				if (ret === true)
				{
					const form1 = {
						name: '',
						color: ''
					}
					this.setState({ form1: form1, modal_delete: false })
					this.setSuccessMsg("La catégorie a bien été supprimée")
				}
				else
				{
					this.setState({ modal_delete: false })
					this.setErrorMsg('error_delete_category', 1)
				}
			})
			.catch(err => {
				this.setState({ modal_delete: false })
				this.setErrorMsg('error_delete_category', 2)
				console.log(err)
			})
		}
	}

	handleName = (event) => {
		let found = false
		const translations = this.state.form1.translations
		for (let i = 0; i < translations.length; i++)
		{
			if (translations[i].lang === this.state.current_lang)
			{
				found = true
				translations[i].name = event.target.value
				break
			}
		}
		if (!found)
		{
			translations.push({
				lang: this.state.current_lang,
				name: event.target.value
			})
		}
		const form1 = {
			...this.state.form1,
			translations: translations
		}
		this.setState({ form1: form1 })
	}

	handleColor = (event) => {
		const form1 = {
			...this.state.form1,
			color: event.target.value
		}
		this.setState({ form1: form1 })
	}

	answerModalEdit = (answer) => {
		if (answer === "yes")
			this._insertCategory()
		else
			this.setState({ attempt_submit: false, modal_edit: false })
	}

	answerModalDelete = (answer) => {
		if (answer === "yes")
			this._deleteCategory()
		else
			this.setState({ delete: false, modal_delete: false })
	}

	unEdit = () => {
		this.clearFlash()
		this.setState({ edit: false })
	}

	updateLang = (lang) => {
		this.setState({ current_lang: lang })
	}

	render() {
		const categories = this.state.categories
		const permissions = this.props.user.permissions
		if (!permissions || !permissions.activities || permissions.activities === 0)
			return (<Redirect to="/" />)
		else if (categories === null)
		{
			return (
				<div className="loader">
					<Loader />
				</div>
			)
		}
		else if (this.state.edit === true)
		{
			let titleHeader = 'Créer une catégorie d\'activité'
			let btn = 'Enregistrer'
			let deletebtn = null
			if (this.state.category !== null)
			{
				titleHeader = 'Modifier une catégorie d\'activité'
				btn = 'Éditer'
				deletebtn = (<button type="button" className="waves-effect waves-light btn-small red" onClick={this.deleteCategory}>Supprimer</button>)
			}
			let name = ""
			for (let i = 0; i < this.state.form1.translations.length; i++)
			{
				if (this.state.form1.translations[i].lang === this.state.current_lang)
				{
					name = this.state.form1.translations[i].name
					break
				}
			}
			let sendbtn = (
				<div>
					<button type="submit" className="waves-effect waves-light btn-small">{btn}</button>
					{deletebtn}
				</div>
			)
			if (this.state.attempt_submit === true)
			{
				sendbtn = (
					<div className="center">
						<Loader />
					</div>
				)
			}
			const modal_msg = "La catégorie d'activité n'est pas traduite dans les langues suivantes : " + this.state.modal_msg
			return (
				<div className="wrapper-content">
					<Modal
						dismissible={false}
						content={modal_msg}
						open_modal={this.state.modal_edit}
						answerModal={this.answerModalEdit}
					/>
					<div className="row">
						<div className="module col s12">
							<p className="error center">{this.state.error.global}</p>
							<h2><span className="uhover" onClick={this.unEdit}>Animations</span> > {titleHeader}</h2>
							<LangSelector lang={this.state.current_lang} updateLang={this.updateLang} />
							<form className="module-form" onSubmit={this.submitCategory}>
								<label>
									<p className="error">{this.state.error.name}</p>
									<p className="column-20">Titre (version {this.state.current_lang.toUpperCase()}) :</p>
									<input type="text" value={name} onChange={this.handleName} />
								</label>
								<label>
									<p className="error">{this.state.error.color}</p>
									<p className="column-20">Couleur :</p>
									<input type="text" value={this.state.form1.color} onChange={this.handleColor} />
								</label>
								{sendbtn}
							</form>
						</div>
					</div>
				</div>
			)
		}
		else
		{
			const modal_msg = "Vous êtes sur le point de supprimer cette catégorie d'activité. Êtes-vous sûr ?"
			let category_k = -1
			let empty_folder = ""
			if (!categories || !categories.length)
			{
				empty_folder = (
					<EmptyFolder 
						emptyFolderSubtitle="Pas de catégories d'activités paramétrées"
					/>
				)
			}
			return (
				<div className="wrapper-content">
					<Modal
						dismissible={false}
						content={modal_msg}
						open_modal={this.state.modal_delete}
						answerModal={this.answerModalDelete}
					/>
					<div className="row">
						<div className="module col s12">
							<p className="error center">{this.state.error.global}</p>
							<p className="success center">{this.state.success}</p>
							<button type="button" className="waves-effect waves-light btn-large" onClick={this.clickCreate}>Créer une catégorie d'activité</button>
							<LangSelector lang={this.state.current_lang} updateLang={this.updateLang} />
							{empty_folder}
							{categories.map(({_id, color, translations}) => {
								let name = ""
								for (let i = 0; i < translations.length; i++)
								{
									if (translations[i].lang === this.state.current_lang)
									{
										name = translations[i].name
										break
									}
								}
								category_k++
								return (
									<div className="object" key={category_k}>
										<div className="title"><span className="pastille" style={{ backgroundColor: '#' + color }}></span><p onClick={this.clickEdit.bind(this, _id)}>{name}</p></div>
										<div className="buttons">
											<button type="button" className="waves-effect waves-light btn red" onClick={this.clickDelete.bind(this, _id)}>Supprimer</button>
										</div>
										<div className="clear"></div>
									</div>
								)
							})}
						</div>
					</div>
				</div>
			)
		}
	}
}

export default Categories

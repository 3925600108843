import React, { Component } from 'react'
import { Switch } from '@headlessui/react'
import { Link } from 'react-router-dom'
import { HomeIcon } from '@heroicons/react/outline'
import tools from '../../tools'
// import { Fragment } from 'react'
// import { Dialog, Transition, Menu } from '@headlessui/react'
// import { PencilIcon, DotsVerticalIcon, ExclamationCircleIcon } from '@heroicons/react/solid'
import Loader from '../UI/Loader'
import BannerInfo from '../UI/BannerInfo'

class DepositParam extends Component {
	constructor(props)
	{
		super(props)
		this.state = {
			config_deposit: null,
			attempt_submit: false,
			accommodation: null,
			accommodations: null,
			form1: {
				activate: false,
				amount: '',
				secret_swikly: '',
				userId: ''
			},
			sucess: null,
			error: this._initErrors(),
			banner_content: null,
			show_banner: false
		}
		this.length_swikly_max = 16
		this.length_intern_max = 32
		this.pages = [
			{ name: 'Cautions', href: '/deposit/users-deposit', current: false },
		]
	}

	componentDidMount() {
		this.loadConfigDeposit()
		this.loadAccommodations()
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.accommodations !== this.props.accommodations)
			this.loadAccommodations(true)
		if (prevProps.config_deposit !== this.props.config_deposit)
			this.loadConfigDeposit(true)
	}

	loadConfigDeposit = (fromProps = false) => {
		if (fromProps === true)
		{
			const form1 = {
				activate: this.props.config_deposit.activate,
				amount: this.props.config_deposit.amount,
				secret_swikly: (this.props.config_deposit.secret_swikly) ? this.props.config_deposit.secret_swikly : '',
				userId: (this.props.config_deposit.userId) ? this.props.config_deposit.userId : ''
			}
			this.setState({ config_deposit: this.props.config_deposit, form1: form1 })
		}
		else
		{
			this.props.fetchConfigDeposit()
			.then(config_deposit => {
				const form1 = {
					activate: config_deposit.activate,
					amount: config_deposit.amount,
					secret_swikly: (config_deposit.secret_swikly) ? config_deposit.secret_swikly : '',
					userId: (config_deposit.userId) ? config_deposit.userId : ''
				}
				this.setState({ config_deposit: config_deposit, form1: form1 })
			})
			.catch(err => {
				this.setErrorMsg("error_fetch_config", 1)
				console.log(err)
			})
		}
	}

	loadAccommodations = (fromProps = false) => {
		if (fromProps === true)
		{
			let accommodations = tools.clone(this.props.accommodations)
			accommodations.sort((a, b) => {
				return a.name.localeCompare(b.name)
			})
			this.setState({ accommodations: accommodations })
		}
		else
		{
			this.props.fetchAccommodations()
			.then(accommodations => {
				accommodations.sort((a, b) => {
					return a.name.localeCompare(b.name)
				})
				this.setState({ accommodations: accommodations })
			})
			.catch(err => {
				this.setErrorMsg("error_fetch_accommodations", 1)
				console.log(err)
			})
		}
	}

	setErrorMsg = (key, msg, id = null) => {
		if (key === "error_fetch_accommodations")
		{
			key = "global"
			msg = tools.default_error_msg("Impossible de récupérer les hébergements", msg)
		}
		else if (key === "error_fetch_config")
		{
			key = "global"
			msg = tools.default_error_msg("Impossible de récupérer les paramètres des cautions", msg)
		}
		else if (key === "error_update_accommodation")
		{
			key = "global"
			msg = tools.default_error_msg("Impossible de modifier mettre à jour cet hébergement", msg)
		}
		else if (key === "error_update_config")
		{
			key = "global"
			msg = tools.default_error_msg("Impossible de mettre à jour les paramètres de cautions", msg)
		}
		let error = this._initErrors()
		if (id !== null)
			error[key][id] = msg
		else
			error[key] = msg
		this.setState({ success: null, show_banner: true, banner_content: msg, error: error })
	}

	_initErrors = () => {
		const errors = {
			global: null,
			secret_swikly: null,
			userId: null
		}
		return errors
	}

	classNames = (...classes) => {
		return classes.filter(Boolean).join(' ')
	}

	closeBanner = () => {
		this.setState({ show_banner: false })
	}

	toggleCaution = (accommodation, value) => {
		accommodation.deposit = value
		this.props.updateAccommodationDeposit(accommodation)
		.then(ret => {
			if (ret === false)
				this.setErrorMsg("error_update_accommodation", 1)
		})
		.catch(err => {
			console.log(err)
			this.setErrorMsg("error_update_accommodation", 2)
		})
	}

	handleAmount = (event) => {
		const form1 = {
			...this.state.form1,
			amount: event.target.value
		}
		this.setState({ form1: form1 })
	}

	handleActivate = () => {
		const activate = this.state.form1.activate ? false : true
		const form1 = {
			...this.state.form1,
			activate: activate
		}
		this.setState({ form1: form1 })
	}

	handleSecretIntern = (event) => {
		const form1 = {
			...this.state.form1,
			userId: event.target.value
		}
		this.setState({ form1: form1 })
	}

	handleSecretSwikly = (event) => {
		const form1 = {
			...this.state.form1,
			secret_swikly: event.target.value
		}
		this.setState({ form1: form1 })
	}

	_validateForm1 = () => {
		let error_obj = this._initErrors()
		let error = false
		const secret_swikly = this.state.form1.secret_swikly
		const userId = this.state.form1.userId
		console.log("form", this.state.form1)
		if (secret_swikly.length !== this.length_swikly_max)
		{
			error_obj.secret_swikly = "La clé secrète doit faire " + this.length_swikly_max + " caractères"
			error = true
		}
		else if (userId.length !== this.length_intern_max)
		{
			error_obj.userId = "L'user ID doit faire " + this.length_intern_max + " caractères"
			error = true
		}
		if (error === true)
		{
			error_obj.config = "Il y a une ou plusieurs erreur(s) dans le formulaire"
			this.setState({ success: null, error: error_obj, attempt_submit: false })
		}
		return (!error) ? true : false
	}

	submitConfig = (event) => {
		event.preventDefault()
		this.setState({ attempt_submit: true })
		if (this._validateForm1())
		{
			const config_deposit = {
				activate: this.state.form1.activate,
				amount: this.state.form1.amount,
				userId: this.state.form1.userId,
				secret_swikly: this.state.form1.secret_swikly
			}
			this.props.updateConfigDeposit(config_deposit)
			.then(ret => {
				if (ret !== false)
					this.setState({ attempt_submit: false, show_banner: true, banner_content: "Les paramètres ont bien été mis à jour", error: this._initErrors() })
				else
				{
					this.setState({ attempt_submit: false })
					this.setErrorMsg("error_update_config", 1)
				}
			})
			.catch(err => {
				console.log(err)
				this.setErrorMsg("error_update_config", 2)
			})
		}
	}

	render() {
		let enableDeposit = this.state.form1.activate
		const navStyle = "flex bg-transparent shadow-none mb-10"
		let sendbtn = (
			<button
				type="submit"
				className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-500 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
			>
				Enregistrer
			</button>
		)
		if (this.state.attempt_submit === true)
		{
			sendbtn = (
				<div className="center">
					<Loader />
				</div>
			)
		}
		return (
			<>
				<nav className={navStyle} aria-label="Breadcrumb">
					<ol className="bg-white rounded-md shadow px-6 flex space-x-4">
						<li className="flex">
						<div className="flex items-center">
							<Link to="/" className="text-gray-400 hover:text-gray-500">
								<HomeIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
								<span className="sr-only">Home</span>
							</Link>
						</div>
						</li>
						{this.pages.map((page) => (
							<li key={page.name} className="flex">
								<div className="flex items-center">
								<svg
									className="flex-shrink-0 w-6 h-full text-gray-200"
									viewBox="0 0 24 44"
									preserveAspectRatio="none"
									fill="currentColor"
									xmlns="http://www.w3.org/2000/svg"
									aria-hidden="true"
								>
									<path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
								</svg>
								<Link
									to={page.href}
									className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
									aria-current={page.current ? 'page' : undefined}
								>
									{page.name}
								</Link>
								</div>
							</li>
						))}
					</ol>
				</nav>
				<BannerInfo
					content={this.state.banner_content}
					show={this.state.show_banner}
					closeBanner={this.closeBanner}
				/>
				<div className="grid grid-cols-1 gap-4 items-start lg:grid-cols-3 lg:gap-8">
					<div className="grid grid-cols-1 gap-4 lg:col-span-2">
						<div className="rounded-lg bg-white overflow shadow">
							<table className="min-w-full divide-y divide-gray-200">
								<thead className="bg-gray-50">
									<tr>
										<th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Type d'hébergement</th>
										<th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Activer la caution</th>
									</tr>
								</thead>
								{this.state.accommodations && this.state.accommodations.map((accommodation, accommodation_k) => {
									const radio_name = "radio" + accommodation_k
									const checked_yes = (accommodation.deposit === true) ? true : false
									const checked_no = (!accommodation.deposit) ? true : false
									return (
										<tbody key={accommodation_k} className="bg-white divide-y divide-gray-200">
											<tr>
												<td className="px-6 py-1 whitespace-nowrap text-base font-medium text-gray-900">{accommodation.name}</td>
												<td className="px-6 py-1 whitespace-nowrap text-right text-lg font-medium">
													<div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
														<div className="flex items-center">
															<input
																id={"Oui"+accommodation_k}
																name={radio_name}
																type="radio"
																value="true"
																onChange={this.toggleCaution.bind(this, accommodation, true)}
																style={{ opacity: '100', pointerEvents: 'auto', position: 'relative'}}
																className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
																checked={checked_yes}
															/>
															<label htmlFor={"Oui"+accommodation_k} className="ml-3 block text-sm font-medium text-gray-700">Oui</label>
														</div>
														<div className="flex items-center">
															<input
																id={"Non"+accommodation_k}
																name={radio_name}
																type="radio"
																value="false"
																onChange={this.toggleCaution.bind(this, accommodation, false)}
																style={{ opacity: '100', pointerEvents: 'auto', position: 'relative'}}
																className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
																checked={checked_no}
															/>
															<label htmlFor={"Non"+accommodation_k} className="ml-3 block text-sm font-medium text-gray-700">Non</label>
														</div>
													</div>
												</td>
											</tr>
										</tbody>
									)
								})}
							</table>
						</div>
					</div>
					<div className="grid grid-cols-1 gap-4">
						<main>
							<form onSubmit={this.submitConfig}>
								<div className="shadow sm:rounded-md sm:overflow-hidden">
									<div className="bg-white py-6 px-4 sm:p-6 space-y-6">
										<div>
											<h1 className="text-xl mb-1 leading-6 font-medium text-gray-900">Caution SWIKLY</h1>
											<p className="text-base text-gray-500">
												Paramétrez la caution in-app avec SWIKLY
											</p>
										</div>
										<Switch.Group as="li" className="py-4 flex items-center justify-between">
											<div className="flex flex-col">
												<Switch.Label as="p" className="my-1 text-base font-medium text-gray-900" passive>
													Dépot de caution in-app
												</Switch.Label>
												<Switch.Description className="text-base text-gray-500">
													Vos clients pourront déposer leur caution sur votre app Pocketcamp à travers le formulaire SWIKLY.
												</Switch.Description>
											</div>
											<Switch
												checked={enableDeposit}
												onChange={this.handleActivate}
												className={this.classNames(
													enableDeposit ? 'bg-indigo-500' : 'bg-gray-200',
													'ml-4 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
												)}
											>
												<span
													aria-hidden="true"
													className={this.classNames(
														enableDeposit ? 'translate-x-5' : 'translate-x-0',
														'inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
													)}
												>
													<span
														className={this.classNames(
															enableDeposit ? 'opacity-0 ease-out duration-100' : 'opacity-100 ease-in duration-200',
															'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity'
														)}
														aria-hidden="true"
													>
														<svg className="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
															<path
																d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
																stroke="currentColor"
																strokeWidth={2}
																strokeLinecap="round"
																strokeLinejoin="round"
															/>
														</svg>
													</span>
													<span
														className={this.classNames(
															enableDeposit ? 'opacity-100 ease-in duration-200' : 'opacity-0 ease-out duration-100',
															'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity'
														)}
														aria-hidden="true"
													>
														<svg className="h-3 w-3 text-indigo-600" fill="currentColor" viewBox="0 0 12 12">
															<path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
														</svg>
													</span>
												</span>
											</Switch>
										</Switch.Group>
										<div>
											<label htmlFor="amount" className="block my-1 text-base font-medium text-gray-700">
												Montant
											</label>
											<p className="text-base text-gray-500">Indiquez le montant de la caution.</p>
											<div>
												<input
													type="number"
													min="0"
													id="amount"
													value={this.state.form1.amount}
													className="block w-full shadow-sm focus:ring-sky-500 focus:border-sky-500 sm:text-base border-gray-300 rounded-md"
													onChange={this.handleAmount}
												/>
											</div>
										</div>
										<div>
											<label htmlFor="secretLink" className="block my-1 text-base font-medium text-gray-700">
												Secret du lien
											</label>
											<p className="text-base text-gray-500">Indiquez la clé secrète, qui se trouve partie « développeurs » de votre compte Swikly en cliquant sur l'avatar en haut à droite de votre écran.</p>
											<p className="mt-2 text-sm text-red-600" id="comment-error">{this.state.error.secret_swikly}</p>
											<div>
												<input
													type="text"
													id="secretLink"
													className="block w-full shadow-sm focus:ring-sky-500 focus:border-sky-500 sm:text-base border-gray-300 rounded-md"
													value={this.state.form1.secret_swikly}
													placeholder="ex: d90f3f5789h9874b"
													onChange={this.handleSecretSwikly}
												/>
											</div>
										</div>
										<div>
											<label htmlFor="UserID" className="block my-1 text-base font-medium text-gray-700">
												User ID
											</label>
											<p className="text-base text-gray-500">Indiquez l’identifiant du compte Swikly (le user ID, qui se trouve partie « développeurs » de votre compte swikly en cliquant sur l'avatar en haut à droite de votre écran.)</p>
											<p className="mt-2 text-sm text-red-600" id="comment-error">{this.state.error.userId}</p>
											<div>
												<input
													type="text"
													id="UserID"
													className="block w-full shadow-sm focus:ring-sky-500 focus:border-sky-500 sm:text-base border-gray-300 rounded-md"
													value={this.state.form1.userId}
													placeholder="ex: fcb8473ad02e45c7f45ce567e8f0c6"
													onChange={this.handleSecretIntern}
												/>
											</div>
										</div>
										{/* <div>
											<label htmlFor="description" className="block my-1 text-base font-medium text-gray-700">
												Informations à communiquer
											</label>
											<p className="text-base text-gray-500">Indiquez ici des infos à ne pas manquer sur la caution. Ce texte s'affichera avant que l'utilisateur dépose sa caution.</p>
											<div className="mt-1">
												<textarea
													id="description"
													name="description"
													rows={3}
													className="block mt-5 w-full shadow-sm focus:ring-sky-500 focus:border-sky-500 sm:text-base border border-gray-300 rounded-md"
													defaultValue={''}
												/>
											</div>
										</div> */}
										<p className="mt-2 text-sm text-red-600" id="comment-error">{this.state.error.config}</p>
										<div className="flex justify-end">
											{sendbtn}
										</div>
									</div>
								</div>
							</form>
						</main>
					</div>
				</div>
			</>
		)
	}
}

export default DepositParam
import React, { Component } from 'react'
import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationIcon } from '@heroicons/react/outline'

class ModalDelete extends Component {
	constructor(props) {
		super(props)
		this.state = {
			attempt_submit: (this.props.attempt_submit === true) ? true : false
		}
		this.closeModal = (this.props.closeModal) ? this.props.closeModal : () => {}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.attempt_submit !== this.props.attempt_submit)
			this.setState({ attempt_submit: this.props.attempt_submit })
	}

	answerModal = (answer) => {
		this.props.answerModal(answer)
	}

	handleSubmit = (event) => {
		event.preventDefault()
		this.props.answerModal(true)
	}

	render() {
		const cancelButtonRef = React.createRef()
		let submitbtn = (
			<button type="submit" className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:w-auto sm:text-sm">
				Supprimer
			</button>
		)
		if (this.state.attempt_submit === true)
		{
			submitbtn = (
				<button type="submit" className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:w-auto sm:text-sm">
					<svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
						<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
						<path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
					</svg>
					Supprimer
				</button>
			)
		}
		return (
			<Transition.Root show={this.props.open_modal} as={Fragment}>
				<Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={this.closeModal}>
					<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
						</Transition.Child>
						{/* This element is to trick the browser into centering the modal contents. */}
						<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
							&#8203;
						</span>
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<form onSubmit={this.handleSubmit} className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
								<div className="sm:flex sm:items-start">
									<div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
										<ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
									</div>
									<div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
										<Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
											{this.props.title}
										</Dialog.Title>
										<div className="mt-2">
											<p className="text-sm text-gray-500">
												Êtes-vous sûrs de vouloir continuer ? {this.props.content}. Cette action est irréversible.
											</p>
										</div>
									</div>
								</div>
								<div className="mt-5 sm:mt-4 sm:ml-10 sm:pl-4 sm:flex">
									{submitbtn}
									<button
										type="button"
										className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 px-4 py-2 bg-white text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
										onClick={() => this.props.answerModal(false)}
										ref={cancelButtonRef}
									>
										Annuler
									</button>
								</div>
							</form>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition.Root>
		)
	}
}

export default ModalDelete
import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { Fragment } from 'react'
import tools from '../../tools'
import Loader from '../UI/Loader'
import { Dialog, Transition, Listbox } from '@headlessui/react'
import { ChevronDownIcon, ChevronUpIcon, MinusIcon, CheckIcon } from '@heroicons/react/solid'
import { Link } from 'react-router-dom'
import { HomeIcon } from '@heroicons/react/outline'
import EmptyFolder from '../UI/EmptyFolder'
import OnlyPremiumEmpty from '../UI/OnlyPremiumEmpty'
import PremiumAlert from '../UI/PremiumAlert'

class DepositDashboard extends Component {
	constructor(props)
	{
		super(props)
		this.default_select_text = "Sélectionnez un filtre"
		this.pages = [
			{ name: 'Cautions', href: '/deposit/users-deposit', current: false },
		]
		this.state = {
			isOpen: false,
			selectedPerson : null,
			sort: {
				status: null,
				lastname: null,
				firstname: null,
				arrival_time: null,
				edited_time: null,
				ref_swikly: null,
				client_number: null,
				stay_number: null,
				location: null
			},
			filtered_deposits: null,
			deposits: undefined,
			search: {
				text: '',
				select: this.default_select_text,
				status_ok: true,
				status_no: true
			},
			error: this._initErrors(),
			success: null
		}
	}

	classNames = (...classes) => {
		return classes.filter(Boolean).join(' ')
	}

	componentDidMount()
	{
		this.loadDeposits()
	}

	setErrorMsg = (key, msg, id = null) => {
		if (key === "error_fetch_deposits")
		{
			key = "global"
			msg = tools.default_error_msg("Impossible de récupérer les cautions", msg)
		}
		let error = this._initErrors()
		if (id !== null)
			error[key][id] = msg
		else
			error[key] = msg
		this.setState({ success: null, error: error })
	}

	setSuccessMsg = (msg) => {
		this.clearFlash()
		this.setState({ success: msg, error: this._initErrors() })
	}

	clearFlash = () => {
		this.setState({ success: null, error: this._initErrors() })
	}

	_initErrors = () => {
		const errors = {
			global: null
		}
		return errors
	}

	loadDeposits = (fromProps = false) => {
		if (fromProps === true)
		{
			let deposits = tools.clone(this.props.deposits)
			deposits.sort((a, b) => {
				return a.name.localeCompare(b.name)
			})
			this.setState({ deposits: deposits })
		}
		else
		{
			this.props.fetchDeposits()
			.then(deposits => {
				this.setState({ deposits: deposits })
			})
			.catch(err => {
				this.setErrorMsg("error_fetch_deposits", 1)
				console.log(err)
			})
		}
	}

	openModal = (id) => {
		this.setState({ isOpen: true, selectedPerson: id })
	}
	
	closeModal = () => {
		this.setState({ isOpen: false })
	}

	compareStrings = (param, chevron) => {
		let deposits = this.state.deposits
		if (chevron === "up")
		{
			deposits.sort((a, b) => {
				let compare_a = a[param]
				let compare_b = b[param]
				if (param.indexOf('.') !== -1)
				{
					const params = param.split('.')
					compare_a = a[params[0]][params[1]]
					compare_b = b[params[0]][params[1]]
				}
				if (!compare_a && !compare_b)
					return 0
				else if (!compare_a && compare_b)
					return 1
				else if (compare_a && !compare_b)
					return -1
				else
					return compare_a.localeCompare(compare_b) * -1
			})
		}
		else if (chevron === "down")
		{
			deposits.sort((a, b) => {
				let compare_a = a[param]
				let compare_b = b[param]
				if (param.indexOf('.') !== -1)
				{
					const params = param.split('.')
					compare_a = a[params[0]][params[1]]
					compare_b = b[params[0]][params[1]]
				}
				if (!compare_a && !compare_b)
					return 0
				else if (!compare_a && compare_b)
					return -1
				else if (compare_a && !compare_b)
					return 1
				else
					return compare_a.localeCompare(compare_b)
			})
		}
		return deposits
	}

	compareNumbers = (param, chevron) => {
		let deposits = this.state.deposits
		if (chevron === "up")
		{
			deposits.sort((a, b) => {
				let compare_a = a[param]
				let compare_b = b[param]
				if (param.indexOf('.') !== -1)
				{
					const params = param.split('.')
					compare_a = a[params[0]][params[1]]
					compare_b = b[params[0]][params[1]]
				}
				return (compare_a - compare_b) * -1
			})
		}
		else if (chevron === "down")
		{
			deposits.sort((a, b) => {
				let compare_a = a[param]
				let compare_b = b[param]
				if (param.indexOf('.') !== -1)
				{
					const params = param.split('.')
					compare_a = a[params[0]][params[1]]
					compare_b = b[params[0]][params[1]]
				}
				return compare_a - compare_b
			})
		}
		return deposits
	}

	handleChevron = (param) => {
		const sort = {
			...this.state.sort
		}
		let index = param.indexOf('.')
		if (index !== -1)
		{
			index++
			param = param.substring(index)
		}
		console.log('param', param)
		const order = (sort[param] !== "down") ? "down" : "up"
		sort[param] = order
		this.setState({ sort: sort })
		return order
	}

	filter = (param) => {
		let deposits = this.state.deposits
		const chevron = this.handleChevron(param)
		if (param === "edited_time" || param === "amount" || param === "stay.stay_number" || param === "stay.client_number" || param === "stay.arrival_time")
			deposits = this.compareNumbers(param, chevron)
		else
			deposits = this.compareStrings(param, chevron)
		this.setState({ deposits: deposits, sortStatus: chevron })
	}

	_searchFilters = (search) => {
		let deposits = []
		if (!(search.status_ok === false && search.status_no === false) && !(search.status_ok === true && search.status_no === true))
		{
			console.log("search", this.state.search)
			for (let i = 0; i < this.state.deposits.length; i++)
			{
				if (search.status_ok === true && search.status_no === false && this.state.deposits[i].status === "OK")
					deposits.push(this.state.deposits[i])
				else if (search.status_no === true && search.status_ok === false && !this.state.deposits[i].status)
					deposits.push(this.state.deposits[i])
			}
		}
		else
			deposits = this.state.deposits
		let filtered_deposits = []
		if (search.text !== '' && search.select !== this.default_select_text)
		{
			for (let i = 0; i < deposits.length; i++)
			{
				if (typeof deposits[i].stay !== "object")
					continue
				else if (search.select === "N. séjour" && Number(deposits[i].stay.stay_number) === Number(search.text))
					filtered_deposits.push(deposits[i])
				else if (search.select === "N. client" && Number(deposits[i].stay.client_number) === Number(search.text))
					filtered_deposits.push(deposits[i])
				else if (search.select === "N. emplacement" && deposits[i].stay.location.trim() === search.text.trim())
					filtered_deposits.push(deposits[i])
			}
		}
		else
			filtered_deposits = deposits
		this.setState({ filtered_deposits: filtered_deposits, search: search })
	}

	searchText = (event) => {
		const search = this.state.search
		search.text = event.target.value
		this._searchFilters(search)
	}
	
	searchSelect = (event) => {
		const search = this.state.search
		search.select = event.name
		this._searchFilters(search)
	}

	searchStatusOk = () => {
		const search = this.state.search
		search.status_ok = (search.status_ok) ? false : true
		this._searchFilters(search)
	}

	searchStatusNo = () => {
		const search = this.state.search
		search.status_no = (search.status_no) ? false : true
		this._searchFilters(search)
	}

	render() {
		const deposits = (this.state.filtered_deposits) ? this.state.filtered_deposits : this.state.deposits
		const id = this.state.selectedPerson
		const navStyle = "flex bg-transparent shadow-none mb-10"
		let table = null
		let empty = null
		let chevronStatus = <MinusIcon className="h-5 w-5" aria-hidden="true" />
		if (this.state.sort.status === "up")
			chevronStatus = <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
		else if (this.state.sort.status === "down")
			chevronStatus = <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
		let chevronLastname = <MinusIcon className="h-5 w-5" aria-hidden="true" />
		if (this.state.sort.lastname === "up")
			chevronLastname = <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
		else if (this.state.sort.lastname === "down")
			chevronLastname = <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
		let chevronFirstname = <MinusIcon className="h-5 w-5" aria-hidden="true" />
		if (this.state.sort.firstname === "up")
			chevronFirstname = <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
		else if (this.state.sort.firstname === "down")
			chevronFirstname = <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
		let chevronEditedTime = <MinusIcon className="h-5 w-5" aria-hidden="true" />
		if (this.state.sort.edited_time === "up")
			chevronEditedTime = <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
		else if (this.state.sort.edited_time === "down")
			chevronEditedTime = <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
		let chevronAmount = <MinusIcon className="h-5 w-5" aria-hidden="true" />
		if (this.state.sort.amount === "up")
			chevronAmount = <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
		else if (this.state.sort.amount === "down")
			chevronAmount = <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
		let chevronRefSwikly = <MinusIcon className="h-5 w-5" aria-hidden="true" />
		if (this.state.sort.ref_swikly === "up")
			chevronRefSwikly = <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
		else if (this.state.sort.ref_swikly === "down")
			chevronRefSwikly = <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
		let chevronClient = <MinusIcon className="h-5 w-5" aria-hidden="true" />
		if (this.state.sort.client_number === "up")
			chevronClient = <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
		else if (this.state.sort.client_number === "down")
			chevronClient = <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
		let chevronStay = <MinusIcon className="h-5 w-5" aria-hidden="true" />
		if (this.state.sort.client_number === "up")
			chevronStay = <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
		else if (this.state.sort.stay_number === "down")
			chevronStay = <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
		let chevronLocation = <MinusIcon className="h-5 w-5" aria-hidden="true" />
		if (this.state.sort.location === "up")
			chevronLocation = <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
		else if (this.state.sort.location === "down")
			chevronLocation = <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
		let chevronArrivalTime = <MinusIcon className="h-5 w-5" aria-hidden="true" />
		if (this.state.sort.arrival_time === "up")
			chevronArrivalTime = <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
		else if (this.state.sort.arrival_time === "down")
			chevronArrivalTime = <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />

		let lastname = null
		let firstname = null
		let email = null
		let client_number = null
		let stay_number = null
		let location = null
		let location_category = null
		let amount = null
		let ref_swikly = null
		let arrival = null
		let departure = null
		let edited_time = null
		if (id !== null)
		{
			lastname = deposits[id].user.lastname
			firstname = deposits[id].user.firstname
			email = deposits[id].user.email
			client_number = deposits[id].stay.client_number
			stay_number = deposits[id].stay.stay_number
			location = deposits[id].stay.location
			location_category = deposits[id].stay.location_category
			amount = deposits[id].amount
			ref_swikly = deposits[id].ref_swikly
			arrival = tools.parseDate(deposits[id].stay.arrival_time, false)
			departure = tools.parseDate(deposits[id].stay.departure_time, false)
			edited_time = tools.parseDate(deposits[id].edited_time, false)
		}
		const permissions = this.props.user.permissions
		if (!permissions || !permissions.deposits || permissions.deposits === 0)
			return (<Redirect to="/" />)
		if (this.props.features !== "premium")
		{
			return (
				<>
					<OnlyPremiumEmpty 
						title="Gérer mes cautions SWIKLY"
						subtitle="Consultez ici les cautions déposées"
						emptyFolderSubtitle="Pas de cautions"
						navName="Cautions"
						navPath="/deposit/users-deposit"
					/>
					<div className="mt-10">
						<PremiumAlert
							content="Mettez votre abonnement à niveau avec la formule premium pour accéder aux cautions SWIKLY !"
							button_text="En savoir plus"
						/>
					</div>
				</>
			)
		}
		else if (deposits === undefined)
		{
			return (
				<div className="loader">
					<Loader />
				</div>
			)
		}
		else if (!this.state.deposits || this.state.deposits.length === 0)
		{
			empty = (
				<EmptyFolder 
					emptyFolderSubtitle = "Pas de cautions pour le moment"
				/>
			)
		}
		else if (deposits)
		{
			const filter = [
				{ id: 1, name: 'N. séjour' },
				{ id: 2, name: 'N. emplacement' },
				{ id: 3, name: 'N. client' },
			]
			const style = {
				border: 'solid',
				borderWidth: '1px',
				fontSize: '0.875rem',
				lineHeight: '1.25rem',
				borderRadius: '0.375rem',
				borderColor: 'rgba(209, 213, 219, var(--tw-border-opacity))',
				opacity: 1,
				boxShadow: 'none',
				padding: '0 0.75rem',
				marginBottom: '0'
			}
			table = (
				<div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
					<div className="py-2 align-middle inline-block sm:px-6 lg:px-8">
						<div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
						<div className="bg-white border-b border-gray-200 py-4 px-5">
							<form className="sm:flex justify-between sm:items-center">
								<div className="flex">
									<div className="mr-5 w-fulltext-sm sm:max-w-xs">
										<label htmlFor="email" className="sr-only">
											Search
										</label>
										<input
											style={style}
											type="text"
											name="input"
											className="text-sm mx-4 block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
											placeholder="N° client, séjour ou emplacement."
											value={this.state.search.text}
											onChange={this.searchText}
										/>
									</div>
									<Listbox value={this.state.search.select} onChange={this.searchSelect}>
										{({ open }) => (
											<>
											<div className="relative ml-3">
												<Listbox.Button className="relative w-44 cursor-default rounded-md border border-gray-300 bg-white py-3.5 pl-3 pr-10 ml-5 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
													<span className="block truncate">{this.state.search.select}</span>
													<span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
														<ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
													</span>
												</Listbox.Button>
												<Transition
													show={open}
													as={Fragment}
													leave="transition ease-in duration-100"
													leaveFrom="opacity-100"
													leaveTo="opacity-0"
												>
													<Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
														{filter.map((person) => (
															<Listbox.Option
																key={person.id}
																className={({ active }) =>
																	this.classNames(
																		active ? 'text-white bg-indigo-600' : 'text-gray-900',
																		'relative cursor-default select-none py-2 pl-3 pr-9'
																	)
																}
																value={person}
															>
																{({ selected, active }) => (
																	<>
																		<span className={this.classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
																			{person.name}
																		</span>
																		{selected ? (
																			<span
																				className={this.classNames(
																				active ? 'text-white' : 'text-indigo-600',
																				'absolute inset-y-0 right-0 flex items-center pr-4'
																				)}
																			>
																				<CheckIcon className="h-5 w-5" aria-hidden="true" />
																			</span>
																		) : null}
																	</>
																)}
															</Listbox.Option>
														))}
													</Listbox.Options>
												</Transition>
											</div>
											</>
										)}
									</Listbox>
								</div>
								{/* --------- CHECKBOX ICI ------------ */}
								<div className="flex">
									<div className="flex">
										<div className="relative w-8 sm:w-6 sm:px-6">
											<input
												type="checkbox"
												onChange={this.searchStatusOk}
												className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6"
												style={{ opacity: '100', pointerEvents: 'auto'}}
												checked={this.state.search.status_ok}
											/>
										</div>
										<div>Cautions déposées</div>
									</div>
									<div className="flex">
										<div className="relative w-8 sm:w6 sm:px-6">
											<input
												type="checkbox"
												onChange={this.searchStatusNo}
												className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6"
												style={{ opacity: '100', pointerEvents: 'auto'}}
												checked={this.state.search.status_no}
											/>
										</div>
										<div>Cautions non déposées</div>
									</div>
								</div>
								{/* --------- CHECKBOX ICI ------------ */}
							</form>
						</div>
							<table className="min-w-full divide-y divide-gray-200">
								<thead className="bg-gray-50">
									<tr>
										<th scope="col" className="px-6 py-3 text-left text-base font-medium text-gray-500 uppercase tracking-wider">
											<div onClick={this.filter.bind(this, 'status')} className="group inline-flex">
												Status
												<span className="ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300">
													{chevronStatus}
												</span>
											</div>
										</th>
										<th scope="col" className="px-6 py-3 text-left text-base font-medium text-gray-500 uppercase tracking-wider">
											<div onClick={this.filter.bind(this, 'user.lastname')} className="group inline-flex">
												Nom
												<span className="ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300">
													{chevronLastname}
												</span>
											</div>
										</th>
										<th scope="col" className="px-6 py-3 text-left text-base font-medium text-gray-500 uppercase tracking-wider">
											<div onClick={this.filter.bind(this, 'user.firstname')} className="group inline-flex">
												Prénom
												<span className="ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300">
													{chevronFirstname}
												</span>
											</div>
										</th>
										<th scope="col" className="px-6 py-3 text-left text-base font-medium text-gray-500 uppercase tracking-wider">
											<div onClick={this.filter.bind(this, 'stay.client_number')} className="group inline-flex">
												Client
												<span className="ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300">
													{chevronClient}
												</span>
											</div>
										</th>
										<th scope="col" className="px-6 py-3 text-left text-base font-medium text-gray-500 uppercase tracking-wider">
											<div onClick={this.filter.bind(this, 'stay.stay_number')} className="group inline-flex">
												Séjour
												<span className="ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300">
													{chevronStay}
												</span>
											</div>
										</th>
										<th scope="col" className="px-6 py-3 text-left text-base font-medium text-gray-500 uppercase tracking-wider">
											<div onClick={this.filter.bind(this, 'stay.location')} className="group inline-flex">
												Emplacement
												<span className="ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300">
													{chevronLocation}
												</span>
											</div>
										</th>
										<th scope="col" className="px-6 py-3 text-left text-base font-medium text-gray-500 uppercase tracking-wider">
											<div onClick={this.filter.bind(this, 'ref_swikly')} className="group inline-flex">
												Réf. SWIKLY
												<span className="ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300">
													{chevronRefSwikly}
												</span>
											</div>
										</th>
										<th scope="col" className="px-6 py-3 text-left text-base font-medium text-gray-500 uppercase tracking-wider">
											<div onClick={this.filter.bind(this, 'amount')} className="group inline-flex">
												Montant
												<span className="ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300">
													{chevronAmount}
												</span>
											</div>
										</th>
										<th scope="col" className="px-6 py-3 text-left text-base font-medium text-gray-500 uppercase tracking-wider">
											<div onClick={this.filter.bind(this, 'stay.arrival_time')} className="group inline-flex">
												Arrivée
												<span className="ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300">
													{chevronArrivalTime}
												</span>
											</div>
										</th>
										<th scope="col" className="px-6 py-3 text-left text-base font-medium text-gray-500 uppercase tracking-wider">
											<div onClick={this.filter.bind(this, 'edited_time')} className="group inline-flex">
												Date de dépot
												<span className="ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300">
													{chevronEditedTime}
												</span>
											</div>
										</th>
										<th scope="col" className="relative px-6 py-3">
										<span className="sr-only">Modifier</span>
										</th>
									</tr>
								</thead>
								<tbody>
									{deposits.map((deposit, deposit_k) => {
										let statusStyle = 'px-6 py-4 whitespace-nowrap text-base font-medium text-red-500'
										let status = deposit.status
										if (deposit.status === "OK")
										{
											status = "Validé"
											statusStyle = 'px-6 py-4 whitespace-nowrap text-base font-medium text-green-600'
										}
										else if (deposit.status === null)
										{
											status = "Non déposé"
											statusStyle = 'px-6 py-4 whitespace-nowrap text-base font-medium text-gray-400'
										}
										const arrival = tools.parseDate(deposit.stay.arrival_time, false)
										const date = tools.parseDate(deposit.edited_time, false)
										return (
											<tr key={deposit_k} className={deposit_k % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
												<td className={statusStyle}>{status}</td>
												<td className="px-6 py-4 whitespace-nowrap text-base font-medium text-gray-500">{deposit.user.lastname}</td>
												<td className="px-6 py-4 whitespace-nowrap text-base text-gray-500">{deposit.user.firstname}</td>
												<td className="px-6 py-4 whitespace-nowrap text-base text-gray-500">{deposit.stay.client_number}</td>
												<td className="px-6 py-4 whitespace-nowrap text-base text-gray-500">{deposit.stay.stay_number}</td>
												<td className="px-6 py-4 whitespace-nowrap text-base text-gray-500">{deposit.stay.location}</td>
												<td className="px-6 py-4 whitespace-nowrap text-base text-gray-500">{deposit.ref_swikly}</td>
												<td className="px-6 py-4 whitespace-nowrap text-base text-gray-500">{deposit.amount} &euro;</td>
												<td className="px-6 py-4 whitespace-nowrap text-base text-gray-500">{arrival}</td>
												<td className="px-6 py-4 whitespace-nowrap text-base text-gray-500">{date}</td>
												<td className="px-6 py-4 whitespace-nowrap text-right text-base font-medium">
													<button onClick={this.openModal.bind(this, deposit_k)} className="text-indigo-600 hover:text-indigo-900">
														consulter
													</button>
												</td>
											</tr>
										)
									})}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			)
		}
		return (
			<div>
				<Transition appear show={this.state.isOpen} as={Fragment}>
					<Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={this.closeModal}>
						<div className="flex justify-center text-center sm:block sm:p-0">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0"
								enterTo="opacity-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100"
								leaveTo="opacity-0"
							>
								<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
							</Transition.Child>
							<span
								className="hidden sm:inline-block sm:align-middle sm:h-screen"
								aria-hidden="true"
							>
								&#8203;
							</span>
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 scale-95"
								enterTo="opacity-100 scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 scale-100"
								leaveTo="opacity-0 scale-95"
							>
								<div className="inline-block align-bottom bg-white rounded-lg text-left shadow-xl transform transition-all sm:align-middle sm:max-w-5xl lg:w-auto sm:w-full">
									<div className="px-4 py-5 sm:px-6">
										<h3 className="text-xl leading-6 my-0 font-medium text-gray-900">Informations sur la caution</h3>
									</div>
									<div className="border-t border-gray-200">
										<dl>
											<div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
												<dt className="text-base font-medium text-gray-500">Nom</dt>
												<dd className="mt-1 text-base text-gray-900 sm:mt-0 sm:col-span-2">{firstname} {lastname}</dd>
											</div>
											<div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
												<dt className="text-base font-medium text-gray-500">Adresse e-mail</dt>
												<dd className="mt-1 text-base text-gray-900 sm:mt-0 sm:col-span-2">{email}</dd>
											</div>
											<div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
												<dt className="text-base font-medium text-gray-500">Dates de séjour</dt>
												<dd className="mt-1 text-base text-gray-900 sm:mt-0 sm:col-span-2">{arrival} - {departure}</dd>
											</div>
											<div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
												<dt className="text-base font-medium text-gray-500">Hébergement</dt>
												<dd className="mt-1 text-base text-gray-900 sm:mt-0 sm:col-span-2">{location_category}</dd>
											</div>
											<div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
												<dt className="text-base font-medium text-gray-500">Emplacement</dt>
												<dd className="mt-1 text-base text-gray-900 sm:mt-0 sm:col-span-2">{location}</dd>
											</div>
											<div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
												<dt className="text-base font-medium text-gray-500">Référence SWIKLY</dt>
												<dd className="mt-1 text-base text-gray-900 sm:mt-0 sm:col-span-2">{ref_swikly}</dd>
											</div>
											<div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
												<dt className="text-base font-medium text-gray-500">N° client</dt>
												<dd className="mt-1 text-base text-gray-900 sm:mt-0 sm:col-span-2">{client_number}</dd>
											</div>
											<div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
												<dt className="text-base font-medium text-gray-500">N° séjour</dt>
												<dd className="mt-1 text-base text-gray-900 sm:mt-0 sm:col-span-2">{stay_number}</dd>
											</div>
											<div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
												<dt className="text-base font-medium text-gray-500">Montant</dt>
												<dd className="mt-1 text-base text-gray-900 sm:mt-0 sm:col-span-2">{amount} &euro;</dd>
											</div>
											<div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
												<dt className="text-base font-medium text-gray-500">Date de dépot</dt>
												<dd className="mt-1 text-base text-gray-900 sm:mt-0 sm:col-span-2">{edited_time}</dd>
											</div>
										</dl>
									</div>
									
									<div className="mt-10 px-4 py-3 text-right sm:px-6">
										<button
											type="submit"
											className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
											onClick={this.closeModal}
										>
											Fermer
										</button>
									</div>
								</div>
							</Transition.Child>
						</div>
					</Dialog>				
				</Transition>
				<div className="flex flex-col">
					<nav className={navStyle} aria-label="Breadcrumb">
						<ol className="bg-white rounded-md shadow px-6 flex space-x-4">
							<li className="flex">
							<div className="flex items-center">
								<Link to="/" className="text-gray-400 hover:text-gray-500">
									<HomeIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
									<span className="sr-only">Home</span>
								</Link>
							</div>
							</li>
							{this.pages.map((page) => (
							<li key={page.name} className="flex">
								<div className="flex items-center">
								<svg
									className="flex-shrink-0 w-6 h-full text-gray-200"
									viewBox="0 0 24 44"
									preserveAspectRatio="none"
									fill="currentColor"
									xmlns="http://www.w3.org/2000/svg"
									aria-hidden="true"
								>
									<path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
								</svg>
								<Link
									to={page.href}
									className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
									aria-current={page.current ? 'page' : undefined}
								>
									{page.name}
								</Link>
								</div>
							</li>
							))}
						</ol>
					</nav>
					<div className="grid-cols-1">
						<div className="mb-5">
							<h2 className="text-xl mt-0 leading-6 font-medium text-gray-900 mb-0">Gérer mes cautions SWIKLY</h2>
							<p className="mt-1 text-base text-gray-500">
								Consultez ici les cautions déposées
							</p>
						</div>
						<Link to="/deposit/swikly" type="button" className="mb-10 inline-flex items-center px-6 py-3 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
							Paramétrer les cautions			
							<svg xmlns="http://www.w3.org/2000/svg" className="ml-3 -mr-1 h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
								<path d="M5 4a1 1 0 00-2 0v7.268a2 2 0 000 3.464V16a1 1 0 102 0v-1.268a2 2 0 000-3.464V4zM11 4a1 1 0 10-2 0v1.268a2 2 0 000 3.464V16a1 1 0 102 0V8.732a2 2 0 000-3.464V4zM16 3a1 1 0 011 1v7.268a2 2 0 010 3.464V16a1 1 0 11-2 0v-1.268a2 2 0 010-3.464V4a1 1 0 011-1z" />
							</svg>
						</Link>
					</div>
					<p className="mb-4">
						{deposits.length} résultats
					</p>
					{table}
				</div>
				{empty}
			</div>
		)
	}
}

export default DepositDashboard
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import connexion from '../../connexion'
import { parseDate } from '../../tools'
import Loader from '../UI/Loader'
import '../Core/Main.css'

class Stays extends Component {
	constructor(props) {
		super(props)
		this.search = (this.props.location.stays !== undefined) ? true : false
		this.state = {
			stay: null,
			id: (this.props.match.params.id !== undefined) ? this.props.match.params.id : null,
			stays: (this.props.location.stays !== undefined) ? this.props.location.stays : null
		}
		this.props.updateNavbarTitle('Séjours')
	}

	componentDidMount() {
		if (this.state.stays === null)
			this.getStayList()
		this.getStay(this.state.id)
	}

	getStayList = () => {
		fetch(connexion.connect.url + 'dashboard/stays/list')
			.then(res => res.json())
			.then(stays => {
				this.setState({ stays: stays })
			})
			.catch(err => console.log(err))
	}

	getStay = (id) => {
		if (id !== null)
		{
			fetch(connexion.connect.url + 'dashboard/stays/find', {
				method: 'POST',
				body: JSON.stringify({
					stay_number: id
				})
			})
			.then(res => res.json())
			.then(stay => {
				fetch(connexion.connect.url + 'dashboard/clients/find', {
					method: 'POST',
					body: JSON.stringify({
						client_number: stay.client_number
					})
				})
				.then(res => res.json())
				.then(client => {
					stay.client = client
					fetch(connexion.connect.url + 'webapp/users/find-by-stay', {
						method: 'POST',
						body: JSON.stringify({
							stay_number: id
						})
					})
						.then(res => res.json())
						.then(users => {
							stay.users = users
							this.setState({ stay: stay })
						})
						.catch(err => console.log(err))
				})
				.catch(err => console.log(err))
			})
			.catch(err => console.log(err))
		}
	}

	handleLink = (id) => {
		this.getStay(id)
		this.setState({ id: id })
	}

	render() {
		const id = this.state.id
		const stay = this.state.stay
		const stays = this.state.stays
		if ((id !== null && stay === null) || (id === null && stays === null))
		{
			return (
				<div className="loader">
					<Loader />
				</div>
			)
		}
		else if (id !== null)
		{
			const created_time = parseDate(stay.created_time)
			let editedJSX = null
			if (stay.edited_time !== null)
			{
				const edited_time = parseDate(stay.edited_time)
				editedJSX = (<span><br /><b>Modification :</b> {edited_time}</span>)
			}
			let stayJSX = (
				<p>
					<b>N° Séjour :</b> {stay.stay_number}<br />
					<b>Emplacement :</b> {stay.location}<br />
					<b>Date d'arrivée :</b> {stay.arrival}<br />
					<b>Date de départ :</b> {stay.departure}<br />
					<b>Création :</b> {created_time}
					{editedJSX}
				</p>
			)
			const client = stay.client
			const civility = (client.civility === null) ? (<i>Non renseigné</i>) : client.civility
			const birthday = (client.birthday === null) ? (<i>Non renseigné</i>) : client.birthday
			const email1 = (client.email1 === null) ? (<i>Non renseigné</i>) : client.email1
			const email2 = (client.email2 === null) ? null : (<span><b>E-mail 2 :</b> {client.email2}<br /></span>)
			const phone1 = (client.phone1 === null) ? (<i>Non renseigné</i>) : client.phone1
			const phone2 = (client.phone2 === null) ? null : (<span><b>Téléphone 2 :</b> {client.phone2}<br /></span>)
			const phone3 = (client.phone3 === null) ? null : (<span><b>Téléphone 3 :</b> {client.phone3}<br /></span>)
			const client_path = '/users/clients/' + client.client_number
			let clientJSX = (
				<p>
					<b>N° Client :</b> <Link to={client_path}>{client.client_number}</Link><br />
					<b>Nom :</b> {client.lastname}<br />
					<b>Prénom :</b> {client.firstname}<br />
					<b>Civilité :</b> {civility}<br />
					<b>Date de naissance :</b> {birthday}<br />
					<b>Langue :</b> {client.langage}<br />
					<b>E-mail 1 :</b> {email1}<br />
					{email2}
					<b>Téléphone 1 :</b> {phone1}<br />
					{phone2}
					{phone3}
					<b>Code d'activation :</b> {client.activation_code}
				</p>
			)
			stay.users.sort((a, b) => {
				return a.created_time - b.created_time
			})
			let users_k = -1
			return (
				<div className="wrapper-content">
					<p><button type="button" className="waves-effect waves-light btn-small" onClick={this.handleLink.bind(this, null)}>Retour à la liste</button></p>
					<div className="row">
						<div className="module col s4">
						<h2 className="title-description">Détails</h2>
							{stayJSX}
						</div>
						<div className="module col s4">
						<h2 className="title-description">Fiche client</h2>
							{clientJSX}
						</div>
						<div className="module col s4">
							<h2 className="title-description">Utilisateurs</h2>
							{stay.users.map(({ _id, activate, birthday, civility, lastname, firstname, email, stay }) => {
								users_k++
								const path = '/users/webapp/' + _id
								const active = (activate === true) ? 'Oui' : 'Non'
								const birthdayJSX = (birthday !== null) ? (<span><b>Date de naissance :</b> {birthday}<br /></span>) : null
								if (civility === "homme")
									civility = "Monsieur"
								else if (civility === "femme")
									civility = "Madame"
								else if (civility === "other")
									civility = "Autre"
								const civilityJSX = (civility !== null) ? (<span><b>Civilité :</b> {civility}<br /></span>) : null
								return (
									<p key={users_k}>
										<b>Nom complet :</b> <Link to={path}>{firstname} {lastname}</Link><br />
										{civilityJSX}
										{birthdayJSX}
										<b>E-mail :</b> {email}<br />
										<b>Compte activé :</b> {active}
									</p>
								)
							})}
						</div>
					</div>
				</div>
			)
		}
		else
		{
			stays.sort((a, b) => {
				return a.stay_number - b.stay_number
			})
			const search = (this.search === true) ? 'Résultats de votre recherche' : 'Liste de touts les séjours'
			let key = -1
			return (
				<div className="wrapper-content">
					<div className="row">
						<div className="module col s12">
							<h2 className="title-description">{search}</h2>
							<p>Nombre de lignes : {stays.length}</p>
							<table>
								<thead>
									<tr>
										<td>N° Séjour</td>
										<td>N° Client</td>
										<td>Date d'arrivée</td>
										<td>Date de départ</td>
										<td>Emplacement</td>
										<td>Création</td>
										<td>Édition</td>
									</tr>
								</thead>
								<tbody>
									{stays.map(({ id, arrival, client_number, created_by, created_time, departure, edited_by, edited_time, location, stay_number }) => {
										key++
										let create = parseDate(created_time)
										let edited = parseDate(edited_time)
										const client_path = '/users/clients/' + client_number
										return (
											<tr key={key}>
												<td><button type="button" className="waves-effect waves-light btn-small" onClick={this.handleLink.bind(this, stay_number)}>{ stay_number }</button></td>
												<td><Link to={client_path}>{ client_number }</Link></td>
												<td>{ arrival }</td>
												<td>{ departure }</td>
												<td>{ location }</td>
												<td>{ create }</td>
												<td>{ edited }</td>
											</tr>
										)
									})}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			)
		}
	}
}

export default Stays

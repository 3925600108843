import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import Loader from '../UI/Loader'
import Modal from '../UI/Modal'
import tools from '../../tools'
import M from 'materialize-css'
import './Services.css'
import connexion from '../../connexion'
import OnlyPremiumEmpty from '../UI/OnlyPremiumEmpty'
import PremiumAlert from '../UI/PremiumAlert'

class Bookings extends Component {
	constructor(props) {
		super(props)
		this.date = new Date(tools.getTimestamp() * 1000)
		const today = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate())
		this.state = {
			init_modal: false,
			client: null,
			clients: null,
			services: null,
			service: null,
			service_benefit: null,
			service_benefits: null,
			service_workers: null,
			service_reservations: null,
			service_workers_cols: null,
			hours: [],
			workers_cols: [],
			webapp_users: null,
			users: [],
			timestamp: Math.round(today.getTime() / 1000),
			delete: null,
			open_modal: false,
			details: null,
			step: 1,
			form1: {
				service: ''
			},
			form2: {
				service_worker: '',
				service_benefit: '',
				client_number: '',
				user: '',
				day: '',
				month: '',
				hour: '',
				minute: ''
			},
			form3: {
				client_number: '',
				identity: '',
				age: '',
				phone: '',
				answers: []
			},
			success: null,
			error: this._initErrors()
		}
		this.step = 600
		this.tableRef = React.createRef()
		this.props.updateNavbarTitle('Services')
	}

	componentDidMount() {
		this.loadServices()
		this.loadServiceBenefits()
		this.loadServiceWorkers()
		this.loadClients()
		this.loadWebappUsers()
	}

	componentDidUpdate(prevProps, prevState) {
		const elems = document.querySelectorAll('select')
		M.FormSelect.init(elems)
		if (prevProps.service_benefits !== this.props.service_benefits)
			this.loadServiceBenefits(true)
		if (prevProps.service_workers !== this.props.service_workers)
			this.loadServiceWorkers(true)
		if (prevProps.clients !== this.props.clients)
			this.loadClients(true)
		if (prevProps.webapp_users !== this.props.webapp_users)
			this.loadWebappUsers(true)
		if (prevProps.service_reservations !== this.props.service_reservations)
			this.setState({ service_reservations: this.props.service_reservations })
		if (prevState.service_reservations !== this.state.service_reservations || prevState.timestamp !== this.state.timestamp ||
			prevState.service !== this.state.service)
			this._constructCalendar()
		if (this.state.init_modal === false)
		{
			const modals = document.querySelectorAll('.modal')
			if (modals.length > 0)
			{
				M.Modal.init(modals)
				this.setState({ init_modal: true })
			}
		}
		if (prevState.service !== this.state.service)
		{
			const element = document.getElementById("booking")
			if (element)
				element.scrollTo(0, 1440)
		}
	}

	_initErrors = () => {
		const errors = {
			global: null,
			service_worker: null,
			service_benefit: null,
			date: null,
			hour: null,
			client_number: null,
			identity: null,
			age: null,
			phone: null,
			answers: []
		}
		if (this.state && this.state.benefit)
			for (let i = 0; i < this.state.benefit.questions.length; i++)
				errors.answers.push(null)
		return errors
	}

	setErrorMsg = (key, msg) => {
		let error = this.state.error
		error[key] = msg
		this.setState({ success: null, error: error })
	}

	setSuccessMsg = (msg) => {
		const error = this._initErrors()
		this.setState({ success: msg, error: error })
	}

	clearFlash = () => {
		const error = this._initErrors()
		this.setState({ error: error, success: null })
	}

	loadServices = (fromProps = false) => {
		if (fromProps === true && this.props.services)
			this.setState({ services: this.props.services })
		else
		{
			this.props.fetchServices()
			.then(ret => {
				this.setState({ services: ret })
			})
			.catch(err => console.log(err))
		}
	}

	loadServiceBenefits = (fromProps = false) => {
		if (fromProps === true && this.props.service_benefits)
			this.setState({ service_benefits: this.props.service_benefits })
		else
		{
			this.props.fetchServiceBenefits()
			.then(ret => {
				this.setState({ service_benefits: ret })
			})
			.catch(err => console.log(err))
		}
	}

	loadServiceWorkers = (fromProps = false) => {
		if (fromProps === true && this.props.service_workers)
			this.setState({ service_workers: this.props.service_workers })
		else
		{
			this.props.fetchServiceWorkers()
			.then(ret => {
				this.setState({ service_workers: ret })
			})
			.catch(err => console.log(err))
		}
	}

	loadClients = (fromProps = false) => {
		if (fromProps === true && this.props.clients)
			this.setState({ clients: this.props.clients })
		else
		{
			this.props.fetchClients()
			.then(ret => {
				this.setState({ clients: ret })
			})
			.catch(err => console.log(err))
		}
	}

	loadWebappUsers = (fromProps = false) => {
		if (fromProps === true && this.props.webapp_users)
			this.setState({ webapp_users: this.props.webapp_users })
		else
		{
			this.props.fetchWebappUsers()
			.then(ret => {
				this.setState({ webapp_users: ret })
			})
			.catch(err => console.log(err))
		}
	}

	_constructCalendar = () => {
		// identification des service_workers appartenant au service
		// on crée 2 tableaux, workers_cols et workers
		// le premier est une matrice qui sera contenue dans le tableau représentant la grille horaire (hours)
		// le second contient toute les réservations pour chaque worker
		let workers = []
		let workers_cols = []
		const service_workers = this.state.service_workers
		if (service_workers)
		{
			for (let i = 0; i < service_workers.length; i++)
			{
				for (let j = 0; j < service_workers[i].services.length; j++)
				{
					if (service_workers[i].service === this.state.service._id)
					{
						workers_cols.push({
							_id: service_workers[i]._id,
							hover: false,
							reservations: [],
							reservation_lists: [],
							group: null
						})
						workers.push({
							_id: service_workers[i]._id,
							reservations: []
						})
						break
					}
				}
			}
		}

		// création du tableau des heures
		// chaque ligne de temps contient un tableau de la taille des service_workers appartenant au service
		const timestamp = this.state.timestamp
		const today = new Date(timestamp * 1000)
		const date = new Date(today.getFullYear(), today.getMonth(), today.getDate())
		let day = date.getDate()
		if (day < 10)
			day = '0' + day
		let month = date.getMonth() + 1
		if (month < 10)
			month = '0' + month
		let hours = []
		const time = Math.round(date.getTime() / 1000)

		// création d'une grille d'heures tous les 1/4 d'heure
		for (let i = time; i < time + 86400; i += this.step)
		{
			hours.push({
				time: i, // timestamp de la case
				workers_cols: tools.clone(workers_cols) // service_workers appartenant au service
			})
		}

		// ajout des réservations au planning
		const service_reservations = this.state.service_reservations
		if (service_reservations)
		{
			// on ajoute chaque réservation à la ligne d'horaire correspondante et au service_worker correspondant
			for (let i = 0; i < service_reservations.length; i++)
			{
				for (let j = 0; j + 1 < hours.length; j++)
				{
					if (service_reservations[i].start >= hours[j].time && service_reservations[i].start + this.step <= hours[j + 1].time)
					{
						for (let k = 0; k < hours[j].workers_cols.length; k++)
						{
							if (service_reservations[i].service_worker === hours[j].workers_cols[k]._id)
							{
								hours[j].workers_cols[k].reservations.push(service_reservations[i])
								break
							}
						}
					}
				}
			}

			// on ajoute les réservations à chaque worker
			service_reservations.sort((a, b) => {
				return a.end - b.end
			})
			service_reservations.sort((a, b) => {
				return a.start - b.start
			})
			for (let j = 0; j < service_reservations.length; j++)
			{
				for (let i = 0; i < workers.length; i++)
				{
					if (service_reservations[j].service_worker === workers[i]._id)
					{
						workers[i].reservations.push(service_reservations[j])
						break
					}
				}
			}

			// on crée des "groupes" de réservations dans le tableau workers
			// chaque groupe est un ensemble de réservations au même créneau ou se chevauchant
			for (let i = 0; i < workers.length; i++)
			{
				let groups = []
				let key = 0
				let nb_groups = 0
				for (let j = 1; j < workers[i].reservations.length; j++)
				{
					// si les 2 réservations se chevauchent, on crée nouveau groupe de réservation
					const before = workers[i].reservations[j - 1]
					const current = workers[i].reservations[j]
					if (before.end <= current.start)
					{
						let found = false
						for (let k = 0; k + key < j; k++)
						{
							if (workers[i].reservations[k + key].end > current.start)
							{
								found = true
								break
							}
						}
						if (!found)
						{
							groups.push([])
							let k = 0
							while (k + key < j)
							{
								groups[nb_groups].push(workers[i].reservations[k + key])
								k++
							}
							key += k
							nb_groups++
						}
					}
				}
				if (key < workers[i].reservations.length)
				{
					groups.push([])
					while (key < workers[i].reservations.length)
					{
						groups[nb_groups].push(workers[i].reservations[key])
						key++
					}
				}
				workers[i].groups = groups
			}

			if (this.state.service.list_mode === true) // vue listes
			{
				for (let i = 0; i < hours.length; i++)
				{
					for (let j = 0; j < hours[i].workers_cols.length; j++)
					{
						const worker_col = hours[i].workers_cols[j]
						let groups = null
						for (let k = 0; k < workers.length; k++)
						{
							if (workers[k]._id === worker_col._id)
							{
								groups = workers[k].groups
								break
							}
						}
						if (groups)
						{
							let m = 0
							let reservation_list = null
							while (m < worker_col.reservations.length)
							{
								const first_reservation = worker_col.reservations[m]

								// on identifie le groupe auquel appartient la réservation
								let group_id = null
								for (let k = 0; k < groups.length; k++)
								{
									for (let n = 0; n < groups[k].length; n++)
									{
										if (groups[k][n]._id === first_reservation._id)
										{
											group_id = k
											break
										}
									}
									if (group_id)
										break
								}

								if (group_id !== null)
								{
									reservation_list = []
									for (let n = 0; n < groups[group_id].length; n++)
									{
										// si la réservation fait partie de la même prestation, on la rassemble au sein de la liste
										if (groups[group_id][n].service_benefit === first_reservation.service_benefit)
										{
											reservation_list.push(groups[group_id][n])
											// on supprime ensuite la réservation des réservations
											for (let c = 0; c < hours[i].workers_cols[j].reservations.length; c++)
											{
												if (groups[group_id][n]._id === hours[i].workers_cols[j].reservations[c]._id)
												{
													// décommenter ça fait buguer l'affichage du planning lorsqu'on fait un hover sur un groupe de réservations
													hours[i].workers_cols[j].reservations.splice(c, 1)
													break
												}
											}
										}
									}
								}
								if (reservation_list !== null)
								{
									hours[i].workers_cols[j].reservation_lists.push({
										service_benefit: reservation_list[0].service_benefit,
										start: reservation_list[0].start,
										end: reservation_list[0].end,
										reservation_list: reservation_list
									})
									m = 0
								}
								else
									m++
							}
						}
					}
				}
			}
			else // vue créneaux (par défaut)
			{
				// on parcourt le tableau représentant la grille horaire pour lui ajouter des paramètres
				// on ajoute le paramètre group_size qui permet de connaître la taille du groupe à laquelle appartient la réservation
				for (let i = 0; i < hours.length; i++)
				{
					for (let j = 0; j < hours[i].workers_cols.length; j++)
					{
						const worker_col = hours[i].workers_cols[j]
						let groups = null
						for (let k = 0; k < workers.length; k++)
						{
							if (workers[k]._id === worker_col._id)
							{
								groups = workers[k].groups
								break
							}
						}
						if (groups)
						{
							for (let m = 0; m < worker_col.reservations.length; m++)
							{
								for (let k = 0; k < groups.length; k++)
								{
									for (let n = 0; n < groups[k].length; n++)
									{
										if (groups[k][n]._id === worker_col.reservations[m]._id)
										{
											worker_col.reservations[m].group_size = groups[k].length
											break
										}
									}
								}
							}
						}
					}
				}
			}
		}
		this.setState({ hours: hours, workers_cols: workers_cols })
	}
	
	handleService = (event) => {
		const form1 = {
			...this.state.form1,
			service: event.target.value
		}
		this.setState({ form1: form1 })
	}

	selectService = (event) => {
		event.preventDefault()
		let service = null
		const services = this.state.services
		for (let i = 0; i < services.length; i++)
		{
			if (services[i]._id === this.state.form1.service)
			{
				service = services[i]
				break
			}
		}
		if (service)
		{
			this.props.fetchServiceReservationsByService(service._id)
			.then(service_reservations => {
				const users = this.state.webapp_users
				for (let i = 0; i < service_reservations.length; i++)
				{
					for (let j = 0; j < users.length; j++)
					{
						if (users[j]._id === service_reservations[i].user)
						{
							service_reservations[i].user = users[j]
							break
						}
					}
				}
				const booking_table = document.getElementById("booking-table")
				setTimeout(() => {
					booking_table.scroll(0, 1440) // scroll to 8am : 1440px (30px * 6 * 8)
				}, 100)
				this.setState({ service_reservations: service_reservations })
			})
		}
		this.setState({ service: service })
	}

	handleServiceWorker = (event) => {
		const form2 = {
			...this.state.form2,
			service_worker: event.target.value
		}
		this.setState({ form2: form2 })
	}

	handleServiceBenefit = (event) => {
		const form2 = {
			...this.state.form2,
			service_benefit: event.target.value
		}
		this.setState({ form2: form2 })
	}

	handleClientNumberOne = (event) => {
		const form2 = {
			...this.state.form2,
			client_number: event.target.value
		}
		this.setState({ form2: form2 })
	}

	selectClientNumber = () => {
		if (this.state.form2.client_number.length > 0)
		{
			let client = null
			const clients = this.state.clients
			for (let i = 0; i < clients.length; i++)
			{
				if (clients[i].client_number === Number(this.state.form2.client_number))
				{
					client = clients[i]
					break
				}
			}
			let users = []
			if (client)
			{
				const webapp_users = this.state.webapp_users
				for (let i = 0; i < webapp_users.length; i++)
					if (webapp_users[i].client_number === Number(this.state.form2.client_number))
						users.push(webapp_users[i])
			}
			this.setState({ client: client, users: users })
		}
	}

	handleUser = (event) => {
		const form2 = {
			...this.state.form2,
			user: event.target.value
		}
		this.setState({ form2: form2 })
	}

	handleDay = (event) => {
		const form2 = {
			...this.state.form2,
			day: event.target.value
		}
		this.setState({ form2: form2 })
	}

	handleMonth = (event) => {
		const form2 = {
			...this.state.form2,
			month: event.target.value
		}
		this.setState({ form2: form2 })
	}

	handleHour = (event) => {
		const form2 = {
			...this.state.form2,
			hour: event.target.value
		}
		this.setState({ form2: form2 })
	}

	handleMinute = (event) => {
		const form2 = {
			...this.state.form2,
			minute: event.target.value
		}
		this.setState({ form2: form2 })
	}

	_validateForm1 = () => {
		let error_obj = this._initErrors()
		let error = false
		const service_worker = this.state.form2.service_worker
		const service_benefit = this.state.form2.service_benefit
		const day = Number(this.state.form2.day)
		const month = Number(this.state.form2.month)
		const hour = Number(this.state.form2.hour)
		const minute = Number(this.state.form2.minute)
		if (service_worker.length === 0)
		{
			error_obj.service_worker = "Veuillez sélectionner une prestataire"
			error = true
		}
		if (service_benefit.length === 0)
		{
			error_obj.service_benefit = "Veuillez sélectionner une prestation"
			error = true
		}
		if (month < this.date.getMonth() || (month === this.date.getMonth() && day < this.date.getDate()))
		{
			error_obj.date = "Veuillez choisir une date après la date du jour"
			error = true
		}
		else if ((month === this.date.getMonth() && day === this.date.getDate()) && (hour < this.date.getHours() || (hour === this.date.getHours() && minute < this.date.getMinutes())))
		{
			error_obj.hour = "Veuillez choisir une heure dans le futur"
			error = true
		}
		if (error === true)
		{
			error_obj.global = "Il y a une ou plusieurs erreur(s) dans le formulaire"
			this.setState({ success: null, error: error_obj })
		}
		return (!error) ? true : false
	}

	submitStepOne = (event) => {
		event.preventDefault()
		if (this._validateForm1())
		{
			let benefit = null
			for (let i = 0; i < this.state.service_benefits.length; i++)
			{
				if (this.state.form2.service_benefit === this.state.service_benefits[i]._id)
				{
					benefit = this.state.service_benefits[i]
					break
				}
			}
			const client_number = this.state.form2.client_number
			let user = null
			if (this.state.form2.user.length > 0)
			{
				for (let i = 0; i < this.state.webapp_users.length; i++)
				{
					if (this.state.webapp_users[i]._id === this.state.form2.user)
					{
						user = this.state.webapp_users[i]
						break
					}
				}
			}
			let identity = ""
			let age = ""
			let phone = ""
			if (user)
			{
				if (user.civility && user.civility.length > 0)
				{
					if (user.civility === "femme")
						identity = "Mme. "
					else if (user.civility === "homme")
						identity = "Mr. "
				}
				identity += user.firstname + " " + user.lastname
				if (user.birthday && user.birthday.length > 0)
				{
					const day = Number(user.birthday.substr(0, 2))
					const month = Number(user.birthday.substr(3, 2)) - 1
					const year = Number(user.birthday.substr(6, 4))
					age = this.date.getFullYear() - year
					if (month > this.date.getMonth() || (month === this.date.getMonth() && day > this.date.getDate()))
						age--
				}
				if (user.phone && user.phone.length > 0)
					phone = user.phone
			}
			const client = this.state.client
			if (client)
			{
				if (identity.length === 0)
				{
					if (client.civility && client.civility.length > 0)
					{
						if (client.civility === "femme")
							identity = "Mme. "
						else if (client.civility === "homme")
							identity = "Mr. "
					}
					identity += client.firstname + " " + client.lastname
				}
				if (age.length === 0 && client.birthday && client.birthday.length > 0)
				{
					const day = Number(client.birthday.substr(0, 2))
					const month = Number(client.birthday.substr(3, 2)) - 1
					const year = Number(client.birthday.substr(6, 4))
					age = this.date.getFullYear() - year
					if (month > this.date.getMonth() || (month === this.date.getMonth() && day > this.date.getDate()))
						age--
				}
				if (phone.length === 0)
				{
					if (client.phone1 && client.phone1.length > 0)
						phone = client.phone1
					else if (client.phone2 && client.phone2.length > 0)
						phone = client.phone2
					else if (client.phone3 && client.phone3.length > 0)
						phone = client.phone3
				}
			}
			let answers = []
			for (let i = 0; i < benefit.questions.length; i++)
				answers.push('')
			const form3 = {
				client_number: client_number,
				identity: identity,
				age: age,
				phone: phone,
				answers: answers
			}
			this.clearFlash()
			this.setState({ service_benefit: benefit, step: 2, form3: form3 })
		}
	}

	handleClientNumberTwo = (event) => {
		const form3 = {
			...this.state.form3,
			client_number: event.target.value
		}
		this.setState({ form3: form3 })
	}

	handleIdentity = (event) => {
		const form3 = {
			...this.state.form3,
			identity: event.target.value
		}
		this.setState({ form3: form3 })
	}

	handleAge = (event) => {
		const form3 = {
			...this.state.form3,
			age: event.target.value
		}
		this.setState({ form3: form3 })
	}

	handlePhone = (event) => {
		const form3 = {
			...this.state.form3,
			phone: event.target.value
		}
		this.setState({ form3: form3 })
	}

	handleAnswer = (question_nb, event) => {
		const answers = this.state.form3.answers
		answers[question_nb] = event.target.value
		const form3 = {
			...this.state.form3,
			answers: answers
		}
		this.setState({ form3: form3 })
	}

	_validateForm2 = () => {
		let error_obj = this._initErrors()
		let error = false
		const benefit = this.state.service_benefit
		const client_number = this.state.form3.client_number
		const identity = this.state.form3.identity
		const age = this.state.form3.age
		const phone = this.state.form3.phone
		const answers = this.state.form3.answers
		if (benefit.client_number === true && client_number.length === 0)
		{
			error_obj.client_number = "Veuillez saisir le numéro client"
			error = true
		}
		else if (benefit.client_number === true && !/^[0-9]+$/.test(client_number))
		{
			error_obj.client_number = "Le numéro client doit être un nombre entier"
			error = true
		}
		if (benefit.identity === true && identity.length === 0)
		{
			error_obj.identity = "Veuillez saisir le nom et prénom du client"
			error = true
		}
		if (benefit.age === true && age.length === 0)
		{
			error_obj.age = "Veuillez saisir l'âge du client"
			error = true
		}
		else if (benefit.age === true && !/^[0-9]+$/.test(age))
		{
			error_obj.age = "L'âge du client doit être un nombre entier"
			error = true
		}
		if (benefit.phone === true && phone.length === 0)
		{
			error_obj.phone = "Veuillez saisir le numéro de téléphone du client"
			error = true
		}
		if (benefit.questions.length > 0)
		{
			for (let i = 0; i < benefit.questions.length; i++)
			{
				const answer = answers[i]
				const question = benefit.questions[i]
				if (question.mandatory === true && answer.length === 0)
				{
					error_obj.answers[i] = "Veuillez répondre à cette question"
					error = true
				}
				else if (question.type === "number" && !/^[0-9]+$/.test(answer))
				{
					error_obj.answers[i] = "Cette question attend comme réponse un nombre entier"
					error = true
				}
			}
		}
		if (error === true)
		{
			error_obj.global = "Il y a une ou plusieurs erreur(s) dans le formulaire"
			this.setState({ success: null, error: error_obj })
		}
		return (!error) ? true : false
	}

	bookClient = (event) => {
		event.preventDefault()
		if (this._validateForm2())
		{
			const year = this.date.getFullYear()
			const benefit = this.state.service_benefit
			const start = Math.floor(new Date(year, this.state.form2.month, this.state.form2.day, this.state.form2.hour, this.state.form2.minute, 0).getTime() / 1000)
			const end = start + (benefit.duration_hour * 3600) + (benefit.duration_minute * 60)
			const reservation = {
				service: this.state.service._id,
				service_worker: this.state.form2.service_worker,
				service_benefit: this.state.form2.service_benefit,
				start: start,
				end: end,
				answers: this.state.form3.answers,
				user: (this.state.form2.user) ? this.state.form2.user : null,
				client_number: this.state.form3.client_number,
				identity: this.state.form3.identity,
				age: this.state.form3.age,
				phone: this.state.form3.phone
			}
			this.props.insertServiceReservation(reservation)
			.then(ret => {
				if (ret === false)
					this.setErrorMsg("global", "Une erreur s'est produite")
				else
				{
					this.setSuccessMsg("La réservation a bien été ajoutée")
					this.closeModal()
				}
			})
			.catch(err => {
				console.log(err)
				this.setErrorMsg("global", "Une erreur s'est produite")
			})
		}
	}

	focusTime = (timetxt, cols_k) => {
		const hour = timetxt.substr(0, 2)
		const minute = timetxt.substr(3, 2)
		const timestamp = this.state.timestamp + hour * 3600 + minute * 60
		const hours = this.state.hours
		for (let i = 0; i < hours.length; i++)
		{
			for (let j = 0; j < hours[i].workers_cols.length; j++)
			{
				if (timestamp === hours[i].time && j === cols_k % hours[i].workers_cols.length &&
					hours[i].workers_cols[j].reservations.length === 0 && hours[i].workers_cols[j].reservation_lists.length === 0)
					hours[i].workers_cols[j].hover = true
				else
					hours[i].workers_cols[j].hover = false
			}
		}
		this.setState({ hours: hours })
	}

	clearTime = () => {
		const hours = this.state.hours
		for (let i = 0; i < hours.length; i++)
			hours[i].hover = false
		this.setState({ hours: hours })
	}

	prevDay = () => {
		const timestamp = this.state.timestamp - 86400
		this.setState({ timestamp: timestamp })
	}

	nextDay = () => {
		const timestamp = this.state.timestamp + 86400
		this.setState({ timestamp: timestamp })
	}

	modalDetails = (reservation) => {
		// on met un timeout car on veut que modalDetails() s'éxécute après modalAddReservation() après le clic pour set la variable this.modal
		setTimeout(() => {
			this.modal = "details"
			this.setState({ step: 3, details: reservation, error: this._initErrors(), success: null })
			const modal = document.getElementById("modal2")
			const instance = M.Modal.getInstance(modal)
			instance.open()
		}, 10)
	}

	modalAddReservation = (hour, minute, worker) => {
		// modalAddReservation() s'éxécute après modalDetails()
		// de cette façon, si on clique sur une réservation, on set d'abord this.modal à "reservation" et ensuite elle est set à "details" si on a cliqué sur modalDetails()
		// ensuite on met un timeout qui s'éxécute après modalDetails() et qui vérifie si on est bien en train de vouloir éxécuter modalAddReservation() si on a cliqué dessus
		this.modal = "reservation"
		setTimeout(() => {
			if (this.state.open_modal === false && this.modal === "reservation")
			{
				const date = new Date(this.state.timestamp * 1000)
				const day = date.getDate()
				const month = date.getMonth()
				const form2 = {
					service_worker: worker,
					service_benefit: '',
					client_number: '',
					user: '',
					day: day,
					month: month,
					hour: Number(hour),
					minute: Number(minute)
				}
				this.setState({ form2: form2, step: 1, error: this._initErrors(), success: null })
				const modals = document.querySelectorAll('.modal')
				const instance = M.Modal.getInstance(modals[1])
				instance.open()
			}
		}, 20)
	}

	closeModal = () => {
		const modals = document.querySelectorAll('.modal')
		const instance = M.Modal.getInstance(modals[1])
		instance.close()
	}

	deleteReservation = (service_reservation) => {
		this.setState({ delete: service_reservation, open_modal: true })
	}

	_printReservationsList = (_id, nb_workers, reservation_lists, hover, timetxt, cols_k, hour, minute) => {
		let resas_k = -1
		const service_benefits = this.state.service_benefits
		const tdWidth = ((this.tableRef.current.offsetWidth * 0.9) / nb_workers) - 5
		const tdStyle = {
			width: tdWidth + 'px'
		}
		let texthover = ""
		let classname = "booking-col"
		if (hover === true)
		{
			classname = "booking-col hover"
			texthover = timetxt
		}
		const id = texthover + ',' + cols_k
		return (
			<div key={cols_k} style={tdStyle} className={classname} id={id} onClick={this.modalAddReservation.bind(this, hour, minute, _id)} onMouseLeave={this.clearTime} onMouseEnter={this.focusTime.bind(this, timetxt, cols_k)}>
				{texthover}
				{this.tableRef.current && reservation_lists.map(({ service_benefit, start, end, reservation_list }) => {
					resas_k++
					let benefit = null
					for (let i = 0; i < service_benefits.length; i++)
					{
						if (service_benefits[i]._id === service_benefit)
						{
							benefit = service_benefits[i]
							break
						}
					}
					if (benefit)
					{
						let subtitle = ""
						for (let i = 0; i < benefit.translations.length; i++)
						{
							if (benefit.translations[i].lang === this.props.lang_default)
							{
								subtitle = benefit.translations[i].subtitle
								break
							}
						}
						const listJSX = reservation_list.map(({ _id, start, end, service_benefit, group_size, answers, client_number, identity, age, phone }) => {
							return (
								<span key={_id}><b>#{client_number}</b> {identity}<br /></span>
							)
						})
						const height = (((end - start) / this.step) * 31) - 5
						const width = (tdWidth / reservation_lists.length) - 5
						const style = {
							height: height + 'px',
							width: width + 'px'
						}
						const start_date = new Date(start * 1000)
						let hour_reservation = start_date.getHours()
						if (hour_reservation < 10)
							hour_reservation = '0' + hour_reservation
						let minute_reservation = start_date.getMinutes()
						if (minute_reservation < 10)
							minute_reservation = '0' + minute_reservation
						const start_txt = hour_reservation + ':' + minute_reservation
						const end_date = new Date(end * 1000)
						hour_reservation = end_date.getHours()
						if (hour_reservation < 10)
							hour_reservation = '0' + hour_reservation
						minute_reservation = end_date.getMinutes()
						if (minute_reservation < 10)
							minute_reservation = '0' + minute_reservation
						const end_txt = hour_reservation + ':' + minute_reservation
						return (
							<div key={resas_k} className="reservation" onClick={this.modalDetails.bind(this, reservation_lists[resas_k])} onMouseEnter={this.clearTime} style={style}>
								<p>
									<b>{start_txt} - {end_txt}</b> : {subtitle}<br />
									<b>Nombre de réservations :</b> {reservation_list.length}<br />
									{listJSX}
								</p>
							</div>
						)
					}
					else
						return null
				})}
			</div>
		)
	}

	_printReservationsSlot = (_id, nb_workers, reservations, hover, timetxt, cols_k, hour, minute) => {
		const service_benefits = this.state.service_benefits
		let resas_k = -1
		const tdWidth = ((this.tableRef.current.offsetWidth * 0.9) / nb_workers) - 5
		const tdStyle = {
			width: tdWidth + 'px'
		}
		let texthover = ""
		let classname = "booking-col"
		if (hover === true)
		{
			classname = "booking-col hover"
			texthover = timetxt
		}
		const id = texthover + ',' + cols_k
		return (
			<div key={cols_k} style={tdStyle} className={classname} id={id} onClick={this.modalAddReservation.bind(this, hour, minute, _id)} onMouseLeave={this.clearTime} onMouseEnter={this.focusTime.bind(this, timetxt, cols_k)}>
				{texthover}
				{this.tableRef.current && reservations.map(({ _id, start, end, from, service_benefit, group_size, answers, client_number, identity, age, phone }) => {
					resas_k++
					const height = (((end - start) / this.step) * 31) - 5
					const width = (tdWidth / group_size) - 5
					const style = {
						height: height + 'px',
						width: width + 'px'
					}
					const start_date = new Date(start * 1000)
					let hour_reservation = start_date.getHours()
					if (hour_reservation < 10)
						hour_reservation = '0' + hour_reservation
					let minute_reservation = start_date.getMinutes()
					if (minute_reservation < 10)
						minute_reservation = '0' + minute_reservation
					const start_txt = hour_reservation + ':' + minute_reservation
					const end_date = new Date(end * 1000)
					hour_reservation = end_date.getHours()
					if (hour_reservation < 10)
						hour_reservation = '0' + hour_reservation
					minute_reservation = end_date.getMinutes()
					if (minute_reservation < 10)
						minute_reservation = '0' + minute_reservation
					const end_txt = hour_reservation + ':' + minute_reservation
					let answersJSX = null
					let benefit = null
					for (let i = 0; i < service_benefits.length; i++)
					{
						if (service_benefits[i]._id === service_benefit)
						{
							benefit = service_benefits[i]
							break
						}
					}
					if (benefit)
					{
						const questions = benefit.questions
						if (questions.length > 0)
						{
							let answers_k = -1
							answersJSX = (
								<p>
									{questions.map(({ translations }) => {
										let title = ""
										for (let i = 0; i < translations.length; i++)
										{
											if (translations[i].lang === this.props.lang_default)
											{
												title = translations[i].title
												break
											}
										}
										answers_k++
										const answer = answers[answers_k]
										return (<span key={answers_k}><b>{title}</b> : {answer}<br /></span>)
									})}
								</p>
							)
						}
						let subtitle = ""
						for (let i = 0; i < benefit.translations.length; i++)
						{
							if (benefit.translations[i].lang === this.props.lang_default)
							{
								subtitle = benefit.translations[i].subtitle
								break
							}
						}
						let infos = null
						let line1 = null
						let line2 = null
						if (benefit.client_number || benefit.identity)
						{
							if (benefit.client_number && benefit.identity)
								line1 = (<span><b>#{client_number}</b> {identity}</span>)
							else if (benefit.client_number)
								line1 = (<span><b>#{client_number}</b></span>)
							else if (benefit.identity)
								line1 = (<span>{identity}</span>)
						}
						if (benefit.age || benefit.phone)
						{
							if (benefit.age && benefit.phone)
								line2 = (<span>Tél.: {phone}<br />{age} ans</span>)
							else if (benefit.age)
								line2 = (<span>{age} ans</span>)
							else if (benefit.phone)
								line2 = (<span>Tél.: {phone}</span>)
						}
						if (line1 && line2)
							infos = (<span>{line1}<br />{line2}</span>)
						else if (line1)
							infos = line1
						else if (line2)
							infos = line2
						let classname = "reservation"
						if (from === "dashboard")
							classname += " green"
						return (
							<div key={resas_k} className={classname} onClick={this.deleteReservation.bind(this, _id)} onMouseEnter={this.clearTime} style={style}>
								<p>
									<b>{start_txt} - {end_txt}</b> : {subtitle}<br />
									{infos}
								</p>
								{answersJSX}
							</div>
						)
					}
					else
						return (<div></div>)
				})}
			</div>
		)
	}

	_printAddReservation = () => {
		const service_benefits = this.state.service_benefits
		const service_workers = this.state.service_workers
		if (this.state.step === 1)
		{
			let workers = []
			let benefits = []
			if (this.state.service)
			{
				for (let i = 0; i < service_workers.length; i++)
				{
					let found = false
					for (let j = 0; j < service_workers[i].services.length; j++)
					{
						let benefit = null
						for (let k = 0; k < service_benefits.length; k++)
						{
							if (service_benefits[k]._id === service_workers[i].services[j])
							{
								benefit = service_benefits[k]
								break
							}
						}
						if (benefit && benefit.service === this.state.service._id)
						{
							found = true
							break
						}
					}
					if (found)
						workers.push(service_workers[i])
				}
				if (this.state.form2.service_worker)
				{
					let worker = null
					for (let i = 0; i < service_workers.length; i++)
					{
						if (service_workers[i]._id === this.state.form2.service_worker)
						{
							worker = service_workers[i]
							break
						}
					}
					for (let j = 0; j < worker.services.length; j++)
					{
						for (let i = 0; i < service_benefits.length; i++)
						{
							if (worker.services[j] === service_benefits[i]._id)
							{
								benefits.push(service_benefits[i])
								break
							}
						}
					}
				}
			}
			let days_arr = []
			for (let i = 1; i <= 31; i++)
			{
				let text = i
				if (text < 10)
					text = '0' + text
				days_arr[i - 1] = {
					value: i,
					text: text
				}
			}
			let months_arr = []
			for (let i = 1; i <= 12; i++)
			{
				let text = i
				if (text < 10)
					text = '0' + text
				months_arr[i - 1] = {
					value: i - 1,
					text: text
				}
			}
			let minutes_arr = []
			for (let i = 0; i <= 59; i++)
			{
				let text = i
				if (text < 10)
					text = '0' + text
				minutes_arr[i] = {
					value: i,
					text: text
				}
			}
			let hours_arr = []
			for (let i = 0; i <= 23; i++)
			{
				let text = i
				if (text < 10)
					text = '0' + text
				hours_arr[i] = {
					value: i,
					text: text
				}
			}
			let detailsJSX = null
			if (this.state.form2.service_benefit)
			{
				let benefit = null
				for (let i = 0; i < benefits.length; i++)
				{
					if (benefits[i]._id === this.state.form2.service_benefit)
					{
						benefit = benefits[i]
						break
					}
				}
				if (benefit)
				{
					let hours = ""
					if (benefit.duration_hour > 0)
						hours = benefit.duration_hour + "h"
					let minutes = ""
					if (benefit.duration_minute > 0)
					{
						if (benefit.duration_minute < 10)
							minutes = "0" + benefit.duration_minute
						else
							minutes = benefit.duration_minute
						if (hours.length === 0)
							minutes += "m"
					}
					let location = ""
					for (let i = 0; i < benefit.translations.length; i++)
					{
						if (benefit.translations[i].lang === this.props.lang_default)
						{
							location = benefit.translations[i].location
							break
						}
					}
					let price = ""
					if (benefit.price === 0)
						price = "Gratuit"
					else
						price = benefit.price + "€"
					detailsJSX = (
						<p>
							<b>Durée :</b> {hours}{minutes}<br />
							<b>Lieu :</b> {location}<br />
							<b>Prix :</b> {price}
						</p>
					)
				}
			}
			const users = this.state.users
			return (
				<form className="module-form" onSubmit={this.submitStepOne}>
					<label>
						<p className="error">{this.state.error.service_worker}</p>
						<p className="column-20">Prestataire :</p>
						<select value={this.state.form2.service_worker} onChange={this.handleServiceWorker}>
							<option value="">-- Sélectionez un prestataire --</option>
							{workers.map(({ _id, firstname, lastname }) => {
								return (
									<option value={_id} key={_id}>{firstname} {lastname}</option>
								)
							})}
						</select>
					</label>
					<label>
						<p className="error">{this.state.error.service_benefit}</p>
						<p className="column-20">Prestation :</p>
						<select value={this.state.form2.service_benefit} onChange={this.handleServiceBenefit}>
							<option value="">-- Sélectionez une prestation --</option>
							{benefits.map(({ _id, translations }) => {
								let subtitle = ""
								for (let i = 0; i < translations.length; i++)
								{
									if (translations[i].lang === this.props.lang_default)
									{
										subtitle = translations[i].subtitle
										break
									}
								}
								return (
									<option value={_id} key={_id}>{subtitle}</option>
								)
							})}
						</select>
					</label>
					{detailsJSX}
					<label>
						<p className="column-20">Numéro client :</p>
						<input value={this.state.form2.client_number} onChange={this.handleClientNumberOne} onBlur={this.selectClientNumber} />
					</label>
					<label>
						<p className="column-20">Utilisateur :</p>
						<select value={this.state.form2.user} onChange={this.handleUser}>
							<option value="">Aucun utilisateur</option>
							{users.map(({ _id, civility, firstname, lastname }) => {
								let text = ""
								if (civility === "femme")
									text = "Mme. "
								else if (civility === "homme")
									text = "Mr. "
								text += firstname + " " + lastname
								return (
									<option value={_id} key={_id}>{text}</option>
								)
							})}
						</select>
					</label>
					<p className="error">{this.state.error.date}</p>
					<p className="column-20">Date :</p>
					<div className="form-group">
						<div className="col s2">
							<label>
								<p>Jour :</p>
								<select value={this.state.form2.day} onChange={this.handleDay}>
									<option value=""></option>
									{days_arr.map(({ text, value }) => {
										return (<option key={value} value={value}>{text}</option>)
									})}
								</select>
							</label>
						</div>
						<div className="col s2 offset-s1">
							<label>
								<p>Mois :</p>
								<select value={this.state.form2.month} onChange={this.handleMonth}>
									<option value=""></option>
									{months_arr.map(({ text, value }) => {
										return (<option key={value} value={value}>{text}</option>)
									})}
								</select>
							</label>
						</div>
					</div>
					<div className="clear"></div>
					<p className="error">{this.state.error.hour}</p>
					<div className="form-group">
						<div className="col s2">
							<label>
								<p>Heure :</p>
								<select value={this.state.form2.hour} onChange={this.handleHour}>
									<option value=""></option>
									{hours_arr.map(({ text, value }) => {
										return (<option key={value} value={value}>{text}</option>)
									})}
								</select>
							</label>
						</div>
						<div className="col s2 offset-s1">
							<label>
								<p>Minute :</p>
								<select value={this.state.form2.minute} onChange={this.handleMinute}>
									<option value=""></option>
									{minutes_arr.map(({ text, value }) => {
										return (<option key={value} value={value}>{text}</option>)
									})}
								</select>
							</label>
						</div>
					</div>
					<div className="clear"></div>
					<p className="error">{this.state.error.global}</p>
					<button type="submit" className="waves-effect waves-light btn-small">Enregistrer</button>
				</form>
			)
		}
		else if (this.state.step === 2)
		{
			const benefit = this.state.service_benefit
			let clientJSX = null
			let identityJSX = null
			let ageJSX = null
			let phoneJSX = null
			if (benefit.client_number === true)
			{
				clientJSX = (
					<label>
						<p className="error">{this.state.error.client_number}</p>
						<p className="column-20">Numéro client :</p>
						<input type="text" value={this.state.form3.client_number} onChange={this.handleClientNumberTwo} />
					</label>
				)
			}
			if (benefit.identity === true)
			{
				identityJSX = (
					<label>
						<p className="error">{this.state.error.identity}</p>
						<p className="column-20">Civilité, nom et prénom :</p>
						<input type="text" value={this.state.form3.identity} onChange={this.handleIdentity} />
					</label>
				)
			}
			if (benefit.age === true)
			{
				ageJSX = (
					<label>
						<p className="error">{this.state.error.age}</p>
						<p className="column-20">Âge :</p>
						<input type="text" value={this.state.form3.age} onChange={this.handleAge} />
					</label>
				)
			}
			if (benefit.phone === true)
			{
				phoneJSX = (
					<label>
						<p className="error">{this.state.error.phone}</p>
						<p className="column-20">Téléphone :</p>
						<input type="text" value={this.state.form3.phone} onChange={this.handlePhone} />
					</label>
				)
			}
			let questionsJSX = null
			if (benefit.questions)
			{
				let question_nb = -1
				questionsJSX = (
					<div>
						{benefit.questions.map(({ type, mandatory, translations }) => {
							question_nb++
							let title = ""
							for (let i = 0; i < translations.length; i++)
							{
								if (translations[i].lang === this.props.lang_default)
								{
									title = translations[i].title
									break
								}
							}
							if (mandatory === true)
								title += " (obligatoire)"
							return (
								<label key={question_nb}>
									<p className="error">{this.state.error.answers[question_nb]}</p>
									<p className="column-20">{title} :</p>
									<input type="text" value={this.state.form3.answers[question_nb]} onChange={this.handleAnswer.bind(this, question_nb)} />
								</label>
							)
						})}
					</div>
				)
			}
			return (
				<form className="module-form" onSubmit={this.bookClient}>
					{clientJSX}
					{identityJSX}
					{ageJSX}
					{phoneJSX}
					{questionsJSX}
					<p className="error">{this.state.error.global}</p>
					<button type="submit" className="waves-effect waves-light btn-small">Valider</button>
				</form>
			)
		}
	}

	_printDetails = () => {
		const details = this.state.details
		if (this.state.service.list_mode === true)
		{
			const service_benefits = this.state.service_benefits
			let benefit = null
			for (let i = 0; i < service_benefits.length; i++)
			{
				if (service_benefits[i]._id === details.service_benefit)
				{
					benefit = service_benefits[i]
					break
				}
			}
			let location = null
			let subtitle = null
			for (let i = 0; i < benefit.translations.length; i++)
			{
				if (benefit.translations[i].lang === this.props.lang_default)
				{
					location = benefit.translations[i].location
					subtitle = benefit.translations[i].subtitle
					break
				}
			}
			const start = new Date(details.start * 1000)
			let day = start.getDate()
			if (day < 10)
				day = "0" + day
			let month = start.getMonth() + 1
			if (month < 10)
				month = "0" + month
			let year = start.getFullYear()
			const date = day + "/" + month + "/" + year
			let start_hour = start.getHours()
			if (start_hour < 10)
				start_hour = "0" + start_hour
			let start_minute = start.getMinutes()
			if (start_minute < 10)
				start_minute = "0" + start_minute
			const start_txt = start_hour + "h" + start_minute
			const end = new Date(details.end * 1000)
			let end_hour = end.getHours()
			if (end_hour < 10)
				end_hour = "0" + end_hour
			let end_minute = end.getMinutes()
			if (end_minute < 10)
				end_minute = "0" + end_minute
			const end_txt = end_hour + "h" + end_minute
			let resas_k = 0
			return (
				<div className="booking-modal">
					<h1>{subtitle}</h1>
					<p>
						<b>Date :</b> {date}<br />
						<b>Heure de début :</b> {start_txt}<br />
						<b>Heure de fin :</b> {end_txt}<br />
						<b>Lieu :</b> {location}
					</p>
					{details.reservation_list.map(({ _id, answers, client_number, identity, age, phone }) => {
						resas_k++
						let infos = null
						let line1 = null
						let line2 = null
						if (benefit.client_number || benefit.identity)
						{
							if (benefit.client_number && benefit.identity)
								line1 = (<span><b>#{client_number}</b> {identity}</span>)
							else if (benefit.client_number)
								line1 = (<span><b>#{client_number}</b></span>)
							else if (benefit.identity)
								line1 = (<span>{identity}</span>)
						}
						if (benefit.age || benefit.phone)
						{
							if (benefit.age && benefit.phone)
								line2 = (<span>Tél.: {phone}<br />{age} ans</span>)
							else if (benefit.age)
								line2 = (<span>{age} ans</span>)
							else if (benefit.phone)
								line2 = (<span>Tél.: {phone}</span>)
						}
						if (line1 && line2)
							infos = (<span>{line1}<br />{line2}</span>)
						else if (line1)
							infos = line1
						else if (line2)
							infos = line2
						let answersJSX = null
						const questions = benefit.questions
						if (questions.length > 0)
						{
							let answers_k = -1
							answersJSX = (
								<p>
									{questions.map(({ translations }) => {
										let title = ""
										for (let i = 0; i < translations.length; i++)
										{
											if (translations[i].lang === this.props.lang_default)
											{
												title = translations[i].title
												break
											}
										}
										answers_k++
										const answer = answers[answers_k]
										return (<span key={answers_k}><b>{title}</b> : {answer}<br /></span>)
									})}
								</p>
							)
						}
						return (
							<div key={resas_k} className="reservation">
								<p>
									<span className="benefit-title">Réservation {resas_k} :</span><br />
									{infos}
								</p>
								{answersJSX}
								<button type="button" onClick={this.deleteReservation.bind(this, _id)} className="waves-effect waves-light btn red">Supprimer</button>
							</div>
						)
					})}
					<div className="clear"></div>
				</div>
			)
		}
		else
		{
			return (<p>mode créneau</p>)
		}
	}

	answerModal = (answer) => {
		if (answer === "yes")
		{
			this.props.deleteServiceReservation(this.state.delete)
			.then(ret => {
				console.log("delete reservation", ret)
				if (ret === true)
				{
					this.closeModal()
					this.setState({ delete: null, open_modal: false, step: 1 })
					this.setSuccessMsg("La réservation a bien été supprimée")
				}
				else
				{
					this.closeModal()
					this.setState({ delete: null, open_modal: false, step: 1 })
					this.setErrorMsg("global", "Une erreur s'est produite")
				}
			})
			.catch(err => {
				console.log(err)
				this.closeModal()
				this.setState({ delete: null, open_modal: false, step: 1 })
				this.setErrorMsg("global", "Une erreur s'est produite")
			})
		}
		else
			this.setState({ delete: null, open_modal: false })
	}

	render() {
		const services = this.state.services
		const service_workers = this.state.service_workers
		const clients = this.state.clients
		const webapp_users = this.state.webapp_users
		const permissions = this.props.user.permissions
		if (!permissions || !permissions.services || permissions.services === 0)
			return (<Redirect to="/" />)
		else if (services === null || service_workers === null || clients === null || webapp_users === null)
		{
			return (
				<div className="loader">
					<Loader />
				</div>
			)
		}
		if (this.props.features !== "premium")
		{
			return (
				<>
					<OnlyPremiumEmpty 
						title="Planning"
						subtitle="Visualisez l'ensemble de vos réservations & inscriptions"
						emptyFolder="Commencez par créer un service"
						emptyFolderSubtitle="Pas de réservations"
						navName="Services"
						navPath="/Services"
					/>
					<div className="mt-10">
						<PremiumAlert
							content="Mettez votre abonnement à niveau avec la formule premium pour accéder aux services !"
							button_text="En savoir plus"
						/>
					</div>
				</>
			)
		}
		else
		{
			const params = {
				timestamp: this.state.timestamp,
				service: (this.state.service) ? this.state.service._id : null
			}
			const path = tools.queryString(connexion.connect.url + 'webapp/service_reservations/extract_service_reservations.csv', params)
			const date = new Date(this.state.timestamp * 1000)
			const days_txt = ["dimanche", "lundi", "mardi", "mercredi", "jeudi", "vendredi", "samedi"]
			const months_txt = ["jan.", "fév.", "mars", "avr.", "mai", "juin", "juil.", "août", "sep.", "oct.", "nov.", "déc."]
			let day = date.getDate()
			if (day < 10)
				day = '0' + day
			let month = months_txt[date.getMonth()]
			let weekday = days_txt[date.getDay()]
			const year = date.getFullYear()
			const hours = this.state.hours
			let hours_k = -1
			let cols_k = -1
			let calendarJSX = null
			let commentJSX = null
			if (this.state.service)
			{
				// affichage du planning de réservations
				const workers = this.state.workers_cols
				if (this.state.service.comment)
					commentJSX = (<div className="module col s12" dangerouslySetInnerHTML={{ __html: this.state.service.comment }}></div>)
				let workers_k = -1
				calendarJSX = (
					<div className="module col s12">
						<a href={path} className="waves-effect waves-light btn">Extraire</a>
						<p className="error center">{this.state.error.global}</p>
						<p className="success center">{this.state.success}</p>
						<p className="booking-date">
							<span className="prev btn-floating waves-effect waves-light" onClick={this.prevDay}><i className="material-icons">chevron_left</i></span>
							<span className="date">{weekday} {day} {month} {year}</span>
							<span className="next btn-floating waves-effect waves-light" onClick={this.nextDay}><i className="material-icons">chevron_right</i></span>
						</p>
						<div className="booking-header">
							{workers.map(({ firstname, lastname }) => {
								workers_k++
								const tdWidth = (this.tableRef.current.offsetWidth * 0.9) / workers.length
								const tdStyle = {
									width: tdWidth + 'px'
								}
								let classname = "worker-name"
								if (workers_k === 0)
									classname = "first worker-name"
								return (
									<div style={tdStyle} className={classname} key={workers_k}>{firstname} {lastname}</div>
								)
							})}
						</div>
						<div className="clear"></div>
						<p>
							<span className="text-green-800 font-bold">En vert :</span> réservations depuis le dashboard<br />
							<span className="text-blue-800 font-bold">En bleu :</span> réservations depuis l'application
						</p>
						<div id="booking-table" className="booking-wrapper">
							<div className="booking" ref={this.tableRef}>
								<div className="hours">
									{hours.map(({ time }) => {
										const date = new Date(time * 1000)
										let hour = date.getHours()
										if (hour < 10)
											hour = '0' + hour
										let minute = date.getMinutes()
										if (minute < 10)
											minute = '0' + minute
										hours_k++
										let firstcol = (<div className="booking-hours">{hour}:{minute}</div>)
										if (hours_k % 6 !== 0)
											firstcol = null
										return (
											<div className="booking-row" key={hours_k}>
												{firstcol}
											</div>
										)
									})}
								</div>
								<div className="workers-cols">
									{hours.map(({ time, workers_cols }) => {
										const date = new Date(time * 1000)
										let hour = date.getHours()
										if (hour < 10)
											hour = '0' + hour
										let minute = date.getMinutes()
										if (minute < 10)
											minute = '0' + minute
										hours_k++
										const timetxt = hour + ":" + minute
										return (
											<div className="booking-row" key={hours_k}>
												{workers_cols.map(({ _id, reservations, reservation_lists, hover }) => {
													cols_k++
													if (this.state.service.list_mode === true)
														return this._printReservationsList(_id, workers_cols.length, reservation_lists, hover, timetxt, cols_k, hour, minute)
													else
														return this._printReservationsSlot(_id, workers_cols.length, reservations, hover, timetxt, cols_k, hour, minute)
												})}
											</div>
										)
									})}
								</div>
							</div>
						</div>
					</div>
				)
			}

			// bugfix étrange
			// avant les variables modalStep1, modalStep2 et modalStep3 étaient une seule et unique variable qui s'appelait modalJSX
			// cela fonctionnait très bien pendant plusieurs semaines, depuis le 10/08/2021 une erreur est apparue :
			// NotFoundError: Node.removeChild: The node to be removed is not a child of this node
			// cette erreur apparaissait sur les éléments de type "select" dans le DOM (handleServiceWorker et handleServiceBenefit)
			// le fait de séparer cela dans deux variables différentes (modalStep1 et modalStep2) a permis de supprimer le bug
			let modalStep1 = null
			let modalStep2 = null
			let modalStep3 = null
			const step = this.state.step
			if (step === 1)
				modalStep1 = this._printAddReservation()
			else if (step === 2)
				modalStep2 = this._printAddReservation()
			else if (step === 3)
				modalStep3 = this._printDetails()
			return (
				<div className="wrapper-content">
					<Modal
						dismissible={false}
						content="Vous êtes sur le point de supprimer définitivement cette réservation."
						open_modal={this.state.open_modal}
						answerModal={this.answerModal}
					/>
					<div id="modal2" className="modal">
						<div className="modal-content">
							{modalStep1}
							{modalStep2}
							{modalStep3}
						</div>
						<div className="modal-footer">
							<button onClick={this.closeModal} className="link waves-effect waves-green btn-flat">Fermer</button>
						</div>
					</div>
					<div className="row">
						<div className="module col s12">
							<form className="module-form" onSubmit={this.selectService}>
								<label>
									<p className="column-20">Service :</p>
									<select value={this.state.form1.service} onChange={this.handleService}>
										<option value="">-- Sélectionez un service --</option>
										{services.map(({ _id, translations }) => {
											let title = ""
											for (let i = 0; i < translations.length; i++)
											{
												if (translations[i].lang === this.props.lang_default)
												{
													title = translations[i].title
													break
												}
											}
											return (
												<option value={_id} key={_id}>{title}</option>
											)
										})}
									</select>
								</label>
								<button type="submit" className="waves-effect waves-light btn-small">Sélectionner</button>
							</form>
						</div>
						{commentJSX}
						{calendarJSX}
					</div>
				</div>
			)
		}
	}
}

export default Bookings

import React, { Component } from 'react'
import M from 'materialize-css'
import connexion from '../../connexion'
import Loader from '../UI/Loader'
import './Icons.css'
import '../Core/Main.css'

class Icons extends Component {
	constructor(props) {
		super(props)
		this.state = {
			themes: null,
			icons: null,
			icon: null,
			form1: {
				type: 'lineal',
				theme: 'blank'
			},
			form2: {
				name: '',
				type: '',
				theme: '',
				icon: null
			},
			error: null,
			success: null
		}
		this.props.updateNavbarTitle('Administrer les icônes')
	}

	componentDidMount() {
		this.loadThemes()
		this.loadIcons()
	}

	componentDidUpdate () {
		const elems = document.querySelectorAll('select')
		M.FormSelect.init(elems)
	}

	loadThemes = () => {
		fetch(connexion.connect.url + 'dashboard/themes/list')
		.then(res => res.json())
		.then(themes => {
			this.setState({ themes: themes })
		})
		.catch(err => console.log())		
	}

	loadIcons = () => {
		fetch(connexion.connect.url + 'dashboard/icons/list')
		.then(res => res.json())
		.then(icons => {
			icons.sort((a, b) => {
				return a.name.localeCompare(b.name);
			})
			this.setState({ icons: icons })
		})
		.catch(err => console.log())
	}

	setErrorMsg = (msg) => {
		this.setState({ error: msg, success: null })
	}

	setSuccessMsg = (msg) => {
		this.setState({ error: null, success: msg })
	}

	clearFlash = () => {
		this.setState({ error: null, success: null })
	}

	selectType = (event) => {
		const form1 = {
			...this.state.form1,
			type: event.target.value
		}
		this.clearFlash()
		this.setState({ form1: form1 })
	}

	selectTheme = (event) => {
		const form1 = {
			...this.state.form1,
			theme: event.target.value
		}
		this.clearFlash()
		this.setState({ form1: form1 })
	}

	handleName = (event) => {
		const form2 = {
			...this.state.form2,
			name: event.target.value
		}
		this.setState({ form2: form2 })
	}

	handleType = (event) => {
		const form2 = {
			...this.state.form2,
			type: event.target.value
		}
		const name = this.state.form2.name
		const theme = this.state.form2.theme
		const type = form2.type
		let icon = null
		if (name.length > 0 && theme.length > 0 && type.length > 0)
		{
			const icons = this.state.icons
			for (let i = 0; i < icons.length; i++)
			{
				if (icons[i].name === name)
				{
					const tab = (type === "lineal") ? icons[i].lineal : icons[i].fill
					for (let j = 0; j < tab.length; j++)
					{
						if (tab[j].theme === theme)
						{
							icon = icons[i]
							break
						}
					}
					break
				}
			}
		}
		this.setState({ form2: form2, icon: icon })
	}

	handleTheme = (event) => {
		const form2 = {
			...this.state.form2,
			theme: event.target.value
		}
		const name = this.state.form2.name
		const theme = form2.theme
		const type = this.state.form2.type
		let icon = null
		if (name.length > 0 && theme.length > 0 && type.length > 0)
		{
			const icons = this.state.icons
			for (let i = 0; i < icons.length; i++)
			{
				if (icons[i].name === name)
				{
					const tab = (type === "lineal") ? icons[i].lineal : icons[i].fill
					for (let j = 0; j < tab.length; j++)
					{
						if (tab[j].theme === theme)
						{
							icon = icons[i]
							break
						}
					}
					break
				}
			}
		}
		this.setState({ form2: form2, icon: icon })
	}

	handleIcon = (event) => {
		const reader = new FileReader()
		const file = event.target.files[0]
		reader.addEventListener("load", () => {
			const form2 = {
				...this.state.form2,
				icon: reader.result
			}
			this.setState({ form2: form2 })
		}, false)
		if (file)
			reader.readAsDataURL(file)
	}

	createIcon = (name, type, theme, icon) => {
		fetch(connexion.connect.url + 'dashboard/icons/insert', {
			method: 'POST',
			body: JSON.stringify({
				name: name,
				type: type,
				theme: theme,
				icon: icon
			})
		})
		.then(res => res.json())
		.then(ret => {
			if (ret !== false)
			{
				const form2 = {
					name: '',
					type: '',
					theme: '',
					icon: null
				}
				this.loadIcons()
				this.setState({ icon: null, form2: form2 })
				this.setSuccessMsg("Cet icône à été ajouté avec succès !")
			}
			else
			{
				this.setErrorMsg("Une erreur s'est produite")
				console.log("ret", ret)
			}
		})
		.catch(err => {
			this.setErrorMsg("Une erreur s'est produite")
			console.log(err)
		})
	}

	updateIcon = (id, name, type, theme, icon) => {
		fetch(connexion.connect.url + 'dashboard/icons/update', {
			method: 'POST',
			body: JSON.stringify({
				id: id,
				name: name,
				theme: theme,
				type: type,
				icon: icon
			})
		})
		.then(res => res.json())
		.then(ret => {
			if (ret !== false)
			{
				const form2 = {
					name: '',
					type: '',
					theme: '',
					icon: null
				}
				this.loadIcons()
				this.setState({ icon: null, form2: form2 })
				this.setSuccessMsg("Cet icône à été édité avec succès !")
			}
			else
				this.setErrorMsg("Une erreur s'est produite")
		})
		.catch(err => {
			this.setErrorMsg("Une erreur s'est produite")
			console.log(err)
		})
	}

	submitIcon = (event) => {
		event.preventDefault()
		const name = this.state.form2.name
		const type = this.state.form2.type
		const theme = this.state.form2.theme
		const icon = this.state.form2.icon
		if (name.length === 0)
			this.setErrorMsg("Veuillez indiquer le nom")
		else if (type.length === 0)
			this.setErrorMsg("Veuillez indiquer le type")
		else if (type === "color" && theme.length === 0)
			this.setErrorMsg("Veuillez indiquer le thème")
		else if (icon === null)
			this.setErrorMsg("Veuillez sélectionner un icône")
		else
		{
			if (this.state.icon === null)
				this.createIcon(name, type, theme, icon)
			else
				this.updateIcon(this.state.icon._id, name, type, theme, icon)
		}
	}

	selectIcon = (id) => {
		const icons = this.state.icons
		const type = this.state.form1.type
		const theme = this.state.form1.theme
		let form2 = {
			name: '',
			type: '',
			theme: '',
			icon: null
		}
		let icon = null
		for (let i = 0; i < icons.length; i++)
		{
			if (icons[i]._id === id)
			{
				icon = icons[i]
				let tab = (type === "lineal") ? icons[i].lineal : icons[i].fill
				let prev = null
				for (let j = 0; j < tab.length; j++)
				{
					if (tab[j].theme === theme)
					{
						prev = tab[j].icon
						break
					}
				}
				form2 = {
					name: icons[i].name,
					type: type,
					theme: theme,
					icon: prev
				}
				if (prev === null)
					icon = null
				break
			}
		}
		this.clearFlash()
		this.setState({ icon: icon, form2: form2 })
	}

	deleteIcon = () => {
		if (this.state.icon !== null)
		{
			fetch(connexion.connect.url + 'dashboard/icons/delete', {
				method: 'POST',
				body: JSON.stringify({
					id: this.state.icon._id
				})
			})
			.then(res => res.json())
			.then(ret => {
				if (ret === true)
				{
					const form2 = {
						name: '',
						type: '',
						theme: '',
						icon: null
					}
					this.setState({ form2: form2, icon: null })
					this.setSuccessMsg("Cet icône à été supprimé")
					this.loadIcons()
				}
				else
					this.setErrorMsg("Une erreur s'est produite")
			})
			.catch(err => {
				this.setErrorMsg("Une erreur s'est produite")
				console.log(err)
			})
		}
	}

	deleteIconColor = () => {
		if (this.state.icon !== null)
		{
			fetch(connexion.connect.url + 'dashboard/icons/delete-color', {
				method: 'POST',
				body: JSON.stringify({
					id: this.state.icon._id,
					type: this.state.form1.type,
					theme: this.state.form2.theme
				})
			})
			.then(res => res.json())
			.then(ret => {
				if (ret !== false)
				{
					const form2 = {
						name: '',
						type: '',
						theme: '',
						icon: null
					}
					this.setState({ form2: form2, icon: null })
					this.setSuccessMsg("Cette couleur à été supprimée")
					this.loadIcons()
				}
				else
					this.setErrorMsg("Une erreur s'est produite")
			})
			.catch(err => {
				this.setErrorMsg("Une erreur s'est produite")
				console.log(err)
			})
		}
	}

	render() {
		const themes = this.state.themes
		if (themes === null)
		{
			return (
				<div className="loader">
					<Loader />
				</div>
			)
		}
		else
		{
			let prev = null
			if (this.state.form2.icon !== null)
			{
				let classname = 'small'
				if (this.state.form2.theme === 'blank')
					classname += ' icon blank'
				prev = (
					<div>
						<p>Prévisualisation :</p><p><img src={this.state.form2.icon} className={classname} alt="Prévisualisation" /></p>
					</div>
				)
			}
			let title = 'Ajouter un icône'
			let btn = 'Créer un icône'
			let deletebtn = null
			let deletecolor = null
			if (this.state.icon !== null)
			{
				title = 'Éditer un icône'
				btn = 'Modifier un icône'
				deletebtn = (<button type="button" className="waves-effect waves-light btn-small red" onClick={this.deleteIcon}>Supprimer l'icône</button>)
				deletecolor = (<button type="button" className="waves-effect waves-light btn-small red" onClick={this.deleteIconColor}>Supprimer cette couleur</button>)
			}
			let iconsJSX = null
			const type = this.state.form1.type
			const theme = this.state.form1.theme
			if (type.length > 0 && theme.length > 0 && this.state.icons !== null)
			{
				const nbRows = 8
				let row = null
				let icons_k = -1
				let table_icons = []
				for (let i = 0; i < this.state.icons.length; i++)
				{
					if (i % nbRows === 0)
					{
						if (row !== null)
							table_icons.push(row)
						row = []
					}
					row.push(this.state.icons[i])
				}
				const count = this.state.icons.length
				if (row && row.length > 0)
					table_icons.push(row)
				iconsJSX = (
					<div>
						<p><b>Icônes ({count}) :</b></p>
						<table className="icons-table">
							<tbody>
								{table_icons && table_icons.map(( row ) => {
									icons_k++
									return (
										<tr key={icons_k}>
											{row && row.map(({ _id, name, fill, lineal }) => {
												const blank = (theme === "blank") ? "blank" : ""
												let icon = null
												let tab = (type === "lineal") ? lineal : fill
												for (let i = 0; i < tab.length; i++)
												{
													if (tab[i].theme === theme)
													{
														icon = tab[i].icon
														break
													}
												}
												return (
													<td key={_id} className={blank} onClick={this.selectIcon.bind(this, _id)}>
														<img src={icon} alt={name} title={name} />
													</td>
												)
											})}
										</tr>
									)
								})}
							</tbody>
						</table>
					</div>
				)
			}
			return (
				<div className="wrapper-content">
					<p className="center error">{this.state.error}</p>
					<p className="center success">{this.state.success}</p>
					<div className="row">
						<div className="module col s6">
							<h2>Liste des icônes</h2>
							<form className="module-form">
								<label>
									<p className="column-20">Type :</p>
									<select value={this.state.form1.type} onChange={this.selectType}>
										<option value="lineal">Filaire</option>
										<option value="fill">Rempli</option>
									</select>
								</label>
								<label>
									<p className="column-20">Couleur :</p>
									<select value={this.state.form1.theme} onChange={this.selectTheme}>
										<option value="blank">Blanc</option>
										{themes && themes.map(({ _id, name }) => {
											return (<option value={_id} key={_id}>{name}</option>)
										})}
									</select>
								</label>
							</form>
							{iconsJSX}
						</div>
						<div className="module col s6">
							<h2>{title}</h2>
							<form className="module-form" onSubmit={this.submitIcon}>
								<label>
									<p className="column-20">Nom :</p>
									<input type="text" value={this.state.form2.name} onChange={this.handleName} />
								</label>
								<label>
									<p className="column-20">Type :</p>
									<select value={this.state.form2.type} onChange={this.handleType}>
										<option value="">Sélectionner un type</option>
										<option value="lineal">Filaire</option>
										<option value="fill">Rempli</option>
									</select>
								</label>
								<label>
									<p className="column-20">Couleur :</p>
									<select value={this.state.form2.theme} onChange={this.handleTheme}>
										<option value="">Sélectioner une couleur</option>
										<option value="blank">Blanc</option>
										{themes && themes.map(({ _id, name }) => {
											return (<option value={_id} key={_id}>{name}</option>)
										})}
									</select>
								</label>
								<label>
									<p className="column-20">Icône :</p>
									<input type="file" onChange={this.handleIcon} />
								</label>
								{prev}
								<button type="submit" className="waves-effect waves-light btn-small">{btn}</button>
								{deletebtn}
								{deletecolor}
							</form>
						</div>
					</div>
				</div>
			)
		}
	}
}

export default Icons

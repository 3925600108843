import connexion from '../../connexion'

class CKEditorUploadAdapter
{
	constructor (loader) {
		// CKEditor 5's FileLoader instance.
		this.loader = loader
		// URL where to send files.
		this.url = connexion.connect.url + 'dashboard/files/insert'
		console.log(this.loader, this.url)
	}

	// Starts the upload process.
	upload = () => {
		return this.loader.file.then(file => new Promise((resolve, reject) => {
			const toBase64 = (file) => new Promise((resolve, reject) => {
				const reader = new FileReader()
				reader.readAsDataURL(file)
				reader.onload = (f => { 
					if (file.type.substr(0, 5) === "image")
					{
						const image = new Image()
						image.src = f.target.result
						image.onload = () => {
							const result = {
								name: file.name,
								type: file.type,
								data: reader.result,
								size: file.size,
								width: image.width,
								height: image.height,
							}
							resolve(result)
						}
					}
					else
					{
						const result = {
							name: file.name,
							type: file.type,
							data: reader.result,
							size: file.size,
							width: null,
							height: null
						}
						resolve(result)
					}
				})
				reader.onerror = (error) => reject(error)
			})
			
			return toBase64(file).then(result => {
				fetch(connexion.connect.url + 'dashboard/files/insert', {
					method: 'POST',
					body: JSON.stringify({
						name: result.name,
						type: result.type,
						data: result.data,
						width: result.width,
						height: result.height
					})
				})
				.then(res => res.json())
				.then(ret => {
					// If the upload is successful, resolve the upload promise with an object containing
					// at least the "default" URL, pointing to the image on the server.
					console.log(ret)
					resolve({
						default: connexion.connect.url + 'dashboard/files/get?id=' + ret._id
					})
				})
				.catch(err => {
					console.log(err)
					reject(err)
				})
			})
		}))
	}
}

export default function CKEditorUploadAdapterPlugin(editor)
{
	editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
		// Configure the URL to the upload script in your back-end here!
		return new CKEditorUploadAdapter(loader)
	}
}
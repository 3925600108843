import React, { Component } from 'react'
import M from 'materialize-css'
import RenderList from './RenderList'
import IconPicker from '../UI/IconPicker'
import Pages from './Pages'
import LangSelector from '../UI/LangSelector'
import Modal from '../UI/Modal'
import Loader from '../UI/Loader'
import tools from '../../tools'
import '../Core/Main.css'

class Lists extends Component {
	constructor(props) {
		super(props)
		this.state = {
			list: null,
			element: null,
			edit: false,
			show: false,
			answerModal: null,
			open_modal: false,
			modal_msg: null,
			delete: false,
			update_cover: false,
			attempt_submit: false,
			form1: {
				icon: null,
				prev: null,
				translations: []
			},
			form2: {
				cover: null
			},
			navigation: [
				{ name: 'Rubriques', href: '/Rubriques', current: false },
				{ name: '...', href: '#', current: true },			
				{ name: '...', href: '#', current: true }			
			],
			error: this._initErrors(),
			success: null
		}
		this.length_max = 40
	}

	componentDidMount() {
		this.loadList()
	}

	componentDidUpdate(prevProps) {
		const elems = document.querySelectorAll('select')
		M.FormSelect.init(elems)
		if (prevProps.lists !== this.props.lists)
			this.loadList(true)
	}

	setErrorMsg = (key, msg, id = null) => {
		if (key === "default_create_element")
		{
			key = "global"
			msg = tools.default_error_msg("Impossible de créer une sous-catégorie", msg)
		}
		else if (key === "default_update_element")
		{
			key = "global"
			msg = tools.default_error_msg("Impossible de modifier une sous-catégorie", msg)
		}
		else if (key === "default_delete_element")
		{
			key = "global"
			msg = tools.default_error_msg("Impossible de supprimer une sous-catégorie", msg)
		}
		else if (key === "default_update_cover")
		{
			key = "global"
			msg = tools.default_error_msg("Impossible de mettre à jour une photo de couverture", msg)
		}
		else if (key === "default_order")
		{
			key = "global"
			msg = tools.default_error_msg("Impossible de réordonner les sous-catégories", msg)
		}
		else if (key === "default_fetch_list")
		{
			key = "global"
			msg = tools.default_error_msg("Impossible de récuperer la liste", msg)
		}
		else if (key === "default_transform_element")
		{
			key = "global"
			msg = tools.default_error_msg("Impossible de transformer la sous-catégorie", msg)
		}
		let error = this._initErrors()
		if (id !== null)
			error[key][id] = msg
		else
			error[key] = msg
		this.setState({ success: null, error: error })
	}

	setSuccessMsg = (msg) => {
		this.setState({ error: this._initErrors(), success: msg })
	}

	clearFlash = () => {
		this.setState({ error: this._initErrors(), success: null })
	}

	_initErrors = () => {
		return {
			global: null,
			text: null,
			icon: null
		}
	}

	loadList = (fromProps = false) => {
		if (fromProps === true && this.props.lists && this.state.list)
		{
			let list = null
			let form2 = {
				cover: null
			}
			for (let i = 0; i < this.props.lists.length; i++)
			{
				if (this.props.lists[i]._id === this.state.list._id)
				{
					list = this.props.lists[i]
					for (let j = 0; j < list.elements.length; j++)
					{
						for (let k = 0; k < this.props.icons.length; k++)
						{
							if (list.elements[j].icon === this.props.icons[k]._id)
							{
								for (let l = 0; l < this.props.icons[k].lineal.length; l++)
								{
									if (this.props.icons[k].lineal[l].theme === "blank")
									{
										list.elements[j].prev = this.props.icons[k].lineal[l].icon
										break
									}
								}
								break
							}
						}
					}
					form2 = {
						cover: list.cover
					}
					break
				}
			}
			this.setState({ list: list, form2: form2 })
		}
		else if (this.props.category)
		{
			this.props.fetchListByCategory(this.props.category._id)
			.then(list => {
				for (let j = 0; j < list.elements.length; j++)
				{
					for (let k = 0; k < this.props.icons.length; k++)
					{
						if (list.elements[j].icon === this.props.icons[k]._id)
						{
							for (let l = 0; l < this.props.icons[k].lineal.length; l++)
							{
								if (this.props.icons[k].lineal[l].theme === "blank")
								{
									list.elements[j].prev = this.props.icons[k].lineal[l].icon
									break
								}
							}
							break
						}
					}
				}
				const form2 = {
					cover: list.cover
				}
				this.setState({ list: list, form2: form2 })
			})
			.catch(err => {
				this.setErrorMsg("default_fetch_list", 1)
				console.log(err)
			})
		}
	}

	handleText = (event) => {
		let found = false
		const translations = this.state.form1.translations
		for (let i = 0; i < translations.length; i++)
		{
			if (translations[i].lang === this.props.current_lang)
			{
				found = true
				translations[i].text = event.target.value
				break
			}
		}
		if (!found)
		{
			translations.push({
				lang: this.props.current_lang,
				text: event.target.value
			})
		}
		const form1 = {
			...this.state.form1,
			translations: translations
		}
		this.setState({ form1: form1 })
	}

	handleIcon = (icon) => {
		const icons = this.props.icons
		let prev = null
		for (let i = 0; i < icons.length; i++)
		{
			if (icons[i]._id === icon)
			{
				for (let j = 0; j < icons[i].lineal.length; j++)
				{
					if (icons[i].lineal[j].theme === "blank")
					{
						prev = icons[i].lineal[j].icon
						break
					}
				}
				break
			}
		}
		const form1 = {
			...this.state.form1,
			icon: icon,
			prev: prev
		}
		this.setState({ form1: form1 })
	}

	_validateForm1 = () => {
		let error_obj = this._initErrors()
		let error = false
		const icon = this.state.form1.icon
		const translations = this.state.form1.translations
		if (icon === null)
		{
			error = true
			error_obj.icon = "Vous devez choisir une icône"
		}
		let found_default_text = false
		for (let i = 0; i < translations.length; i++)
		{
			const lang = translations[i].lang
			const text = translations[i].text
			if (text.length === 0 && lang === this.props.lang_default)
			{
				error = true
				error_obj.text = "Vous devez indiquer le texte (version " + lang.toUpperCase() + ")"
			}
			else if (text.length > this.length_max)
			{
				error = true
				error_obj.text = "Le texte ne doit pas dépasser " + this.length_max + " caractères (version " + lang.toUpperCase() + ")"
			}
			else if (lang === this.props.lang_default)
				found_default_text = true
		}
		if (!found_default_text && !error_obj.text)
		{
			error = true
			error_obj.text = 'Vous devez indiquer le texte (version ' + this.props.lang_default.toUpperCase() + ')'
		}
		if (error === true)
		{
			error_obj.section = "Il y a une ou plusieurs erreur(s) dans le formulaire"
			this.setState({ success: null, error: error_obj, attempt_submit: false })
		}
		return (!error) ? true : false
	}

	submitElement = (event) => {
		event.preventDefault()
		this.setState({ attempt_submit: true })
		if (this._validateForm1())
		{
			const translations = this.state.form1.translations
			let langs_not_found = []
			for (let i = 0; i < this.props.langs.length; i++)
			{
				let found = false
				for (let j = 0; j < translations.length; j++)
				{
					if (this.props.langs[i] === translations[j].lang && translations[j].text.length > 0)
					{
						found = true
						break
					}
				}
				if (!found)
					langs_not_found.push(this.props.langs[i])
			}
			if (langs_not_found.length > 0)
			{
				let msg = ""
				for (let i = 0; i < langs_not_found.length; i++)
				{
					if (i > 0)
						msg += ', '
					msg += langs_not_found[i].toUpperCase()
				}
				msg = "La liste n'est pas traduite dans les langues suivantes : " + msg
				this.setState({ modal_msg: msg, open_modal: true, answerModal: this.answerModalEdit })
			}
			else
				this._insertListElement()
		}
	}

	_insertListElement = () => {
		const icon = this.state.form1.icon
		const translations = this.state.form1.translations
		let pages_translations = []
		for (let i = 0; i < translations.length; i++)
		{
			pages_translations.push({
				lang: translations[i].lang,
				name: translations[i].text,
				category_name: ''
			})
		}
		for (let j = 0; j < pages_translations.length; j++)
		{
			for (let i = 0; i < this.props.category.translations.length; i++)
			{
				if (pages_translations[j].lang === this.props.category.translations[i].lang)
				{
					pages_translations[j].category_name = this.props.category.translations[i].name
					break
				}
			}
		}
		if (this.state.element === null)
		{
			const page = {
				translations: pages_translations,
				category: null,
				cover: null,
				sections: []
			}
			this.props.insertPage(page)
			.then(ret => {
				if (ret === false)
				{
					this.setErrorMsg('default_create_element', 1)
					this.setState({ attempt_submit: false, open_modal: false })
				}
				else
				{
					const element = {
						list_id: this.state.list._id,
						page: ret._id,
						icon: icon,
						translations: translations
					}
					this.props.insertListElement(element)
					.then(ret => {
						if (ret === false)
						{
							this.setErrorMsg('default_create_element', 2)
							this.setState({ attempt_submit: false, open_modal: false })
						}
						else
						{
							const form1 = {
								icon: null,
								prev: null,
								translations: []
							}
							this.setState({ form1: form1, edit: false, attempt_submit: false, open_modal: false })
							this.setSuccessMsg("La sous-catégorie a bien été créé")
						}
					})
					.catch(err => {
						this.setState({ attempt_submit: false, open_modal: false })
						this.setErrorMsg('default_create_element', 3)
						console.log(err)
					})
				}
			})
			.catch(err => {
				this.setState({ attempt_submit: false, open_modal: false })
				this.setErrorMsg('default_create_element', 4)
				console.log(err)
			})
		}
		else
		{
			const page = {
				_id: this.state.element.page,
				translations: pages_translations
			}
			this.props.updatePageName(page)
			.then(ret => {
				let key = 0
				for (let i = 0; i < this.state.list.elements.length; i++)
				{
					if (this.state.list.elements[i] === this.state.element)
					{
						key = i
						break
					}
				}
				const element = {
					list_id: this.state.list._id,
					key: key,
					icon: icon,
					translations: translations
				}
				this.props.updateListElement(element)
				.then(ret => {
					if (ret === false)
					{
						this.setState({ attempt_submit: false, open_modal: false })
						this.setErrorMsg('default_update_element', 1)
					}
					else
					{
						const form1 = {
							icon: null,
							prev: null,
							translations: []
						}
						this.setState({ form1: form1, edit: false, attempt_submit: false, open_modal: false })
						this.setSuccessMsg("La sous-catégorie a été mis à jour")
					}
				})
				.catch(err => {
					this.setState({ attempt_submit: false, open_modal: false })
					this.setErrorMsg('default_update_element', 2)
					console.log(err)
				})
			})
			.catch(err => {
				this.setState({ attempt_submit: false, open_modal: false })
				this.setErrorMsg('default_update_element', 3)
				console.log(err)
			})
		}
	}

	handleCover = (event) => {
		const reader = new FileReader()
		const file = event.target.files[0]
		reader.addEventListener("load", () => {
			const form2 = {
				cover: reader.result
			}
			this.setState({ form2: form2 })
		}, false)
		if (file)
			reader.readAsDataURL(file)
	}

	submitCover = (event) => {
		event.preventDefault()
		const list = {
			_id: this.state.list._id,
			cover: this.state.form2.cover
		}
		this.props.updateListCover(list)
		.then(ret => {
			if (ret === false)
				this.setErrorMsg('default_update_cover', 1)
			else
			{
				const form2 = {
					cover: null
				}
				this.setState({ form2: form2, update_cover: false })
				this.setSuccessMsg("La photo de couverture à été mise à jour")
			}
		})
		.catch(err => {
			this.setErrorMsg('default_update_cover', 2)
			console.log(err)
		})
	}

	clickCover = () => {
		const update_cover = (this.state.update_cover === true) ? false : true
		this.setState({ update_cover: update_cover })
	}

	clickCreate = () => {
		const form1 = {
			icon: null,
			prev: null,
			translations: []
		}
		this.setState({ edit: true, form1: form1, element: null })
	}

	clickEdit = (key) => {
		const element = this.state.list.elements[key]
		const form1 = {
			icon: element.icon,
			prev: element.prev,
			translations: element.translations
		}
		this.setState({ edit: true, form1: form1, element: element })
	}

	clickSelect = (key) => {
		const element = this.state.list.elements[key]
		// const navigation = this.state.navigation
		// if (navigation.length < 4)
		// {
		// 	for (let i = 0; i < element.translations.length; i++)
		// 	{
		// 		if (element.translations[i].lang === this.props.current_lang)
		// 			navigation.push({ name: element.translations[i].text, href: '#', current: true })
		// 	}
		// }
		this.setState({ show: true, element: element })
	}

	clickUp = (key) => {
		let update = false
		let elements = this.state.list.elements
		if (key > 0)
		{
			let order_tmp = elements[key - 1].order
			elements[key - 1].order = elements[key].order
			elements[key].order = order_tmp
			let element_tmp = elements[key - 1]
			elements[key - 1] = elements[key]
			elements[key] = element_tmp
			update = true
		}
		if (update === true)
		{
			const list = {
				_id: this.state.list._id,
				elements: elements
			}
			this.props.updateListOrderElements(list)
			.then(ret => {
				if (ret === false)
					this.setErrorMsg('default_order', 1)
			})
			.catch(err => {
				this.setErrorMsg('default_order', 2)
				console.log(err)
			})
		}
	}

	clickDown = (key) => {
		let update = false
		let elements = this.state.list.elements
		if (key + 1 < elements.length)
		{
			let order_tmp = elements[key + 1].order
			elements[key + 1].order = elements[key].order
			elements[key].order = order_tmp
			let element_tmp = elements[key + 1]
			elements[key + 1] = elements[key]
			elements[key] = element_tmp
			update = true
		}
		if (update === true)
		{
			const list = {
				_id: this.state.list._id,
				elements: elements
			}
			this.props.updateListOrderElements(list)
			.then(ret => {
				if (ret === false)
					this.setErrorMsg('default_order', 3)
			})
			.catch(err => {
				this.setErrorMsg('default_order', 4)
				console.log(err)
			})
		}
	}

	clickDelete = (key) => {
		const msg = "Vous supprimerez cette sous-catégorie et tout le contenu qui s'y trouve. Cette action est irréversible"
		this.setState({ delete: key, modal_msg: msg, open_modal: true, answerModal: this.answerModalDelete })
	}

	clickTranformCategory = () => {
		const msg = "Vous allez tranformer cette catégorie en sous-catégorie. Cette action est irréversible"
		this.setState({ attempt_submit: true, modal_msg: msg, open_modal: true, answerModal: this.answerModalTransform })
	}

	_deleteListElement = () => {
		const key = this.state.delete
		this.props.deletePage(this.state.list.elements[key].page)
		.then(ret => {
			if (ret === false)
			{
				this.setState({ open_modal: false })
				this.setErrorMsg('default_delete_element', 1)
			}
			else
			{
				const element = {
					list_id: this.state.list._id,
					key: key
				}
				this.props.deleteListElement(element)
				.then(ret => {
					if (ret === false)
					{
						this.setState({ open_modal: false })
						this.setErrorMsg('default_delete_element', 2)
					}
					else
					{
						this.setState({ open_modal: false })
						this.setSuccessMsg("La sous-catégorie a bien été supprimée")
					}
				})
				.catch(err => {
					this.setState({ open_modal: false })
					this.setErrorMsg('default_delete_element', 3)
					console.log(err)
				})
			}
		})
		.catch(err => {
			this.setState({ open_modal: false })
			this.setErrorMsg('default_delete_element', 4)
			console.log(err)
		})
	}

	_transformSubcategory = () => {
		let elements = this.state.list.elements
		let page = null
		for (let i = 0; i < elements.length; i++)
		{
			if (elements[i] === this.state.element)
			{
				page = elements[i].page
				elements.splice(i, 1)
				break
			}
		}
		const list = {
			...this.state.list,
			elements: elements
		}
		if (page !== null)
		{
			this.props.updateListTransformSubcategory(list, page)
			.then(list => {
				this.setSuccessMsg("La sous-catégorie a été transformée en catégorie")
				this.setState({ attempt_submit: false, open_modal: false, edit: false })
			})
			.catch(err => {
				this.setState({ open_modal: false, attempt_submit: false })
				this.setErrorMsg('default_transform_element', 2)
				console.log(err)
			})
		}
		else
		{
			this.setErrorMsg('default_transform_element', 3)
			this.setState({ attempt_submit: false, open_modal: false })
		}
	}

	answerModalEdit = (answer) => {
		if (answer === "yes")
			this._insertListElement()
		else
			this.setState({ attempt_submit: false, open_modal: false })
	}

	answerModalDelete = (answer) => {
		if (answer === "yes")
			this._deleteListElement()
		else
			this.setState({ delete: false, open_modal: false })
	}

	answerModalTransform = (answer) => {
		if (answer === "yes")
			this._transformSubcategory()
		else
			this.setState({ attempt_submit: false, open_modal: false })
	}

	unEdit = () => {
		this.setState({ edit: false })
	}

	unShow = () => {
		this.setState({ show: false })
	}

	handleBreadcumb = () => {
		//
	}

	render() {
		// console.log(this.props.group.translations)
		if (this.state.list === null)
		{
			return (
				<div className="loader">
					<Loader />
				</div>
			)
		}
		else if (this.state.show === true)
		{
			return (
				<Pages
					navigation={this.state.navigation}
					element={this.state.element}
					current_lang={this.props.current_lang}
					features={this.props.features}
					icons={this.props.icons}
					langs={this.props.langs}
					lang_default={this.props.lang_default}
					page={this.state.element.page}
					pages={this.props.pages}
					services={this.props.services}
					theme={this.props.theme}
					fetchPageByID={this.props.fetchPageByID}
					fetchServices={this.props.fetchServices}
					insertPageSection={this.props.insertPageSection}
					updatePageCover={this.props.updatePageCover}
					updatePageDuplicateSection={this.props.updatePageDuplicateSection}
					updatePageOrderSections={this.props.updatePageOrderSections}
					updatePageSection={this.props.updatePageSection}
					updatePageService={this.props.updatePageService}
					deletePageSection={this.props.deletePageSection}
					deletePageService={this.props.deletePageService}
					updateLang={this.props.updateLang}
					unShowGroup={this.props.unShowGroup}
					unShowCategory={this.props.unShowCategory}
					unShowList={this.unShow}
				/>
			)
		}
		else
		{
			let titleHeader = (<h1><span className="uhover" onClick={this.props.unShowGroup}>Rubriques</span> > <span className="uhover" onClick={this.props.unShowCategory}>Catégories</span> > Liste</h1>)
			let left = null
			const list = this.state.list
			let nav = this.state.navigation
			for (let i = 0; i < this.props.category.translations.length; i++)
			{
				if (this.props.category.translations[i].lang === this.props.current_lang)
					nav[2].name = this.props.category.translations[i].name
			}
			// let breadcrumb = (
			// 	<nav className={navStyle} aria-label="Breadcrumb">
			// 		<ol className="bg-white rounded-md shadow px-6 flex space-x-4">
			// 			<li className="flex">
			// 				<div className="flex items-center">
			// 					<Link to="/" className="text-gray-400 hover:text-gray-500">
			// 						<HomeIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
			// 						<span className="sr-only">Home</span>
			// 					</Link>
			// 				</div>
			// 			</li>
			// 			{nav.map((page) => {
			// 				// const func = (this.state.navigation.indexOf(page) === 1) ? this.props.unShowCategory : this.unEdit
			// 				let func = this.unEdit
			// 				if (this.state.navigation.indexOf(page) === 1)
			// 					func = this.props.unShowCategory
			// 				else if (this.state.navigation.indexOf(page) === 2)
			// 					func = this.props.unShowList
			// 				else
			// 					func = this.unEdit
			// 					return (
			// 					<li key={page.name} className="flex">
			// 						<div className="flex items-center">
			// 							<svg
			// 								className="flex-shrink-0 w-6 h-full text-gray-200"
			// 								viewBox="0 0 24 44"
			// 								preserveAspectRatio="none"
			// 								fill="currentColor"
			// 								xmlns="http://www.w3.org/2000/svg"
			// 								aria-hidden="true"
			// 							>
			// 								<path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
			// 							</svg>
			// 							<button
			// 								// onClick={func.bind(this, page)}
			// 								onClick={func}
			// 								className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
			// 								aria-current={page.current ? 'page' : undefined}
			// 							>
			// 								{page.name}
			// 							</button>
			// 						</div>
			// 					</li>
			// 				)
			// 			})}
			// 		</ol>
			// 	</nav>
			// )
			if (this.state.edit === true)
			{
				titleHeader = (<h1><span className="uhover" onClick={this.props.unShowGroup}>Rubriques</span> > <span className="uhover" onClick={this.props.unShowCategory}>Catégories</span> > <span className="uhover" onClick={this.unEdit}>Liste</span> > Sous-catégorie</h1>)
				let btn = 'Ajouter une sous-catégorie'
				let transfortCatBtn = null
				if (this.state.element !== null)
				{
					btn = 'Modifier une sous-catégorie'
					transfortCatBtn = (<button type="button" className="waves-effect waves-light btn-small red" onClick={this.clickTranformCategory}>Transformer en catégorie</button>)
				}
				let text = ""
				for (let i = 0; i < this.state.form1.translations.length; i++)
				{
					if (this.state.form1.translations[i].lang === this.props.current_lang)
					{
						text = this.state.form1.translations[i].text
						break
					}
				}
				let prevIcon = null
				if (this.state.form1.prev !== null)
					prevIcon = (<div><p>Prévisualisation :</p><p><img src={this.state.form1.prev} className="icon blank" alt="Prévisualisation" /></p></div>)
				let sendbtn = (<button type="submit" className="waves-effect waves-light btn-small">{btn}</button>)
				if (this.state.attempt_submit === true)
				{
					sendbtn = (
						<div className="center">
							<Loader />
						</div>
					)
					transfortCatBtn = null
				}
				left = (
					<div className="module col s12">
						<LangSelector lang={this.props.current_lang} updateLang={this.props.updateLang} />
						<form onSubmit={this.submitElement}>

							<label>
								<p className="error">{this.state.error.text}</p>
								<p>Texte (version {this.props.current_lang.toUpperCase()}) :</p>
								<input type="text" value={text} onChange={this.handleText} />
							</label>
							<div className="label">
								<p className="error">{this.state.error.icon}</p>
								<p className="column-20">Icône :</p>
								<IconPicker type="lineal" theme="blank" icons={this.props.icons} onChange={this.handleIcon} />
							</div>
							{prevIcon}
							{sendbtn}
							{transfortCatBtn}
						</form>
					</div>
				)
			}
			else
			{
				let prev = null
				if (this.state.form2.cover !== null)
					prev = (<div><p>Prévisualisation :</p><p><img src={this.state.form2.cover} alt="Prévisualisation" /></p></div>)
				let textCover = 'Éditer la photo de couverture'
				if (this.state.form2.cover === null)
					textCover = 'Ajouter une photo de couverture'
				let formCover = (
					<button type="submit" className="waves-effect waves-light btn-small" onClick={this.clickCover}>{textCover}</button>
				)
				if (this.state.update_cover === true)
				{
					formCover = (
						<form onSubmit={this.submitCover}>
							<label>
								<p className="column-20">Photo :</p>
								<input type="file" onChange={this.handleCover} />
							</label>
							{prev}
							<button type="submit" className="waves-effect waves-light btn-small">Enregistrer</button>
							<button type="submit" className="waves-effect waves-light btn-small" onClick={this.clickCover}>Masquer</button>
						</form>
					)
				}
				const elements = this.state.list.elements
				let element_k = -1
				left = (
					<div>
						<div className="module col s12">
							{formCover}
						</div>
						<div className="module col s12">
							<button type="button" className="waves-effect waves-light btn-large" onClick={this.clickCreate}>Ajouter une sous-catégorie</button>
							<LangSelector lang={this.props.current_lang} updateLang={this.props.updateLang} />
							{elements.map(({ prev, translations }) => {
								let text = ""
								for (let i = 0; i < translations.length; i++)
								{
									if (translations[i].lang === this.props.current_lang)
									{
										text = translations[i].text
										break
									}
								}
								element_k++
								let image = null
								if (prev)
									image = (<div className="icon"><img src={prev} alt="Icône sections" /></div>)
								return (
									<div className="object full-width" key={element_k}>
										<div className="title">{image}<p onClick={this.clickSelect.bind(this, element_k)}>{text}</p></div>
										<div className="buttons">
											<button type="button" className="waves-effect waves-light btn" onClick={this.clickUp.bind(this, element_k)}>Monter</button>
											<button type="button" className="waves-effect waves-light btn" onClick={this.clickDown.bind(this, element_k)}>Descendre</button>
											<button type="button" className="waves-effect waves-light btn" onClick={this.clickEdit.bind(this, element_k)}>Éditer</button>
											<button type="button" className="waves-effect waves-light btn red" onClick={this.clickDelete.bind(this, element_k)}>Supprimer</button>
										</div>
										<div className="clear"></div>
									</div>
								)
							})}
						</div>
					</div>
				)
			}
			return (
				<div className="wrapper-content">
					{titleHeader}
					{/* {breadcrumb} */}
					<p className="center error">{this.state.error.global}</p>
					<p className="center success">{this.state.success}</p>
					<Modal
						dismissible={false}
						content={this.state.modal_msg}
						open_modal={this.state.open_modal}
						answerModal={this.state.answerModal}
					/>
					<div className="row">
						<div className="col s6">
							{left}
						</div>
						<div className="col s6">
							<div className="row">
								<div className="module col s12">
									<RenderList
										current_lang={this.props.current_lang}
										icons={this.props.icons}
										list={list}
										theme={this.props.theme}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			)
		}
	}
}

export default Lists

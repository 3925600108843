import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import LangSelector from '../UI/LangSelector'
import Modal from '../UI/Modal'
import Loader from '../UI/Loader'
import M from "materialize-css"
import tools from '../../tools'
import french_assets from '../../langs/main.fr.json'
import english_assets from '../../langs/main.en.json'
import german_assets from '../../langs/main.de.json'
import dutch_assets from '../../langs/main.nl.json'
import italian_assets from '../../langs/main.it.json'
import spanish_assets from '../../langs/main.es.json'
import portuguese_assets from '../../langs/main.pt.json'
import '../Core/Main.css'
import EmptyFolder from '../UI/EmptyFolder'

class TemplateNotification extends Component {
	constructor(props) {
		super(props)
		this.lang_assets = {
			fr: french_assets,
			en: english_assets,
			de: german_assets,
			nl: dutch_assets,
			it: italian_assets,
			es: spanish_assets,
			pt: portuguese_assets
		}
		this.state = {
			edit: null,
			attempt_submit: false,
			notifications: undefined,
			pages: undefined,
			categories: undefined,
			users: undefined,
			selected_users: null,
			notification: null,
			modal_delete: false,
			modal_send: false,
			modal_msg: null,
			delete: null,
			create: false,
			form2: {
				day: '',
				month: '',
				hour: '',
				minute: '',
				users: 'all'
			},
			form1: {
				name: '',
				redirect: '{"type":"home"}',
				translations: []
			},
			current_lang: this.props.lang_default,
			error: this._initErrors(),
			success: null
		}
		this.length_max = 40
		this.content_length = 140
		this.props.updateNavbarTitle('Paramétrer les notifications')
	}

	componentDidMount() {
		this.loadUsers()
		this.loadNotifications()
		this.loadPages()
	}
	
	componentDidUpdate (prevProps) {
		const elems = document.querySelectorAll('select')
		M.FormSelect.init(elems)
		if (prevProps.notifications !== this.props.notifications)
			this.loadNotifications(true)
	}

	setErrorMsg = (key, msg) => {
		if (key === "error_send_notification")
		{
			key = "global"
			msg = tools.default_error_msg("Impossible d'envoyer une notification", msg)
		}
		else if (key === "error_create_notification")
		{
			key = "global"
			msg = tools.default_error_msg("Impossible de créer une notification", msg)
		}
		else if (key === "error_update_notification")
		{
			key = "global"
			msg = tools.default_error_msg("Impossible de mettre à jour une notification", msg)
		}
		else if (key === "error_delete_notification")
		{
			key = "global"
			msg = tools.default_error_msg("Impossible de supprimer une notification", msg)
		}
		else if (key === "error_fetch_notification")
		{
			key = "global"
			msg = tools.default_error_msg("Impossible de récupérer les notifications", msg)
		}
		else if (key === "error_fetch_users")
		{
			key = "global"
			msg = tools.default_error_msg("Impossible de récupérer les utilisateurs", msg)
		}
		let error = this._initErrors()
		error[key] = msg
		this.setState({ success: null, error: error })
	}

	setSuccessMsg = (msg) => {
		this.clearFlash()
		this.setState({ success: msg, error: this._initErrors() })
	}

	clearFlash = () => {
		this.setState({ success: null, error: this._initErrors() })
	}

	_initErrors = () => {
		return {
			global: null,
			name: null,
			title: null,
			content: null,
			users: null,
			day: null,
			month: null,
			hour: null
		}
	}

	loadUsers = () => {
		this.props.fetchWebappUsers()
		.then(users => {
			let selected_users = []
			if (this.props.location.clients_numbers)
			{
				for (let i = 0; i < users.length; i++)
				{
					let find = false
					for (let j = 0; j < this.props.location.clients_numbers.length; j++)
					{
						if (users[i].client_number === this.props.location.clients_numbers[j])
						{
							find = true
							break
						}
					}
					if (find)
						selected_users.push(users[i])
				}
			}
			if (selected_users.length === 0)
				selected_users = null
			this.setState({ users: users, selected_users: selected_users })
		})
		.catch(err => {
			this.setErrorMsg('error_fetch_users', 1)
			console.log(err)
		})
	}
	
	loadNotifications = (fromProps = false) => {
		if (fromProps === true)
			this.setState({ notifications: this.props.notifications })
		else
		{
			this.props.fetchNotifications()
			.then(notifications => {
				this.setState({ notifications: notifications })
			})
			.catch(err => {
				this.setErrorMsg('error_fetch_notification', 1)
				console.log(err)
			})
		}
	}

	loadPages = () => {
		this.props.fetchPages(true, true)
		.then(pages => {
			this.props.fetchGroups()
			.then(groups => {
				this.props.fetchCategories()
				.then(categories => {
					for (let i = 0; i < categories.length; i++)
					{
						for (let j = 0; j < groups.length; j++)
						{
							if (categories[i].group === groups[j]._id)
							{
								categories[i].group = groups[j]
								break
							}
						}
					}
					this.setState({ categories: categories, pages: pages })
				})
				.catch(err => {
					this.setErrorMsg('error_fetch_notification', 2)
					console.log(err)
				})
			})
			.catch(err => {
				this.setErrorMsg('error_fetch_notification', 3)
				console.log(err)
			})
		})
		.catch(err => {
			this.setErrorMsg('error_fetch_notification', 4)
			console.log(err)
		})
	}

	handleName = (event) => {
		const form1 = {
			...this.state.form1,
			name: event.target.value
		}
		this.setState({ form1: form1 })
	}

	handleTitle = (event) => {
		let found = false
		const translations = this.state.form1.translations
		for (let i = 0; i < translations.length; i++)
		{
			if (translations[i].lang === this.state.current_lang)
			{
				found = true
				translations[i].title = event.target.value
				break
			}
		}
		if (!found)
		{
			translations.push({
				lang: this.state.current_lang,
				title: event.target.value,
				content: ''
			})
		}
		const form1 = {
			...this.state.form1,
			translations: translations
		}
		this.setState({ form1: form1 })
	}

	handleDay = (event) => {
		const form2 = {
			...this.state.form2,
			day: event.target.value
		}
		this.setState({ form2: form2 })
	}

	handleContent = (event) => {
		let found = false
		const translations = this.state.form1.translations
		for (let i = 0; i < translations.length; i++)
		{
			if (translations[i].lang === this.state.current_lang)
			{
				found = true
				translations[i].content = event.target.value
				break
			}
		}
		if (!found)
		{
			translations.push({
				lang: this.state.current_lang,
				title: '',
				content: event.target.value
			})
		}
		const form1 = {
			...this.state.form1,
			translations: translations
		}
		this.setState({ form1: form1 })
	}

	handleRedirect = (event) => {
		const form1 = {
			...this.state.form1,
			redirect: event.target.value
		}
		this.setState({ form1: form1 })
	}

	handleMonth = (event) => {
		const form2 = {
			...this.state.form2,
			month: event.target.value
		}
		this.setState({ form2: form2 })
	}

	handleHour = (event) => {
		const form2 = {
			...this.state.form2,
			hour: event.target.value
		}
		this.setState({ form2: form2 })
	}

	handleMinute = (event) => {
		const form2 = {
			...this.state.form2,
			minute: event.target.value
		}
		this.setState({ form2: form2 })
	}

	handleUsers = (event) => {
		const form2 = {
			...this.state.form2,
			users: event.target.value
		}
		this.setState({ form2: form2 })
	}

	_validateForm1 = () => {
		const name = this.state.form1.name
		const translations = this.state.form1.translations
		let error = false
		let error_obj = {
			global: null,
			name: null,
			title: null,
			content: null,
			users: null,
			day: null,
			month: null,
			hour: null
		}
		if (name.length === 0)
		{
			error = true
			error_obj.name = 'Le nom ne doit pas être vide'
		}
		else if (name.length > this.length_max)
		{
			error = true
			error_obj.name = 'Le nom ne doit pas dépasser ' + this.length_max + ' caractères'
		}
		let found_default_title = false
		let found_default_content = false
		for (let i = 0; i < translations.length; i++)
		{
			const lang = translations[i].lang
			const title = translations[i].title
			if (title.length === 0 && lang === this.props.lang_default)
			{
				error = true
				error_obj.title = "Vous devez indiquer le titre (version " + lang.toUpperCase() + ")"
			}
			else if (title.length > this.length_max)
			{
				error = true
				error_obj.title = "Le titre ne doit pas dépasser " + this.length_max + " caractères (version " + lang.toUpperCase() + ")"
			}
			else if (lang === this.props.lang_default)
				found_default_title = true
			const content = translations[i].content
			if (content.length === 0 && lang === this.props.lang_default)
			{
				error = true
				error_obj.content = "Vous devez indiquer le contenu (version " + lang.toUpperCase() + ")"
			}
			else if (content.length > this.content_length)
			{
				error = true
				error_obj.content = "Le contenu ne doit pas dépasser " + this.content_length + " caractères (version " + lang.toUpperCase() + ")"
			}
			else if (lang === this.props.lang_default)
				found_default_content = true
		}
		if (!found_default_title && !error_obj.title)
		{
			error = true
			error_obj.title = 'Vous devez indiquer le titre (version ' + this.props.lang_default.toUpperCase() + ')'
		}
		if (!found_default_content && !error_obj.content)
		{
			error = true
			error_obj.content = 'Vous devez indiquer le contenu (version ' + this.props.lang_default.toUpperCase() + ')'
		}
		if (error)
			this.setState({ success: null, error: error_obj, attempt_submit: false })
		return (!error) ? true : false
	}

	handleSave = (event) => {
		event.preventDefault()
		this.clearFlash()
		this.setState({ attempt_submit: true })
		if (this._validateForm1())
		{
			const name = this.state.form1.name
			const redirect = this.state.form1.redirect
			const translations = this.state.form1.translations
			if (this.state.edit !== null)
			{
				const notification = {
					_id: this.state.edit._id,
					name: name,
					redirect: redirect,
					translations: translations,
					user: this.props.user._id
				}
				this.props.updateNotification(notification)
				.then(ret => {
					if (ret !== false)
					{
						const form1 = {
							name: '',
							redirect: '',
							translations: []
						}
						this.setSuccessMsg('La notification a bien été mise à jour')
						this.setState({ form1: form1, edit: null, attempt_submit: false })
					}
					else
					{
						this.setErrorMsg('error_update_notification', 1)
						this.setState({ attempt_submit: false })
					}
				})
				.catch(err => {
					this.setErrorMsg('error_update_notification', 2)
					this.setState({ attempt_submit: false })
					console.log(err)
				})
			}
			else if (this.state.create === true)
			{
				const notification = {
					name: name,
					redirect: redirect,
					translations: translations,
					user: this.props.user._id
				}
				this.props.insertNotification(notification)
				.then(ret => {
					if (ret !== false)
					{
						const form1 = {
							name: '',
							redirect: '',
							translations: []
						}
						this.setSuccessMsg('La notification a bien été créée')
						this.setState({ form1: form1, create: false, attempt_submit: false })
					}
					else
					{
						this.setErrorMsg('error_create_notification', 1)
						this.setState({ attempt_submit: false })
					}
				})
				.catch(err => {
					this.setErrorMsg('error_create_notification', 2)
					this.setState({ attempt_submit: false })
					console.log(err)
				})
			}
		}
	}

	handleCreate = () => {
		const form1 = {
			name: '',
			redirect: '{"type":"home"}',
			translations: []
		}
		this.clearFlash()
		this.setState({ edit: null, form1: form1, create: true })
	}

	initTimeSelectors = () => {
		let days = []
		for (let i = 1; i <= 31; i++)
		{
			let text = i
			if (i < 10)
				text = '0' + i
			days.push({
				value: i,
				text: text
			})
		}
		let months = []
		for (let i = 1; i <= 12; i++)
		{
			let text = i
			if (i < 10)
				text = '0' + i
			months.push({
				value: i - 1,
				text: text
			})
		}
		let hours = []
		for (let i = 0; i <= 23; i++)
		{
			let text = i
			if (i < 10)
				text = '0' + i
			hours.push({
				value: i,
				text: text
			})
		}
		let minutes = []
		for (let i = 0; i <= 59; i++)
		{
			let text = i
			if (i < 10)
				text = '0' + i
			minutes.push({
				value: i,
				text: text
			})
		}
		return ({
			days: days,
			months: months,
			hours: hours,
			minutes: minutes
		})
	}

	clickSelect = (id) => {
		let notification = null
		for (let i = 0; i < this.state.notifications.length; i++)
		{
			if (this.state.notifications[i]._id === id)
			{
				notification = this.state.notifications[i]
				break
			}
		}
		this.clearFlash()
		this.setState({ notification: notification })
	}

	clickEdit = (id) => {
		let edit = null
		let form1 = this.state.form1
		for (let i = 0; i < this.state.notifications.length; i++)
		{
			if (this.state.notifications[i]._id === id)
			{
				edit = this.state.notifications[i]
				form1 = {
					name: edit.name,
					redirect: JSON.stringify(edit.redirect),
					translations: edit.translations
				}
				break
			}
		}
		this.clearFlash()
		this.setState({ edit: edit, form1: form1 })
	}

	clickDelete = (id) => {
		this.setState({ delete: id, modal_delete: true })
	}

	_deleteCronNotification = () => {
		this.props.deleteNotification(this.state.delete)
		.then(ret => {
			if (ret === true)
			{
				this.setState({ modal_delete: false })
				this.setSuccessMsg("La notification a bien été supprimée")
			}
			else
			{
				this.setState({ modal_delete: false })
				this.setErrorMsg('error_delete_notification', 1)
			}
		})
		.catch(err => {
			this.setState({ modal_delete: false })
			this.setErrorMsg('error_delete_notification', 2)
			console.log(err)
		})
	}

	_validateForm2 = () => {
		const day = this.state.form2.day
		const month = this.state.form2.month
		const hour = this.state.form2.hour
		const minute = this.state.form2.minute
		const users = this.state.form2.users
		let error = false
		let error_obj = {
			global: null,
			name: null,
			title: null,
			content: null,
			users: null,
			day: null,
			month: null,
			hour: null
		}
		if (users.length === 0)
		{
			error = true
			error_obj.users = 'Veuillez sélectionner une liste d\'utilisateurs'
		}
		if (day.length === 0)
		{
			error = true
			error_obj.day = 'Jour obligatoire'
		}
		if (month.length === 0)
		{
			error = true
			error_obj.month = 'Mois obligatoire'
		}
		if (hour.length === 0)
		{
			error = true
			error_obj.hour = 'Heure obligatoire'
		}
		if (minute.length === 0)
		{
			error = true
			error_obj.hour = 'Minutes obligatoire'
		}
		if (day.length > 0 && month.length > 0 && hour.length > 0 && minute.length > 0)
		{
			const year = new Date().getFullYear()
			const time = Math.round(new Date(year, month, day, hour, minute).getTime() / 1000)
			const now = Math.round(new Date().getTime() / 1000)
			if (time < now)
			{
				error = true
				error_obj.global = 'La date d\'envoi doit être dans le futur'
			}
		}
		if (error)
			this.setState({ attempt_submit: false, success: null, error: error_obj })
		return (!error) ? true : false
	}

	submitSend = (event) => {
		event.preventDefault()
		this.clearFlash()
		this.setState({ attempt_submit: true })
		if (this._validateForm2())
		{
			const translations = this.state.notification.translations
			let langs_not_found = []
			for (let i = 0; i < this.props.langs.length; i++)
			{
				let found = false
				for (let j = 0; j < translations.length; j++)
				{
					if (this.props.langs[i] === translations[j].lang && translations[j].title.length > 0 && translations[j].content.length > 0)
					{
						found = true
						break
					}
				}
				if (!found)
					langs_not_found.push(this.props.langs[i])
			}
			if (langs_not_found.length > 0)
			{
				let msg = ""
				for (let i = 0; i < langs_not_found.length; i++)
				{
					if (i > 0)
						msg += ', '
					msg += langs_not_found[i].toUpperCase()
				}
				this.setState({ modal_msg: msg, modal_send: true })
			}
			else
				this._insertCronNotification()
		}
	}

	_insertCronNotification = () => {
		const day = this.state.form2.day
		const month = this.state.form2.month
		const hour = this.state.form2.hour
		const minute = this.state.form2.minute
		const users = this.state.form2.users
		const year = new Date().getFullYear()
		const time = Math.round(new Date(year, month, day, hour, minute).getTime() / 1000)
		let user_list = []
		if (users === 'all')
		{
			for (let i = 0; i < this.state.users.length; i++)
				user_list.push(this.state.users[i]._id)
		}
		else if (users === 'preselected')
		{
			for (let i = 0; i < this.state.selected_users.length; i++)
				user_list.push(this.state.selected_users[i]._id)
		}
		console.log("users", users)
		console.log("preselected", this.state.selected_users)
		console.log("user_list", user_list)
		const cron_notification = {
			timestamp: time,
			user: this.props.user._id,
			redirect: this.state.notification.redirect,
			translations: this.state.notification.translations,
			user_list: user_list
		}
		this.props.insertCronNotification(cron_notification)
		.then(ret => {
			if (ret !== false)
			{
				this.setState({ attempt_submit: false, open_modal: false })
				this.setSuccessMsg("L'envoi de notification a bien été paramétré")
			}
			else
			{
				this.setState({ attempt_submit: false, open_modal: false })
				this.setErrorMsg('error_send_notification', 1)
			}
		})
		.catch(err => {
			this.setState({ attempt_submit: false, open_modal: false })
			this.setErrorMsg('error_send_notification', 2)
			console.log(err)
		})
	}

	_getPageList = (categories, pages) => {
		const lang = this.state.current_lang
		let page_list = tools.clone([])
		for (let i = 0; i < categories.length; i++)
		{
			let name = ""
			let suffix = ""
			const category = this.props.categories[i]
			for (let j = 0; j < category.translations.length; j++)
			{
				if (category.translations[j].lang === lang)
				{
					name = category.translations[j].name
					suffix = category.translations[j].suffix
					break
				}
			}
			let prefix = ""
			for (let k = 0; k < this.props.groups.length; k++)
			{
				if (this.props.groups[k]._id === category.group._id)
				{
					const group = this.props.groups[k]
					for (let j = 0; j < group.translations.length; j++)
					{
						if (group.translations[j].lang === lang)
						{
							prefix = group.translations[j].prefix
							break
						}
					}
					break
				}
			}
			let url = this.lang_assets[lang].paths.rubrics + '/' + prefix + '/' + suffix
			if ((prefix.length === 0 || suffix.length === 0) && lang !== this.props.lang_default)
			{
				for (let j = 0; j < category.translations.length; j++)
				{
					if (category.translations[j].lang === this.props.lang_default)
					{
						url = "(rubrique indisponible en version " + lang.toUpperCase() + ")"
						name = category.translations[j].name
						break
					}
				}
			}
			const value = JSON.stringify({
				type: 'category',
				category: categories[i]._id
			})
			for (let k = 0; k < page_list.length; k++)
			{
				if (page_list[k].text === url + ' - ' + name)
				{
					console.log('same category', url + ' - ' + name)
					break
				}
			}
			page_list.push({
				value: value,
				text: name + ' - ' + url
			})
		}
		for (let i = 0; i < pages.length; i++)
		{
			const page = pages[i]
			if (page.category !== null)
				continue
			let name = ""
			let url = this.lang_assets[lang].paths.pages + '/'
			for (let j = 0; j < page.translations.length; j++)
			{
				if (page.translations[j].lang === lang)
				{
					name = page.translations[j].name
					url += page.translations[j].url
					break
				}
			}
			if (name.length === 0 && lang !== this.props.lang_default)
			{
				for (let j = 0; j < page.translations.length; j++)
				{
					if (page.translations[j].lang === this.props.lang_default)
					{
						url = "(page indisponible en version " + lang.toUpperCase() + ")"
						name = page.translations[j].name
						break
					}
				}
			}
			for (let k = 0; k < page_list.length; k++)
			{
				if (page_list[k].text === url + ' - ' + name)
				{
					console.log('same page', url + ' - ' + name)
					break
				}
			}
			const value = JSON.stringify({
				type: 'page',
				page: page._id
			})
			page_list.push({
				value: value,
				text: name + ' - ' + url
			})
		}
		page_list.sort((a, b) => {
			return a.text.localeCompare(b.text)
		})
		page_list.unshift({
			value: JSON.stringify({
				type: 'membres'
			}),
			text: "Page membres - " + this.lang_assets[lang].paths.account
		})
		page_list.unshift({
			value: JSON.stringify({
				type: 'animations'
			}),
			text: "Planning d'animations -" + this.lang_assets[lang].paths.activities
		})
		page_list.unshift({
			value: JSON.stringify({
				type: 'home'
			}),
			text: "Page d'accueil - /"
		})
		return (
			<select id="redirect" value={this.state.form1.redirect} onChange={this.handleRedirect}>
				{page_list.map(({ value, text }) => {
					return (<option value={value} key={value}>{text}</option>)
				})}
			</select>
		)
	}

	answerModalSend = (answer) => {
		if (answer === "yes")
			this._insertCronNotification()
		else
			this.setState({ attempt_submit: false, modal_send: false })
	}

	answerModalDelete = (answer) => {
		if (answer === "yes")
			this._deleteCronNotification()
		else
			this.setState({ delete: false, modal_delete: false })
	}

	updateLang = (lang) => {
		this.setState({ current_lang: lang })
	}

	render() {
		const notifications = this.state.notifications
		const categories = this.state.categories
		const pages = this.state.pages
		const users = this.state.users
		const permissions = this.props.user.permissions
		if (!permissions || !permissions.notifications || permissions.notifications === 0)
			return (<Redirect to="/" />)
		else if (notifications === undefined || pages === undefined || categories === undefined || users === undefined)
		{
			return (
				<div className="loader">
					<Loader />
				</div>
			)
		}
		else if (this.state.notification !== null)
		{
			const {days, months, hours, minutes} = this.initTimeSelectors()
			const notification = this.state.notification
			let title = ""
			let content = ""
			for (let i = 0; i < notification.translations.length; i++)
			{
				if (notification.translations[i].lang === this.state.current_lang)
				{
					title = notification.translations[i].title
					content = notification.translations[i].content
					break
				}
			}
			let printMsg = null
			if (this.state.error.global || this.state.success)
			{
				printMsg = (
					<div className="center">
						<p className="error">{this.state.error.global}</p>
						<p className="success">{this.state.success}</p>
					</div>
				)
			}
			let sendbtn = (
				<button type="submit" className="waves-effect waves-light btn-small">Envoyer une notification</button>
			)
			if (this.state.attempt_submit === true)
			{
				sendbtn = (
					<div className="center">
						<Loader />
					</div>
				)
			}
			const lang = this.state.current_lang
			let url = '/'
			if (notification.redirect.type === 'animations')
				url = this.lang_assets[lang].paths.activities
			else if (notification.redirect.type === 'membres')
				url = this.lang_assets[lang].paths.account
			else if (notification.redirect.type === 'page')
			{
				for (let i = 0; i < this.props.pages.length; i++)
				{
					if (notification.redirect.page === this.props.pages[i]._id)
					{
						url = this.lang_assets[lang].paths.pages + '/'
						for (let j = 0; j < this.props.pages[i].translations.length; j++)
						{
							if (this.props.pages[i].translations[j].lang === lang)
							{
								url += this.props.pages[i].translations[j].url
								break
							}
						}
						break
					}
				}
			}
			else if (notification.redirect.type === 'category')
			{
				for (let i = 0; i < this.props.categories.length; i++)
				{
					if (notification.redirect.category === this.props.categories[i]._id)
					{
						let suffix = ""
						const category = this.props.categories[i]
						for (let j = 0; j < category.translations.length; j++)
						{
							if (category.translations[j].lang === lang)
							{
								suffix = category.translations[j].suffix
								break
							}
						}
						let prefix = ""
						if (category.group.translations)
						{
							for (let j = 0; j < category.group.translations.length; j++)
							{
								if (category.group.translations[j].lang === lang)
								{
									prefix = category.group.translations[j].prefix
									break
								}
							}
						}
						url = this.lang_assets[lang].paths.rubrics + '/' + prefix + '/' + suffix
						break
					}
				}
			}
			let preselected = null
			if (this.state.selected_users && this.state.selected_users.length > 0)
				preselected = (<option value="preselected" selected>Utilisateurs pré-sélectionnés ({this.state.selected_users.length})</option>)
			const modal_msg = "La notification n'est pas traduite pour tous les utilisateurs et ne sera pas envoyé dans les langues suivantes : " + this.state.modal_msg
			return (
				<div className="wrapper-content">
					<Modal
						dismissible={false}
						content={modal_msg}
						open_modal={this.state.modal_send}
						answerModal={this.answerModalSend}
					/>
					<div className="row">
						<div className="module col s8">
							<h2 className="title-description">Envoyer une notification</h2>
							<LangSelector lang={this.state.current_lang} updateLang={this.updateLang} />
							<p className="x-large">
								Nom : {notification.name}<br />
								Titre : {title}<br />
								Contenu : {content}<br />
								Page : {url}
							</p>
						</div>
						<div className="transparent module col s4">
							<h2 className="title-description">Programmer un envoi</h2>
							{printMsg}
							<form className="module-form" onSubmit={this.submitSend}>
								<label>
									<p className="column-20">Jour :</p>
									<div className="column-20 right60">
										<select value={this.state.form2.day} onChange={this.handleDay}>
											<option value="">Jour</option>
											{days.map((day) => {
												return (<option value={day.value} key={day.value}>{day.text}</option>)
											})}
										</select>
										<p className="error">{this.state.error.day}</p>
									</div>
									<p className="column-20">Mois :</p>
									<div className="column-20">
										<select value={this.state.form2.start_month} onChange={this.handleMonth}>
											<option value="">Mois</option>
											{months.map((month) => {
												return (<option value={month.value} key={month.value}>{month.text}</option>)
											})}
										</select>
										<p className="error">{this.state.error.month}</p>
									</div>
								</label>
								<label>
									<p className="column-20">Heure :</p>
									<div className="column-20 right60">
										<select value={this.state.form2.hour} onChange={this.handleHour}>
											<option value="">Heure</option>
											{hours.map((hour) => {
												return (<option value={hour.value} key={hour.value}>{hour.text}</option>)
											})}
										</select>
										<p className="error">{this.state.error.hour}</p>
									</div>
									<p className="column-20">Minutes :</p>
									<div className="column-20">
										<select value={this.state.form2.minute} onChange={this.handleMinute}>
											<option value="">Minutes</option>
											{minutes.map((minute) => {
												return (<option value={minute.value} key={minute.value}>{minute.text}</option>)
											})}
										</select>
										<p className="error">{this.state.error.hour}</p>
									</div>
								</label>
								<label>
									<p className="error">{this.state.error.users}</p>
									<p className="column-20">Sélection :</p>
									<div className="column-80">
										<select value={this.state.form2.users} onChange={this.handleUsers}>
											<option value="all">Tous les utilisateurs</option>
											{preselected}
										</select>
									</div>
								</label>
								{sendbtn}
							</form>
						</div>
					</div>
				</div>
			)
		}
		else if (this.state.create === true || this.state.edit !== null)
		{
			let btn = (this.state.create) ? "Créer un template" : "Mettre à jour"
			const lang = this.state.current_lang
			let title = ""
			let content = ""
			for (let i = 0; i < this.state.form1.translations.length; i++)
			{
				if (this.state.form1.translations[i].lang === lang)
				{
					title = this.state.form1.translations[i].title
					content = this.state.form1.translations[i].content
					break
				}
			}
			const page_dropdown = this._getPageList(categories, pages)
			let printMsg = null
			if (this.state.error.global || this.state.success)
			{
				printMsg = (
					<div className="center">
						<p className="error">{this.state.error.global}</p>
						<p className="success">{this.state.success}</p>
					</div>
				)
			}
			let sendbtn = (
				<div className="center"style={{ marginTop: '20px' }}>
					<button type="submit" className="waves-effect waves-light btn-small">{btn}</button>
				</div>
			)
			if (this.state.attempt_submit === true)
			{
				sendbtn = (
					<div className="center">
						<Loader />
					</div>
				)
			}
			return (
				<div className="wrapper-content">
					<div className="row">
						<div className="module col s8">
							<h2 className="title-description">Paramétrer un template de notification</h2>
							{printMsg}
							<LangSelector lang={this.state.current_lang} updateLang={this.updateLang} />
							<form className="module-form" onSubmit={this.handleSave}>
								<label>
									<p className="error">{this.state.error.name}</p>
									<p>Nom :</p>
									<input type="text" value={this.state.form1.name} onChange={this.handleName} />
								</label>
								<label>
									<p className="error">{this.state.error.title}</p>
									<p>Titre (version {this.state.current_lang.toUpperCase()}) :</p>
									<input type="text" value={title} onChange={this.handleTitle} />
								</label>
								<label>
									<p className="error">{this.state.error.content}</p>
									<p>Contenu (version {this.state.current_lang.toUpperCase()}) :</p>
									<input type="text" value={content} onChange={this.handleContent} />
								</label>
								<label>
									<p>Page :</p>
									{page_dropdown}
								</label>
								{sendbtn}
							</form>
						</div>
					</div>
				</div>
			)
		}
		else
		{
			let printMsg = null
			let empty_folder = ""
			if (this.state.error.global || this.state.success)
			{
				printMsg = (
					<div className="center">
						<p className="error">{this.state.error.global}</p>
						<p className="success">{this.state.success}</p>
					</div>
				)
			}
			if (!notifications || !notifications.length)
			{
				empty_folder = (
					<EmptyFolder
						emptyFolderSubtitle = "Pas de template de notification paramétré"
					/>
				)
			}
			const modal_msg = "Vous êtes sur le point de supprimer ce template de notification. Cette action est irréversible"
			return (
				<div className="wrapper-content">
					<Modal
						dismissible={false}
						content={modal_msg}
						open_modal={this.state.modal_delete}
						answerModal={this.answerModalDelete}
					/>
					<div className="row">
						<div className="module col s12">
							{printMsg}
							<button className="waves-effect waves-light btn" onClick={this.handleCreate}>Créer un template</button>
							<div className="clear"></div>
							{empty_folder}
							{notifications.map(({ _id, name }) => {
								return (
									<div className="object" key={_id}>
										<div className="title"><p onClick={this.clickSelect.bind(this, _id)}>{name}</p></div>
										<div className="buttons">
											<button type="button" className="waves-effect waves-light btn" onClick={this.clickEdit.bind(this, _id)}>Éditer</button>
											<button type="button" className="waves-effect waves-light btn red" onClick={this.clickDelete.bind(this, _id)}>Supprimer</button>
										</div>
										<div className="clear"></div>
									</div>
								)
							})}
						</div>
					</div>
				</div>
			)
		}
	}
}

export default TemplateNotification

import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'
import fr_flag from '../../images/flags/france.png'
import en_flag from '../../images/flags/united-kingdom.png'
import de_flag from '../../images/flags/germany.png'
import nl_flag from '../../images/flags/netherlands.png'
import it_flag from '../../images/flags/italy.png'
import es_flag from '../../images/flags/spain.png'
import pt_flag from '../../images/flags/portugal.png'
import LangSelector from '../UI/LangSelector'
import Modal from '../UI/Modal'
import Loader from '../UI/Loader'

class ConfigWebapp extends Component {
	constructor(props) {
		super(props)
		this.state = {
			attempt_submit: false,
			form1: {
				sendmail: this.props.emails_activate,
				nbdays: this.props.emails_nbdays,
				establishment: this.props.establishment,
				fr: (this.props.langs.indexOf('fr') !== -1) ? true : false,
				en: (this.props.langs.indexOf('en') !== -1) ? true : false,
				de: (this.props.langs.indexOf('de') !== -1) ? true : false,
				nl: (this.props.langs.indexOf('nl') !== -1) ? true : false,
				it: (this.props.langs.indexOf('it') !== -1) ? true : false,
				es: (this.props.langs.indexOf('es') !== -1) ? true : false,
				pt: (this.props.langs.indexOf('pt') !== -1) ? true : false,
				background: this.props.background_img,
				welcome: this.props.welcome_txt
			},
			modal_msg: null,
			open_modal: false,
			error: this._initErrors(),
			success: null,
			current_lang: this.props.lang_default
		}
		this.length_max = 40
		this.props.updateNavbarTitle('Paramètres généraux')
	}

	setErrorMsg = (key, msg) => {
		if (key === "default_update_config")
		{
			key = "global"
			msg = "Erreur: Impossible de mettre à jour les paramètres généraux. Veuillez contacter le service technique (code : #" + msg + ")"
		}
		let error = this._initErrors()
		error[key] = msg
		this.setState({ success: null, error: error })
	}

	setSuccessMsg = (msg) => {
		this.setState({ error: this._initErrors(), success: msg })
	}

	clearFlash = () => {
		this.setState({ error: this._initErrors(), success: null })
	}

	_initErrors = () => {
		const errors = {
			global: null,
			establishment: null,
			welcome: null,
			nbdays: null,
			langs: null
		}
		return errors
	}

	handleSendMail = (event) => {
		const form1 = {
			...this.state.form1,
			sendmail: (this.state.form1.sendmail === true) ? false : true
		}
		this.setState({ form1: form1 })
	}

	handleNbDays = (event) => {
		const form1 = {
			...this.state.form1,
			nbdays: event.target.value
		}
		this.setState({ form1: form1 })
	}

	handleEstablishment = (event) => {
		const form1 = {
			...this.state.form1,
			establishment: event.target.value
		}
		this.setState({ form1: form1 })
	}

	handleWelcome = (event) => {
		let found = false
		const welcome = this.state.form1.welcome
		for (let i = 0; i < welcome.length; i++)
		{
			if (welcome[i].lang === this.state.current_lang)
			{
				found = true
				welcome[i].welcome = event.target.value
				break
			}
		}
		if (!found)
		{
			welcome.push({
				lang: this.state.current_lang,
				welcome: event.target.value
			})
		}
		const form1 = {
			...this.state.form1,
			welcome: welcome
		}
		this.setState({ form1: form1 })
	}

	handleLang = (lang) => {
		const form1 = {
			...this.state.form1
		}
		form1[lang] = (this.state.form1[lang] === true) ? false : true
		this.setState({ form1: form1 })
	}

	handleBackground = (event) => {
		const reader = new FileReader()
		const file = event.target.files[0]
		reader.addEventListener("load", () => {
			const form1 = {
				...this.state.form1,
				background: reader.result
			}
			this.setState({ form1: form1 })
		}, false)
		if (file)
			reader.readAsDataURL(file)
	}

	_validateForm1 = () => {
		let error_obj = this._initErrors()
		let error = false
		const establishment = this.state.form1.establishment
		const nbdays = this.state.form1.nbdays
		const welcome = this.state.form1.welcome
		if (establishment.length === 0)
		{
			error = true
			error_obj.establishment = "Veuillez renseigner le nom de l'établissement"
		}
		else if (establishment.length > this.length_max)
		{
			error = true
			error_obj.establishment = "Le nom de l'établissement ne doit pas dépasser " + this.length_max + " caractères"
		}
		if (nbdays.length === 0)
		{
			error = true
			error_obj.nbdays = "Veuillez renseigner un nombre de jours"
		}
		else if (!/^( *[0-9]+ *,?)+$/.test(nbdays))
		{
			error = true
			error_obj.nbdays = "Vous devez renseigner un ou plusieurs nombre de jours séparés par des virgules"
		}
		if (this.state.form1[this.props.lang_default] === false)
		{
			error = true
			error_obj.langs = "Vous ne pouvez pas désactiver cette langue : " + this.props.lang_default.toUpperCase()
		}
		let found_default_welcome = false
		for (let i = 0; i < welcome.length; i++)
		{
			const lang = welcome[i].lang
			const welcome_txt = welcome[i].welcome
			if (welcome_txt.length === 0 && lang === this.props.lang_default)
			{
				error = true
				error_obj.welcome = "Vous devez indiquer le texte de bienvenue (version " + lang.toUpperCase() + ")"
			}
			else if (welcome_txt.length > this.length_max)
			{
				error = true
				error_obj.welcome = "Le texte de bienvenue ne doit pas dépasser " + this.length_max + " caractères (version " + lang.toUpperCase() + ")"
			}
			else if (lang === this.props.lang_default)
				found_default_welcome = true
		}
		if (!found_default_welcome && !error_obj.welcome)
		{
			error = true
			error_obj.welcome = "Vous devez indiquer le texte de bienvenue (version " + this.props.lang_default.toUpperCase() + ")"
		}
		if (error === true)
		{
			error_obj.global = "Il y a une ou plusieurs erreur(s) dans le formulaire"
			this.setState({ success: null, error: error_obj, attempt_submit: false })
		}
		return (!error) ? true : false
	}

	_updateConfig = () => {
		const config = {
			establishment: this.state.form1.establishment,
			sendmail: this.state.form1.sendmail,
			nbdays: this.state.form1.nbdays,
			langs: {
				fr: this.state.form1.fr,
				en: this.state.form1.en,
				de: this.state.form1.de,
				nl: this.state.form1.nl,
				it: this.state.form1.it,
				es: this.state.form1.es,
				pt: this.state.form1.pt
			},
			background: this.state.form1.background,
			welcome: this.state.form1.welcome
		}
		this.props.updateConfig(config)
		.then(ret => {
			if (!ret)
			{
				this.setState({ attempt_submit: false })
				this.setErrorMsg('default_update_config', 1)
			}
			else
			{
				this.setState({ attempt_submit: false })
				this.setSuccessMsg('Les paramètres ont bien été mis à jour')
			}
		})
		.catch(err => {
			this.setState({ attempt_submit: false })
			this.setErrorMsg('default_update_config', 2)
			console.log(err)
		})
	}

	handleSubmit = (event) => {
		event.preventDefault()
		this.setState({ attempt_submit: true })
		if (this._validateForm1())
		{
			const welcome = this.state.form1.welcome
			let langs_not_found = []
			for (let i = 0; i < this.props.langs.length; i++)
			{
				let found = false
				for (let j = 0; j < welcome.length; j++)
				{
					if (this.props.langs[i] === welcome[j].lang && welcome[j].welcome.length > 0)
					{
						found = true
						break
					}
				}
				if (!found)
					langs_not_found.push(this.props.langs[i])
			}
			if (langs_not_found.length > 0)
			{
				let msg = ""
				for (let i = 0; i < langs_not_found.length; i++)
				{
					if (i > 0)
						msg += ', '
					msg += langs_not_found[i].toUpperCase()
				}
				msg = "Le texte de bienvenue n'est pas traduit dans les langues suivantes : " + msg
				this.setState({ modal_msg: msg, open_modal: true })
			}
			else
				this._updateConfig()
		}
	}

	answerModal = (answer) => {
		if (answer === "yes")
			this._updateConfig()
		else
			this.setState({ attempt_submit: false, open_modal: false })
	}

	updateLang = (lang) => {
		this.setState({ current_lang: lang })
	}

	render() {
		let prev = null
		if (this.state.form1.background !== null)
			prev = (<div><p>Prévisualisation :</p><p style={{ width: '30%' }}><img src={this.state.form1.background} alt="Prévisualisation" /></p></div>)
		let sendbtn = (<button type="submit" className="waves-effect waves-light btn-small">Enregistrer</button>)
		let welcome = ""
		for (let i = 0; i < this.state.form1.welcome.length; i++)
		{
			if (this.state.form1.welcome[i].lang === this.state.current_lang)
			{
				welcome = this.state.form1.welcome[i].welcome
				break
			}
		}
		const permissions = this.props.user.permissions
		if (!permissions || !permissions.params || permissions.params === 0)
			return (<Redirect to="/" />)
		else if (this.state.attempt_submit === true)
		{
			sendbtn = (
				<div className="center">
					<Loader />
				</div>
			)
		}
		let admin_btn = null
		console.log(permissions)
		if (permissions && permissions.super && permissions.super === 1)
			admin_btn = (<Link to="/administration/features"><button type="button" className="waves-effect waves-light btn-small">Paramètres administrateur</button></Link>)
		return (
			<div className="wrapper-content">
				<Modal
					dismissible={false}
					content={this.state.modal_msg}
					open_modal={this.state.open_modal}
					answerModal={this.answerModal}
				/>
				<div className="row">
					<div className="module col s12">
						<p className="success">{this.state.success}</p>
						{admin_btn}
						<LangSelector lang={this.state.current_lang} updateLang={this.updateLang} />
						<form className="module-form" onSubmit={this.handleSubmit}>
							<label>
								<p className="error">{this.state.error.establishment}</p>
								<p className="column-30">Nom de l'établissement :</p>
								<input type="text" value={this.state.form1.establishment} onChange={this.handleEstablishment} />
							</label>
							<label>
								<p className="error">{this.state.error.welcome}</p>
								<p className="column-30">Sous-titre bienvenue écran d'accueil (version {this.state.current_lang.toUpperCase()}) :</p>
								<input type="text" value={welcome} onChange={this.handleWelcome} />
							</label>
							<label>
								<p className="column-30">Activer l'envoi automatique des mails avant-séjour :</p>
								<input type="checkbox" checked={this.state.form1.sendmail} onChange={this.handleSendMail} />
								<span></span>
							</label>
							<label>
								<p className="error">{this.state.error.nbdays}</p>
								<p className="column-30">Nombre de jours avant d'envoyer le mail avant-séjour :</p>
								<input type="text" min="0" max="60" value={this.state.form1.nbdays} onChange={this.handleNbDays} />
							</label>
							<p className="error">{this.state.error.langs}</p>
							<p className="column-30">Langues activées :</p>
							<div className="clear"></div>
							<div className="column-10">
								<label>
									<span className="form-langs"><img src={fr_flag} alt="français" title="français" /></span>
									<div className="clear"></div>
									<input type="checkbox" checked={this.state.form1.fr} onChange={this.handleLang.bind(this, 'fr')} /><span className="form-langs-checkbox"></span>
								</label>
							</div>
							<div className="column-10">
								<label>
									<span className="form-langs"><img src={en_flag} alt="anglais" title="anglais" /></span>
									<div className="clear"></div>
									<input type="checkbox" checked={this.state.form1.en} onChange={this.handleLang.bind(this, 'en')} /><span className="form-langs-checkbox"></span>
								</label>
							</div>
							<div className="column-10">
								<label>
									<span className="form-langs"><img src={de_flag} alt="allemand" title="allemand" /></span>
									<div className="clear"></div>
									<input type="checkbox" checked={this.state.form1.de} onChange={this.handleLang.bind(this, 'de')} /><span className="form-langs-checkbox"></span>
								</label>
							</div>
							<div className="column-10">
								<label>
									<span className="form-langs"><img src={nl_flag} alt="hollandais" title="hollandais" /></span>
									<div className="clear"></div>
									<input type="checkbox" checked={this.state.form1.nl} onChange={this.handleLang.bind(this, 'nl')} /><span className="form-langs-checkbox"></span>
								</label>
							</div>
							<div className="column-10">
								<label>
									<span className="form-langs"><img src={it_flag} alt="italien" title="italien" /></span>
									<div className="clear"></div>
									<input type="checkbox" checked={this.state.form1.it} onChange={this.handleLang.bind(this, 'it')} /><span className="form-langs-checkbox"></span>
								</label>
							</div>
							<div className="column-10">
								<label>
									<span className="form-langs"><img src={es_flag} alt="espagnol" title="espagnol" /></span>
									<div className="clear"></div>
									<input type="checkbox" checked={this.state.form1.es} onChange={this.handleLang.bind(this, 'es')} /><span className="form-langs-checkbox"></span>
								</label>
							</div>
							<div className="column-10">
								<label>
									<span className="form-langs"><img src={pt_flag} alt="portugais" title="portugais" /></span>
									<div className="clear"></div>
									<input type="checkbox" checked={this.state.form1.pt} onChange={this.handleLang.bind(this, 'pt')} /><span className="form-langs-checkbox"></span>
								</label>
							</div>
							<div className="clear"></div>
							<label>
								<p className="column-20">Arrière-plan écran d'accueil :</p>
								<input type="file" onChange={this.handleBackground} />
							</label>
							{prev}
							<p className="error">{this.state.error.global}</p>
							{sendbtn}
						</form>
					</div>
				</div>
			</div>
		)
	}
}

export default ConfigWebapp

import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { HomeIcon } from '@heroicons/react/outline'
import EmptyFolder from '../UI/EmptyFolder'
import { PlusCircleIcon, DotsHorizontalIcon, ExclamationCircleIcon } from '@heroicons/react/solid'
import { Dialog, Transition, Menu } from '@headlessui/react'
// import { PlusCircleIcon, ExclamationCircleIcon } from '@heroicons/react/solid'
// import { Dialog, Transition } from '@headlessui/react'
import pdfIcon from '../../images/pdf.png'
import { Fragment } from 'react'
import Loader from '../UI/Loader'
import connexion from '../../connexion'

class Pdf extends Component {
	constructor(props) {
		super(props)
		this.state = {
			form1: {
				isOpen: false,
				id: '',
				title: '',
				link: '',
			},
			form2: {
				pdf: [
					{
						id: 1,
						title: 'Règlement',
						create_time: 1652176676,
						link: 'www.pocketcamp.pdf'
					},
					{
						id: 2,
						title: 'Charte',
						create_time: 1652176676,
						link: 'pocketcamp.pdf'
					},
					{
						id: 3,
						title: 'Caution',
						create_time: 1652176676,
						link: 'pocketcamp.pdf'
					},
					{
						id: 4,
						title: 'Tri sélectif',
						create_time: 1652176676,
						link: '267979365_499719464679438_5598747032707996669_n copie.pdf'
					}
				]
			},
			files: null,
			breadcrumb: [
				{ name: "Fichiers", href: '/pdf', current: false },
			],
			error: this._initErrors(),
			sucess: null
		}
	}

	componentDidMount() {
		this.loadFiles()
	}

	componentDidUpdate(prevProps) {
		if (prevProps.files !== this.props.files)
			this.loadFiles(true)
	}

	loadFiles = (fromProps = false) => {
		if (fromProps === true)
			this.setState({ files: this.props.files })
		else
		{
			this.props.fetchFiles()
			.then(files => {
				this.setState({ files: files })
			})
		}
	}

	setSuccessMsg = (msg) => {
		this.setState({ success: msg, bannerContent: msg, showBanner: true, error: this._initErrors() })
	}

	setErrorMsg = (key, msg, id = null) => {
		//cas d'erreurs
		//Si la personne en modifiant met un titre vide: automatiquement nommer le fichier par le lien
		let error = this._initErrors()
		if (id !== null)
			error[key][id] = msg
		else
			error[key] = msg
		this.setState({ success: null, showBanner: true, bannerContent: msg, error: error })
	}

	_initErrors = () => {
		const errors = {
			global: null,
			title: null,
			upload: null,
			link: null
		}
		return errors
	}

	classNames = (...classes) => {
		return classes.filter(Boolean).join(' ')
	}

	showFile = () => {
		console.log('Aperçu')
	}

	renderMonth = (d) => {
		const date = new Date(d * 1000)
		let month = date.getMonth() + 1
		if (month < 10)
			month = '0' + month
		return (month)
	}

	renderDay = (d) => {
		const date = new Date(d * 1000)
		return (date.getDate())
	}

	renderYear = (d) => {
		const date = new Date(d * 1000)
		return (date.getFullYear())
	}

	uploadFile = (event) => {
		this.setState({ error: this._initErrors() })
		const data = new FormData()
		const file = event.target.files[0]
		if (file.type.substr(0, "image/".length) === "image/")
		{
			data.append("file", file)
			data.append("path", "images")
			data.append("sizes[0]", 1920)
			data.append("sizes[1]", 1280)
			data.append("sizes[2]", 192)
			this.props.insertPicture(data)
			.then(ret => {
				const files = this.state.files
				files.push(ret)
				this.setState({ files: files, success: "Fichier importé" })
			})
			.catch(err => console.log(err))
		}
		else if (file.type === "application/pdf")
		{
			data.append("file", file)
			data.append("path", "pdfs")
			this.props.insertPdf(data)
			.then(ret => {
				const files = this.state.files
				files.push(ret)
				this.setState({ files: files, success: "Fichier importé" })
			})
			.catch(err => console.log(err))
		}
		else
			this.setErrorMsg("upload", "Ce format de fichier n'est pas pris en charge")
	}

	openModal = (elem) => {
		const form1 = {
			...this.state.form1,
			isOpen: true,
			id: elem.id,
			title: elem.title,
			link: elem.link
		}
		this.setState({ form1: form1 })
	}

	updateTitle = (event) => {
		const form1 = {
			...this.state.form1,
			isOpen: true,
			title: event.target.value,
		}
		this.setState({ form1: form1 })
	}

	updateLink = (event) => {
		const reader = new FileReader()
		const file = event.target.files[0]
		console.log('New file: ', file)
		reader.addEventListener("load", () => {
			const form1 = {
				...this.state.form1,
				title: file.name,
				link: file.name
			}
			this.setState({ form1: form1 })
		}, false)
		if (file)
			reader.readAsDataURL(file)
	}

	copyToClipboard = (file) => {
		if (file.name && file.name.length > 0 && file.type && file.type.length > 0)
		{
			const filename = file.name.split('.')[0]
			if (file.type && file.type.substr(0, "image/".length) === "image/" && file.sources && file.sources.length > 0)
				{
				const path = connexion.connect.url + 'dashboard/files/get-image/' + filename
				if (navigator && navigator.clipboard && navigator.clipboard.writeText)
				{
					this.setSuccessMsg("Le lien a été copié dans le presse-papier")
					return (navigator.clipboard.writeText(path))
				}
				return (Promise.reject('The Clipboard API is not available'))
			}
			else if (file.type && file.type === "application/pdf")
			{
				const path = connexion.connect.url + 'dashboard/files/get-file/' + filename
				if (navigator && navigator.clipboard && navigator.clipboard.writeText)
				{
					this.setSuccessMsg("Le lien a été copié dans le presse-papier")
					return (navigator.clipboard.writeText(path))
				}
				return (Promise.reject('The Clipboard API is not available'))
			}
			else
				this.setErrorMsg("upload", "Impossible de copier le lien du fichier")
		}
		else
			this.setErrorMsg("upload", "Impossible de copier le lien du fichier")
	}

	submitEditFile = (event) => {
		event.preventDefault()
		const form1 = {
			...this.state.form1,
			isOpen: false
		}
		this.setState({ form1: form1 })
	}

	deleteFile = (file) => {
		if (file.type === "application/pdf")
		{
			this.props.deletePdf(file._id)
			.then(ret => {
				if (ret === false)
					this.setErrorMsg("upload", "Une erreur s'est produite lors de la suppression du pdf")
				else
					this.setSuccessMsg("Fichier supprimé")
			})
			.catch(err => console.log(err))
		}
		else if (file.type.substr(0, "image/".length) === "image/")
		{
			this.props.deletePicture(file._id)
			.then(ret => {
				if (ret === false)
					this.setErrorMsg("upload", "Une erreur s'est produite lors de la suppression de l'image")
				else
					this.setSuccessMsg("Image supprimée")
			})
			.catch(err => console.log(err))
		}
		else
			this.setErrorMsg("upload", "Une erreur s'est produite lors de la suppression du fichier")
	} 

	closeModal = () => {
		const form1 = {
			...this.state.form1,
			isOpen: false,
		}
		this.setState({ form1 : form1 })
	}

	handleTitle = (event) => {
		const form1 = {
			...this.state.form1,
			title: event.target.value
		}
		this.setState({ form1: form1 })
	}

	render() {
		const navStyle = "flex bg-transparent shadow-none mb-10"
		let files = (<EmptyFolder emptyFolderSubtitle="Pas de fichiers" content="Cliquez sur Importer un fichier" />)
		let error_nameJSX = null
		let error_uploadJSX = null
		let success_msgJSX = null
		if (this.state.files === null)
		{
			return (
				<Loader />
			)
		}
	 	if (this.state.error.link) //si pas possible d'upload, mauvais format...
		{
			error_nameJSX = (
				<div className="flex">
					<div className="flex-shrink-0">
						<ExclamationCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
					</div>
					<div className="ml-3 flex-1 md:flex md:justify-between">
						<p className="text-sm text-red-700">{this.state.error.link}</p>
					</div>
				</div>
			)
		}
		if (this.state.error.upload)
		{
			error_uploadJSX = (
				<div className="flex mt-4">
					<div className="flex-shrink-0">
						<ExclamationCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
					</div>
					<div className="ml-3 flex-1 md:flex md:justify-between">
						<p className="text-sm text-bold text-red-700">{this.state.error.upload}</p>
					</div>
				</div>
			)
		}
		if (this.state.success)
		{
			success_msgJSX = (
				<div className="flex mt-4">
					<div className="flex-shrink-0">
						<ExclamationCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
					</div>
					<div className="ml-3 flex-1 md:flex md:justify-between">
						<p className="text-sm text-bold text-green-700">{this.state.success}</p>
					</div>
				</div>
			)
		}
		if (this.state.files.length > 0)
		{
			files = (
				<>
					<ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-6">
						{this.state.files.map((file, file_k) => {
							const created_time = file.created_time
							const day = this.renderDay(created_time)
							const month = this.renderMonth(created_time)
							const year = this.renderYear(created_time)
							let imageSrcData = null
							const filename = (file.name && file.name.length > 0) ? file.name.split('.')[0] : String(file._id)
							if (file.type && file.type.substr(0, "image/".length) === "image/" && file.data && file.data.length > 0)
								imageSrcData = (<img src={file.data} alt={file.name} />)
							else if (file.type && file.type === "application/pdf")
							{
								imageSrcData = (
									<a href={connexion.connect.url + 'dashboard/files/get-file/' + filename} target="_blank" rel="noopener noreferrer">
										<img src={pdfIcon} alt={file.name} style={{ width: '50%', margin: 'auto' }} />
									</a>
								)
							}
							else
							{
								imageSrcData = (
									<a href={connexion.connect.url + 'dashboard/files/get-image/' + filename} target="_blank" rel="noopener noreferrer">
										<img src={connexion.connect.url + 'dashboard/files/get-image/' + filename + '/720'} alt={file.name} />
									</a>
								)
							}
							return (
							<li
								key={file_k}
								className="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200"
							>
								
								<div className="flex-1 flex flex-col p-8 cursor-pointer" onClick={this.showFile}>
									{/* <img className="h-24 flex-shrink-0 mx-auto" src={pdfIcon} alt="" /> */}
									{imageSrcData}
									<p className="mt-6 mb-1 text-gray-900 text-sm font-medium truncate">{file.originalname}</p>
									{/* <p className="text-gray-500 text-sm">Ajouté le {file.create_time}</p> */}
									<p className="text-gray-500 text-sm">Ajouté le {day}/{month}/{year}</p>
								</div>
								<div className="rounded-b-lg bottom-0 inset-x-0 bg-gray-50 px-4 py-2 sm:px-6">
									<Menu as="div" className="relative flex justify-end inline-block text-left">
										<div>
											<Menu.Button className="rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
												<span className="sr-only">Open options</span>
												<DotsHorizontalIcon className="h-5 w-5" aria-hidden="true" />
											</Menu.Button>
										</div>
										<Transition
											as={Fragment}
											enter="transition ease-out duration-100"
											enterFrom="transform opacity-0 scale-95"
											enterTo="transform opacity-100 scale-100"
											leave="transition ease-in duration-75"
											leaveFrom="transform opacity-100 scale-100"
											leaveTo="transform opacity-0 scale-95"
										>
											<Menu.Items className="z-10 mx-3 origin-top-left absolute left-36 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
												<div className="py-1 ">
													{/*
													** A décommenter si on veut ajouter la fonctionnalité de modifier le fichier
													
													<Menu.Item>
														{({ active }) => (
															<button
																type='button'
																onClick={this.openModal.bind(this, file)}
																className={this.classNames(
																	active ? 'bg-gray-100 text-gray-900 w-full text-left' : 'w-full text-left text-gray-700',
																	'block px-4 py-2 text-sm w-full text-left'
																)}
																>
																Modifier
															</button>
														)}
													</Menu.Item> */}
													<Menu.Item>
														{({ active }) => (
															<button
																type='button'
																onClick={this.copyToClipboard.bind(this, file)}
																className={this.classNames(
																	active ? 'bg-gray-100 text-gray-900 w-full text-left' : 'w-full text-left text-gray-700',
																	'block px-4 py-2 text-sm w-full text-left'
																)}
																>
																Copier le lien
															</button>
														)}
													</Menu.Item>
													<Menu.Item>
														{({ active }) => (
															<button
																type='button'
																onClick={this.deleteFile.bind(this, file)}
																className={this.classNames(
																	active ? 'bg-gray-100 text-gray-900 w-full text-left' : 'text-gray-700 w-full text-left',
																	'block w-full text-left px-4 py-2 text-sm'
																)}
																>
																Supprimer
															</button>
														)}
													</Menu.Item>
												</div>
											</Menu.Items>
										</Transition>
									</Menu>
								</div>
							</li>
						)})}
						</ul>
						{/* Modal pour modifier le fichier */}
						<Transition appear show={this.state.form1.isOpen} as={Fragment}>
							<Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={this.closeModal}>
								<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
									<Transition.Child
										as={Fragment}
										enter="ease-out duration-300"
										enterFrom="opacity-0"
										enterTo="opacity-100"
										leave="ease-in duration-200"
										leaveFrom="opacity-100"
										leaveTo="opacity-0"
									>
										<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
									</Transition.Child>
									<span
										className="hidden sm:inline-block sm:align-middle sm:h-screen"
										aria-hidden="true"
									>
										&#8203;
									</span>
									<Transition.Child
										as={Fragment}
										enter="ease-out duration-300"
										enterFrom="opacity-0 scale-95"
										enterTo="opacity-100 scale-100"
										leave="ease-in duration-200"
										leaveFrom="opacity-100 scale-100"
										leaveTo="opacity-0 scale-95"
									>
										<form onSubmit={this.submitEditFile} className="inline-block align-bottom bg-white rounded-lg px-4 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
											<label htmlFor="title" className="block text-sm font-medium text-gray-700 mb-0">
												Nom du fichier
											</label>
											<div className="mt-1">
												<input
													type="text"
													id="title"
													className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
													placeholder={this.state.form1.link}
													value={this.state.form1.title}
													onChange={this.updateTitle}
												/>
												{error_nameJSX}
											</div>
											<div className="sm:mt-0 sm:col-span-2">
												<div className="flex items-center">
													<p className="truncate font-medium">{this.state.form1.link}</p>
													<label htmlFor="link" className="ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
														Parcourir...
													</label>
													<input
														id="link"
														type="file"
														className="opacity-0 w-0.5 h-0.5 absolute"
														onChange={this.updateLink}
													/>
													{error_nameJSX}
												</div>
											</div>
											<div className="mt-7">
												<button type="submit" className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500">
													Enregistrer
												</button>
											</div>
										</form>
									</Transition.Child>
								</div>
							</Dialog>				
						</Transition>
					</>
				)
		}
		return (
			<div>
				<nav className={navStyle} aria-label="Breadcrumb">
					<ol className="bg-white rounded-md shadow px-6 flex space-x-4">
						<li className="flex">
							<div className="flex items-center">
								<Link to="/" className="text-gray-400 hover:text-gray-500">
									<HomeIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
									<span className="sr-only">Home</span>
								</Link>
							</div>
						</li>
						{this.state.breadcrumb.map((page) => (
							<li key={page.name} className="flex">
								<div className="flex items-center">
									<svg
										className="flex-shrink-0 w-6 h-full text-gray-200"
										viewBox="0 0 24 44"
										preserveAspectRatio="none"
										fill="currentColor"
										xmlns="http://www.w3.org/2000/svg"
										aria-hidden="true"
									>
										<path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
									</svg>
								<Link
									to={page.href}
									className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
									aria-current={page.current ? 'page' : undefined}
								>
									{page.name}
								</Link>
								</div>
							</li>
						))}
					</ol>
				</nav>
				<div>
                	<h2 className="text-xl leading-6 font-medium text-gray-900 mb-0">Fichiers</h2>
                	<p className="mt-1 text-base text-gray-500">
						Retrouvez ici vos fichiers images et PDFs. Pour ajouter un fichier dans votre app, cliquez sur "Récupérer le lien" puis copier le dans votre contenu.
                	</p>
                	<p className="mt-1 text-base text-gray-500">Formats acceptés: jpg, jpeg, png, pdf</p>
					{error_uploadJSX}
					{success_msgJSX}
					<div className="cursor-pointer">
						<label htmlFor="file" className="cursor-pointer mb-10 mt-5 inline-flex items-center px-6 py-3 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-50 focus:ring-indigo-600">
							Importer un fichier <PlusCircleIcon className="ml-3 h-5 w-5" aria-hidden="true" />
						</label>
						<input
							className="opacity-0 w-0.5 h-0.5 absolute"
							type="file"
							onChange={this.uploadFile}
							id="file"
						/>
					</div>
					{files}
				</div>
			</div>
		)
	}
}

export default Pdf
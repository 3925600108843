import React, { Component } from 'react'
import sha1 from 'sha1'
import connexion from '../../connexion'
import tools from '../../tools'

class User extends Component {
	constructor(props) {
		super(props)
		const user = this.props.user
		this.state = {
			form1: {
				password: '',
				avatar: user.avatar,
				lastname: user.lastname,
				firstname: user.firstname,
				email: user.email,
				username: user.username,
				newpassword: '',
				confirm: ''
			},
			error: null,
			success: null
		}
		this.props.updateNavbarTitle('Paramètres')
	}

	setErrorMsg = (msg) => {
		this.setState({ error: msg, success: null })
	}

	setSuccessMsg = (msg) => {
		this.setState({ error: null, success: msg })
	}

	clearFlash = () => {
		this.setState({ error: null, success: null })
	}

	handlePassword = (event) => {
		const form1 = {
			...this.state.form1,
			password: event.target.value
		}
		this.setState({ form1: form1 })
	}

	handleAvatar = (event) => {
		const reader = new FileReader()
		const file = event.target.files[0]
		reader.addEventListener("load", () => {
			const form1 = {
				...this.state.form1,
				avatar: reader.result
			}
			this.setState({ form1: form1 })
		}, false)
		if (file)
			reader.readAsDataURL(file)
	}

	handleLastname = (event) => {
		const form1 = {
			...this.state.form1,
			lastname: event.target.value
		}
		this.setState({ form1: form1 })
	}

	handleFirstname = (event) => {
		const form1 = {
			...this.state.form1,
			firstname: event.target.value
		}
		this.setState({ form1: form1 })
	}

	handleEmail = (event) => {
		const form1 = {
			...this.state.form1,
			email: event.target.value
		}
		this.setState({ form1: form1 })
	}

	handleUsername = (event) => {
		const form1 = {
			...this.state.form1,
			username: event.target.value
		}
		this.setState({ form1: form1 })
	}

	handleNewPassword = (event) => {
		const form1 = {
			...this.state.form1,
			newpassword: event.target.value
		}
		this.setState({ form1: form1 })
	}

	handleConfirm = (event) => {
		const form1 = {
			...this.state.form1,
			confirm: event.target.value
		}
		this.setState({ form1: form1 })
	}

	handleSettings = (event) => {
		event.preventDefault()
		const password = this.state.form1.password
		const avatar = this.state.form1.avatar
		const lastname = this.state.form1.lastname
		const firstname = this.state.form1.firstname
		const email = this.state.form1.email
		const username = this.state.form1.username
		const newpassword = this.state.form1.newpassword
		const confirm = this.state.form1.confirm
		if (sha1(password) !== this.props.user.password)
			this.setErrorMsg("Mot de passe invalide")
		else if (lastname.length === 0)
			this.setErrorMsg("Veuillez indiquer le nom")
		else if (firstname.length === 0)
			this.setErrorMsg("Veuillez indiquer le prénom")
		else if (email.length === 0)
			this.setErrorMsg("Veuillez indiquer l'adresse e-mail")
		else if (tools.testEmail(email) === false)
			this.setErrorMsg("Adresse e-mail invalide")
		else if (username.length === 0)
			this.setErrorMsg("Veuillez indiquer le nom d'utilisateur")
		else if (username.length < 4)
			this.setErrorMsg("Nom d'utilisateur trop court (4 caractères min.)")
		else if (newpassword.length > 0 && newpassword.length < 8)
			this.setErrorMsg("Mot de passe trop court (8 caractères min.)")
		else if (newpassword.length > 0 && newpassword !== confirm)
			this.setErrorMsg("Le mot de passe et sa confirmation sont différents")
		else
		{
			fetch(connexion.connect.url + 'dashboard/users/update', {
				method: 'POST',
				body: JSON.stringify({
					id: this.props.user._id,
					lastname: lastname,
					firstname: firstname,
					email: email,
					username: username,
					password: newpassword,
					avatar: avatar
				})
			})
			.then(res => res.json())
			.then(ret => {
				if (ret === false)
					this.setErrorMsg("Une erreur s'est produite")
				else if (ret === 1)
					this.setErrorMsg("Un autre utilisateur avec le même identifiant existe déjà")
				else
				{
					const form1 = {
						password: '',
						avatar: ret.avatar,
						lastname: ret.lastname,
						firstname: ret.firstname,
						email: ret.email,
						username: ret.username,
						newpassword: '',
						confirm: ''
					}
					this.setState({ form1: form1 })
					this.props.updateUser(ret, true)
					this.setSuccessMsg("Les paramètres ont bien été mis à jour")
				}
			})
			.catch(err => {
				this.setErrorMsg("Une erreur s'est produite")
				console.log(err)
			})
		}
	}

	render() {
		let prev = null
		if (this.state.form1.avatar)
			prev = (<img src={this.state.form1.avatar} alt="avatar" className="avatar" />)
		return (
			<div className="wrapper-content">
				<p className="error center">{this.state.error}</p>
				<p className="success center">{this.state.success}</p>
				<div className="row">
					<div className="module col s6">
						<h2>Modifier les paramètres</h2>
						<form className="module-form" onSubmit={this.handleSettings}>
							<label>
								<p>Mot de passe :</p>
								<input type="password" value={this.state.form1.password} onChange={this.handlePassword} />
							</label>
							<label>
								<p>Avatar :</p>
								<input type="file" onChange={this.handleAvatar} />
							</label>
							{prev}
							<label>
								<p>Nom :</p>
								<input type="text" value={this.state.form1.lastname} onChange={this.handleLastname} />
							</label>
							<label>
								<p>Prénom :</p>
								<input type="text" value={this.state.form1.firstname} onChange={this.handleFirstname} />
							</label>
							<label>
								<p>E-mail :</p>
								<input type="text" value={this.state.form1.email} onChange={this.handleEmail} />
							</label>
							<label>
								<p>Identifiant :</p>
								<input type="text" value={this.state.form1.username} onChange={this.handleUsername} />
							</label>
							<label>
								<p>Nouveau mot de passe :</p>
								<input type="password" value={this.state.form1.newpassword} onChange={this.handleNewPassword} />
							</label>
							<label>
								<p>Confirmation du mot de passe :</p>
								<input type="password" value={this.state.form1.confirm} onChange={this.handleConfirm} />
							</label>
							<button type="submit" className="waves-effect waves-light btn-small">Mettre à jour</button>
						</form>
					</div>
				</div>
			</div>
		)
	}
}

export default User

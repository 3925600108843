import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import connexion from '../../connexion'
import { parseDate } from '../../tools'
import Loader from '../UI/Loader'
import user_pic from '../../images/user.png'
import '../Core/Main.css'

class WebUsers extends Component {
	constructor(props) {
		super(props)
		this.search = (this.props.location.users !== undefined) ? true : false
		this.state = {
			user: null,
			id: (this.props.match.params.id !== undefined) ? this.props.match.params.id : null,
			users: (this.props.location.users !== undefined) ? this.props.location.users : null,
			form1: {
				title: '',
				msg: ''
			},
			error: null,
			success: null,
			notification: null
		}
		this.props.updateNavbarTitle('Utilisateurs (webapp)')
	}

	componentDidMount() {
		if (this.state.users === null)
			this.getUserList()
		this.getUser(this.state.id)
	}

	getUserList = () => {
		fetch(connexion.connect.url + 'webapp/users/list')
			.then(res => res.json())
			.then(users => {
				this.setState({ users: users })
			})
			.catch(err => console.log(err))
	}

	getUser = (id) => {
		if (id !== null)
		{
			fetch(connexion.connect.url + 'webapp/users/find', {
				method: 'POST',
				body: JSON.stringify({
					id: id
				})
			})
			.then(res => res.json())
			.then(user => {
				if (user !== null)
				{
					fetch(connexion.connect.url + 'dashboard/clients/find', {
						method: 'POST',
						body: JSON.stringify({
							client_number: user.client_number
						})
					})
					.then(res => res.json())
					.then(client => {
						if (client !== false)
							user.client = client
						this.setState({ user: user })
					})
					.catch(err => console.log(err))
					fetch(connexion.connect.url + 'webapp/notifications/find-by-user', {
						method: 'POST',
						body: JSON.stringify({
							user: user._id
						})
					})
					.then(res => res.json())
					.then(notification => {
						if (notification)
							this.setState({ notification: true })
						else
							this.setState({ notification: false })
					})
					.catch(err => {
						console.log("could not find notification", err)
					})
				}
			})
			.catch(err => console.log(err))
		}
	}

	setSuccess = (msg) => {
		this.setState({ success: msg, error: null })
	}

	setError = (msg) => {
		this.setState({ error: msg, success: null })
	}

	resetFlash = () => {
		this.setState({ error: null, success: null })
	}

	handleLink = (id) => {
		this.getUser(id)
		this.resetFlash()
		this.setState({ id: id })
	}

	handleSend = () => {
		const user = this.state.user
		fetch(connexion.connect.url + 'webapp/users/send-email', {
			method: 'POST',
			body: JSON.stringify({
				activate: user.activate,
				email: user.email,
				firstname: user.firstname,
				lastname: user.lastname
			})
		})
		.then(res => res.json())
		.then(ret => {
			if (ret === false)
				this.setError('Impossible d\'envoyer le mail d\'activation')
			else
			{
				const success = "Le mail d'activation à été envoyé à l'adresse " + user.email
				this.setSuccess(success)
			}
		})
		.catch(err => {
			this.setError('Une erreur est survenue pendant l\'envoi du mail')
			console.log(err)
		})
	}

	handleTitle = (event) => {
		const form1 = {
			...this.state.form1,
			title: event.target.value
		}
		this.setState({ form1: form1 })
	}

	handleMsg = (event) => {
		const form1 = {
			...this.state.form1,
			msg: event.target.value
		}
		this.setState({ form1: form1 })
	}

	submitNotification = (event) => {
		event.preventDefault()
		const msg = this.state.form1.msg
		if (msg.length === 0)
			this.setErrorMsg("Veuillez insérer un message")
		else
		{
			fetch(connexion.connect.url + 'webapp/notifications/send-user', {
				method: 'POST',
				body: JSON.stringify({
					title: this.state.form1.title,
					msg: this.state.form1.msg,
					user: this.state.id
				})
			})
			.then(res => res.json())
			.then(ret => {
				const form1 = {
					title: '',
					msg: ''
				}
				this.setState({ form1: form1 })
				this.setSuccessMsg("Une notification à été envoyée à tous les utilisateurs")
			})
			.catch(err => {
				this.setErrorMsg("Une erreur s'est produite")
				console.log(err)
			})
		}
	}

	render() {
		const id = this.state.id
		const user = this.state.user
		const users = this.state.users
		if ((id !== null && user === null) || (id === null && users === null))
		{
			return (
				<div className="loader">
					<Loader />
				</div>
			)
		}
		else if (id !== null)
		{
			let civility = ""
			if (user.civility === "homme")
				civility = "Monsieur"
			else if (user.civility === "femme")
				civility = "Madame"
			else if (user.civility === "other")
				civility = "Autre"
			let birthday = user.birthday
			if (user.birthday === null)
				birthday = "Non renseigné"
			const create = parseDate(user.created_time)
			const active = (user.activate === true) ? 'Oui' : 'Non'
			let sendMail = null
			if (user.activate !== true)
			{
				sendMail = (
					<p>
						<span className="error">Le compte de cet utilisateur n'est pas activé.</span><br />
						<button type="button" className="waves-effect waves-light btn-small" onClick={this.handleSend}>Renvoyer le lien d'activation par e-mail</button>
					</p>
				)
			}
			const stay = user.stay
			let stayJSX = null
			if (stay !== null)
			{
				const arrival = parseDate(stay.arrival_time)
				const departure = parseDate(stay.departure_time)
				const stay_path = '/users/sejours/' + stay.stay_number
				stayJSX = (
					<p>
						<b>N° Séjour :</b> <Link to={stay_path}>{stay.stay_number}</Link><br />
						<b>Date d'arrivée :</b> {arrival}<br />
						<b>Date de départ :</b> {departure}<br />
						<b>Emplacement :</b> {stay.location}
					</p>
				)
			}
			else
			{
				stayJSX = (
					<p className="center">Aucun séjour ne correspond à cet utilisateur.</p>
				)
			}
			const client = user.client
			const civility_client = (client.civility === null) ? (<i>Non renseigné</i>) : client.civility
			const birthday_client = (client.birthday === null) ? (<i>Non renseigné</i>) : client.birthday
			const email1 = (client.email1 === null) ? (<i>Non renseigné</i>) : client.email1
			const email2 = (client.email2 === null) ? null : (<span><b>E-mail 2 :</b> {client.email2}<br /></span>)
			const phone1 = (client.phone1 === null) ? (<i>Non renseigné</i>) : client.phone1
			const phone2 = (client.phone2 === null) ? null : (<span><b>Téléphone 2 :</b> {client.phone2}<br /></span>)
			const phone3 = (client.phone3 === null) ? null : (<span><b>Téléphone 3 :</b> {client.phone3}<br /></span>)
			const client_path = '/users/clients/' + client.client_number
			let clientJSX = (
				<p>
					<b>N° Client :</b> <Link to={client_path}>{client.client_number}</Link><br />
					<b>Nom :</b> {client.lastname}<br />
					<b>Prénom :</b> {client.firstname}<br />
					<b>Civilité :</b> {civility_client}<br />
					<b>Date de naissance :</b> {birthday_client}<br />
					<b>Langue :</b> {client.langage}<br />
					<b>E-mail 1 :</b> {email1}<br />
					{email2}
					<b>Téléphone 1 :</b> {phone1}<br />
					{phone2}
					{phone3}
					<b>Code d'activation :</b> {client.activation_code}
				</p>
			)
			let sendNotif = null
			if (this.state.notification === true)
			{
				sendNotif = (
					<div className="module col s4 offset-s4">
						<h2 className="title-description">Envoyer une notification à l'utilisateur</h2>
						<form onSubmit={this.submitNotification}>
							<label>
								Titre :
								<input type="text" value={this.state.form1.title} onChange={this.handleTitle} />
							</label>
							<label>
								Message :
								<input type="text" value={this.state.form1.msg} onChange={this.handleMsg} />
							</label>
							<button type="submit" className="waves-effect waves-light btn-small">Envoyer une notification</button>
						</form>
					</div>
				)
			}
			return (
				<div className="wrapper-content">
					<p className="error center">{this.state.error}</p>
					<p className="success center">{this.state.success}</p>
					<p><button type="button" className="waves-effect waves-light btn-small" onClick={this.handleLink.bind(this, null)}>Retour à la liste</button></p>
					<div className="row">
						<div className="module col s4 quickview">
							<p className="text">
								Identité<br />
								<strong>
									{user.firstname}<br />
									{user.lastname}
								</strong>
							</p>
							<p className="icon"><img src={user_pic} alt="Identité" /></p>
						</div>
						{sendNotif}
					</div>
					<div className="row">
						<div className="module col s4">
							<h2 className="title-description">Détails</h2>
							<p>
								<b>Nom :</b> {user.lastname}<br />
								<b>Prénom :</b> {user.firstname}<br />
								<b>Civilité :</b> {civility}<br />
								<b>Date de naissance :</b> {birthday}<br />
								<b>Adresse e-mail :</b> {user.email}<br />
								<b>Date d'inscription :</b> {create}<br />
								<b>ID :</b> {user._id}<br />
								<b>Compte activé :</b> {active}
							</p>
							{sendMail}
						</div>
						<div className="module col s4">
							<h2 className="title-description">Fiche client</h2>
							{clientJSX}
						</div>
						<div className="module col s4">
							<h2 className="title-description">Séjour</h2>
							{stayJSX}
						</div>
					</div>
				</div>
			)
		}
		else
		{
			let key = -1
			const search = (this.search === true) ? 'Résultats de votre recherche' : 'Liste de tous les utilisateurs'
			return (
				<div className="wrapper-content">
					<div className="row">
						<div className="module col s12">
							<h2 className="title-description">{search}</h2>
							<p>Nombre de lignes : {users.length}</p>
							<table>
								<thead>
									<tr>
										<td>Détails</td>
										<td>N° Client</td>
										<td>N° Séjour</td>
										<td>Civilité</td>
										<td>Nom</td>
										<td>Prénom</td>
										<td>E-mail</td>
										<td>Date de naissance</td>
										<td>Compte activé</td>
										<td>Création</td>
										<td>Édition</td>
									</tr>
								</thead>
								<tbody>
									{users.map(({ _id, activate, birthday, civility, client_number, email, firstname, lastname, stay, created_time, edited_time }) => {
										key++
										const create = parseDate(created_time)
										const edited = parseDate(edited_time)
										const activation = (activate === true) ? 'Oui' : 'Non'
										const color = (activate === true) ? { backgroundColor: '#42CC42' } : { backgroundColor: '#CC4242' }
										const client_path = '/users/clients/' + client_number
										let stayJSX = null
										if (stay !== null)
										{
											const stay_path = '/users/sejours/' + stay.stay_number
											stayJSX = (<Link to={stay_path}>{stay.stay_number}</Link>)
										}
										return (
											<tr key={key}>
												<td><button type="button" className="waves-effect waves-light btn-small" onClick={this.handleLink.bind(this, _id)}>Détails</button></td>
												<td><Link to={client_path}>{ client_number }</Link></td>
												<td>{stayJSX}</td>
												<td>{ civility }</td>
												<td>{ lastname }</td>
												<td>{ firstname }</td>
												<td>{ email }</td>
												<td>{ birthday }</td>
												<td style={color}>{ activation }</td>
												<td>{ create }</td>
												<td>{ edited }</td>
											</tr>
										)
									})}
								</tbody>
							</table>
							</div>
					</div>
				</div>
			)
		}
	}
}

export default WebUsers

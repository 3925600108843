import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import Loader from '../UI/Loader'
import tools from '../../tools'
import M from 'materialize-css'
import '../Core/Main.css'
import './Animations.css'

class Dates extends Component {
	constructor(props) {
		super(props)
		this.state = {
			dates: null,
			calendar: null,
			month: null,
			month_txt: null,
			year: null,
			start: null,
			end: null,
			edit: false,
			form1: {
				animation: '',
				start_day: '',
				start_month: '',
				end_day: '',
				end_month: '',
				start_hour: '',
				start_minute: '',
				end_hour: '',
				end_minute: '',
				monday: false,
				tuesday: false,
				wednesday: false,
				thursday: false,
				friday: false,
				saturday: false,
				sunday: false
			}
		}
		this.props.updateNavbarTitle('Animations')
	}

	componentDidMount() {
		this.props.fetchAnimationCategories()
		this.loadAnimations()
	}

	componentDidUpdate(prevProps, prevState) {
		const elems = document.querySelectorAll('select')
		M.FormSelect.init(elems)
		if (prevProps.animations !== this.props.animations || prevProps.categories !== this.props.categories)
			this.loadAnimations(true)
		if (prevState.animations !== this.state.animations)
			this.loadAnimationDates()
		if (prevProps.dates !== this.props.dates)
			this.loadAnimationDates(true)
		if (prevState.dates !== this.state.dates)
		{
			const month = (this.state.month || this.state.month === 0) ? this.state.month : null
			const year = (this.state.year) ? this.state.year : null
			this._constructCalendar(month, year)	
		}
	}

	loadAnimations = (fromProps = false) => {
		if (fromProps === true && this.props.animations && this.props.categories)
		{
			const categories = this.props.categories
			let animations = tools.clone(this.props.animations)
			for (let i = 0; i < animations.length; i++)
			{
				for (let j = 0; j < categories.length; j++)
				{
					if (animations[i].category === categories[j]._id)
					{
						animations[i].category_obj = categories[j]
						break
					}
				}
			}
			this.setState({ animations: animations })
		}
		else if (this.props.categories)
		{
			const categories = this.props.categories
			this.props.fetchAnimations(true)
			.then(ret => {
				let animations = tools.clone(ret)
				for (let i = 0; i < animations.length; i++)
				{
					for (let j = 0; j < categories.length; j++)
					{
						if (animations[i].category === categories[j]._id)
						{
							animations[i].category_obj = categories[j]
							break
						}
					}
				}
				this.setState({ animations: animations })
			})
			.catch(err => console.log(err))
		}
	}

	loadAnimationDates = (fromProps = false) => {
		if (fromProps === true)
		{
			const animations = this.state.animations
			let dates = tools.clone(this.props.dates)
			for (let i = 0; i < dates.length; i++)
			{
				for (let j = 0; j < animations.length; j++)
				{
					if (dates[i].animation === animations[j]._id)
					{
						dates[i].animation_obj = animations[j]
						break
					}
				}
			}
			this.setState({ dates: dates })
		}
		else if (this.state.animations)
		{
			const animations = this.state.animations
			this.props.fetchAnimationDates()
			.then(ret => {
				let dates = tools.clone(ret)
				for (let i = 0; i < dates.length; i++)
				{
					for (let j = 0; j < animations.length; j++)
					{
						if (dates[i].animation === animations[j]._id)
						{
							dates[i].animation_obj = animations[j]
							break
						}
					}
				}
				this.setState({ dates: dates })
			})
			.catch(err => console.log(err))
		}
	}

	_constructCalendar(month, year) {
		if (this.state.dates)
		{
			const dates = this.state.dates
			let date = null
			const month_list = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"]
			if (month === null && year === null)
			{
				date = new Date()
				month = date.getMonth()
				year = date.getFullYear()
			}
			date = new Date(year, month, 1)
			let firstday = date.getDay()
			firstday = (firstday === 0) ? 6 : firstday - 1
			let nbDays = 0
			if ([0, 2, 4, 6, 7, 9, 11].indexOf(month) !== -1)
				nbDays = 31
			else if ([3, 5, 8, 10].indexOf(month) !== -1)
				nbDays = 30
			else if (month === 1 && year % 4 === 0)
				nbDays = 29
			else if (month === 1)
				nbDays = 28
			const start_month = Math.floor(date.getTime() / 1000)
			date = new Date(year, month, nbDays)
			const end_month = Math.floor(date.getTime() / 1000)
			let c = 0
			let i = 0
			let calendar = [[]]
			while (c < firstday)
			{
				calendar[0][c] = null
				c++
			}
			let row = 0
			while (i < nbDays)
			{
				calendar[row][c] = {
					day: c,
					nb: i + 1,
					date: new Date(year, month, i + 1, 0, 0, 0)
				}
				if (c === 6)
				{
					c = 0
					row++
					calendar[row] = []
				}
				else
					c++
				i++
			}
			while (c < 7)
			{
				calendar[row][c] = null
				c++
			}
			for (let i = 0; i < calendar.length; i++)
			{
				for (let j = 0; j < calendar[i].length; j++)
				{
					if (calendar[i][j] === null)
						continue
					calendar[i][j].dates = []
					const start = Math.floor(calendar[i][j].date.getTime() / 1000)
					const end = start + 86400
					for (let k = 0; k < dates.length; k++)
						if (dates[k].start >= start && dates[k].start < end)
							calendar[i][j].dates.push(dates[k])
				}
			}
			const month_txt = month_list[month]
			this.setState({ calendar: calendar, month: month, month_txt: month_txt, year: year, start: start_month, end: end_month })
		}
	}

	prevMonth = () => {
		let month = this.state.month - 1
		let year = this.state.year
		if (month < 0)
		{
			month = 11
			year--
		}
		this.clearFlash()
		this._constructCalendar(month, year)
	}

	nextMonth = () => {
		let month = this.state.month + 1
		let year = this.state.year
		if (month > 11)
		{
			month = 0
			year++
		}
		this.clearFlash()
		this._constructCalendar(month, year)
	}

	setErrorMsg = (msg) => {
		this.setState({ error: msg, success: null })
	}

	setSuccessMsg = (msg) => {
		this.setState({ error: null, success: msg })
	}

	clearFlash = () => {
		this.setState({ error: null, success: null })
	}

	handleAnimation = (event) => {
		const form1 = {
			...this.state.form1,
			animation: event.target.value
		}
		this.setState({ form1: form1 })
	}

	_parseDates = (form1, start = true) => {
		if (start === true)
		{
			if (Number(form1.end_month) < Number(form1.start_month))
				form1.end_month = form1.start_month
			if (Number(form1.end_day) < Number(form1.start_day) && Number(form1.end_month) <= Number(form1.start_month))
				form1.end_day = form1.start_day
		}
		else if (start !== true && form1.start_day === '' && form1.start_month === '')
		{
			if (Number(form1.start_month) > Number(form1.end_month))
				form1.start_month = form1.end_month
			if (Number(form1.start_day) > Number(form1.end_day) && Number(form1.start_month) >= Number(form1.end_month))
				form1.start_day = form1.end_day
		}
		form1.start_day = Number(form1.start_day)
		form1.start_month = Number(form1.start_month)
		form1.end_day = Number(form1.end_day)
		form1.end_month = Number(form1.end_month)
		if (form1.start_day > 31)
			form1.start_day = 31
		else if (form1.start_day < 1)
			form1.start_day = ''
		if (form1.start_month > 12)
			form1.start_month = 12
		else if (form1.start_month < 1)
			form1.start_month = ''
		if (form1.end_day > 31)
			form1.end_day = 31
		else if (form1.end_day < 1)
			form1.end_day = ''
		if (form1.end_month > 12)
			form1.end_month = 12
		else if (form1.end_month < 1)
			form1.end_month = ''
		if (form1.start_day < 10)
			form1.start_day = '0' + form1.start_day
		if (form1.start_month < 10)
			form1.start_month = '0' + form1.start_month
		if (form1.end_day < 10)
			form1.end_day = '0' + form1.end_day
		if (form1.end_month < 10)
			form1.end_month = '0' + form1.end_month
		form1.start_day = String(form1.start_day)
		form1.start_month = String(form1.start_month)
		form1.end_day = String(form1.end_day)
		form1.end_month = String(form1.end_month)
		return form1
	}

	handleStartDay = (event) => {
		let form1 = {
			...this.state.form1,
			start_day: event.target.value
		}
		form1 = this._parseDates(form1)
		this.setState({ form1: form1 })
	}

	handleStartMonth = (event) => {
		let form1 = {
			...this.state.form1,
			start_month: event.target.value
		}
		form1 = this._parseDates(form1)
		this.setState({ form1: form1 })
	}

	handleEndDay = (event) => {
		let form1 = {
			...this.state.form1,
			end_day: event.target.value
		}
		form1 = this._parseDates(form1, false)
		this.setState({ form1: form1 })
	}

	handleEndMonth = (event) => {
		let form1 = {
			...this.state.form1,
			end_month: event.target.value
		}
		form1 = this._parseDates(form1, false)
		this.setState({ form1: form1 })
	}

	focusStartDay = () => {
		const form1 = {
			...this.state.form1,
			start_day: ''
		}
		this.setState({ form1: form1 })
	}

	focusStartMonth = () => {
		const form1 = {
			...this.state.form1,
			start_month: ''
		}
		this.setState({ form1: form1 })
	}

	focusEndDay = () => {
		const form1 = {
			...this.state.form1,
			end_day: ''
		}
		this.setState({ form1: form1 })
	}

	focusEndMonth = () => {
		const form1 = {
			...this.state.form1,
			end_month: ''
		}
		this.setState({ form1: form1 })
	}

	_parseHours = (form1, start = true) => {
		if (start === true && (Number(form1.end_hour) < Number(form1.start_hour) ||
			(Number(form1.end_hour) === Number(form1.start_hour) && Number(form1.end_minute) <= Number(form1.start_minute))))
		{
			form1.end_hour = Number(form1.start_hour) + 1
			form1.end_minute = form1.start_minute
			if (form1.end_hour > 23)
			{
				form1.end_hour = 23
				form1.end_minute = Number(form1.end_minute) + 1
				if (Number(form1.end_minute) > 59)
					form1.end_minute = 59
			}
		}
		else if (start !== true && form1.start_hour === '' && form1.start_minute === '' (Number(form1.end_hour) < Number(form1.start_hour) ||
			(Number(form1.end_hour) === Number(form1.start_hour) && Number(form1.end_minute) <= Number(form1.start_minute))))
		{
			form1.start_hour = Number(form1.end_hour) - 1
			form1.start_minute = form1.end_minute
			if (form1.start_hour < 0)
			{
				form1.start_hour = 0
				form1.start_minute = Number(form1.start_minute) - 1
				if (Number(form1.start_minute) < 0)
					form1.start_minute = 0
			}
		}
		form1.start_hour = Number(form1.start_hour)
		form1.start_minute = Number(form1.start_minute)
		form1.end_hour = Number(form1.end_hour)
		form1.end_minute = Number(form1.end_minute)
		if (form1.start_hour > 59)
			form1.start_hour = 59
		if (form1.start_minute > 59)
			form1.start_minute = 59
		if (form1.end_hour > 59)
			form1.end_hour = 59
		if (form1.end_minute > 59)
			form1.end_minute = 59
		if (form1.start_hour === 0)
			form1.start_hour = '00'
		else if (form1.start_hour < 10)
			form1.start_hour = '0' + form1.start_hour
		if (form1.start_minute === 0)
			form1.start_minute = '00'
		else if (form1.start_minute < 10)
			form1.start_minute = '0' + form1.start_minute
		if (form1.end_hour === 0)
			form1.end_hour = '00'
		else if (form1.end_hour < 10)
			form1.end_hour = '0' + form1.end_hour
		if (form1.end_minute === 0)
			form1.end_minute = '00'
		else if (form1.end_minute < 10)
			form1.end_minute = '0' + form1.end_minute
		form1.start_hour = String(form1.start_hour)
		form1.start_minute = String(form1.start_minute)
		form1.end_hour = String(form1.end_hour)
		form1.end_minute = String(form1.end_minute)
		return form1
	}

	handleStartHour = (event) => {
		let form1 = {
			...this.state.form1,
			start_hour: event.target.value
		}
		form1 = this._parseHours(form1)
		this.setState({ form1: form1 })
	}

	handleStartMinute = (event) => {
		let form1 = {
			...this.state.form1,
			start_minute: event.target.value
		}
		form1 = this._parseHours(form1)
		this.setState({ form1: form1 })
	}

	handleEndHour = (event) => {
		let form1 = {
			...this.state.form1,
			end_hour: event.target.value
		}
		form1 = this._parseHours(form1, false)
		this.setState({ form1: form1 })
	}

	handleEndMinute = (event) => {
		let form1 = {
			...this.state.form1,
			end_minute: event.target.value
		}
		form1 = this._parseHours(form1, false)
		this.setState({ form1: form1 })
	}

	focusStartHour = () => {
		const form1 = {
			...this.state.form1,
			start_hour: ''
		}
		this.setState({ form1: form1 })
	}

	focusStartMinute = () => {
		const form1 = {
			...this.state.form1,
			start_minute: ''
		}
		this.setState({ form1: form1 })
	}

	focusEndHour = () => {
		const form1 = {
			...this.state.form1,
			end_hour: ''
		}
		this.setState({ form1: form1 })
	}

	focusEndMinute = () => {
		const form1 = {
			...this.state.form1,
			end_minute: ''
		}
		this.setState({ form1: form1 })
	}

	handleWeekday = (day) => {
		const form1 = {
			...this.state.form1
		}
		form1[day] = (this.state.form1[day] === true) ? false : true
		this.setState({ form1: form1 })
	}

	clickCreate = () => {
		this.clearFlash()
		this.setState({ edit: true })
	}

	deleteAnimation = (id) => {
		this.props.deleteAnimationDate(id)
		.then(ret => {
			if (ret === true)
				this.setSuccessMsg("La date à été supprimée")
			else
				this.setErrorMsg("Une erreur s'est produite")
		})
		.catch(err => {
			this.setErrorMsg("Une erreur s'est produite")
			console.log(err)
		})
	}

	submitSchedule = (event) => {
		event.preventDefault()
		const animation = this.state.form1.animation
		const start_day = Number(this.state.form1.start_day)
		const start_month = Number(this.state.form1.start_month)
		let end_day = Number(this.state.form1.end_day)
		let end_month = Number(this.state.form1.end_month)
		const start_hour = Number(this.state.form1.start_hour)
		const start_minute = Number(this.state.form1.start_minute)
		const end_hour = Number(this.state.form1.end_hour)
		const end_minute = Number(this.state.form1.end_minute)
		const monday = this.state.form1.monday
		const tuesday = this.state.form1.tuesday
		const wednesday = this.state.form1.wednesday
		const thursday = this.state.form1.thursday
		const friday = this.state.form1.friday
		const saturday = this.state.form1.saturday
		const sunday = this.state.form1.sunday
		if (animation.length === 0)
			this.setErrorMsg("Veuillez sélectionner une animation")
		else if (typeof(start_day) === "string" || typeof(start_month) === "string" || typeof(end_day) === "string" || typeof(end_month) === "string")
			this.setErrorMsg("Veuillez insérer les dates")
		else if (start_month > end_month || (start_day > end_day && start_month === end_month))
			this.setErrorMsg("Erreur dans les dates")
		else if (typeof(start_hour) === "string" || typeof(start_minute) === "string" || typeof(end_hour) === "string" || typeof(end_minute) === "string")
			this.setErrorMsg("Veuillez insérer les heures")
		else if (start_hour > end_hour || (start_minute >= end_minute && start_hour === end_hour))
			this.setErrorMsg("Erreur dans les heures")
		else if (monday === false && tuesday === false && wednesday === false && thursday === false && friday === false && saturday === false && sunday === false)
			this.setErrorMsg("Veuillez sélectionner au moins un jour")
		else
		{
			const year = new Date().getFullYear()
			let end = new Date(year, end_month - 1, end_day)
			end_month = end.getMonth()
			end_day = end.getDate()
			const init = new Date(year, start_month - 1, start_day)
			let dates = []
			let day = init.getDate()
			let month = init.getMonth()
			let i = 0
			let j = 0
			let error = false
			while (true)
			{
				const start = new Date(year, month, day, start_hour, start_minute, 0, 0)
				end = new Date(year, month, day, end_hour, end_minute, 0, 0)
				const weekday = start.getDay()
				if ((weekday === 0 && sunday === true) ||
					(weekday === 1 && monday === true) ||
					(weekday === 2 && tuesday === true) ||
					(weekday === 3 && wednesday === true) ||
					(weekday === 4 && thursday === true) ||
					(weekday === 5 && friday === true) ||
					(weekday === 6 && saturday === true))
				{
					dates[j] = {
						start: start.getTime() / 1000,
						end: end.getTime() / 1000
					}
					j++
				}
				if (month === end_month && day === end_day)
					break
				day++
				const verif = new Date(year, month, day)
				month = verif.getMonth()
				day = verif.getDate()
				i++
				if (i > 365)
				{
					error = true
					this.setErrorMsg("Erreur dans la création des dates")
					break
				}
			}
			if (dates.length === 0)
				this.setErrorMsg("Les jours cochés ne correspondent pas avec les dates sélectionnées !")
			else if (error === false)
			{
				const obj = {
					animation: animation,
					dates: dates
				}
				this.props.insertAnimationDates(obj)
				.then(ret => {
					if (ret === false)
						this.setErrorMsg("Une erreur s'est produite")
					else
					{
						const form1 = {
							animation: '',
							start_day: '',
							start_month: '',
							end_day: '',
							end_month: '',
							start_hour: '',
							start_minute: '',
							end_hour: '',
							end_minute: '',
							monday: true,
							tuesday: true,
							wednesday: true,
							thursday: true,
							friday: true,
							saturday: true,
							sunday: true
						}
						this.setSuccessMsg("Les dates ont bien été ajoutées")
						this.setState({ form1: form1, edit: false })
					}
				})
				.catch(err => {
					this.setErrorMsg("Une erreur s'est produite")
					console.log(err)
				})
			}
		}
	}

	unEdit = () => {
		const form1 = {
			animation: '',
			start_day: '',
			start_month: '',
			end_day: '',
			end_month: '',
			start_hour: '',
			start_minute: '',
			end_hour: '',
			end_minute: '',
			monday: true,
			tuesday: true,
			wednesday: true,
			thursday: true,
			friday: true,
			saturday: true,
			sunday: true
		}
		this.setState({ form1: form1, edit: false })
	}

	render() {
		const calendar = this.state.calendar
		const dates = this.state.dates
		const permissions = this.props.user.permissions
		if (!permissions || !permissions.activities || permissions.activities === 0)
			return (<Redirect to="/" />)
		else if (calendar === null || dates === null)
		{
			return (
				<div className="loader">
					<Loader />
				</div>
			)
		}
		else if (this.state.edit === true)
		{
			const animations = this.state.animations
			let animation_k = 0
			return (
				<div className="wrapper-content">
					<div className="row">
						<div className="module col s12">
							<h2><span className="uhover" onClick={this.unEdit}>Animations</span> > Ajouter des dates</h2>
							<p className="error center">{this.state.error}</p>
							<p className="success center">{this.state.success}</p>
							<form className="module-form" onSubmit={this.submitSchedule}>
								<p className="title-group">Choisissez une animation</p>
								<div className="form-group">
									<label>
										<p className="column-10">Animation :</p>
										<div className="column-30">
											<select value={this.state.form1.animation} onChange={this.handleAnimation}>
												<option value="" key={animation_k}>Sélectionner une animation</option>
												{animations.map(({ _id, translations }) => {
													let name = ""
													for (let i = 0; i < translations.length; i++)
													{
														if (translations[i].lang === this.props.lang_default)
														{
															name = translations[i].name
															break
														}
													}
													animation_k++
													return (<option key={animation_k} value={_id}>{name}</option>)
												})}
											</select>
										</div>
									</label>
									<div className="clear"></div>
								</div>
								<p className="title-group">Choisissez les horaires</p>
								<div className="form-group">
									<label>
										<p className="column-10">Du :</p>
										<div className="column-10">
											<input type="text" min="1" max="31" value={this.state.form1.start_day} onChange={this.handleStartDay} onFocus={this.focusStartDay} />
										</div>
										<div className="sep"><p>/</p></div>
										<div className="column-10 right60">
											<input type="text" min="1" max="12" value={this.state.form1.start_month} onChange={this.handleStartMonth} onFocus={this.focusStartMonth} />
										</div>
										<p className="column-10">au</p>
										<div className="column-10">
											<input type="text" min="1" max="31" value={this.state.form1.end_day} onChange={this.handleEndDay} onFocus={this.focusEndDay} />
										</div>
										<div className="sep"><p>/</p></div>
										<div className="column-10">
											<input type="text" min="1" max="12" value={this.state.form1.end_month} onChange={this.handleEndMonth} onFocus={this.focusEndMonth} />
										</div>
									</label>
									<label>
										<p className="column-10">De :</p>
										<div className="column-10">
											<input type="text" min="0" max="59" value={this.state.form1.start_hour} onChange={this.handleStartHour} onFocus={this.focusStartHour} />
										</div>
										<div className="sep"><p>:</p></div>
										<div className="column-10 right60">
											<input type="text" min="0" max="59" value={this.state.form1.start_minute} onChange={this.handleStartMinute} onFocus={this.focusStartMinute} />
										</div>
										<p className="column-10">à</p>
										<div className="column-10">
											<input type="text" min="0" max="59" value={this.state.form1.end_hour} onChange={this.handleEndHour} onFocus={this.focusEndHour} />
										</div>
										<div className="sep"><p>:</p></div>
										<div className="column-10">
											<input type="text" min="0" max="59" value={this.state.form1.end_minute} onChange={this.handleEndMinute} onFocus={this.focusEndMinute} />
										</div>
									</label>
									<div className="clear"></div>
								</div>
								<p className="title-group">Cochez les jours de récurrence</p>
								<div className="form-group">
									<div className="column-10">
										<label>
											<span className="weekdays">L</span>
											<div className="clear"></div>
											<input type="checkbox" checked={this.state.form1.monday} onChange={this.handleWeekday.bind(this, 'monday')} /><span></span>
										</label>
									</div>
									<div className="column-10">
										<label>
											<span className="weekdays">M</span>
											<div className="clear"></div>
											<input type="checkbox" checked={this.state.form1.tuesday} onChange={this.handleWeekday.bind(this, 'tuesday')} /><span></span>
										</label>
									</div>
									<div className="column-10">
										<label>
											<span className="weekdays">M</span>
											<div className="clear"></div>
											<input type="checkbox" checked={this.state.form1.wednesday} onChange={this.handleWeekday.bind(this, 'wednesday')} /><span></span>
										</label>
									</div>
									<div className="column-10">
										<label>
											<span className="weekdays">J</span>
											<div className="clear"></div>
											<input type="checkbox" checked={this.state.form1.thursday} onChange={this.handleWeekday.bind(this, 'thursday')} /><span></span>
										</label>
									</div>
									<div className="column-10">
										<label>
											<span className="weekdays">V</span>
											<div className="clear"></div>
											<input type="checkbox" checked={this.state.form1.friday} onChange={this.handleWeekday.bind(this, 'friday')} /><span></span>
										</label>
									</div>
									<div className="column-10">
										<label>
											<span className="weekdays">S</span>
											<div className="clear"></div>
											<input type="checkbox" checked={this.state.form1.saturday} onChange={this.handleWeekday.bind(this, 'saturday')} /><span></span>
										</label>
									</div>
									<div className="column-10">
										<label>
											<span className="weekdays">D</span>
											<div className="clear"></div>
											<input type="checkbox" checked={this.state.form1.sunday} onChange={this.handleWeekday.bind(this, 'sunday')} /><span></span>
										</label>
									</div>
									<div className="clear"></div>
								</div>
								<button type="submit" className="waves-effect waves-light btn-small">Ajouter des dates</button>
							</form>
						</div>
					</div>
				</div>
			)
		}
		else
		{
			let row_k = -1
			const month = this.state.month_txt
			const year = this.state.year
			let day_k = -1
			let animation_k = -1
			return (
				<div className="wrapper-content">
					<div className="row">
						<div className="module col s12">
							<button type="button" className="waves-effect waves-light btn-large" onClick={this.clickCreate}>Ajouter</button>
							<p className="error center">{this.state.error}</p>
							<p className="success center">{this.state.success}</p>
							<p className="title-date"><span className="prev" onClick={this.prevMonth}>&lt;</span>{month} {year}<span className="next" onClick={this.nextMonth}>&gt;</span></p>
							<table className="calendar">
								<thead>
									<tr>
										<td>Lun.</td>
										<td>Mar.</td>
										<td>Mer.</td>
										<td>Jeu.</td>
										<td>Ven.</td>
										<td>Sam.</td>
										<td>Dim.</td>
									</tr>
								</thead>
								<tbody>
									{calendar.map(row => {
										row_k++
										return (
											<tr key={row_k}>
												{row.map(obj => {
													day_k++
													if (obj === null)
														return (<td key={day_k}></td>)
													else
													{
														return (
															<td key={day_k}>
																<span className="date">{obj.nb}</span>
																{obj.dates.map(({_id, animation, animation_obj, start, end}) => {
																	if (animation_obj)
																	{
																		animation_k++
																		const start_date = new Date(start * 1000)
																		const end_date = new Date(end * 1000)
																		let color = ""
																		let name = ""
																		let category_name = ""
																		if (animation_obj && animation_obj.category_obj)
																		{
																			color = animation_obj.category_obj.color
																			for (let i = 0; i < animation_obj.translations.length; i++)
																			{
																				if (animation_obj.translations[i].lang === this.props.lang_default)
																				{
																					name = animation_obj.translations[i].name
																					break
																				}
																			}
																			for (let i = 0; i < animation_obj.category_obj.translations.length; i++)
																			{
																				if (animation_obj.category_obj.translations[i].lang === this.props.lang_default)
																				{
																					category_name = animation_obj.category_obj.translations[i].name
																					break
																				}
																			}
																		}
																		// const color = (animation_obj && animation.category_obj && animation.category_obj.color) ? animation.category_obj.color : '888888'
																		// const name = (animation_obj && animation.category_obj && animation.category_obj.name) ? animation.category_obj.name : ''
																		let hour_start = start_date.getHours()
																		let min_start = start_date.getMinutes()
																		if (min_start === 0)
																			min_start = undefined
																		else if (min_start < 10)
																			min_start = '0' + min_start
																		let hour_end = end_date.getHours()
																		let min_end = end_date.getMinutes()
																		if (min_end === 0)
																			min_end = undefined
																		else if (min_end < 10)
																			min_end = '0' + min_end
																		return (
																			<p
																				className="animation"
																				style={{ backgroundColor: '#' + color }}
																				key={animation_k}
																				title={category_name}
																			>
																				{name}<br />
																				{hour_start}h{min_start} - {hour_end}h{min_end}<br />
																				<span className="delete" onClick={this.deleteAnimation.bind(this, _id)}>Supprimer</span>
																			</p>
																		)
																	}
																	else
																		return undefined
																})}
															</td>
														)
													}
												})}
											</tr>
										)
									})}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			)
		}
	}
}

export default Dates

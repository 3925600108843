import React, { Component } from 'react'
import fr_flag from '../../images/flags/france.png'
import en_flag from '../../images/flags/united-kingdom.png'
import de_flag from '../../images/flags/germany.png'
import nl_flag from '../../images/flags/netherlands.png'
import it_flag from '../../images/flags/italy.png'
import es_flag from '../../images/flags/spain.png'
import pt_flag from '../../images/flags/portugal.png'

class LangSelector extends Component {
	constructor(props) {
		super(props)
		this.state = {
			langs: {
				fr: (this.props.lang === "fr") ? "selected" : null,
				en: (this.props.lang === "en") ? "selected" : null,
				de: (this.props.lang === "de") ? "selected" : null,
				nl: (this.props.lang === "nl") ? "selected" : null,
				it: (this.props.lang === "it") ? "selected" : null,
				es: (this.props.lang === "es") ? "selected" : null,
				pt: (this.props.lang === "pt") ? "selected" : null
			}
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.lang !== this.props.lang)
			this.selectLang(this.props.lang)
	}

	selectLang = (lang) => {
		const langs = {
			french: null,
			english: null,
			german: null,
			dutch: null,
			italian: null,
			spanish: null,
			portuguese: null
		}
		langs[lang] = "selected"
		this.setState({ langs: langs })
		this.props.updateLang(lang)
	}

	render() {
		return (
			<div>
				<ul className="translation-flags">
					<li className={this.state.langs.fr} onClick={this.selectLang.bind(this, 'fr')}><img src={fr_flag} alt="français" title="français" /></li>
					<li className={this.state.langs.en} onClick={this.selectLang.bind(this, 'en')}><img src={en_flag} alt="anglais" title="anglais" /></li>
					<li className={this.state.langs.de} onClick={this.selectLang.bind(this, 'de')}><img src={de_flag} alt="allemand" title="allemand" /></li>
					<li className={this.state.langs.nl} onClick={this.selectLang.bind(this, 'nl')}><img src={nl_flag} alt="hollandais" title="hollandais" /></li>
					<li className={this.state.langs.it} onClick={this.selectLang.bind(this, 'it')}><img src={it_flag} alt="italien" title="italien" /></li>
					<li className={this.state.langs.es} onClick={this.selectLang.bind(this, 'es')}><img src={es_flag} alt="espagnol" title="espagnol" /></li>
					<li className={this.state.langs.pt} onClick={this.selectLang.bind(this, 'pt')}><img src={pt_flag} alt="portugais" title="portugais" /></li>
				</ul>
				<div className="clear"></div>
			</div>
		)
	}
}

export default LangSelector

import React, { Component } from 'react'
import M from 'materialize-css'
import RenderPage from './RenderPage'
import CKEditor from '../UI/CKEditor'
import IconPicker from '../UI/IconPicker'
import LangSelector from '../UI/LangSelector'
import Modal from '../UI/Modal'
import Loader from '../UI/Loader'
import tools from '../../tools'
import '../Core/Main.css'

class Pages extends Component {
	constructor(props) {
		super(props)
		this.state = {
			page: null,
			edit: false,
			section: null,
			update_cover: false,
			answerModal: null,
			open_modal: false,
			modal_msg: null,
			delete: false,
			duplicate: false,
			attempt_submit: false,
			form1: { //Image de couverture
				cover: null
			},
			form2: { //Section : icon, titre, contenu
				icon: null,
				prev: null,
				phone: '',
				email: '',
				website: '',
				localisation: '',
				facebook: '',
				instagram: '',
				translations: []
			},
			form3: { //services
				add_service: false,
				service: '',
				link: '',
				translations: []
			},
			navigation: [
				{ name: 'Rubriques', href: '/Rubriques', current: false },
				{ name: '...', href: '#', current: true },
			],
			error: this._initErrors(),
			success: this._initSuccess()
		}
		this.section_length_max = 60
		this.length_max = 40
	}

	componentDidMount() {
		this.loadPage()
		if (this.props.features === "premium")
			this.props.fetchServices()
		this.setState({ navigation: this.props.navigation })
	}

	componentDidUpdate(prevProps) {
		const elems = document.querySelectorAll('select')
		M.FormSelect.init(elems)
		if (prevProps.pages !== this.props.pages)
			this.loadPage(true)
	}

	_initErrors = () => {
		const errors = {
			global: null,
			section: null,
			section_title: null,
			section_content: null,
			section_icon: null,
			section_phone: null,
			section_email: null,
			section_website: null,
			section_localisation: null,
			section_facebook: null,
			section_instagram: null,
			service: null,
			service_title: null,
			service_select: null
		}
		return errors
	}

	_initSuccess = () => {
		const success = {
			global: null,
			section: null,
			service: null
		}
		return success
	}

	setErrorMsg = (key, msg, id = null) => {
		if (key === "error_create_section")
		{
			key = "section"
			msg = tools.default_error_msg("Impossible de créer une section", msg)
		}
		else if (key === "error_update_section")
		{
			key = "section"
			msg = tools.default_error_msg("Impossible de modifier une section", msg)
		}
		else if (key === "error_delete_section")
		{
			key = "global"
			msg = tools.default_error_msg("Impossible de supprimer une section", msg)
		}
		else if (key === "error_update_service")
		{
			key = "global"
			msg = tools.default_error_msg("Impossible d'ajouter un service", msg)
		}
		else if (key === "error_delete_service")
		{
			key = "global"
			msg = tools.default_error_msg("Impossible de supprimer un service", msg)
		}
		else if (key === "error_update_cover")
		{
			key = "global"
			msg = tools.default_error_msg("Impossible de mettre à jour une photo de couverture", msg)
		}
		else if (key === "error_order")
		{
			key = "global"
			msg = tools.default_error_msg("Impossible de réordonner les sections", msg)
		}
		else if (key === "error_duplicate")
		{
			key = "global"
			msg = tools.default_error_msg("Impossible de dupliquer une section", msg)
		}
		else if (key === "error_fetch_page")
		{
			key = "global"
			msg = tools.default_error_msg("Impossible de récupérer la page", msg)
		}
		let error = this._initErrors()
		if (id !== null)
			error[key][id] = msg
		else
			error[key] = msg
		this.setState({ success: this._initSuccess(), error: error })
	}

	setSuccessMsg = (key, msg) => {
		let success = this._initSuccess()
		success[key] = msg
		this.setState({ error: this._initErrors(), success: success })
	}

	clearFlash = () => {
		this.setState({ error: this._initErrors(), success: this._initSuccess() })
	}

	loadPage = (fromProps = false) => {
		if (fromProps === true && this.props.pages && this.state.page)
		{
			let page = null
			let form1 = {
				cover: null
			}
			let form3 = {
				add_service: false,
				service: '',
				link: '',
				translations: []
			}
			for (let i = 0; i < this.props.pages.length; i++)
			{
				if (this.props.pages[i]._id === this.state.page._id)
				{
					page = this.props.pages[i]
					for (let j = 0; j < page.sections.length; j++)
					{
						for (let k = 0; k < this.props.icons.length; k++)
						{
							if (page.sections[j].icon === this.props.icons[k]._id)
							{
								for (let l = 0; l < this.props.icons[k].fill.length; l++)
								{
									if (this.props.icons[k].fill[l].theme === this.props.theme._id)
									{
										page.sections[j].prev = this.props.icons[k].fill[l].icon
										break
									}
								}
								break
							}
						}
					}
					form1 = {
						cover: page.cover
					}
					if (page.service)
					{
						form3 = {
							add_service: true,
							service: page.service.service,
							link: page.service.link,
							translations: page.service.translations
						}
					}
					break
				}
			}
			this.setState({ page: page, form1: form1, form3: form3 })
		}
		else if (this.props.category)
		{
			const category = this.props.category._id
			this.props.fetchPageByCategory(category)
			.then(page => {
				for (let j = 0; j < page.sections.length; j++)
				{
					for (let k = 0; k < this.props.icons.length; k++)
					{
						if (page.sections[j].icon === this.props.icons[k]._id)
						{
							for (let l = 0; l < this.props.icons[k].fill.length; l++)
							{
								if (this.props.icons[k].fill[l].theme === this.props.theme._id)
								{
									page.sections[j].prev = this.props.icons[k].fill[l].icon
									break
								}
							}
							break
						}
					}
				}
				const form1 = {
					cover: page.cover
				}
				let form3 = {
					add_service: false,
					service: '',
					link: '',
					translations: []
				}
				if (page.service)
				{
					form3 = {
						add_service: true,
						service: page.service.service,
						link: page.service.link,
						translations: page.service.translations
					}
				}
				this.setState({ page: page, form1: form1, form3: form3 })
			})
			.catch(err => {
				this.setErrorMsg("error_fetch_page", 1)
				console.log(err)
			})
		}
		else if (this.props.page)
		{
			const page = this.props.page
			this.props.fetchPageByID(page)
			.then(page => {
				if (page.sections)
				{
					for (let j = 0; j < page.sections.length; j++)
					{
						for (let k = 0; k < this.props.icons.length; k++)
						{
							if (page.sections[j].icon === this.props.icons[k]._id)
							{
								for (let l = 0; l < this.props.icons[k].fill.length; l++)
								{
									if (this.props.icons[k].fill[l].theme === this.props.theme._id)
									{
										page.sections[j].prev = this.props.icons[k].fill[l].icon
										break
									}
								}
								break
							}
						}
					}
				}
				const form1 = {
					cover: page.cover
				}
				let form3 = {
					add_service: false,
					service: '',
					translations: []
				}
				if (page.service)
				{
					form3 = {
						add_service: true,
						service: page.service.service,
						translations: page.service.translations
					}
				}
				this.setState({ page: page, form1: form1, form3: form3 })
			})
			.catch(err => {
				this.setErrorMsg("error_fetch_page", 2)
				console.log(err)
			})
		}
	}

	handleTitle = (event) => {
		let found = false
		const translations = this.state.form2.translations
		for (let i = 0; i < translations.length; i++)
		{
			if (translations[i].lang === this.props.current_lang)
			{
				found = true
				translations[i].title = event.target.value
				break
			}
		}
		if (!found)
		{
			translations.push({
				lang: this.props.current_lang,
				title: event.target.value,
				html: ""
			})
		}
		const form2 = {
			...this.state.form2,
			translations: translations
		}
		this.setState({ form2: form2 })
	}

	handleIcon = (icon) => {
		const icons = this.props.icons
		let prev = null
		for (let i = 0; i < icons.length; i++)
		{
			if (icons[i]._id === icon)
			{
				for (let j = 0; j < icons[i].fill.length; j++)
				{
					if (icons[i].fill[j].theme === this.props.theme._id)
					{
						prev = icons[i].fill[j].icon
						break
					}
				}
				break
			}
		}
		const form2 = {
			...this.state.form2,
			icon: icon,
			prev: prev
		}
		this.setState({ form2: form2 })
	}

	handleHTML = (html) => {
		let found = false
		const translations = this.state.form2.translations
		for (let i = 0; i < translations.length; i++)
		{
			if (translations[i].lang === this.props.current_lang)
			{
				found = true
				translations[i].html = html
				break
			}
		}
		if (!found)
		{
			translations.push({
				lang: this.props.current_lang,
				title: "",
				html: html
			})
		}
		const form2 = {
			...this.state.form2,
			translations: translations
		}
		this.setState({ form2: form2 })
	}

	handlePhone = (event) => {
		const form2 = {
			...this.state.form2,
			phone: event.target.value
		}
		this.setState({ form2: form2 })
	}

	handleEmail = (event) => {
		const form2 = {
			...this.state.form2,
			email: event.target.value
		}
		this.setState({ form2: form2 })
	}

	handleWebsite = (event) => {
		const form2 = {
			...this.state.form2,
			website: event.target.value
		}
		this.setState({ form2: form2 })
	}

	handleLocalisation = (event) => {
		const form2 = {
			...this.state.form2,
			localisation: event.target.value
		}
		this.setState({ form2: form2 })
	}

	handleFacebook = (event) => {
		const form2 = {
			...this.state.form2,
			facebook: event.target.value
		}
		this.setState({ form2: form2 })
	}

	handleInstagram = (event) => {
		const form2 = {
			...this.state.form2,
			instagram: event.target.value
		}
		this.setState({ form2: form2 })
	}

	_validateForm2 = () => {
		let error_obj = this._initErrors()
		let error = false
		const icon = this.state.form2.icon
		const phone = this.state.form2.phone.trim()
		const email = this.state.form2.email.trim()
		const website = this.state.form2.website.trim()
		const localisation = this.state.form2.localisation.trim()
		const facebook = this.state.form2.facebook.trim()
		const instagram = this.state.form2.instagram.trim()
		const translations = this.state.form2.translations
		if (icon === null)
		{
			error = true
			error_obj.section_icon = "Vous dechez choisir une icône"
		}
		let found_default_html = false
		let found_default_title = false
		for (let i = 0; i < translations.length; i++)
		{
			const lang = translations[i].lang
			const title = translations[i].title
			if (title.length === 0 && lang === this.props.lang_default)
			{
				error = true
				error_obj.section_title = "Vous devez indiquer le titre (version " + lang.toUpperCase() + ")"
			}
			else if (title.length > this.section_length_max)
			{
				error = true
				error_obj.section_title = "Le titre ne doit pas dépasser " + this.section_length_max + " caractères (version " + lang.toUpperCase() + ")"
			}
			else if (lang === this.props.lang_default)
				found_default_title = true
			const html = translations[i].html
			if (html.length === 0 && lang === this.props.lang_default)
			{
				error = true
				error_obj.section_content = "Vous devez insérer un contenu (version " + lang.toUpperCase() + ")"
			}
			else if (lang === this.props.lang_default)
				found_default_html = true
		}
		if (!found_default_title && !error_obj.section_title)
		{
			error = true
			error_obj.section_title = 'Vous devez indiquer le titre (version ' + this.props.lang_default.toUpperCase() + ')'
		}
		if (!found_default_html && !error_obj.section_content)
		{
			error = true
			error_obj.section_content = 'Vous devez insérer un contenu (version ' + this.props.lang_default.toUpperCase() + ')'
		}
		if (phone.length > 0 && /^\+?[0-9 ./-]{9,}$/.test(phone) === false)
		{
			error = true
			error_obj.section_phone = 'Le format du numéro de téléhpone n\'est pas correct'
		}
		if (email.length > 0 && /^[^@]+@.{2,}\.[a-z]{2,}$/.test(email) === false)
		{
			error = true
			error_obj.section_email = 'Le format de l\'e-mail n\'est pas correct'
		}
		if (website.length > 0 && /[^.]{2,}\.[a-z]{2,}/.test(website) === false)
		{
			error = true
			error_obj.section_website = 'Le format du site web n\'est pas correct'
		}
		if (localisation.length > 0 && /^https:\/\/goo\.gl\/maps\//.test(localisation) === false && /^https:\/\/www\.google\.[a-z]{2,}\/maps\//.test(localisation) === false)
		{
			error = true
			error_obj.section_localisation = 'Le format du lien Google Maps n\'est pas correct'
		}
		if (facebook.length > 0 && /^https:\/\/([a-z]{2,}\.)?facebook\.com\//.test(facebook) === false)
		{
			error = true
			error_obj.section_facebook = 'Le format du lien Facebook n\'est pas correct'
		}
		if (instagram.length > 0 && /^https:\/\/(www\.)?instagram\.com\//.test(instagram) === false)
		{
			error = true
			error_obj.section_instagram = 'Le format du lien Instagram n\'est pas correct'
		}
		if (error === true)
		{
			error_obj.section = "Il y a une ou plusieurs erreur(s) dans le formulaire"
			const success = this._initSuccess()
			this.setState({ success: success, error: error_obj, attempt_submit: false })
		}
		return (!error) ? true : false
	}

	submitSection = (event) => {
		event.preventDefault()
		this.setState({ attempt_submit: true })
		if (this._validateForm2())
		{
			const translations = this.state.form2.translations
			let langs_not_found = []
			for (let i = 0; i < this.props.langs.length; i++)
			{
				let found = false
				for (let j = 0; j < translations.length; j++)
				{
					if (this.props.langs[i] === translations[j].lang && translations[j].title.length > 0 && translations[j].html.length > 0)
					{
						found = true
						break
					}
				}
				if (!found)
					langs_not_found.push(this.props.langs[i])
			}
			if (langs_not_found.length > 0)
			{
				let msg = ""
				for (let i = 0; i < langs_not_found.length; i++)
				{
					if (i > 0)
						msg += ', '
					msg += langs_not_found[i].toUpperCase()
				}
				msg = "La page n'est pas traduite dans les langues suivantes : " + msg
				this.setState({ modal_msg: msg, open_modal: true, answerModal: this.answerModalEdit })
			}
			else
				this._insertPageSection()
		}
	}

	_insertPageSection = () => {
		const icon = this.state.form2.icon
		let phone = this.state.form2.phone.trim()
		let loop = true
		while (loop)
		{
			loop = false
			let newphone = phone.replace(/[/ .-]/, '')
			if (newphone.localeCompare(phone) !== 0)
				loop = true
			phone = newphone
		}
		const email = this.state.form2.email.trim()
		let website = this.state.form2.website.trim()
		if (website.length > 0 && /^http(s)?:\/\//.test(website) === false)
			website = "https://" + website
		const localisation = this.state.form2.localisation.trim()
		const facebook = this.state.form2.facebook.trim()
		const instagram = this.state.form2.instagram.trim()
		const translations = this.state.form2.translations
		if (this.state.section !== null)
		{
			let key = 0
			for (let i = 0; i < this.state.page.sections.length; i++)
			{
				if (this.state.page.sections[i] === this.state.section)
				{
					key = i
					break
				}
			}
			const section = {
				page_id: this.state.page._id,
				icon: icon,
				key: key,
				phone: phone,
				email: email,
				website: website,
				localisation: localisation,
				facebook: facebook,
				instagram: instagram,
				translations: translations
			}
			this.props.updatePageSection(section)
			.then(ret => {
				if (ret === false)
				{
					this.setState({ attempt_submit: false, open_modal: false })
					this.setErrorMsg("error_update_section", 1)
				}
				else
				{
					const form2 = {
						icon: null,
						prev: null,
						phone: '',
						email: '',
						website: '',
						localisation: '',
						facebook: '',
						instagram: '',
						translations: []
					}
					this.setState({ form2: form2, edit: false, attempt_submit: false, open_modal: false })
					this.setSuccessMsg("section", "La section a été mise à jour")
				}
			})
			.catch(err => {
				this.setState({ attempt_submit: false, open_modal: false })
				this.setErrorMsg("error_update_section", 2)
				console.log(err)
			})
		}
		else
		{
			const section = {
				page_id: this.state.page._id,
				icon: icon,
				phone: phone,
				email: email,
				website: website,
				localisation: localisation,
				facebook: facebook,
				instagram: instagram,
				translations: translations
			}
			this.props.insertPageSection(section)
			.then(ret => {
				if (ret === false)
				{
					this.setState({ attempt_submit: false, open_modal: false })
					this.setErrorMsg("error_create_section", 1)
				}
				else
				{
					const form2 = {
						icon: null,
						prev: null,
						phone: '',
						email: '',
						website: '',
						localisation: '',
						facebook: '',
						instagram: '',
						translations: []
					}
					this.setState({ form2: form2, edit: false, attempt_submit: false, open_modal: false })
					this.setSuccessMsg("section", "La section a été bien été créée")
				}
			})
			.catch(err => {
				this.setState({ attempt_submit: false, open_modal: false })
				this.setErrorMsg("error_create_section", 2)
				console.log(err)
			})
		}
	}

	handleCover = (event) => {
		const reader = new FileReader()
		const file = event.target.files[0]
		reader.addEventListener("load", () => {
			const form1 = {
				cover: reader.result
			}
			this.setState({ form1: form1 })
		}, false)
		if (file)
			reader.readAsDataURL(file)
	}

	submitCover = (event) => {
		event.preventDefault()
		this.setState({ attempt_submit: true })
		const page = {
			_id: this.state.page._id,
			cover: this.state.form1.cover
		}
		this.props.updatePageCover(page)
		.then(ret => {
			if (ret === false)
			{
				this.setState({ attempt_submit: false })
				this.setErrorMsg("error_update_cover", 1)
			}
			else
			{
				const form1 = {
					cover: null
				}
				this.setState({ form1: form1, update_cover: false, attempt_submit: false })
				this.setSuccessMsg("global", "La photo de couverture à été mise à jour")
			}
		})
		.catch(err => {
			this.setState({ attempt_submit: false })
			this.setErrorMsg("error_update_cover", 2)
			console.log(err)
		})
	}

	displayService = () => {
		const form3 = {
			...this.state.form3,
			add_service: (this.state.form3.add_service === true) ? false : true
		}
		this.setState({ form3: form3 })
	}

	handleService = (event) => {
		const form3 = {
			...this.state.form3,
			service: event.target.value
		}
		this.setState({ form3: form3 })
	}

	handleServiceTitle = (event) => {
		let found = false
		const translations = this.state.form3.translations
		for (let i = 0; i < translations.length; i++)
		{
			if (translations[i].lang === this.props.current_lang)
			{
				found = true
				translations[i].title = event.target.value
				break
			}
		}
		if (!found)
		{
			translations.push({
				lang: this.props.current_lang,
				title: event.target.value
			})
		}
		const form3 = {
			...this.state.form3,
			translations: translations
		}
		this.setState({ form3: form3 })
	}

	handleServiceLink = (event) => {
		const form3 = {
			...this.state.form3,
			link: event.target.value
		}
		this.setState({ form3: form3 })
	}

	_validateForm3 = () => {
		let error_obj = this._initErrors()
		let error = false
		const service = this.state.form3.service
		const link = this.state.form3.link
		const translations = this.state.form3.translations
		if (service && service.length === 0 && link && link.length === 0)
		{
			error = true
			error_obj.service = "Vous devez sélectionner un service ou renseigner un lien externe"
		}
		else if (service && service.length > 0 && link && link.length > 0)
		{
			error = true
			error_obj.service = "Vous devez choisir entre un service ou renseigner un lien externe"
		}
		let found_default_title = false
		for (let i = 0; i < translations.length; i++)
		{
			const lang = translations[i].lang
			const title = translations[i].title
			if (title.length === 0 && lang === this.props.lang_default)
			{
				error = true
				error_obj.service_title = "Vous devez indiquer le titre (version " + lang.toUpperCase() + ")"
			}
			else if (title.length > this.length_max)
			{
				error = true
				error_obj.service_title = "Le titre ne doit pas dépasser " + this.length_max + " caractères (version " + lang.toUpperCase() + ")"
			}
			else if (lang === this.props.lang_default)
				found_default_title = true
		}
		if (!found_default_title && !error_obj.service_title)
		{
			error = true
			error_obj.service_title = 'Vous devez indiquer le titre (version ' + this.props.lang_default.toUpperCase() + ')'
		}
		if (error === true)
		{
			if (!error_obj.service)
				error_obj.service = "Il y a une ou plusieurs erreur(s) dans le formulaire"
			const success = this._initSuccess()
			this.setState({ success: success, error: error_obj, attempt_submit: false })
		}
		return (!error) ? true : false
	}

	handleBreadcrumb = () => {
		/* par défaut ce que je récupère en props : vue catégorie
		1. Vue liste 
			-> Vue liste 
				-> Vue page
					-> Vue section

		2. Vue page
			-> vue section
		*/
	} 

	submitService = (event) => {
		event.preventDefault()
		this.setState({ attempt_submit: true })
		if (this.state.form3.add_service === true && this._validateForm3())
		{
			const translations = this.state.form3.translations
			let langs_not_found = []
			for (let i = 0; i < this.props.langs.length; i++)
			{
				let found = false
				for (let j = 0; j < translations.length; j++)
				{
					if (this.props.langs[i] === translations[j].lang && translations[j].title.length > 0)
					{
						found = true
						break
					}
				}
				if (!found)
					langs_not_found.push(this.props.langs[i])
			}
			if (langs_not_found.length > 0)
			{
				let msg = ""
				for (let i = 0; i < langs_not_found.length; i++)
				{
					if (i > 0)
						msg += ', '
					msg += langs_not_found[i].toUpperCase()
				}
				msg = "Le lien vers le service n'est pas traduite dans les langues suivantes : " + msg
				this.setState({ modal_msg: msg, open_modal: true, answerModal: this.answerModalService })
			}
			else
				this._insertPageService()
		}
		else if (this.state.form3.add_service === false)
		{
			const service = {
				page_id: this.state.page._id
			}
			this.props.deletePageService(service)
			.then(ret => {
				if (ret)
					this.setSuccessMsg("service", "Le service a bien été retiré de la page")
				else
					this.setErrorMsg("error_delete_service", 1)
				this.setState({ attempt_submit: false })
			})
			.catch(err => {
				this.setState({ attempt_submit: false })
				this.setErrorMsg("error_delete_service", 2)
				console.log(err)
			})
		}
	}

	_insertPageService = () => {
		const service = {
			page_id: this.state.page._id,
			service: this.state.form3.service,
			link: this.state.form3.link,
			translations: this.state.form3.translations
		}
		this.props.updatePageService(service)
		.then(ret => {
			if (!ret)
				this.setErrorMsg("error_update_service", 1)
			else
				this.setSuccessMsg("service", "Le service a bien été ajouté dans la page")
			this.setState({ attempt_submit: false, open_modal: false })
		})
		.catch(err => {
			this.setState({ attempt_submit: false, open_modal: false })
			this.setErrorMsg("error_update_service", 2)
			console.log(err)
		})
	}

	clickCreate = () => {
		const form2 = {
			icon: null,
			prev: null,
			phone: '',
			email: '',
			website: '',
			localisation: '',
			facebook: '',
			instagram: '',
			translations: []
		}
		this.clearFlash()
		this.setState({ form2: form2, section: null, edit: true })
	}

	clickSelect = (key) => {
		const section = this.state.page.sections[key]
		const form2 = {
			icon: section.icon,
			prev: section.prev,
			phone: (section.phone) ? section.phone : '',
			email: (section.email) ? section.email : '',
			website: (section.website) ? section.website : '',
			localisation: (section.localisation) ? section.localisation : '',
			facebook: (section.facebook) ? section.facebook : '',
			instagram: (section.instagram) ? section.instagram : '',
			translations: section.translations
		}
		this.clearFlash()
		this.setState({ edit: true, section: section, form2: form2 })
	}

	clickUp = (key) => {
		let update = false
		let sections = this.state.page.sections
		if (key > 0)
		{
			let order_tmp = sections[key - 1].order
			sections[key - 1].order = sections[key].order
			sections[key].order = order_tmp
			let section_tmp = sections[key - 1]
			sections[key - 1] = sections[key]
			sections[key] = section_tmp
			update = true
		}
		if (update === true)
		{
			const page = {
				_id: this.state.page._id,
				sections: sections
			}
			this.props.updatePageOrderSections(page)
			.then(ret => {
				if (ret === false)
					this.setErrorMsg("error_order", 1)
			})
			.catch(err => {
				this.setErrorMsg("error_order", 2)
				console.log(err)
			})
		}
	}

	clickDown = (key) => {
		let update = false
		let sections = this.state.page.sections
		if (key + 1 < sections.length)
		{
			let order_tmp = sections[key + 1].order
			sections[key + 1].order = sections[key].order
			sections[key].order = order_tmp
			let section_tmp = sections[key + 1]
			sections[key + 1] = sections[key]
			sections[key] = section_tmp
			update = true
		}
		if (update === true)
		{
			const page = {
				_id: this.state.page._id,
				sections: sections
			}
			this.props.updatePageOrderSections(page)
			.then(ret => {
				if (ret === false)
					this.setErrorMsg("error_order", 3)
			})
			.catch(err => {
				this.setErrorMsg("error_order", 4)
				console.log(err)
			})
		}
	}

	// clickMove = (key) => {
	// 	console.log("click move", key)
	// 	// const msg = "Vous allez dupliquer cette section et tout le contenu qui s'y trouve."
	// 	// this.setState({ duplicate: key, modal_msg: msg, open_modal: true, answerModal: this.answerModalDuplicate })
	// }

	clickDuplicate = (key) => {
		const msg = "Vous allez dupliquer cette section et tout le contenu qui s'y trouve."
		this.setState({ duplicate: key, modal_msg: msg, open_modal: true, answerModal: this.answerModalDuplicate })
	}

	_duplicateSection = () => {
		const section = {
			page_id: this.state.page._id,
			key: this.state.duplicate
		}
		this.props.updatePageDuplicateSection(section)
		.then(ret => {
			if (ret === false)
			{
				this.setState({ duplicate: false, open_modal: false })
				this.setErrorMsg("error_duplicate", 1)
			}
			else
			{
				this.setState({ duplicate: false, open_modal: false })
				this.setSuccessMsg("global", "La section a bien été dupliquée")
			}
		})
		.catch(err => {
			this.setState({ duplicate: false, open_modal: false })
			this.setErrorMsg("error_duplicate", 2)
			console.log(err)
		})
	}

	clickDelete = (key) => {
		const msg = "Vous supprimerez cette section et tout le contenu qui s'y trouve. Cette action est irréversible"
		this.setState({ delete: key, modal_msg: msg, open_modal: true, answerModal: this.answerModalDelete })
	}

	_deletePageSection = () => {
		const section = {
			page_id: this.state.page._id,
			key: this.state.delete
		}
		this.props.deletePageSection(section)
		.then(ret => {
			if (ret === false)
			{
				this.setState({ open_modal: false })
				this.setErrorMsg("error_delete_section", 1)
			}
			else
			{
				this.setState({ open_modal: false })
				this.setSuccessMsg("global", "La section a bien été supprimée")
			}
		})
		.catch(err => {
			this.setState({ open_modal: false })
			this.setErrorMsg("error_delete_section", 2)
			console.log(err)
		})
	}

	clickCover = () => {
		const update_cover = (this.state.update_cover === true) ? false : true
		this.setState({ update_cover: update_cover })
	}

	answerModalEdit = (answer) => {
		if (answer === "yes")
			this._insertPageSection()
		else
			this.setState({ attempt_submit: false, open_modal: false })
	}

	answerModalDelete = (answer) => {
		if (answer === "yes")
			this._deletePageSection()
		else
			this.setState({ delete: false, open_modal: false })
	}

	answerModalDuplicate = (answer) => {
		if (answer === "yes")
			this._duplicateSection()
		else
			this.setState({ duplicate: false, open_modal: false })
	}

	answerModalService = (answer) => {
		if (answer === "yes")
			this._insertPageService()
		else
			this.setState({ attempt_submit: false, open_modal: false })
	}

	unEdit = () => {
		this.setState({ edit: false })
	}

	render() {
		if (this.state.page === null)
		{
			return (
				<div className="loader">
					<Loader />
				</div>
			)
		}
		else
		{
			let titleHeader = null
			if (this.props.page) // category > liste > page 
				titleHeader = (<h1><span className="uhover" onClick={this.props.unShowGroup}>Rubriques</span> > <span className="uhover" onClick={this.props.unShowCategory}>Catégories</span> > <span className="uhover" onClick={this.props.unShowList}>Liste</span> > Page</h1>)	
			else // category > page
				titleHeader = (<h1><span className="uhover" onClick={this.props.unShowGroup}>Rubriques</span> > <span className="uhover" onClick={this.props.unShowCategory}>Catégories</span> > Page</h1>)
			const page = this.state.page
			let left = null
			if (this.state.edit === true)
			{
				let title = ""
				let html = ""
				for (let i = 0; i < this.state.form2.translations.length; i++)
				{
					if (this.state.form2.translations[i].lang === this.props.current_lang)
					{
						title = this.state.form2.translations[i].title
						html = this.state.form2.translations[i].html
						break
					}
				}
				let sendbtn = (<button type="submit" className="waves-effect waves-light btn-small">Ajouter une section</button>)
				if (this.state.section !== null)
					sendbtn = (<button type="submit" className="waves-effect waves-light btn-small">Enregistrer</button>)
				if (this.state.attempt_submit === true)
				{
					sendbtn = (
						<div className="center">
							<Loader />
						</div>
					)
				}
				if (this.props.page) // category > liste > page > section
					titleHeader = (<h1><span className="uhover" onClick={this.props.unShowGroup}>Rubriques</span> > <span className="uhover" onClick={this.props.unShowCategory}>Catégories</span> > <span className="uhover" onClick={this.props.unShowList}>Liste</span> > <span className="uhover" onClick={this.unEdit}>Page</span> > Section</h1>)
				else // category > page > section
					titleHeader = (<h1><span className="uhover" onClick={this.props.unShowGroup}>Rubriques</span> > <span className="uhover" onClick={this.props.unShowCategory}>Catégories</span> > <span className="uhover" onClick={this.unEdit}>Page</span> > Section</h1>)
				let prevIcon = null
				if (this.state.form2.prev !== null)
					prevIcon = (<div><p>Prévisualisation :</p><p><img src={this.state.form2.prev} className="small" alt="Prévisualisation" /></p></div>)
				left = (
					<div>
						<div className="row">
							<div className="module col s12">
								<p className="success">{this.state.success.section}</p>
								<LangSelector lang={this.props.current_lang} updateLang={this.props.updateLang} />
								<form onSubmit={this.submitSection}>
									<label>
										<p className="error">{this.state.error.section_title}</p>
										<p>Titre (version {this.props.current_lang.toUpperCase()}) :</p>
										<input type="text" value={title} onChange={this.handleTitle} />
									</label>
									<div className="label">
										<p className="error">{this.state.error.section_icon}</p>
										<p className="column-20">Icône :</p>
										<IconPicker type="fill" theme={this.props.theme._id} icons={this.props.icons} onChange={this.handleIcon} />
									</div>
									{prevIcon}
									<p className="error">{this.state.error.section_content}</p>
									<CKEditor
										toolbar="rubrics"
										data={html}
										updateContent={this.handleHTML}
									/>
									<p className="error">{this.state.error.section}</p>
									<div className="label">
										<p className="error">{this.state.error.section_phone}</p>
										<p><span role="img" aria-label="phone">📞</span> Téléphone :</p>
										<input type="text" value={this.state.form2.phone} onChange={this.handlePhone} />
									</div>
									<div className="label">
										<p className="error">{this.state.error.section_email}</p>
										<p><span role="img" aria-label="envelop">✉️</span> E-mail :</p>
										<input type="text" value={this.state.form2.email} onChange={this.handleEmail} />
									</div>
									<div className="label">
										<p className="error">{this.state.error.section_website}</p>
										<p><span role="img" aria-label="globe">🌐</span> Site Internet :</p>
										<input type="text" value={this.state.form2.website} onChange={this.handleWebsite} />
									</div>
									<div className="label">
										<p className="error">{this.state.error.section_localisation}</p>
										<p><span role="img" aria-label="earth">🌎</span> Géolocalisation :</p>
										<input type="text" value={this.state.form2.localisation} onChange={this.handleLocalisation} />
									</div>
									<div className="label">
										<p className="error">{this.state.error.section_facebook}</p>
										<p><span role="img" aria-label="person">🧑</span> Facebook :</p>
										<input type="text" value={this.state.form2.facebook} onChange={this.handleFacebook} />
									</div>
									<div className="label">
										<p className="error">{this.state.error.section_instagram}</p>
										<p><span role="img" aria-label="camera">📷</span> Instagram :</p>
										<input type="text" value={this.state.form2.instagram} onChange={this.handleInstagram} />
									</div>
									{sendbtn}
								</form>
							</div>
						</div>
					</div>
				)
			}
			else
			{
				let prev = null
				if (this.state.form2.cover !== null)
					prev = (<div><p>Prévisualisation :</p><p><img src={this.state.form2.cover} alt="Prévisualisation" /></p></div>)
				let textCover = 'Éditer photo de couverture'
				if (this.state.form2.cover === null)
					textCover = 'Ajouter une photo de couverture'
				let formCover = (
					<button type="submit" className="waves-effect waves-light btn-small" onClick={this.clickCover}>{textCover}</button>
				)
				let sendbtn = (
					<div>
						<button type="submit" className="waves-effect waves-light btn-small">Enregistrer</button>
						<button type="submit" className="waves-effect waves-light btn-small" onClick={this.clickCover}>Masquer</button>
					</div>
				)
				if (this.state.attempt_submit === true)
				{
					sendbtn = (
						<div className="center">
							<Loader />
						</div>
					)
				}
				if (this.state.update_cover === true)
				{
					formCover = (
						<form onSubmit={this.submitCover}>
							<label>
								<p className="column-20">Photo :</p>
								<input type="file" onChange={this.handleCover} />
							</label>
							{prev}
							{sendbtn}
						</form>
					)
				}
				let services = null
				if (this.props.features === "premium")
				{
					let serviceForm = null
					if (this.state.form3.add_service === true)
					{
						const services = this.props.services
						let title = ""
						for (let i = 0; i < this.state.form3.translations.length; i++)
						{
							if (this.state.form3.translations[i].lang === this.props.current_lang)
							{
								title = this.state.form3.translations[i].title
								break
							}
						}
						serviceForm = (
							<div>
								<label>
									<p className="error">{this.state.error.service_title}</p>
									<p className="column-20">Titre (version {this.props.current_lang.toUpperCase()}) :</p>
									<input type="text" value={title} onChange={this.handleServiceTitle} />
								</label>
								<label>
									<p className="error">{this.state.error.service_select}</p>
									<p className="column-20">Service :</p>
									<select value={this.state.form3.service} onChange={this.handleService}>
										<option value="">-- Sélectionner un service --</option>
										{services && services.map(({ _id, name }) => {
											return (<option value={_id} key={_id}>{name}</option>)
										})}
									</select>
								</label>
								<label>
									<p className="error">{this.state.error.service_link}</p>
									<p className="column-20">Lien externe :</p>
									<input type="text" value={this.state.form3.link} onChange={this.handleServiceLink} />
								</label>
							</div>
						)
					}
					let sendbtn = (<button type="submit" className="waves-effect waves-light btn-small">Enregistrer</button>)
					if (this.state.attempt_submit === true)
					{
						sendbtn = (
							<div className="center">
								<Loader />
							</div>
						)
					}
					services = (
						<div className="module col s12">
							<p className="success">{this.state.success.service}</p>
							<LangSelector lang={this.props.current_lang} updateLang={this.props.updateLang} />
							<form onSubmit={this.submitService}>
								<label>
									<p className="column-20">Ajouter un bouton</p>
									<input type="checkbox" checked={this.state.form3.add_service} onChange={this.displayService} />
									<span></span>
								</label>
								{serviceForm}
								<p className="error">{this.state.error.service}</p>
								{sendbtn}
							</form>
						</div>
					)
				}
				let section_k = -1
				const sections = page.sections
				left = (
					<div className="row">
						<div className="module col s12">
							{formCover}
						</div>
						<div className="module col s12">
							<button type="button" className="waves-effect waves-light btn-large" onClick={this.clickCreate}>Ajouter une section</button>
							<LangSelector lang={this.props.current_lang} updateLang={this.props.updateLang} />
							{sections && sections.map(({ prev, translations }) => {
								let title = ""
								for (let i = 0; i < translations.length; i++)
								{
									if (translations[i].lang === this.props.current_lang)
									{
										title = translations[i].title
										break
									}
								}
								section_k++
								let image = null
								if (prev)
									image = (<div className="icon fill"><img src={prev} alt="Icône sections" /></div>)
								return (
									<div className="object full-width" key={section_k}>
										<div className="title">{image}<p onClick={this.clickSelect.bind(this, section_k)}>{title}</p></div>
										<div className="buttons">
											<button type="button" className="waves-effect waves-light btn" onClick={this.clickUp.bind(this, section_k)}>Monter</button>
											<button type="button" className="waves-effect waves-light btn" onClick={this.clickDown.bind(this, section_k)}>Descendre</button>
											{/* <button type="button" className="waves-effect waves-light btn" onClick={this.clickMove.bind(this, section_k)}>Déplacer</button> */}
											<button type="button" className="waves-effect waves-light btn" onClick={this.clickDuplicate.bind(this, section_k)}>Dupliquer</button>
											<button type="button" className="waves-effect waves-light btn red" onClick={this.clickDelete.bind(this, section_k)}>Supprimer</button>
										</div>
										<div className="clear"></div>
									</div>
								)
							})}
						</div>
						{services}
					</div>
				)
			}
			// let breadcrumb = (
			// 	<nav className={navStyle} aria-label="Breadcrumb">
			// 		<ol className="bg-white rounded-md shadow px-6 flex space-x-4">
			// 			<li className="flex">
			// 				<div className="flex items-center">
			// 					<Link to="/" className="text-gray-400 hover:text-gray-500">
			// 						<HomeIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
			// 						<span className="sr-only">Home</span>
			// 					</Link>
			// 				</div>
			// 			</li>
			// 			{this.state.navigation.map((page) => {
			// 				if (this.props.page)
			// 				{
			// 					if (this.state.navigation.indexOf(page) === 1)
			// 						func = this.props.unShowGroup
			// 					else if (this.state.navigation.indexOf(page) === 2)
			// 						func = this.props.unShowCategory
			// 					else if (this.state.navigation.indexOf(page) === 3)
			// 						func = this.props.unShowList
			// 				}
			// 				// const func = (this.state.navigation.indexOf(page) === 1) ? this.props.unShowCategory : this.unEdit
			// 				return (
			// 					<li key={page.name} className="flex">
			// 						<div className="flex items-center">
			// 							<svg
			// 								className="flex-shrink-0 w-6 h-full text-gray-200"
			// 								viewBox="0 0 24 44"
			// 								preserveAspectRatio="none"
			// 								fill="currentColor"
			// 								xmlns="http://www.w3.org/2000/svg"
			// 								aria-hidden="true"
			// 							>
			// 								<path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
			// 							</svg>
			// 							<button
			// 								onClick={func.bind(this, page)}
			// 								className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
			// 								aria-current={page.current ? 'page' : undefined}
			// 							>
			// 								{page.name}
			// 							</button>
			// 						</div>
			// 					</li>
			// 				)
			// 			})}
			// 		</ol>
			// 	</nav>
			// )
			return (
				<div className="wrapper-content">
					{titleHeader}
					{/* {breadcrumb}	 */}
					<p className="center error">{this.state.error.global}</p>
					<p className="center success">{this.state.success.global}</p>
					<Modal
						dismissible={false}
						content={this.state.modal_msg}
						open_modal={this.state.open_modal}
						answerModal={this.state.answerModal}
					/>
					<div className="row">
						<div className="col s6">
							{left}
						</div>
						<div className="col s6">
							<div className="row">
								<div className="module col s12">
									<RenderPage
										current_lang={this.props.current_lang}
										icons={this.props.icons}
										page={page}
										theme={this.props.theme}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			)
		}
	}
}

export default Pages

import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import connexion from '../../connexion'
import tools from '../../tools'
import Loader from '../UI/Loader'
import M from "materialize-css"
import '../Core/Main.css'
import EmptyFolder from '../UI/EmptyFolder'

class CronMail extends Component {
	constructor(props) {
		super(props)
		this.state = {
			cron: null,
			error: null,
			success: null
		}
		this.max_emails_to_show = 10
		this.props.updateNavbarTitle('Pré-programmer des envoi d\'e-mails')
	}

	componentDidMount() {
		this.fetchCronMails()
	}
	
	fetchCronMails = () => {
		fetch(connexion.connect.url + 'dashboard/cron_mail/list')
		.then(res => res.json())
		.then(cron => {
			cron = cron.map((mail) => {
				mail.sort_time = (mail.update_time === null) ? mail.create_time : mail.update_time
				return mail
			})
			cron.sort((a, b) => {
				return b.sort_time - a.sort_time
			})
			for (let i = 0; i < cron.length; i++)
				cron[i].expend = false
			this.setState({ cron: cron })
		})
	}

	clickDelete = (id) => {
		console.log(id)
	}
	
	componentDidUpdate () {
		const elems = document.querySelectorAll('select')
		M.FormSelect.init(elems)
	}

	setSuccessMsg = (msg) => {
		this.setState({ error: null, success: msg })
	}

	setErrorMsg = (msg) => {
		this.setState({ error: msg, success: null })
	}

	expend = (id) => {
		let cron = this.state.cron
		for (let i = 0; i < cron.length; i++)
		{
			if (cron[i]._id === id)
			{
				cron[i].expend = true
				break
			}
		}
		this.setState({ cron: cron })
	}

	unexpend = (id) => {
		let cron = this.state.cron
		for (let i = 0; i < cron.length; i++)
		{
			if (cron[i]._id === id)
			{
				cron[i].expend = false
				break
			}
		}
		this.setState({ cron: cron })
	}

	render() {
		const cron = this.state.cron
		const permissions = this.props.user.permissions
		if (!permissions || !permissions.emails || permissions.emails === 0)
			return (<Redirect to="/" />)
		else if (cron === null)
		{
			return (
				<div className="loader">
					<Loader />
				</div>
			)
		}
		else if (!cron || !cron.length)
		{
			return (
				<EmptyFolder 
					emptyFolderSubtitle="Pas de campagne d'Email envoyée"
					content="Retrouvez ici vos campagnes d'Emails personnalisés (hors Email avant séjour)"
				/>
			)
		}
		else
		{
			return (
				<div className="wrapper-content">
					<p className="success">{this.state.success}</p>
					<p className="error">{this.state.error}</p>
					<div className="row">

						<div className="module col s12">
							{cron.map(({ _id, sent, translations, sent_list, email_list, expend, time }) => {
								const datestr = tools.parseDate(time)
								let title = ""
								let content = ""
								for (let i = 0; i < translations.length; i++)
								{
									if (translations[i].lang === this.props.lang_default)
									{
										title = translations[i].title
										content = translations[i].content
										break
									}
								}
								const sent_length = (sent_list !== null) ? sent_list.length : 0
								if (sent === false)
									sent = sent_length + "/" + email_list.length
								else
									sent = tools.parseDate(sent)
								let deletebtn = null
								const now = tools.getTimestamp()
								let emailsJSX = null
								if (sent_list !== null)
								{
									sent_list.sort((a, b) => {
										return a.localeCompare(b)
									})
									let emails = ''
									let emails_short = ''
									let i = 0
									while (i < sent_list.length)
									{
										if (i > 0)
											emails += ', '
										if (i > 0 && i < this.max_emails_to_show)
											emails_short += ', '
										emails += sent_list[i]
										if (i < this.max_emails_to_show)
											emails_short += sent_list[i]
										i++
									}
									if (i > this.max_emails_to_show)
										emails_short += '...'
									if (expend === true)
										emailsJSX = (<span><b>Adresses e-mail :</b> {emails} <span className="alink" onClick={this.unexpend.bind(this, _id)}>Afficher moins</span><br /></span>)
									else if (i > this.max_emails_to_show)
										emailsJSX = (<span><b>Adresses e-mail :</b> {emails_short} <span className="alink" onClick={this.expend.bind(this, _id)}>Afficher plus</span><br /></span>)
									else
										emailsJSX = (<span><b>Adresses e-mail :</b> {emails_short}<br /></span>)
								}
								if (now < time)
									deletebtn = (<div className="buttons"><button type="button" className="waves-effect waves-light btn red" onClick={this.clickDelete.bind(this, _id)}>Annuler l'envoi</button></div>)
								return (
									<div className="object-2" key={_id}>
										<p>
											<b>Objet :</b> {title}<br />
											<b>Nombre d'e-mails à envoyer :</b> {email_list.length}<br />
											<b>Date d'envoi :</b> {datestr}<br />
											<b>Envoi complété :</b> {sent}<br />
											{emailsJSX}
											<b>Contenu :</b>
										</p>
										<div dangerouslySetInnerHTML={{ __html: content }}></div>
										{deletebtn}
									</div>
								)
							})}
						</div>
					</div>
				</div>
			)
		}
	}
}

export default CronMail

import React, { Component } from 'react'
import M from 'materialize-css'
import tools from '../../tools'
import '../Core/Main.css'

class Workers extends Component {
	constructor(props) {
		super(props)
		this.state = this._initState()
		this.length_max = 50
	}

	componentDidMount() {
	}

	componentDidUpdate(prevProps, prevState) {
		const elems = document.querySelectorAll('select')
		M.FormSelect.init(elems)
		if (prevProps.click_modal !== this.props.click_modal)
			this.setState(this._initState())
	}

	_initState = () => {
		let state = {
			edit: null,
			create: false,
			step: 0,
			attempt_submit: false,
			icons: undefined,
			theme: undefined,
			form1: {
				firstname: '',
				lastname: '',
				working_dates: [{
					start_day: '',
					start_month: '',
					end_day: '',
					end_month: ''
				}],
				working_hours: [{
					monday: false,
					tuesday: false,
					wednesday: false,
					thursday: false,
					friday: false,
					saturday: false,
					sunday: false,
					start_hour: '',
					start_minute: '',
					end_hour: '',
					end_minute: ''
				}],
				services: ['']
			},
			error: this._initErrors(),
			success: null
		}
		if (this.props.edit_modal !== false)
		{
			const worker = this.props.edit_modal
			const form1 = {
				lastname: worker.lastname,
				firstname: worker.firstname,
				working_dates: worker.working_dates,
				working_hours: worker.working_hours,
				services: worker.services
			}
			state.form1 = form1
		}
		return state
	}

	_initErrors = () => {
		const errors = {
			global: null,
			worker_firstname: null,
			worker_lastname: null,
			working_dates: [],
			working_hours_weekdays: [],
			working_hours_time: [],
			service_workers: [],
			remove_working_dates: [],
			remove_working_hours: [],
			remove_service_workers: [],
			add_service_worker: null
		}
		if (this.state)
		{
			for (let j = 0; j < this.state.form1.working_dates.length; j++)
			{
				errors.working_dates.push(null)
				errors.remove_working_dates.push(null)
			}
			for (let j = 0; j < this.state.form1.working_hours.length; j++)
			{
				errors.working_hours_weekdays.push(null)
				errors.working_hours_time.push(null)
				errors.remove_working_hours.push(null)
			}
			for (let j = 0; j < this.props.service_benefits.length; j++)
			{
				errors.service_workers.push(null)
				errors.remove_service_workers.push(null)
			}
		}
		return errors
	}

	setErrorMsg = (key, msg, id = null) => {
		let error = this.state.error
		if (id !== null)
			error[key][id] = msg
		else
			error[key] = msg
		this.setState({ success: null, error: error })
	}

	setSuccessMsg = (msg) => {
		const error = this._initErrors()
		this.setState({ success: msg, error: error })
	}

	clearFlash = () => {
		const error = this._initErrors()
		this.setState({ error: error, success: null })
	}

	handleWorkerLastname = (event) => {
		const form1 = {
			...this.state.form1,
			lastname: event.target.value
		}
		this.setState({ form1: form1 })
	}

	handleWorkerFirstname = (event) => {
		const form1 = {
			...this.state.form1,
			firstname: event.target.value
		}
		this.setState({ form1: form1 })
	}

	handleStartDayWorker = (working_dates_k, event) => {
		const working_dates = this.state.form1.working_dates
		working_dates[working_dates_k].start_day = event.target.value
		const form1 = {
			...this.state.form1,
			working_dates: working_dates
		}
		this.setState({ form1: form1 })
	}

	handleStartMonthWorker = (working_dates_k, event) => {
		const working_dates = this.state.form1.working_dates
		working_dates[working_dates_k].start_month = event.target.value
		const form1 = {
			...this.state.form1,
			working_dates: working_dates
		}
		this.setState({ form1: form1 })
	}

	handleEndDayWorker = (working_dates_k, event) => {
		const working_dates = this.state.form1.working_dates
		working_dates[working_dates_k].end_day = event.target.value
		const form1 = {
			...this.state.form1,
			working_dates: working_dates
		}
		this.setState({ form1: form1 })
	}

	handleEndMonthWorker = (working_dates_k, event) => {
		const working_dates = this.state.form1.working_dates
		working_dates[working_dates_k].end_month = event.target.value
		const form1 = {
			...this.state.form1,
			working_dates: working_dates
		}
		this.setState({ form1: form1 })
	}

	handleAddWorkingDate = () => {
		let working_dates = this.state.form1.working_dates
		working_dates.push({
			start_day: '',
			start_month: '',
			end_day: '',
			end_month: ''
		})
		const form1 = {
			...this.state.form1,
			working_dates: working_dates
		}
		this.clearFlash()
		this.setState({ form1: form1 })
	}

	handleRemoveWorkingDate = (working_dates_k) => {
		if (this.state.form1.working_dates.length <= 1)
			this.setErrorMsg('remove_working_dates', 'Vous devez définir au moins 1 période de disponibilité', working_dates_k)
		else
		{
			let working_dates = this.state.form1.working_dates
			working_dates.splice(working_dates_k, 1)
			const form1 = {
				...this.state.form1,
				working_dates: working_dates
			}
			this.clearFlash()
			this.setState({ form1: form1 })
		}
	}

	handleWeekdayWorker = (working_hours_k, day) => {
		const working_hours = this.state.form1.working_hours
		working_hours[working_hours_k][day] = (working_hours[working_hours_k][day]) ? false : true
		const form1 = {
			...this.state.form1,
			working_hours: working_hours
		}
		this.setState({ form1: form1 })
	}

	handleStartHourWorker = (working_hours_k, event) => {
		const working_hours = this.state.form1.working_hours
		working_hours[working_hours_k].start_hour = event.target.value
		const form1 = {
			...this.state.form1,
			working_hours: working_hours
		}
		this.setState({ form1: form1 })
	}

	handleStartMinuteWorker = (working_hours_k, event) => {
		const working_hours = this.state.form1.working_hours
		working_hours[working_hours_k].start_minute = event.target.value
		const form1 = {
			...this.state.form1,
			working_hours: working_hours
		}
		this.setState({ form1: form1 })
	}

	handleEndHourWorker = (working_hours_k, event) => {
		const working_hours = this.state.form1.working_hours
		working_hours[working_hours_k].end_hour = event.target.value
		const form1 = {
			...this.state.form1,
			working_hours: working_hours
		}
		this.setState({ form1: form1 })
	}

	handleEndMinuteWorker = (working_hours_k, event) => {
		const working_hours = this.state.form1.working_hours
		working_hours[working_hours_k].end_minute = event.target.value
		const form1 = {
			...this.state.form1,
			working_hours: working_hours
		}
		this.setState({ form1: form1 })
	}

	handleAddWorkingHour = (worker_k) => {
		let working_hours = this.state.form1.working_hours
		working_hours.push({
			monday: false,
			tuesday: false,
			wednesday: false,
			thursday: false,
			friday: false,
			saturday: false,
			sunday: false,
			start_hour: '',
			start_minute: '',
			end_hour: '',
			end_minute: ''
		})
		const form1 = {
			...this.state.form1,
			working_hours: working_hours
		}
		this.clearFlash()
		this.setState({ form1: form1 })
	}

	handleRemoveWorkingHour = (working_hours_k) => {
		if (this.state.form1.working_hours.length <= 1)
			this.setErrorMsg('remove_working_hours', 'Vous devez définir au moins 1 horaire de travail', working_hours_k)
		else
		{
			let working_hours = this.state.form1.working_hours
			working_hours.splice(working_hours_k, 1)
			const form1 = {
				...this.state.form1,
				working_hours: working_hours
			}
			this.clearFlash()
			this.setState({ form1: form1 })
		}
	}

	handleServiceWorker = (worker_services_k, event) => {
		const services = this.state.form1.services
		services[worker_services_k] = event.target.value
		const form1 = {
			...this.state.form1,
			services: services
		}
		this.setState({ form1: form1 })
	}

	handleAddServiceWorker = (worker_k) => {
		const nb_services = this.props.service_benefits.length
		let services = this.state.form1.services
		if (services.length >= nb_services)
			this.setErrorMsg('add_service_worker', 'Vous ne pouvez pas ajouter plus de prestations')
		else
		{
			services.push('')
			const form1 = {
				...this.state.form1,
				services: services
			}
			this.clearFlash()
			this.setState({ form1: form1 })
		}
	}

	handleRemoveServiceWorker = (worker_services_k) => {
		if (this.state.form1.services.length <= 1)
			this.setErrorMsg('remove_service_workers', 'Vous devez définir au moins 1 prestation', worker_services_k)
		else
		{
			let services = this.state.form1.services
			services.splice(worker_services_k, 1)
			const form1 = {
				...this.state.form1,
				services: services
			}
			this.clearFlash()
			this.setState({ form1: form1 })
		}
	}

	validateForm1 = () => {
		let error_obj = this._initErrors()
		let error = false
		if (this.state.form1.firstname.length === 0)
		{
			error = true
			error_obj.worker_firstname = "Le prénom ne doit être vide"
		}
		else if (this.state.form1.firstname.length > this.length_max)
		{
			error = true
			error_obj.worker_name = "Le prénom ne doit pas dépasser " + this.length_max + " caractères"
		}
		if (this.state.form1.lastname.length === 0)
		{
			error = true
			error_obj.worker_lastname = "Le nom ne doit être vide"
		}
		else if (this.state.form1.lastname.length > this.length_max)
		{
			error = true
			error_obj.worker_lastname = "Le nom ne doit pas dépasser " + this.length_max + " caractères"
		}
		const year = new Date().getFullYear()
		for (let i = 0; i < this.state.form1.working_dates.length; i++)
		{
			const working_date = this.state.form1.working_dates[i]
			const start_time = tools.parseTimestamp(Number(working_date.start_day), Number(working_date.start_month), year)
			const end_time = tools.parseTimestamp(Number(working_date.end_day) + 1, Number(working_date.end_month), year)
			if (working_date.start_day.length === 0 || working_date.start_month.length === 0 || working_date.end_day.length === 0 || working_date.end_month.length === 0)
			{
				error = true
				error_obj.working_dates[i] = "Les dates d'ouverture de début et de fin doivent être remplis"
			}
			else if (start_time >= end_time)
			{
				error = true
				error_obj.working_dates[i] = "La date de début doit être antérieure à la date de fin"
			}
		}
		for (let i = 0; i < this.state.form1.working_hours.length; i++)
		{
			const working_hour = this.state.form1.working_hours[i]
			const month = new Date().getMonth()
			const day = new Date().getDate()
			const start_time = tools.parseTimestamp(day, month, year, Number(working_hour.start_hour), Number(working_hour.start_minute))
			const end_time = tools.parseTimestamp(day, month, year, Number(working_hour.end_hour), Number(working_hour.end_minute))
			if (working_hour.start_hour.length === 0 || working_hour.start_minute.length === 0 || working_hour.end_hour.length === 0 || working_hour.end_minute.length === 0)
			{
				error = true
				error_obj.working_hours_time[i] = "Les horaires d'ouverture de début et de fin doivent être remplis"
			}
			else if (start_time >= end_time)
			{
				error = true
				error_obj.working_hours_time[i] = "L'heure de début doit être antérieure à l'heure de fin"
			}
			if (!working_hour.monday && !working_hour.tuesday && !working_hour.wednesday && !working_hour.thursday && !working_hour.friday && !working_hour.saturday && !working_hour.sunday)
			{
				error = true
				error_obj.working_hours_weekdays[i] = "Vous devez sélectionner au mois 1 jour"
			}
		}
		for (let i = 0; i < this.state.form1.services.length; i++)
		{
			if (this.state.form1.services[i].length === 0)
			{
				error = true
				error_obj.service_workers[i] = "Vous devez sélectionner un service"
			}
		}
		if (error === true)
		{
			error_obj.global = "Il y a une ou plusieur erreur(s) dans le formulaire"
			this.setState({ success: null, error: error_obj, attempt_submit: false })
		}
		return (!error) ? true : false
	}

	handleSubmit = (event) => {
		event.preventDefault()
		if (this.validateForm1())
		{
			let worker = {
				service: this.props.service._id,
				firstname: this.state.form1.firstname,
				lastname: this.state.form1.lastname,
				working_dates: this.state.form1.working_dates,
				working_hours: this.state.form1.working_hours,
				services: this.state.form1.services,
				user: this.props.user._id
			}
			if (this.props.edit_modal === false)
			{
				this.props.insertServiceWorker(worker)
				.then(ret => {
					this.props.closeModal('worker', 'Le prestataire a bien été ajouté')
					this.clearFlash()
				})
				.catch(err => console.log(err))
			}
			else
			{
				worker._id = this.props.edit_modal._id
				this.props.updateServiceWorker(worker)
				.then(ret => {
					this.props.closeModal('worker', 'La prestation a bien été mise à jour')
					this.clearFlash()
				})
				.catch(err => console.log(err))
			}
		}
	}

	render() {
		let days = []
		for (let i = 1; i <= 31; i++)
		{
			let txt = i
			if (txt < 10)
				txt = '0' + txt
			days.push({
				text: txt,
				value: i
			})
		}
		const month_list = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre']
		let months = []
		for (let i = 0; i < 12; i++)
		{
			const txt = month_list[i]
			months.push({
				text: txt,
				value: i + 1
			})
		}
		let hours = []
		for (let i = 0; i <= 23; i++)
		{
			let txt = i
			if (txt < 10)
				txt = '0' + txt
			hours.push({
				text: txt,
				value: i
			})
		}
		let minutes = []
		for (let i = 0; i <= 59; i++)
		{
			let txt = i
			if (txt < 10)
				txt = '0' + txt
			minutes.push({
				text: txt,
				value: i
			})
		}
		let duration_hours = []
		for (let i = 0; i <= 12; i++)
		{
			let txt = i
			if (txt < 10)
				txt = '0' + txt
			duration_hours.push({
				text: txt,
				value: i
			})
		}
		let duration_minutes = []
		for (let i = 0; i <= 59; i += 5)
		{
			let txt = i
			if (txt < 10)
				txt = '0' + txt
			duration_minutes.push({
				text: txt,
				value: i
			})
		}
		const firstname = this.state.form1.firstname
		const lastname = this.state.form1.lastname
		const working_dates = this.state.form1.working_dates
		const working_hours = this.state.form1.working_hours
		const services = this.state.form1.services
		let service_benefits = []
		for (let i = 0; i < this.props.service_benefits.length; i++)
			if (this.props.service_benefits[i].service === this.props.service._id)
				service_benefits.push(this.props.service_benefits[i])
		let working_dates_k = -1
		let working_hours_k = -1
		let worker_services_k = -1
		return (
			<div className="wrapper-content">
				<div className="row">
					<div className="module col s12">
						<form className="module-form" onSubmit={this.handleSubmit}>
							<label className="input-field">
								<p className="error">{this.state.error.worker_lastname}</p>
								<p>Nom :</p>
								<input type="text" value={lastname} onChange={this.handleWorkerLastname} />
							</label>
							<label className="input-field">
								<p className="error">{this.state.error.worker_firstname}</p>
								<p>Prénom :</p>
								<input type="text" value={firstname} onChange={this.handleWorkerFirstname} />
							</label>
							<p className="center x-large">Dates de disponibilités :</p>
							{working_dates.map(({ start_day, start_month, end_day, end_month }) => {
								working_dates_k++
								return (
									<div key={working_dates_k}>
										<p>Période {working_dates_k + 1} :</p>
										<div className="form-group">
											<p className="error">{this.state.error.working_dates[working_dates_k]}</p>
											<div className="col s2">
												<label>
													<p>Jour (début) :</p>
													<select value={start_day} onChange={this.handleStartDayWorker.bind(this, working_dates_k)}>
														<option value=""></option>
														{days.map(({ text, value }) => {
															return (<option key={value} value={value}>{text}</option>)
														})}
													</select>
												</label>
											</div>
											<div className="col s2 offset-s1">
												<label>
													<p>Mois (début) :</p>
													<select value={start_month} onChange={this.handleStartMonthWorker.bind(this, working_dates_k)}>
														<option value=""></option>
														{months.map(({ text, value }) => {
															return (<option key={value} value={value}>{text}</option>)
														})}
													</select>
												</label>
											</div>
											<div className="col s2 offset-s2">
												<label>
													<p>Jour (fin) :</p>
													<select value={end_day} onChange={this.handleEndDayWorker.bind(this, working_dates_k)}>
														<option value=""></option>
														{days.map(({ text, value }) => {
															return (<option key={value} value={value}>{text}</option>)
														})}
													</select>
												</label>
											</div>
											<div className="col s2 offset-s1">
												<label>
													<p>Mois (fin) :</p>
													<select value={end_month} onChange={this.handleEndMonthWorker.bind(this, working_dates_k)}>
														<option value=""></option>
														{months.map(({ text, value }) => {
															return (<option key={value} value={value}>{text}</option>)
														})}
													</select>
												</label>
											</div>
											<div className="center">
												<p className="error">{this.state.error.remove_working_dates[working_dates_k]}</p>
												<button type="button" className="waves-effect waves-light btn red" onClick={this.handleRemoveWorkingDate.bind(this, working_dates_k)}>Supprimer</button>
											</div>
										</div>
									</div>
								)
							})}
							<div className="center">
								<button type="button" onClick={this.handleAddWorkingDate} className="btn-floating btn-large waves-effect waves-light teal">
									<i className="material-icons">add</i>
								</button>
							</div>
							<p className="center x-large">Horaires de travail :</p>
							{working_hours.map(({ monday, tuesday, wednesday, thursday, friday, saturday, sunday, start_hour, start_minute, end_hour, end_minute }) => {
								working_hours_k++
								return (
									<div key={working_hours_k}>
										<p>Créneau {working_hours_k + 1} :</p>
										<div className="form-group">
											<p className="error">{this.state.error.working_hours_weekdays[working_hours_k]}</p>
											<div className="column-10">
												<label>
													<span className="weekdays">L</span>
													<div className="clear"></div>
													<input type="checkbox" checked={monday} onChange={this.handleWeekdayWorker.bind(this, working_hours_k, 'monday')} /><span></span>
												</label>
											</div>
											<div className="column-10">
												<label>
													<span className="weekdays">M</span>
													<div className="clear"></div>
													<input type="checkbox" checked={tuesday} onChange={this.handleWeekdayWorker.bind(this, working_hours_k, 'tuesday')} /><span></span>
												</label>
											</div>
											<div className="column-10">
												<label>
													<span className="weekdays">M</span>
													<div className="clear"></div>
													<input type="checkbox" checked={wednesday} onChange={this.handleWeekdayWorker.bind(this, working_hours_k, 'wednesday')} /><span></span>
												</label>
											</div>
											<div className="column-10">
												<label>
													<span className="weekdays">J</span>
													<div className="clear"></div>
													<input type="checkbox" checked={thursday} onChange={this.handleWeekdayWorker.bind(this, working_hours_k, 'thursday')} /><span></span>
												</label>
											</div>
											<div className="column-10">
												<label>
													<span className="weekdays">V</span>
													<div className="clear"></div>
													<input type="checkbox" checked={friday} onChange={this.handleWeekdayWorker.bind(this, working_hours_k, 'friday')} /><span></span>
												</label>
											</div>
											<div className="column-10">
												<label>
													<span className="weekdays">S</span>
													<div className="clear"></div>
													<input type="checkbox" checked={saturday} onChange={this.handleWeekdayWorker.bind(this, working_hours_k, 'saturday')} /><span></span>
												</label>
											</div>
											<div className="column-10">
												<label>
													<span className="weekdays">D</span>
													<div className="clear"></div>
													<input type="checkbox" checked={sunday} onChange={this.handleWeekdayWorker.bind(this, working_hours_k, 'sunday')} /><span></span>
												</label>
											</div>
											<div className="clear"></div>
											<p className="error">{this.state.error.working_hours_time[working_hours_k]}</p>
											<div className="col s2">
												<label>
													<p>Heure (début) :</p>
													<select value={start_hour} onChange={this.handleStartHourWorker.bind(this, working_hours_k)}>
														<option value=""></option>
														{hours.map(({ text, value }) => {
															return (<option key={value} value={value}>{text}</option>)
														})}
													</select>
												</label>
											</div>
											<div className="col s2 offset-s1">
												<label>
													<p>Minute (début) :</p>
													<select value={start_minute} onChange={this.handleStartMinuteWorker.bind(this, working_hours_k)}>
														<option value=""></option>
														{minutes.map(({ text, value }) => {
															return (<option key={value} value={value}>{text}</option>)
														})}
													</select>
												</label>
											</div>
											<div className="col s2 offset-s2">
												<label>
													<p>Heure (fin) :</p>
													<select value={end_hour} onChange={this.handleEndHourWorker.bind(this, working_hours_k)}>
														<option value=""></option>
														{hours.map(({ text, value }) => {
															return (<option key={value} value={value}>{text}</option>)
														})}
													</select>
												</label>
											</div>
											<div className="col s2 offset-s1">
												<label>
													<p>Minute (fin) :</p>
													<select value={end_minute} onChange={this.handleEndMinuteWorker.bind(this, working_hours_k)}>
														<option value=""></option>
														{minutes.map(({ text, value }) => {
															return (<option key={value} value={value}>{text}</option>)
														})}
													</select>
												</label>
											</div>
											<div className="center">
												<p className="error">{this.state.error.remove_working_hours[working_hours_k]}</p>
												<button type="button" className="waves-effect waves-light btn red" onClick={this.handleRemoveWorkingHour.bind(this, working_hours_k)}>Supprimer</button>
											</div>
										</div>
									</div>
								)
							})}
							<div className="center">
								<button type="button" onClick={this.handleAddWorkingHour} className="btn-floating btn-large waves-effect waves-light teal">
									<i className="material-icons">add</i>
								</button>
							</div>
							<p className="center x-large">Prestations :</p>
							{services.map(service => {
								worker_services_k++
								return (
									<div key={worker_services_k} className="row">
										<div className="col s6 offset-s3">
											<label>
												<p>Prestation {worker_services_k + 1} :</p>
												<p className="error">{this.state.error.service_workers[worker_services_k]}</p>
												<select value={service} onChange={this.handleServiceWorker.bind(this, worker_services_k)}>
													<option value=""></option>
													{service_benefits.map(({ _id, translations }) => {
														let subtitle = ""
														for (let i = 0; i < translations.length; i++)
														{
															if (translations[i].lang === this.props.lang_default)
															{
																subtitle = translations[i].subtitle
																break
															}
														}
														return (<option key={_id} value={_id}>{subtitle}</option>)
													})}
												</select>
											</label>
										</div>
										<div className="col s3 center">
											<p className="error">{this.state.error.remove_service_workers[worker_services_k]}</p>
											<button type="button" className="waves-effect waves-light btn red" onClick={this.handleRemoveServiceWorker.bind(this, worker_services_k)}>Supprimer</button>
										</div>
									</div>
								)
							})}
							<div className="center">
								<p className="error">{this.state.error.add_service_worker}</p>
								<button type="button" onClick={this.handleAddServiceWorker} className="btn-floating btn-large waves-effect waves-light teal">
									<i className="material-icons">add</i>
								</button>
							</div>
							<div className="center">
								<p className="error">{this.state.error.global}</p>
								<button type="submit" className="waves-effect waves-light btn">Enregistrer</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		)
	}
}

export default Workers

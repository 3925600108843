module.exports = {
	parseDate: function(timestamp, complete = true) {
		if (timestamp === null)
			return null
		const date = new Date(timestamp * 1000)
		let day = date.getDate()
		let month = date.getMonth() + 1
		let year = date.getFullYear()
		let hours = date.getHours()
		let minutes = date.getMinutes()
		let seconds = date.getSeconds()
		if (day < 10)
			day = '0' + day
		if (month < 10)
			month = '0' + month
		if (hours < 10)
			hours = '0' + hours
		if (minutes < 10)
			minutes = '0' + minutes
		if (seconds < 10)
			seconds = '0' + seconds
		if (complete === true)
			return day + "/" + month + "/" + year + " " + hours + ":" + minutes + ":" + seconds
		else
			return day + "/" + month + "/" + year
	},

	parseTimestamp: function(day, month, year, hour = 0, minute = 0, second = 0) {
		return Math.floor(new Date(year, month, day, hour, minute, second).getTime() / 1000)
	},

	getTimestamp: function() {
		return Math.floor(new Date().getTime() / 1000)
	},

	queryString: function(baseurl, params) {
		const keys = Object.keys(params)
		let url = baseurl + '?'
		let a = false
		for (let i = 0; i < keys.length; i++)
		{
			const key = keys[i]
			if (Array.isArray(params[key]))
			{
				let value = ""
				let b = false
				for (let j = 0; j < params[key].length; j++)
				{
					if (!b)
						b = true
					else
						value += ","
					value += params[key][j]
				}
				if (!a)
					a = true
				else
					url += "&"
				url += key + "=" + value
			}
			else if (params[key])
			{
				if (!a)
					a = true
				else
					url += "&"
				url += key + '=' + params[key]
			}
		}
		if (url.length > 2000)
		{
			console.log("error: query string is longer than 2000 chars, can not send request to url", baseurl)
			return null
		}
		return url
	},

	testEmail: function(email) {
		if (/^[\w!#$%&'*+-/=?^_`{|}~]+(\.[\w!#$%&'*+-/=?^_`{|}~]+)*@((([-\w]+\.)+[a-zA-Z]{2,4})|(([0-9]{1,3}\.){3}[0-9]{1,3}))$/.test(email) === false)
			return false
		else
			return true
	},

	clone: function(obj) {
		return JSON.parse(JSON.stringify(obj))
	},

	generateSlug: function(s, opt) {
		s = String(s);
		opt = Object(opt);

		let defaults = {
			'delimiter': '-',
			'limit': undefined,
			'lowercase': true,
			'replacements': {},
			'transliterate': (typeof(XRegExp) === 'undefined') ? true : false
		};

		// Merge options
		for (let k in defaults) {
			if (!opt.hasOwnProperty(k)) {
				opt[k] = defaults[k];
			}
		}

		let char_map = {
			// Latin
			'À': 'A', 'Á': 'A', 'Â': 'A', 'Ã': 'A', 'Ä': 'A', 'Å': 'A', 'Æ': 'AE', 'Ç': 'C', 
			'È': 'E', 'É': 'E', 'Ê': 'E', 'Ë': 'E', 'Ì': 'I', 'Í': 'I', 'Î': 'I', 'Ï': 'I', 
			'Ð': 'D', 'Ñ': 'N', 'Ò': 'O', 'Ó': 'O', 'Ô': 'O', 'Õ': 'O', 'Ö': 'O', 'Ő': 'O', 
			'Ø': 'O', 'Ù': 'U', 'Ú': 'U', 'Û': 'U', 'Ü': 'U', 'Ű': 'U', 'Ý': 'Y', 'Þ': 'TH', 
			'ß': 'ss', 
			'à': 'a', 'á': 'a', 'â': 'a', 'ã': 'a', 'ä': 'a', 'å': 'a', 'æ': 'ae', 'ç': 'c', 
			'è': 'e', 'é': 'e', 'ê': 'e', 'ë': 'e', 'ì': 'i', 'í': 'i', 'î': 'i', 'ï': 'i', 
			'ð': 'd', 'ñ': 'n', 'ò': 'o', 'ó': 'o', 'ô': 'o', 'õ': 'o', 'ö': 'o', 'ő': 'o', 
			'ø': 'o', 'ù': 'u', 'ú': 'u', 'û': 'u', 'ü': 'u', 'ű': 'u', 'ý': 'y', 'þ': 'th', 
			'ÿ': 'y',

			// NE PAS DÉCOMMENTER
			// SINON ÇA FAIT BUGUER L'IMPORT DU FICHIER DANS REACT
			// MERCI JAVASCRIPT :) :) :) :)

			// // Latin symbols
			// '©': '(c)',

			// // Greek
			// 'Α': 'A', 'Β': 'B', 'Γ': 'G', 'Δ': 'D', 'Ε': 'E', 'Ζ': 'Z', 'Η': 'H', 'Θ': '8',
			// 'Ι': 'I', 'Κ': 'K', 'Λ': 'L', 'Μ': 'M', 'Ν': 'N', 'Ξ': '3', 'Ο': 'O', 'Π': 'P',
			// 'Ρ': 'R', 'Σ': 'S', 'Τ': 'T', 'Υ': 'Y', 'Φ': 'F', 'Χ': 'X', 'Ψ': 'PS', 'Ω': 'W',
			// 'Ά': 'A', 'Έ': 'E', 'Ί': 'I', 'Ό': 'O', 'Ύ': 'Y', 'Ή': 'H', 'Ώ': 'W', 'Ϊ': 'I',
			// 'Ϋ': 'Y',
			// 'α': 'a', 'β': 'b', 'γ': 'g', 'δ': 'd', 'ε': 'e', 'ζ': 'z', 'η': 'h', 'θ': '8',
			// 'ι': 'i', 'κ': 'k', 'λ': 'l', 'μ': 'm', 'ν': 'n', 'ξ': '3', 'ο': 'o', 'π': 'p',
			// 'ρ': 'r', 'σ': 's', 'τ': 't', 'υ': 'y', 'φ': 'f', 'χ': 'x', 'ψ': 'ps', 'ω': 'w',
			// 'ά': 'a', 'έ': 'e', 'ί': 'i', 'ό': 'o', 'ύ': 'y', 'ή': 'h', 'ώ': 'w', 'ς': 's',
			// 'ϊ': 'i', 'ΰ': 'y', 'ϋ': 'y', 'ΐ': 'i',

			// // Turkish
			// 'Ş': 'S', 'İ': 'I', 'Ç': 'C', 'Ü': 'U', 'Ö': 'O', 'Ğ': 'G',
			// 'ş': 's', 'ı': 'i', 'ç': 'c', 'ü': 'u', 'ö': 'o', 'ğ': 'g', 

			// // Russian
			// 'А': 'A', 'Б': 'B', 'В': 'V', 'Г': 'G', 'Д': 'D', 'Е': 'E', 'Ё': 'Yo', 'Ж': 'Zh',
			// 'З': 'Z', 'И': 'I', 'Й': 'J', 'К': 'K', 'Л': 'L', 'М': 'M', 'Н': 'N', 'О': 'O',
			// 'П': 'P', 'Р': 'R', 'С': 'S', 'Т': 'T', 'У': 'U', 'Ф': 'F', 'Х': 'H', 'Ц': 'C',
			// 'Ч': 'Ch', 'Ш': 'Sh', 'Щ': 'Sh', 'Ъ': '', 'Ы': 'Y', 'Ь': '', 'Э': 'E', 'Ю': 'Yu',
			// 'Я': 'Ya',
			// 'а': 'a', 'б': 'b', 'в': 'v', 'г': 'g', 'д': 'd', 'е': 'e', 'ё': 'yo', 'ж': 'zh',
			// 'з': 'z', 'и': 'i', 'й': 'j', 'к': 'k', 'л': 'l', 'м': 'm', 'н': 'n', 'о': 'o',
			// 'п': 'p', 'р': 'r', 'с': 's', 'т': 't', 'у': 'u', 'ф': 'f', 'х': 'h', 'ц': 'c',
			// 'ч': 'ch', 'ш': 'sh', 'щ': 'sh', 'ъ': '', 'ы': 'y', 'ь': '', 'э': 'e', 'ю': 'yu',
			// 'я': 'ya',

			// // Ukrainian
			// 'Є': 'Ye', 'І': 'I', 'Ї': 'Yi', 'Ґ': 'G',
			// 'є': 'ye', 'і': 'i', 'ї': 'yi', 'ґ': 'g',

			// // Czech
			// 'Č': 'C', 'Ď': 'D', 'Ě': 'E', 'Ň': 'N', 'Ř': 'R', 'Š': 'S', 'Ť': 'T', 'Ů': 'U', 
			// 'Ž': 'Z', 
			// 'č': 'c', 'ď': 'd', 'ě': 'e', 'ň': 'n', 'ř': 'r', 'š': 's', 'ť': 't', 'ů': 'u',
			// 'ž': 'z', 

			// // Polish
			// 'Ą': 'A', 'Ć': 'C', 'Ę': 'e', 'Ł': 'L', 'Ń': 'N', 'Ó': 'o', 'Ś': 'S', 'Ź': 'Z', 
			// 'Ż': 'Z', 
			// 'ą': 'a', 'ć': 'c', 'ę': 'e', 'ł': 'l', 'ń': 'n', 'ó': 'o', 'ś': 's', 'ź': 'z',
			// 'ż': 'z',

			// // Latvian
			// 'Ā': 'A', 'Č': 'C', 'Ē': 'E', 'Ģ': 'G', 'Ī': 'i', 'Ķ': 'k', 'Ļ': 'L', 'Ņ': 'N', 
			// 'Š': 'S', 'Ū': 'u', 'Ž': 'Z', 
			// 'ā': 'a', 'č': 'c', 'ē': 'e', 'ģ': 'g', 'ī': 'i', 'ķ': 'k', 'ļ': 'l', 'ņ': 'n',
			// 'š': 's', 'ū': 'u', 'ž': 'z'
		}

		// Make custom replacements
		for (let k in opt.replacements) {
			s = s.replace(RegExp(k, 'g'), opt.replacements[k]);
		}

		// Transliterate characters to ASCII
		if (opt.transliterate) {
			for (let k in char_map) {
				s = s.replace(RegExp(k, 'g'), char_map[k]);
			}
		}

		// Replace non-alphanumeric characters with our delimiter
		let alnum = RegExp('[^a-z0-9]+', 'ig');
		// var alnum = (typeof(XRegExp) === 'undefined') ? RegExp('[^a-z0-9]+', 'ig') : XRegExp('[^\\p{L}\\p{N}]+', 'ig');
		s = s.replace(alnum, opt.delimiter);

		// Remove duplicate delimiters
		s = s.replace(RegExp('[' + opt.delimiter + ']{2,}', 'g'), opt.delimiter);

		// Truncate slug to max. characters
		s = s.substring(0, opt.limit);

		// Remove delimiter from ends
		s = s.replace(RegExp('(^' + opt.delimiter + '|' + opt.delimiter + '$)', 'g'), '');

		return opt.lowercase ? s.toLowerCase() : s;
	},

	parseCSV: function(file, delimiter = ';')
	{
		const lines = file.replace("\r\n", "\n").split("\n")
		let csv = []
		let c = 0
		const keys = lines[0].split(delimiter)
		let error = false
		let save = null
		for (let i = 1; i < lines.length; i++)
		{
			const line = lines[i]
			if (line.length === 0)
				continue
			let data = line.split(delimiter)
			if (save !== null)
			{
				const last = save.pop()
				data[0] = last + "\n" + data[0]
				data = save.concat(data)
				save = null
			}
			if (data.length !== keys.length)
			{
				if (data.length < keys.length)
				{
					save = data
					continue
				}
				else
				{
					error = i
					break
				}
			}
			csv.push({})
			for (let j = 0; j < keys.length; j++)
			{
				const key = this.generateSlug(keys[j], { delimiter: '_' })
				csv[c][key] = data[j]
			}
			c++
		}
		if (error === false)
			return csv
		else
			return error
	},

	default_error_msg: function(msg, code)
	{
		return 'Erreur: ' + msg + '. Contactez-nous pour résoudre le problème (code erreur: ' + code + ')'
	}
}
import React, { Component } from 'react'
import Loader from '../UI/Loader'
import LangSelector from '../UI/LangSelector'
import M from 'materialize-css'
import tools from '../../tools'
import '../Core/Main.css'
import EmptyFolder from '../UI/EmptyFolder'

class ServiceVente extends Component {
	constructor(props) {
		super(props)
		this.state = this._initState()
		this.length_max = 50
		this.location_length_max = 30
		this.props.updateNavbarTitle('Services')
	}

	componentDidUpdate(prevProps, prevState) {
		const elems = document.querySelectorAll('select')
		M.FormSelect.init(elems)
		if (this.state.init_dropdown === false)
		{
			const dropdowns = document.querySelectorAll('.dropdown-trigger')
			if (dropdowns.length > 0)
			{
				M.Dropdown.init(dropdowns)
				this.setState({ init_dropdown: true })
			}
		}
	}

	loadIcons = () => {
		this.props.fetchIcons()
		.then(icons => {
			this.setState({ icons: icons })
		})
		.catch(err => {
			this.setErrorMsg("error_fetch_services", 1)
			console.log(err)
		})
	}

	loadTheme = () => {
		this.props.fetchTheme()
		.then(theme => {
			this.setState({ theme: theme })
		})
		.catch(err => {
			this.setErrorMsg("error_fetch_services", 2)
			console.log(err)
		})
	}

	_initState = () => {
		let state = {
			edit: null,
			create: false,
			click_modal: 0,
			attempt_submit: false,
			services: undefined,
			init_dropdown: false,
			delete: null,
			pages: [
				{ name: 'Services', href: '/services', current: false }
			],
			form1: {
				duration_hour: 0,
				duration_minute: 0,
				interval_hour: 0,
				interval_minute: 0,
				limit_end: 0,
				open_dates: [{
					start_day: '',
					start_month: '',
					end_day: '',
					end_month: ''
				}],
				open_hours: [{
					monday: false,
					tuesday: false,
					wednesday: false,
					thursday: false,
					friday: false,
					saturday: false,
					sunday: false,
					start_hour: '',
					start_minute: '',
					end_hour: '',
					end_minute: ''
				}],
				translations: []
			},
			show_banner: false,
			banner_content: null,
			error: this._initErrors(),
			success: this._initSuccess()
		}
		if (this.props.service.duration_hour)
			state.form1.duration_hour = this.props.service.duration_hour
		if (this.props.service.duration_minute)
			state.form1.duration_minute = this.props.service.duration_minute
		if (this.props.service.interval_hour)
			state.form1.interval_hour = this.props.service.interval_hour
		if (this.props.service.interval_minute)
			state.form1.interval_minute = this.props.service.interval_minute
		if (this.props.service.limit_end)
			state.form1.limit_end = this.props.service.limit_end
		if (this.props.service.open_dates)
			state.form1.open_dates = this.props.service.open_dates
		if (this.props.service.open_hours)
			state.form1.open_hours = this.props.service.open_hours
		if (this.props.service.translations)
		{
			const translations = tools.clone(this.props.service.translations)
			for (let i = 0; i < translations.length; i++)
			{
				if (!translations[i].location)
					translations[i].location = ""
			}
			state.form1.translations = translations
		}
		return state
	}

	_initSuccess = () => {
		const success = {
			global: null,
			products: null
		}
		return success
	}

	_initErrors = () => {
		const errors = {
			global: null,
			service: null,
			name: null,
			title: null,
			cover: null,
			type: null,
			products: null,
			open_dates: [],
			open_hours_weekdays: [],
			open_hours_time: [],
			remove_open_dates: [],
			remove_open_hours: []
		}
		return errors
	}

	setErrorMsg = (key, msg, id = null) => {
		if (key === "error_fetch_service_products")
		{
			key = "global"
			msg = tools.default_error_msg("Impossible de récupérer les produits", msg)
		}
		else if (key === "error_delete_service_product")
		{
			key = "products"
			msg = tools.default_error_msg("Impossible de supprimer une produit", msg)
		}
		let error = this._initErrors()
		if (id !== null)
			error[key][id] = msg
		else
			error[key] = msg
		this.setState({ success: this._initSuccess(), error: error, show_banner: true, banner_content: msg })
	}

	setSuccessMsg = (key, msg) => {
		const error = this._initErrors()
		let success = this.state.success
		success[key] = msg
		this.setState({ success: success, error: error, show_banner: true, banner_content: msg })
	}

	clearFlash = () => {
		const error = this._initErrors()
		const success = this._initSuccess()
		this.setState({ error: error, success: success })
	}

	closeBanner = () => {
		this.setState({ show_banner: false })
	}

	classNames = (...classes) => {
		return classes.filter(Boolean).join(' ')
	}

	handleDurationHour = (event) => {
		const form1 = {
			...this.state.form1,
			duration_hour: event.target.value
		}
		this.setState({ form1: form1 })
	}

	handleDurationMinute = (event) => {
		const form1 = {
			...this.state.form1,
			duration_minute: event.target.value
		}
		this.setState({ form1: form1 })
	}

	handleIntervalHour = (event) => {
		const form1 = {
			...this.state.form1,
			interval_hour: event.target.value
		}
		this.setState({ form1: form1 })
	}

	handleIntervalMinute = (event) => {
		const form1 = {
			...this.state.form1,
			interval_minute: event.target.value
		}
		this.setState({ form1: form1 })
	}

	handleLimitEnd = (event) => {
		const form1 = {
			...this.state.form1,
			limit_end: event.target.value
		}
		this.setState({ form1: form1 })
	}

	handleStartDay = (open_dates_k, event) => {
		const open_dates = this.state.form1.open_dates
		open_dates[open_dates_k].start_day = event.target.value
		const form1 = {
			...this.state.form1,
			open_dates: open_dates
		}
		this.setState({ form1: form1 })
	}

	handleStartMonth = (open_dates_k, event) => {
		const open_dates = this.state.form1.open_dates
		open_dates[open_dates_k].start_month = event.target.value
		const form1 = {
			...this.state.form1,
			open_dates: open_dates
		}
		this.setState({ form1: form1 })
	}

	handleEndDay = (open_dates_k, event) => {
		const open_dates = this.state.form1.open_dates
		open_dates[open_dates_k].end_day = event.target.value
		const form1 = {
			...this.state.form1,
			open_dates: open_dates
		}
		this.setState({ form1: form1 })
	}

	handleEndMonth = (open_dates_k, event) => {
		const open_dates = this.state.form1.open_dates
		open_dates[open_dates_k].end_month = event.target.value
		const form1 = {
			...this.state.form1,
			open_dates: open_dates
		}
		this.setState({ form1: form1 })
	}

	handleAddOpenDate = () => {
		let open_dates = this.state.form1.open_dates
		open_dates.push({
			start_day: '',
			start_month: '',
			end_day: '',
			end_month: ''
		})
		const form1 = {
			...this.state.form1,
			open_dates: open_dates
		}
		this.clearFlash()
		this.setState({ form1: form1 })
	}

	handleRemoveOpenDate = (open_dates_k) => {
		if (this.state.form1.open_dates.length <= 1)
			this.setErrorMsg('remove_open_dates', 'Vous devez au moins avoir 1 période d\'ouverture', open_dates_k)
		else
		{
			let open_dates = this.state.form1.open_dates
			open_dates.splice(open_dates_k, 1)
			const form1 = {
				...this.state.form1,
				open_dates: open_dates
			}
			this.clearFlash()
			this.setState({ form1: form1 })
		}
	}

	handleWeekday = (open_hours_k, day) => {
		const open_hours = this.state.form1.open_hours
		open_hours[open_hours_k][day] = (open_hours[open_hours_k][day]) ? false : true
		const form1 = {
			...this.state.form1,
			open_hours: open_hours
		}
		this.setState({ form1: form1 })
	}

	handleStartHour = (open_hours_k, event) => {
		const open_hours = this.state.form1.open_hours
		open_hours[open_hours_k].start_hour = event.target.value
		const form1 = {
			...this.state.form1,
			open_hours: open_hours
		}
		this.setState({ form1: form1 })
	}

	handleStartMinute = (open_hours_k, event) => {
		const open_hours = this.state.form1.open_hours
		open_hours[open_hours_k].start_minute = event.target.value
		const form1 = {
			...this.state.form1,
			open_hours: open_hours
		}
		this.setState({ form1: form1 })
	}

	handleEndHour = (open_hours_k, event) => {
		const open_hours = this.state.form1.open_hours
		open_hours[open_hours_k].end_hour = event.target.value
		const form1 = {
			...this.state.form1,
			open_hours: open_hours
		}
		this.setState({ form1: form1 })
	}

	handleEndMinute = (open_hours_k, event) => {
		const open_hours = this.state.form1.open_hours
		open_hours[open_hours_k].end_minute = event.target.value
		const form1 = {
			...this.state.form1,
			open_hours: open_hours
		}
		this.setState({ form1: form1 })
	}

	handleAddOpenHour = () => {
		let open_hours = this.state.form1.open_hours
		open_hours.push({
			monday: false,
			tuesday: false,
			wednesday: false,
			thursday: false,
			friday: false,
			saturday: false,
			sunday: false,
			start_hour: '',
			start_minute: '',
			end_hour: '',
			end_minute: ''
		})
		const form1 = {
			...this.state.form1,
			open_hours: open_hours
		}
		this.clearFlash()
		this.setState({ form1: form1 })
	}

	handleRemoveOpenHour = (open_hours_k) => {
		if (this.state.form1.open_hours.length <= 1)
			this.setErrorMsg('remove_open_hours', 'Vous devez au moins avoir 1 horaire d\'ouverture', open_hours_k)
		else
		{
			let open_hours = this.state.form1.open_hours
			open_hours.splice(open_hours_k, 1)
			const form1 = {
				...this.state.form1,
				open_hours: open_hours
			}
			this.clearFlash()
			this.setState({ form1: form1 })
		}
	}

	handleLocation = (event) => {
		let found = false
		const translations = this.state.form1.translations
		for (let i = 0; i < translations.length; i++)
		{
			if (translations[i].lang === this.props.current_lang)
			{
				found = true
				translations[i].location = event.target.value
				break
			}
		}
		if (!found)
		{
			translations.push({
				lang: this.props.current_lang,
				location: event.target.value
			})
		}
		const form1 = {
			...this.state.form1,
			translations: translations
		}
		this.setState({ form1: form1 })
	}

	_validateForm1 = () => {
		let error_obj = this._initErrors()
		let error = false
		const year = new Date().getFullYear()
		let found_default_location = false
		const translations = this.state.form1.translations
		for (let i = 0; i < translations.length; i++)
		{
			const lang = translations[i].lang
			const location = translations[i].location
			if (location.length === 0 && lang === this.props.lang_default)
			{
				error = true
				error_obj.location = "Le lieu ne doit pas être vide (version " + lang.toUpperCase() + ")"
			}
			else if (location.length > this.location_length_max)
			{
				error = true
				error_obj.location = "Le lieu ne doit pas dépasser " + this.location_length_max + " caractères (version " + lang.toUpperCase() + ")"
			}
			else if (lang === this.props.lang_default)
				found_default_location = true
		}
		if (!found_default_location && !error_obj.location)
		{
			error = true
			error_obj.location = 'Le lieu ne doit pas être vide (version ' + this.props.lang_default.toUpperCase() + ')'
		}
		for (let i = 0; i < this.state.form1.open_dates.length; i++)
		{
			const open_date = this.state.form1.open_dates[i]
			const start_time = tools.parseTimestamp(Number(open_date.start_day), Number(open_date.start_month), year)
			const end_time = tools.parseTimestamp(Number(open_date.end_day) + 1, Number(open_date.end_month), year)
			if (open_date.start_day.length === 0 || open_date.start_month.length === 0 || open_date.end_day.length === 0 || open_date.end_month.length === 0)
			{
				error = true
				error_obj.open_dates[i] = "Les dates d'ouverture de début et de fin doivent être remplis"
			}
			else if (start_time >= end_time)
			{
				error = true
				error_obj.open_dates[i] = "La date de début doit être antérieure à la date de fin"
			}
		}
		for (let i = 0; i < this.state.form1.open_hours.length; i++)
		{
			const open_hour = this.state.form1.open_hours[i]
			const month = new Date().getMonth()
			const day = new Date().getDate()
			const start_time = tools.parseTimestamp(day, month, year, Number(open_hour.start_hour), Number(open_hour.start_minute))
			const end_time = tools.parseTimestamp(day, month, year, Number(open_hour.end_hour), Number(open_hour.end_minute))
			if (open_hour.start_hour.length === 0 || open_hour.start_minute.length === 0 || open_hour.end_hour.length === 0 || open_hour.end_minute.length === 0)
			{
				error = true
				error_obj.open_hours_time[i] = "Les horaires d'ouverture de début et de fin doivent être remplis"
			}
			else if (start_time >= end_time)
			{
				error = true
				error_obj.open_hours_time[i] = "L'heure de début doit être antérieure à l'heure de fin"
			}
			if (!open_hour.monday && !open_hour.tuesday && !open_hour.wednesday && !open_hour.thursday && !open_hour.friday && !open_hour.saturday && !open_hour.sunday)
			{
				error = true
				error_obj.open_hours_weekdays[i] = "Vous devez sélectionner au mois 1 jour"
			}
		}
		if (error === true)
		{
			error_obj.global = "Il y a une ou plusieurs erreur(s) dans le formulaire"
			this.setState({ success: null, error: error_obj })
		}
		return (!error) ? true : false
	}

	handleSubmit = (event) => {
		event.preventDefault()
		if (this._validateForm1())
		{
			let service = {
				id: this.props.service._id,
				duration_hour: this.state.form1.duration_hour,
				duration_minute: this.state.form1.duration_minute,
				interval_hour: this.state.form1.interval_hour,
				interval_minute: this.state.form1.interval_minute,
				limit_end: this.state.form1.limit_end,
				translations: this.state.form1.translations,
				open_dates: this.state.form1.open_dates,
				open_hours: this.state.form1.open_hours
			}
			this.props.updateServiceDuration(service)
			.then(ret => {
				this.setSuccessMsg('global', 'Les créneaux ont été mis à jours')
			})
			.catch(err => {
				console.log(err)
			})
		}
	}

	render() {
		let sendbtn = (<div className="center"><button type="submit" className="waves-effect waves-light btn">Enregistrer</button></div>)
		if (this.state.attempt_submit === true)
		{
			sendbtn = (
				<div className="center">
					<Loader />
				</div>
			)
		}
		const products = (this.props.service_products) ? this.props.service_products : []
		let product_content = ""
		if (!products || !products.length)
		(
			product_content = (
				<div>
					<EmptyFolder
						emptyFolderSubtitle="Il n'y pas encore de produit"
					/>
					<button type="button" onClick={this.props.createModal.bind(this, 'product')} className="waves-effect waves-light btn">Configurer un produit</button>
				</div>
			)
		)
		else 
		{
			product_content = (
				<div>
					<LangSelector lang={this.props.current_lang} updateLang={this.props.updateLang} />
					<p className="success center">{this.state.success.products}</p>
					<p className="error center">{this.state.error.products}</p>
					<table className="reviews striped responsive-table">
						<thead>
							<tr>
								<td>Titre</td>
								<td>Prix</td>
								<td>Actions</td>
							</tr>
						</thead>
						<tbody>
							{products.map(({ _id, price, translations }) => {
								let subtitle = ""
								for (let i = 0; i < translations.length; i++)
								{
									if (translations[i].lang === this.props.current_lang)
									{
										subtitle = translations[i].subtitle
										break
									}
								}
								return (
									<tr key={_id}>
										<td>{subtitle}</td>
										<td>{price} €</td>
										<td>
											<button type="button" onClick={this.props.editModal.bind(this, 'product', _id)} className="btn-floating waves-effect waves-light teal"><i className="material-icons">mode_edit</i></button>
											<button type="button" onClick={this.props.deleteObject.bind(this, 'product', _id)} className="btn-floating waves-effect waves-light red accent-2"><i className="material-icons">clear</i></button>
										</td>
									</tr>
								)
							})}
						</tbody>
					</table>
					<button type="button" onClick={this.props.createModal.bind(this, 'product')} className="waves-effect waves-light btn">Ajouter un produit</button>
				</div>
			)
		}
		const duration_hour = this.state.form1.duration_hour
		const duration_minute = this.state.form1.duration_minute
		const interval_hour = this.state.form1.interval_hour
		const interval_minute = this.state.form1.interval_minute
		const limit_end = this.state.form1.limit_end
		const open_dates = this.state.form1.open_dates
		const open_hours = this.state.form1.open_hours
		let location = ""
		for (let i = 0; i < this.state.form1.translations.length; i++)
		{
			if (this.state.form1.translations[i].lang === this.props.current_lang)
			{
				location = this.state.form1.translations[i].location
				break
			}
		}
		let open_dates_k = -1
		let open_hours_k = -1
		return (
			<>
				<div className="row">
					<div className="module col s12">
						<form className="module-form" onSubmit={this.handleSubmit}>
							<LangSelector lang={this.props.current_lang} updateLang={this.props.updateLang} />
							<h2>Paramètres des créneaux</h2>
							<div className="row">
								<p className="error">{this.state.error.duration}</p>
								<p>Durée :</p>
								<div className="col s2">
									<label>
										<p>Heures (durée) :</p>
										<input type="number" value={duration_hour} onChange={this.handleDurationHour} min="0" max="12" />
									</label>
								</div>
								<div className="col s2 offset-s1">
									<label>
										<p>Minutes (durée) :</p>
										<input type="number" value={duration_minute} onChange={this.handleDurationMinute} min="0" max="59" />
									</label>
								</div>
							</div>
							<div className="row">
								<p className="error">{this.state.error.interval}</p>
								<p>Intervalle :</p>
								<div className="col s2">
									<label>
										<p>Heures (durée) :</p>
										<input type="number" value={interval_hour} onChange={this.handleIntervalHour} min="0" max="12" />
									</label>
								</div>
								<div className="col s2 offset-s1">
									<label>
										<p>Minutes (durée) :</p>
										<input type="number" value={interval_minute} onChange={this.handleIntervalMinute} min="0" max="59" />
									</label>
								</div>
							</div>
							<div className="row">
								<p className="error">{this.state.error.interval}</p>
								<p>Empêcher les réservations (x) heures avant le début du créneau :</p>
								<div className="col s3">
									<label>
										<p>Nombre d'heures :</p>
										<input type="number" value={limit_end} onChange={this.handleLimitEnd} min="0" max="300" />
									</label>
								</div>
							</div>
							<div className="row">
								<p className="error">{this.state.error.interval}</p>
								<p>Lieu pour récupérer la commande (version {this.props.current_lang.toUpperCase()}) :</p>
								<div className="col s3">
									<label>
										<p>Restaurant, bar / snack, réception...</p>
										<input type="text" value={location} onChange={this.handleLocation} min="0" max="300" />
									</label>
								</div>
							</div>
							<p className="center x-large">Dates d'ouverture :</p>
							{open_dates.map(({ start_day, start_month, end_day, end_month }) => {
								open_dates_k++
								return (
									<div key={open_dates_k}>
										<p>Période {open_dates_k + 1} :</p>
										<div className="form-group">
											<p className="error">{this.state.error.open_dates[open_dates_k]}</p>
											<div className="col s2">
												<label>
													<p>Jour (début) :</p>
													<input type="number" value={start_day} onChange={this.handleStartDay.bind(this, open_dates_k)} min="1" max="31" />
												</label>
											</div>
											<div className="col s2 offset-s1">
												<label>
													<p>Mois (début) :</p>
													<input type="number" value={start_month} onChange={this.handleStartMonth.bind(this, open_dates_k)} min="1" max="12" />
												</label>
											</div>
											<div className="col s2 offset-s2">
												<label>
													<p>Jour (fin) :</p>
													<input type="number" value={end_day} onChange={this.handleEndDay.bind(this, open_dates_k)} min="1" max="31" />
												</label>
											</div>
											<div className="col s2 offset-s1">
												<label>
													<p>Mois (fin) :</p>
													<input type="number" value={end_month} onChange={this.handleEndMonth.bind(this, open_dates_k)} min="1" max="12" />
												</label>
											</div>
											<div className="center">
												<p className="error">{this.state.error.remove_open_dates[open_dates_k]}</p>
												<button type="button" className="waves-effect waves-light btn red" onClick={this.handleRemoveOpenDate.bind(this, open_dates_k)}>Supprimer</button>
											</div>
										</div>
									</div>
								)
							})}
							<div className="center">
								<button type="button" onClick={this.handleAddOpenDate} className="btn-floating btn-large waves-effect waves-light teal">
									<i className="material-icons">add</i>
								</button>
							</div>
							<p className="center x-large">Horaires d'ouverture :</p>
							{open_hours.map(({ monday, tuesday, wednesday, thursday, friday, saturday, sunday, start_hour, start_minute, end_hour, end_minute }) => {
								open_hours_k++
								return (
									<div key={open_hours_k}>
										<p>Créneau {open_hours_k + 1} :</p>
										<div className="form-group">
											<p className="error">{this.state.error.open_hours_weekdays[open_hours_k]}</p>
											<div className="column-10">
												<label>
													<span className="weekdays">L</span>
													<div className="clear"></div>
													<input type="checkbox" checked={monday} onChange={this.handleWeekday.bind(this, open_hours_k, 'monday')} /><span></span>
												</label>
											</div>
											<div className="column-10">
												<label>
													<span className="weekdays">M</span>
													<div className="clear"></div>
													<input type="checkbox" checked={tuesday} onChange={this.handleWeekday.bind(this, open_hours_k, 'tuesday')} /><span></span>
												</label>
											</div>
											<div className="column-10">
												<label>
													<span className="weekdays">M</span>
													<div className="clear"></div>
													<input type="checkbox" checked={wednesday} onChange={this.handleWeekday.bind(this, open_hours_k, 'wednesday')} /><span></span>
												</label>
											</div>
											<div className="column-10">
												<label>
													<span className="weekdays">J</span>
													<div className="clear"></div>
													<input type="checkbox" checked={thursday} onChange={this.handleWeekday.bind(this, open_hours_k, 'thursday')} /><span></span>
												</label>
											</div>
											<div className="column-10">
												<label>
													<span className="weekdays">V</span>
													<div className="clear"></div>
													<input type="checkbox" checked={friday} onChange={this.handleWeekday.bind(this, open_hours_k, 'friday')} /><span></span>
												</label>
											</div>
											<div className="column-10">
												<label>
													<span className="weekdays">S</span>
													<div className="clear"></div>
													<input type="checkbox" checked={saturday} onChange={this.handleWeekday.bind(this, open_hours_k, 'saturday')} /><span></span>
												</label>
											</div>
											<div className="column-10">
												<label>
													<span className="weekdays">D</span>
													<div className="clear"></div>
													<input type="checkbox" checked={sunday} onChange={this.handleWeekday.bind(this, open_hours_k, 'sunday')} /><span></span>
												</label>
											</div>
											<div className="clear"></div>
											<p className="error">{this.state.error.open_hours_time[open_hours_k]}</p>
											<div className="col s2">
												<label>
													<p>Heure (début) :</p>
													<input type="number" value={start_hour} onChange={this.handleStartHour.bind(this, open_hours_k)} min="0" max="23" />
												</label>
											</div>
											<div className="col s2 offset-s1">
												<label>
													<p>Minute (début) :</p>
													<input type="number" value={start_minute} onChange={this.handleStartMinute.bind(this, open_hours_k)} min="0" max="59" />
												</label>
											</div>
											<div className="col s2 offset-s2">
												<label>
													<p>Heure (fin) :</p>
													<input type="number" value={end_hour} onChange={this.handleEndHour.bind(this, open_hours_k)} min="0" max="23" />
												</label>
											</div>
											<div className="col s2 offset-s1">
												<label>
													<p>Minute (fin) :</p>
													<input type="number" value={end_minute} onChange={this.handleEndMinute.bind(this, open_hours_k)} min="0" max="59" />
												</label>
											</div>
											<div className="center">
												<p className="error">{this.state.error.remove_open_hours[open_hours_k]}</p>
												<button type="button" className="waves-effect waves-light btn red" onClick={this.handleRemoveOpenHour.bind(this, open_hours_k)}>Supprimer</button>
											</div>
										</div>
									</div>
								)
							})}
							<div className="center">
								<button type="button" onClick={this.handleAddOpenHour} className="btn-floating btn-large waves-effect waves-light teal">
									<i className="material-icons">add</i>
								</button>
							</div>
							<p className="success center">{this.state.success.global}</p>
							{sendbtn}
						</form>
					</div>
				</div>
				<div className="row">
					<div className="module col s12">
						<h2>Paramètres des produits</h2>
						{product_content}
					</div>
				</div>
			</>
		)
	}
}

export default ServiceVente

import React, { Component } from 'react'
import Modal from '../UI/Modal'
import Loader from '../UI/Loader'
import CKEditor from '../UI/CKEditor'
import LangSelector from '../UI/LangSelector'
import M from 'materialize-css'
import Benefits from './Benefits'
import Workers from './Workers'
import Products from './Products'
import ServiceVente from './ServiceVente'
import ServicePrestation from './ServicePrestation'
import tools from '../../tools'
import '../Core/Main.css'
import OnlyPremium from '../UI/OnlyPremium'
import PremiumAlert from '../UI/PremiumAlert'
import EmptyFolder from '../UI/EmptyFolder'
import { HomeIcon } from '@heroicons/react/outline'
import { Link, Redirect } from 'react-router-dom'
import BannerInfo from '../UI/BannerInfo'
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'

class Services extends Component {
	constructor(props) {
		super(props)
		this.state = this._initState()
		this.length_max = 50
		this.props.updateNavbarTitle('Services')
	}

	componentDidMount() {
		this.loadServices()
	}

	componentDidUpdate(prevProps, prevState) {
		const elems = document.querySelectorAll('select')
		M.FormSelect.init(elems)
		if (prevProps.services !== this.props.services)
			this.loadServices(true)
		if (prevProps.location.key !== this.props.location.key)
		{
			this.setState(this._initState())
			this.loadServices()
		}
		if (this.state.init_dropdown === false)
		{
			const dropdowns = document.querySelectorAll('.dropdown-trigger')
			if (dropdowns.length > 0)
			{
				M.Dropdown.init(dropdowns)
				this.setState({ init_dropdown: true })
			}
		}
		if (this.state.init_modal === false)
		{
			const modals = document.querySelectorAll('.modal')
			if (modals.length > 0)
			{
				M.Modal.init(modals)
				this.setState({ init_modal: true })
			}
		}
		if (prevProps.service_benefits !== this.props.service_benefits)
		{
			let service_benefits = []
			for (let i = 0; i < this.props.service_benefits.length; i++)
			{
				if (this.props.service_benefits[i].service === this.state.edit._id)
					service_benefits.push(this.props.service_benefits[i])
			}
			this.setState({ service_benefits: service_benefits })
		}
		if (prevProps.service_workers !== this.props.service_workers)
		{
			let service_workers = []
			for (let i = 0; i < this.props.service_workers.length; i++)
			{
				if (this.props.service_workers[i].service === this.state.edit._id)
					service_workers.push(this.props.service_workers[i])
			}
			this.setState({ service_workers: service_workers })
		}
		if (prevProps.service_products !== this.props.service_products)
		{
			let service_products = []
			for (let i = 0; i < this.props.service_products.length; i++)
			{
				if (this.props.service_products[i].service === this.state.edit._id)
					service_products.push(this.props.service_products[i])
			}
			this.setState({ service_products: service_products })
		}
	}

	loadIcons = () => {
		this.props.fetchIcons()
		.then(icons => {
			this.setState({ icons: icons })
		})
		.catch(err => {
			this.setErrorMsg("error_fetch_services", 1)
			console.log(err)
		})
	}

	loadTheme = () => {
		this.props.fetchTheme()
		.then(theme => {
			this.setState({ theme: theme })
		})
		.catch(err => {
			this.setErrorMsg("error_fetch_services", 2)
			console.log(err)
		})
	}

	loadServices = (fromProps = false) => {
		if (fromProps === true)
			this.setState({ services: this.props.services })
		else
		{
			this.props.fetchServices()
			.then(services => {
				this.setState({ services: services })
			})
			.catch(err => {
				this.setErrorMsg("error_fetch_services", 3)
				console.log(err)
			})
		}
	}

	loadServiceBenefits = (service) => {
		this.props.fetchServiceBenefitsByService(service)
		.then(service_benefits => {
			this.setState({ service_benefits: service_benefits })
		})
		.catch(err => {
			this.setErrorMsg("error_fetch_service_benefits", 1)
			console.log(err)
		})
	}

	loadServiceWorkers = (service) => {
		this.props.fetchServiceWorkersByService(service)
		.then(service_workers => {
			this.setState({ service_workers: service_workers })
		})
		.catch(err => {
			this.setErrorMsg("error_fetch_service_workers", 1)
			console.log(err)
		})
	}

	loadServiceProducts = (service) => {
		this.props.fetchServiceProductsByService(service)
		.then(service_products => {
			this.setState({ service_products: service_products })
		})
		.catch(err => {
			this.setErrorMsg("error_fetch_service_products", 1)
			console.log(err)
		})
	}

	_initState = () => {
		return {
			edit: null,
			create: false,
			click_modal: 0,
			attempt_submit: false,
			services: undefined,
			init_dropdown: false,
			init_modal: false,
			modal: null,
			edit_modal: false,
			answerModal: null,
			open_modal: false,
			modal_msg: null,
			delete: null,
			service_benefits: null,
			service_workers: null,
			form1: {
				name: '',
				cover: null,
				type: '',
				translations: [],
				comment: '',
				list_mode: false
			},
			pages: [
				{ name: 'Services', href: '/services', current: false }
			],
			show_banner: false,
			banner_content: null,
			current_lang: this.props.lang_default,
			error: this._initErrors(),
			success: this._initSuccess()
		}
	}

	_initSuccess = () => {
		const success = {
			global: null,
			benefits: null,
			workers: null
		}
		return success
	}

	_initErrors = () => {
		const errors = {
			global: null,
			service: null,
			name: null,
			title: null,
			cover: null,
			type: null,
			benefits: null,
			workers: null
		}
		return errors
	}

	setErrorMsg = (key, msg, id = null) => {
		if (key === "error_fetch_services")
		{
			key = "global"
			msg = tools.default_error_msg("Impossible de récupérer les services", msg)
		}
		else if (key === "error_create_service")
		{
			key = "global"
			msg = tools.default_error_msg("Impossible de créer un service", msg)
		}
		else if (key === "error_update_service")
		{
			key = "global"
			msg = tools.default_error_msg("Impossible de modifier un service", msg)
		}
		else if (key === "error_delete_service")
		{
			key = "global"
			msg = tools.default_error_msg("Impossible de supprimer un service", msg)
		}
		else if (key === "error_fetch_service_benefits")
		{
			key = "global"
			msg = tools.default_error_msg("Impossible de récupérer les prestations", msg)
		}
		else if (key === "error_delete_service_benefit")
		{
			key = "benefits"
			msg = tools.default_error_msg("Impossible de supprimer une prestation", msg)
		}
		else if (key === "error_fetch_service_workers")
		{
			key = "global"
			msg = tools.default_error_msg("Impossible de récupérer les prestataires", msg)
		}
		else if (key === "error_delete_service_worker")
		{
			key = "workers"
			msg = tools.default_error_msg("Impossible de supprimer un pretataire", msg)
		}
		else if (key === "error_fetch_service_procucts")
		{
			key = "global"
			msg = tools.default_error_msg("Impossible de récupérer les produits", msg)
		}
		else if (key === "error_delete_service_product")
		{
			key = "products"
			msg = tools.default_error_msg("Impossible de supprimer un produit", msg)
		}
		let error = this._initErrors()
		if (id !== null)
			error[key][id] = msg
		else
			error[key] = msg
		this.setState({ success: this._initSuccess(), error: error, show_banner: true, banner_content: msg })
	}

	setSuccessMsg = (key, msg) => {
		const error = this._initErrors()
		let success = this.state.success
		success[key] = msg
		this.setState({ success: success, error: error, show_banner: true, banner_content: msg })
	}

	clearFlash = () => {
		const error = this._initErrors()
		const success = this._initSuccess()
		this.setState({ error: error, success: success })
	}

	closeBanner = () => {
		this.setState({ show_banner: false })
	}

	classNames = (...classes) => {
		return classes.filter(Boolean).join(' ')
	}

	clickEdit = (id) => {
		let service = null
		let form1 = {
			name: '',
			cover: null,
			type: '',
			translations: [],
			comment: '',
			list_mode: false
		}
		let pages = [...this.state.pages]
		for (let i = 0; i < this.state.services.length; i++)
		{
			if (this.state.services[i]._id === id)
			{
				service = this.state.services[i]
				form1 = {
					name: service.name,
					cover: service.cover,
					type: service.type,
					translations: service.translations,
					comment: service.comment,
					list_mode: service.list_mode
				}
				break
			}
		}
		for (let i = 0; i < pages.length; i++)
			pages[i].current = false
		pages.push({ name: form1.name, href: null, current: true })
		if (service.type === "prestation")
		{
			this.loadServiceBenefits(id)
			this.loadServiceWorkers(id)
		}
		else if (service.type === "vente")
			this.loadServiceProducts(id)
		this.clearFlash()
		this.setState({ edit: service, form1: form1, create: false, attempt_submit: false, init_modal: false, pages: pages })
	}

	clickDelete = (id) => {
		this.clearFlash()
		const modal_msg = "Vous êtes sur le point du supprimer cette prestation. Cette action est irréversible"
		this.setState({ delete: id, open_modal: true, modal_msg: modal_msg, answerModal: this.answerModalDelete })
	}

	clickDeleteService = () => {
		this.clearFlash()
		const modal_msg = "Vous êtes sur le point du supprimer ce service. Cette action est irréversible"
		this.setState({ delete: true, open_modal: true, modal_msg: modal_msg, answerModal: this.answerModalDelete })
	}
	
	clickCreate = () => {
		this.clearFlash()
		const form1 = {
			name: '',
			type: '',
			cover: null,
			translations: [],
			comment: '',
			list_mode: false
		}
		this.clearFlash()
		this.setState({ edit: null, form1: form1, create: true, attempt_submit: false, init_modal: false })
	}

	handleName = (event) => {
		const form1 = {
			...this.state.form1,
			name: event.target.value
		}
		this.setState({ form1: form1 })
	}

	handleTitle = (event) => {
		let found = false
		const translations = this.state.form1.translations
		for (let i = 0; i < translations.length; i++)
		{
			if (translations[i].lang === this.state.current_lang)
			{
				found = true
				translations[i].title = event.target.value
				break
			}
		}
		if (!found)
		{
			translations.push({
				lang: this.state.current_lang,
				title: event.target.value
			})
		}
		const form1 = {
			...this.state.form1,
			translations: translations
		}
		this.setState({ form1: form1 })
	}

	handleListMode = (event) => {
		const form1 = {
			...this.state.form1,
			list_mode: (this.state.form1.list_mode === true) ? false : true,
		}
		this.setState({ form1: form1 })
	}

	handleCover = (event) => {
		const reader = new FileReader()
		const file = event.target.files[0]
		reader.addEventListener("load", () => {
			const form1 = {
				...this.state.form1,
				cover: reader.result
			}
			this.setState({ form1: form1 })
		}, false)
		if (file)
			reader.readAsDataURL(file)
	}

	handleType = (type) => {
		let options = {}
		if (type === "prestation")
		{
			options = {
				limit: true,
				simultaneous: 1
			}
		}
		const form1 = {
			...this.state.form1,
			type: type,
			options: options
		}
		this.setState({ form1: form1 })
	}

	handleComment = (html) => {
		const form1 = {
			...this.state.form1,
			comment: html
		}
		this.setState({ form1: form1 })
	}

	_validateForm1 = () => {
		let error_obj = this._initErrors()
		const name = this.state.form1.name
		const type = this.state.form1.type
		const cover = this.state.form1.cover
		const translations = this.state.form1.translations
		let error = false
		if (name.length === 0)
		{
			error = true
			error_obj.name = "Le nom ne doit être vide"
		}
		else if (name.length > this.length_max)
		{
			error = true
			error_obj.name = "Le nom ne doit pas dépasser " + this.length_max + " caractères"
		}
		if (cover === null)
		{
			error = true
			error_obj.cover = "Vous devez sélectionner une photo de couverture"
		}
		if (type.length === 0)
		{
			error = true
			error_obj.type = "Vous devez choisir un type"
		}
		else if (type !== "location" && type !== "prestation" && type !== "vente")
		{
			error = true
			error_obj.type = "Type invalide"
		}
		let found_default_title = false
		for (let i = 0; i < translations.length; i++)
		{
			const lang = translations[i].lang
			const title = translations[i].title
			if (title.length === 0 && lang === this.props.lang_default)
			{
				error = true
				error_obj.title = "Le titre ne doit pas être vide (version " + lang.toUpperCase() + ")"
			}
			else if (title.length > this.length_max)
			{
				error = true
				error_obj.title = "Le titre ne doit pas dépasser " + this.length_max + " caractères (version " + lang.toUpperCase() + ")"
			}
			else if (lang === this.props.lang_default)
				found_default_title = true
		}
		if (!found_default_title && !error_obj.title)
		{
			error = true
			error_obj.title = 'Le titre ne doit pas être vide (version ' + this.props.lang_default.toUpperCase() + ')'
		}
		if (error === true)
		{
			const success = this._initSuccess()
			error_obj.service = "Il y a une ou plusieurs erreur(s) dans le formulaire"
			this.setState({ success: success, error: error_obj, attempt_submit: false })
		}
		return (!error) ? true : false
	}

	handleSubmit = (event) => {
		this.setState({ attempt_submit: true })
		event.preventDefault()
		if (this._validateForm1())
		{
			const translations = this.state.form1.translations
			let langs_not_found = []
			for (let i = 0; i < this.props.langs.length; i++)
			{
				let found = false
				for (let j = 0; j < translations.length; j++)
				{
					if (this.props.langs[i] === translations[j].lang && translations[j].title.length > 0)
					{
						found = true
						break
					}
				}
				if (!found)
					langs_not_found.push(this.props.langs[i])
			}
			if (langs_not_found.length > 0)
			{
				let msg = ""
				for (let i = 0; i < langs_not_found.length; i++)
				{
					if (i > 0)
						msg += ', '
					msg += langs_not_found[i].toUpperCase()
				}
				msg = "Le service n'est pas traduit dans les langues suivantes : " + msg
				this.setState({ modal_msg: msg, open_modal: true, answerModal: this.answerModalEdit })
			}
			else
				this._insertService()
		}
	}

	_insertService = () => {
		const service = {
			name: this.state.form1.name,
			cover: this.state.form1.cover,
			type: this.state.form1.type,
			translations: this.state.form1.translations,
			comment: (this.state.form1.comment !== "") ? this.state.form1.comment : null,
			list_mode: this.state.form1.list_mode,
			user: this.props.user._id
		}
		if (this.state.create === true)
		{
			this.props.insertService(service)
			.then(ret => {
				this.setSuccessMsg('global', "Le service a bien été créé")
				this.setState({ create: false, edit: ret, init_dropdown: false, attempt_submit: false, open_modal: false })
			})
			.catch(err => {
				this.setState({ attempt_submit: false, open_modal: false })
				this.setErrorMsg("error_create_service", 1)
				console.log(err)
			})
		}
		else if (this.state.edit !== null)
		{
			service._id = this.state.edit._id
			if (this.state.edit.type !== service.type)
			{
				if (this.state.edit.type === 'prestation')
				{
					this.props.deleteServiceBenefitsByService(this.state.edit._id)
					.then(ret => {
						if (ret === true)
						{
							this.props.deleteServiceWorkersByService(this.state.edit._id)
							.then(ret => {
								if (ret === true)
								{
									this.props.updateService(service)
									.then(ret => {
										this.setSuccessMsg('global', "Le service a bien été modifié")
										this.setState({ edit: ret, attempt_submit: false, open_modal: false })
									})
									.catch(err => {
										this.setState({ attempt_submit: false, open_modal: false })
										this.setErrorMsg("error_update_service", 1)
										console.log(err)
									})
								}
								else
								{
									this.setState({ attempt_submit: false, open_modal: false })
									this.setErrorMsg("error_update_service", 2)
								}
							})
						}
						else
						{
							this.setState({ attempt_submit: false, open_modal: false })
							this.setErrorMsg("error_update_service", 3)
						}
					})
					.catch(err => {
						this.setState({ attempt_submit: false, open_modal: false })
						this.setErrorMsg("error_update_service", 4)
						console.log(err)
					})
				}
				else if (this.state.edit.type === 'location')
				{
					/*
					TODO
					*/
				}
			}
			else
			{
				this.props.updateService(service)
				.then(ret => {
					this.setSuccessMsg('global', "Le service a bien été modifié")
					this.setState({ edit: ret, attempt_submit: false, open_modal: false })
				})
				.catch(err => {
					this.setState({ attempt_submit: false, open_modal: false })
					this.setErrorMsg("error_update_service", 5)
					console.log(err)
				})
			}
		}
	}

	_deleteService = () => {
		if (this.state.edit !== null)
		{
			const id = this.state.edit._id
			const type = this.state.edit.type
			if (type === "prestation")
			{
				this.props.deleteServiceBenefitsByService(id)
				.then(ret => {
					if (ret === true)
					{
						this.props.deleteServiceWorkersByService(id)
						.then(ret => {
							if (ret === true)
							{
								this.props.deleteService(id)
								.then(ret => {
									if (ret === true)
									{
										this.setSuccessMsg('global', "Le service a bien été supprimé")
										this.setState({ create: false, edit: null, delete: null, init_dropdown: false })
									}
									else
										this.setErrorMsg("error_delete_service", 3)
								})
								.catch(err => {
									this.setErrorMsg("error_delete_service", 4)
									console.log(err)
								})
							}
							else
								this.setErrorMsg("error_delete_service", 5)
						})
					}
					else
						this.setErrorMsg("error_delete_service", 6)
				})
				.catch(err => {
					this.setErrorMsg("error_delete_service", 7)
					console.log(err)
				})
			}
			else if (type === "location")
			{
				/*
				TODO
				*/
			}
		}
	}

	createModal = (type) => {
		const modals = document.querySelectorAll('.modal')
		const instance = M.Modal.getInstance(modals[1])
		instance.open()
		this.clearFlash()
		const click_modal = this.state.click_modal + 1
		this.setState({ click_modal: click_modal, modal: type, edit_modal: false })
	}

	editModal = (type, id) => {
		let object = false
		let list = null
		if (type === 'benefit')
			list = this.state.service_benefits
		else if (type === 'worker')
			list = this.state.service_workers
		else if (type === "product")
			list = this.state.service_products
		if (list !== null)
		{
			for (let i = 0; i < list.length; i++)
			{
				if (list[i]._id === id)
				{
					object = list[i]
					break
				}
			}
			if (object)
			{
				const modals = document.querySelectorAll('.modal')
				const instance = M.Modal.getInstance(modals[1])
				instance.open()
				this.clearFlash()
				const click_modal = this.state.click_modal + 1
				this.setState({ click_modal: click_modal, modal: type, edit_modal: object })
			}
		}
	}

	deleteObject = (type, id) => {
		this.clearFlash()
		if (type === 'benefit')
		{
			const modal_msg = "Vous êtes sur le point du supprimer cette prestation. Cette action est irréversible"
			this.setState({ delete: { type: type, id: id }, open_modal: true, modal_msg: modal_msg, answerModal: this.answerModalDeleteObject })
		}
		else if (type === 'worker')
		{
			const modal_msg = "Vous êtes sur le point du supprimer ce prestataire. Cette action est irréversible"
			this.setState({ delete: { type: type, id: id }, open_modal: true, modal_msg: modal_msg, answerModal: this.answerModalDeleteObject })
		}
		else if (type === 'product')
		{
			const modal_msg = "Vous êtes sur le point du supprimer ce produit. Cette action est irréversible"
			this.setState({ delete: { type: type, id: id }, open_modal: true, modal_msg: modal_msg, answerModal: this.answerModalDeleteObject })
		}
	}

	_deleteObject = () => {
		const type = this.state.delete.type
		const id = this.state.delete.id
		if (type === 'benefit')
		{
			this.props.deleteServiceBenefit(id)
			.then(ret => {
				if (ret === true)
				{
					this.setState({ open_modal: false })
					this.setSuccessMsg('benefits', "La prestation a bien été supprimée")
				}
				else
				{
					this.setState({ open_modal: false })
					this.setErrorMsg("error_delete_service_benefit", 1)
				}
			})
			.catch(err => {
				this.setState({ open_modal: false })
				this.setErrorMsg("error_delete_service_benefit", 2)
				console.log(err)
			})
		}
		else if (type === 'worker')
		{
			this.props.deleteServiceWorker(id)
			.then(ret => {
				if (ret === true)
				{
					this.setState({ open_modal: false })
					this.setSuccessMsg('workers', "Le prestataire a bien été supprimé")
				}
				else
				{
					this.setState({ open_modal: false })
					this.setErrorMsg("error_delete_service_worker", 1)
				}
			})
			.catch(err => {
				this.setState({ open_modal: false })
				this.setErrorMsg("error_delete_service_worker", 2)
				console.log(err)
			})
		}
		else if (type === 'product')
		{
			this.props.deleteServiceProduct(id)
			.then(ret => {
				if (ret === true)
				{
					this.setState({ open_modal: false })
					this.setSuccessMsg('products', "Le produit a bien été supprimé")
				}
				else
				{
					this.setState({ open_modal: false })
					this.setErrorMsg("error_delete_service_product", 1)
				}
			})
			.catch(err => {
				this.setState({ open_modal: false })
				this.setErrorMsg("error_delete_service_product", 2)
				console.log(err)
			})
		}
	}

	closeModal = (key = null, msg = null) => {
		const modals = document.querySelectorAll('.modal')
		const instance = M.Modal.getInstance(modals[1])
		instance.close()
		if (key && msg)
			this.setSuccessMsg(key, msg)
	}

	answerModalEdit = (answer) => {
		if (answer === "yes")
			this._insertService()
		else
			this.setState({ attempt_submit: false, open_modal: false })
	}

	answerModalDelete = (answer) => {
		if (answer === "yes")
			this._deleteService()
		else
			this.setState({ delete: false, open_modal: false })
	}

	answerModalDeleteObject = (answer) => {
		if (answer === "yes")
			this._deleteObject()
		else
			this.setState({ delete: false, open_modal: false })
	}

	updateModal = (msg, open_modal, answerModal) => {
		this.setState({ modal_msg: msg, open_modal: open_modal, answerModal: answerModal })
	}

	updateLang = (lang) => {
		this.setState({ current_lang: lang })
	}

	render() {
		let content = null
		const navStyle = "flex bg-transparent shadow-none mb-10 px-4 sm:px-6 lg:px-8"
		const empty_folder_content = "Pour créer un service ou une activité sur réservation, cliquez sur le bouton \"Créer un service\"."
		const permissions = this.props.user.permissions
		if (!permissions || !permissions.services || permissions.services === 0)
			return (<Redirect to="/" />)
		else if (this.state.services === undefined)
		{
			content = (
				<div className="loader">
					<Loader />
				</div>
			)
		}
		else if (this.props.features !== "premium")
		{
			content = (
				<div>
					<OnlyPremium 
						title="Mes services"
						subtitle="Paramétrez des évènements sur inscription (ex : location de vélo, club enfant, dépot de pain...)"
						button="Créer un service"
						emptyFolder="Commencez par créer un service"
						emptyFolderSubtitle="Pas de services"
						navName="Services"
						navPath="/Services"
					/>
					<div className="mt-10">
						<PremiumAlert
							content="Mettez votre abonnement à niveau avec la formule premium pour accéder aux services !"
							button_text="En savoir plus !"
						/>
					</div>
				</div>
			)
		}
		else if (this.state.create === false && !this.state.edit)
		{
			const services = this.state.services
			let sub_content = (
				<EmptyFolder 
					emptyFolderSubtitle="Pas de service paramétré"
					content={empty_folder_content}
				/>
			)
			if (services && services.length)
			{
				sub_content = (
					<ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
						{services.map(({ _id, name, cover }, id) => {
							let style = null
							let default_img = ""
							if (!cover)
							{
								default_img = (
									<div className="flex h-full w-full items-center text-center bg-gray-50">
										<div className="mx-auto">
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-12 h-12 text-gray-300">
												<path fillRule="evenodd" d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z" clipRule="evenodd" />
											</svg>
										</div>
									</div>
								  )
							}
							else
							{
								style = {
									backgroundImage: `url(${cover})` 
								}
							}
							const items = [
								{ name: 'Éditer', func: this.clickEdit.bind(this, _id) },
								{ name: 'Supprimer', func: this.clickDelete.bind(this, _id) },
							]
							return (
								<li
									key={id}
									className=" col-span-1 flex flex-col h-56 divide-y divide-gray-200 rounded-lg bg-white text-center shadow"
								>
									<div 
										className="cursor-pointer flex flex-1 flex-col bg-cover bg-center rounded-t-lg" 
										onClick={this.clickEdit.bind(this, _id)} 
										style={style}
									>
										{default_img}
									</div>
									<div className="py-3 px-4 flex flex-row justify-between">
										<div className="text-sm font-medium text-gray-900">{name}</div>
										<div className="inline-flex rounded-md shadow-sm">
											<Menu as="div" className="relative -ml-px block">
												<Menu.Button className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-1 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500">
													<span>...</span>
												</Menu.Button>
												<Transition
													as={Fragment}
													enter="transition ease-out duration-100"
													enterFrom="transform opacity-0 scale-95"
													enterTo="transform opacity-100 scale-100"
													leave="transition ease-in duration-75"
													leaveFrom="transform opacity-100 scale-100"
													leaveTo="transform opacity-0 scale-95"
												>
													<Menu.Items className="absolute right-0 z-10 mt-2 -mr-1 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
														<div className="py-1">
															{items.map((item) => (
																<Menu.Item key={item.name}>
																	{({ active }) => (
																		<button
																			onClick={item.func}
																			className={this.classNames(
																				active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
																				'block px-4 py-2 text-sm w-full text-left'
																			)}
																		>
																			{item.name}
																		</button>
																	)}
																</Menu.Item>
															))}
														</div>
													</Menu.Items>
												</Transition>
											</Menu>
										</div>
									</div>
								</li>
							)
						})}
					</ul>
				) 
			}
			content = (
				<>
					<nav className={navStyle} aria-label="Breadcrumb">
						<ol className="bg-white rounded-md shadow px-6 flex space-x-4">
							<li className="flex">
								<div className="flex items-center">
									<Link to="/" className="text-gray-400 hover:text-gray-500">
										<HomeIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
										<span className="sr-only">Home</span>
									</Link>
								</div>
							</li>
							{this.state.pages.map((page, id) => (
								<li key={id} className="flex">
									<div className="flex items-center">
										<svg
											className="flex-shrink-0 w-6 h-full text-gray-200"
											viewBox="0 0 24 44"
											preserveAspectRatio="none"
											fill="currentColor"
											xmlns="http://www.w3.org/2000/svg"
											aria-hidden="true"
										>
											<path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
										</svg>
										<Link
											to={page.href}
											className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
											aria-current={page.current ? 'page' : undefined}
										>
											{page.name}
										</Link>
									</div>
								</li>
							))}
						</ol>
					</nav>
					<div className="flex flex-col px-4 sm:px-6 lg:px-8">
						<div className="sm:flex-auto mb-7">
							<h2 className="text-xl mt-0 leading-6 font-medium text-gray-900 mb-0">Mes services</h2>
							<p className="mt-1 text-base text-gray-500">
								Paramétrez des évènements sur inscription (ex : location de vélo, club enfant, dépot de pain...)
							</p>
						</div>
						<div className="grid-cols-1">
							<button onClick={this.clickCreate} type="button" className="mb-10 inline-flex items-center px-6 py-3 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
								Créer un service
								<svg xmlns="http://www.w3.org/2000/svg" className="ml-3 -mr-1 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
									<path strokeLinecap="round" strokeLinejoin="round" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
								</svg>
							</button>
						</div>
						<div className="mt-8">
							{sub_content}
						</div>
					</div>
				</>
			)
		}
		else if (this.state.create === true || this.state.edit !== null)
		{
			const prestation = (this.state.form1.type === "prestation") ? true : false
			// const location = (this.state.form1.type === "location") ? true : false
			const vente = (this.state.form1.type === "vente") ? true : false
			let sendbtn = (<button type="submit" className="waves-effect waves-light btn">Enregistrer</button>)
			if (this.state.attempt_submit === true)
			{
				sendbtn = (
					<div className="center">
						<Loader />
					</div>
				)
			}
			let detailsJSX = null
			let modalJSX = null
			if (this.state.edit !== null && this.state.edit.type === "prestation")
			{
				detailsJSX = (
					<ServicePrestation
						updateLang={this.updateLang}
						langs={this.props.langs}
						lang_default={this.props.lang_default}
						service={this.state.edit}
						service_benefits={this.state.service_benefits}
						service_workers={this.state.service_workers}
						fetchServices={this.props.fetchServices}
						createModal={this.createModal}
						editModal={this.editModal}
						deleteObject={this.deleteObject}
						current_lang={this.state.current_lang}
						updateNavbarTitle={this.props.updateNavbarTitle}
					/>
				)
				if (this.state.modal === 'benefit')
				{
					modalJSX = (
						<Benefits
							current_lang={this.state.current_lang}
							langs={this.props.langs}
							lang_default={this.props.lang_default}
							updateLang={this.updateLang}
							closeModal={this.closeModal}
							edit_modal={this.state.edit_modal}
							service={this.state.edit}
							service_benefits={this.state.service_benefits}
							user={this.props.user}
							insertServiceBenefit={this.props.insertServiceBenefit}
							updateServiceBenefit={this.props.updateServiceBenefit}
							fetchIcons={this.props.fetchIcons}
							fetchTheme={this.props.fetchTheme}
							updateModal={this.updateModal}
							click_modal={this.state.click_modal}
						/>
					)
				}
				else if (this.state.modal === 'worker')
				{
					modalJSX = (
						<Workers
							closeModal={this.closeModal}
							edit_modal={this.state.edit_modal}
							lang_default={this.props.lang_default}
							service={this.state.edit}
							service_benefits={this.props.service_benefits}
							user={this.props.user}
							insertServiceWorker={this.props.insertServiceWorker}
							updateServiceWorker={this.props.updateServiceWorker}
							click_modal={this.state.click_modal}
						/>
					)
				}
			}
			else if (this.state.edit !== null && this.state.edit.type === "vente")
			{
				detailsJSX = (
					<ServiceVente
						updateLang={this.updateLang}
						langs={this.props.langs}
						lang_default={this.props.lang_default}
						service={this.state.edit}
						service_products={this.state.service_products}
						fetchServices={this.props.fetchServices}
						createModal={this.createModal}
						editModal={this.editModal}
						deleteObject={this.deleteObject}
						updateServiceDuration={this.props.updateServiceDuration}
						current_lang={this.state.current_lang}
						updateNavbarTitle={this.props.updateNavbarTitle}
					/>
				)
				if (this.state.modal === 'product')
				{
					modalJSX = (
						<Products
							current_lang={this.state.current_lang}
							langs={this.props.langs}
							lang_default={this.props.lang_default}
							updateLang={this.updateLang}
							closeModal={this.closeModal}
							edit_modal={this.state.edit_modal}
							service={this.state.edit}
							service_products={this.state.service_products}
							user={this.props.user}
							insertServiceProduct={this.props.insertServiceProduct}
							updateServiceProduct={this.props.updateServiceProduct}
							fetchIcons={this.props.fetchIcons}
							fetchTheme={this.props.fetchTheme}
							updateModal={this.updateModal}
							click_modal={this.state.click_modal}
						/>
					)
				}
			}
			let title = ""
			for (let i = 0; i < this.state.form1.translations.length; i++)
			{
				if (this.state.form1.translations[i].lang === this.state.current_lang)
				{
					title = this.state.form1.translations[i].title
					break
				}
			}
			let prev = null
			if (this.state.form1.cover !== null)
				prev = (<div><p>Prévisualisation :</p><p><img src={this.state.form1.cover} className="prev" alt="Prévisualisation" /></p></div>)
			let deletebtn = null
			if (this.state.edit !== null)
				deletebtn = (<button type="button" onClick={this.clickDeleteService} className="waves-effect waves-light btn red">Supprimer le service</button>)
			content = (
				<div className="wrapper-content">
					<nav className={navStyle} aria-label="Breadcrumb">
						<ol className="bg-white rounded-md shadow px-6 flex space-x-4">
							<li className="flex">
								<div className="flex items-center">
									<Link to="/" className="text-gray-400 hover:text-gray-500">
										<HomeIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
										<span className="sr-only">Home</span>
									</Link>
								</div>
							</li>
							{this.state.pages.map((page, id) => {
								let linkJSX = (
									<div
										className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
									>
										{page.name}
									</div>
								)
								if (page.href)
								{
									linkJSX = (
										<Link
											to={page.href}
											className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
											aria-current={page.current ? 'page' : undefined}
										>
											{page.name}
										</Link>
									)
								}
								return (
									<li key={id} className="flex">
										<div className="flex items-center">
											<svg
												className="flex-shrink-0 w-6 h-full text-gray-200"
												viewBox="0 0 24 44"
												preserveAspectRatio="none"
												fill="currentColor"
												xmlns="http://www.w3.org/2000/svg"
												aria-hidden="true"
											>
												<path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
											</svg>
											{linkJSX}
										</div>
									</li>
								)
							})}
						</ol>
					</nav>
					<div id="modal2" className="modal">
						<div className="modal-content">
							{modalJSX}
						</div>
						<div className="modal-footer">
							<button onClick={this.closeModal} className="link waves-effect waves-green btn-flat">Fermer</button>
						</div>
					</div>
					<div className="row">
						<div className="module col s12">
							{/* <p className="center error">{this.state.error.global}</p>
							<p className="center success">{this.state.success.global}</p> */}
							<LangSelector lang={this.state.current_lang} updateLang={this.updateLang} />
							<form className="module-form" onSubmit={this.handleSubmit}>
								<label>
									<p className="error">{this.state.error.name}</p>
									<p>Nom :</p>
									<input type="text" value={this.state.form1.name} onChange={this.handleName} />
								</label>
								<label>
									<p className="error">{this.state.error.title}</p>
									<p>Titre (version {this.state.current_lang.toUpperCase()}) :</p>
									<input type="text" value={title} onChange={this.handleTitle} />
								</label>
								<label>
									<p>Commentaire :</p>
								</label>
								<CKEditor
									data={this.state.form1.comment}
									updateContent={this.handleComment}
								/>
								<p className="error">{this.state.error.type}</p>
								<p>Type :</p>
								<label>
									<input type="radio" className="with-gap" name="type" value="prestation" checked={prestation} onChange={this.handleType.bind(this, "prestation")} /> <span>Prestation</span>
								</label>
								{/* <label>
									<input type="radio" className="with-gap" name="type" value="location" checked={location} onChange={this.handleType.bind(this, "location")} /> <span>Location</span>
								</label> */}
								<label>
									<input type="radio" className="with-gap" name="type" value="vente" checked={vente} onChange={this.handleType.bind(this, "vente")} /> <span>Vente</span>
								</label>
								<label>
									<p>Vue groupée :</p>
									<input type="checkbox" checked={this.state.form1.list_mode} onChange={this.handleListMode} /><span></span>
								</label>
								<label>
									<p className="error">{this.state.error.cover}</p>
									<p className="column-20">Photo de couverture :</p>
									<input type="file" onChange={this.handleCover} />
								</label>
								{prev}
								<p className="error">{this.state.error.service}</p>
								{sendbtn}
							</form>
						</div>
					</div>
					{detailsJSX}
					{deletebtn}
				</div>
			)
		}
		return (
			<div>
				<div className="wrapper-content">
					<Modal
						dismissible={false}
						content={this.state.modal_msg}
						open_modal={this.state.open_modal}
						answerModal={this.state.answerModal}
					/>
					<BannerInfo
						content={this.state.banner_content}
						show={this.state.show_banner}
						closeBanner={this.closeBanner}
						error={this.state.error.global}
						success={this.state.success}
					/>
					{content}
				</div>
			</div>
		)
	}
}

export default Services

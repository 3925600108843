import React, { Component } from 'react'
import Loader from '../UI/Loader'
import CKEditor from '../UI/CKEditor'
import IconPicker from '../UI/IconPicker'
import LangSelector from '../UI/LangSelector'
import M from 'materialize-css'
import tools from '../../tools'
import '../Core/Main.css'

class Benefits extends Component {
	constructor(props) {
		super(props)
		this.state = this._initState()
		this.length_max = 50
		this.location_length_max = 30
		this.services_max = 20
		this.question_title_length = 20
		this.questions_max = 5
	}

	componentDidMount() {
		this.loadTheme()
		this.loadIcons()
	}

	componentDidUpdate(prevProps, prevState) {
		const elems = document.querySelectorAll('select')
		M.FormSelect.init(elems)
		if (prevProps.click_modal !== this.props.click_modal)
		{
			this.setState(this._initState())
			this.loadTheme()
			this.loadIcons()
		}
	}

	loadIcons = () => {
		this.props.fetchIcons()
		.then(icons => {
			let form1 = this.state.form1
			if (this.props.edit_modal !== false)
			{
				let prev = null
				const benefit = this.props.edit_modal
				for (let i = 0; i < icons.length; i++)
				{
					if (icons[i]._id === benefit.icon)
					{
						for (let j = 0; j < icons[i].fill.length; j++)
						{
							if (icons[i].fill[j].theme === this.state.theme._id)
							{
								prev = icons[i].fill[j].icon
								break
							}
						}
						break
					}
				}
				form1.prev = prev
			}
			this.setState({ icons: icons, form1: form1 })
		})
	}

	loadTheme = () => {
		this.props.fetchTheme()
		.then(theme => {
			this.setState({ theme: theme })
		})
		.catch(err => console.log("Could not fetch theme", err))
	}

	_initState = () => {
		let state = {
			edit: null,
			create: false,
			attempt_submit: false,
			icons: undefined,
			theme: undefined,
			open_modal: false,
			modal_msg: null,
			form1: {
				icon: null,
				prev: null,
				show_price: false,
				price: '',
				confirmation: false,
				duration_hour: 0,
				duration_minute: 0,
				interval_hour: 0,
				interval_minute: 0,
				open_dates: [{
					start_day: '',
					start_month: '',
					end_day: '',
					end_month: ''
				}],
				open_hours: [{
					monday: false,
					tuesday: false,
					wednesday: false,
					thursday: false,
					friday: false,
					saturday: false,
					sunday: false,
					start_hour: '',
					start_minute: '',
					end_hour: '',
					end_minute: ''
				}],
				simultaneous: 1,
				client_number: false,
				identity: true,
				age: false,
				phone: false,
				questions: [],
				translations: []
			},
			error: this._initErrors(),
			success: null
		}
		if (this.props.edit_modal !== false)
		{
			const benefit = this.props.edit_modal
			const form1 = {
				icon: benefit.icon,
				prev: null,
				show_price: benefit.show_price,
				price: benefit.price,
				confirmation: benefit.confirmation,
				duration_hour: benefit.duration_hour,
				duration_minute: benefit.duration_minute,
				interval_hour: benefit.interval_hour,
				interval_minute: benefit.interval_minute,
				open_dates: benefit.open_dates,
				open_hours: benefit.open_hours,
				limit: (benefit.simultaneous === null) ? false : true,
				simultaneous: benefit.simultaneous,
				client_number: benefit.client_number,
				identity: benefit.identity,
				age: benefit.age,
				phone: benefit.phone,
				questions: benefit.questions,
				translations: benefit.translations
			}
			state.form1 = form1
		}
		return state
	}

	_initErrors = () => {
		const errors = {
			global: null,
			icon: null,
			subtitle: null,
			description: null,
			price: null,
			comment: null,
			location: null,
			duration: null,
			interval: null,
			simultaneous: null,
			open_dates: [],
			open_hours_weekdays: [],
			open_hours_time: [],
			remove_open_dates: [],
			remove_open_hours: [],
			questions_title: [],
			questions_type: [],
			add_questions: [],
			remove_questions: []
		}
		if (this.state)
		{
			for (let j = 0; j < this.state.form1.questions.length; j++)
			{
				errors.questions_title.push(null)
				errors.questions_type.push(null)
				errors.remove_questions.push(null)
			}
			for (let j = 0; j < this.state.form1.open_dates.length; j++)
			{
				errors.open_dates.push(null)
				errors.remove_open_dates.push(null)
			}
			for (let j = 0; j < this.state.form1.open_hours.length; j++)
			{
				errors.open_hours_weekdays.push(null)
				errors.open_hours_time.push(null)
				errors.remove_open_hours.push(null)
			}
		}
		return errors
	}

	setErrorMsg = (key, msg, id = null) => {
		let error = this.state.error
		if (id !== null)
			error[key][id] = msg
		else
			error[key] = msg
		this.setState({ success: null, error: error })
	}

	setSuccessMsg = (msg) => {
		const error = this._initErrors()
		this.setState({ success: msg, error: error })
	}

	clearFlash = () => {
		const error = this._initErrors()
		this.setState({ error: error, success: null })
	}
	
	clickCreate = () => {
		this.clearFlash()
		const form1 = {
			icon: null,
			prev: null,
			show_price: false,
			price: '',
			confirmation: false,
			duration_hour: 0,
			duration_minute: 0,
			interval_hour: 0,
			interval_minute: 0,
			open_dates: [{
				start_day: '',
				start_month: '',
				end_day: '',
				end_month: ''
			}],
			open_hours: [{
				monday: false,
				tuesday: false,
				wednesday: false,
				thursday: false,
				friday: false,
				saturday: false,
				sunday: false,
				start_hour: '',
				start_minute: '',
				end_hour: '',
				end_minute: ''
			}],
			simultaneous: 1,
			client_number: false,
			identity: true,
			age: false,
			phone: false,
			questions: [],
			translations: []
		}
		this.setState({ edit: null, form1: form1, create: true })
	}

	handleSubtitle = (event) => {
		let found = false
		const translations = this.state.form1.translations
		for (let i = 0; i < translations.length; i++)
		{
			if (translations[i].lang === this.props.current_lang)
			{
				found = true
				translations[i].subtitle = event.target.value
				break
			}
		}
		if (!found)
		{
			translations.push({
				lang: this.props.current_lang,
				subtitle: event.target.value,
				description: '',
				comment: '',
				location: ''
			})
		}
		const form1 = {
			...this.state.form1,
			translations: translations
		}
		this.setState({ form1: form1 })
	}

	handleIcon = (icon) => {
		const icons = this.state.icons
		let prev = null
		for (let i = 0; i < icons.length; i++)
		{
			if (icons[i]._id === icon)
			{
				for (let j = 0; j < icons[i].fill.length; j++)
				{
					if (icons[i].fill[j].theme === this.state.theme._id)
					{
						prev = icons[i].fill[j].icon
						break
					}
				}
				break
			}
		}
		const form1 = {
			...this.state.form1,
			icon: icon,
			prev: prev
		}
		this.setState({ form1: form1 })
	}

	handleDescription = (html) => {
		let found = false
		const translations = this.state.form1.translations
		for (let i = 0; i < translations.length; i++)
		{
			if (translations[i].lang === this.props.current_lang)
			{
				found = true
				translations[i].description = html
				break
			}
		}
		if (!found)
		{
			translations.push({
				lang: this.props.current_lang,
				subtitle: '',
				description: html,
				comment: '',
				location: ''
			})
		}
		const form1 = {
			...this.state.form1,
			translations: translations
		}
		this.setState({ form1: form1 })
	}

	handleShowPrice = (event) => {
		const form1 = {
			...this.state.form1,
			show_price: (this.state.form1.show_price) ? false : true
		}
		this.setState({ form1: form1 })
	}

	handlePrice = (event) => {
		const form1 = {
			...this.state.form1,
			price: event.target.value
		}
		this.setState({ form1: form1 })
	}

	handleComment = (html) => {
		let found = false
		const translations = this.state.form1.translations
		for (let i = 0; i < translations.length; i++)
		{
			if (translations[i].lang === this.props.current_lang)
			{
				found = true
				translations[i].comment = html
				break
			}
		}
		if (!found)
		{
			translations.push({
				lang: this.props.current_lang,
				subtitle: '',
				description: '',
				comment: html,
				location: ''
			})
		}
		const form1 = {
			...this.state.form1,
			translations: translations
		}
		this.setState({ form1: form1 })
	}

	handleLocation = (event) => {
		let found = false
		const translations = this.state.form1.translations
		for (let i = 0; i < translations.length; i++)
		{
			if (translations[i].lang === this.props.current_lang)
			{
				found = true
				translations[i].location = event.target.value
				break
			}
		}
		if (!found)
		{
			translations.push({
				lang: this.props.current_lang,
				subtitle: '',
				description: '',
				comment: '',
				location: event.target.value
			})
		}
		const form1 = {
			...this.state.form1,
			translations: translations
		}
		this.setState({ form1: form1 })
	}

	handleDurationHour = (event) => {
		const form1 = {
			...this.state.form1,
			duration_hour: event.target.value
		}
		this.setState({ form1: form1 })
	}

	handleDurationMinute = (event) => {
		const form1 = {
			...this.state.form1,
			duration_minute: event.target.value
		}
		this.setState({ form1: form1 })
	}

	handleIntervalHour = (event) => {
		const form1 = {
			...this.state.form1,
			interval_hour: event.target.value
		}
		this.setState({ form1: form1 })
	}

	handleIntervalMinute = (event) => {
		const form1 = {
			...this.state.form1,
			interval_minute: event.target.value
		}
		this.setState({ form1: form1 })
	}

	handleConfirmation = (event) => {
		const form1 = {
			...this.state.form1,
			confirmation: (this.state.form1.confirmation) ? false : true
		}
		this.setState({ form1: form1 })
	}

	handleLimit = (event) => {
		const form1 = {
			...this.state.form1,
			limit: (this.state.form1.limit === true) ? false : true,
			simultaneous: (this.state.form1.limit === true) ? null : 1
		}
		this.setState({ form1: form1 })
	}

	handleSimultaneous = (event) => {
		const form1 = {
			...this.state.form1,
			simultaneous: event.target.value
		}
		this.setState({ form1: form1 })
	}

	handleStartDay = (open_dates_k, event) => {
		const open_dates = this.state.form1.open_dates
		open_dates[open_dates_k].start_day = event.target.value
		const form1 = {
			...this.state.form1,
			open_dates: open_dates
		}
		this.setState({ form1: form1 })
	}

	handleStartMonth = (open_dates_k, event) => {
		const open_dates = this.state.form1.open_dates
		open_dates[open_dates_k].start_month = event.target.value
		const form1 = {
			...this.state.form1,
			open_dates: open_dates
		}
		this.setState({ form1: form1 })
	}

	handleEndDay = (open_dates_k, event) => {
		const open_dates = this.state.form1.open_dates
		open_dates[open_dates_k].end_day = event.target.value
		const form1 = {
			...this.state.form1,
			open_dates: open_dates
		}
		this.setState({ form1: form1 })
	}

	handleEndMonth = (open_dates_k, event) => {
		const open_dates = this.state.form1.open_dates
		open_dates[open_dates_k].end_month = event.target.value
		const form1 = {
			...this.state.form1,
			open_dates: open_dates
		}
		this.setState({ form1: form1 })
	}

	handleAddOpenDate = () => {
		let open_dates = this.state.form1.open_dates
		open_dates.push({
			start_day: '',
			start_month: '',
			end_day: '',
			end_month: ''
		})
		const form1 = {
			...this.state.form1,
			open_dates: open_dates
		}
		this.clearFlash()
		this.setState({ form1: form1 })
	}

	handleRemoveOpenDate = (open_dates_k) => {
		if (this.state.form1.open_dates.length <= 1)
			this.setErrorMsg('remove_open_dates', 'Vous devez au moins avoir 1 période d\'ouverture', open_dates_k)
		else
		{
			let open_dates = this.state.form1.open_dates
			open_dates.splice(open_dates_k, 1)
			const form1 = {
				...this.state.form1,
				open_dates: open_dates
			}
			this.clearFlash()
			this.setState({ form1: form1 })
		}
	}

	handleWeekday = (open_hours_k, day) => {
		const open_hours = this.state.form1.open_hours
		open_hours[open_hours_k][day] = (open_hours[open_hours_k][day]) ? false : true
		const form1 = {
			...this.state.form1,
			open_hours: open_hours
		}
		this.setState({ form1: form1 })
	}

	handleStartHour = (open_hours_k, event) => {
		const open_hours = this.state.form1.open_hours
		open_hours[open_hours_k].start_hour = event.target.value
		const form1 = {
			...this.state.form1,
			open_hours: open_hours
		}
		this.setState({ form1: form1 })
	}

	handleStartMinute = (open_hours_k, event) => {
		const open_hours = this.state.form1.open_hours
		open_hours[open_hours_k].start_minute = event.target.value
		const form1 = {
			...this.state.form1,
			open_hours: open_hours
		}
		this.setState({ form1: form1 })
	}

	handleEndHour = (open_hours_k, event) => {
		const open_hours = this.state.form1.open_hours
		open_hours[open_hours_k].end_hour = event.target.value
		const form1 = {
			...this.state.form1,
			open_hours: open_hours
		}
		this.setState({ form1: form1 })
	}

	handleEndMinute = (open_hours_k, event) => {
		const open_hours = this.state.form1.open_hours
		open_hours[open_hours_k].end_minute = event.target.value
		const form1 = {
			...this.state.form1,
			open_hours: open_hours
		}
		this.setState({ form1: form1 })
	}

	handleAddOpenHour = () => {
		let open_hours = this.state.form1.open_hours
		open_hours.push({
			monday: false,
			tuesday: false,
			wednesday: false,
			thursday: false,
			friday: false,
			saturday: false,
			sunday: false,
			start_hour: '',
			start_minute: '',
			end_hour: '',
			end_minute: ''
		})
		const form1 = {
			...this.state.form1,
			open_hours: open_hours
		}
		this.clearFlash()
		this.setState({ form1: form1 })
	}

	handleRemoveOpenHour = (open_hours_k) => {
		if (this.state.form1.open_hours.length <= 1)
			this.setErrorMsg('remove_open_hours', 'Vous devez au moins avoir 1 horaire d\'ouverture', open_hours_k)
		else
		{
			let open_hours = this.state.form1.open_hours
			open_hours.splice(open_hours_k, 1)
			const form1 = {
				...this.state.form1,
				open_hours: open_hours
			}
			this.clearFlash()
			this.setState({ form1: form1 })
		}
	}

	handleClientNumber = (event) => {
		const form1 = {
			...this.state.form1,
			client_number: (this.state.form1.client_number === true) ? false : true
		}
		this.setState({ form1: form1 })
	}

	handleIdentity = (event) => {
		const form1 = {
			...this.state.form1,
			identity: (this.state.form1.identity === true) ? false : true
		}
		this.setState({ form1: form1 })
	}

	handleAge = (event) => {
		const form1 = {
			...this.state.form1,
			age: (this.state.form1.age === true) ? false : true
		}
		this.setState({ form1: form1 })
	}

	handlePhone = (event) => {
		const form1 = {
			...this.state.form1,
			phone: (this.state.form1.phone === true) ? false : true
		}
		this.setState({ form1: form1 })
	}

	handleQuestionTitle = (questions_k, event) => {
		const questions = this.state.form1.questions
		let found = false
		const question = questions[questions_k]
		for (let i = 0; i < question.translations.length; i++)
		{
			if (question.translations[i].lang === this.props.current_lang)
			{
				found = true
				question.translations[i].title = event.target.value
				break
			}
		}
		if (!found)
		{
			question.translations.push({
				lang: this.props.current_lang,
				title: event.target.value
			})
		}
		questions[questions_k] = question
		const form1 = {
			...this.state.form1,
			questions: questions
		}
		this.setState({ form1: form1 })
	}

	handleQuestionType = (questions_k, event) => {
		const questions = this.state.form1.questions
		questions[questions_k].type = event.target.value
		const form1 = {
			...this.state.form1,
			questions: questions
		}
		this.setState({ form1: form1 })
	}

	handleQuestionMandatory = (questions_k, event) => {
		const questions = this.state.form1.questions
		questions[questions_k].mandatory = questions[questions_k].mandatory ? false : true
		const form1 = {
			...this.state.form1,
			questions: questions
		}
		this.setState({ form1: form1 })
	}

	handleAddQuestion = () => {
		if (this.state.form1.questions.length + 1 > this.questions_max)
			this.setErrorMsg('add_questions', 'Vous ne pouvez pas ajouter plus de ' + this.questions_max + ' questions')
		else
		{
			let questions = this.state.form1.questions
			questions.push({
				type: '',
				mandatory: false,
				translations: []
			})
			const form1 = {
				...this.state.form1,
				questions: questions
			}
			this.clearFlash()
			this.setState({ form1: form1 })
		}
	}

	handleRemoveQuestion = (questions_k) => {
		if (this.state.form1.questions.length <= 0)
			this.setErrorMsg('remove_questions', 'Vous ne pouvez pas supprimer de questions', questions_k)
		else
		{
			let questions = this.state.form1.questions
			questions.splice(questions_k, 1)
			const form1 = {
				...this.state.form1,
				questions: questions
			}
			this.clearFlash()
			this.setState({ form1: form1 })
		}
	}

	_validateForm1 = () => {
		let error_obj = this._initErrors()
		let error = false
		const price = Number(this.state.form1.price)
		const limit = this.state.form1.limit
		const simultaneous = parseInt(this.state.form1.simultaneous)
		if (this.state.form1.icon === null)
		{
			error = true
			error_obj.icon = "Vous devez sélectionner un icône"
		}
		if (this.state.form1.price.length === 0)
		{
			error = true
			error_obj.price = "Le prix ne doit pas être vide"
		}
		else if (!/^[+-]?[0-9]+([.,][0-9]{1,2})?$/.test(this.state.form1.price))
		{
			error = true
			error_obj.price = "Le prix doit être un nombre valide"
		}
		else if (price < 0)
		{
			error = true
			error_obj.price = "Le prix doit être un nombre positif"
		}
		else if (price > 1000000)
		{
			error = true
			error_obj.price = "Le prix doit être inférieur ou égal à 1 000 000€"
		}
		if (Number(this.state.form1.duration_hour) === 0 && Number(this.state.form1.duration_minute) === 0)
		{
			error = true
			error_obj.duration = "La durée doit être strictement positive"
		}
		if (limit === true && simultaneous <= 0)
		{
			error = true
			error_obj.simultaneous = "La limite des réservations en simultané doit être supérieure à zéro"
		}
		else if (limit === true && isNaN(this.state.form1.simultaneous) === true)
		{
			error = true
			error_obj.simultaneous = "La limite des réservations en simultané doit être un nombre valide"
		}
		const year = new Date().getFullYear()
		for (let i = 0; i < this.state.form1.open_dates.length; i++)
		{
			const open_date = this.state.form1.open_dates[i]
			const start_time = tools.parseTimestamp(Number(open_date.start_day), Number(open_date.start_month), year)
			const end_time = tools.parseTimestamp(Number(open_date.end_day) + 1, Number(open_date.end_month), year)
			if (open_date.start_day.length === 0 || open_date.start_month.length === 0 || open_date.end_day.length === 0 || open_date.end_month.length === 0)
			{
				error = true
				error_obj.open_dates[i] = "Les dates d'ouverture de début et de fin doivent être remplis"
			}
			else if (start_time >= end_time)
			{
				error = true
				error_obj.open_dates[i] = "La date de début doit être antérieure à la date de fin"
			}
		}
		for (let i = 0; i < this.state.form1.open_hours.length; i++)
		{
			const open_hour = this.state.form1.open_hours[i]
			const month = new Date().getMonth()
			const day = new Date().getDate()
			const start_time = tools.parseTimestamp(day, month, year, Number(open_hour.start_hour), Number(open_hour.start_minute))
			const end_time = tools.parseTimestamp(day, month, year, Number(open_hour.end_hour), Number(open_hour.end_minute))
			if (open_hour.start_hour.length === 0 || open_hour.start_minute.length === 0 || open_hour.end_hour.length === 0 || open_hour.end_minute.length === 0)
			{
				error = true
				error_obj.open_hours_time[i] = "Les horaires d'ouverture de début et de fin doivent être remplis"
			}
			else if (start_time >= end_time)
			{
				error = true
				error_obj.open_hours_time[i] = "L'heure de début doit être antérieure à l'heure de fin"
			}
			if (!open_hour.monday && !open_hour.tuesday && !open_hour.wednesday && !open_hour.thursday && !open_hour.friday && !open_hour.saturday && !open_hour.sunday)
			{
				error = true
				error_obj.open_hours_weekdays[i] = "Vous devez sélectionner au mois 1 jour"
			}
		}
		const translations = this.state.form1.translations
		let found_default_subtitle = false
		let found_default_description = false
		let found_default_location = false
		for (let i = 0; i < translations.length; i++)
		{
			const lang = translations[i].lang
			const subtitle = translations[i].subtitle
			if (subtitle.length === 0 && lang === this.props.lang_default)
			{
				error = true
				error_obj.subtitle = "Le sous-titre ne doit pas être vide (version " + lang.toUpperCase() + ")"
			}
			else if (subtitle.length > this.length_max)
			{
				error = true
				error_obj.subtitle = "Le sous-titre ne doit pas dépasser " + this.length_max + " caractères (version " + lang.toUpperCase() + ")"
			}
			else if (lang === this.props.lang_default)
				found_default_subtitle = true
			const description = translations[i].description
			if (description.length === 0 && lang === this.props.lang_default)
			{
				error = true
				error_obj.description = "La description ne doit pas être vide (version " + lang.toUpperCase() + ")"
			}
			else if (lang === this.props.lang_default)
				found_default_description = true
			const location = translations[i].location
			if (location.length === 0 && lang === this.props.lang_default)
			{
				error = true
				error_obj.location = "Le lieu ne doit pas être vide (version " + lang.toUpperCase() + ")"
			}
			else if (location.length > this.location_length_max)
			{
				error = true
				error_obj.location = "Le lieu ne doit pas dépasser " + this.location_length_max + " caractères (version " + lang.toUpperCase() + ")"
			}
			else if (lang === this.props.lang_default)
				found_default_location = true
		}
		if (!found_default_subtitle && !error_obj.subtitle)
		{
			error = true
			error_obj.subtitle = 'Le sous-titre ne doit pas être vide (version ' + this.props.lang_default.toUpperCase() + ')'
		}
		if (!found_default_description && !error_obj.description)
		{
			error = true
			error_obj.description = 'La description ne doit pas être vide (version ' + this.props.lang_default.toUpperCase() + ')'
		}
		if (!found_default_location && !error_obj.location)
		{
			error = true
			error_obj.location = 'Le lieu ne doit pas être vide (version ' + this.props.lang_default.toUpperCase() + ')'
		}
		for (let i = 0; i < this.state.form1.questions.length; i++)
		{
			const question = this.state.form1.questions[i]
			if (question.type.length === 0)
			{
				error = true
				error_obj.questions_type[i] = "Le type ne doit pas être vide"
			}
			const translations = question.translations
			let found_default_title = false
			for (let i = 0; i < translations.length; i++)
			{
				const lang = translations[i].lang
				const title = translations[i].title
				if (title.length === 0 && lang === this.props.lang_default)
				{
					error = true
					error_obj.questions_title[i] = "Le titre ne doit pas être vide (version " + lang.toUpperCase() + ")"
				}
				else if (title.length > this.length_max)
				{
					error = true
					error_obj.questions_title[i] = "Le titre ne doit pas dépasser " + this.length_max + " caractères (version " + lang.toUpperCase() + ")"
				}
				else if (lang === this.props.lang_default)
					found_default_title = true
			}
			if (!found_default_title && !error_obj.questions_title[i])
			{
				error = true
				error_obj.questions_title[i] = 'Le titre ne doit pas être vide (version ' + this.props.lang_default.toUpperCase() + ')'
			}
		}
		if (error === true)
		{
			error_obj.global = "Il y a une ou plusieurs erreur(s) dans le formulaire"
			this.setState({ success: null, error: error_obj })
		}
		return (!error) ? true : false
	}

	handleSubmit = (event) => {
		event.preventDefault()
		if (this._validateForm1())
		{
			const translations = this.state.form1.translations
			let langs_not_found = []
			for (let i = 0; i < this.props.langs.length; i++)
			{
				let found = false
				for (let j = 0; j < translations.length; j++)
				{
					if (this.props.langs[i] === translations[j].lang && translations[j].subtitle.length > 0 &&
						translations[j].description.length > 0 && translations[j].location.length > 0)
					{
						found = true
						break
					}
				}
				if (!found)
					langs_not_found.push(this.props.langs[i])
			}
			if (langs_not_found.length > 0)
			{
				let msg = ""
				for (let i = 0; i < langs_not_found.length; i++)
				{
					if (i > 0)
						msg += ', '
					msg += langs_not_found[i].toUpperCase()
				}
				msg = "La prestation n'est pas traduite dans les langues suivantes : " + msg
				this.props.updateModal(msg, true, this.answerModalEdit)
			}
			else
				this._insertServiceBenefit()
		}
	}

	_insertServiceBenefit = () => {
		let benefit = {
			service: this.props.service._id,
			icon: this.state.form1.icon,
			show_price: this.state.form1.show_price,
			price: this.state.form1.price,
			duration_hour: this.state.form1.duration_hour,
			duration_minute: this.state.form1.duration_minute,
			interval_hour: this.state.form1.interval_hour,
			interval_minute: this.state.form1.interval_minute,
			confirmation: this.state.form1.confirmation,
			translations: this.state.form1.translations,
			simultaneous: this.state.form1.simultaneous,
			client_number: this.state.form1.client_number,
			identity: this.state.form1.identity,
			age: this.state.form1.age,
			phone: this.state.form1.phone,
			open_dates: this.state.form1.open_dates,
			open_hours: this.state.form1.open_hours,
			questions: this.state.form1.questions,
			user: this.props.user._id
		}
		if (this.props.edit_modal === false)
		{
			this.props.insertServiceBenefit(benefit)
			.then(ret => {
				this.props.updateModal(null, false, null)
				this.props.closeModal('benefit', 'La prestation a bien été créée')
				this.clearFlash()
			})
			.catch(err => {
				this.props.updateModal(null, false, null)
				console.log(err)
			})
		}
		else
		{
			benefit._id = this.props.edit_modal._id
			this.props.updateServiceBenefit(benefit)
			.then(ret => {
				this.props.updateModal(null, false, null)
				this.props.closeModal('benefit', 'La prestation a bien été mise à jour')
				this.clearFlash()
			})
			.catch(err => {
				this.props.updateModal(null, false, null)
				console.log(err)
			})
		}
	}

	answerModalEdit = (answer) => {
		if (answer === "yes")
			this._insertServiceBenefit()
		else
		{
			this.props.updateModal(null, false, null)
			this.setState({ attempt_submit: false })
		}
	}

	render() {
		if (this.state.theme === undefined || this.state.icons === undefined)
		{
			return (
				<div className="loader">
					<Loader />
				</div>
			)
		}
		else
		{
			let duration_hours = []
			for (let i = 0; i <= 12; i++)
			{
				let txt = i
				if (txt < 10)
					txt = '0' + txt
				duration_hours.push({
					text: txt,
					value: i
				})
			}
			let duration_minutes = []
			for (let i = 0; i <= 59; i += 5)
			{
				let txt = i
				if (txt < 10)
					txt = '0' + txt
				duration_minutes.push({
					text: txt,
					value: i
				})
			}
			let subtitle = ""
			let description = ""
			let comment = ""
			let location = ""
			for (let i = 0; i < this.state.form1.translations.length; i++)
			{
				if (this.state.form1.translations[i].lang === this.props.current_lang)
				{
					subtitle = this.state.form1.translations[i].subtitle
					description = this.state.form1.translations[i].description
					comment = this.state.form1.translations[i].comment
					location = this.state.form1.translations[i].location
					break
				}
			}
			let simultaneousJSX = null
			if (this.state.form1.limit === true)
			{
				simultaneousJSX = (
					<label>
						<p className="error">{this.state.error.simultaneous}</p>
						<p>Limite de places / prestataire :</p>
						<input type="text" value={this.state.form1.simultaneous} onChange={this.handleSimultaneous} />
					</label>
				)
			}
			const prev = this.state.form1.prev
			const show_price = this.state.form1.show_price
			const price = this.state.form1.price
			const duration_hour = this.state.form1.duration_hour
			const duration_minute = this.state.form1.duration_minute
			const interval_hour = this.state.form1.interval_hour
			const interval_minute = this.state.form1.interval_minute
			const confirmation = this.state.form1.confirmation
			const questions = this.state.form1.questions
			const open_dates = this.state.form1.open_dates
			const open_hours = this.state.form1.open_hours
			let days = []
			for (let i = 1; i <= 31; i++)
			{
				let txt = i
				if (txt < 10)
					txt = '0' + txt
				days.push({
					text: txt,
					value: i
				})
			}
			const month_list = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre']
			let months = []
			for (let i = 0; i < 12; i++)
			{
				const txt = month_list[i]
				months.push({
					text: txt,
					value: i + 1
				})
			}
			let hours = []
			for (let i = 0; i <= 23; i++)
			{
				let txt = i
				if (txt < 10)
					txt = '0' + txt
				hours.push({
					text: txt,
					value: i
				})
			}
			let minutes = []
			for (let i = 0; i <= 59; i++)
			{
				let txt = i
				if (txt < 10)
					txt = '0' + txt
				minutes.push({
					text: txt,
					value: i
				})
			}
			let open_dates_k = -1
			let open_hours_k = -1
			let questions_k = -1
			let prevIcon = null
			if (prev !== null)
				prevIcon = (<div><p>Prévisualisation :</p><p><img src={prev} className="small" alt="Prévisualisation" /></p></div>)
			let priceJSX = null
			if (show_price === true)
			{
				priceJSX = (
					<label>
						<p className="error">{this.state.error.price}</p>
						<p>Prix (€) :</p>
						<input type="text" value={price} onChange={this.handlePrice} />
					</label>
				)
			}
			return (
				<div className="wrapper-content">
					<div className="row">
						<div className="module col s12">
							<LangSelector lang={this.props.current_lang} updateLang={this.props.updateLang} />
							<form className="module-form" onSubmit={this.handleSubmit}>
								<div className="label">
									<p className="error">{this.state.error.icon}</p>
									<p>Icône :</p>
									<IconPicker theme={this.state.theme._id} type="fill" icons={this.state.icons} onChange={this.handleIcon} />
									{prevIcon}
								</div>
								<label className="input-field">
									<p className="error">{this.state.error.subtitle}</p>
									<p>Sous-titre (version {this.props.current_lang.toUpperCase()}) :</p>
									<input type="text" value={subtitle} onChange={this.handleSubtitle} />
								</label>
								<label className="input-field">
									<p className="error">{this.state.error.description}</p>
									<p>Description (version {this.props.current_lang.toUpperCase()}) :</p>
								</label>
								<CKEditor
									data={description}
									updateContent={this.handleDescription}
								/>
								<label>
									<p>Afficher le prix :</p>
									<input type="checkbox" checked={show_price} onChange={this.handleShowPrice} /><span></span>
								</label>
								{priceJSX}
								<label className="input-field">
									<p className="error">{this.state.error.comment}</p>
									<p>Commentaire (version {this.props.current_lang.toUpperCase()}) :</p>
								</label>
								<CKEditor
									data={comment}
									updateContent={this.handleComment}
								/>
								<label>
									<p className="error">{this.state.error.location}</p>
									<p>Lieu (version {this.props.current_lang.toUpperCase()}) :</p>
									<input type="text" value={location} onChange={this.handleLocation} />
								</label>
								<div className="row">
									<p className="error">{this.state.error.duration}</p>
									<p>Durée :</p>
									<div className="col s2">
										<label>
											<p>Heures (durée) :</p>
											<select value={duration_hour} onChange={this.handleDurationHour}>
												<option value=""></option>
												{duration_hours.map(({ text, value }) => {
													return (<option key={value} value={value}>{text}</option>)
												})}
											</select>
										</label>
									</div>
									<div className="col s2 offset-s1">
										<label>
											<p>Minutes (durée) :</p>
											<select value={duration_minute} onChange={this.handleDurationMinute}>
												<option value=""></option>
												{duration_minutes.map(({ text, value }) => {
													return (<option key={value} value={value}>{text}</option>)
												})}
											</select>
										</label>
									</div>
								</div>
								<div className="row">
									<p className="error">{this.state.error.interval}</p>
									<p>Intervalle :</p>
									<div className="col s2">
										<label>
											<p>Heures (durée) :</p>
											<select value={interval_hour} onChange={this.handleIntervalHour}>
												{duration_hours.map(({ text, value }) => {
													return (<option key={value} value={value}>{text}</option>)
												})}
											</select>
										</label>
									</div>
									<div className="col s2 offset-s1">
										<label>
											<p>Minutes (durée) :</p>
											<select value={interval_minute} onChange={this.handleIntervalMinute}>
												{duration_minutes.map(({ text, value }) => {
													return (<option key={value} value={value}>{text}</option>)
												})}
											</select>
										</label>
									</div>
								</div>
								<label>
									<p>Réservation automatique :</p>
									<input type="checkbox" checked={confirmation} onChange={this.handleConfirmation} /><span></span>
								</label>
								<label>
									<p>Limiter les réservations en simultané :</p>
									<input type="checkbox" checked={this.state.form1.limit} onChange={this.handleLimit} /><span></span>
								</label>
								{simultaneousJSX}
								<p className="center x-large">Dates d'ouverture :</p>
								{open_dates.map(({ start_day, start_month, end_day, end_month }) => {
									open_dates_k++
									return (
										<div key={open_dates_k}>
											<p>Période {open_dates_k + 1} :</p>
											<div className="form-group">
												<p className="error">{this.state.error.open_dates[open_dates_k]}</p>
												<div className="col s2">
													<label>
														<p>Jour (début) :</p>
														<select value={start_day} onChange={this.handleStartDay.bind(this, open_dates_k)}>
															<option value=""></option>
															{days.map(({ text, value }) => {
																return (<option key={value} value={value}>{text}</option>)
															})}
														</select>
													</label>
												</div>
												<div className="col s2 offset-s1">
													<label>
														<p>Mois (début) :</p>
														<select value={start_month} onChange={this.handleStartMonth.bind(this, open_dates_k)}>
															<option value=""></option>
															{months.map(({ text, value }) => {
																return (<option key={value} value={value}>{text}</option>)
															})}
														</select>
													</label>
												</div>
												<div className="col s2 offset-s2">
													<label>
														<p>Jour (fin) :</p>
														<select value={end_day} onChange={this.handleEndDay.bind(this, open_dates_k)}>
															<option value=""></option>
															{days.map(({ text, value }) => {
																return (<option key={value} value={value}>{text}</option>)
															})}
														</select>
													</label>
												</div>
												<div className="col s2 offset-s1">
													<label>
														<p>Mois (fin) :</p>
														<select value={end_month} onChange={this.handleEndMonth.bind(this, open_dates_k)}>
															<option value=""></option>
															{months.map(({ text, value }) => {
																return (<option key={value} value={value}>{text}</option>)
															})}
														</select>
													</label>
												</div>
												<div className="center">
													<p className="error">{this.state.error.remove_open_dates[open_dates_k]}</p>
													<button type="button" className="waves-effect waves-light btn red" onClick={this.handleRemoveOpenDate.bind(this, open_dates_k)}>Supprimer</button>
												</div>
											</div>
										</div>
									)
								})}
								<div className="center">
									<button type="button" onClick={this.handleAddOpenDate} className="btn-floating btn-large waves-effect waves-light teal">
										<i className="material-icons">add</i>
									</button>
								</div>
								<p className="center x-large">Horaires d'ouverture :</p>
								{open_hours.map(({ monday, tuesday, wednesday, thursday, friday, saturday, sunday, start_hour, start_minute, end_hour, end_minute }) => {
									open_hours_k++
									return (
										<div key={open_hours_k}>
											<p>Créneau {open_hours_k + 1} :</p>
											<div className="form-group">
												<p className="error">{this.state.error.open_hours_weekdays[open_hours_k]}</p>
												<div className="column-10">
													<label>
														<span className="weekdays">L</span>
														<div className="clear"></div>
														<input type="checkbox" checked={monday} onChange={this.handleWeekday.bind(this, open_hours_k, 'monday')} /><span></span>
													</label>
												</div>
												<div className="column-10">
													<label>
														<span className="weekdays">M</span>
														<div className="clear"></div>
														<input type="checkbox" checked={tuesday} onChange={this.handleWeekday.bind(this, open_hours_k, 'tuesday')} /><span></span>
													</label>
												</div>
												<div className="column-10">
													<label>
														<span className="weekdays">M</span>
														<div className="clear"></div>
														<input type="checkbox" checked={wednesday} onChange={this.handleWeekday.bind(this, open_hours_k, 'wednesday')} /><span></span>
													</label>
												</div>
												<div className="column-10">
													<label>
														<span className="weekdays">J</span>
														<div className="clear"></div>
														<input type="checkbox" checked={thursday} onChange={this.handleWeekday.bind(this, open_hours_k, 'thursday')} /><span></span>
													</label>
												</div>
												<div className="column-10">
													<label>
														<span className="weekdays">V</span>
														<div className="clear"></div>
														<input type="checkbox" checked={friday} onChange={this.handleWeekday.bind(this, open_hours_k, 'friday')} /><span></span>
													</label>
												</div>
												<div className="column-10">
													<label>
														<span className="weekdays">S</span>
														<div className="clear"></div>
														<input type="checkbox" checked={saturday} onChange={this.handleWeekday.bind(this, open_hours_k, 'saturday')} /><span></span>
													</label>
												</div>
												<div className="column-10">
													<label>
														<span className="weekdays">D</span>
														<div className="clear"></div>
														<input type="checkbox" checked={sunday} onChange={this.handleWeekday.bind(this, open_hours_k, 'sunday')} /><span></span>
													</label>
												</div>
												<div className="clear"></div>
												<p className="error">{this.state.error.open_hours_time[open_hours_k]}</p>
												<div className="col s2">
													<label>
														<p>Heure (début) :</p>
														<select value={start_hour} onChange={this.handleStartHour.bind(this, open_hours_k)}>
															<option value=""></option>
															{hours.map(({ text, value }) => {
																return (<option key={value} value={value}>{text}</option>)
															})}
														</select>
													</label>
												</div>
												<div className="col s2 offset-s1">
													<label>
														<p>Minute (début) :</p>
														<select value={start_minute} onChange={this.handleStartMinute.bind(this, open_hours_k)}>
															<option value=""></option>
															{minutes.map(({ text, value }) => {
																return (<option key={value} value={value}>{text}</option>)
															})}
														</select>
													</label>
												</div>
												<div className="col s2 offset-s2">
													<label>
														<p>Heure (fin) :</p>
														<select value={end_hour} onChange={this.handleEndHour.bind(this, open_hours_k)}>
															<option value=""></option>
															{hours.map(({ text, value }) => {
																return (<option key={value} value={value}>{text}</option>)
															})}
														</select>
													</label>
												</div>
												<div className="col s2 offset-s1">
													<label>
														<p>Minute (fin) :</p>
														<select value={end_minute} onChange={this.handleEndMinute.bind(this, open_hours_k)}>
															<option value=""></option>
															{minutes.map(({ text, value }) => {
																return (<option key={value} value={value}>{text}</option>)
															})}
														</select>
													</label>
												</div>
												<div className="center">
													<p className="error">{this.state.error.remove_open_hours[open_hours_k]}</p>
													<button type="button" className="waves-effect waves-light btn red" onClick={this.handleRemoveOpenHour.bind(this, open_hours_k)}>Supprimer</button>
												</div>
											</div>
										</div>
									)
								})}
								<div className="center">
									<button type="button" onClick={this.handleAddOpenHour} className="btn-floating btn-large waves-effect waves-light teal">
										<i className="material-icons">add</i>
									</button>
								</div>
								<p className="center x-large">Informations de base :</p>
								<div className="col s2 offset-s1">
									<label>
										<p>Numéro client :</p>
										<input type="checkbox" checked={this.state.form1.client_number} onChange={this.handleClientNumber} /><span></span>
									</label>
								</div>
								<div className="col s2 offset-s1">
									<label>
										<p>Identité :</p>
										<input type="checkbox" checked={this.state.form1.identity} onChange={this.handleIdentity} /><span></span>
									</label>
								</div>
								<div className="col s2 offset-s1">
									<label>
										<p>Âge :</p>
										<input type="checkbox" checked={this.state.form1.age} onChange={this.handleAge} /><span></span>
									</label>
								</div>
								<div className="col s2 offset-s1">
									<label>
										<p>Téléphone :</p>
										<input type="checkbox" checked={this.state.form1.phone} onChange={this.handlePhone} /><span></span>
									</label>
								</div>
								<div className="clear"></div>
								<LangSelector lang={this.props.current_lang} updateLang={this.props.updateLang} />
								<p className="center x-large">Questions :</p>
								{questions.map(({ type, mandatory, translations }) => {
									let title = ""
									for (let i = 0; i < translations.length; i++)
									{
										if (translations[i].lang === this.props.current_lang)
										{
											title = translations[i].title
											break
										}
									}
									questions_k++
									return (
										<div key={questions_k}>
											<p>Question {questions_k + 1} :</p>
											<div className="form-group">
												<p className="error">{this.state.error.questions_title[questions_k]}</p>
												<label>
													<p>Titre (version {this.props.current_lang.toUpperCase()}) :</p>
													<input type="text" value={title} onChange={this.handleQuestionTitle.bind(this, questions_k)} />
												</label>
												<p className="error">{this.state.error.questions_type[questions_k]}</p>
												<label>
													<p>Type :</p>
													<select value={type} onChange={this.handleQuestionType.bind(this, questions_k)}>
														<option value=""></option>
														<option value="text">Texte</option>
														<option value="number">Nombre</option>
													</select>
												</label>
												<label>
													<p>Obligatoire :</p>
													<input type="checkbox" checked={mandatory} onChange={this.handleQuestionMandatory.bind(this, questions_k)} /><span></span>
												</label>
												<div className="center">
													<p className="error">{this.state.error.remove_questions[questions_k]}</p>
													<button type="button" className="waves-effect waves-light btn red" onClick={this.handleRemoveQuestion.bind(this, questions_k)}>Supprimer</button>
												</div>
											</div>
										</div>
									)
								})}
								<div className="center">
									<p className="error">{this.state.error.add_questions}</p>
									<button type="button" onClick={this.handleAddQuestion} className="btn-floating btn-large waves-effect waves-light teal">
										<i className="material-icons">add</i>
									</button>
								</div>
								<div className="center">
									<p className="error">{this.state.error.global}</p>
									<button type="submit" className="waves-effect waves-light btn">Enregistrer</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			)
		}
	}
}

export default Benefits

import React, { Component } from 'react'
import Loader from '../UI/Loader'
import CKEditor from '../UI/CKEditor'
import IconPicker from '../UI/IconPicker'
import LangSelector from '../UI/LangSelector'
import M from 'materialize-css'
import '../Core/Main.css'

class Products extends Component {
	constructor(props) {
		super(props)
		this.state = this._initState()
		this.length_max = 50
		this.services_max = 20
		this.question_title_length = 20
		this.questions_max = 5
	}

	componentDidMount() {
		this.loadTheme()
		this.loadIcons()
	}

	componentDidUpdate(prevProps, prevState) {
		const elems = document.querySelectorAll('select')
		M.FormSelect.init(elems)
		if (prevProps.click_modal !== this.props.click_modal)
		{
			this.setState(this._initState())
			this.loadTheme()
			this.loadIcons()
		}
	}

	loadIcons = () => {
		this.props.fetchIcons()
		.then(icons => {
			let form1 = this.state.form1
			if (this.props.edit_modal !== false)
			{
				let prev = null
				const product = this.props.edit_modal
				for (let i = 0; i < icons.length; i++)
				{
					if (icons[i]._id === product.icon)
					{
						for (let j = 0; j < icons[i].fill.length; j++)
						{
							if (icons[i].fill[j].theme === this.state.theme._id)
							{
								prev = icons[i].fill[j].icon
								break
							}
						}
						break
					}
				}
				form1.prev = prev
			}
			this.setState({ icons: icons, form1: form1 })
		})
	}

	loadTheme = () => {
		this.props.fetchTheme()
		.then(theme => {
			this.setState({ theme: theme })
		})
		.catch(err => console.log("Could not fetch theme", err))
	}

	_initState = () => {
		let state = {
			edit: null,
			create: false,
			attempt_submit: false,
			icons: undefined,
			theme: undefined,
			open_modal: false,
			modal_msg: null,
			form1: {
				icon: null,
				prev: null,
				price: '',
				limit: false,
				stock: null,
				translations: []
			},
			error: this._initErrors(),
			success: null
		}
		if (this.props.edit_modal !== false)
		{
			const product = this.props.edit_modal
			const form1 = {
				icon: product.icon,
				prev: null,
				price: product.price,
				limit: (product.stock === null) ? false : true,
				stock: product.stock,
				open_dates: product.open_dates,
				open_hours: product.open_hours,
				translations: product.translations
			}
			state.form1 = form1
		}
		return state
	}

	_initErrors = () => {
		const errors = {
			global: null,
			icon: null,
			subtitle: null,
			description: null,
			price: null
		}
		return errors
	}

	setErrorMsg = (key, msg, id = null) => {
		let error = this.state.error
		if (id !== null)
			error[key][id] = msg
		else
			error[key] = msg
		this.setState({ success: null, error: error })
	}

	setSuccessMsg = (msg) => {
		const error = this._initErrors()
		this.setState({ success: msg, error: error })
	}

	clearFlash = () => {
		const error = this._initErrors()
		this.setState({ error: error, success: null })
	}
	
	clickCreate = () => {
		this.clearFlash()
		const form1 = {
			icon: null,
			prev: null,
			price: '',
			open_dates: [{
				start_day: '',
				start_month: '',
				end_day: '',
				end_month: ''
			}],
			open_hours: [{
				monday: false,
				tuesday: false,
				wednesday: false,
				thursday: false,
				friday: false,
				saturday: false,
				sunday: false,
				start_hour: '',
				start_minute: '',
				end_hour: '',
				end_minute: ''
			}],
			translations: []
		}
		this.setState({ edit: null, form1: form1, create: true })
	}

	handleSubtitle = (event) => {
		let found = false
		const translations = this.state.form1.translations
		for (let i = 0; i < translations.length; i++)
		{
			if (translations[i].lang === this.props.current_lang)
			{
				found = true
				translations[i].subtitle = event.target.value
				break
			}
		}
		if (!found)
		{
			translations.push({
				lang: this.props.current_lang,
				subtitle: event.target.value,
				description: ''
			})
		}
		const form1 = {
			...this.state.form1,
			translations: translations
		}
		this.setState({ form1: form1 })
	}

	handleIcon = (icon) => {
		const icons = this.state.icons
		let prev = null
		for (let i = 0; i < icons.length; i++)
		{
			if (icons[i]._id === icon)
			{
				for (let j = 0; j < icons[i].fill.length; j++)
				{
					if (icons[i].fill[j].theme === this.state.theme._id)
					{
						prev = icons[i].fill[j].icon
						break
					}
				}
				break
			}
		}
		const form1 = {
			...this.state.form1,
			icon: icon,
			prev: prev
		}
		this.setState({ form1: form1 })
	}

	handleDescription = (html) => {
		let found = false
		const translations = this.state.form1.translations
		for (let i = 0; i < translations.length; i++)
		{
			if (translations[i].lang === this.props.current_lang)
			{
				found = true
				translations[i].description = html
				break
			}
		}
		if (!found)
		{
			translations.push({
				lang: this.props.current_lang,
				subtitle: '',
				description: html
			})
		}
		const form1 = {
			...this.state.form1,
			translations: translations
		}
		this.setState({ form1: form1 })
	}

	handlePrice = (event) => {
		const form1 = {
			...this.state.form1,
			price: event.target.value
		}
		this.setState({ form1: form1 })
	}

	handleLimit = (event) => {
		const form1 = {
			...this.state.form1,
			limit: (this.state.form1.limit === true) ? false : true,
			stock: (this.state.form1.limit === true) ? null : 1
		}
		this.setState({ form1: form1 })
	}

	handleStock = (event) => {
		const form1 = {
			...this.state.form1,
			stock: event.target.value
		}
		this.setState({ form1: form1 })
	}

	_validateForm1 = () => {
		let error_obj = this._initErrors()
		let error = false
		const price = Number(this.state.form1.price)
		if (this.state.form1.icon === null)
		{
			error = true
			error_obj.icon = "Vous devez sélectionner un icône"
		}
		if (this.state.form1.price.length === 0)
		{
			error = true
			error_obj.price = "Le prix ne doit pas être vide"
		}
		else if (!/^[+-]?[0-9]+([.,][0-9]{1,2})?$/.test(this.state.form1.price))
		{
			error = true
			error_obj.price = "Le prix doit être un nombre valide"
		}
		else if (price < 0)
		{
			error = true
			error_obj.price = "Le prix doit être un nombre positif"
		}
		else if (price > 1000000)
		{
			error = true
			error_obj.price = "Le prix doit être inférieur ou égal à 1 000 000€"
		}
		const translations = this.state.form1.translations
		let found_default_subtitle = false
		let found_default_description = false
		for (let i = 0; i < translations.length; i++)
		{
			const lang = translations[i].lang
			const subtitle = translations[i].subtitle
			if (subtitle.length === 0 && lang === this.props.lang_default)
			{
				error = true
				error_obj.subtitle = "Le sous-titre ne doit pas être vide (version " + lang.toUpperCase() + ")"
			}
			else if (subtitle.length > this.length_max)
			{
				error = true
				error_obj.subtitle = "Le sous-titre ne doit pas dépasser " + this.length_max + " caractères (version " + lang.toUpperCase() + ")"
			}
			else if (lang === this.props.lang_default)
				found_default_subtitle = true
			const description = translations[i].description
			if (description.length === 0 && lang === this.props.lang_default)
			{
				error = true
				error_obj.description = "La description ne doit pas être vide (version " + lang.toUpperCase() + ")"
			}
			else if (lang === this.props.lang_default)
				found_default_description = true
		}
		if (!found_default_subtitle && !error_obj.subtitle)
		{
			error = true
			error_obj.subtitle = 'Le sous-titre ne doit pas être vide (version ' + this.props.lang_default.toUpperCase() + ')'
		}
		if (!found_default_description && !error_obj.description)
		{
			error = true
			error_obj.description = 'La description ne doit pas être vide (version ' + this.props.lang_default.toUpperCase() + ')'
		}
		if (error === true)
		{
			error_obj.global = "Il y a une ou plusieurs erreur(s) dans le formulaire"
			this.setState({ success: null, error: error_obj })
		}
		return (!error) ? true : false
	}

	handleSubmit = (event) => {
		event.preventDefault()
		if (this._validateForm1())
		{
			const translations = this.state.form1.translations
			let langs_not_found = []
			for (let i = 0; i < this.props.langs.length; i++)
			{
				let found = false
				for (let j = 0; j < translations.length; j++)
				{
					if (this.props.langs[i] === translations[j].lang && translations[j].subtitle.length > 0 &&
						translations[j].description.length > 0)
					{
						found = true
						break
					}
				}
				if (!found)
					langs_not_found.push(this.props.langs[i])
			}
			if (langs_not_found.length > 0)
			{
				let msg = ""
				for (let i = 0; i < langs_not_found.length; i++)
				{
					if (i > 0)
						msg += ', '
					msg += langs_not_found[i].toUpperCase()
				}
				msg = "Le produit n'est pas traduit dans les langues suivantes : " + msg
				this.props.updateModal(msg, true, this.answerModalEdit)
			}
			else
				this._insertServiceProduct()
		}
	}

	_insertServiceProduct = () => {
		let product = {
			service: this.props.service._id,
			icon: this.state.form1.icon,
			price: this.state.form1.price,
			stock: this.state.form1.stock,
			translations: this.state.form1.translations,
			user: this.props.user._id
		}
		if (this.props.edit_modal === false)
		{
			this.props.insertServiceProduct(product)
			.then(ret => {
				this.props.updateModal(null, false, null)
				this.props.closeModal('product', 'Le produit a bien été créé')
				this.clearFlash()
			})
			.catch(err => {
				this.props.updateModal(null, false, null)
				console.log(err)
			})
		}
		else
		{
			product._id = this.props.edit_modal._id
			this.props.updateServiceProduct(product)
			.then(ret => {
				this.props.updateModal(null, false, null)
				this.props.closeModal('product', 'Le produit a bien été mis à jour')
				this.clearFlash()
			})
			.catch(err => {
				this.props.updateModal(null, false, null)
				console.log(err)
			})
		}
	}

	answerModalEdit = (answer) => {
		if (answer === "yes")
			this._insertServiceProduct()
		else
		{
			this.props.updateModal(null, false, null)
			this.setState({ attempt_submit: false })
		}
	}

	render() {
		if (this.state.theme === undefined || this.state.icons === undefined)
		{
			return (
				<div className="loader">
					<Loader />
				</div>
			)
		}
		else
		{
			let subtitle = ""
			let description = ""
			for (let i = 0; i < this.state.form1.translations.length; i++)
			{
				if (this.state.form1.translations[i].lang === this.props.current_lang)
				{
					subtitle = this.state.form1.translations[i].subtitle
					description = this.state.form1.translations[i].description
					break
				}
			}
			let stockJSX = null
			if (this.state.form1.limit === true)
			{
				stockJSX = (
					<label>
						<p className="error">{this.state.error.stock}</p>
						<p>Limite de stock / créneau :</p>
						<input type="text" value={this.state.form1.stock} onChange={this.handleStock} />
					</label>
				)
			}
			const prev = this.state.form1.prev
			const price = this.state.form1.price
			let prevIcon = null
			if (prev !== null)
				prevIcon = (<div><p>Prévisualisation :</p><p><img src={prev} className="small" alt="Prévisualisation" /></p></div>)
			return (
				<div className="wrapper-content">
					<div className="row">
						<div className="module col s12">
							<LangSelector lang={this.props.current_lang} updateLang={this.props.updateLang} />
							<form className="module-form" onSubmit={this.handleSubmit}>
								<div className="label">
									<p className="error">{this.state.error.icon}</p>
									<p>Icône :</p>
									<IconPicker theme={this.state.theme._id} type="fill" icons={this.state.icons} onChange={this.handleIcon} />
									{prevIcon}
								</div>
								<label className="input-field">
									<p className="error">{this.state.error.subtitle}</p>
									<p>Nom du produit (version {this.props.current_lang.toUpperCase()}) :</p>
									<input type="text" value={subtitle} onChange={this.handleSubtitle} />
								</label>
								<label className="input-field">
									<p className="error">{this.state.error.description}</p>
									<p>Description (version {this.props.current_lang.toUpperCase()}) :</p>
								</label>
								<CKEditor
									data={description}
									updateContent={this.handleDescription}
								/>
								<label>
									<p className="error">{this.state.error.price}</p>
									<p>Prix (€) :</p>
									<input type="text" value={price} onChange={this.handlePrice} />
								</label>
								<label>
									<p>Limiter le stock :</p>
									<input type="checkbox" checked={this.state.form1.limit} onChange={this.handleLimit} /><span></span>
								</label>
								{stockJSX}
								<div className="center">
									<p className="error">{this.state.error.global}</p>
									<button type="submit" className="waves-effect waves-light btn">Enregistrer</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			)
		}
	}
}

export default Products

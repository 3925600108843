import React, { Component } from 'react'
import Categories from './Categories'
import IconPicker from '../UI/IconPicker'
import Modal from '../UI/Modal'
import Loader from '../UI/Loader'
import LangSelector from '../UI/LangSelector'
import M from 'materialize-css'
import tools from '../../tools'
import '../Core/Main.css'
import { Link, Redirect } from 'react-router-dom'
import { HomeIcon } from '@heroicons/react/solid'
import EmptyFolder from '../UI/EmptyFolder'
import BannerInfo from '../UI/BannerInfo'

class Rubrics extends Component {
	constructor(props) {
		super(props)
		this.state = this._initState()
		this.props.updateNavbarTitle('Rubriques')
		this.length_max = 40
	}

	componentDidMount() {
		this.loadColors()
		this.loadGroups()
	}

	componentDidUpdate(prevProps) {
		const elems = document.querySelectorAll('select')
		M.FormSelect.init(elems)
		if (prevProps.groups !== this.props.groups)
			this.loadGroups(true)
		if (prevProps.location.key !== this.props.location.key)
		{
			this.setState(this._initState())
			this.loadColors()
			this.loadGroups()
		}
	}

	_initState = () => {
		return {
			edit: false,
			show: false,
			groups: null,
			group: null,
			theme: null,
			icons: null,
			modal_edit: false,
			modal_delete: false,
			modal_msg: null,
			delete: false,
			attempt_submit: false,
			categories: null,
			form1: {
				translations: [],
				icon: null,
				prev: null
			},
			navigation: [
				{ name: 'Rubriques', href: '/Rubriques', current: false },
			],
			show_banner: false,
			banner_content: null,
			current_lang: this.props.lang_default,
			error: this._initErrors(),
			success: null
		}
	}

	_initErrors = () => {
		return {
			global: null,
			icon: null,
			name: null
		}
	}

	setErrorMsg = (key, msg) => {
		if (key === "error_fetch_groups")
		{
			key = "global"
			msg = tools.default_error_msg("Impossible de récupérer les rubriques", msg)
		}
		else if (key === "error_create_group")
		{
			key = "global"
			msg = tools.default_error_msg("Impossible de créer une rubrique", msg)
		}
		else if (key === "error_update_group")
		{
			key = "global"
			msg = tools.default_error_msg("Impossible de modifier une rubrique", msg)
		}
		else if (key === "error_delete_group")
		{
			key = "global"
			msg = tools.default_error_msg("Impossible de supprimer une rubrique", msg)
		}
		else if (key === "error_icon")
		{
			key = "global"
			msg = tools.default_error_msg("Impossible de récupérer les icônes", msg)
		}
		let error = this._initErrors()
		error[key] = msg
		this.setState({ success: null, error: error, show_banner: true, banner_content: msg })
	}

	setSuccessMsg = (msg) => {
		this.clearFlash()
		this.setState({ banner_content: msg, show_banner: true, success: msg, error: this._initErrors() })
	}

	clearFlash = () => {
		this.setState({ success: null, error: this._initErrors() })
	}

	closeBanner = () => {
		this.setState({ show_banner: false })
	}

	loadColors = () => {
		this.props.fetchTheme()
		.then(theme => {
			this.setState({ theme: theme })
		})
		.catch(err => {
			this.setErrorMsg("error_icon", 1)
			console.log(err)
		})
	}

	loadGroups = (fromProps = false) => {
		if (fromProps === true && this.props.groups)
		{
			const groups = this.props.groups
			groups.sort((a, b) => {
				let name_a = null
				let name_b = null
				for (let i = 0; i < a.translations.length; i++)
				{
					if (a.translations[i].lang === this.props.lang_default)
					{
						name_a = a.translations[i].name
						break
					}
				}
				for (let i = 0; i < b.translations.length; i++)
				{
					if (b.translations[i].lang === this.props.lang_default)
					{
						name_b = b.translations[i].name
						break
					}
				}
				if (!name_a && !name_b)
					return 0
				else if (!name_a)
					return 1
				else if (!name_b)
					return -1
				else
					return name_a.localeCompare(name_b)
			})
			this.props.fetchIcons()
			.then(icons => {
				for (let k = 0; k < groups.length; k++)
				{
					for (let i = 0; i < icons.length; i++)
					{
						if (icons[i]._id === groups[k].icon)
						{
							for (let j = 0; j < icons[i].lineal.length; j++)
							{
								if (icons[i].lineal[j].theme === "blank")
								{
									groups[k].prev = icons[i].lineal[j].icon
									break
								}
							}
							break
						}
					}
				}
				this.setState({ icons: icons, groups: groups })
			})
			.catch(err => {
				this.setErrorMsg("error_icon", 2)
				console.log(err)
			})
		}
		else
		{
			this.props.fetchGroups()
			.then(groups => {
				groups.sort((a, b) => {
					let name_a = null
					let name_b = null
					for (let i = 0; i < a.translations.length; i++)
					{
						if (a.translations[i].lang === this.state.current_lang)
						{
							name_a = a.translations[i].name
							break
						}
					}
					for (let i = 0; i < b.translations.length; i++)
					{
						if (b.translations[i].lang === this.state.current_lang)
						{
							name_b = b.translations[i].name
							break
						}
					}
					return name_a.localeCompare(name_b)
				})
				this.props.fetchIcons()
				.then(icons => {
					for (let k = 0; k < groups.length; k++)
					{
						for (let i = 0; i < icons.length; i++)
						{
							if (icons[i]._id === groups[k].icon)
							{
								for (let j = 0; j < icons[i].lineal.length; j++)
								{
									if (icons[i].lineal[j].theme === "blank")
									{
										groups[k].prev = icons[i].lineal[j].icon
										break
									}
								}
								break
							}
						}
					}
					this.setState({ icons: icons, groups: groups })
				})
				.catch(err => {
					this.setErrorMsg("error_icon", 3)
					console.log(err)
				})
			})
			.catch(err => {
				this.setErrorMsg("error_fetch_groups", 1)
				console.log(err)
			})
		}
	}

	handleTitle = (event) => {
		let found = false
		const translations = this.state.form1.translations
		for (let i = 0; i < translations.length; i++)
		{
			if (translations[i].lang === this.state.current_lang)
			{
				found = true
				translations[i].name = event.target.value
				break
			}
		}
		if (!found)
		{
			translations.push({
				lang: this.state.current_lang,
				name: event.target.value
			})
		}
		const form1 = {
			...this.state.form1,
			translations: translations
		}
		this.setState({ form1: form1 })
	}

	handleIcon = (icon) => {
		const icons = this.state.icons
		let prev = null
		for (let i = 0; i < icons.length; i++)
		{
			if (icons[i]._id === icon)
			{
				for (let j = 0; j < icons[i].lineal.length; j++)
				{
					if (icons[i].lineal[j].theme === "blank")
					{
						prev = icons[i].lineal[j].icon
						break
					}
				}
				break
			}
		}
		const form1 = {
			...this.state.form1,
			icon: icon,
			prev: prev
		}
		this.setState({ form1: form1 })
	}

	_validateForm1 = () => {
		let error_obj = this._initErrors()
		let error = false
		const icon = this.state.form1.icon
		const translations = this.state.form1.translations
		if (!icon)
		{
			error = true
			error_obj.icon = "Veuillez choisir un icône"
		}
		let found_default_name = false
		for (let i = 0; i < translations.length; i++)
		{
			const lang = translations[i].lang
			const name = translations[i].name
			if (name.length === 0 && lang === this.props.lang_default)
			{
				error = true
				error_obj.name = "Veuillez indiquer le titre (version " + lang.toUpperCase() + ")"
			}
			else if (name.length > this.length_max)
			{
				error = true
				error_obj.name = "Le titre ne doit pas dépasser " + this.length_max + " caractères (version " + lang.toUpperCase() + ")"
			}
			else if (lang === this.props.lang_default)
				found_default_name = true
		}
		if (!found_default_name && !error_obj.name)
		{
			error = true
			error_obj.name = 'Vous devez indiquer le titre (version ' + this.props.lang_default.toUpperCase() + ')'
		}
		if (error === true)
		{
			error_obj.global = "Il y a une ou plusieurs erreur(s) dans le formulaire"
			this.setState({ success: null, error: error_obj, attempt_submit: false })
		}
		return (!error) ? true : false
	}

	submitEdit = (event) => {
		event.preventDefault()
		this.setState({ attempt_submit: true })
		if (this._validateForm1())
		{
			const translations = this.state.form1.translations
			let langs_not_found = []
			for (let i = 0; i < this.props.langs.length; i++)
			{
				let found = false
				for (let j = 0; j < translations.length; j++)
				{
					if (this.props.langs[i] === translations[j].lang && translations[j].name.length > 0)
					{
						found = true
						break
					}
				}
				if (!found)
					langs_not_found.push(this.props.langs[i])
			}
			if (langs_not_found.length > 0)
			{
				let msg = ""
				for (let i = 0; i < langs_not_found.length; i++)
				{
					if (i > 0)
						msg += ', '
					msg += langs_not_found[i].toUpperCase()
				}
				this.setState({ modal_msg: msg, modal_edit: true })
			}
			else
				this._insertGroup()
		}
	}

	_insertGroup = () => {
		const icon = this.state.form1.icon
		const translations = this.state.form1.translations
		if (this.state.group !== null)
		{
			const group = {
				_id: this.state.group._id,
				translations: translations,
				icon: icon
			}
			this.props.updateGroup(group)
			.then(ret => {
				if (ret === false)
				{
					this.setState({ attempt_submit: false, modal_edit: false })
					this.setErrorMsg("error_update_group", 1)
				}
				else
				{
					this.setState({ edit: false, attempt_submit: false, modal_edit: false })
					this.setSuccessMsg("La rubrique a bien été mise à jour")
				}
			})
			.catch(err => {
				this.setState({ attempt_submit: false, modal_edit: false })
				this.setErrorMsg("error_update_group", 2)
				console.log(err)
			})
		}
		else
		{
			const group = {
				translations: translations,
				icon: icon
			}
			this.props.insertGroup(group)
			.then(ret => {
				if (ret === false)
				{
					this.setState({ attempt_submit: false, modal_edit: false })
					this.setErrorMsg("error_create_group", 1)
				}
				else
				{
					const form1 = {
						translations: [],
						icon: null,
						prev: null
					}
					this.setSuccessMsg("La rubrique a bien été créée !")
					this.setState({ form1: form1, edit: false, attempt_submit: false, modal_edit: false })
				}
			})
			.catch(err => {
				this.setState({ attempt_submit: false, modal_edit: false })
				this.setErrorMsg("error_create_group", 2)
				console.log(err)
			})
		}
	}

	clickCreate = () => {
		const form1 = {
			translations: [],
			icon: null,
			prev: null
		}
		this.clearFlash()
		this.setState({ group: null, edit: true, form1: form1 })
	}

	clickSelect = (id) => {
		let group = null
		for (let i = 0; i < this.state.groups.length; i++)
		{
			if (this.state.groups[i]._id === id)
			{
				group = this.state.groups[i]
				break
			}
		}
		const show = (group) ? true : false
		this.setState({ show: show, group: group })
	}

	clickEdit = (id) => {
		let group = null
		let form1 = {
			translations: [],
			icon: null,
			prev: null
		}
		for (let i = 0; i < this.state.groups.length; i++)
		{
			if (this.state.groups[i]._id === id)
			{
				group = this.state.groups[i]
				form1 = {
					translations: group.translations,
					icon: group.icon,
					prev: group.prev
				}
				break
			}
		}
		let edit = (group) ? true : false
		this.clearFlash()
		this.setState({ edit: edit, group: group, form1: form1 })
	}

	clickDelete = (id) => {
		this.setState({ delete: id, modal_delete: true })
	}

	_deleteGroup = () => {
		this.props.deleteGroup(this.state.delete)
		.then(ret => {
			if (ret === false)
			{
				this.setState({ modal_delete: false })
				this.setErrorMsg("error_delete_group", 1)
			}
			else
			{
				this.setState({ modal_delete: false })
				this.setSuccessMsg("La rubrique a bien été supprimée.")
			}
		})
		.catch(err => {
			this.setState({ modal_delete: false })
			this.setErrorMsg("error_delete_group", 2)
			console.log(err)
		})
	}

	answerModalEdit = (answer) => {
		if (answer === "yes")
			this._insertGroup()
		else
			this.setState({ attempt_submit: false, modal_edit: false })
	}

	answerModalDelete = (answer) => {
		if (answer === "yes")
			this._deleteGroup()
		else
			this.setState({ delete: false, modal_delete: false })
	}

	unEdit = () => {
		this.setState({ edit: false })
	}

	unShow = () => {
		this.setState({ show: false })
	}

	updateLang = (lang) => {
		this.setState({ current_lang: lang })
	}

	render() {
		const permissions = this.props.user.permissions
		if (!permissions || !permissions.rubrics || permissions.rubrics === 0)
			return (<Redirect to="/" />)
		else if (this.state.groups === null || this.state.icons === null || this.state.theme === null)
		{
			return (
				<div className="loader">
					<Loader />
				</div>
			)
		}
		else if (this.state.edit === true)
		{
			let name = ""
			for (let i = 0; i < this.state.form1.translations.length; i++)
			{
				if (this.state.form1.translations[i].lang === this.state.current_lang)
				{
					name = this.state.form1.translations[i].name
					break
				}
			}
			let titleHeader = "Éditer une rubrique"
			let prev = null
			if (this.state.form1.prev)
				prev = (<img className="icon blank" src={this.state.form1.prev} alt="Prévisualisation" />)
			let sendbtn = (<button type="submit" className="waves-effect waves-light btn">Enregistrer</button>)
			if (this.state.attempt_submit === true)
			{
				sendbtn = (
					<div className="center">
						<Loader />
					</div>
				)
			}
			const modal_msg = "La rubrique n'est pas traduite dans les langues suivantes : " + this.state.modal_msg
			const navStyle = "flex bg-transparent shadow-none mb-10"
			return (
				<div className="wrapper-content">
					<nav className={navStyle} aria-label="Breadcrumb">
						<ol className="bg-white rounded-md shadow px-6 flex space-x-4">
							<li className="flex">
							<div className="flex items-center">
								<Link to="/" className="text-gray-400 hover:text-gray-500">
									<HomeIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
									<span className="sr-only">Home</span>
								</Link>
							</div>
							</li>
							<li className="flex">
								<div className="flex items-center" onClick={this.unEdit}>
									<svg
										className="flex-shrink-0 w-6 h-full text-gray-200"
										viewBox="0 0 24 44"
										preserveAspectRatio="none"
										fill="currentColor"
										xmlns="http://www.w3.org/2000/svg"
										aria-hidden="true"
									>
										<path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
									</svg>
									<p className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">Rubriques</p>
								</div>
							</li>
							<li className="flex">
								<div className="flex items-center">
									<svg
										className="flex-shrink-0 w-6 h-full text-gray-200"
										viewBox="0 0 24 44"
										preserveAspectRatio="none"
										fill="currentColor"
										xmlns="http://www.w3.org/2000/svg"
										aria-hidden="true"
									>
										<path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
									</svg>
									<p className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">Éditer une rubrique</p>
								</div>
							</li>
						</ol>
					</nav>
					<h1><span className="uhover" onClick={this.unEdit}>Rubriques</span> {titleHeader}</h1>
					<p className="center success">{this.state.success}</p>
					<Modal
						dismissible={false}
						content={modal_msg}
						open_modal={this.state.modal_edit}
						answerModal={this.answerModalEdit}
					/>
					<div className="row">
						<div className="module col s12">
							<LangSelector lang={this.state.current_lang} updateLang={this.updateLang} />
							<form onSubmit={this.submitEdit}>
								<p className="error">{this.state.error.name}</p>
								<label>
									<p>Titre (version {this.state.current_lang.toUpperCase()}) :</p>
									<input type="text" value={name} onChange={this.handleTitle} />
								</label>
								<p className="error">{this.state.error.icon}</p>
								<div className="label">
									<p>Icône :</p>
									<IconPicker theme="blank" type="lineal" icons={this.state.icons} onChange={this.handleIcon} />
									{prev}
								</div>
								<p className="error">{this.state.error.global}</p>
								{sendbtn}
							</form>
						</div>
					</div>
				</div>
			)
		}
		else if (this.state.show === true)
		{
			return (
				<Categories
					current_lang={this.state.current_lang}
					icons={this.state.icons}
					langs={this.props.langs}
					lang_default={this.props.lang_default}
					theme={this.state.theme}
					groups={this.state.groups}
					features={this.props.features}
					categories={this.props.categories}
					group={this.state.group}
					pages={this.props.pages}
					lists={this.props.lists}
					services={this.props.services}
					insertCategory={this.props.insertCategory}
					insertList={this.props.insertList}
					insertListElement={this.props.insertListElement}
					insertPage={this.props.insertPage}
					insertPageSection={this.props.insertPageSection}
					updateCategoriesOrder={this.props.updateCategoriesOrder}
					updateCategory={this.props.updateCategory}
					updateCategoryDuplicate={this.props.updateCategoryDuplicate}
					updateLang={this.updateLang}
					updateListCover={this.props.updateListCover}
					updateListElement={this.props.updateListElement}
					updateListOrderElements={this.props.updateListOrderElements}
					updateListTransformSubcategory={this.props.updateListTransformSubcategory}
					updatePageCover={this.props.updatePageCover}
					updatePageDuplicateSection={this.props.updatePageDuplicateSection}
					updatePageName={this.props.updatePageName}
					updatePageOrderSections={this.props.updatePageOrderSections}
					updatePageSection={this.props.updatePageSection}
					updatePageService={this.props.updatePageService}
					deleteCategory={this.props.deleteCategory}
					deleteListByCategory={this.props.deleteListByCategory}
					deleteListElement={this.props.deleteListElement}
					deletePage={this.props.deletePage}
					deletePageByCategory={this.props.deletePageByCategory}
					deletePageSection={this.props.deletePageSection}
					deletePageService={this.props.deletePageService}
					fetchPageByID={this.props.fetchPageByID}
					fetchPageByCategory={this.props.fetchPageByCategory}
					fetchListByCategory={this.props.fetchListByCategory}
					fetchCategoriesByGroup={this.props.fetchCategoriesByGroup}
					fetchServices={this.props.fetchServices}
					unShow={this.unShow}
				/>
			)
		}
		else
		{
			const groups = this.state.groups
			const modal_msg = "Vous supprimerez cette rubrique et tout le contenu qui s'y trouve. Cette action est irréversible"
			const navStyle = "flex bg-transparent shadow-none mb-10"
			let content = ""
			if (!groups || !groups.length)
			{
				content = (
					<EmptyFolder 
						emptyFolderSubtitle="Pas de rubrique"
					/>
				)
			}
			else
			{
				content = (
					<div className="mt-8 max-w-4xl">
						<div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
							<div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
								<div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
									<table className="min-w-full divide-y divide-gray-300">
										<thead className="bg-gray-50">
											<tr>
												<th>
													<span className="sr-only">Icone</span>
												</th>
												<th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-medium text-gray-700 sm:pl-6">
													Nom de rubrique
												</th>
												<th scope="col" className="py-3.5 pl-4 pr-3 text-right text-sm font-medium text-gray-700 sm:pl-6">
													Titre et icône
												</th>
												<th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
													<span className="sr-only">Supprimer</span>
												</th>
											</tr>
										</thead>
										<tbody className="divide-y divide-gray-200 bg-white">
											{groups.map(({ _id, translations, icon, prev }, id) => {
												let name = ""
												for (let i = 0; i < translations.length; i++)
												{
													if (translations[i].lang === this.state.current_lang)
													{
														name = translations[i].name
														break
													}
												}
												let image = null
												if (prev)
													image = (<div className="icon"><img src={prev} alt="Icône rubrique" /></div>)
												return (
													<tr key={id}>
														<td className="whitespace-nowrap py-4 pl-4 pr-3 text-base text-gray-900 sm:pl-6">
															<div className="w-10 h-10 bg-yellow-500 rounded-md flex justify-center items-center">
																<div className="w-8 h-8">{image}</div>
															</div>
															<span className="sr-only"></span>
														</td>
														<td className="whitespace-nowrap py-4 pl-4 pr-3 text-base text-gray-900 sm:pl-6">
															<button onClick={this.clickSelect.bind(this, _id)}>
																{name}<span className="sr-only"></span>
															</button>
														</td>
														<td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
															<button onClick={this.clickEdit.bind(this, _id)} className="text-indigo-600 hover:text-indigo-900">
																Modifier<span className="sr-only"></span>
															</button>
														</td>
														<td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
															<button onClick={this.clickDelete.bind(this, _id)} className="text-red-300 hover:text-red-600">
																<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 test-red-300">
																	<path fillRule="evenodd" d="M16.5 4.478v.227a48.816 48.816 0 013.878.512.75.75 0 11-.256 1.478l-.209-.035-1.005 13.07a3 3 0 01-2.991 2.77H8.084a3 3 0 01-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 01-.256-1.478A48.567 48.567 0 017.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 013.369 0c1.603.051 2.815 1.387 2.815 2.951zm-6.136-1.452a51.196 51.196 0 013.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 00-6 0v-.113c0-.794.609-1.428 1.364-1.452zm-.355 5.945a.75.75 0 10-1.5.058l.347 9a.75.75 0 101.499-.058l-.346-9zm5.48.058a.75.75 0 10-1.498-.058l-.347 9a.75.75 0 001.5.058l.345-9z" clipRule="evenodd" />
																</svg>
																<span className="sr-only"></span>
															</button>
														</td>	
													</tr>
												)
											})}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				)
			}
			return (
				<div className="flex flex-col px-4 sm:px-6 lg:px-8">
					<Modal
						dismissible={false}
						content={modal_msg}
						open_modal={this.state.modal_delete}
						answerModal={this.answerModalDelete}
					/>
					<BannerInfo
						content={this.state.banner_content}
						show={this.state.show_banner}
						closeBanner={this.closeBanner}
						error={this.state.error.global}
						success={this.state.success}
					/>
					<nav className={navStyle} aria-label="Breadcrumb">
						<ol className="bg-white rounded-md shadow px-6 flex space-x-4">
							<li className="flex">
							<div className="flex items-center">
								<Link to="/" className="text-gray-400 hover:text-gray-500">
									<HomeIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
									<span className="sr-only">Home</span>
								</Link>
							</div>
							</li>
							{this.state.navigation.map((page) => (
							<li key={page.name} className="flex">
								<div className="flex items-center">
								<svg
									className="flex-shrink-0 w-6 h-full text-gray-200"
									viewBox="0 0 24 44"
									preserveAspectRatio="none"
									fill="currentColor"
									xmlns="http://www.w3.org/2000/svg"
									aria-hidden="true"
								>
									<path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
								</svg>
								<Link
									to={page.href}
									className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
									aria-current={page.current ? 'page' : undefined}
								>
									{page.name}
								</Link>
								</div>
							</li>
							))}
						</ol>
					</nav>
					<div className="sm:flex-auto mb-7">
						<h2 className="text-xl leading-6 font-medium text-gray-900 mt-0 mb-0">Mes rubriques</h2>
						<p className="mt-1 text-base text-gray-500">Retrouvez ici les grandes thématiques de votre livret d'accueil.</p>
						<p className="text-base text-gray-500">Ex: "Bienvenue", "Nos équipements, "Nos activités préférées"...</p>
					</div>
					<div className="grid-cols-1">
						<button onClick={this.clickCreate} type="button" className="mb-10 inline-flex items-center px-6 py-3 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
							Créer une rubrique
							<svg xmlns="http://www.w3.org/2000/svg" className="ml-3 -mr-1 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
								<path strokeLinecap="round" strokeLinejoin="round" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
							</svg>
						</button>
					</div>
					<LangSelector lang={this.state.current_lang} updateLang={this.updateLang} />
					{content}
				</div>
			)
		}
	}
}

export default Rubrics

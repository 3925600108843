import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'
import Menu from '../Home/Menu'
import Modules from '../Home/Modules'
import Icons from '../Home/Icons'
import Rubrics from '../Rubrics/Rubrics'
import AnimationDates from '../Animations/Dates'
import AnimationCategories from '../Animations/Categories'
import Animations from '../Animations/Animations'
import Bookings from '../Services/Bookings'
import BackUsers from '../Users/BackUsers'
import Chat from '../Messages/Chat'
import Clients from '../Users/Clients'
import CronMail from '../Emails/CronMail'
import DepositParam from '../Inventory/DepositParam'
import UsersDeposit from '../Inventory/UsersDeposit'
import Email from '../Emails/Email'
import NewClients from '../Users/NewClients'
import Home from '../Home/Home'
import Inventory from '../Inventory/Inventory'
import Inventories from '../Inventory/Inventories'
import InventoryEdit from '../Inventory/InventoryEdit'
import Pdf from '../Home/Pdf'
import ReviewsAnswers from '../Reviews/Answers'
import ReviewsTemplate from '../Reviews/Template'
import Services from '../Services/Services'
import SendEmail from '../Emails/SendEmail'
import SettingsUser from '../Settings/User'
import SettingsConfigAdmin from '../Settings/ConfigAdmin'
import SettingsConfigWebapp from '../Settings/ConfigWebapp'
import Stats from '../Home/Stats'
import Stays from '../Users/Stays'
import Users from '../Users/Users'
import TemplateNotification from '../Emails/TemplateNotification'
import Theme from '../Home/Theme'
import WebUsers from '../Users/WebUsers'
import './Main.css'

class Main extends Component {
	render() {
		return (
			<div className="wrapper">
				<Switch>
					<Route
						exact
						path="/icons"
						render={(props) =>
							<Icons
								{...props}
								updateNavbarTitle={this.props.updateNavbarTitle}
							/>}
					/>
					<Route
						exact
						path="/parametres"
						render={(props) =>
							<SettingsUser
								{...props}
								user={this.props.user}
								updateUser={this.props.updateUser}
								updateNavbarTitle={this.props.updateNavbarTitle}
							/>}
					/>
					<Route
						exact
						path="/modules"
						render={(props) =>
							<Modules
								{...props}
								user={this.props.user}
								langs={this.props.langs}
								lang_default={this.props.lang_default}
								modules={this.props.modules}
								insertModule={this.props.insertModule}
								updateModule={this.props.updateModule}
								updateModulesOrder={this.props.updateModulesOrder}
								deleteModule={this.props.deleteModule}
								fetchModules={this.props.fetchModules}
								fetchPages={this.props.fetchPages}
								fetchLists={this.props.fetchLists}
								fetchGroups={this.props.fetchGroups}
								fetchCategories={this.props.fetchCategories}
								updateNavbarTitle={this.props.updateNavbarTitle}
							/>}
					/>
					<Route
						exact
						path="/rubriques"
						render={(props) =>
							<Rubrics
								// properties
								{...props}
								user={this.props.user}
								features={this.props.features}
								categories={this.props.categories}
								groups={this.props.groups}
								langs={this.props.langs}
								lang_default={this.props.lang_default}
								pages={this.props.pages}
								lists={this.props.lists}
								services={this.props.services}

								// insert methods
								insertCategory={this.props.insertCategory}
								insertGroup={this.props.insertGroup}
								insertList={this.props.insertList}
								insertListElement={this.props.insertListElement}
								insertPage={this.props.insertPage}
								insertPageSection={this.props.insertPageSection}

								// update methods
								updateCategoriesOrder={this.props.updateCategoriesOrder}
								updateCategory={this.props.updateCategory}
								updateCategoryDuplicate={this.props.updateCategoryDuplicate}
								updateGroup={this.props.updateGroup}
								updateListCover={this.props.updateListCover}
								updateListElement={this.props.updateListElement}
								updateListOrderElements={this.props.updateListOrderElements}
								updateListTransformSubcategory={this.props.updateListTransformSubcategory}
								updatePageCover={this.props.updatePageCover}
								updatePageDuplicateSection={this.props.updatePageDuplicateSection}
								updatePageName={this.props.updatePageName}
								updatePageOrderSections={this.props.updatePageOrderSections}
								updatePageSection={this.props.updatePageSection}
								updatePageService={this.props.updatePageService}

								// delete methods
								deleteCategory={this.props.deleteCategory}
								deleteGroup={this.props.deleteGroup}
								deleteListByCategory={this.props.deleteListByCategory}
								deleteListElement={this.props.deleteListElement}
								deletePage={this.props.deletePage}
								deletePageByCategory={this.props.deletePageByCategory}
								deletePageSection={this.props.deletePageSection}
								deletePageService={this.props.deletePageService}

								// fetch methods
								fetchCategoriesByGroup={this.props.fetchCategoriesByGroup}
								fetchGroups={this.props.fetchGroups}
								fetchIcons={this.props.fetchIcons}
								fetchListByCategory={this.props.fetchListByCategory}
								fetchPageByID={this.props.fetchPageByID}
								fetchPageByCategory={this.props.fetchPageByCategory}
								fetchServices={this.props.fetchServices}
								fetchTheme={this.props.fetchTheme}

								// others
								updateNavbarTitle={this.props.updateNavbarTitle}
							/>}
					/>
					<Route
						exact
						path="/animation-categories"
						render={(props) => 
							<AnimationCategories
								{...props}
								user={this.props.user}
								langs={this.props.langs}
								lang_default={this.props.lang_default}
								categories={this.props.animationCategories}
								deleteAnimationCategory={this.props.deleteAnimationCategory}
								insertAnimationCategory={this.props.insertAnimationCategory}
								updateAnimationCategory={this.props.updateAnimationCategory}
								fetchAnimationCategories={this.props.fetchAnimationCategories}
								updateNavbarTitle={this.props.updateNavbarTitle}
							/>}
					/>
					<Route
						exact
						path="/animations"
						render={(props) =>
							<Animations
								{...props}
								user={this.props.user}
								animations={this.props.animations}
								categories={this.props.animationCategories}
								features={this.props.features}
								langs={this.props.langs}
								lang_default={this.props.lang_default}
								deleteAnimation={this.props.deleteAnimation}
								insertAnimation={this.props.insertAnimation}
								updateAnimation={this.props.updateAnimation}
								updateAnimationCategories={this.props.updateAnimationCategories}
								fetchAnimationDates={this.props.fetchAnimationDates}
								fetchAnimations={this.props.fetchAnimations}
								fetchAnimationByID={this.props.fetchAnimationByID}
								fetchAnimationCategories={this.props.fetchAnimationCategories}
								fetchServiceBenefits={this.props.fetchServiceBenefits}
								fetchServices={this.props.fetchServices}
								updateNavbarTitle={this.props.updateNavbarTitle}
							/>}
					/>
					<Route
						exact
						path="/animation-dates"
						render={(props) =>
							<AnimationDates
								{...props}
								user={this.props.user}
								animations={this.props.animations}
								categories={this.props.animationCategories}
								dates={this.props.animationDates}
								lang_default={this.props.lang_default}
								deleteAnimationDate={this.props.deleteAnimationDate}
								insertAnimationDates={this.props.insertAnimationDates}
								fetchAnimations={this.props.fetchAnimations}
								fetchAnimationDates={this.props.fetchAnimationDates}
								fetchAnimationCategories={this.props.fetchAnimationCategories}
								updateNavbarTitle={this.props.updateNavbarTitle}
							/>}
					/>
					<Route
						exact
						path="/services"
						render={(props) =>
							<Services
								{...props}
								icons={this.props.icons}
								features={this.props.features}
								langs={this.props.langs}
								lang_default={this.props.lang_default}
								service_benefits={this.props.service_benefits}
								service_products={this.props.service_products}
								service_workers={this.props.service_workers}
								services={this.props.services}
								user={this.props.user}
								insertService={this.props.insertService}
								insertServiceBenefit={this.props.insertServiceBenefit}
								insertServiceProduct={this.props.insertServiceProduct}
								insertServiceWorker={this.props.insertServiceWorker}
								updateService={this.props.updateService}
								updateServiceBenefit={this.props.updateServiceBenefit}
								updateServiceDuration={this.props.updateServiceDuration}
								updateServiceProduct={this.props.updateServiceProduct}
								updateServiceWorker={this.props.updateServiceWorker}
								fetchIcons={this.props.fetchIcons}
								fetchTheme={this.props.fetchTheme}
								fetchServices={this.props.fetchServices}
								fetchServiceBenefitsByService={this.props.fetchServiceBenefitsByService}
								fetchServiceProductsByService={this.props.fetchServiceProductsByService}
								fetchServiceWorkersByService={this.props.fetchServiceWorkersByService}
								deleteService={this.props.deleteService}
								deleteServiceBenefit={this.props.deleteServiceBenefit}
								deleteServiceBenefitsByService={this.props.deleteServiceBenefitsByService}
								deleteServiceProduct={this.props.deleteServiceProduct}
								deleteServiceWorker={this.props.deleteServiceWorker}
								deleteServiceWorkersByService={this.props.deleteServiceWorkersByService}
								updateNavbarTitle={this.props.updateNavbarTitle}
							/>}
					/>
					<Route
						exact
						path="/services/booking"
						render={(props) =>
							<Bookings
								{...props}
								clients={this.props.clients}
								icons={this.props.icons}
								lang_default={this.props.lang_default}
								features={this.props.features}
								service_benefits={this.props.service_benefits}
								service_reservations={this.props.service_reservations}
								service_workers={this.props.service_workers}
								user={this.props.user}
								webapp_users={this.props.webapp_users}
								insertServiceReservation={this.props.insertServiceReservation}
								deleteServiceReservation={this.props.deleteServiceReservation}
								fetchClients={this.props.fetchClients}
								fetchReservationServices={this.props.fetchReservationServices}
								fetchServices={this.props.fetchServices}
								fetchServiceBenefits={this.props.fetchServiceBenefits}
								fetchServiceReservationsByService={this.props.fetchServiceReservationsByService}
								fetchServiceWorkers={this.props.fetchServiceWorkers}
								fetchWebappUsers={this.props.fetchWebappUsers}
								updateNavbarTitle={this.props.updateNavbarTitle}
							/>}
					/>
					<Route
						exact
						path="/menu"
						render={(props) =>
							<Menu
								{...props}
								menu={this.props.menu}
								groups={this.props.groups}
								updateMenu={this.props.updateMenu}
								fetchGroups={this.props.fetchGroups}
								// fetchMenu={this.props.fetchMenu}
								fetchTheme={this.props.fetchTheme}
								fetchIcons={this.props.fetchIcons}
								updateNavbarTitle={this.props.updateNavbarTitle}
							/>}
					/>
					<Route
						exact
						path="/users/dashboard"
						render={(props) =>
							<BackUsers
								{...props}
								updateNavbarTitle={this.props.updateNavbarTitle}
							/>}
					/>
					<Route
						exact
						path="/users/webapp/:id"
						render={(props) =>
							<WebUsers
								{...props}
								updateNavbarTitle={this.props.updateNavbarTitle}
							/>}
					/>
					<Route
						exact
						path="/users/webapp"
						render={(props) =>
							<WebUsers
								{...props}
								updateNavbarTitle={this.props.updateNavbarTitle}
							/>}
					/>
					<Route
						exact
						path="/users/clients/:id"
						render={(props) =>
							<Clients
								{...props}
								updateNavbarTitle={this.props.updateNavbarTitle}
							/>}
					/>
					<Route
						exact
						path="/users/clients"
						render={(props) =>
							<Clients
								{...props}
								updateNavbarTitle={this.props.updateNavbarTitle}
							/>}
					/>
					<Route
						exact
						path="/users/sejours/:id"
						render={(props) =>
							<Stays
								{...props}
								updateNavbarTitle={this.props.updateNavbarTitle}
							/>}
					/>
					<Route
						exact
						path="/users/sejours"
						render={(props) =>
							<Stays
								{...props}
								updateNavbarTitle={this.props.updateNavbarTitle}
							/>}
					/>
					<Route
						exact
						path="/users"
						render={(props) =>
							<Users
								{...props}
								updateNavbarTitle={this.props.updateNavbarTitle}
							/>}
					/>
					<Route
						exact
						path="/utilisateurs"
						render={(props) =>
							<NewClients
								{...props}
								user={this.props.user}
								config_pms={this.props.config_pms}
								subscriptions={this.props.subscriptions}
								updateNavbarTitle={this.props.updateNavbarTitle}
								insertRoomlist={this.props.insertRoomlist}
								fetchConfigPms={this.props.fetchConfigPms}
								fetchNotificationSubscriptions={this.props.fetchNotificationSubscriptions}
							/>}
					/>
					<Route
						exact
						path="/administration/parametres"
						render={(props) =>
							<SettingsConfigWebapp
								{...props}
								user={this.props.user}
								background_img={this.props.background_img}
								establishment={this.props.establishment}
								emails_activate={this.props.emails_activate}
								emails_nbdays={this.props.emails_nbdays}
								langs={this.props.langs}
								lang_default={this.props.lang_default}
								webapp_connexion={this.props.webapp_connexion}
								welcome_txt={this.props.welcome_txt}
								updateConfig={this.props.updateConfig}
								updateNavbarTitle={this.props.updateNavbarTitle}
							/>}
					/>
					<Route
						exact
						path="/administration/features"
						render={(props) =>
							<SettingsConfigAdmin
								{...props}
								user={this.props.user}
								features={this.props.features}
								config_pms={this.props.config_pms}
								fetchDashboardConfig={this.props.fetchDashboardConfig}
								fetchConfigPms={this.props.fetchConfigPms}
								updateConfigDashboard={this.props.updateConfigDashboard}
								updateNavbarTitle={this.props.updateNavbarTitle}
							/>}
					/>
					<Route
						exact
						path="/administration/emails"
						render={(props) =>
							<Email
								{...props}
								emails={this.props.emails}
								langs={this.props.langs}
								lang_default={this.props.lang_default}
								user={this.props.user}
								insertEmail={this.props.insertEmail}
								updateEmail={this.props.updateEmail}
								deleteEmail={this.props.deleteEmail}
								fetchEmails={this.props.fetchEmails}
								sendEmail={this.props.sendEmail}
								updateNavbarTitle={this.props.updateNavbarTitle}
							/>}
					/>
					<Route
						exact
						path="/administration/auto-email"
						render={(props) =>
							<CronMail
								{...props}
								lang_default={this.props.lang_default}
								user={this.props.user}
								updateNavbarTitle={this.props.updateNavbarTitle}
							/>}
					/>
					<Route
						exact
						path="/administration/send-emails"
						render={(props) =>
							<SendEmail
								{...props}
								lang_default={this.props.lang_default}
								user={this.props.user}
								fetchEmails={this.props.fetchEmails}
								updateNavbarTitle={this.props.updateNavbarTitle}
							/>}
					/>
					<Route
						exact
						path="/administration/notifications"
						render={(props) =>
							<TemplateNotification
								{...props}
								categories={this.props.categories}
								groups={this.props.groups}
								langs={this.props.langs}
								lang_default={this.props.lang_default}
								notifications={this.props.notifications}
								pages={this.props.pages}
								user={this.props.user}
								insertCronNotification={this.props.insertCronNotification}
								insertNotification={this.props.insertNotification}
								updateNotification={this.props.updateNotification}
								deleteNotification={this.props.deleteNotification}
								fetchCategories={this.props.fetchCategories}
								fetchGroups={this.props.fetchGroups}
								fetchNotifications={this.props.fetchNotifications}
								fetchPages={this.props.fetchPages}
								fetchWebappUsers={this.props.fetchWebappUsers}
								updateNavbarTitle={this.props.updateNavbarTitle}
							/>}
					/>
					<Route
						exact
						path="/avis/formulaires"
						render={(props) =>
							<ReviewsTemplate
								{...props}
								user={this.props.user}
								langs={this.props.langs}
								lang_default={this.props.lang_default}
								review_models={this.props.review_models}
								insertReviewModel={this.props.insertReviewModel}
								updateReviewModel={this.props.updateReviewModel}
								deleteReviewModel={this.props.deleteReviewModel}
								fetchReviewModels={this.props.fetchReviewModels}
								updateNavbarTitle={this.props.updateNavbarTitle}
							/>}
					/>
					<Route
						exact
						path="/avis/reponses"
						render={(props) =>
							<ReviewsAnswers
								{...props}
								clients={this.props.clients}
								user={this.props.user}
								reviews={this.props.reviews}
								lang_default={this.props.lang_default}
								fetchClients={this.props.fetchClients}
								postExtractReviews={this.props.postExtractReviews}
								fetchReviewsByType={this.props.fetchReviewsByType}
								fetchWebappUsers={this.props.fetchWebappUsers}
								updateNavbarTitle={this.props.updateNavbarTitle}
							/>}
					/>
					<Route
						exact
						path="/themes"
						render={(props) =>
							<Theme
								{...props}
								updateNavbarTitle={this.props.updateNavbarTitle}
							/>}
					/>
					<Route
						exact
						path="/stats"
						render={(props) =>
							<Stats
								{...props}
								user={this.props.user}
								updateNavbarTitle={this.props.updateNavbarTitle}
							/>}
					/>
					<Route
						exact
						path="/inventaires"
						render={(props) =>
							<Inventory
								{...props}
								user={this.props.user}
								accommodations={this.props.accommodations}
								features={this.props.features}
								langs={this.props.langs}
								lang_default={this.props.lang_default}
								fetchAccommodations={this.props.fetchAccommodations}
								fetchConfigInventory={this.props.fetchConfigInventory}
								insertAccommodation={this.props.insertAccommodation}
								insertAccommodationDuplicate={this.props.insertAccommodationDuplicate}
								updateAccommodation={this.props.updateAccommodation}
								updateConfigInventory={this.props.updateConfigInventory}
								deleteAccommodation={this.props.deleteAccommodation}
								updateNavbarTitle={this.props.updateNavbarTitle}
							/>}
					/>
					<Route
						exact
						path="/inventaires/checkin"
						render={(props) =>
							<Inventories
								{...props}
								user={this.props.user}
								features={this.props.features}
								lang_default={this.props.lang_default}
								inventories={this.props.inventories}
								insertInventory={this.props.insertInventory}
								updateInventoryComment={this.props.updateInventoryComment}
								updateInventoryPictures={this.props.updateInventoryPictures}
								deleteInventoryPicture={this.props.deleteInventoryPicture}
								fetchConfigInventory={this.props.fetchConfigInventory}
								fetchInventories={this.props.fetchInventories}
								fetchInventoryBySearch={this.props.fetchInventoryBySearch}
								fetchInventoryByStayNb={this.props.fetchInventoryByStayNb}
								updateNavbarTitle={this.props.updateNavbarTitle}
							/>}
					/>
					<Route
						exact
						path="/deposit/swikly"
						render={(props) =>
							<DepositParam
								{...props}
								updateNavbarTitle={this.props.updateNavbarTitle}
								fetchAccommodations={this.props.fetchAccommodations}
								fetchConfigDeposit={this.props.fetchConfigDeposit}
								updateAccommodationDeposit={this.props.updateAccommodationDeposit}
								updateConfigDeposit={this.props.updateConfigDeposit}
							/>}
					/>
					<Route
						exact
						path="/deposit/users-deposit"
						render={(props) =>
							<UsersDeposit
								{...props}
								user={this.props.user}
								features={this.props.features}
								deposits={this.props.deposits}
								fetchDeposits={this.props.fetchDeposits}
								updateNavbarTitle={this.props.updateNavbarTitle}
							/>}
					/>
					<Route
						exact
						path="/inventaires/edit/:id"
						render={(props) =>
							<InventoryEdit
								{...props}
								langs={this.props.langs}
								lang_default={this.props.lang_default}
								accommodations={this.props.accommodations}
								fetchAccommodationByID={this.props.fetchAccommodationByID}
								insertInventoryCategory={this.props.insertInventoryCategory}
								insertInventoryObjects={this.props.insertInventoryObjects}
								updateInventoryCategory={this.props.updateInventoryCategory}
								deleteInventoryCategory={this.props.deleteInventoryCategory}
								updateNavbarTitle={this.props.updateNavbarTitle}
							/>}
					/>
					<Route
						exact
						path="/messages/chat"
						render={(props) =>
							<Chat
								{...props}
								conversations={this.props.conversations}
								messages={this.props.messages}
								fetchConversations={this.props.fetchConversations}
								fetchMessagesByConversation={this.props.fetchMessagesByConversation}
								updateNavbarTitle={this.props.updateNavbarTitle}
							/>}
					/>
					<Route
						exact
						path="/pdf"
						render={(props) =>
							<Pdf
								{...props}
								updateNavbarTitle={this.props.updateNavbarTitle}
								files={this.props.files}
								insertPdf={this.props.insertPdf}
								insertPicture={this.props.insertPicture}
								deletePdf={this.props.deletePdf}
								deletePicture={this.props.deletePicture}
								fetchFiles={this.props.fetchFiles}
							/>}
					/>
					<Route
						path="/"
						render={(props) =>
							<Home
								{...props}
								user={this.props.user}
								features={this.props.features}
								langs={this.props.langs}
								lang_default={this.props.lang_default}
								pages={this.props.pages}
								groups={this.props.groups}
								categories={this.props.categories}
								fetchCategories={this.props.fetchCategories}
								fetchGroups={this.props.fetchGroups}
								fetchPages={this.props.fetchPages}
								fetchWebappConfig={this.props.fetchWebappConfig}
								updateNavbarTitle={this.props.updateNavbarTitle}
								sendNotifications={this.props.sendNotifications}
							/>}
					/>
				</Switch>
			</div>
		)
	}
}

export default Main

import React, { Component } from 'react'
import { InformationCircleIcon, LightningBoltIcon
} from '@heroicons/react/solid'
import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'
// import { ExclamationCircleIcon, XCircleIcon, XMarkIcon, TrophyIcon } from '@heroicons/react/outline'
import BannerInfo from '../UI/BannerInfo'

class PremiumAlert extends Component {
	constructor(props) {
		super(props)
		this.state = {
			is_modal_open: false,
			showBanner: false,
			bannerContent: null,
		}
	}

	closeModal = () => {
		this.setState({ is_modal_open : false })
	}

	openModal = () => {
		this.setState({ is_modal_open : true })
	}

	openMoreInfo = (event) => {
		event.preventDefault()
		this.openModal()
	}

	sendNotification = (event) => {
		event.preventDefault()
		this.setState({ showBanner: true, bannerContent: "Un message nous a été envoyé !", is_modal_open: false })
	}

	closeBanner = () => {
		this.setState({ showBanner: false })
	}

	render () {
		return (
			<>
				<BannerInfo
					content={this.state.bannerContent}
					show={this.state.showBanner}
					closeBanner={this.closeBanner}
					// error={this.state.error.global}
					// success={this.state.success}
				/>
				<Transition appear show={this.state.is_modal_open} as={Fragment}>
					<Dialog as="div" className="fixed inset-0 z-10" onClose={this.closeModal}>
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
						</Transition.Child>
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<div className="h-full z-10 overflow-y-auto">
         						<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
             						<div className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
										<div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
											<button
												type="button"
												className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
												onClick={this.closeModal}
											>
												<span className="sr-only">Close</span>
												<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
													<path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
												</svg>
											</button>
										</div>
                						<div className="sm:flex sm:items-start">
											<div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-yellow-100 sm:mx-0 sm:h-10 sm:w-10">
											<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-yellow-600">
												<path strokeLinecap="round" strokeLinejoin="round" d="M16.5 18.75h-9m9 0a3 3 0 013 3h-15a3 3 0 013-3m9 0v-3.375c0-.621-.503-1.125-1.125-1.125h-.871M7.5 18.75v-3.375c0-.621.504-1.125 1.125-1.125h.872m5.007 0H9.497m5.007 0a7.454 7.454 0 01-.982-3.172M9.497 14.25a7.454 7.454 0 00.981-3.172M5.25 4.236c-.982.143-1.954.317-2.916.52A6.003 6.003 0 007.73 9.728M5.25 4.236V4.5c0 2.108.966 3.99 2.48 5.228M5.25 4.236V2.721C7.456 2.41 9.71 2.25 12 2.25c2.291 0 4.545.16 6.75.47v1.516M7.73 9.728a6.726 6.726 0 002.748 1.35m8.272-6.842V4.5c0 2.108-.966 3.99-2.48 5.228m2.48-5.492a46.32 46.32 0 012.916.52 6.003 6.003 0 01-5.395 4.972m0 0a6.726 6.726 0 01-2.749 1.35m0 0a6.772 6.772 0 01-3.044 0" />
											</svg>

											</div>
											<div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
												<Dialog.Title as="h3" style={{ margin:"0" }} className="text-lg font-medium leading-6 text-gray-900">
													Formule Premium
												</Dialog.Title>
												<div className="mt-2">
													<p className="text-sm text-gray-500">
														Bénéficiez de tous les avantages offerts par Pocketcamp en basculant sur notre offre Premium ! Si vous souhaitez en savoir plus, cliquez sur "Oui, ça m'intéresse !" pour être recontacté par notre équipe commerciale <span role="img" aria-label="sunny-glasses">😎</span>
													</p>
												</div>
											</div>
                						</div>
										<div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
											<button
												type="button"
												className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-bold text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
												onClick={this.sendNotification}
											>
												Oui, ça m'intéresse !
											</button>
											<button
												type="button"
												className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
												onClick={this.closeModal}
											>
												Plus tard
											</button>
										</div>
             				 		</div>
								</div>
							</div>
						</Transition.Child>
					</Dialog>
				</Transition>
				<div className="flex justify-center">
					<div className="rounded-md border-2 border-indigo-600 bg-indigo-50 w-2/4 p-4">
						<div className="flex">
							<div className="flex-shrink-0">
								<InformationCircleIcon className="h-5 w-5 text-indigo-600" aria-hidden="true" />
							</div>
							<div className="ml-3 flex-1 md:flex md:justify-between">
								<p className="text-base text-blue-700">{this.props.content} <span role="img" aria-label="smiley">🤩</span></p>
							</div>
						</div>
						<div className="mt-6 mb-3">
							<div className="-mx-2 -my-1.5 flex justify-center">
								<button
									type="button"
									onClick={this.openMoreInfo}
									className="inline-flex items-center bg-indigo-600 px-4 py-2 rounded-md text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-50 focus:ring-indigo-600"
								>
									<LightningBoltIcon className="-ml-0.5 mr-2 h-5 w-5" aria-hidden="true" />
									{this.props.button_text}
								</button>
							</div>
						</div>
					</div>
				</div>
			</>
		)
	}
}

export default PremiumAlert
import React, { Component } from 'react'
import M from 'materialize-css'
import connexion from '../../connexion'
import Loader from '../UI/Loader'
import '../Core/Main.css'
import './Theme.css'

class Theme extends Component {
	constructor(props) {
		super(props)
		this.state = {
			themes: null,
			loaded: false,
			theme: null,
			form1: {
				theme: ''
			},
			form2: {
				name: '',
				color1: '',
				color2: '',
				color3: '',
				color4: ''
			},
			displayUpdate: 'none',
			selected: null,
			error: null,
			success: null
		}
		this.props.updateNavbarTitle('Thèmes')
	}

	componentDidMount() {
		this.loadThemes()
	}

	componentDidUpdate () {
		const elems = document.querySelectorAll('select')
		M.FormSelect.init(elems)
	}

	loadThemes = () => {
		fetch(connexion.connect.url + 'dashboard/themes/list')
		.then(res => res.json())
		.then(themes => {
			this.setState({ themes: themes, loaded: true })
		})
		.catch(err => console.log(err))
	}

	setErrorMsg = (msg) => {
		this.setState({ error: msg, success: null })
	}

	setSuccessMsg = (msg) => {
		this.setState({ error: null, success: msg })
	}

	clearFlash = () => {
		this.setState({ error: null, success: null })
	}

	handleTheme = (event) => {
		const form1 = {
			theme: event.target.value
		}
		this.setState({ form1: form1 })
	}

	submitSelect = (event) => {
		event.preventDefault()
		let theme = null
		let form2 = {
			name: '',
			color1: '',
			color2: '',
			color3: '',
			color4: ''
		}
		const themes = this.state.themes
		for (let i = 0; i < themes.length; i++)
		{
			if (themes[i]._id === this.state.form1.theme)
			{
				theme = themes[i]
				form2 = {
					name: theme.name,
					color1: theme.color1,
					color2: theme.color2,
					color3: theme.color3,
					color4: theme.color4
				}
				break
			}
		}
		this.clearFlash()
		this.setState({ theme: theme, form2: form2 })
	}

	handleName = (event) => {
		const form2 = {
			...this.state.form2,
			name: event.target.value
		}
		this.setState({ form2: form2 })
	}

	handleColor = (event) => {
		let update = {}
		const prop = event.target.name
		const nb = event.target.name.substr(5)
		const propDisplay = "display" + nb
		let display = 'block'
		if (event.target.value.length === 0)
			display = 'none'
		let form2 = {
			...this.state.form2
		}
		form2[prop] = event.target.value
		update[propDisplay] = display
		update['form2'] = form2
		this.setState(update)
	}

	submitTheme = (event) => {
		event.preventDefault()
		console.log("submitTheme")
		const name = this.state.form2.name
		const color1 = this.state.form2.color1
		const color2 = this.state.form2.color2
		const color3 = this.state.form2.color3
		const color4 = this.state.form2.color4
		if (name.length === 0)
			this.setErrorMsg("Vous devez indiquer le nom")
		else if (color1.length < 6 || color2.length === 0 || color3.length === 0 || color4.length === 0)
			this.setErrorMsg("Vous devez indiquer toutes les couleurs")
		else
		{
			if (this.state.theme === null)
			{
				fetch(connexion.connect.url + 'dashboard/themes/insert', {
					method: 'POST',
					body: JSON.stringify({
						name: name,
						color1: color1,
						color2: color2,
						color3: color3,
						color4: color4
					})
				})
				.then(res => res.json())
				.then(ret => {
					if (ret !== false)
					{
						const form2 = {
							name: '',
							color1: '',
							color2: '',
							color3: '',
							color4: ''
						}
						this.loadThemes()
						this.setState({ form2: form2 })
						this.setSuccessMsg("Le nouveau thème à bien été ajouté")
					}
					else
						this.setErrorMsg("Une erreur s'est produite")
				})
				.catch(err => {
					this.setErrorMsg("Une erreur s'est produite")
					console.log(err)
				})
			}
			else
			{
				fetch(connexion.connect.url + 'dashboard/themes/update', {
					method: 'POST',
					body: JSON.stringify({
						id: this.state.theme._id,
						name: name,
						color1: color1,
						color2: color2,
						color3: color3,
						color4: color4
					})
				})
				.then(res => res.json())
				.then(ret => {
					if (ret !== false)
					{
						this.loadThemes()
						this.setSuccessMsg("Le nouveau thème à bien été modifié")
					}
					else
						this.setErrorMsg("Une erreur s'est produite")
				})
				.catch(err => {
					this.setErrorMsg("Une erreur s'est produite")
					console.log(err)
				})
			}
		}
	}

	deleteTheme = () => {
		if (this.state.theme !== null)
		{
			fetch(connexion.connect.url + 'dashboard/themes/delete', {
				method: 'POST',
				body: JSON.stringify({
					id: this.state.theme._id
				})
			})
			.then(res => res.json())
			.then(ret => {
				if (ret === true)
				{
					const form2 = {
						name: '',
						color1: '',
						color2: '',
						color3: '',
						color4: ''
					}
					this.setState({ form2: form2, theme: null })
					this.setSuccessMsg("Le thème à bien été supprimé")
					this.loadThemes()
				}
				else
					this.setErrorMsg("Une erreur s'est produite")
			})
			.catch(err => {
				this.setErrorMsg("Une erreur s'est produite")
				console.log(err)
			})
		}
	}

	selectTheme = (event) => {
		event.preventDefault()
		fetch(connexion.connect.url + 'webapp/config/update-theme', {
			method: 'POST',
			body: JSON.stringify({
				id: this.state.theme._id
			})
		})
		.then(res => res.json())
		.then(update => {
			if (update === true)
				this.setSuccessMsg("Le thème à été sélectionné sur l'application")
			else
				this.setErrorMsg("Une erreur s'est produite")
		})
		.catch(err => {
			this.setErrorMsg("Une erreur s'est produite")
			console.log(err)
		})
	}

	render() {
		if (this.state.loaded === false)
		{
			return (
				<div className="loader">
					<Loader />
				</div>
			)
		}
		else
		{
			const themes = this.state.themes
			const display1 = (this.state.form2.color1.length === 6) ? 'block' : 'none'
			const display2 = (this.state.form2.color2.length === 6) ? 'block' : 'none'
			const display3 = (this.state.form2.color3.length === 6) ? 'block' : 'none'
			const display4 = (this.state.form2.color4.length === 6) ? 'block' : 'none'
			let title = 'Créer un thème'
			let btn = 'Créer un nouveau thème'
			let deletebtn = null
			let select = null
			if (this.state.theme !== null)
			{
				title = 'Paramétrer un thème'
				btn = 'Mettre à jour'
				select = (<button type="button" className="waves-effect waves-light btn-small blue" onClick={this.selectTheme}>Sélectionner</button>)
				deletebtn = (<button type="button" className="waves-effect waves-light btn-small red" onClick={this.deleteTheme}>Supprimer</button>)
			}
			return (
				<div className="wrapper-content">
					<p className="error center">{this.state.error}</p>
					<p className="success center">{this.state.success}</p>
					<div className="row">
						<div className="module col s6">
							<h2>Sélectionner un thème</h2>
							<form className="module-form" onSubmit={this.submitSelect}>
								<label>
									<p className="column-20">Thème :</p>
									<select value={this.state.form1.theme} onChange={this.handleTheme}>
										<option value="">Sélectionner un thème</option>
										{themes && themes.map(({ _id, name, color1, color2, color3, color4 }) => {
											return (
												<option value={_id} key={_id}>{name}</option>
											)
										})}
									</select>
									<button type="submit" className="waves-effect waves-light btn-small">Sélectionner</button>
								</label>
							</form>
						</div>
						<div className="module col s6">
							<h2>{title}</h2>
							<form className="module-form" onSubmit={this.submitTheme}>
								<label>
									<p className="column-20">Nom :</p>
									<input type="text" value={this.state.form2.name} onChange={this.handleName} />
								</label>
								<label>
									<p className="column-20">Couleur 1 :</p>
									<input type="text" name="color1" value={this.state.form2.color1} onChange={this.handleColor} />
									<div className="color-preview" style={{ backgroundColor: '#' + this.state.form2.color1, display: display1 }}></div>
								</label>
								<label>
									<p className="column-20">Couleur 2 :</p>
									<input type="text" name="color2" value={this.state.form2.color2} onChange={this.handleColor} />
									<div className="color-preview" style={{ backgroundColor: '#' + this.state.form2.color2, display: display2 }}></div>
								</label>
								<label>
									<p className="column-20">Couleur 3 :</p>
									<input type="text" name="color3" value={this.state.form2.color3} onChange={this.handleColor} />
									<div className="color-preview" style={{ backgroundColor: '#' + this.state.form2.color3, display: display3 }}></div>
								</label>
								<label>
									<p className="column-20">Couleur 4 :</p>
									<input type="text" name="color4" value={this.state.form2.color4} onChange={this.handleColor} />
									<div className="color-preview" style={{ backgroundColor: '#' + this.state.form2.color4, display: display4 }}></div>
								</label>
								<label>
									{select}
									<button type="submit" className="waves-effect waves-light btn-small">{btn}</button>
									{deletebtn}
								</label>
							</form>
						</div>
					</div>
				</div>
			)
		}
	}
}

export default Theme

import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Fragment } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import {
	CalendarIcon,
	ChartBarIcon,
	HomeIcon,
	MenuAlt2Icon,
	UsersIcon,
	XIcon,
	CollectionIcon,
	TicketIcon, 
	StarIcon, 
	ClipboardListIcon,
	QuestionMarkCircleIcon,
	MailIcon,
	SpeakerphoneIcon,
	CogIcon,
	LibraryIcon,
	LockClosedIcon
	} from '@heroicons/react/outline'
import logo from '../../images/logo-pc-white.png'
import tools from '../../tools'

class SidebarTailwind extends Component {
	constructor(props) {
		super(props)
		this.state = {
			sidebarOpen : false,
			navigation: [],
			url: null
		}
		const permissions = this.props.user.permissions
		if (permissions && permissions.homepage && permissions.homepage === 1)
			this.state.navigation.push({ name: 'Dashboard', path: '/', icon: HomeIcon, current: true, display: false, collapse: null })
		if (permissions && permissions.rubrics && permissions.rubrics === 1)
		{
			this.state.navigation.push({
				name: 'Livret d\'accueil',
				path: '/rubriques',
				icon: CollectionIcon,
				display: false,
				collapse: [
					{ collapseName: 'Structure de l\'app', path: '/modules', current: false },
					{ collapseName: 'Mes rubriques', path: '/rubriques', current: false },
					{ collapseName: 'Fichiers', path: '/pdf', current: false }
				]
			})
		}
		if (permissions && permissions.activities && permissions.activities === 1)
		{
			this.state.navigation.push({
				name: 'Activités',
				path: '/animations',
				icon: CalendarIcon,
				display: false,
				collapse: [
					{ collapseName: 'Mes activités', path: '/animations', current: false },
					{ collapseName: 'Planning', path: '/animation-dates', current: false },
					{ collapseName: 'Catégories d\'activité', path: '/animation-categories', current: false }
				]
			})
		}
		if (permissions && permissions.services && permissions.services === 1)
		{
			this.state.navigation.push({
				name: 'Services',
				path: '/services',
				icon: TicketIcon,
				display: false,
				collapse: [
					{ collapseName: 'Mes services',  path: '/services', current: false },
					{ collapseName: 'Planning',  path: '/services/booking', current: false }
				] 
			})
		}
		if (permissions && permissions.users && permissions.users === 1)
			this.state.navigation.push({ name: 'Utilisateurs', path: '/utilisateurs', icon: UsersIcon, current: false, display: false, collapse: null })
		if (permissions && permissions.reviews && permissions.reviews === 1)
		{
			this.state.navigation.push({
				name: 'Avis clients',
				path: '/avis/formulaires',
				icon: StarIcon,
				display: false,
				collapse: [
					{ collapseName: 'Mes questionnaires',  path: '/avis/formulaires', current: false },
					{ collapseName: 'Avis récoltés',  path: '/avis/reponses', current: false }
				]
			})
		}
		if (permissions && permissions.stats && permissions.stats === 1)
			this.state.navigation.push({ name: 'Statistiques', path: '/stats', icon: ChartBarIcon, current: false, display: false, collapse: null })
		if (permissions && permissions.emails && permissions.emails === 1)
		{
			this.state.navigation.push({
				name: 'Email',
				path: '/administration/emails',
				icon: MailIcon,
				current: false,
				display: false,
				collapse: [
					{ collapseName: 'Messagerie',  path: '/messages/chat', current: false },
					{ collapseName: 'Templates d\'Email',  path: '/administration/emails', current: false },
					{ collapseName: 'Campagnes envoyées',  path: '/administration/auto-email', current: false }
				]
			})
		}
		if (permissions && permissions.inventories && permissions.inventories === 1)
		{
			this.state.navigation.push({
				name: 'Inventaires',
				path: '/inventaires',
				icon: ClipboardListIcon,
				current: false,
				display: false,
				collapse: [
					{ collapseName: 'Mes hébergements',  path: '/inventaires', current: false },
					{ collapseName: 'Inventaires',  path: '/inventaires/checkin', current: false }
				]
			})
		}
		if (permissions && permissions.deposits && permissions.deposits === 1)
			this.state.navigation.push({ name: 'Cautions SWIKLY', path: '/deposit/users-deposit', icon: LockClosedIcon, current: false, display: false, collapse: null })
		if (permissions && permissions.notifications && permissions.notifications === 1)
			this.state.navigation.push({ name: 'Notifications', path: '/administration/notifications', icon: SpeakerphoneIcon, current: false, display: false, collapse: null })
		if (permissions && permissions.params && permissions.params === 1)
			this.state.navigation.push({ name: 'Paramètres', path: '/administration/parametres', icon: CogIcon, current: false, display: false, collapse: null })
		// if (this.props.user.limited === true)
		// {
		// 	this.state.navigation = [
		// 		{ name: 'Dashboard', path: '/', icon: HomeIcon, current: true, display: false, collapse: null },
		// 		{ name: 'Inventaires', path: '/inventaires', icon: ClipboardListIcon, current: false, display: false, collapse: 
		// 			[
		// 				{ collapseName: 'Inventaires',  path: '/inventaires/checkin', current: false },
		// 			]	 
		// 		}
		// 	]
		// }
		for (let i = 0; i < this.state.navigation.length; i++)
		{
			this.state.navigation[i].current = false
			this.state.navigation[i].current = null
		}
		this.userNavigation = [
			// { name: 'Votre profil', path: '#' },
  			{ name: 'Mon compte', path: '/parametres', onclick: null },
  			{ name: 'Se déconnecter', path: '#', onclick: this.props.updateUser.bind(this, null) },
		]
	}

	componentDidUpdate()
	{
		if (this.state.url !== window.location.href)
		{
			const navigation = tools.clone(this.state.navigation)
			for (let i = 0; i < this.state.navigation.length; i++)
			{
				switch (navigation[i].name)
				{
					case 'Dashboard':
						navigation[i].icon = LibraryIcon
						break
					case 'Livret d\'accueil':
						navigation[i].icon = CollectionIcon
						break
					case 'Activités':
						navigation[i].icon = CalendarIcon
						break
					case 'Services':
						navigation[i].icon = TicketIcon
						break
					case 'Utilisateurs':
						navigation[i].icon = UsersIcon
						break
					case 'Avis clients':
						navigation[i].icon = StarIcon
						break
					case 'Statistiques':
						navigation[i].icon = ChartBarIcon
						break
					case 'Inventaires':
						navigation[i].icon = HomeIcon
						break
					case 'Cautions SWIKLY':
						navigation[i].icon = LockClosedIcon
						break
					case 'Email':
						navigation[i].icon = MailIcon
						break
					case 'Notifications':
						navigation[i].icon = SpeakerphoneIcon
						break
					case 'Paramètres':
						navigation[i].icon = CogIcon
						break
					default:
						navigation[i].icon = QuestionMarkCircleIcon
				}
				const url = window.location.href
				let parsedUrl = new URL(url).pathname
				if (parsedUrl === navigation[i].path && navigation[i].collapse === null)
					navigation[i].current = true
				else if (parsedUrl !== navigation[i].path)
					navigation[i].current = false
				if (navigation[i].collapse)
				{
					for (let j = 0; j < navigation[i].collapse.length; j++)
					{
						if (parsedUrl === navigation[i].collapse[j].path)
							navigation[i].collapse[j].current = true
						else if (parsedUrl !== navigation[i].collapse[j].path)
							navigation[i].collapse[j].current = false
					}
				}
			}
			this.setState({ navigation: navigation, url: window.location.href })
		}
	}

	classNames = (...classes) => {
		return classes.filter(Boolean).join(' ')
	}

	setSidebarOpen = () => {
		if (this.state.sidebarOpen === true)
			this.setState({ sidebarOpen: false })
		else
			this.setState({ sidebarOpen: true })
	}

	handleCollapse = (id) => {
		const navigation = this.state.navigation 
		if (navigation[id].display === false)
			navigation[id].display = true
		else
			navigation[id].display = false
		this.setState({ navigation: navigation })
	}

	render() {
		let sidebarOpen = this.state.sidebarOpen
		const user = this.props.user
		let userAvatar = (
			<span className="inline-block h-10 w-10 rounded-full overflow-hidden bg-gray-100">
				<svg className="h-full w-full text-gray-400" fill="currentColor" viewBox="0 0 24 24">
					<path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
				</svg>
			</span>)
		if (user.avatar)
		{
			userAvatar = ( 
				<img
					className="inline-block h-10 w-10 rounded-full"
					src={user.avatar}
					alt="avatar"
				/>)
		}
		return (
			<div>
				<Transition.Root show={sidebarOpen} as={Fragment}>
					<Dialog as="div" className="fixed inset-0 flex z-40 lg:hidden" onClose={this.setSidebarOpen}>
						<Transition.Child
							as={Fragment}
							enter="transition-opacity ease-linear duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="transition-opacity ease-linear duration-300"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
              				<Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
           				</Transition.Child>
						<Transition.Child
							as={Fragment}
							enter="transition ease-in-out duration-300 transform"
							enterFrom="-translate-x-full"
							enterTo="translate-x-0"
							leave="transition ease-in-out duration-300 transform"
							leaveFrom="translate-x-0"
							leaveTo="-translate-x-full"
						>
              				<div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-gray-800">
								<Transition.Child
									as={Fragment}
									enter="ease-in-out duration-300"
									enterFrom="opacity-0"
									enterTo="opacity-100"
									leave="ease-in-out duration-300"
									leaveFrom="opacity-100"
									leaveTo="opacity-0"
								>
									<div className="absolute top-0 right-0 -mr-12 pt-2">
										<button
											type="button"
											className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
											onClick={() => this.setSidebarOpen(false)}
										>
											<span className="sr-only">Close sidebar</span>
											<XIcon className="h-6 w-6 text-white" aria-hidden="true" />
										</button>
									</div>
                				</Transition.Child>
								<div className="flex-shrink-0 flex items-center px-4">
									<img
										className="h-8 w-auto"
										src={logo}
										alt="Workflow"
									/>
								</div>
								<div className="mt-5 flex-1 h-0 overflow-y-auto" style={{ backgroundColor: '#1F2937'}}>
                 					<nav className="px-2 space-y-1 shadow-none" style={{ backgroundColor: '#1F2937'}}>
										{this.state.navigation.map((item, navigation_k) => {
											let collapse = null
											if (item.collapse)
											{
												let collapseStyle = "hidden"
												if (this.state.navigation[navigation_k].display === true)
													collapseStyle = "block"
												collapse = this.state.navigation[navigation_k].collapse.map((elem, collapse_k) => {
													return (
														<div key={collapse_k} className={collapseStyle}>
															<Link
																to={elem.path}
																className={this.classNames(		
																elem.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
																'group flex items-center pl-12 py-2 text-base font-medium rounded-md'
																)}
															>
																{elem.collapseName}
															</Link>
														</div>
													)
												})
												return (
													<div key={navigation_k}>
														<li
															onClick={this.handleCollapse.bind(this, navigation_k)}
															className={this.classNames(		
															item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
															'group flex relative items-center px-3 py-2 text-base font-medium rounded-md'
															)}
														>
															<item.icon
																className={this.classNames(
																	item.current ? 'text-gray-300' : 'text-gray-400 group-hover:text-gray-300',
																	'mr-3 flex-shrink-0 h-6 w-6'
																)}
																aria-hidden="true"
															/>
															{item.name}
															<svg
																className={this.classNames(
																	(collapseStyle === "block") ? 'text-gray-400 rotate-90' : 'text-gray-300',
																	'ml-3 flex-shrink-0 absolute right-4 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150'
																	)}
																	viewBox="0 0 20 20"
																	aria-hidden="true"
															>
																<path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
															</svg>
														</li>
														{collapse}
													</div>
												)
											}
											else 
											{
												return (
													<div key={navigation_k}>
														<Link
															to={item.path}
															className={this.classNames(
															item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
															'group flex items-center px-2 py-2 text-base font-medium rounded-md'
															)}
														>
															<item.icon
																className={this.classNames(
																	item.current ? 'text-gray-300' : 'text-gray-400 group-hover:text-gray-300',
																	'mr-4 flex-shrink-0 h-6 w-6'
																)}
																aria-hidden="true"
															/>
															{item.name}
														</Link>
													</div>
												)
											}
										})}
                  					</nav>
               					</div>
							</div>
						</Transition.Child>
						<div className="flex-shrink-0 w-14" aria-hidden="true">
							{/* Dummy element to force sidebar to shrink to fit close icon */}
						</div>
					</Dialog>
				</Transition.Root>

				{/* Static sidebar for desktop */}
				<div className="hidden lg:flex lg:w-64 lg:flex-col lg:fixed lg:inset-y-0 z-20">
					<div className="flex-1 flex flex-col min-h-0 bg-gray-800">
						<div className="flex items-center h-16 flex-shrink-0 px-4 bg-gray-900">
							<img
								className="h-16 w-auto"
								src={logo}
								alt="pocketcamp"
							/>
						</div>
						<div className="flex-1 flex flex-col overflow-y-auto" style={{ backgroundColor: '#1F2937'}}>
              				<nav className="flex-1 px-2 py-4 space-y-1 shadow-none" style={{ backgroundColor: '#1F2937'}}>
                				{this.state.navigation.map((item, navigation_k) => {
									let collapse = null
									if (item.collapse)
									{
										let collapseStyle = "hidden"
										if (this.state.navigation[navigation_k].display === true)
											collapseStyle = "block"
										collapse = this.state.navigation[navigation_k].collapse.map((elem, collapse_k) => {
											return (
												<div key={collapse_k} className={collapseStyle}>
													<Link
														to={elem.path}
														className={this.classNames(		
														elem.current ? 'bg-gray-900 text-white block' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
														'block group flex items-center pl-12 py-2 text-base font-medium rounded-md'
														)}
													>
														{elem.collapseName}
													</Link>
												</div>
											)
										})
										return (
											<div key={navigation_k}>
												<li
													onClick={this.handleCollapse.bind(this, navigation_k)}
													className={this.classNames(		
													item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
													'group flex relative items-center px-3 py-2 text-base font-medium rounded-md'
													)}
												>
													<item.icon
														className={this.classNames(
															item.current  ? 'text-gray-300' : 'text-gray-400 group-hover:text-gray-300',
															'mr-3 flex-shrink-0 h-6 w-6'
														)}
														aria-hidden="true"
													/>
														{item.name}
													<svg
														className={this.classNames(
															(collapseStyle === "block") ? 'text-gray-400 rotate-90' : 'text-gray-300',
															'ml-3 flex-shrink-0 absolute right-4 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150'
															)}
															viewBox="0 0 20 20"
															aria-hidden="true"
													>
														<path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
													</svg>
												</li>
												{collapse}
											</div>
										)
									}
									else
									{
										return (
											<div key={navigation_k}>
												<Link
													to={item.path}
													className={this.classNames(		
													item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
													'group flex items-center px-3 py-2 text-base font-medium rounded-md'
													)}
												>
													<item.icon
														className={this.classNames(
															item.current ? 'text-gray-300' : 'text-gray-400 group-hover:text-gray-300',
															'mr-3 flex-shrink-0 h-6 w-6'
														)}
														aria-hidden="true"
													/>
													{item.name}
												</Link>
											</div>
										)
									}
								})}
              				</nav>
            			</div>
					</div>
				</div>
				<div className="lg:pl-64 flex flex-col">
					<div className="sticky top-0 z-10 flex-shrink-0 space-between flex h-16 bg-white shadow">
						<div className="flex justify-start">
							<button
								type="button"
								className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 lg:hidden"
								onClick={() => this.setSidebarOpen(true)}
							>
								<span className="sr-only">Open sidebar</span>
								<MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
							</button>
						</div>
						<div className="mr-4 w-full flex justify-end items-center lg:ml-6">
							{/* -- NOTIFICATIONS TO KEEP -- */}
							{/* <button
								type="button"
								className="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
							>
								<span className="sr-only">Voir les notifications</span>
								<BellIcon className="h-6 w-6" aria-hidden="true" />
							</button> */}

							{/* Profile dropdown */}
							<Menu as="div" className="ml-3 relative">
								<div>
									<Menu.Button className="max-w-xs bg-white flex items-center text-sm rounded-full ring-2 ring-gray-400 ring-opacity-20 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
										{userAvatar}
									</Menu.Button>
								</div>
								<Transition
									as={Fragment}
									enter="transition ease-out duration-100"
									enterFrom="transform opacity-0 scale-95"
									enterTo="transform opacity-100 scale-100"
									leave="transition ease-in duration-75"
									leaveFrom="transform opacity-100 scale-100"
									leaveTo="transform opacity-0 scale-95"
								>
									<Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
										{this.userNavigation.map((item) => {
											return (
											<Menu.Item key={item.name}>
												{({ active }) => {
													if (item.onclick === null) // link
													{
														return (
														<Link
															to={item.path}
															className={this.classNames(
																active ? 'bg-gray-100' : '',
																'block px-4 py-2 text-sm text-gray-700'
															)}
														>
															{item.name}
														</Link>
														)
													}
													else
													{
														return (
															<li
																onClick={item.onclick}
																className={this.classNames(
																	active ? 'bg-gray-100' : '',
																	'block px-4 py-2 text-sm text-gray-700'
																)}
															>
																{item.name}
															</li>
														)
													}
												}}
											</Menu.Item>
										)})}
									</Menu.Items>
								</Transition>
							</Menu>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default SidebarTailwind
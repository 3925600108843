import React, { Component } from 'react'
import Loader from '../UI/Loader'
import ModalDelete from '../UI/ModalDelete'
import Modal from '../UI/Modal'
import '../Core/Main.css'
import LangSelector from '../UI/LangSelector'
import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { PencilIcon, XIcon, PlusCircleIcon, HomeIcon } from '@heroicons/react/solid'
import { Link } from 'react-router-dom'
import { ExclamationCircleIcon } from '@heroicons/react/solid'
import tools from '../../tools'
import BannerInfo from '../UI/BannerInfo'
import EmptyFolder from '../UI/EmptyFolder'

class InventoryEdit extends Component {
	constructor(props) {
		super(props)
		this.state = {
			attempt_submit: false,
			accommodation: null,
			pages: [
				{ name: 'Hébergements', href: '/inventaires', current: false },
  				{ name: 'Hébergement ...', href: '#', current: true }
			],
			create_modal: false,
			form1: {
				translations: [{
					lang: this.props.lang_default,
					name: ''
				}]
			},
			form2: {
				objects: [],
				reviews: [],
				selectedId: null,
				name: null
			},
			category_k: null,
			modal_edit: false,
			modal_msg: null,
			current_lang: this.props.lang_default,
			edit_modal: false,
			delete_modal: false,
			delete: null,
			error: this._initErrors(),
			success: null,
			showBanner: false,
			bannerContent: null
		}
		this.length_max = 50
		this.props.updateNavbarTitle('Mes inventaires')
	}

	componentDidMount() {
		this.loadAccommodation()
	}

	componentDidUpdate(prevProps) {
		if (prevProps.accommodations !== this.props.accommodations)
			this.loadAccommodation(true)
	}

	setErrorMsg = (key, msg, id = null) => {
		if (key === "error_fetch_accommodation")
		{
			key = "global"
			msg = tools.default_error_msg("Impossible de récupérer l'hébergement", msg)
		}
		else if (key === "error_create_inventory")
		{
			key = "modal"
			msg = tools.default_error_msg("Impossible de créer un inventaire", msg)
		}
		else if (key === "error_update_inventory")
		{
			key = "modal"
			msg = tools.default_error_msg("Impossible de mettre à jour l'inventaire", msg)
		}
		else if (key === "error_delete_inventory")
		{
			key = "global"
			msg = tools.default_error_msg("Impossible de supprimer un inventaire", msg)
		}
		let error = this._initErrors()
		if (id !== null)
			error[key][id] = msg
		else
			error[key] = msg
		this.setState({ success: null, showBanner: true, bannerContent: msg, error: error })
	}

	setSuccessMsg = (msg) => {
		this.setState({ success: msg, showBanner: true, bannerContent: msg, error: this._initErrors() })
	}

	clearFlash = () => {
		this.setState({ success: null, error: this._initErrors() })
	}

	_initErrors = () => {
		const errors = {
			global: null,
			modal: null,
			name: null,
			object_name: [],
			object_price: [],
			object_quantity: [],
			review_name: [],
			category_name: []
		}
		if (this.state && this.state.form2)
		{
			if (this.state.form2.objects)
			{
				for (let i = 0; i < this.state.form2.objects.length; i++)
				{
					errors.object_name.push(null)
					errors.object_price.push(null)
					errors.object_quantity.push(null)
				}
			}
			if (this.state.form2.reviews)
				for (let i = 0; i < this.state.form2.reviews.length; i++)
					errors.review_name.push(null)
			if (this.state.accommodation.inventory && this.state.accommodation.inventory.categories)
				for (let i = 0; i < this.state.accommodation.inventory.categories.length; i++)
					errors.category_name.push(null)
		}
		return errors
	}

	closeBanner = () => {
		this.setState({ showBanner: false })
	}
	
	loadAccommodation = (fromProps = false) => {
		if (fromProps === true && this.state.accommodation)
		{
			let accommodation = null
			for (let i = 0; i < this.props.accommodations.length; i++)
			{
				if (this.state.accommodation._id === this.props.accommodations[i]._id)
				{
					accommodation = this.props.accommodations[i]
					break
				}
			}
			if (accommodation)
			{
				for (let i = 0; i < accommodation.inventory.categories.length; i++)
					accommodation.inventory.categories[i].old_name = accommodation.inventory.categories[i].name
				this.setState({ accommodation: accommodation })
			}
		}
		else
		{
			this.props.fetchAccommodationByID(this.props.match.params.id)
			.then(accommodation => {
				this.setState({ accommodation: accommodation })
			})
			.catch(err => {
				this.setErrorMsg("error_fetch_accommodation", 1)
				console.log(err)
			})
		}
	}

	openCreateModal = () => {
		const form1 = {
			translations: [{
				lang: this.state.current_lang,
				name: ''
			}]
		}
		this.setState({ form1: form1, create_modal: true, error: this._initErrors(), success: null })
	}

	handleCategoryName = (event) => {
		let found = false
		const translations = tools.clone(this.state.form1.translations)
		for (let i = 0; i < translations.length; i++)
		{
			if (translations[i].lang === this.state.current_lang)
			{
				found = true
				translations[i].name = event.target.value
				break
			}
		}
		if (!found)
		{
			translations.push({
				lang: this.state.current_lang,
				name: event.target.value
			})
		}
		const form1 = {
			translations: translations
		}
		this.setState({ form1: form1 })
	}

	editCategoryName = (category_k, event) => {
		if (this.state.accommodation.inventory && this.state.accommodation.inventory.categories)
		{
			const accomodation = {
				...this.state.accommodation,
			}
			let found = false
			for (let i = 0; i < accomodation.inventory.categories[category_k].translations.length; i++)
			{
				if (accomodation.inventory.categories[category_k].translations[i].lang === this.state.current_lang)
				{
					found = true
					accomodation.inventory.categories[category_k].translations[i].name = event.target.value
					break
				}
			}
			if (!found)
			{
				accomodation.inventory.categories[category_k].translations.push({
					lang: this.state.current_lang,
					name: event.target.value
				})
			}
			this.setState({ accommodation : accomodation })
		}
	}

	blurCategoryName = (category_k, event) => {
		this.submitRenameCategory(category_k, event)
	}

	_validateForm3 = (category_k) => {
		let error_obj = this._initErrors()
		let error = false
		let found_default_name = false
		const translations = this.state.accommodation.inventory.categories[category_k].translations
		for (let i = 0; i < translations.length; i++)
		{
			const lang = translations[i].lang
			const name = translations[i].name
			if (name.length === 0 && lang === this.props.lang_default)
			{
				error = true
				error_obj.category_name[category_k] = "Vous devez indiquer la catégorie (version " + lang.toUpperCase() + ")"
			}
			else if (name.length > this.length_max)
			{
				error = true
				error_obj.category_name[category_k] = "Le nom ne doit pas dépasser " + this.length_max + " caractères (version " + lang.toUpperCase() + ")"
			}
			else if (lang === this.props.lang_default)
				found_default_name = true
			if (!found_default_name && !error_obj.category_name[category_k])
			{
				error = true
				error_obj.category_name[category_k] = "Vous devez indiquer la catégorie (version " + lang.toUpperCase() + ")"
			}
		}
		if (error === true)
			this.setState({ success: null, error: error_obj, attempt_submit: false })
		return (!error) ? true : false
	} 

	submitRenameCategory = (category_k, event) => {
		event.preventDefault()
		if (this._validateForm3(category_k))
		{
			const translations = this.state.accommodation.inventory.categories[category_k].translations
			let langs_not_found = []
			for (let i = 0; i < this.props.langs.length; i++)
			{
				let found = false
				for (let j = 0; j < translations.length; j++)
				{
					if (this.props.langs[i] === translations[j].lang && translations[j].name.length > 0)
					{
						found = true
						break
					}
				}
				if (!found)
					langs_not_found.push(this.props.langs[i])
			}
			if (langs_not_found.length > 0)
			{
				let msg = ""
				for (let i = 0; i < langs_not_found.length; i++)
				{
					if (i > 0)
						msg += ', '
					msg += langs_not_found[i].toUpperCase()
				}
				this.setState({ modal_msg: msg, modal_edit: "rename-category", category_k: category_k })
			}
			else
				this._updateInventoryCategory(category_k)
		}
	}

	_updateInventoryCategory = (category_k) => {
		const category = {
			translations: this.state.accommodation.inventory.categories[category_k].translations,
			accommodation: this.state.accommodation._id,
			id: category_k
		}
		this.props.updateInventoryCategory(category)
		.then(ret => {
			if (ret)
			{
				this.setState({ attempt_submit: false, create_modal: false, modal_edit: false, category_k: null })
				this.setSuccessMsg("Le nom de la catégorie a bien été mis à jour !")
			}
			else
			{
				this.setState({ attempt_submit: false, create_modal: false, modal_edit: false, category_k: null })
				this.setErrorMsg("error_update_inventory", 1)
			}
		})
		.catch(err => {
			this.setState({ attempt_submit: false, create_modal: false, modal_edit: false, category_k: null })
			this.setErrorMsg("error_update_inventory", 2)
			console.log(err)
		})
	}

	_validateForm1 = () => {
		let error_obj = this._initErrors()
		let error = false
		let found_default_name = false
		const translations = this.state.form1.translations
		for (let i = 0; i < translations.length; i++)
		{
			const lang = translations[i].lang
			const name = translations[i].name
			if (name.length === 0 && lang === this.props.lang_default)
			{
				error = true
				error_obj.name = "Vous devez indiquer la catégorie (version " + lang.toUpperCase() + ")"
			}
			else if (name.length > this.length_max)
			{
				error = true
				error_obj.name = "Le nom ne doit pas dépasser " + this.length_max + " caractères (version " + lang.toUpperCase() + ")"
			}
			else if (lang === this.props.lang_default)
				found_default_name = true
			if (!found_default_name && !error_obj.name)
			{
				error = true
				error_obj.name = "Vous devez indiquer la catégorie (version " + this.props.lang_default.toUpperCase() + ")"
			}
		}
		if (error === true)
		{
			error_obj.modal = "Il y a une ou plusieurs erreur(s) dans le formulaire"
			this.setState({ success: null, error: error_obj, attempt_submit: false })
		}
		return (!error) ? true : false
	}

	submitCategory = (event) => {
		event.preventDefault()
		this.setState({ attempt_submit: true })
		if (this._validateForm1())
		{
			const translations = this.state.form1.translations
			let langs_not_found = []
			for (let i = 0; i < this.props.langs.length; i++)
			{
				let found = false
				for (let j = 0; j < translations.length; j++)
				{
					if (this.props.langs[i] === translations[j].lang && translations[j].name.length > 0)
					{
						found = true
						break
					}
				}
				if (!found)
					langs_not_found.push(this.props.langs[i])
			}
			if (langs_not_found.length > 0)
			{
				let msg = ""
				for (let i = 0; i < langs_not_found.length; i++)
				{
					if (i > 0)
						msg += ', '
					msg += langs_not_found[i].toUpperCase()
				}
				this.setState({ modal_msg: msg, modal_edit: "insert-category" })
			}
			else
				this._insertInventoryCategory()
		}
	}

	_insertInventoryCategory = () => {
		const category = {
			translations: this.state.form1.translations,
			accommodation: this.state.accommodation._id
		}
		this.props.insertInventoryCategory(category)
		.then(ret => {
			if (ret)
			{
				this.setState({ attempt_submit: false, create_modal: false, modal_edit: false, category_k: null })
				this.setSuccessMsg("Nouvelle catégorie d'inventaire ajoutée !")
			}
			else
			{
				this.setState({ attempt_submit: false, create_modal: false, modal_edit: false, category_k: null })
				this.setErrorMsg("error_create_inventory", 1)
			}
		})
		.catch(err => {
			this.setState({ attempt_submit: false, create_modal: false, modal_edit: false, category_k: null })
			this.setErrorMsg("error_create_inventory", 2)
			console.log(err)
		})
	}

	closeCreationModal = () => {
		this.setState({ create_modal: false })
	}

	orderItemUp = (object_k) => {
		const objects = this.state.form2.objects
		if (object_k > 0)
		{
			const tmp = objects[object_k]
			objects[object_k] = objects[object_k - 1]
			objects[object_k - 1] = tmp
			const form2 = {
				...this.state.form2,
				objects: objects
			}
			this.setState({ form2 : form2 })
		}
	}

	orderItemDown = (object_k) => {
		const objects = this.state.form2.objects
		if (object_k < objects.length - 1)
		{
			const tmp = objects[object_k]
			objects[object_k] = objects[object_k + 1]
			objects[object_k + 1] = tmp
			const form2 = {
				...this.state.form2,
				objects: objects
			}
			this.setState({ form2 : form2 })
		}
	}

	orderItemReviewUp = (review_k) => {
		const reviews = this.state.form2.reviews
		if (review_k > 0)
		{
			const tmp = reviews[review_k]
			reviews[review_k] = reviews[review_k - 1]
			reviews[review_k - 1] = tmp
			const form2 = {
				...this.state.form2,
				reviews: reviews
			}
			this.setState({ form2 : form2 })
		}
	}

	orderItemReviewDown = (review_k) => {
		const reviews = this.state.form2.reviews
		if (review_k < reviews.length - 1)
		{
			const tmp = reviews[review_k]
			reviews[review_k] = reviews[review_k + 1]
			reviews[review_k + 1] = tmp
			const form2 = {
				...this.state.form2,
				reviews: reviews
			}
			this.setState({ form2 : form2 })
		}
	}
	
	deleteItem = (object_k) => {
		const objects = this.state.form2.objects
		objects.splice(object_k, 1)
		const form2 = {
			...this.state.form2,
			objects: objects
		}
		this.setState({ form2 : form2 })
	}

	deleteItemReview = (review_k) => {
		const reviews = this.state.form2.reviews
		reviews.splice(review_k, 1)
		const form2 = {
			...this.state.form2,
			reviews: reviews
		}
		this.setState({ form2 : form2 })
	}

	_validateForm2 = () => {
		let error_obj = this._initErrors()
		let error = false
		const objects = this.state.form2.objects
		for (let j = 0; j < objects.length; j++)
		{
			const translations = objects[j].translations
			const quantity = objects[j].quantity
			const price = objects[j].price
			let found_default_name = false
			for (let i = 0; i < translations.length; i++)
			{
				const lang = translations[i].lang
				const name = translations[i].name
				if (name.length === 0 && lang === this.props.lang_default)
				{
					error = true
					error_obj.object_name[j] = "Vous devez indiquer le nom de l'objet (version " + lang.toUpperCase() + ")"
				}
				else if (name.length > this.length_max)
				{
					error = true
					error_obj.object_name[j] = "Le nom ne doit pas dépasser " + this.length_max + " caractères (version " + lang.toUpperCase() + ")"
				}
				else if (lang === this.props.lang_default)
					found_default_name = true
				if (!found_default_name && !error_obj.object_name[j])
				{
					error = true
					error_obj.object_name[j] = "Vous devez indiquer le nom de l'objet (version " + this.props.lang_default.toUpperCase() + ")"
				}
			}
			if (quantity.length === 0)
			{
				error_obj.object_quantity[j] = "La quantité est obligatoire"
				error = true
			}
			else if (Number(quantity) <= 0)
			{
				error_obj.object_quantity[j] = "La quantité doit être un entier positif"
				error = true
			}
			if (price.length > 0 && Number(price) <= 0)
			{
				error_obj.object_price[j] = "Le prix doit être une valeur positive"
				error = true
			}
		}
		const reviews = this.state.form2.reviews
		for (let j = 0; j < reviews.length; j++)
		{
			const translations = reviews[j].translations
			let found_default_name = false
			for (let i = 0; i < translations.length; i++)
			{
				const lang = translations[i].lang
				const name = translations[i].name
				if (name.length === 0 && lang === this.props.lang_default)
				{
					error = true
					error_obj.review_name[j] = "Vous devez indiquer le nom de l'objet (version " + lang.toUpperCase() + ")"
				}
				else if (name.length > this.length_max)
				{
					error = true
					error_obj.review_name[j] = "Le nom ne doit pas dépasser " + this.length_max + " caractères (version " + lang.toUpperCase() + ")"
				}
				else if (lang === this.props.lang_default)
					found_default_name = true
				if (!found_default_name && !error_obj.review_name[j])
				{
					error = true
					error_obj.review_name[j] = "Vous devez indiquer le nom de l'objet (version " + this.props.lang_default.toUpperCase() + ")"
				}
			}
		}
		if (error === true)
		{
			error_obj.modal = "Il y a une ou plusieurs erreur(s) dans le formulaire"
			this.setState({ success: null, error: error_obj, attempt_submit: false })
		}
		return (!error) ? true : false
	}

	submitInventoryList = (event) => {
		event.preventDefault()
		this.setState({ attempt_submit: true })
		if (this._validateForm2())
		{
			let langs_not_found = []
			const objects = this.state.form2.objects
			for (let k = 0; k < objects.length; k++)
			{
				const translations = objects[k].translations
				for (let i = 0; i < this.props.langs.length; i++)
				{
					let found = false
					for (let j = 0; j < translations.length; j++)
					{
						if (this.props.langs[i] === translations[j].lang && translations[j].name.length > 0)
						{
							found = true
							break
						}
					}
					if (!found && langs_not_found.indexOf(this.props.langs[i]) === -1)
						langs_not_found.push(this.props.langs[i])
				}
			}
			const reviews = this.state.form2.reviews
			for (let k = 0; k < reviews.length; k++)
			{
				const translations = reviews[k].translations
				for (let i = 0; i < this.props.langs.length; i++)
				{
					let found = false
					for (let j = 0; j < translations.length; j++)
					{
						if (this.props.langs[i] === translations[j].lang && translations[j].name.length > 0)
						{
							found = true
							break
						}
					}
					if (!found && langs_not_found.indexOf(this.props.langs[i]) === -1)
						langs_not_found.push(this.props.langs[i])
				}
			}
			if (langs_not_found.length > 0)
			{
				let msg = ""
				for (let i = 0; i < langs_not_found.length; i++)
				{
					if (i > 0)
						msg += ', '
					msg += langs_not_found[i].toUpperCase()
				}
				this.setState({ modal_msg: msg, modal_edit: "insert-inventory" })
			}
			else
				this._submitInventoryList()
		}
	}

	_submitInventoryList = () => {
		const category = {
			accommodation: this.state.accommodation._id,
			translations: this.state.accommodation.inventory.categories[this.state.form2.selectedId].translations,
			lang: this.props.lang_default,
			objects: this.state.form2.objects,
			reviews: this.state.form2.reviews
		}
		this.props.insertInventoryObjects(category)
		.then(ret => {
			if (ret)
			{
				this.setState({ attempt_submit: false, edit_modal: false, modal_edit: false })
				this.setSuccessMsg("L'inventaire a bien été mis à jour")
			}
			else
			{
				this.setState({ attempt_submit: false, edit_modal: false, modal_edit: false })
				this.setErrorMsg("error_update_inventory", 1)
			}
		})
		.catch(err => {
			this.setState({ attempt_submit: false, edit_modal: false, modal_edit: false })
			this.setErrorMsg("error_update_inventory", 2)
			console.log(err)
		})
	}

	openInventory = (id) => {
		let name = ""
		const translations = this.state.accommodation.inventory.categories[id].translations
		for (let i = 0; i < translations.length; i++)
		{
			if (translations[i].lang === this.props.lang_default)
			{
				name = translations[i].name
				break
			}
		}
		const reviews = this.state.accommodation.inventory.categories[id].reviews
		const form2 = {
			objects: tools.clone(this.state.accommodation.inventory.categories[id].objects),
			reviews: (reviews) ? tools.clone(reviews) : [],
			selectedId: id,
			name: name
		}
		this.setState({ edit_modal: true, form2: form2, error: this._initErrors(), success: null })
	}

	closeEditModal = () => {
		this.setState({ edit_modal: false })
	}

	addItem = () => {
		const objects = this.state.form2.objects
		objects.push({
			quantity: '',
			price: '',
			translations: []
		})
		const form2 = {
			...this.state.form2,
			objects: objects
		}
		this.setState({ form2: form2 })
	}

	addItemReview = () => {
		const reviews = this.state.form2.reviews
		reviews.push({
			translations: []
		})
		const form2 = {
			...this.state.form2,
			reviews: reviews
		}
		this.setState({ form2: form2 })
	}

	handleItemName = (object_k, event) => {
		const objects = this.state.form2.objects
		let found = false
		if (objects[object_k].translations)
		{
			for (let i = 0; i < objects[object_k].translations.length; i++)
			{
				if (objects[object_k].translations[i].lang === this.state.current_lang)
				{
					found = true
					objects[object_k].translations[i].name = event.target.value
					break
				}
			}
		}
		if (!found)
		{
			if (!objects[object_k].translations)
				objects[object_k].translations = []
			objects[object_k].translations.push({
				lang: this.state.current_lang,
				name: event.target.value
			})
		}
		const form2 = {
			...this.state.form2,
			objects: objects
		}
		this.setState({ form2: form2 })
	}

	handleItemReviewName = (review_k, event) => {
		const reviews = this.state.form2.reviews
		let found = false
		if (reviews[review_k].translations)
		{
			for (let i = 0; i < reviews[review_k].translations.length; i++)
			{
				if (reviews[review_k].translations[i].lang === this.state.current_lang)
				{
					found = true
					reviews[review_k].translations[i].name = event.target.value
					break
				}
			}
		}
		if (!found)
		{
			if (!reviews[review_k].translations)
				reviews[review_k].translations = []
			reviews[review_k].translations.push({
				lang: this.state.current_lang,
				name: event.target.value
			})
		}
		const form2 = {
			...this.state.form2,
			reviews: reviews
		}
		this.setState({ form2: form2 })
	}

	handleItemQuantity = (object_k, event) => {
		const objects = this.state.form2.objects
		objects[object_k].quantity = event.target.value
		const form2 = {
			...this.state.form2,
			objects: objects
		}
		this.setState({ form2: form2 })
	}

	handleItemPrice = (object_k, event) => {
		const objects = this.state.form2.objects
		objects[object_k].price = event.target.value
		const form2 = {
			...this.state.form2,
			objects: objects
		}
		this.setState({ form2: form2 })
	}

	answerModalDelete = (answer) => {
		if (answer === true)
			this._deleteInventoryCategory()
		else
			this.setState({ delete: false, delete_modal: false })
	}

	answerModalEdit = (answer) => {
		const modal_edit = this.state.modal_edit
		if (answer === "yes" && modal_edit === "insert-category")
			this._insertInventoryCategory()
		else if (answer === "yes" && modal_edit === "insert-inventory")
			this._submitInventoryList()
		else if (answer === "yes" && modal_edit === "rename-category")
			this._updateInventoryCategory(this.state.category_k)
		else
			this.setState({ attempt_submit: false, modal_edit: false, category_k: null })
	}

	deleteInventoryCategory = (id) => {
		this.setState({ delete: id, delete_modal: true })
	}

	_deleteInventoryCategory = () => {
		const category = {
			accommodation: this.state.accommodation._id,
			id: this.state.delete
		}
		this.setState({ attempt_submit: true })
		this.props.deleteInventoryCategory(category)
		.then(ret => {
			if (ret)
			{
				this.setState({ delete_modal: false, delete: null, attempt_submit: false })
				this.setSuccessMsg("Catégorie d'inventaire supprimée !")
			}
			else
			{
				this.setState({ delete_modal: false, delete: null, attempt_submit: false })
				this.setErrorMsg("error_delete_inventory", 2)
			}
		})
		.catch(err => {
			this.setState({ delete_modal: false, delete: null, attempt_submit: false })
			this.setErrorMsg("error_delete_inventory", 1)
			console.log(err)
		})
	}

	updateLang = (lang) => {
		this.setState({ current_lang: lang })
	}

	render() {
		if (this.state.accommodation)
		{
			const pages = {
				...this.state.pages
			}
			pages[1].name = this.state.accommodation.name
		}
		if (this.state.accommodation === null)
		{
			return (
				<div className="loader">
					<Loader />
				</div>
			)
		}
		else
		{
			const accommodation = this.state.accommodation
			const categories = (accommodation.inventory && accommodation.inventory.categories) ? this.state.accommodation.inventory.categories : []
			const navStyle = "flex bg-transparent shadow-none mb-10"
			let error_nameJSX = null
			if (this.state.error.name)
			{
				error_nameJSX = (
					<div>
						<div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
							<ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
						</div>
						<p className="mt-2 text-sm text-red-600">
							{this.state.error.name}
						</p>
					</div>
				)
			}
			let error_modalJSX = null
			if (this.state.error.modal)
			{
				error_modalJSX = (
					<p className="mt-2 text-sm text-red-600">
						{this.state.error.modal}
					</p>
				)
			}
			let submitbtn_category = (
				<button type="submit" className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500">
					Confirmer
				</button>
			)
			let submitbtn_inventory = (
				<button type="submit" className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
					Enregistrer
				</button>
			)
			if (this.state.attempt_submit === true)
			{
				submitbtn_category = (
					<button type="submit" disabled className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500">
						<svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
							<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
							<path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
						</svg>
						Confirmer
					</button>
				)
				submitbtn_inventory = (
					<button type="submit" disabled className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
						<svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
							<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
							<path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
						</svg>
						Enregistrer
					</button>
				)
			}
			let list_categoriesJSX = (
				<EmptyFolder content="Commencez par créer une catégorie" />
			)
			if (categories.length > 0)
			{
				list_categoriesJSX = (
					<ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4">
						{categories.map((category, category_k) => {
							const inputStyle = {
								borderBottom: '0',
								fontSize: '1.25rem',
								lineHeight: '1.75rem',
								overflow: 'hidden',
								textOverflow: 'ellipsis',
								whiteSpace: 'nowrap',
								textAlign: 'center'
							}
							let name = ''
							for (let i = 0; i < category.translations.length; i++)
							{
								if (category.translations[i].lang === this.state.current_lang)
								{
									name = category.translations[i].name
									break
								}
							}
							return (
								<li key={category_k} className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200">
									<form onSubmit={this.submitRenameCategory.bind(this, category_k)} className="w-full flex items-center justify-between p-6 space-x-6">
										<div className="flex-1 truncate">
											<div className="flex items-center space-x-3">
												{/* class to keep after delete materialize */}
												{/* <h3 className="text-gray-900 text-xl m-auto font-medium truncate">{category.name}</h3> */}
												<p className="mt-2 text-sm text-red-600">
													{this.state.error.category_name[category_k]}
												</p>
												<input
													type='text'
													style={inputStyle}
													value={name}
													onChange={this.editCategoryName.bind(this, category_k)}
													onBlur={this.blurCategoryName.bind(this, category_k)}
												/>
											</div>
										</div>
									</form>
									<div>
										<div className="-mt-px flex divide-x divide-gray-200">
											<div className="w-0 flex-1 flex">
												<button
													type="button"
													onClick={this.openInventory.bind(this, category_k)}
													className="bg-indigo-600 relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500"
												>
													<PencilIcon className="w-5 h-5 text-indigo-400 hover:text-indigo-600" aria-hidden="true" />
													<span className="ml-3 text-white text-base font-medium hover:text-indigo-900">Inventaire</span>
												</button>
											</div>
											<div className="-ml-px w-0 flex-1 flex">
												<button
													type="button"
													onClick={this.deleteInventoryCategory.bind(this, category_k)}
													className="relative bg-red-400  w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500"
												>
													<XIcon className="w-5 h-5 text-white hover:text-red-600" aria-hidden="true" />
												<span className="ml-3 text-white text-base font-medium hover:text-red-900">Supprimer</span>
												</button>
											</div>
										</div>
									</div>
								</li>
							)
						})}
					</ul>
				)
			}
			let open_modal = false
			let content_modal = null
			if (this.state.modal_edit === "insert-category" || this.state.modal_edit === "rename-category")
			{
				open_modal = true
				content_modal = "Le nom de la catégorie n'est pas traduit dans les langues suivantes : " + this.state.modal_msg
			}
			else if (this.state.modal_edit === "insert-inventory")
			{
				open_modal = true
				content_modal = "Le nom de certains objets n'ont pas été traduits dans les langues suivantes : " + this.state.modal_msg
			}
			let name = ""
			for (let i = 0; i < this.state.form1.translations.length; i++)
			{
				if (this.state.form1.translations[i].lang === this.state.current_lang)
				{
					name = this.state.form1.translations[i].name
					break
				}
			}
			return (
				<div>
					<BannerInfo
						content={this.state.bannerContent}
						show={this.state.showBanner}
						closeBanner={this.closeBanner}
						error={this.state.error.global}
						success={this.state.success}
					/>
					<ModalDelete
						title="Supprimer un thème"
						attempt_submit={this.state.attempt_submit}
						content="Vous allez supprimer ce thème tous les objets qui y sont associés"
						open_modal={this.state.delete_modal}
						answerModal={this.answerModalDelete}
					/>
					<Modal
						dismissible={false}
						content={content_modal}
						open_modal={open_modal}
						answerModal={this.answerModalEdit}
					/>
					{/* START - MODAL CREATION */}
					<Transition appear show={this.state.create_modal} as={Fragment}>
						<Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={this.closeCreationModal}>
							<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
								<Transition.Child
									as={Fragment}
									enter="ease-out duration-300"
									enterFrom="opacity-0"
									enterTo="opacity-100"
									leave="ease-in duration-200"
									leaveFrom="opacity-100"
									leaveTo="opacity-0"
								>
									<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
								</Transition.Child>
								<span
									className="hidden sm:inline-block sm:align-middle sm:h-screen"
									aria-hidden="true"
								>
									&#8203;
								</span>
								<Transition.Child
									as={Fragment}
									enter="ease-out duration-300"
									enterFrom="opacity-0 scale-95"
									enterTo="opacity-100 scale-100"
									leave="ease-in duration-200"
									leaveFrom="opacity-100 scale-100"
									leaveTo="opacity-0 scale-95"
								>
									<form onSubmit={this.submitCategory} className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
										<Dialog.Title
											as="h3" className="text-lg leading-6 font-medium text-gray-900"
										>
											Catégorie d'inventaire
										</Dialog.Title>
										<LangSelector lang={this.state.current_lang} updateLang={this.updateLang} />
										<div className="mt-2">
											<div className="mt-1">
												<input
													type="text"
													className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
													placeholder="ex: Vaisselle"
													value={name} 
													onChange={this.handleCategoryName}
												/>
												{error_nameJSX}
											</div>
											<div className="mt-4">
												{submitbtn_category}
											</div>
										</div>
									</form>
								</Transition.Child>
							</div>
						</Dialog>				
					</Transition>
					{/* END - MODAL CREATION */}
					{/* START - MODAL OBJETS INVENTAIRES */}
					<Transition appear show={this.state.edit_modal} as={Fragment}>
						<Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={this.closeEditModal}>
							<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
								<Transition.Child
									as={Fragment}
									enter="ease-out duration-300"
									enterFrom="opacity-0"
									enterTo="opacity-100"
									leave="ease-in duration-200"
									leaveFrom="opacity-100"
									leaveTo="opacity-0"
								>
									<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
								</Transition.Child>
								<span
									className="hidden sm:inline-block sm:align-middle sm:h-screen"
									aria-hidden="true"
								>
									&#8203;
								</span>
								<Transition.Child
									as={Fragment}
									enter="ease-out duration-300"
									enterFrom="opacity-0 scale-95"
									enterTo="opacity-100 scale-100"
									leave="ease-in duration-200"
									leaveFrom="opacity-100 scale-100"
									leaveTo="opacity-0 scale-95"
								>
									<form onSubmit={this.submitInventoryList} className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-5xl sm:w-full sm:p-6" style={{ padding: "0" }}>
										<div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
											<div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
												<div className="ml-4 mt-2">
													<h3 className="ml-10 text-lg leading-6 font-medium text-gray-900">{this.state.form2.name}</h3>
												</div>
												<div className="ml-4 mt-2 flex-shrink-0">
													<button
														type="button"
														className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
														onClick={this.addItemReview}
													>
														Nouvel état des lieux
														<PlusCircleIcon className="ml-2 -mr-1 h-5 w-5" aria-hidden="true"/>
													</button>
												</div>
												<div className="ml-4 mt-2 flex-shrink-0">
													<button
														type="button"
														className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
														onClick={this.addItem}
													>
														Nouvelle fourniture
														<PlusCircleIcon className="ml-2 -mr-1 h-5 w-5" aria-hidden="true"/>
													</button>
												</div>
											</div>
										</div>
										<LangSelector lang={this.state.current_lang} updateLang={this.updateLang} />
										<h2 className="ml-16 font-bold">Fournitures :</h2>
										{this.state.form2 && this.state.form2.objects && this.state.form2.objects.map((object, object_k) => {
											let error_object_nameJSX = null
											if (this.state.error.object_name[object_k])
											{
												error_object_nameJSX = (
													<div>
														<div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
															<ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
														</div>
														<p className="mt-2 text-sm text-red-600">
															{this.state.error.object_name[object_k]}
														</p>
													</div>
												)
											}
											let error_object_quantityJSX = null
											if (this.state.error.object_quantity[object_k])
											{
												error_object_quantityJSX = (
													<div>
														<div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
															<ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
														</div>
														<p className="mt-2 text-sm text-red-600">
															{this.state.error.object_quantity[object_k]}
														</p>
													</div>
												)
											}
											let error_object_priceJSX = null
											if (this.state.error.object_price[object_k])
											{
												error_object_priceJSX = (
													<div>
														<div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
															<ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
														</div>
														<p className="mt-2 text-sm text-red-600">
															{this.state.error.object_price[object_k]}
														</p>
													</div>
												)
											}
											let name = ""
											if (object.translations)
											{
												for (let i = 0; i < object.translations.length; i++)
												{
													if (object.translations[i].lang === this.state.current_lang)
													{
														name = object.translations[i].name
														break
													}
												}
											}
											return (
												<div key={object_k} className="grid grid-cols-4 gap-4 mx-16">
													<div className="col-span-1 sm:grid-cols-2 lg:grid-cols-4">
														<label htmlFor="city" className="block text-sm font-medium text-gray-700">
															Désignation
														</label>
														<input
															type="text"
															className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
															value={name}
															onChange={this.handleItemName.bind(this, object_k)}
														/>
														{error_object_nameJSX}
													</div>
													<div className="col-span-1 sm:grid-cols-2 lg:grid-cols-4">
														<label htmlFor="region" className="block text-sm font-medium text-gray-700">
															Quantité
														</label>
														<input
															type="number"
															className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
															value={object.quantity}
															onChange={this.handleItemQuantity.bind(this, object_k)}
														/>
														{error_object_quantityJSX}
													</div>
													<div className="col-span-1 sm:grid-cols-2 lg:grid-cols-2">
														<label htmlFor="postal-code" className="block text-sm font-medium text-gray-700">
															Prix unitaire (€)
														</label>
														<input
															type="number"
															className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
															value={object.price}
															onChange={this.handleItemPrice.bind(this, object_k)}
														/>
														{error_object_priceJSX}
													</div>
													<div className="flex items-end col-span-1 sm:grid-cols-2 lg:grid-cols-4">
														<button
															type='button'
															className='px-3 py-2 mb-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-green-500 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500'
															onClick={this.orderItemUp.bind(this, object_k)}
														>
															Monter
														</button>
														<button
															type='button'
															className='px-3 py-2 mb-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-green-500 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500'
															onClick={this.orderItemDown.bind(this, object_k)}
														>
															Descendre
														</button>
														<button
															type='button'
															className='px-3 py-2 mb-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-red-300 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500'
															onClick={this.deleteItem.bind(this, object_k)}
														>
															Supprimer
														</button>														
													</div>
												</div>
											)
										})}
										<LangSelector lang={this.state.current_lang} updateLang={this.updateLang} />
										<h2 className="ml-16 font-bold">État des lieux :</h2>
										{this.state.form2 && this.state.form2.reviews && this.state.form2.reviews.map((review, review_k) => {
											let error_review_nameJSX = null
											if (this.state.error.review_name[review_k])
											{
												error_review_nameJSX = (
													<div>
														<div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
															<ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
														</div>
														<p className="mt-2 text-sm text-red-600">
															{this.state.error.review_name[review_k]}
														</p>
													</div>
												)
											}
											let name = ""
											if (review.translations)
											{
												for (let i = 0; i < review.translations.length; i++)
												{
													if (review.translations[i].lang === this.state.current_lang)
													{
														name = review.translations[i].name
														break
													}
												}
											}
											return (
												<div key={review_k} className="grid grid-cols-4 gap-4 mx-16">
													<div className="col-span-1 sm:grid-cols-2 lg:grid-cols-4">
														<label htmlFor="city" className="block text-sm font-medium text-gray-700">
															Désignation
														</label>
														<input
															type="text"
															className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
															value={name}
															onChange={this.handleItemReviewName.bind(this, review_k)}
														/>
														{error_review_nameJSX}
													</div>
													<div className="flex items-end col-span-1 sm:grid-cols-2 lg:grid-cols-4">
														<button
															type='button'
															className='px-3 py-2 mb-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-green-500 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500'
															onClick={this.orderItemReviewUp.bind(this, review_k)}
														>
															Monter
														</button>
														<button
															type='button'
															className='px-3 py-2 mb-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-green-500 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500'
															onClick={this.orderItemReviewDown.bind(this, review_k)}
														>
															Descendre
														</button>
														<button
															type='button'
															className='px-3 py-2 mb-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-red-300 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500'
															onClick={this.deleteItemReview.bind(this, review_k)}
														>
															Supprimer
														</button>														
													</div>
												</div>
											)
										})}
										<div className="mt-10 px-4 py-3 bg-gray-50 text-right sm:px-6">
											{error_modalJSX}
											{submitbtn_inventory}
										</div>
									</form>
								</Transition.Child>
							</div>
						</Dialog>				
					</Transition>
					{/* END - MODAL OBJETS INVENTAIRES */}
					{/* START - OUTILS DE NAVIGATION */}
					<nav className={navStyle} aria-label="Breadcrumb">
						<ol className="bg-white rounded-md shadow px-6 flex space-x-4">
							<li className="flex">
							<div className="flex items-center">
								<Link to="/" className="text-gray-400 hover:text-gray-500">
									<HomeIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
									<span className="sr-only">Home</span>
								</Link>
							</div>
							</li>
							{this.state.pages.map((page, id) => (
								<li key={id} className="flex">
									<div className="flex items-center">
										<svg
											className="flex-shrink-0 w-6 h-full text-gray-200"
											viewBox="0 0 24 44"
											preserveAspectRatio="none"
											fill="currentColor"
											xmlns="http://www.w3.org/2000/svg"
											aria-hidden="true"
										>
											<path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
										</svg>
										<Link
											to={page.href}
											className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
											aria-current={page.current ? 'page' : undefined}
										>
											{page.name}
										</Link>
									</div>
								</li>
							))}
						</ol>
					</nav>
					{/* END - OUTILS DE NAVIGATION */}
					<div className="mb-5">
						<h2 className="text-xl leading-6 font-medium text-gray-900 mb-0">{this.state.accommodation.name}</h2>
						<p className="mt-1 text-base text-gray-500">
							Créez votre inventaire pour cet hébergement.
						</p>
					</div>
					<button onClick={this.openCreateModal} type="button" className="mb-10 inline-flex items-center px-6 py-3 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
						Nouvelle catégorie							
						<svg xmlns="http://www.w3.org/2000/svg" className="ml-3 -mr-1 h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
							  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clipRule="evenodd" />
						</svg>
					</button>
					<LangSelector lang={this.state.current_lang} updateLang={this.updateLang} />
					{list_categoriesJSX}
				</div>
			)
		}
	}
}

export default InventoryEdit
import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'
import M from "materialize-css"
import LangSelector from '../UI/LangSelector'
import Modal from '../UI/Modal'
import Loader from '../UI/Loader'
import tools from '../../tools'
import french_assets from '../../langs/main.fr.json'
import english_assets from '../../langs/main.en.json'
import german_assets from '../../langs/main.de.json'
import dutch_assets from '../../langs/main.nl.json'
import italian_assets from '../../langs/main.it.json'
import spanish_assets from '../../langs/main.es.json'
import portuguese_assets from '../../langs/main.pt.json'
import '../Core/Main.css'
import './Home.css'
import { 
	CursorClickIcon, 
	BellIcon, 
	UsersIcon,
	DownloadIcon } from '@heroicons/react/outline'

class Home extends Component {
	constructor(props) {
		super(props)
		this.lang_assets = {
			fr: french_assets,
			en: english_assets,
			de: german_assets,
			nl: dutch_assets,
			it: italian_assets,
			es: spanish_assets,
			pt: portuguese_assets
		}
		this.state = {
			design: null,
			categories: null,
			pages: null,
			open_modal: false,
			modal_msg: null,
			attempt_submit: false,
			form1: {
				name: '',
				redirect: '{"type":"home"}',
				translations: []
			},
			logs: '?',
			downloads: '?',
			users: '?',
			notifications: '?',
			current_lang: this.props.lang_default,
			error: this._initErrors(),
			success: null
		}
		this.stats = [
			{ id: 1, name: 'Total des utilisateurs', stat: '...', icon: UsersIcon, change: '122', changeType: 'increase' },
			{ id: 3, name: 'Total des pages visitées', stat: '...', icon: CursorClickIcon, change: '3.2%', changeType: 'decrease' },
  			{ id: 2, name: 'Utilisateurs avec notifications', stat: '...', icon: BellIcon, change: '5.4%', changeType: 'increase' },
  			{ id: 4, name: 'Visites depuis l\'app téléchargée', stat: '...', icon: DownloadIcon, change: '3.2%', changeType: 'increase' },
		]
		this.length_max = 40
		this.content_length = 140
		this.props.updateNavbarTitle('Tableau de bord')
	}

	componentDidMount() {
		this.props.fetchWebappConfig()
		.then(config => {
			this.setState({
				downloads: config.downloads,
				logs: config.logs,
				notifications: config.notifications,
				users: config.users
			})
		})
		.catch(err => console.log("could not fetch config", err))
		this.loadPages()
	}

	componentDidUpdate () {
		const elems = document.querySelectorAll('select')
		M.FormSelect.init(elems)
	}

	_initErrors = () => {
		return {
			global: null,
			title: null,
			content: null,
			users: null
		}
	}

	setErrorMsg = (key, msg) => {
		if (key === "error_send_notification")
		{
			key = "global"
			msg = tools.default_error_msg("Impossible d'envoyer une notification", msg)
		}
		else if (key === "error_create_notification")
		{
			key = "global"
			msg = tools.default_error_msg("Impossible de créer une notification", msg)
		}
		else if (key === "error_update_notification")
		{
			key = "global"
			msg = tools.default_error_msg("Impossible de mettre à jour une notification", msg)
		}
		else if (key === "error_delete_notification")
		{
			key = "global"
			msg = tools.default_error_msg("Impossible de supprimer une notification", msg)
		}
		else if (key === "error_fetch_notification")
		{
			key = "global"
			msg = tools.default_error_msg("Impossible de récupérer les notifications", msg)
		}
		else if (key === "error_fetch_users")
		{
			key = "global"
			msg = tools.default_error_msg("Impossible de récupérer les utilisateurs", msg)
		}
		let error = this._initErrors()
		error[key] = msg
		this.setState({ success: null, error: error })
	}

	setSuccessMsg = (msg) => {
		this.clearFlash()
		this.setState({ success: msg, error: this._initErrors() })
	}

	clearFlash = () => {
		this.setState({ success: null, error: this._initErrors() })
	}

	//  = () => {
	// 	this.stats[0].stat = this.state.users
	// }

	handleTitle = (event) => {
		let found = false
		const translations = this.state.form1.translations
		for (let i = 0; i < translations.length; i++)
		{
			if (translations[i].lang === this.state.current_lang)
			{
				found = true
				translations[i].title = event.target.value
				break
			}
		}
		if (!found)
		{
			translations.push({
				lang: this.state.current_lang,
				title: event.target.value,
				content: ''
			})
		}
		const form1 = {
			...this.state.form1,
			translations: translations
		}
		this.setState({ form1: form1 })
	}

	loadPages = () => {
		this.props.fetchPages(true, true)
		.then(pages => {
			this.props.fetchGroups()
			.then(groups => {
				this.props.fetchCategories()
				.then(categories => {
					for (let i = 0; i < categories.length; i++)
					{
						for (let j = 0; j < groups.length; j++)
						{
							if (categories[i].group === groups[j]._id)
							{
								categories[i].group = groups[j]
								break
							}
						}
					}
					this.setState({ categories: categories, pages: pages })
				})
				.catch(err => {
					this.setErrorMsg('error_fetch_notification', 2)
					console.log(err)
				})
			})
			.catch(err => {
				this.setErrorMsg('error_fetch_notification', 3)
				console.log(err)
			})
		})
		.catch(err => {
			this.setErrorMsg('error_fetch_notification', 4)
			console.log(err)
		})
	}

	handleContent = (event) => {
		let found = false
		const translations = this.state.form1.translations
		for (let i = 0; i < translations.length; i++)
		{
			if (translations[i].lang === this.state.current_lang)
			{
				found = true
				translations[i].content = event.target.value
				break
			}
		}
		if (!found)
		{
			translations.push({
				lang: this.state.current_lang,
				title: '',
				content: event.target.value
			})
		}
		const form1 = {
			...this.state.form1,
			translations: translations
		}
		this.setState({ form1: form1 })
	}

	handleRedirect = (event) => {
		const form1 = {
			...this.state.form1,
			redirect: event.target.value
		}
		this.setState({ form1: form1 })
	}

	_validateForm1 = () => {
		const translations = this.state.form1.translations
		let error = false
		let error_obj = this._initErrors()
		let found_default_title = false
		let found_default_content = false
		for (let i = 0; i < translations.length; i++)
		{
			const lang = translations[i].lang
			const title = translations[i].title
			if (title.length === 0 && lang === this.props.lang_default)
			{
				error = true
				error_obj.title = "Vous devez indiquer le titre (version " + lang.toUpperCase() + ")"
			}
			else if (title.length > this.length_max)
			{
				error = true
				error_obj.title = "Le titre ne doit pas dépasser " + this.length_max + " caractères (version " + lang.toUpperCase() + ")"
			}
			else if (lang === this.props.lang_default)
				found_default_title = true
			const content = translations[i].content
			if (content.length === 0 && lang === this.props.lang_default)
			{
				error = true
				error_obj.content = "Vous devez indiquer le contenu (version " + lang.toUpperCase() + ")"
			}
			else if (content.length > this.content_length)
			{
				error = true
				error_obj.content = "Le contenu ne doit pas dépasser " + this.content_length + " caractères (version " + lang.toUpperCase() + ")"
			}
			else if (lang === this.props.lang_default)
				found_default_content = true
		}
		if (!found_default_title && !error_obj.title)
		{
			error = true
			error_obj.title = 'Vous devez indiquer le titre (version ' + this.props.lang_default.toUpperCase() + ')'
		}
		if (!found_default_content && !error_obj.content)
		{
			error = true
			error_obj.content = 'Vous devez indiquer le contenu (version ' + this.props.lang_default.toUpperCase() + ')'
		}
		if (error)
			this.setState({ success: null, error: error_obj, attempt_submit: false })
		return (!error) ? true : false
	}

	handleSend = (event) => {
		event.preventDefault()
		this.clearFlash()
		this.setState({ attempt_submit: true })
		if (this._validateForm1())
		{
			const translations = this.state.form1.translations
			let langs_not_found = []
			for (let i = 0; i < this.props.langs.length; i++)
			{
				let found = false
				for (let j = 0; j < translations.length; j++)
				{
					if (this.props.langs[i] === translations[j].lang && translations[j].title.length > 0)
					{
						found = true
						break
					}
				}
				if (!found)
					langs_not_found.push(this.props.langs[i])
			}
			if (langs_not_found.length > 0)
			{
				let msg = ""
				for (let i = 0; i < langs_not_found.length; i++)
				{
					if (i > 0)
						msg += ', '
					msg += langs_not_found[i].toUpperCase()
				}
				msg = "La notification n'est pas traduite et ne sera pas envoyée dans les langues suivantes : " + msg
				this.setState({ modal_msg: msg, open_modal: true })
			}
			else
				this._sendNotifcation()
		}
	}

	_sendNotifcation = () => {
		const redirect = this.state.form1.redirect
		const translations = this.state.form1.translations
		const notification = {
			user_list: null,
			translations: translations,
			redirect: redirect
		}
		this.props.sendNotifications(notification)
		.then(ret => {
			if (ret === true)
			{
				const form1 = {
					redirect: '{"type":"home"}',
					translations: []
				}
				this.setSuccessMsg("La notification vient d'être envoyée !")
				this.setState({ form1: form1, attempt_submit: false, open_modal: false })
			}
			else
			{
				this.setErrorMsg("global", "Une erreur s'est produite")
				this.setState({ attempt_submit: false, open_modal: false })
			}
		})
		.catch(err => {
			console.log(err)
			this.setErrorMsg("global", "Une erreur s'est produite")
			this.setState({ attempt_submit: false, open_modal: false })
		})
	}

	_getPageList = (categories, pages) => {
		const lang = this.state.current_lang
		let page_list = tools.clone([])
		for (let i = 0; i < categories.length; i++)
		{
			let name = ""
			let suffix = ""
			const category = this.props.categories[i]
			for (let j = 0; j < category.translations.length; j++)
			{
				if (category.translations[j].lang === lang)
				{
					name = category.translations[j].name
					suffix = category.translations[j].suffix
					break
				}
			}
			let prefix = ""
			for (let k = 0; k < this.props.groups.length; k++)
			{
				if (this.props.groups[k]._id === category.group._id)
				{
					const group = this.props.groups[k]
					for (let j = 0; j < group.translations.length; j++)
					{
						if (group.translations[j].lang === lang)
						{
							prefix = group.translations[j].prefix
							break
						}
					}
					break
				}
			}
			let url = this.lang_assets[lang].paths.rubrics + '/' + prefix + '/' + suffix
			if ((prefix.length === 0 || suffix.length === 0) && lang !== this.props.lang_default)
			{
				for (let j = 0; j < category.translations.length; j++)
				{
					if (category.translations[j].lang === this.props.lang_default)
					{
						url = "(rubrique indisponible en version " + lang.toUpperCase() + ")"
						name = category.translations[j].name
						break
					}
				}
			}
			const value = JSON.stringify({
				type: 'category',
				category: categories[i]._id
			})
			for (let k = 0; k < page_list.length; k++)
			{
				if (page_list[k].text === url + ' - ' + name)
				{
					console.log('same category', url + ' - ' + name)
					break
				}
			}
			page_list.push({
				value: value,
				text: name + ' - ' + url
			})
		}
		for (let i = 0; i < pages.length; i++)
		{
			const page = pages[i]
			if (page.category !== null)
				continue
			let name = ""
			let url = this.lang_assets[lang].paths.pages + '/'
			for (let j = 0; j < page.translations.length; j++)
			{
				if (page.translations[j].lang === lang)
				{
					name = page.translations[j].name
					url += page.translations[j].url
					break
				}
			}
			if (name.length === 0 && lang !== this.props.lang_default)
			{
				for (let j = 0; j < page.translations.length; j++)
				{
					if (page.translations[j].lang === this.props.lang_default)
					{
						url = "(page indisponible en version " + lang.toUpperCase() + ")"
						name = page.translations[j].name
						break
					}
				}
			}
			for (let k = 0; k < page_list.length; k++)
			{
				if (page_list[k].text === url + ' - ' + name)
				{
					console.log('same page', url + ' - ' + name)
					break
				}
			}
			const value = JSON.stringify({
				type: 'page',
				page: page._id
			})
			page_list.push({
				value: value,
				text: name + ' - ' + url
			})
		}
		page_list.sort((a, b) => {
			return a.text.localeCompare(b.text)
		})
		page_list.unshift({
			value: JSON.stringify({
				type: 'membres'
			}),
			text: "Page membres - " + this.lang_assets[lang].paths.account
		})
		page_list.unshift({
			value: JSON.stringify({
				type: 'animations'
			}),
			text: "Planning d'animations -" + this.lang_assets[lang].paths.activities
		})
		page_list.unshift({
			value: JSON.stringify({
				type: 'home'
			}),
			text: "Page d'accueil - /"
		})
		return (
			<select id="redirect" value={this.state.form1.redirect} onChange={this.handleRedirect}>
				{page_list.map(({ value, text }) => {
					return (<option value={value} key={value}>{text}</option>)
				})}
			</select>
		)
	}

	answerModal = (answer) => {
		if (answer === "yes")
			this._sendNotifcation()
		else
			this.setState({ attempt_submit: false, open_modal: false })
	}

	updateLang = (lang) => {
		this.setState({ current_lang: lang })
	}

	classNames = (...classes) => {
		return classes.filter(Boolean).join(' ')
	}

	render() {
		const categories = this.state.categories
		const pages = this.state.pages
		const permissions = this.props.user.permissions
		if (!permissions || !permissions.homepage || permissions.homepage === 0)
		{
			let redirection = null
			if (permissions && permissions.rubrics === 1)
				redirection = "/rubriques"
			else if (permissions && permissions.activities === 1)
				redirection = "/animations"
			else if (permissions && permissions.services === 1)
				redirection = "/services"
			else if (permissions && permissions.users === 1)
				redirection = "/utilisateurs"
			else if (permissions && permissions.reviews === 1)
				redirection = "/avis/reponses"
			else if (permissions && permissions.stats === 1)
				redirection = "/stats"
			else if (permissions && permissions.emails === 1)
				redirection = "/administration/emails"
			else if (permissions && permissions.inventories === 1)
				redirection = "/inventaires/checkin"
			else if (permissions && permissions.deposits === 1)
				redirection = "/deposit/users-deposit"
			else if (permissions && permissions.notifications === 1)
				redirection = "/administration/notifications"
			else if (permissions && permissions.params === 1)
				redirection = "/administration/parametres"
			if (redirection != null)
				return (<Redirect to={redirection} />)
		}
		else if (!pages || !categories)
		{
			return (
				<div className="loader">
					<Loader />
				</div>
			)
		}
		else
		{
			let printMsg = null
			if (this.state.error.global || this.state.success)
			{
				printMsg = (
					<div className="center">
						<p className="error">{this.state.error.global}</p>
						<p className="success">{this.state.success}</p>
					</div>
				)
			}
			let sendbtn = (
				<div className="center"style={{ marginTop: '20px' }}>
					<button type="submit" className="waves-effect waves-light btn-small">Envoyer</button>
				</div>
			)
			if (this.state.attempt_submit === true)
			{
				sendbtn = (
					<div className="center">
						<Loader />
					</div>
				)
			}
			let title = ""
			let content = ""
			for (let i = 0; i < this.state.form1.translations.length; i++)
			{
				if (this.state.form1.translations[i].lang === this.state.current_lang)
				{
					title = this.state.form1.translations[i].title
					content = this.state.form1.translations[i].content
					break
				}
			}
			const page_dropdown = this._getPageList(categories, pages)
			return (
				<div className="wrapper-content Home">
					<Modal
						dismissible={false}
						content={this.state.modal_msg}
						open_modal={this.state.open_modal}
						answerModal={this.answerModal}
					/>
					{/* <div className="row">
						<div className="module col s3 quickview">
							<p className="text">
								Connexions<br />
								<strong>{this.state.logs}</strong><br />
								&nbsp;
							</p>
							<p className="icon"><img src={stats} alt="Connexions" /></p>
						</div>
						<div className="module col s3 quickview">
							<p className="text">
								Utilisateurs<br />
								<strong>{this.state.users}</strong><br />
								&nbsp;
							</p>
							<p className="icon"><img src={users} alt="Utilisateurs" /></p>
						</div>
						<div className="module col s3 quickview">
							<p className="text">
								Téléchargements<br />
								<strong>{this.state.downloads}</strong><br />
								&nbsp;
							</p>
							<p className="icon"><img src={downloads} alt="Téléchargements" /></p>
						</div>
						<div className="module col s3 quickview">
							<p className="text">
								Notifications<br /><span className="info">(nb. d'utilisateurs)</span><br />
								<strong>{this.state.notifications}</strong>
							</p>
							<p className="icon"><img src={bell} alt="Notifications" /></p>
						</div>
					</div> */}
					<div className="mt-10">
						<Link to="/stats" className="text-lg leading-6 font-medium text-indigo-600 hover:text-indigo-900">Statistiques générales</Link>
						<dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
							{this.stats.map((item) => {
								if (item.name === 'Total des utilisateurs')
									item.stat = this.state.users
								if (item.name === 'Utilisateurs avec notifications')
									item.stat = this.state.notifications
								if (item.name === 'Total des pages visitées')
									item.stat = this.state.logs
								if (item.name === 'Visites depuis l\'app téléchargée')
									item.stat = this.state.downloads
								return (
								<div
									key={item.id}
									className="relative bg-white pt-5 px-4 pb-5 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden"
								>
									<dt>
										<div className="absolute bg-indigo-500 rounded-md p-3">
											<item.icon className="h-6 w-6 text-white" aria-hidden="true" />
										</div>
										<p className="ml-16 text-sm font-medium text-gray-500 truncate">{item.name}</p>
									</dt>
									<dd className="ml-16 pb-6 flex items-baseline sm:pb-7">
										<p className="text-2xl font-semibold text-gray-900">{item.stat}</p>
										{/* <p className={this.classNames(item.changeType === 'increase' ? 'text-green-600' : 'text-red-600', 'ml-2 flex items-baseline text-sm font-semibold')}>
											{
												item.changeType === 'increase' ? (<ArrowSmUpIcon className="self-center flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" />) : (<ArrowSmDownIcon className="self-center flex-shrink-0 h-5 w-5 text-red-500" aria-hidden="true" />)
											}
											<span className="sr-only">{item.changeType === 'increase' ? 'Increased' : 'Decreased'} by</span>
											{item.change}
										</p> */}
									</dd>
								</div>
								)
							})}
						</dl>
					</div>
					<div className="row">
						{/* <div className="module col s6">
							<h2 className="title-description">Administration</h2>
							<p className="overview">
								<img className="icon" src={settings} alt="Général" /> <Link to="/administration/parametres" className="text">Paramètres généraux</Link>
							</p>
							<p className="overview">
								<img className="icon" src={email} alt="E-mails" /> <Link to="/administration/emails" className="text">Paramétrer les e-mails</Link>
							</p>
							<p className="overview">
								<img className="icon" src={email} alt="Envoi de mails" /> <Link to="/administration/auto-email" className="text">Paramétrer les envoi de mails automatique</Link>
							</p>
							<p className="overview">
								<img className="icon" src={menu} alt="Notifications" /> <Link to="/administration/notifications" className="text">Paramétrer les notifications</Link>
							</p>
						</div> */}
						<div className="module col s6">
							<h2 className="title-description">Envoyer une notification</h2>
							{printMsg}
							<LangSelector lang={this.state.current_lang} updateLang={this.updateLang} />
							<form className="module-form" onSubmit={this.handleSend}>
								<label>
									<p className="error">{this.state.error.title}</p>
									<p>Titre (version {this.state.current_lang.toUpperCase()}) :</p>
									<input type="text" value={title} onChange={this.handleTitle} />
								</label>
								<label>
									<p className="error">{this.state.error.content}</p>
									<p>Contenu (version {this.state.current_lang.toUpperCase()}) :</p>
									<input type="text" value={content} onChange={this.handleContent} />
								</label>
								<label>
									<p>Page qui s'ouvrira en cliquant sur la notification</p>
									{page_dropdown}
								</label>
								{/* <label>Destinataires</label>
								<select>
									<option>Tous les utilisateurs</option>
									<option>Utilisateurs sur place uniquement</option>
									<option>Résidents</option>
								</select> */}
								{sendbtn}
							</form>
						</div>
					</div>
				</div>
			)
			/*let service = null
			let animations = null
			let like = null
			return (
				<div className="wrapper-content">
					<div className="row">
						<div className="module col s6">
							<h2>Administration générale</h2>
							<p>Voici les différentes actions que vous pouvez effectuer :</p>
							<ul>
								<li><Link to="/modules">Administrer la page d'accueil</Link></li>
								<li><Link to="/administration/parametres">Paramètres généraux</Link></li>
								<li><Link to="/administration/emails">Paramétrer les envoi d'email</Link></li>
								<li><Link to="/themes">Gérer les thèmes</Link></li>
								<li><Link to="/icons">Administrer les icônes</Link></li>
								<li><Link to="/menu">Administrer le menu</Link></li>
							</ul>
						</div>
						<div className="module col s6">
							<h2 className="title-description">Accès rapide</h2>
							<p className="overview">
								<img className="icon" src={file} alt="Pages" /> <span className="text">Créer une page</span>
							</p>
							<p className="overview">
								<img className="icon" src={service} alt="Services" /> <span className="text">Ajouter un service</span>
							</p>
							<p className="overview">
								<img className="icon" src={animations} alt="Animations" /> <span className="text">Ajouter une animation</span>
							</p>
							<p className="overview">
								<img className="icon" src={like} alt="Partenaires" /> <span className="text">Changer de thème</span>
							</p>
						</div>
						<div className="module col s6">
							<h2 className="title-description">Top des pages les plus consultées</h2>
							<p className="top-five">
								<strong>1</strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Animations<br />
								<strong>2</strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Location de vélos<br />
								<strong>3</strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Spa<br />
								<strong>4</strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Informations pratiques<br />
								<strong>5</strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Location de kayaks<br />
							</p>
						</div>
					</div>
				</div>
			)*/
		}
	}
}

export default Home

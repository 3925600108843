import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import Modal from '../UI/Modal'
import Loader from '../UI/Loader'
import LangSelector from '../UI/LangSelector'
import CKEditor from '../UI/CKEditor'
import tools from '../../tools'
import M from "materialize-css"
import '../Core/Main.css'

class Email extends Component {
	constructor(props) {
		super(props)
		this.subscribe_id = 'subscribe-email'
		this.confirm_id = 'confirmation-email'
		this.state = {
			edit: null,
			header: 'Envoi d\'e-mails',
			emails: null,
			current_lang: this.props.lang_default,
			create: false,
			answerModal: null,
			open_modal: false,
			modal_msg: null,
			delete: null,
			attempt_submit: false,
			attempt_send: false,
			form1: {
				id: ''
			},
			form2: {
				destinataire: '',
				expediteur: '',
				nom: '',
				prenom: '',
				code: '',
				lien: ''
			},
			form3: {
				name: '',
				translations: []
			},
			error: this._initErrors(),
			success: this._initSuccess()
		}
		this.length_max = 120
		console.log(props.location.clients_numbers)
		this.props.updateNavbarTitle('Paramétrer les e-mails')
	}

	componentDidMount() {
		this.loadEmails()
	}
	
	componentDidUpdate (prevProps) {
		const elems = document.querySelectorAll('select')
		M.FormSelect.init(elems)
		if (prevProps.emails !== this.props.emails)
			this.loadEmails(true)
	}

	loadEmails = (fromProps = false) => {
		if (fromProps === true && this.props.emails)
			this.setState({ emails: this.props.emails })
		else
		{
			this.props.fetchEmails()
			.then(emails => {
				this.setState({ emails: emails })
			})
			.catch(err => {
				this.setErrorMsg("error_fetch_emails", 1)
				console.log(err)
			})
		}
	}
	
	fetchEmails = () => {
		this.props.fetchEmails()
		.then(emails => {
			this.setState({ emails: emails })
		})
		.catch(err => {
			this.setErrorMsg("error_fetch_emails", 1)
			console.log(err)
		})
	}

	handleName = (event) => {
		const form3 = {
			...this.state.form3,
			name: event.target.value
		}
		this.setState({ form3: form3 })
	}

	handleTitle = (event) => {
		let found = false
		const translations = this.state.form3.translations
		for (let i = 0; i < translations.length; i++)
		{
			if (translations[i].lang === this.state.current_lang)
			{
				found = true
				translations[i].title = event.target.value
				break
			}
		}
		if (!found)
		{
			translations.push({
				lang: this.state.current_lang,
				title: event.target.value,
				content: ""
			})
		}
		const form3 = {
			...this.state.form3,
			translations: translations
		}
		this.setState({ form3: form3 })
	}

	updateContent = (content) => {
		let found = false
		const translations = this.state.form3.translations
		for (let i = 0; i < translations.length; i++)
		{
			if (translations[i].lang === this.state.current_lang)
			{
				found = true
				translations[i].content = content
				break
			}
		}
		if (!found)
		{
			translations.push({
				lang: this.state.current_lang,
				title: '',
				content: content
			})
		}
		const form3 = {
			...this.state.form3,
			translations: translations
		}
		this.setState({ form3: form3 })
		/*
		const form3 = this.state.form3
		if (this.state.edit !== null)
		{
			let emails = this.state.emails
			for (let i = 0; i < emails.length; i++)
			{
				if (emails[i]._id === this.state.edit)
				{
					emails[i].content = content // <----- WHY ?
					form3.content = content
					break
				}
			}
			this.setState({ form3: form3, emails: emails })
		}
		else if (this.state.create === true)
		{
			form3.content = content
			this.setState({ form3: form3 })
		}
		*/
	}

	setErrorMsg = (key, msg, id = null) => {
		if (key === "error_create_email")
		{
			key = "global"
			msg = tools.default_error_msg("Impossible de créer un e-mail", msg)
		}
		else if (key === "error_update_email")
		{
			key = "global"
			msg = tools.default_error_msg("Impossible de modifier un e-mail", msg)
		}
		else if (key === "error_delete_email")
		{
			key = "global"
			msg = tools.default_error_msg("Impossible de supprimer un e-mail", msg)
		}
		else if (key === "error_send_email")
		{
			key = "send"
			msg = tools.default_error_msg("Impossible d'envoyer un e-mail de test", msg)
		}
		else if (key === "error_fetch_emails")
		{
			key = "global"
			msg = tools.default_error_msg("Impossible de récupérer la page", msg)
		}
		let error = this._initErrors()
		if (id !== null)
			error[key][id] = msg
		else
			error[key] = msg
		this.setState({ success: this._initSuccess(), error: error })
	}

	setSuccessMsg = (key, msg) => {
		let success = this._initSuccess()
		success[key] = msg
		this.setState({ error: this._initErrors(), success: success })
	}

	clearFlash = () => {
		this.setState({ error: this._initErrors(), success: this._initSuccess() })
	}

	_initErrors = () => {
		const errors = {
			global: null,
			email_name: null,
			email_title: null,
			email_content: null,
			send: null,
			send_to: null,
			send_from: null
		}
		return errors
	}

	_initSuccess = () => {
		const success = {
			global: null,
			send: null
		}
		return success
	}

	handleTemplate = (event) => {
		const form1 = {
			id: event.target.value
		}
		this.setState({ form1: form1 })
	}

	handleDestinataire = (event) => {
		const form2 = {
			...this.state.form2,
			destinataire: event.target.value
		}
		this.setState({ form2: form2 })
	}

	handleExpediteur = (event) => {
		const form2 = {
			...this.state.form2,
			expediteur: event.target.value,
		}
		this.setState({ form2: form2 })
	}

	handleNom = (event) => {
		const form2 = {
			...this.state.form2,
			nom: event.target.value
		}
		this.setState({ form2: form2 })
	}

	handlePrenom = (event) => {
		const form2 = {
			...this.state.form2,
			prenom: event.target.value
		}
		this.setState({ form2: form2 })
	}

	handleCode = (event) => {
		const form2 = {
			...this.state.form2,
			code: event.target.value
		}
		this.setState({ form2: form2 })
	}

	handleLien = (event) => {
		const form2 = {
			...this.state.form2,
			lien: event.target.value
		}
		this.setState({ form2: form2 })
	}

	selectTemplate = (event) => {
		event.preventDefault()
		let header = "Template d'e-mail personnalisé"
		let id = (this.state.form1.id.length > 0) ? this.state.form1.id : null
		let form3 = {
			name: '',
			translations: []
		}
		if (id !== null)
		{
			for (let i = 0; i < this.state.emails.length; i++)
			{
				if (this.state.emails[i]._id === id)
				{
					if (this.state.emails[i].default === "subscribe-email")
						header = "E-mail avant séjour"
					else if (this.state.emails[i].default === "confirmation-email")
						header = "E-mail d'activation de l'adresse e-mail"
					form3 = {
						name: this.state.emails[i].name,
						translations: this.state.emails[i].translations
					}
					break
				}
			}
		}
		this.clearFlash()
		this.setState({ create: false, edit: id, header: header, form3: form3 })
	}

	_validateForm3 = () => {
		let error = false
		let error_obj = this._initErrors()
		const name = this.state.form3.name
		const translations = this.state.form3.translations
		if (name.length === 0)
		{
			error = true
			error_obj.email_name = "Veuillez indiquer un nom"
		}
		else if (name.length > this.length_max)
		{
			error = true
			error_obj.email_name = "Le nom ne doit pas dépasser " + this.length_max + " caractères"
		}
		let found_default_title = false
		let found_default_content = false
		for (let i = 0; i < translations.length; i++)
		{
			const lang = translations[i].lang
			const title = translations[i].title
			if (title.length === 0 && lang === this.props.lang_default)
			{
				error = true
				error_obj.email_title = "Vous devez indiquer le titre (version " + lang.toUpperCase() + ")"
			}
			else if (title.length > this.length_max)
			{
				error = true
				error_obj.email_title = "Le titre ne doit pas dépasser " + this.length_max + " caractères (version " + lang.toUpperCase() + ")"
			}
			else if (lang === this.props.lang_default)
				found_default_title = true
			const content = translations[i].content
			if (content.length === 0 && lang === this.props.lang_default)
			{
				error = true
				error_obj.email_content = "Vous devez indiquer un contenu (version " + lang.toUpperCase() + ")"
			}
			else if (lang === this.props.lang_default)
				found_default_content = true
		}
		if (!found_default_title && !error_obj.email_title)
		{
			error = true
			error_obj.email_title = 'Vous devez indiquer le titre (version ' + this.props.lang_default.toUpperCase() + ')'
		}
		if (!found_default_content && !error_obj.email_content)
		{
			error = true
			error_obj.email_content = 'Vous devez indiquer un contenu (version ' + this.props.lang_default.toUpperCase() + ')'
		}
		if (error === true)
		{
			error_obj.global = "Il y a une ou plusieurs erreur(s) dans le formulaire"
			this.setState({ success: this._initSuccess(), error: error_obj, attempt_submit: false })
		}
		return (!error) ? true : false
	}

	handleSave = () => {
		this.setState({ attempt_submit: true })
		if (this._validateForm3())
		{
			const translations = this.state.form3.translations
			let langs_not_found = []
			for (let i = 0; i < this.props.langs.length; i++)
			{
				let found = false
				for (let j = 0; j < translations.length; j++)
				{
					if (this.props.langs[i] === translations[j].lang && translations[j].title.length > 0 && translations[j].content.length > 0)
					{
						found = true
						break
					}
				}
				if (!found)
					langs_not_found.push(this.props.langs[i])
			}
			if (langs_not_found.length > 0)
			{
				let msg = ""
				for (let i = 0; i < langs_not_found.length; i++)
				{
					if (i > 0)
						msg += ', '
					msg += langs_not_found[i].toUpperCase()
				}
				msg = "Le template d'e-mail n'est pas traduit pour tous les utilisateurs et ne sera pas envoyé dans les langues suivantes : " + msg
				this.setState({ modal_msg: msg, open_modal: true, answerModal: this.answerModalEdit })
			}
			else
				this._insertEmail()
		}
	}

	_insertEmail = () => {
		const form1 = {
			id: ''
		}
		const form3 = {
			name: '',
			translations: []
		}
		if (this.state.edit !== null)
		{
			const email = {
				_id: this.state.edit,
				name: this.state.form3.name,
				translations: this.state.form3.translations
			}
			this.props.updateEmail(email)
			.then(ret => {
				if (ret === false)
				{
					this.setState({ attempt_submit: false, open_modal: false })
					this.setErrorMsg("error_create_email", 1)
				}
				else
				{
					this.setState({ attempt_submit: false, form1: form1, form3: form3, edit: null, open_modal: false })
					this.setSuccessMsg("global", "Le mail a bien été mis à jour")
				}
			})
			.catch(err => {
				this.setState({ attempt_submit: false, open_modal: false })
				this.setErrorMsg("error_create_email", 2)
				console.log(err)
			})
		}
		else if (this.state.create === true)
		{
			const email = {
				name: this.state.form3.name,
				translations: this.state.form3.translations
			}
			this.props.insertEmail(email)
			.then(ret => {
				if (ret === false)
				{
					this.setState({ attempt_submit: false, open_modal: false })
					this.setErrorMsg("error_update_email", 2)
				}
				else
				{
					this.setState({ attempt_submit: false, form1: form1, form3: form3, create: false, open_modal: false })
					this.setSuccessMsg("global", "Le mail a bien été créé")
				}
			})
			.catch(err => {
				this.setState({ attempt_submit: false, open_modal: false })
				this.setErrorMsg("error_update_email", 2)
				console.log(err)
			})
		}
	}

	_validateForm2 = () => {
		let error = false
		let error_obj = this._initErrors()
		const destinataire = this.state.form2.destinataire
		const expediteur = this.state.form2.expediteur
		if (destinataire.length === 0)
		{
			error = true
			error_obj.send_to = "Veuillez indiquer l'adresse e-mail de destination"
		}
		else if (tools.testEmail(destinataire) === false)
		{
			error = true
			error_obj.send_to = "Adresse e-mail du destinataire invalide"
		}
		if (expediteur.length > 0 && tools.testEmail(expediteur) === false)
		{
			error = true
			error_obj.send_from = "Adresse e-mail de l'expéditeur invalide"
		}
		if (error === true)
		{
			error_obj.send = "Il y a une ou plusieurs erreur(s) dans le formulaire"
			this.setState({ success: this._initSuccess(), error: error_obj, attempt_send: false })
		}
		return (!error) ? true : false
	}

	handleSend = (event) => {
		event.preventDefault()
		this.setState({ attempt_send: true })
		if (this._validateForm2())
		{
			const email = {
				_id: this.state.form1.id,
				lang: this.state.current_lang,
				destinataire: this.state.form2.destinataire,
				expediteur: this.state.form2.expediteur,
				nom: this.state.form2.nom,
				prenom: this.state.form2.prenom,
				code: this.state.form2.code,
				lien: this.state.form2.lien
			}
			this.props.sendEmail(email)
			.then(ret => {
				if (ret === false)
				{
					this.setState({ attempt_send: false })
					this.setErrorMsg("error_send_email", 1)
				}
				else
				{
					this.setState({ attempt_send: false })
					this.setSuccessMsg("send", "L'email de test vient d'être envoyé")
				}
			})
			.catch(err => {
				this.setState({ attempt_send: false })
				console.log(err)
			})
		}
	}

	handleCreate = () => {
		const form1 = {
			id: ''
		}
		const form3 = {
			name: '',
			translations: []
		}
		this.setState({ edit: null, form1: form1, form3: form3, create: true })
	}

	handleDelete = () => {
		const msg = "Vous êtes sur le point de supprimer ce template d'e-mail. Cette action est irréversible"
		this.setState({ modal_msg: msg, open_modal: true, answerModal: this.answerModalDelete })
	}

	_deleteEmail = () => {
		if (this.state.edit !== null)
		{
			const form1 = {
				id: ''
			}
			const form3 = {
				name: '',
				translations: []
			}
			this.props.deleteEmail(this.state.edit)
			.then(ret => {
				if (ret === false)
				{
					this.setState({ open_modal: false })
					this.setErrorMsg("error_delete_email", 2)
				}
				else
				{
					this.setState({ form1: form1, form3: form3, edit: null, open_modal: false })
					this.setSuccessMsg("global", "Le mail a bien été supprimé")
				}
			})
			.catch(err => {
				this.setState({ open_modal: false })
				this.setErrorMsg("error_delete_email", 2)
				console.log(err)
			})
		}
	}

	answerModalEdit = (answer) => {
		if (answer === "yes")
			this._insertEmail()
		else
			this.setState({ attempt_submit: false, open_modal: false })
	}

	answerModalDelete = (answer) => {
		if (answer === "yes")
			this._deleteEmail()
		else
			this.setState({ open_modal: false })
	}

	updateLang = (lang) => {
		this.setState({ current_lang: lang })
	}

	render() {
		const emails = this.state.emails
		const permissions = this.props.user.permissions
		if (!permissions || !permissions.emails || permissions.emails === 0)
			return (<Redirect to="/" />)
		else if (emails === null)
		{
			return (
				<div className="loader">
					<Loader />
				</div>
			)
		}
		else
		{
			let JoditJSX = null
			let sendMailJSX = null
			if (this.state.edit !== null || this.state.create === true)
			{
				let btn = (this.state.create) ? "Enregistrer" : "Mettre à jour"
				let deletebtn = (<button onClick={this.handleDelete} className="waves-effect waves-light btn-small red lighten-1 margin-left">Supprimer</button>)
				let title = ""
				let content = ""
				for (let i = 0; i < this.state.form3.translations.length; i++)
				{
					if (this.state.form3.translations[i].lang === this.state.current_lang)
					{
						title = this.state.form3.translations[i].title
						content = this.state.form3.translations[i].content
						break
					}
				}
				let email = null
				if (this.state.edit !== null)
				{
					for (let i = 0; i < this.state.emails.length; i++)
					{
						if (this.state.emails[i]._id === this.state.edit)
						{
							email = this.state.emails[i]
							break
						}
					}
				}
				if (this.state.create === true || (email !== null && email.default !== false))
					deletebtn = null
				let sendbtn = (
					<div className="center"style={{ marginTop: '20px' }}>
						<p className="error">{this.state.error.global}</p>
						<button onClick={this.handleSave} className="waves-effect waves-light btn-small">{btn}</button>
						{deletebtn}
					</div>
				)
				if (this.state.attempt_submit === true)
				{
					sendbtn = (
						<div className="center">
							<Loader />
						</div>
					)
				}
				JoditJSX = (
					<div className="row">
						<div className="module col s12">
							<h2 className="title-description">{this.state.header}</h2>
							<LangSelector lang={this.state.current_lang} updateLang={this.updateLang} />
							<label>
								<p className="error">{this.state.error.email_name}</p>
								<p>Nom :</p>
								<input type="text" value={this.state.form3.name} onChange={this.handleName} />
							</label>
							<label>
								<p className="error">{this.state.error.email_title}</p>
								<p>Objet (version {this.state.current_lang.toUpperCase()}) :</p>
								<input type="text" value={title} onChange={this.handleTitle} />
							</label>
							<p className="error">{this.state.error.email_content}</p>
							<CKEditor
								toolbar="full"
								data={content}
								updateContent={this.updateContent}
							/>
							{sendbtn}
						</div>
					</div>
				)
				sendbtn = (<button type="submit" className="waves-effect waves-light btn-small">Envoyer un test</button>)
				if (this.state.attempt_send === true)
				{
					sendbtn = (
						<div className="center">
							<Loader />
						</div>
					)
				}
				sendMailJSX = (
					<div>
						<h3 style={{ marginTop: '50px' }}>Envoyer un e-mail de test</h3>
						<p className="success">{this.state.success.send}</p>
						<form className="module-form" onSubmit={this.handleSend}>
							<label>
								<p className="error">{this.state.error.send_to}</p>
								<p className="column-20">* Destinataire :</p>
								<input type="text" value={this.state.form2.destinataire} onChange={this.handleDestinataire} />
							</label>
							<label>
								<p className="error">{this.state.error.send_from}</p>
								<p className="column-20">Expéditeur :</p>
								<input type="text" value={this.state.form2.expediteur} onChange={this.handleExpediteur} />
							</label>
							<label>
								<p className="column-20">Nom :</p>
								<input type="text" value={this.state.form2.nom} onChange={this.handleNom} />
							</label>
							<label>
								<p className="column-20">Prénom :</p>
								<input type="text" value={this.state.form2.prenom} onChange={this.handlePrenom} />
							</label>
							<label>
								<p className="column-20">Code :</p>
								<input type="text" value={this.state.form2.code} onChange={this.handleCode} />
							</label>
							<label>
								<p className="column-20">Lien :</p>
								<input type="text" value={this.state.form2.lien} onChange={this.handleLien} />
							</label>
							<p className="error">{this.state.error.send}</p>
							{sendbtn}
						</form>
					</div>
				)
			}
			let btnCreate = null
			if (this.state.create === false)
				btnCreate = (<button className="waves-effect waves-light btn" onClick={this.handleCreate}>Créer un nouveau template</button>)
			else
				sendMailJSX = null
			return (
				<div className="wrapper-content">
					<p className="success">{this.state.success.global}</p>
					<Modal
						dismissible={false}
						content={this.state.modal_msg}
						open_modal={this.state.open_modal}
						answerModal={this.state.answerModal}
					/>
					{JoditJSX}
					<div className="row">
						<div className="module col s6">
							{btnCreate}
							<form className="module-form" onSubmit={this.selectTemplate} style={{ marginTop: '20px' }}>
								<label>
									{/* <p className="">Template d'e-mail :</p> */}
									<select value={this.state.form1.id} onChange={this.handleTemplate}>
										<option value="">Sélectionnez un tempate d'Email</option>
										{emails.map(({ _id, name }) => {
											return (<option value={_id} key={_id}>{name}</option>)
										})}
									</select>
									<button type="submit" className="waves-effect waves-light btn-small">Confirmer</button>
								</label>
							</form>
							{sendMailJSX}
						</div>
						<div className="module col s6">
							<h2>Intégrer des variables</h2>
							<p>
								Pour intégrer des variables à l'intérieur du message, comme le nom de la personne, son adresse e-mail ou le code d'activation,
								il faut écrire le nom de la variable précédé d'un <i>dièse</i> <b>"#"</b>.<br />
								<i>Exemple : pour écrire le prénom de la personne à qui sera envoyé le mail écrivez</i> <b>#prenom</b>
							</p>
							<p>Liste des variables disponibles pour les mails :</p>
							<ul>
								<li><b>#code :</b> Code d'activation pour l'inscription</li>
								<li><b>#lien :</b> Lien d'activation pour l'adresse e-mail</li>
								<li><b>#nom :</b> Nom de famille du destinataire</li>
								<li><b>#prenom :</b> Prénom du destinataire</li>
								<li><b>#destinataire :</b> Adresse e-mail du destinataire</li>
								<li><b>#expediteur :</b> Adresse e-mail de l'expéditeur</li>
							</ul>
						</div>
					</div>
				</div>
			)
		}
	}
}

export default Email

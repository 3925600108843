import React, { Component } from 'react'
import ModalDelete from '../UI/ModalDelete'
import tools from '../../tools'
import '../Core/Main.css'
import '../UI/BannerInfo'
import Loader from '../UI/Loader'
import LangSelector from '../UI/LangSelector'
import { Link, Redirect } from 'react-router-dom'
import { Fragment } from 'react'
import { Dialog, Transition, Menu } from '@headlessui/react'
import { HomeIcon } from '@heroicons/react/outline'
import { PencilIcon, DotsVerticalIcon, ExclamationCircleIcon } from '@heroicons/react/solid'
import BannerInfo from '../UI/BannerInfo'
import EmptyFolder from '../UI/EmptyFolder'
import OnlyPremiumEmpty from '../UI/OnlyPremiumEmpty'
import PremiumAlert from '../UI/PremiumAlert'

class Inventory extends Component {
	constructor(props) {
		super(props)
		this.state = {
			create: false,
			accommodation: null,
			accommodations: null,
			config_inventory: null,
			attempt_submit: false,
			init_modal: false,
			open_modal: false,
			pages: [
				{ name: 'Hébergements', href: '/inventaires', current: false },
			],
			form1: {
				isOpen: false,
				name: ''
			},
			form2: {
				arrival: false,
				departure: false,
				delay: '',
				translations: [{
					lang: this.props.lang_default,
					comment: ''
				}],
			},
			form3: {
				arrival_access: {
					staff: false,
					user: false
				},
				departure_access: {
					staff: false,
					user: false
				}
			},
			current_lang: this.props.lang_default,
			error: this._initErrors(),
			success: null,
			show_banner: false,
			banner_content: null,
			selected_accommodation: null,
			modal_mode: null
		}
		this.length_max = 50
		this.intro_length_max = 1200
		this.props.updateNavbarTitle('Mes inventaires')
	}
	
	componentDidMount() {
		this.loadConfigInventory()
		this.loadAccommodations()
	}

	componentDidUpdate(prevProps) {
		if (prevProps.accommodations !== this.props.accommodations)
			this.loadAccommodations(true)
		if (prevProps.config_inventory !== this.props.config_inventory)
			this.loadConfigInventory(true)
	}

	setErrorMsg = (key, msg, id = null) => {
		if (key === "error_fetch_accommodations")
		{
			key = "global"
			msg = tools.default_error_msg("Impossible de récupérer les catégories d'hébergement", msg)
		}
		else if (key === "error_fetch_config")
		{
			key = "global"
			msg = tools.default_error_msg("Impossible de récupérer les paramètres d'inventaires", msg)
		}
		else if (key === "error_create_accommodation")
		{
			key = "global"
			msg = tools.default_error_msg("Impossible de créer une catégorie d'hébergement", msg)
		}
		else if (key === "error_duplicate_accommodation")
		{
			key = "global"
			msg = tools.default_error_msg("Impossible de dupliquer une catégorie d'hébergement", msg)
		}
		else if (key === "error_update_accommodation")
		{
			key = "global"
			msg = tools.default_error_msg("Impossible de modifier une catégorie d'hébergement", msg)
		}
		else if (key === "error_update_config")
		{
			key = "global"
			msg = tools.default_error_msg("Impossible de mettre à jour les paramètres d'inventaires", msg)
		}
		else if (key === "error_delete_accommodation")
		{
			key = "global"
			msg = tools.default_error_msg("Impossible de supprimer une catégorie d'hébergement", msg)
		}
		let error = this._initErrors()
		if (id !== null)
			error[key][id] = msg
		else
			error[key] = msg
		this.setState({ success: null, show_banner: true, banner_content: msg, error: error })
	}

	setSuccessMsg = (msg) => {
		this.clearFlash()
		this.setState({ success: msg, banner_content: msg, show_banner: true, error: this._initErrors() })
	}

	clearFlash = () => {
		this.setState({ success: null, error: this._initErrors() })
	}

	_initErrors = () => {
		const errors = {
			global: null,
			name: null,
			config: null,
			delay: null,
			comment: null
		}
		return errors
	}

	closeBanner = () => {
		this.setState({ show_banner: false })
	}

	loadAccommodations = (fromProps = false) => {
		if (fromProps === true)
		{
			let accommodations = tools.clone(this.props.accommodations)
			accommodations.sort((a, b) => {
				return a.name.localeCompare(b.name)
			})
			this.setState({ accommodations: accommodations })
		}
		else
		{
			this.props.fetchAccommodations()
			.then(accommodations => {
				accommodations.sort((a, b) => {
					return a.name.localeCompare(b.name)
				})
				this.setState({ accommodations: accommodations })
			})
			.catch(err => {
				this.setErrorMsg("error_fetch_accommodations", 1)
				console.log(err)
			})
		}
	}

	loadConfigInventory = (fromProps = false) => {
		if (fromProps === true)
			this.setState({ config_inventory: this.props.config_inventory, form2: this.props.config_inventory })
		else
		{
			this.props.fetchConfigInventory()
			.then(config_inventory => {
				const form3 = {
					arrival_access: {
						staff: config_inventory.arrival_staff,
						user: config_inventory.arrival_user
					},
					departure_access: {
						staff: config_inventory.departure_staff,
						user: config_inventory.departure_user
					}
				}
				this.setState({ config_inventory: config_inventory, form2: config_inventory, form3: form3 })
			})
			.catch(err => {
				this.setErrorMsg("error_fetch_config", 1)
				console.log(err)
			})
		}
	}

	handleName = (event) => {
		const form1 = {
			...this.state.form1,
			name: event.target.value
		}
		this.setState({ form1: form1 })
	}

	_validateForm1 = () => {
		let error_obj = this._initErrors()
		let error = false
		const name = this.state.form1.name
		if (name.length === 0)
		{
			error_obj.name = "Saisissez le nom de l'hébergement"
			error = true
		}
		else if (name.length > this.length_max)
		{
			error_obj.name = "Le nom ne doit pas dépasser " + this.length_max + " caractères"
			error = true
		}
		if (error === true)
		{
			error_obj.modal = "Il y a une ou plusieurs erreur(s) dans le formulaire"
			this.setState({ success: null, error: error_obj, attempt_submit: false })
		}
		return (!error) ? true : false
	}

	submitAccommodation = (event) => {
		event.preventDefault()
		this.setState({ attempt_submit: 1 })
		if (this._validateForm1())
		{
			const form1 = {
				isOpen: false,
				name: ''
			}
			if (this.state.modal_mode === "create")
			{
				const accommodation = {
					name: this.state.form1.name
				}
				this.props.insertAccommodation(accommodation)
				.then(ret => {
					if (ret === false)
					{
						this.setState({ form1: form1, attempt_submit: false })
						this.setErrorMsg('error_create_accommodation', 1)
					}
					else
					{
						this.setState({ form1: form1, attempt_submit: false })
						this.setSuccessMsg("Nouvel hébergement créé !")
					}
				})
				.catch(err => {
					this.setState({ form1: form1, attempt_submit: false })
					this.setErrorMsg('error_create_accommodation', 2)
					console.log(err)
				})
			}
			else if (this.state.modal_mode === "duplicate")
			{
				const accommodation = {
					name: this.state.form1.name,
					duplicate: this.state.selected_accommodation
				}
				let from = null
				for (let i = 0; i < this.state.accommodations.length; i++)
				{
					if (this.state.accommodations[i]._id === this.state.selected_accommodation)
					{
						from = this.state.accommodations[i].name
						break
					}
				}
				this.props.insertAccommodationDuplicate(accommodation)
				.then(ret => {
					if (ret === false)
					{
						this.setState({ form1: form1, attempt_submit: false })
						this.setErrorMsg('error_duplicate_accommodation', 1)
					}
					else
					{
						this.setState({ form1: form1, attempt_submit: false })
						this.setSuccessMsg("Nouvel hébergement copié depuis \"" + from + "\" !")
					}
				})
				.catch(err => {
					this.setState({ form1: form1, attempt_submit: false })
					this.setErrorMsg('error_duplicate_accommodation', 2)
					console.log(err)
				})
			}
			else if (this.state.modal_mode === "rename")
			{
				const accommodation = {
					_id: this.state.selected_accommodation,
					name: this.state.form1.name
				}
				this.props.updateAccommodation(accommodation)
				.then(ret => {
					if (ret === false)
					{
						this.setState({ form1: form1, attempt_submit: false })
						this.setErrorMsg('error_update_accommodation', 1)
					}
					else
					{
						this.setState({ form1: form1, attempt_submit: false })
						this.setSuccessMsg("L'hébergement a bien été mis à jour !")
					}
				})
				.catch(err => {
					this.setState({ form1: form1, attempt_submit: false })
					this.setErrorMsg('error_update_accommodation', 2)
					console.log(err)
				})
			}
			else
				console.log("incorrect modal_mode", this.state.modal_mode)
		}
	}

	openNewInventory = () => {
		const form1 = {
			name: '',
			isOpen: true
		}
		this.setState({ form1: form1, modal_mode: "create", success: null, error: this._initErrors() })
	}

	closeModal = () => {
		const form1 = {
			isOpen: false,
			name: ''
		}
		this.setState({ form1 : form1 })
	}

	answerModalDelete = (answer) => {
		if (answer === true)
			this._deleteAccommodation()
		else
			this.setState({ selected_accommodation: null, open_modal: false })
	}

	deleteAccommodation = (id) => {
		this.setState({ selected_accommodation: id, open_modal: true })
	}

	_deleteAccommodation = () => {
		this.setState({ attempt_submit: true })
		this.props.deleteAccommodation(this.state.selected_accommodation)
		.then(ret => {
			if (ret === true)
			{
				this.setState({ open_modal: false, attempt_submit: false })
				this.setSuccessMsg("Hébergement supprimé !")
			}
			else
			{
				this.setState({ open_modal: false, attempt_submit: false })
				this.setErrorMsg('error_delete_accommodation', 1)
			}
		})
		.catch(err => {
			this.setState({ open_modal: false, attempt_submit: false })
			this.setErrorMsg('error_delete_accommodation', 2)
			console.log(err)
		})
	}

	duplicateAccommodation = (id) => {
		const form1 = {
			name: '',
			isOpen: true
		}
		this.setState({ form1: form1, selected_accommodation: id, modal_mode: "duplicate", success: null, error: this._initErrors() })
	}

	editNameAccommodation = (id) => {
		let name = null
		for (let i = 0; i < this.state.accommodations.length; i++)
		{
			if (this.state.accommodations[i]._id === id)
			{
				name = this.state.accommodations[i].name
				break
			}
		}
		const form1 = {
			name: name,
			isOpen: true
		}
		this.setState({ form1: form1, selected_accommodation: id, modal_mode: "rename", success: null, error: this._initErrors() })
	}

	classNames = (...classes) => {
		return classes.filter(Boolean).join(' ')
	}

	handleConfigArrival = () => {
		const form2 = {
			...this.state.form2,
			arrival: (this.state.form2.arrival === true) ? false : true
		}
		this.setState({ form2: form2 })
	}

	handleConfigDeparture = () => {
		const form2 = {
			...this.state.form2,
			departure: (this.state.form2.departure === true) ? false : true
		}
		this.setState({ form2: form2 })
	}

	configAccessEditInventory = (id) => {
		const form3 = { ...this.state.form3 }
		if (id === 'checkin_user')
			form3.arrival_access.user = (form3.arrival_access.user === true) ? false : true
		if (id === 'checkin_staff')
			form3.arrival_access.staff = (form3.arrival_access.staff === true) ? false : true
		if (id === 'checkout_user')
			form3.departure_access.user = (form3.departure_access.user === true) ? false : true
		if (id === 'checkout_staff')
			form3.departure_access.staff = (form3.departure_access.staff === true) ? false : true
		this.setState({ form3: form3 })
	}

	handleConfigDelay = (event) => {
		const form2 = {
			...this.state.form2,
			delay: event.target.value
		}
		this.setState({ form2: form2 })
	}

	handleConfigComment = (event) => {
		let found = false
		const translations = this.state.form2.translations
		for (let i = 0; i < translations.length; i++)
		{
			if (translations[i].lang === this.state.current_lang)
			{
				found = true
				translations[i].comment = event.target.value
				break
			}
		}
		if (!found)
		{
			translations.push({
				lang: this.state.current_lang,
				comment: event.target.value
			})
		}
		const form2 = {
			...this.state.form2,
			translations: translations
		}
		this.setState({ form2: form2 })
	}

	_validateForm2 = () => {
		let error_obj = this._initErrors()
		let error = false
		const translations = this.state.form2.translations
		for (let i = 0; translations && i < translations.length; i++)
		{
			const lang = translations[i].lang
			const comment = translations[i].comment
			if (comment.length > this.intro_length_max)
			{
				error = true
				error_obj.comment = "Le texte ne doit pas dépasser " + this.intro_length_max + " caractères (version " + lang.toUpperCase() + ")"
			}
		}
		const delay = Number(this.state.form2.delay)
		if (delay < 1)
		{
			error_obj.delay = "Indiquez un délai supérieur à zéro"
			error = true
		}
		if (error === true)
		{
			error_obj.config = "Il y a une ou plusieurs erreur(s) dans le formulaire"
			this.setState({ success: null, error: error_obj, attempt_submit: false })
		}
		return (!error) ? true : false
	}

	submitConfig = (event) => {
		event.preventDefault()
		this.setState({ attempt_submit: 2 })
		if (this._validateForm2())
		{
			const config = {
				arrival: this.state.form2.arrival,
				departure: this.state.form2.departure,
				arrival_staff: this.state.form3.arrival_access.staff,
				arrival_user: this.state.form3.arrival_access.user,
				departure_staff: this.state.form3.departure_access.staff,
				departure_user: this.state.form3.departure_access.user,
				delay: Number(this.state.form2.delay),
				translations: (this.state.form2.translations) ? this.state.form2.translations : []
			}
			this.props.updateConfigInventory(config)
			.then(ret => {
				if (ret === false)
				{
					this.setState({ attempt_submit: false })
					this.setErrorMsg("error_update_config", 1)
				}
				else
				{
					this.setState({ attempt_submit: false })
					this.setSuccessMsg("Les paramètres ont bien été mis à jour")
				}
			})
			.catch(err => {
				console.log(err)
				this.setState({ attempt_submit: false })
				this.setErrorMsg("error_update_config", 2)
			})
		}
	}

	updateLang = (lang) => {
		this.setState({ current_lang: lang })
	}

	render() {
		const permissions = this.props.user.permissions
		if (!permissions || !permissions.inventories || permissions.inventories === 0)
			return (<Redirect to="/" />)
		else if (this.props.features !== "premium")
		{
			return (
				<>
					<OnlyPremiumEmpty 
						title="Mes inventaires par hébergement"
						subtitle="Commencez par créer un hébergement"
						emptyFolderSubtitle="Pas d'inventaires"
						navName="Inventaires"
						navPath="/inventaires"
					/>
					<div className="mt-10">
						<PremiumAlert
							content="Mettez votre abonnement à niveau avec la formule premium pour accéder aux inventaires !"
							button_text="En savoir plus !"
						/>
					</div>
				</>
			)
		}
		else if (this.state.config_inventory === null || this.state.accommodations === null)
		{
			return (
				<div className="loader">
					<Loader />
				</div>
			)
		}
		else
		{
			const navStyle = "flex bg-transparent shadow-none mb-10"
			let error_nameJSX = null
			if (this.state.error.name)
			{
				error_nameJSX = (
					<div>
						<div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
							<ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
						</div>
						<p className="mt-2 text-sm text-red-600" id="email-error">
							{this.state.error.name}
						</p>
					</div>
				)
			}
			let error_delayJSX = null
			if (this.state.error.delay)
			{
				error_delayJSX = (
					<p className="mt-2 text-sm text-red-600" id="delay-error">
						{this.state.error.delay}
					</p>
				)
			}
			let error_commentJSX = null
			if (this.state.error.comment)
			{
				error_commentJSX = (
					<p className="mt-2 text-sm text-red-600" id="comment-error">
						{this.state.error.comment}
					</p>
				)
			}
			let error_configJSX = null
			if (this.state.error.config)
			{
				error_configJSX = (
					<p className="mt-2 text-sm text-red-600" id="config-error">
						{this.state.error.config}
					</p>
				)
			}
			let content_tableJSX = (
				<EmptyFolder
					emptyFolderSubtitle="Pas d'hébergement paramétré" 
					content="Commencez par créer un hébergement" 
				/>
			)
			if (this.state.accommodations.length > 0)
			{
				content_tableJSX = (
					<div className="rounded-lg bg-white overflow shadow">
						<BannerInfo
							content={this.state.banner_content}
							show={this.state.show_banner}
							closeBanner={this.closeBanner}
							error={this.state.error.global}
							success={this.state.success}
						/>
						<table className="min-w-full divide-y divide-gray-200">
							<thead className="bg-gray-50">
								<tr>
									<th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Type d'hébergement</th>
									<th scope="col" className="relative px-3 py-3">
										<span className="sr-only">Modifier</span>
									</th>
									<th scope="col" className="relative px-3 py-3">
										<span className="sr-only">Supprimer</span>
									</th>
								</tr>
							</thead>
							{this.state.accommodations && this.state.accommodations.map((accommodation, accommodation_k) => {
								const url = "/inventaires/edit/" + accommodation._id
								return (
									<tbody key={accommodation_k} className="bg-white divide-y divide-gray-200">
										<tr>
											<td className="px-6 py-4 whitespace-nowrap text-base font-medium text-gray-900">{accommodation.name}</td>
											<td className="px-6 py-4 whitespace-nowrap text-right text-lg font-medium">
												<Link to={url} className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-base font-medium rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
													Inventaire
													<PencilIcon className="ml-2 -mr-0.5 h-4 w-4" aria-hidden="true" />
												</Link>
											</td>
											<td className="px-6 py-4 whitespace-nowrap text-right text-base font-medium">
												<Menu as="div" className="relative inline-block text-left">
													<div>
														<Menu.Button className="bg-gray-100 rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
														<span className="sr-only">Open options</span>
														<DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
														</Menu.Button>
													</div>
													<Transition
														as={Fragment}
														enter="transition ease-out duration-100"
														enterFrom="transform opacity-0 scale-95"
														enterTo="transform opacity-100 scale-100"
														leave="transition ease-in duration-75"
														leaveFrom="transform opacity-100 scale-100"
														leaveTo="transform opacity-0 scale-95"
													>
														<Menu.Items className="z-10 mx-3 origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
															<div className="py-1 ">
																<Menu.Item>
																	{({ active }) => (
																		<button
																			type='button'
																			onClick={this.editNameAccommodation.bind(this, accommodation._id)}
																			className={this.classNames(
																				active ? 'bg-gray-100 text-gray-900 w-full text-left' : 'w-full text-left text-gray-700',
																				'block px-4 py-2 text-sm w-full text-left'
																			)}
																			>
																			Renommer
																		</button>
																	)}
																</Menu.Item>
																<Menu.Item>
																	{({ active }) => (
																		<button
																			type='button'
																			onClick={this.duplicateAccommodation.bind(this, accommodation._id)}
																			className={this.classNames(
																				active ? 'bg-gray-100 text-gray-900 w-full text-left' : 'w-full text-left text-gray-700',
																				'block px-4 py-2 text-sm w-full text-left'
																			)}
																			>
																			Dupliquer
																		</button>
																	)}
																</Menu.Item>
																<Menu.Item>
																	{({ active }) => (
																		<button
																			type='button'
																			onClick={this.deleteAccommodation.bind(this, accommodation._id)}
																			className={this.classNames(
																				active ? 'bg-gray-100 text-gray-900 w-full text-left' : 'text-gray-700 w-full text-left',
																				'block w-full text-left px-4 py-2 text-sm'
																			)}
																			>
																			Supprimer
																		</button>
																	)}
																</Menu.Item>
															</div>
														</Menu.Items>
													</Transition>
												</Menu>
											</td>
										</tr>
									</tbody>
								)})}
						</table>
					</div>
				)
			}
			let submitbtn_accommodation = (
				<button type="submit" className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500">
					Confirmer
				</button>
			)
			if (this.state.attempt_submit === 1)
			{
				submitbtn_accommodation = (
					<button type="submit" disabled className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500">
						<svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
							<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
							<path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
						</svg>
						Confirmer
					</button>
				)
			}
			let submitbtn_config = (
				<button type="submit" className="mt-5 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
					Enregistrer
				</button>
			)
			if (this.state.attempt_submit === 2)
			{
				submitbtn_config = (
					<button type="submit" className="mt-5 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
						<svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
							<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
							<path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
						</svg>
						Enregistrer
					</button>
				)
			}
			let checkin_inventory_access = ""
			let checkout_inventory_access = ""
			if (this.state.form2.arrival === true)
			{
				checkin_inventory_access = (
					<>
					<p className="text-gray-500 text-sm">Sélectionnez qui pourra remplir l'inventaire d'entrée.</p>
					<div className="flex space-x-3">
						<div className="mt-2 flex items-center items-start">
							<div className="flex items-center h-5 mr-3">
								<input
									id="checkin_access_user"
									onChange={this.configAccessEditInventory.bind(this, 'checkin_user')}
									checked={this.state.form3.arrival_access.user}
									type="checkbox"
									className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
									style={{ opacity: '1', pointerEvents: 'auto' }}
								/>
							</div>
							<div className="ml-3 text-base">
								<label htmlFor="checkin_access_user" className="font-normal text-base text-gray-700 m-0">
									Utilisateur
								</label>
							</div>
						</div>
						<div className="mt-2 flex items-center items-start">
							<div className="flex items-center h-5 mr-3">
								<input
									id="checkin_access_staff"
									onChange={this.configAccessEditInventory.bind(this, 'checkin_staff')}
									checked={this.state.form3.arrival_access.staff}
									type="checkbox"
									className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
									style={{ opacity: '1', pointerEvents: 'auto' }}
								/>
							</div>
							<div className="ml-3 text-base">
								<label htmlFor="checkin_access_staff" className="font-normal text-base text-gray-700 m-0">
									Établissement
								</label>
							</div>
						</div>
					</div>
					</>
				)
			}
			if (this.state.form2.departure === true)
			{
				checkout_inventory_access = (
					<>
						<p className="text-gray-500 text-sm">Sélectionnez qui pourra remplir l'inventaire de sortie.</p>
						<div className="flex space-x-3">
							<div className="mt-2 flex items-center items-start">
								<div className="flex items-center h-5 mr-3">
									<input
										id="departure"
										onChange={this.configAccessEditInventory.bind(this, 'checkout_user')}
										checked={this.state.form3.departure_access.user}
										type="checkbox"
										className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
										style={{ opacity: '1', pointerEvents: 'auto' }}
									/>
								</div>
								<div className="ml-3 text-base">
									<label htmlFor="departure" className="font-normal text-base text-gray-700 m-0">
										Utilisateur
									</label>
								</div>
							</div>
							<div className="mt-2 flex items-center items-start">
								<div className="flex items-center h-5 mr-3">
									<input
										id="departure"
										onChange={this.configAccessEditInventory.bind(this, 'checkout_staff')}
										checked={this.state.form3.departure_access.staff}
										type="checkbox"
										className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
										style={{ opacity: '1', pointerEvents: 'auto' }}
									/>
								</div>
								<div className="ml-3 text-base">
									<label htmlFor="departure" className="font-normal text-base text-gray-700 m-0">
										Établissement
									</label>
								</div>
							</div>
						</div>
					</>
				)
			}
			let comment = ''
			if (this.state.form2.translations)
			{
				for (let i = 0; i < this.state.form2.translations.length; i++)
				{
					if (this.state.form2.translations[i].lang === this.state.current_lang)
					{
						comment = this.state.form2.translations[i].comment
						break
					}
				}
			}
			return (
				<div className="wrapper-content">
					
					<ModalDelete
						title="Supprimer l'hébergement"
						content="Vous allez supprimer l'hébergement et l'inventaire qui y est associé"
						attempt_submit={this.state.attempt_submit}
						open_modal={this.state.open_modal}
						answerModal={this.answerModalDelete}
					/>
					{/* START - MODAL */}
					<Transition appear show={this.state.form1.isOpen} as={Fragment}>
						<Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={this.closeModal}>
							<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
								<Transition.Child
									as={Fragment}
									enter="ease-out duration-300"
									enterFrom="opacity-0"
									enterTo="opacity-100"
									leave="ease-in duration-200"
									leaveFrom="opacity-100"
									leaveTo="opacity-0"
								>
									<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
								</Transition.Child>
								<span
									className="hidden sm:inline-block sm:align-middle sm:h-screen"
									aria-hidden="true"
								>
									&#8203;
								</span>
								<Transition.Child
									as={Fragment}
									enter="ease-out duration-300"
									enterFrom="opacity-0 scale-95"
									enterTo="opacity-100 scale-100"
									leave="ease-in duration-200"
									leaveFrom="opacity-100 scale-100"
									leaveTo="opacity-0 scale-95"
								>
									<form onSubmit={this.submitAccommodation} className="inline-block align-bottom bg-white rounded-lg px-4 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
										<label htmlFor="email" className="block text-sm font-medium text-gray-700">
											Nom de l'hébergement
										</label>
										<div className="mt-1">
											<input
												type="text"
												name="text"
												id="accomodation"
												className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
												placeholder="ex : chalet cottage"
												value={this.state.form1.name}
												onChange={this.handleName}
											/>
											{error_nameJSX}
										</div>
										<div className="mt-4">
											{submitbtn_accommodation}
										</div>
									</form>
								</Transition.Child>
							</div>
						</Dialog>				
					</Transition>
					{/* END - MODAL */}
					{/* START - OUTILS DE NAVIGATION */}
					<nav className={navStyle} aria-label="Breadcrumb">
						<ol className="bg-white rounded-md shadow px-6 flex space-x-4">
							<li className="flex">
							<div className="flex items-center">
								<Link to="/" className="text-gray-400 hover:text-gray-500">
									<HomeIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
									<span className="sr-only">Home</span>
								</Link>
							</div>
							</li>
							{this.state.pages.map((page) => (
								<li key={page.name} className="flex">
									<div className="flex items-center">
										<svg
											className="flex-shrink-0 w-6 h-full text-gray-200"
											viewBox="0 0 24 44"
											preserveAspectRatio="none"
											fill="currentColor"
											xmlns="http://www.w3.org/2000/svg"
											aria-hidden="true"
										>
											<path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
										</svg>
										<Link
											to={page.href}
											className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
											aria-current={page.current ? 'page' : undefined}
										>
											{page.name}
										</Link>
									</div>
								</li>
							))}
						</ol>
					</nav>
					{/* END - OUTILS DE NAVIGATION */}
					<div className="grid grid-cols-1 gap-4 items-start lg:grid-cols-3 lg:gap-8">
						<div className="grid grid-cols-1 gap-4 lg:col-span-2">
							<div className="mb-5">
								<h2 className="text-xl leading-6 font-medium text-gray-900 mb-0">Mes inventaires par hébergement</h2>
								<p className="mt-1 text-base text-gray-500">
									Commencez par créer un hébergement.
								</p>
							</div>
							<div className="grid-cols-1">
								<button onClick={this.openNewInventory} type="button" className="mb-10 inline-flex items-center px-6 py-3 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
									Créer un hébergement			
									<svg xmlns="http://www.w3.org/2000/svg" className="ml-3 -mr-1 h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
										<path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clipRule="evenodd" />
									</svg>
								</button>
							</div>
							<section aria-labelledby="section-1-title">
								{content_tableJSX}
							</section>
						</div>
						<div className="grid grid-cols-1 gap-4">
							<section aria-labelledby="section-2-title">
								<div className="rounded-lg bg-white overflow-hidden shadow">
									<div className="p-6">
										<h3 className="text-lg leading-6 font-medium text-gray-900 mb-2">Paramètres généraux</h3>
										<p className="text-gray-500 mb-3">Cochez les choix ci-dessous pour mettre en ligne les inventaires.</p>
										<form onSubmit={this.submitConfig}>
											<fieldset>
												<div className="space-y-5">
													<div className="flex items-start mt-2">
														<div className="flex items-center h-5 mr-3">
															<input
																id="arrival"
																onChange={this.handleConfigArrival}
																checked={this.state.form2.arrival}
																type="checkbox"
																className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
																style={{ opacity: '1', pointerEvents: 'auto' }}
															/>
														</div>
														<div className="ml-3 text-base">
															<label htmlFor="arrival" className="font-medium text-base text-gray-700 m-0">
																Inventaire d'entrée
															</label>
															{checkin_inventory_access}
														</div>
													</div>
													<div className="flex items-start mt-4">
														<div className="flex items-center h-5 mr-3">
															<input
																id="departure"
																onChange={this.handleConfigDeparture}
																checked={this.state.form2.departure}
																type="checkbox"
																className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
																style={{ opacity: '1', pointerEvents: 'auto' }}
															/>
														</div>
														<div className="ml-3 text-base">
															<label htmlFor="departure" className="font-medium text-base text-gray-700 m-0">
																Inventaire de sortie
															</label>
															{checkout_inventory_access}
														</div>
													</div>
													<div className="sm:col-span-3">
														<label htmlFor="validity" className="block text-base font-medium text-gray-700 m-0">
															Durée de validité (en heure)
														</label>
														<div className="mt-1">
															<input
																type="number"
																onChange={this.handleConfigDelay}
																value={this.state.form2.delay}
																min="1"
																id="validity"
																className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
																placeholder="48"
															/>
															{error_delayJSX}
														</div>
														<p className="mt-2 text-sm text-gray-500">
															Période au delà de laquelle l'utilisateur ne pourra plus modifier le formulaire.
														</p>
													</div>
													<LangSelector lang={this.state.current_lang} updateLang={this.updateLang} />
													<div className="flex flex-col items-start">
														<label htmlFor="about" className="block text-base font-medium text-gray-700 m-0">
															Informations complémentaires
														</label>
														<div className="mt-1 w-full">
															<textarea
																id="about"
																onChange={this.handleConfigComment}
																value={comment}
																rows={5}
																className="h-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
																placeholder="En cas d'éléments manquant, veuillez le signaler à la réception."
															/>
															{error_commentJSX}
														</div>
														<p className="mt-2 text-sm text-gray-500">
															Texte d'introduction affiché avant le remplissage de l'inventaire.
														</p>
													</div>
												</div>
												{error_configJSX}
												{submitbtn_config}
											</fieldset>
										</form>
									</div>
								</div>
							</section>
						</div>
					</div>
				</div>
			)
		}
	}
}

export default Inventory

import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import connexion from '../../connexion'
import tools from '../../tools'
import Loader from '../UI/Loader'
import M from "materialize-css"
import '../Core/Main.css'

class SendEmail extends Component {
	constructor(props) {
		super(props)
		this.state = {
			clients: null,
			users: null,
			emails: null,
			template: null,
			client_list: null,
			user_list: null,
			total_list: null,
			client_info_list: null,
			user_info_list: null,
			total_info_list: null,
			form1: {
				id: ''
			},
			form2: {
				clients: true,
				users: false
			},
			form3: {
				day: '',
				month: '',
				hour: ''
			},
			error: null,
			success: null
		}
		console.log(this.props.location.clients_numbers)
		this.props.updateNavbarTitle('Envoyer des e-mails')
	}

	componentDidMount() {
		this.fetchClients()
		this.fetchEmails()
	}
	
	componentDidUpdate () {
		const elems = document.querySelectorAll('select');
		M.FormSelect.init(elems);
	}

	setSuccessMsg = (msg) => {
		this.setState({ error: null, success: msg })
	}

	setErrorMsg = (msg) => {
		this.setState({ error: msg, success: null })
	}
	
	fetchClients = () => {
		if (this.props.location.clients_numbers !== undefined)
		{
			fetch(connexion.connect.url + 'dashboard/clients/find-many', {
				method: 'POST',
				body: JSON.stringify({
					clients: this.props.location.clients_numbers
				})
			})
			.then(res => res.json())
			.then(clients => {
				fetch(connexion.connect.url + 'webapp/users/find-many-by-clients', {
					method: 'POST',
					body: JSON.stringify({
						clients: this.props.location.clients_numbers
					})
				})
				.then(res => res.json())
				.then(users => {
					let client_list = []
					let client_info_list = []
					for (let i = 0; i < clients.length; i++)
					{
						if (clients[i].email1 && client_list.indexOf(clients[i].email1) === -1)
						{
							client_list.push(clients[i].email1)
							client_info_list.push({
								client_number: clients[i].client_number,
								email: clients[i].email1,
								firstname: clients[i].firstname,
								lastname: clients[i].lastname,
								code: clients[i].activation_code,
								lang: clients[i].langage,
								user: false
							})
						}
						if (clients[i].email2 && client_list.indexOf(clients[i].email2) === -1)
						{
							client_list.push(clients[i].email2)
							client_info_list.push({
								client_number: clients[i].client_number,
								email: clients[i].email2,
								firstname: clients[i].firstname,
								lastname: clients[i].lastname,
								code: clients[i].activation_code,
								lang: clients[i].langage,
								user: false
							})
						}
					}
					let total_list = tools.clone(client_list)
					let total_info_list = tools.clone(client_info_list)
					let user_list = []
					let user_info_list = []
					for (let i = 0; i < users.length; i++)
					{
						if (users[i].activate !== true)
							continue
						let client = null
						for (let j = 0; j < clients.length; j++)
						{
							if (clients[j].client_number === users[i].client_number)
							{
								client = clients[j]
								break
							}
						}
						if (users[i].email && client_list.indexOf(users[i].email) === -1 && user_list.indexOf(users[i].email) === -1)
						{
							total_list.push(users[i].email)
							total_info_list.push({
								client_number: users[i].client_number,
								email: users[i].email,
								firstname: users[i].firstname,
								lastname: users[i].lastname,
								code: client.activation_code,
								lang: users[i].langage,
								user: users[i]._id
							})
						}
						if (users[i].email && user_list.indexOf(users[i].email) === -1)
						{
							user_list.push(users[i].email)
							user_info_list.push({
								client_number: users[i].client_number,
								email: users[i].email,
								firstname: users[i].firstname,
								lastname: users[i].lastname,
								code: client.activation_code,
								lang: users[i].langage,
								user: users[i]._id
							})
						}
					}
					this.setState({
						client_list: client_list,
						user_list: user_list,
						total_list: total_list,
						client_info_list: client_info_list,
						user_info_list: user_info_list,
						total_info_list: total_info_list,
						clients: clients,
						users: users
					})
				})
				.catch(err => console.log(err))
			})
			.catch(err => console.log(err))
		}
	}
	
	fetchEmails = () => {
		this.props.fetchEmails()
		.then(ret => {
			let emails = []
			for (let i = 0; i < ret.length; i++)
			{
				if (ret[i].default !== false)
					continue
				emails.push(ret[i])
			}
			this.setState({ emails: emails })
		})
		.catch(err => {
			console.log(err)
		})
	}

	handleTemplate = (event) => {
		const form1 = {
			id: event.target.value
		}
		this.setState({ form1: form1 })
	}

	selectTemplate = (event) => {
		event.preventDefault()
		let template = null
		if (this.state.form1.id.length > 0)
		{
			for (let i = 0; i < this.state.emails.length; i++)
			{
				if (this.state.emails[i]._id === this.state.form1.id)
				{
					template = this.state.emails[i]
					break
				}
			}
			console.log('template', template)
			this.setState({ template: template })
		}
	}

	handleClients = () => {
		const form2 = {
			...this.state.form2,
			clients: (this.state.form2.clients === false) ? true : false
		}
		if (form2.clients === false)
			form2.users = true
		this.setState({ form2: form2 })
	}

	handleUsers = () => {
		const form2 = {
			...this.state.form2,
			users: (this.state.form2.users === false) ? true : false
		}
		if (form2.users === false)
			form2.clients = true
		this.setState({ form2: form2 })
	}

	handleDay = (event) => {
		const form3 = {
			...this.state.form3,
			day: event.target.value
		}
		this.setState({ form3: form3 })
	}

	handleMonth = (event) => {
		const form3 = {
			...this.state.form3,
			month: event.target.value
		}
		this.setState({ form3: form3 })
	}

	handleHour = (event) => {
		const form3 = {
			...this.state.form3,
			hour: event.target.value
		}
		this.setState({ form3: form3 })
	}

	submitEmail = (event) => {
		event.preventDefault()
		const day = this.state.form3.day
		const month = this.state.form3.month
		const hour = this.state.form3.hour
		if (day.length > 0 && month.length > 0 && hour.length > 0)
		{
			const year = new Date().getFullYear()
			const time = Math.round(new Date(year, month, day, hour).getTime() / 1000)
			const now = Math.round(new Date().getTime() / 1000)
			if (time < now)
				this.setErrorMsg("La date d'envoi doit être dans le futur")
			else
			{
				let email_list = []
				if (this.state.form2.clients === true && this.state.form2.users === true)
					email_list = this.state.total_info_list
				else if (this.state.form2.clients === true)
					email_list = this.state.client_info_list
				else if (this.state.form2.users === true)
					email_list = this.state.user_info_list
				fetch(connexion.connect.url + 'dashboard/cron_mail/insert', {
					method: 'POST',
					body: JSON.stringify({
						timestamp: time,
						user: this.props.user._id,
						translations: this.state.template.translations,
						email_list: email_list
					})
				})
				.then(ret => {
					if (ret !== false)
						this.setSuccessMsg("Le mail à bien été paramétré")
					else
						this.setErrorMsg("Une erreur s'est produite")
				})
				.catch(err => console.log(err))
			}
		}
	}

	render() {
		const clients = this.state.clients
		const emails = this.state.emails
		const permissions = this.props.user.permissions
		if (!permissions || !permissions.emails || permissions.emails === 0)
			return (<Redirect to="/" />)
		else if (this.props.location.clients_numbers === undefined)
		{
			return (<Redirect to="/utilisateurs" />)
		}
		else if (emails === null || clients === null)
		{
			return (
				<div className="loader">
					<Loader />
				</div>
			)
		}
		else
		{
			let templateJSX = null
			if (this.state.template !== null)
			{
				let days = []
				for (let i = 1; i <= 31; i++)
				{
					let text = i
					if (i < 10)
						text = '0' + i
					days.push({
						value: i,
						text: text
					})
				}
				let months = []
				for (let i = 1; i <= 12; i++)
				{
					let text = i
					if (i < 10)
						text = '0' + i
					months.push({
						value: i - 1,
						text: text
					})
				}
				let hours = []
				for (let i = 0; i <= 23; i++)
				{
					let text = i
					if (i < 10)
						text = '0' + i
					text += 'h'
					hours.push({
						value: i,
						text: text
					})
				}
				let title = ""
				let content = ""
				for (let i = 0; i < this.state.template.translations.length; i++)
				{
					if (this.state.template.translations[i].lang === this.props.lang_default)
					{
						title = this.state.template.translations[i].title
						content = this.state.template.translations[i].content
						break
					}
				}
				templateJSX = (
					<div>
						<div className="row">
							<div className="module col s12">
								<h2 className="title-description">Paramétrer un envoi d'e-mail</h2>
								<p className="success">{this.state.success}</p>
								<p className="error">{this.state.error}</p>
								<form className="module-form" onSubmit={this.submitEmail}>
									<p className="title-group">Choisir une date</p>
									<div className="form-group">
										<label>
											<p className="column-10">Date :</p>
											<div className="column-10">
												<select value={this.state.form1.day} onChange={this.handleDay}>
													<option value="">Jour</option>
													{days.map((day) => {
														return (<option value={day.value} key={day.value}>{day.text}</option>)
													})}
												</select>
											</div>
											<div className="sep"><p>/</p></div>
											<div className="column-10 right60">
												<select value={this.state.form1.start_month} onChange={this.handleMonth}>
													<option value="">Mois</option>
													{months.map((month) => {
														return (<option value={month.value} key={month.value}>{month.text}</option>)
													})}
												</select>
											</div>
											<p className="column-10">à</p>
											<div className="column-10">
												<select value={this.state.form1.start_day} onChange={this.handleHour}>
													<option value="">Heure</option>
													{hours.map((hour) => {
														return (<option value={hour.value} key={hour.value}>{hour.text}</option>)
													})}
												</select>
											</div>
										</label>
										<div className="clear"></div>
									</div>
									<button type="submit" className="waves-effect waves-light btn-small">Enregistrer</button>
								</form>
							</div>
						</div>
						<div className="row">
							<div className="module col s12">
								<h2 className="title-description">Pré-aperçu de l'e-mail</h2>
								<p><b>Sujet :</b> {title}</p>
								<div dangerouslySetInnerHTML={{ __html: content }}></div>
							</div>
						</div>
					</div>
				)
			}
			let total = 0
			if (this.state.form2.clients === true && this.state.form2.users === true)
				total = this.state.total_list.length
			else if (this.state.form2.clients === true)
				total = this.state.client_list.length
			else if (this.state.form2.users === true)
				total = this.state.user_list.length
			return (
				<div className="wrapper-content">
					<div className="row">
						<div className="module col s6">
							<h2 className="title-description">Extraction d'e-mails</h2>
							<p>
								<b>Nombre de fiches clients sélectionnées :</b> {this.state.clients.length}<br />
								<b>Nombre d'utilisateurs sélectionnés :</b> {this.state.users.length}<br />
								<b>Nombre d'e-mails des fiches clients :</b> {this.state.client_list.length}<br />
								<b>Nombre d'e-mails des utilisateurs (activés) :</b> {this.state.user_list.length}<br />
								<b>Nombre d'e-mails total (sans doublons) :</b> {this.state.total_list.length}
							</p>
							<form className="module-form">
								<label>
									<p className="column-40">Inclure les mails des fiches clients :</p>
									<div className="column-20">
										<input type="checkbox" checked={this.state.form2.clients} onChange={this.handleClients} /><span></span>
									</div>
								</label>
								<label>
									<p className="column-40">Inclure les mails des utilisateurs :</p>
									<div className="column-20">
										<input type="checkbox" checked={this.state.form2.users} onChange={this.handleUsers} /><span></span>
									</div>
								</label>
							</form>
							<div className="clear"></div>
							<p>
								<b>Nombre total de mail à envoyer :</b> {total}
							</p>
						</div>
						<div className="module col s6">
							<h2 className="title-description">Sélectionner un template</h2>
							<form className="module-form" onSubmit={this.selectTemplate}>
								<label>
									<p className="">Template d'e-mail :</p>
									<select value={this.state.form1.id} onChange={this.handleTemplate}>
										<option value="">Sélectionner un template</option>
										{emails.map(({ _id, name }) => {
											return (<option value={_id} key={_id}>{name}</option>)
										})}
									</select>
									<button type="submit" className="waves-effect waves-light btn-small">Sélectionner</button>
								</label>
							</form>
						</div>
					</div>
					{templateJSX}
				</div>
			)
		}
	}
}

export default SendEmail

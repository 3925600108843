import React, { Component } from 'react'
import Loader from '../UI/Loader'
import tools from '../../tools'

class ImportAmenitiz extends Component {
	constructor(props) {
		super(props)
		this.state = {
			attempt_submit: false,
			form1: {
				clients: null,
				reservations: null
			},
			success: null,
			error: this._initErrors()
		}
	}

	_initErrors = () => {
		const errors = {
			global: null,
			clients: null,
			reservations: null
		}
		return errors
	}

	setErrorMsg = (key, msg, id = null) => {
		if (key === "error_import_file")
		{
			key = "global"
			msg = tools.default_error_msg("Impossible d'importer un fichier csv", msg)
		}
		let error = this._initErrors()
		if (id !== null)
			error[key][id] = msg
		else
			error[key] = msg
		this.setState({ success: null, error: error })
	}

	setSuccessMsg = (msg) => {
		this.setState({ error: this._initErrors(), success: msg })
	}

	clearFlash = () => {
		this.setState({ error: this._initErrors(), success: null })
	}

	handleClients = (event) => {
		const reader = new FileReader()
		const file = event.target.files[0]
		reader.addEventListener("load", () => {
			const form1 = {
				...this.state.form1,
				clients: reader.result
			}
			this.setState({ form1: form1 })
		}, false)
		if (file)
			reader.readAsText(file)
	}

	handleReservations = (event) => {
		const reader = new FileReader()
		const file = event.target.files[0]
		reader.addEventListener("load", () => {
			const form1 = {
				...this.state.form1,
				reservations: reader.result
			}
			this.setState({ form1: form1 })
		}, false)
		if (file)
			reader.readAsText(file)
	}

	_validateForm1 = () => {
		let error_obj = this._initErrors()
		let error = false
		const clients = this.state.form1.clients
		const reservations = this.state.form1.reservations
		let clients_csv = null
		if (clients)
			clients_csv = tools.parseCSV(this.state.form1.clients)
		let reservations_csv = null
		if (reservations)
			reservations_csv = tools.parseCSV(this.state.form1.reservations)
		if (clients && !isNaN(clients_csv))
		{
			error_obj.clients = "Erreur de parsing dans le fichier CSV à la ligne " + clients_csv
			error = true
		}
		if (reservations && !isNaN(reservations_csv))
		{
			error_obj.reservations = "Erreur de parsing dans le fichier CSV à la ligne " + reservations_csv
			error = true
		}
		if (!clients && !reservations)
		{
			error = true
			error_obj.global = "Veuillez uploader au moins un fichier"
			this.setState({ success: null, error: error_obj })
		}
		else if (error === true)
		{
			error_obj.global = "Il y a une ou plusieurs erreur(s) dans ce formulaire"
			this.setState({ success: null, error: error_obj })
		}
		return (!error) ? true : false
	}

	submitImport = (event) => {
		event.preventDefault()
		if (this._validateForm1())
		{
			let clients_csv = null
			if (this.state.form1.clients)
				clients_csv = tools.parseCSV(this.state.form1.clients)
			let reservations_csv = null
			if (this.state.form1.reservations)
				reservations_csv = tools.parseCSV(this.state.form1.reservations)
			const roomlist = {
				clients: clients_csv,
				reservations: reservations_csv
			}
			this.props.insertRoomlist(roomlist)
			.then(ret => {
				if (ret === true)
					this.setSuccessMsg("Les réservations ont bien été importées !")
				else if (ret === false)
					this.setErrorMsg("error_import_file", 2)
				else
					this.setErrorMsg("global", ret.length + " réservations ne sont pas passées : " + ret.join(', '))
			})
			.catch(err => {
				this.setErrorMsg("error_import_file", 1)
			})
		}
	}

	render() {
		let sendbtn = (<button type="submit" className="waves-effect waves-light btn-small">Importer</button>)
		if (this.state.attempt_submit === true)
		{
			sendbtn = (
				<div className="center">
					<Loader />
				</div>
			)
		}
		return (
			<div className="module col s12">
				<h2 className="title-description">Importer des réservations</h2>
				<form className="module-form" onSubmit={this.submitImport}>
					<label>
						<p className="column-20">Fichier clients :</p>
						<input type="file" onChange={this.handleClients} />
						<p className="error">{this.state.error.clients}</p>
					</label>
					<label>
						<p className="column-20">Fichier séjours :</p>
						<input type="file" onChange={this.handleReservations} />
						<p className="error">{this.state.error.reservations}</p>
					</label>
					<p className="error">{this.state.error.global}</p>
					<p className="success">{this.state.success}</p>
					{sendbtn}
				</form>
			</div>
		)
	}
}

export default ImportAmenitiz

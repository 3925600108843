import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import M from 'materialize-css'
import Modal from '../UI/Modal'
import Loader from '../UI/Loader'
import tools from '../../tools'

class ConfigAdmin extends Component {
	constructor(props) {
		super(props)
		this.state = {
			attempt_submit: false,
			form1: {
				active_connexion: false,
				show_stay_dates: false,
				show_stay_location: false,
				application_ouverte: false,
				bcc_inventories: "",
				bcc_services: "",
				default: "",
				inventories: "",
				services: ""
			},
			features: this.props.features,
			config_pms: null,
			modal_msg: null,
			open_modal: false,
			error: this._initErrors(),
			success: null,
			current_lang: this.props.lang_default
		}
		this.length_max = 40
		this.props.updateNavbarTitle('Paramètres administrateurs')
	}

	componentDidMount() {
		this.loadConfigPms()
		this.loadGlobalConfig()
	}

	componentDidUpdate(prevProps, prevState) {
		const elems = document.querySelectorAll('select')
		M.FormSelect.init(elems)
		if (this.props.config_pms !== prevProps.config_pms)
			this.loadConfigPms(true)
	}

	loadConfigPms = (fromProps = false) => {
		if (fromProps === true)
			this.setState({ config_pms: this.props.config_pms })
		else if (!this.state.config_pms)
		{
			this.props.fetchConfigPms()
			.then(config_pms => {
				this.setState({ config_pms: config_pms })
			})
			.catch(err => {
				console.log(err)
			})
		}
	}

	loadGlobalConfig = () => {
		this.props.fetchDashboardConfig()
		.then(config => {
			console.log("global config: ", config)
			const form1 = {
				...this.state.form1,
				active_connexion: config.active_connexion,
				show_stay_dates: config.show_stay_dates,
				show_stay_location: config.show_stay_location,
				application_ouverte: config.application_ouverte,
				bcc_inventories: (!config.config_emails.bcc_inventories) ? "" : config.config_emails.bcc_inventories,
				bcc_services: (!config.config_emails.bcc_services) ? "" : config.config_emails.bcc_services,
				default: (!config.config_emails.default) ? "" : config.config_emails.default,
				inventories: (!config.config_emails.inventories) ? "" : config.config_emails.inventories,
				services: (!config.config_emails.services) ? "" : config.config_emails.services
			}
			this.setState({ form1: form1 })
		})
		.catch(err => {
			console.log(err)
		})
	}

	setErrorMsg = (key, msg) => {
		if (key === "default_update_config")
		{
			key = "global"
			msg = "Erreur: Impossible de mettre à jour les paramètres administrateurs. Veuillez contacter le service technique (code : #" + msg + ")"
		}
		let error = this._initErrors()
		error[key] = msg
		this.setState({ success: null, error: error })
	}

	setSuccessMsg = (msg) => {
		this.setState({ error: this._initErrors(), success: msg })
	}

	clearFlash = () => {
		this.setState({ error: this._initErrors(), success: null })
	}

	_initErrors = () => {
		const errors = {
			global: null,
			establishment: null,
			welcome: null,
			nbdays: null,
			langs: null
		}
		return errors
	}

	handleFeatures = (event) => {
		this.setState({ features: event.target.value })
	}

	handleNeedToSubscribe = (event) => {
		const form1 = {
			...this.state.form1,
			application_ouverte: (this.state.form1.application_ouverte === true) ? false : true
		}
		this.setState({ form1: form1 })
	}

	handleDefaultEmail = (event) => {
		const form1 = {
			...this.state.form1,
			default: event.target.value
		}
		this.setState({ form1: form1 })
	}

	handleServicesEmail = (event) => {
		const form1 = {
			...this.state.form1,
			services: event.target.value
		}
		this.setState({ form1: form1 })
	}

	handleServicesEmailBcc = (event) => {
		const form1 = {
			...this.state.form1,
			bcc_services: event.target.value
		}
		this.setState({ form1: form1 })
	}

	handleInventoriesEmail = (event) => {
		const form1 = {
			...this.state.form1,
			inventories: event.target.value
		}
		this.setState({ form1: form1 })
	}

	handleInventoriesEmailBcc = (event) => {
		const form1 = {
			...this.state.form1,
			bcc_inventories: event.target.value
		}
		this.setState({ form1: form1 })
	}

	handleActiveConnexion = (event) => {
		const form1 = {
			...this.state.form1,
			active_connexion: (this.state.form1.active_connexion === true) ? false : true
		}
		this.setState({ form1: form1 })
	}

	handleShowStayDates = (event) => {
		const form1 = {
			...this.state.form1,
			show_stay_dates: (this.state.form1.show_stay_dates === true) ? false : true
		}
		this.setState({ form1: form1 })
	}

	handleShowStayLocation = (event) => {
		const form1 = {
			...this.state.form1,
			show_stay_location: (this.state.form1.show_stay_location === true) ? false : true
		}
		this.setState({ form1: form1 })
	}

	handlePMS = (event) => {
		const config_pms = {
			...this.state.config_pms,
			values: {
				...this.state.config_pms.values,
				name: event.target.value
			}
		}
		this.setState({ config_pms: config_pms })
	}

	handlePrefix = (event) => {
		const config_pms = {
			...this.state.config_pms,
			values: {
				...this.state.config_pms.values,
				prefix: event.target.value
			}
		}
		this.setState({ config_pms: config_pms })
	}

	handleFTPHost = (event) => {
		const config_pms = {
			...this.state.config_pms,
			values: {
				...this.state.config_pms.values,
				ftp_host: event.target.value
			}
		}
		this.setState({ config_pms: config_pms })
	}

	handleFTPUser = (event) => {
		const config_pms = {
			...this.state.config_pms,
			values: {
				...this.state.config_pms.values,
				ftp_user: event.target.value
			}
		}
		this.setState({ config_pms: config_pms })
	}

	handleFTPPassword = (event) => {
		const config_pms = {
			...this.state.config_pms,
			values: {
				...this.state.config_pms.values,
				ftp_pass: event.target.value
			}
		}
		this.setState({ config_pms: config_pms })
	}

	handleInternToken = (event) => {
		const config_pms = {
			...this.state.config_pms,
			values: {
				...this.state.config_pms.values,
				intern_token: event.target.value
			}
		}
		this.setState({ config_pms: config_pms })
	}

	handleAPIToken = (event) => {
		const config_pms = {
			...this.state.config_pms,
			values: {
				...this.state.config_pms.values,
				api_token: event.target.value
			}
		}
		this.setState({ config_pms: config_pms })
	}

	handleAPIURL = (event) => {
		const config_pms = {
			...this.state.config_pms,
			values: {
				...this.state.config_pms.values,
				api_url: event.target.value
			}
		}
		this.setState({ config_pms: config_pms })
	}

	// _validateForm1 = () => {
	// 	let error_obj = this._initErrors()
	// 	let error = false
	// 	const establishment = this.state.form1.establishment
	// 	if (establishment.length === 0)
	// 	{
	// 		error = true
	// 		error_obj.establishment = "Veuillez renseigner le nom de l'établissement"
	// 	}
	// 	else if (establishment.length > this.length_max)
	// 	{
	// 		error = true
	// 		error_obj.establishment = "Le nom de l'établissement ne doit pas dépasser " + this.length_max + " caractères"
	// 	}
	// 	if (error === true)
	// 	{
	// 		error_obj.global = "Il y a une ou plusieurs erreur(s) dans le formulaire"
	// 		this.setState({ success: null, error: error_obj, attempt_submit: false })
	// 	}
	// 	return (!error) ? true : false
	// }

	// _updateConfig = () => {
	// 	const config = {
	// 		establishment: this.state.form1.establishment,
	// 		sendmail: this.state.form1.sendmail,
	// 		nbdays: this.state.form1.nbdays,
	// 		connexion: this.state.form1.connexion,
	// 		langs: {
	// 			fr: this.state.form1.fr,
	// 			en: this.state.form1.en,
	// 			de: this.state.form1.de,
	// 			nl: this.state.form1.nl,
	// 			it: this.state.form1.it,
	// 			es: this.state.form1.es,
	// 			pt: this.state.form1.pt
	// 		},
	// 		background: this.state.form1.background,
	// 		welcome: this.state.form1.welcome
	// 	}
	// 	this.props.updateConfig(config)
	// 	.then(ret => {
	// 		if (!ret)
	// 		{
	// 			this.setState({ attempt_submit: false })
	// 			this.setErrorMsg('default_update_config', 1)
	// 		}
	// 		else
	// 		{
	// 			this.setState({ attempt_submit: false })
	// 			this.setSuccessMsg('Les paramètres ont bien été mis à jour')
	// 		}
	// 	})
	// 	.catch(err => {
	// 		this.setState({ attempt_submit: false })
	// 		this.setErrorMsg('default_update_config', 2)
	// 		console.log(err)
	// 	})
	// }

	handleSubmit = (event) => {
		event.preventDefault()
		this.setState({ attempt_submit: true })
		const config = {
			config_pms: this.state.config_pms.values,
			features: this.state.features,
			active_connexion: this.state.form1.active_connexion,
			application_ouverte: this.state.form1.application_ouverte,
			show_stay_dates: this.state.form1.show_stay_dates,
			show_stay_location: this.state.form1.show_stay_location,
			config_emails: {
				bcc_services: this.state.form1.bcc_services,
				bcc_inventories: this.state.form1.bcc_inventories,
				default: this.state.form1.default,
				inventories: this.state.form1.inventories,
				services: this.state.form1.services
			}
		}
		if (config.config_pms.name !== this.props.config_pms.values.name)
		{
			if (config.config_pms.name === "inaxel" || config.config_pms.name === "e-season" || config.config_pms.name === "logmis")
			{
				config.config_pms.period = "1030"
				config.config_pms.separator = ';'
			}
			else if (config.config_pms.name === "hippocamp")
				config.config_pms.period = "0500"
			else if (config.config_pms.name === "applicamp")
				config.config_pms.period = "0600"
		}
		this.props.updateConfigDashboard(config)
		.then(ret => {
			if (!ret)
			{
				this.setState({ attempt_submit: false })
				this.setErrorMsg('default_update_config', 1)
			}
			else
			{
				this.setState({ attempt_submit: false })
				this.setSuccessMsg('Les paramètres ont bien été mis à jour')
			}
		})
		.catch(err => {
			this.setState({ attempt_submit: false })
			this.setErrorMsg('default_update_config', 2)
			console.log(err)
		})
	}

	answerModal = (answer) => {
		if (answer === "yes")
			this._updateConfig()
		else
			this.setState({ attempt_submit: false, open_modal: false })
	}

	updateLang = (lang) => {
		this.setState({ current_lang: lang })
	}

	render() {
		let sendbtn = (<button type="submit" className="waves-effect waves-light btn-small">Enregistrer</button>)
		const permissions = this.props.user.permissions
		if (!permissions || !permissions.super || permissions.super === 0)
			return (<Redirect to="/" />)
		else if (!this.state.config_pms)
		{
			return (
				<div className="center">
					<Loader />
				</div>
			)
		}
		else if (this.state.attempt_submit === true)
		{
			sendbtn = (
				<div className="center">
					<Loader />
				</div>
			)
		}
		let paramPrefix = null
		let paramFTPHost = null
		let paramFTPUser = null
		let paramFTPPassword = null
		let paramSeparator = null
		if (this.state.config_pms.values.name === "inaxel" || this.state.config_pms.values.name === "e-season" || this.state.config_pms.values.name === "logmis")
		{
			paramPrefix = (
				<label>
					<p className="column-30">Préfixe :</p>
					<input type="text" value={this.state.config_pms.values.prefix} onChange={this.handlePrefix} />
				</label>
			)
			paramFTPHost = (
				<label>
					<p className="column-30">Hôte FTP :</p>
					<input type="text" value={this.state.config_pms.values.ftp_host} onChange={this.handleFTPHost} />
				</label>
			)
			paramFTPUser = (
				<label>
					<p className="column-30">Utilisateur FTP :</p>
					<input type="text" value={this.state.config_pms.values.ftp_user} onChange={this.handleFTPUser} />
				</label>
			)
			paramFTPPassword = (
				<label>
					<p className="column-30">Mot de passe FTP :</p>
					<input type="text" value={this.state.config_pms.values.ftp_pass} onChange={this.handleFTPPassword} />
				</label>
			)
			paramSeparator = (
				<label>
					<p className="column-30">Séparateur :</p>
					<input type="text" value={this.state.config_pms.values.separator} disabled />
				</label>
			)
		}
		let paramInternToken = null
		if (this.state.config_pms.values.name === "applicamp")
		{
			paramInternToken = (
				<label>
					<p className="column-30">Token interne :</p>
					<input type="text" value={this.state.config_pms.values.intern_token} onChange={this.handleInternToken} />
				</label>
			)
		}
		let paramAPIToken = null
		let paramAPIURL = null
		if (this.state.config_pms.values.name === "hippocamp")
		{
			paramAPIToken = (
				<label>
					<p className="column-30">Token API :</p>
					<input type="text" value={this.state.config_pms.values.api_token} onChange={this.handleAPIToken} />
				</label>
			)
			paramAPIURL = (
				<label>
					<p className="column-30">URL API :</p>
					<input type="text" value={this.state.config_pms.values.api_url} onChange={this.handleAPIURL} />
				</label>
			)
		}
		const last_update = tools.parseDate(this.state.config_pms.values.last_handling, true)
		return (
			<div className="wrapper-content">
				<Modal
					dismissible={false}
					content={this.state.modal_msg}
					open_modal={this.state.open_modal}
					answerModal={this.answerModal}
				/>
				<form className="module-form" onSubmit={this.handleSubmit}>
					<div className="row">
						<div className="module col s6">
							{sendbtn}
						</div>
						<div className="module col s6">
							<p>
								Dernière mise à jour de la passerelle : <strong>{last_update}</strong>
							</p>
						</div>
					</div>
					<div className="row">
						<div className="module col s6">
							<h2 className="title-description">Paramètres généraux</h2>
							<p className="success">{this.state.success}</p>
							<label>
								<p className="column-20">Formule :</p>
								<select value={this.state.features} onChange={this.handleFeatures}>
									<option value="">-- Sélectionez un Pack --</option>
									<option value="essentiel">Essentiel</option>
									<option value="premium">Premium</option>
								</select>
							</label>
							<label>
								<p className="column-30">Application ouverte :</p>
								<input type="checkbox" checked={this.state.form1.application_ouverte} onChange={this.handleNeedToSubscribe} />
								<span></span>
							</label>
							<label>
								{/* <p className="error">{this.state.error.establishment}</p> */}
								<p className="column-30">E-mail par défaut :</p>
								<input type="text" value={this.state.form1.default} onChange={this.handleDefaultEmail} />
							</label>
							<label>
								<p className="column-30">E-mail inventaires :</p>
								<input type="text" value={this.state.form1.inventories} onChange={this.handleInventoriesEmail} />
							</label>
							<label>
								<p className="column-30">E-mail inventaires (bcc) :</p>
								<input type="text" value={this.state.form1.bcc_inventories} onChange={this.handleInventoriesEmailBcc} />
							</label>
							<label>
								<p className="column-30">E-mail services :</p>
								<input type="text" value={this.state.form1.services} onChange={this.handleServicesEmail} />
							</label>
							<label>
								<p className="column-30">E-mail services (bcc) :</p>
								<input type="text" value={this.state.form1.bcc_services} onChange={this.handleServicesEmailBcc} />
							</label>
							<label>
								<p className="column-30">Activer la connexion sur la webapp :</p>
								<input type="checkbox" checked={this.state.form1.active_connexion} onChange={this.handleActiveConnexion} />
								<span></span>
							</label>
							<label>
								<p className="column-30">Afficher le n° d'emplacement sur "Mon Compte" :</p>
								<input type="checkbox" checked={this.state.form1.show_stay_location} onChange={this.handleShowStayLocation} />
								<span></span>
							</label>
							<label>
								<p className="column-30">Afficher les dates de séjours sur "Mon Compte" :</p>
								<input type="checkbox" checked={this.state.form1.show_stay_dates} onChange={this.handleShowStayDates} />
								<span></span>
							</label>
						</div>
						<div className="module col s6">
							<h2 className="title-description">Paramètres passerelle</h2>
							<label>
								<p className="column-20">PMS :</p>
								<select value={this.state.config_pms.values.name} onChange={this.handlePMS}>
									<option value="">-- Sélectionez un PMS --</option>
									<option value="inaxel">Inaxel</option>
									<option value="e-season">E-Season</option>
									<option value="hippocamp">Hippocamp</option>
									<option value="applicamp">Applicamp</option>
									<option value="logmis">Logmis</option>
									<option value="koban">Koban</option>
								</select>
							</label>
							<label>
								<p className="column-30">Période :</p>
								<input type="text" value={this.state.config_pms.values.period} disabled />
							</label>
							{paramPrefix}
							{paramFTPHost}
							{paramFTPUser}
							{paramFTPPassword}
							{paramSeparator}
							{paramInternToken}
							{paramAPIToken}
							{paramAPIURL}
							<p className="error">{this.state.error.global}</p>
						</div>
					</div>
				</form>
			</div>
		)
	}
}

export default ConfigAdmin

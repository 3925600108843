import React, { Component } from 'react'
import '../Home/Icons.css'
import '../Core/Main.css'

class IconPicker extends Component {
	constructor(props) {
		super(props)
		this.state = {
			icons: null,
			display: false
		}
	}

	handleClick = () => {
		let display = (this.state.display) ? false : true
		this.setState({ display: display })
	}

	handleSelect = (id) => {
		this.props.onChange(id)
		this.setState({ display: false })
	}

	render() {
		const nbRows = 8
		let row = null
		const display = (this.state.display) ? 'block' : 'none'
		let iconJSX = null
		if (this.props.icons)
		{
			let table_k = -1
			let table_icons = []
			for (let i = 0; i < this.props.icons.length; i++)
			{
				if (i % nbRows === 0)
				{
					if (row !== null)
						table_icons.push(row)
					row = []
				}
				row.push(this.props.icons[i])
			}
			if (row && row.length > 0)
				table_icons.push(row)
			iconJSX = (
				<table className="icons-table">
					<tbody>
						{table_icons && table_icons.map(( row ) => {
							table_k++
							return (
								<tr key={table_k}>
									{row && row.map(({ _id, name, lineal, fill }) => {
										let prev = null
										let classname = (this.props.theme === "blank") ? "blank" : null
										let icons = (this.props.type === "lineal") ? lineal : fill
										for (let i = 0; i < icons.length; i++)
										{
											if (icons[i].theme === this.props.theme)
											{
												prev = icons[i].icon
												break
											}
										}
										return (
											<td key={_id} className={classname} onClick={this.handleSelect.bind(this, _id)}>
												<img src={prev} alt={name} title={name} />
											</td>
										)
									})}
								</tr>
							)
						})}
					</tbody>
				</table>
			)
			/*iconJSX = (
				<table className="icons-table">
					<tbody>
						{table_icons && table_icons.map(( row ) => {
							table_k++
							return (
								<tr key={table_k}>
									{row && row.map(({ _id, name, icon, icons }) => {
										let prev = null
										let classname = null
										if (this.props.type === "color")
										{
											for (let i = 0; i < icons.length; i++)
											{
												if (icons[i].theme === this.props.theme._id)
												{
													prev = icons[i].icon
													break
												}
											}
										}
										else if (this.props.type === "blank")
										{
											classname = "blank"
											prev = icon
										}
										return (
											<td key={_id} className={classname} onClick={this.handleSelect.bind(this, _id)}>
												<img src={prev} alt={name} title={name} />
											</td>
										)
									})}
								</tr>
							)
						})}
					</tbody>
				</table>
			)*/
		}
		return (
			<div>
				<button type="button" onClick={this.handleClick} className="btn-floating btn-large waves-effect waves-light teal"><i className="material-icons">mode_edit</i></button>
				<div className="icon-picker" style={{ display: display }}>
					{iconJSX}
				</div>
			</div>
		)
	}
}

export default IconPicker

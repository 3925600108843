import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { HomeIcon } from '@heroicons/react/outline'
import EmptyFolder from '../UI/EmptyFolder'

class OnlyPremiumEmpty extends Component {
	constructor(props) {
		super(props)
		this.state = {
			pages: [
				{ name: this.props.navName, href: this.props.navPath, current: false },
			]
		}
	}

	render() {
		const navStyle = "flex bg-transparent shadow-none mb-10"
		let content_tableJSX = (
			<EmptyFolder content={this.props.emptyFolder} emptyFolderSubtitle={this.props.emptyFolderSubtitle}/>
		)
		return (
			<div>
				<nav className={navStyle} aria-label="Breadcrumb">
					<ol className="bg-white rounded-md shadow px-6 flex space-x-4">
						<li className="flex">
							<div className="flex items-center">
								<Link to="/" className="text-gray-400 hover:text-gray-500">
									<HomeIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
									<span className="sr-only">Home</span>
								</Link>
							</div>
						</li>
						{this.state.pages.map((page) => (
							<li key={page.name} className="flex">
								<div className="flex items-center">
									<svg
										className="flex-shrink-0 w-6 h-full text-gray-200"
										viewBox="0 0 24 44"
										preserveAspectRatio="none"
										fill="currentColor"
										xmlns="http://www.w3.org/2000/svg"
										aria-hidden="true"
									>
										<path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
									</svg>
									<Link
										to={page.href}
										className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
										aria-current={page.current ? 'page' : undefined}
									>
										{page.name}
									</Link>
								</div>
							</li>
						))}
					</ol>
				</nav>

				<div className="mb-5">
                	<h2 className="text-xl leading-6 font-medium text-gray-900 mb-0">{this.props.title}</h2>
                	<p className="mt-1 text-base text-gray-500">
						{this.props.subtitle}
                	</p>
				</div>
				<div className="flex flex-col mt-20">
					<div className="-my-2 sm:-mx-6 lg:-mx-8">
						<div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
							{content_tableJSX}
						</div>
					</div>
				</div>

			</div>
		)
	}
}

export default OnlyPremiumEmpty

import React, { Component } from 'react'
import M from 'materialize-css'
import Modal from '../UI/Modal'
import Loader from '../UI/Loader'
import LangSelector from '../UI/LangSelector'
import tools from '../../tools'
import { Link, Redirect } from 'react-router-dom'
import { HomeIcon } from '@heroicons/react/solid'
import EmptyFolder from '../UI/EmptyFolder'

class Modules extends Component {
	constructor(props) {
		super(props)
		this.state = {
			attempt_submit: false,
			create: false,
			init_modal: false,
			answerModal: null,
			open_modal: false,
			modal_msg: null,
			delete: null,
			modules: null,
			module: null,
			groups: null,
			pages: null,
			lists: null,
			navigation: [
				{ name: 'Livret d\'accueil', href: '/modules', current: false },
			],
			categories: null,
			form1: {
				name: '',
				type: '',
				values: {},
				translations: []
			},
			error: this._initErrors(),
			current_lang: this.props.lang_default,
			success: null
		}
		this.length_max = 50
		this.title_length_max = 40
		this.props.updateNavbarTitle('Administrer la page d\'accueil')
	}

	componentDidMount() {
		this.loadModules()
		this.loadPagesAndCategories()
	}

	componentDidUpdate(prevProps) {
		const selects = document.querySelectorAll('select')
		M.FormSelect.init(selects)
		if (this.state.init_modal === false)
		{
			const modals = document.querySelectorAll('.modal')
			if (modals.length > 0)
			{
				M.Modal.init(modals)
				this.setState({ init_modal: true })
			}
		}
		if (prevProps.modules !== this.props.modules)
			this.loadModules(true)
	}

	setErrorMsg = (key, msg, id = null) => {
		if (key === "error_fetch_modules")
		{
			key = "global"
			msg = tools.default_error_msg("Impossible de récupérer les modules", msg)
		}
		else if (key === "error_create_module")
		{
			key = "modal"
			msg = tools.default_error_msg("Impossible de créer un module", msg)
		}
		else if (key === "error_update_module")
		{
			key = "modal"
			msg = tools.default_error_msg("Impossible de modifier un module", msg)
		}
		else if (key === "error_delete_module")
		{
			key = "global"
			msg = tools.default_error_msg("Impossible de supprimer un module", msg)
		}
		else if (key === "error_order_modules")
		{
			key = "global"
			msg = tools.default_error_msg("Impossible de réordonner les modules", msg)
		}
		let error = this._initErrors()
		if (id !== null)
			error[key][id] = msg
		else
			error[key] = msg
		this.setState({ success: '', error: error })
	}

	setSuccessMsg = (msg) => {
		this.clearFlash()
		this.setState({ success: msg, error: this._initErrors() })
	}

	clearFlash = () => {
		this.setState({ success: null, error: this._initErrors() })
	}

	_initErrors = () => {
		return {
			global: null,
			modal: null,
			name: null,
			title: null,
			type: null,
			page: null,
			list: null,
			subtitle: null,
			group: null
		}
	}

	loadModules = (fromProps = false) => {
		if (fromProps === true)
		{
			let modules = tools.clone(this.props.modules)
			modules.sort((a, b) => {
				return a.order - b.order
			})
			this.setState({ modules: modules })

		}
		else
		{
			this.props.fetchModules()
			.then(modules => {
				modules.sort((a, b) => {
					return a.order - b.order
				})
				this.setState({ modules: modules })
			})
			.catch(err => {
				this.setErrorMsg("error_fetch_modules", 1)
				console.log(err)
			})
		}
	}

	loadPagesAndCategories = () => {
		this.props.fetchGroups()
		.then(groups => {
			this.setState({ groups: groups })
			this.props.fetchLists(true)
			.then(lists => {
				this.props.fetchPages(true, true)
				.then(pages => {
					let filter_pages = []
					for (let i = 0; i < pages.length; i++)
					{
						let name = ""
						let url = ""
						for (let k = 0; k < pages[i].translations.length; k++)
						{
							if (pages[i].translations[k].lang === this.props.lang_default)
							{
								name = pages[i].translations[k].name
								url = pages[i].translations[k].url
								break
							}
						}
						if (!name && !url)
							continue
						if (!pages[i].category)
							filter_pages.push(pages[i])
					}
					this.props.fetchCategories()
					.then(categories => {
						let filter_lists = []
						let filter_categories = []
						for (let i = 0; i < categories.length; i++)
						{
							let group = null
							for (let k = 0; k < groups.length; k++)
							{
								if (groups[k]._id === categories[i].group)
								{
									group = groups[k]
									break
								}
							}
							if (group)
							{
								let prefix = ""
								for (let k = 0; k < group.translations.length; k++)
								{
									if (group.translations[k].lang === this.props.lang_default)
									{
										prefix = group.translations[k].prefix
										break
									}
								}
								let k = 0
								let suffix = ""
								while (k < categories[i].translations.length)
								{
									if (categories[i].translations[k].lang === this.props.lang_default)
									{
										suffix = categories[i].translations[k].suffix
										break
									}
									k++
								}
								if (prefix && suffix)
									categories[i].translations[k].url = prefix + '/' + suffix
								else
									categories[i].translations[k].url = null
							}
							if (categories[i].type === "page")
								filter_categories.push(categories[i])
							else if (categories[i].type === "list")
							{
								for (let k = 0; k < lists.length; k++)
								{
									if (String(categories[i]._id) === String(lists[k].category))
									{
										for (let l = 0; l < categories[i].translations.length; l++)
										{
											if (categories[i].translations[l].lang === this.props.lang_default)
											{
												lists[k].name = categories[i].translations[l].name
												lists[k].url = categories[i].translations[l].url
												break
											}
										}
										filter_lists.push(lists[k])
										break
									}
								}
							}
						}
						this.setState({ lists: filter_lists, pages: filter_pages, categories: filter_categories })
					})
					.catch(err => {
						this.setErrorMsg("error_fetch_modules", 2)
						console.log(err)
					})
				})
				.catch(err => {
					this.setErrorMsg("error_fetch_modules", 3)
					console.log(err)
				})
			})
			.catch(err => {
				this.setErrorMsg("error_fetch_modules", 4)
				console.log(err)
			})
		})
		.catch(err => {
			this.setErrorMsg("error_fetch_modules", 5)
			console.log(err)
		})
	}

	handleName = (event) => {
		const form1 = {
			...this.state.form1,
			name: event.target.value
		}
		this.setState({ form1: form1 })
	}

	handleTitle = (event) => {
		let found = false
		const translations = this.state.form1.translations
		for (let i = 0; i < translations.length; i++)
		{
			if (translations[i].lang === this.state.current_lang)
			{
				found = true
				translations[i].title = event.target.value
				break
			}
		}
		if (!found)
		{
			if (this.state.form1.type === "page")
			{
				translations.push({
					lang: this.state.current_lang,
					title: event.target.value,
					values: {
						subtitle: ""
					}
				})
			}
			else
			{
				translations.push({
					lang: this.state.current_lang,
					title: event.target.value
				})
			}
		}
		const form1 = {
			...this.state.form1,
			translations: translations
		}
		this.setState({ form1: form1 })
	}

	handleType = (event) => {
		let values = {}
		const type = event.target.value
		const translations = this.state.form1.translations
		if (type === "group")
		{
			values = { group: "" }
			for (let i = 0; i < translations.length; i++)
			{
				translations[i] = {
					lang: translations[i].lang,
					title: translations[i].title
				}
			}
		}
		else if (type === "page")
		{
			values = { category: "", page: "" }
			for (let i = 0; i < translations.length; i++)
			{
				const values = (translations[i].values && translations[i].values.subtitle) ? translations[i].values : { subtitle: "" }
				translations[i] = {
					lang: translations[i].lang,
					title: translations[i].title,
					values: values
				}
			}
		}
		else if (type === "list")
		{
			values = { list: "" }
			for (let i = 0; i < translations.length; i++)
			{
				const values = (translations[i].values && translations[i].values.subtitle) ? translations[i].values : { subtitle: "" }
				translations[i] = {
					lang: translations[i].lang,
					title: translations[i].title,
					values: values
				}
			}
		}
		const form1 = {
			...this.state.form1,
			type: type,
			values: values,
			translations: translations
		}
		this.setState({ form1: form1 })
	}

	handleGroup = (event) => {
		const values = {
			group: event.target.value
		}
		const form1 = {
			...this.state.form1,
			values: values
		}
		this.setState({ form1: form1 })
	}

	handlePage = (event) => {
		let values = {
			...this.state.form1.values,
			category: '',
			page: ''
		}
		const split = event.target.value.split(',')
		const id = split[0]
		const type = split[1]
		if (type === 'page')
		{
			let found = false
			for (let i = 0; i < this.state.pages.length; i++)
			{
				if (this.state.pages[i]._id === id)
				{
					found = true
					break
				}
			}
			if (found === true)
				values.page = id
		}
		else if (type === 'category')
		{
			let found = false
			for (let i = 0; i < this.state.categories.length; i++)
			{
				if (this.state.categories[i]._id === id)
				{
					found = true
					break
				}
			}
			if (found === true)
				values.category = id
		}
		const form1 = {
			...this.state.form1,
			values: values
		}
		this.setState({ form1: form1 })
	}

	handleList = (event) => {
		let values = {
			...this.state.form1.values,
			list: event.target.value
		}
		const form1 = {
			...this.state.form1,
			values: values
		}
		this.setState({ form1: form1 })
	}

	handleSubtitle = (event) => {
		let found = false
		const translations = this.state.form1.translations
		for (let i = 0; i < translations.length; i++)
		{
			if (translations[i].lang === this.state.current_lang)
			{
				found = true
				if (!translations[i].values)
					translations[i].values = {}
				translations[i].values.subtitle = event.target.value
				break
			}
		}
		if (!found)
		{
			translations.push({
				lang: this.state.current_lang,
				title: "",
				values: {
					subtitle: event.target.value
				}
			})
		}
		const form1 = {
			...this.state.form1,
			translations: translations
		}
		this.setState({ form1: form1 })
	}

	_validateForm1 = () => {
		let error_obj = this._initErrors()
		let error = false
		const name = this.state.form1.name
		const type = this.state.form1.type
		const values = this.state.form1.values
		const translations = this.state.form1.translations
		if (name.length === 0)
		{
			error = true
			error_obj.name = 'Le nom ne doit pas être vide'
		}
		else if (name.length > this.length_max)
		{
			error = true
			error_obj.name = 'Le nom ne doit pas dépasser ' + this.length_max + ' caractères'
		}
		if (type.length === 0)
		{
			error = true
			error_obj.type = 'Veuillez indiquer le type de module'
		}
		if (type === "group" && values.group.length === 0)
		{
			error = true
			error_obj.group = "Veuillez sélectionner une rubrique"
		}
		if (type === "page" && values.page.length === 0 && values.category.length === 0)
		{
			error = true
			error_obj.page = "Veuillez sélectionner une page"
		}
		if (type === "list" && values.list.length === 0)
		{
			error = true
			error_obj.list = "Veuillez sélectionner une liste"
		}
		let found_default_title = false
		let found_default_subtitle = false
		for (let i = 0; i < translations.length; i++)
		{
			const lang = translations[i].lang
			const title = translations[i].title
			if (lang === this.props.lang_default && title.length === 0)
			{
				error = true
				error_obj.title = 'Le titre ne doit pas être vide (version ' + lang.toUpperCase() + ')'
			}
			else if (title.length > this.title_length_max)
			{
				error = true
				error_obj.title = 'Le titre ne doit pas dépasser ' + this.title_length_max + ' caractères (version ' + lang.toUpperCase() + ')'
			}
			else if (lang === this.props.lang_default)
				found_default_title = true
			if (type === "page")
			{
				const subtitle = translations[i].values.subtitle
				if (subtitle.length === 0)
				{
					error = true
					error_obj.subtitle = "Le sous-titre ne doit pas être vide (version " + lang.toUpperCase() + ")"
				}
				else if (subtitle.length > this.title_length_max)
				{
					error = true
					error_obj.subtitle = "Le sous-titre ne doit pas dépasser " + this.title_length_max + " caractères (version " + lang.toUpperCase() + ")"
				}
				else if (lang === this.props.lang_default)
					found_default_subtitle = true
			}
		}
		if (!found_default_title && !error_obj.title)
		{
			error = true
			error_obj.title = 'Le titre ne doit pas être vide (version ' + this.props.lang_default.toUpperCase() + ')'
		}
		if (type === "page" && !found_default_subtitle && !error_obj.subtitle)
		{
			error = true
			error_obj.subtitle = "Le sous-titre ne doit pas être vide (version " + this.props.lang_default.toUpperCase() + ")"
		}
		if (error === true)
		{
			error_obj.modal = "Il y a une ou plusieurs erreur(s) dans le formulaire"
			this.setState({ success: null, error: error_obj, attempt_submit: false })
		}
		return (!error) ? true : false
	}

	submitModule = (event) => {
		event.preventDefault()
		this.setState({ attempt_submit: true })
		if (this._validateForm1())
		{
			const type = this.state.form1.type
			const translations = this.state.form1.translations
			let langs_not_found = []
			for (let i = 0; i < this.props.langs.length; i++)
			{
				let found = false
				for (let j = 0; j < translations.length; j++)
				{
					if ((type === "page" && this.props.langs[i] === translations[j].lang && translations[j].title.length > 0 && translations[j].values.subtitle.length > 0) ||
						(this.props.langs[i] === translations[j].lang && translations[j].title.length > 0))
					{
						found = true
						break
					}
				}
				if (!found)
					langs_not_found.push(this.props.langs[i])
			}
			if (langs_not_found.length > 0)
			{
				let msg = ""
				for (let i = 0; i < langs_not_found.length; i++)
				{
					if (i > 0)
						msg += ', '
					msg += langs_not_found[i].toUpperCase()
				}
				msg = "Le module n'est pas traduite dans les langues suivantes : " + msg
				this.setState({ modal_msg: msg, open_modal: true, answerModal: this.answerModalEdit })
			}
			else
				this._insertModule()
		}
	}

	_insertModule = () => {
		const name = this.state.form1.name
		const type = this.state.form1.type
		const values = this.state.form1.values
		const translations = this.state.form1.translations
		if (this.state.module === null)
		{
			const module = {
				name: name,
				type: type,
				values: values,
				translations: translations
			}
			this.props.insertModule(module)
			.then(ret => {
				if (ret === false)
				{
					this.setState({ attempt_submit: false, open_modal: false })
					this.setErrorMsg('error_create_module', 1)
				}
				else
				{
					console.log(ret)
					this.clickClose()
					this.setState({ attempt_submit: false, open_modal: false })
					this.setSuccessMsg("Le module a bien été créé")
				}
			})
			.catch(err => {
				this.setState({ attempt_submit: false, open_modal: false })
				this.setErrorMsg('error_create_module', 2)
				console.log(err)
			})
		}
		else
		{
			const module = {
				_id: this.state.module._id,
				name: name,
				type: type,
				values: values,
				translations: translations
			}
			this.props.updateModule(module)
			.then(ret => {
				if (ret === false)
				{
					this.setState({ attempt_submit: false, open_modal: false })
					this.setErrorMsg('error_update_module', 1)
				}
				else
				{
					this.clickClose()
					this.setState({ attempt_submit: false, open_modal: false })
					this.setSuccessMsg("Le module a bien été modifié")
				}
			})
			.catch(err => {
				this.setState({ attempt_submit: false, open_modal: false })
				this.setErrorMsg('error_update_module', 2)
				console.log(err)
			})
		}
	}

	deleteModule = (id) => {
		const modal_msg = "Vous êtes sur le point du supprimer ce module de la page d'accueil. Cette action est irréversible"
		this.setState({ delete: id, open_modal: true, modal_msg: modal_msg, answerModal: this.answerModalDelete })
	}

	_deleteModule = () => {
		this.props.deleteModule(this.state.delete)
		.then(ret => {
			if (ret === true)
			{
				this.setState({ open_modal: false })
				this.setSuccessMsg("Le module a bien été supprimé")
			}
			else
			{
				this.setState({ open_modal: false })
				this.setErrorMsg('error_delete_module', 1)
			}
		})
		.catch(err => {
			this.setState({ open_modal: false })
			this.setErrorMsg('error_delete_module', 2)
			console.log(err)
		})
	}

	handleUp = (order) => {
		let modules = this.state.modules
		for (let i = 0; i < modules.length; i++)
		{
			if (order === modules[i].order && i > 0)
			{
				const order_tmp = modules[i].order
				modules[i].order = modules[i - 1].order
				modules[i - 1].order = order_tmp
				const tmp = modules[i]
				modules[i] = modules[i - 1]
				modules[i - 1] = tmp
				break
			}
		}
		this.props.updateModulesOrder(modules)
		.then(modules => {
			if (!modules)
				this.setErrorMsg('error_order_modules', 1)
		})
		.catch(err => {
			this.setErrorMsg('error_order_modules', 2)
			console.log(err)
		})
	}

	handleDown = (order) => {
		let modules = this.state.modules
		for (let i = 0; i < modules.length; i++)
		{
			if (order === modules[i].order && i + 1 < modules.length)
			{
				const order_tmp = modules[i].order
				modules[i].order = modules[i + 1].order
				modules[i + 1].order = order_tmp
				const tmp = modules[i]
				modules[i] = modules[i + 1]
				modules[i + 1] = tmp
				break
			}
		}
		this.props.updateModulesOrder(modules)
		.then(modules => {
			if (!modules)
				this.setErrorMsg('error_order_modules', 3)
		})
		.catch(err => {
			this.setErrorMsg('error_order_modules', 4)
			console.log(err)
		})
	}

	clickClose = () => {
		const modals = document.querySelectorAll('.modal')
		const instance = M.Modal.getInstance(modals[1])
		instance.close()
		this.setState({ create: false, edit: null })
	}

	clickCreate = () => {
		const modals = document.querySelectorAll('.modal')
		const instance = M.Modal.getInstance(modals[1])
		instance.open()
		const form1 = {
			name: '',
			type: '',
			values: {},
			translations: []
		}
		this.setState({ create: true, module: null, form1: form1 })
	}

	clickEdit = (id) => {
		const modals = document.querySelectorAll('.modal')
		const instance = M.Modal.getInstance(modals[1])
		instance.open()
		const modules = this.state.modules
		let module = null
		let form1 = {
			name: '',
			type: '',
			values: {},
			translations: []
		}
		for (let i = 0; i < modules.length; i++)
		{
			if (modules[i]._id === id)
			{
				module = modules[i]
				form1 = {
					name: module.name,
					type: module.type,
					values: module.values,
					translations: tools.clone(module.translations)
				}
				break
			}
		}
		this.setState({ create: false, module: module, form1: form1 })
	}

	answerModalEdit = (answer) => {
		if (answer === "yes")
			this._insertModule()
		else
			this.setState({ attempt_submit: false, open_modal: false })
	}

	answerModalDelete = (answer) => {
		if (answer === "yes")
			this._deleteModule()
		else
			this.setState({ delete: false, open_modal: false })
	}

	updateLang = (lang) => {
		this.setState({ current_lang: lang })
	}

	render() {
		const modules = this.state.modules
		const permissions = this.props.user.permissions
		if (!permissions || !permissions.rubrics || permissions.rubrics === 0)
			return (<Redirect to="/" />)
		else if (modules !== null && this.state.groups !== null && this.state.categories !== null && this.state.pages !== null)
		{
			let module_k = 0
			const type = this.state.form1.type
			let secondPart = null
			let title = ""
			let subtitle = ""
			for (let i = 0; i < this.state.form1.translations.length; i++)
			{
				if (this.state.form1.translations[i].lang === this.state.current_lang)
				{
					title = this.state.form1.translations[i].title
					if ((type === "page" || type === "list") && this.state.form1.translations[i].values)
						subtitle = this.state.form1.translations[i].values.subtitle
					break
				}
			}
			if (type === "group")
			{
				secondPart = (
					<label>
						<p className="error">{this.state.error.group}</p>
						<p>Rubrique :</p>
						<select value={this.state.form1.values.group} onChange={this.handleGroup}>
							<option value="">-- Sélectionner une rubrique --</option>
							{this.state.groups.map(({ _id, translations }) => {
								let name = ""
								for (let i = 0; i < translations.length; i++)
								{
									if (translations[i].lang === this.props.lang_default)
									{
										name = translations[i].name
										break
									}
								}
								return (<option key={_id} value={_id}>{name}</option>)
							})}
						</select>
					</label>
				)
			}
			else if (type === "page")
			{
				let pages = []
				for (let i = 0; i < this.state.pages.length; i++)
				{
					const page = {
						type: 'page',
						id: this.state.pages[i]._id,
						translations: this.state.pages[i].translations
					}
					pages.push(page)
				}
				for (let i = 0; i < this.state.categories.length; i++)
				{
					const page = {
						type: 'category',
						id: this.state.categories[i]._id,
						translations: this.state.categories[i].translations
					}
					pages.push(page)
				}
				let value = ''
				if (this.state.form1.values.page !== '')
					value = this.state.form1.values.page + ',page'
				else if (this.state.form1.values.category !== '')
					value = this.state.form1.values.category + ',category'
				pages.sort((a, b) => {
					let name_a = null
					let name_b = null
					for (let i = 0; i < a.translations.length; i++)
					{
						if (a.translations[i].lang === this.state.current_lang)
						{
							name_a = a.translations[i].name
							break
						}
					}
					for (let i = 0; i < b.translations.length; i++)
					{
						if (b.translations[i].lang === this.state.current_lang)
						{
							name_b = b.translations[i].name
							break
						}
					}
					if (!name_a && !name_b)
						return 0
					else if (!name_a)
						return 1
					else if (!name_b)
						return -1
					else
						return name_a.localeCompare(name_b)
				})
				secondPart = (
					<div>
						<label>
							<p className="error">{this.state.error.page}</p>
							<p>Page :</p>
							<select value={value} onChange={this.handlePage}>
								<option value="">-- Sélectionner une page --</option>
								{pages.map(({ id, type, translations }) => {
									let name = ""
									let url = ""
									for (let i = 0; i < translations.length; i++)
									{
										if (translations[i].lang === this.props.lang_default)
										{
											name = translations[i].name
											url = translations[i].url
											break
										}
									}
									const value = id + "," + type
									return (<option key={id} value={value}>{name}: /{url}</option>)
								})}
							</select>
						</label>
						<label>
							<p className="error">{this.state.error.subtitle}</p>
							<p>Sous-Titre ({this.state.current_lang.toUpperCase()}) :</p>
							<input type="text" value={subtitle} onChange={this.handleSubtitle} />
						</label>
					</div>
				)
			}
			else if (type === "list")
			{
				this.state.lists.sort((a, b) => {
					if (!a.name && !b.name)
						return 0
					else if (!a.name)
						return 1
					else if (!b.name)
						return -1
					else
						return a.name.localeCompare(b.name)
				})
				secondPart = (
					<div>
						<label>
							<p className="error">{this.state.error.list}</p>
							<p>Liste :</p>
							<select value={this.state.form1.list} onChange={this.handleList}>
								<option value="">-- Sélectionner une liste --</option>
								{this.state.lists.map(({ _id, name, url }) => {
									return (<option key={_id} value={_id}>{name}: /{url}</option>)
								})}
							</select>
						</label>
						<label>
							<p className="error">{this.state.error.subtitle}</p>
							<p>Sous-Titre ({this.state.current_lang.toUpperCase()}) :</p>
							<input type="text" value={subtitle} onChange={this.handleSubtitle} />
						</label>
					</div>
				)
			}
			let btn = 'Ajouter'
			let titleHeader = 'Créer un module'
			const navStyle = "flex bg-transparent shadow-none mb-10"
			if (this.state.module !== null)
			{
				btn = 'Éditer'
				titleHeader = 'Modifier un module'
			}
			let sendbtn = (<button className="waves-effect waves-light btn-small" type="submit"><i className="material-icons right">send</i>{btn}</button>)
			if (this.state.attempt_submit === true)
			{
				sendbtn = (
					<div className="center">
						<Loader />
					</div>
				)
			}
			let empty_folder = ""
			if (!modules || !modules.length)
			{
				empty_folder = (
					<div className="mt-10">
						<EmptyFolder
							emptyFolderSubtitle="Il n'y pas encore de modules affichés sur votre application." 
						/>
					</div>
				)
			}
			return (
				<div className="wrapper-content">
					<p className="success center">{this.state.success}</p>
					<p className="error center">{this.state.error.global}</p>
					<nav className={navStyle} aria-label="Breadcrumb">
						<ol className="bg-white rounded-md shadow px-6 flex space-x-4">
							<li className="flex">
							<div className="flex items-center">
								<Link to="/" className="text-gray-400 hover:text-gray-500">
									<HomeIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
									<span className="sr-only">Home</span>
								</Link>
							</div>
							</li>
							{this.state.navigation.map((page) => (
							<li key={page.name} className="flex">
								<div className="flex items-center">
								<svg
									className="flex-shrink-0 w-6 h-full text-gray-200"
									viewBox="0 0 24 44"
									preserveAspectRatio="none"
									fill="currentColor"
									xmlns="http://www.w3.org/2000/svg"
									aria-hidden="true"
								>
									<path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
								</svg>
								<Link
									to={page.href}
									className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
									aria-current={page.current ? 'page' : undefined}
								>
									{page.name}
								</Link>
								</div>
							</li>
							))}
						</ol>
					</nav>
					<div className="mb-5 w-1/2">
						<h2 className="text-xl leading-6 font-medium text-gray-900 mb-0">Structure de mon app</h2>
						<p className="mt-1 text-base text-gray-500">Agencez ici l'interface de votre application.</p>
						<p className="text-base text-gray-500">Vous pouvez ajouter les rubriques créées, des modules photo clicables ainsi que le module animation.</p>
					</div>
					<Modal
						dismissible={false}
						content={this.state.modal_msg}
						open_modal={this.state.open_modal}
						answerModal={this.state.answerModal}
					/>
					<div id="modal2" className="modal">
						<div className="modal-content">
							<h4>{titleHeader}</h4>
							<LangSelector lang={this.state.current_lang} updateLang={this.updateLang} />
							<p className="error center">{this.state.error.modal}</p>
							<form onSubmit={this.submitModule}>
								<label>
									<p className="error">{this.state.error.name}</p>
									<p>Nom :</p>
									<input type="text" value={this.state.form1.name} onChange={this.handleName} />
								</label>
								<label>
									<p className="error">{this.state.error.title}</p>
									<p>Titre ({this.state.current_lang.toUpperCase()}) :</p>
									<input type="text" value={title} onChange={this.handleTitle} />
								</label>
								<label>
									<p className="error">{this.state.error.type}</p>
									<p>Type :</p>
									<select value={this.state.form1.type} onChange={this.handleType}>
										<option value="">-- Sélectionner un type --</option>
										<option value="animation">Module Animation</option>
										<option value="group">Module Rubrique</option>
										<option value="page">Module Page</option>
										<option value="list">Module Liste</option>
									</select>
								</label>
								{secondPart}
								{sendbtn}
							</form>
						</div>
						<div className="modal-footer">
							<button onClick={this.clickClose} className="link waves-effect waves-green btn-flat">Fermer</button>
						</div>
					</div>
					<div className="row">
						<div className="module col s10">
							<table>
								<thead>
									<tr>
										<th>Ordre</th>
										<td>Module</td>
										<td>Type</td>
										<td className="center">Monter/Descendre</td>
										<td className="center">Éditer</td>
										<td className="center">Supprimer</td>
									</tr>
								</thead>
								<tbody>
									{modules.map(({ _id, name, type, order }) => {
										module_k++
										let type_txt = type
										if (type === "page")
											type_txt = "Page"
										else if (type === "group")
											type_txt = "Rubrique"
										else if (type === "animation")
											type_txt = "Animation"
										return (
											<tr key={_id}>
												<th>{module_k}</th>
												<td>{name}</td>
												<td>{type_txt}</td>
												<td className="center">
													<button type="button" className="btn-floating waves-effect waves-light" onClick={this.handleUp.bind(this, order)}><i className="material-icons">arrow_upward</i></button>
													<button type="button" className="btn-floating waves-effect waves-light" onClick={this.handleDown.bind(this, order)}><i className="material-icons">arrow_downward</i></button>
												</td>
												<td className="center"><button type="button" className="btn-floating waves-effect waves-light" onClick={this.clickEdit.bind(this, _id)}><i className="material-icons">edit</i></button></td>
												<td className="center"><button type="button" className="btn-floating waves-effect waves-light red" onClick={this.deleteModule.bind(this, _id)}><i className="material-icons">delete</i></button></td>
											</tr>
										)
									})}
								</tbody>
							</table>
							{empty_folder}
							<p onClick={this.clickCreate} className="button-text">
								<button type="button" className="btn-floating waves-effect waves-light">
									<i className="material-icons">add</i>
								</button>
								<span>Ajouter un module</span>
							</p>
						</div>
					</div>
				</div>
			)
		}
		else
		{
			return (
				<div className="loader">
					<Loader />
				</div>
			)
		}
	}
}

export default Modules

import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'
import bell from '../../images/bell.png'
import connexion from '../../connexion'
import ImportAmenitiz from './ImportAmenitiz'
import ImportKoban from './ImportKoban'
import { parseDate, parseTimestamp } from '../../tools'
import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ChevronDownIcon, ChevronUpIcon, MinusIcon } from '@heroicons/react/solid'
import Loader from '../UI/Loader'
import tools from '../../tools'
import M from 'materialize-css'
import '../Core/Main.css'

class NewClients extends Component {
	constructor(props) {
		super(props)
		this.state = {
			config_pms: null,
			clients: null,
			filtered_clients: null,
			stays: null,
			open_modal: false,
			modal_participants: null,
			sort: {
				lastname: null,
				firstname: null,
				email: null,
				location: null,
				status: null,
				created_date: null,
				created_time: null,
				stay_number: null
			},
			form1: {
				client_number: '',
				lastname: '',
				firstname: '',
				email: '',
				phone: '',
				location: '',
				client_type: '',
				arrival: {
					start_day: '',
					start_month: '',
					start_year: '',
					end_day: '',
					end_month: '',
					end_year: ''
				},
				departure: {
					start_day: '',
					start_month: '',
					start_year: '',
					end_day: '',
					end_month: '',
					end_year: ''
				},
				upload: {
					start_day: '',
					start_month: '',
					start_year: '',
					end_day: '',
					end_month: '',
					end_year: ''
				}
			},
			form2: {
				nbByPage: 50,
				sortBy: 'client',
				with_stay: true,
				without_stay: true,
				with_user: true,
				without_user: true,
				on_site: false
			},
			form3: {
				firstname: "",
				lastname: "",
				arrival: "",
				departure: "",
				email: "",
				phone: "",
				file: null
			},
			pages: null,
			list: null,
			page: 1
		}
		this.length_max = 50
		this.props.updateNavbarTitle('Fiches clients')
	}

	componentDidMount() {
		this.fetchAll()
		this.loadConfigPms()
	}
	
	componentDidUpdate(prevProps, prevState) {
		const elems = document.querySelectorAll('select')
		M.FormSelect.init(elems)
		const input = document.querySelector('input')
		if (input)
		{
			const offset = input.offsetWidth - 30
			const carets = document.getElementsByClassName('caret')
			for (let i = 0; i < carets.length; i++)
				carets[i].style.marginLeft = offset + "px"
		}
		if (prevState.clients !== this.state.clients)
			this.buildFilteredList()
		if (prevState.filtered_clients !== this.state.filtered_clients)
		{
			this.buildList()
			this.buildPages()
		}
		if (prevState.form2.nbByPage !== this.state.form2.nbByPage)
		{
			this.buildList()
			this.buildPages()
		}
		if (prevState.form2.sortBy !== this.state.form2.sortBy)
			this.buildList()
		if (prevState.page !== this.state.page)
			this.buildList()
		if ((prevState.form2.with_stay !== this.state.form2.with_stay) ||
			(prevState.form2.without_stay !== this.state.form2.without_stay) ||
			(prevState.form2.with_user !== this.state.form2.with_user) ||
			(prevState.form2.without_user !== this.state.form2.without_user) ||
			(prevState.form2.on_site !== this.state.form2.on_site))
			this.buildFilteredList()
		if (prevProps.subscriptions !== this.props.subscriptions)
			this.loadNotifications(true)
		if (prevProps.config_pms !== this.props.config_pms)
			this.loadConfigPms(true)
	}

	fetchAll = () => {
		this.loadNotifications()
	}

	loadNotifications = (fromProps = false) => {
		if (fromProps === true)
			this.setState({ subscriptions: this.props.subscriptions})
		else
		{
			this.props.fetchNotificationSubscriptions()
			.then(notifications => {
				this.fetchUsers(notifications)
			})
			.catch(err => console.log("could not fetch notification subcriptions", err))
		}
	}

	loadConfigPms = (fromProps = false) => {
		if (fromProps === true)
			this.setState({ config_pms: this.props.config_pms})
		else
		{
			this.props.fetchConfigPms()
			.then(config_pms => {
				this.setState({ config_pms: config_pms.values })
			})
			.catch(err => console.log("can not fetch config pms", err))
		}
	}

	fetchUsers = (notifications) => {
		fetch(connexion.connect.url + 'webapp/users/list')
		.then(res => res.json())
		.then(users => {
			for (let i = 0; i < users.length; i++)
				users[i].notification = false
			for (let i = 0; i < notifications.length; i++)
			{
				if (!notifications[i].user)
					continue
				for (let j = 0; j < users.length; j++)
				{
					if (String(notifications[i].user) === String(users[j]._id))
					{
						users[j].notification = true
						break
					}
				}
			}
			this.fetchStays(users)
		})
		.catch(err => console.log(err))
	}

	fetchStays = (users) => {
		const form = this.state.form1
		if (form.location !== '' ||
			(form.arrival.start_day !== '' && form.arrival.start_month !== '' && form.arrival.start_year !== '') ||
			(form.arrival.end_day !== '' && form.arrival.end_month !== '' && form.arrival.end_year !== '') ||
			(form.departure.start_day !== '' && form.departure.start_month !== '' && form.departure.start_year !== '') ||
			(form.departure.end_day !== '' && form.departure.end_month !== '' && form.departure.end_year !== ''))
		{
			let after_arrival = ''
			let before_arrival = ''
			let after_departure = ''
			let before_departure = ''
			if (form.arrival.start_day !== '' && form.arrival.start_month !== '' && form.arrival.start_year !== '')
				after_arrival = parseTimestamp(form.arrival.start_day, form.arrival.start_month, form.arrival.start_year)
			if (form.arrival.end_day !== '' && form.arrival.end_month !== '' && form.arrival.end_year !== '')
				before_arrival = parseTimestamp(form.arrival.end_day, form.arrival.end_month, form.arrival.end_year)
			if (form.departure.start_day !== '' && form.departure.start_month !== '' && form.departure.start_year !== '')
				after_departure = parseTimestamp(form.departure.start_day, form.departure.start_month, form.departure.start_year)
			if (form.departure.end_day !== '' && form.departure.end_month !== '' && form.departure.end_year !== '')
				before_departure = parseTimestamp(form.departure.end_day, form.departure.end_month, form.departure.end_year)
			fetch(connexion.connect.url + 'dashboard/stays/search', {
				method: 'POST',
				body: JSON.stringify({
					client_number: '',
					stay_number: '',
					location: form.location,
					after_arrival: after_arrival,
					before_arrival: before_arrival,
					after_departure: after_departure,
					before_departure: before_departure
				})
			})
			.then(res => res.json())
			.then(stays => {
				let client_ids = []
				for (let i = 0; i < stays.length; i++)
					client_ids.push(Number(stays[i].client_number))
				this.fetchClients(users, stays, client_ids)
			})
			.catch(err => console.log(err))
		}
		else
		{
			fetch(connexion.connect.url + 'dashboard/stays/list')
			.then(res => res.json())
			.then(stays => {
				this.fetchClients(users, stays)
			})
			.catch(err => console.log(err))
		}
	}
	
	fetchClients = (users, stays, client_ids) => {
		const form = this.state.form1
		if (form.client_number !== '' || form.lastname !== '' || form.firstname !== '' || form.email !== '' || form.phone !== '' || client_ids !== undefined ||
			form.client_type !== '' || (form.upload.start_day !== '' && form.upload.start_month !== '' && form.upload.start_year !== '') ||
			(form.upload.end_day !== '' && form.upload.end_month !== '' && form.upload.end_year !== ''))
		{
			let after_upload = ''
			let before_upload = ''
			if (form.upload.start_day !== '' && form.upload.start_month !== '' && form.upload.start_year !== '')
				after_upload = parseTimestamp(form.upload.start_day, form.upload.start_month, form.upload.start_year)
			if (form.upload.end_day !== '' && form.upload.end_month !== '' && form.upload.end_year !== '')
				before_upload = parseTimestamp(form.upload.end_day, form.upload.end_month, form.upload.end_year)
			fetch(connexion.connect.url + 'dashboard/clients/search', {
				method: 'POST',
				body: JSON.stringify({
					client_number: form.client_number,
					client_ids: client_ids,
					lastname: form.lastname,
					firstname: form.firstname,
					email: form.email,
					phone: form.phone,
					code: '',
					client_type: form.client_type,
					after: after_upload,
					before: before_upload
				})
			})
			.then(res => res.json())
			.then(clients => {
				for (let j = 0; j < clients.length; j++)
				{
					clients[j].checked = false
					if (clients[j].stays === undefined)
						clients[j].stays = []
					if (clients[j].users === undefined)
						clients[j].users = []
					for (let i = 0; i < stays.length; i++)
					{
						if (stays[i].client_number === clients[j].client_number)
							clients[j].stays.push(stays[i])
					}
					for (let i = 0; i < users.length; i++)
					{
						if (users[i].client_number === clients[j].client_number)
							clients[j].users.push(users[i])
					}
				}
				this.setState({ page: 1, clients: clients, filtered_clients: clients, stays: stays })
			})
			.catch(err => console.log(err))
		}
		else
		{
			fetch(connexion.connect.url + 'dashboard/clients/list')
			.then(res => res.json())
			.then(clients => {
				for (let j = 0; j < clients.length; j++)
				{
					clients[j].checked = false
					if (clients[j].stays === undefined)
						clients[j].stays = []
					if (clients[j].users === undefined)
						clients[j].users = []
					for (let i = 0; i < stays.length; i++)
					{
						if (stays[i].client_number === clients[j].client_number)
							clients[j].stays.push(stays[i])
					}
					for (let i = 0; i < users.length; i++)
					{
						if (users[i].client_number === clients[j].client_number)
							clients[j].users.push(users[i])
					}
				}
				this.setState({ page: 1, clients: clients, filtered_clients: clients, stays: stays })
			})
			.catch(err => console.log(err))
		}
	}

	buildFilteredList = () => {
		const clients = this.state.clients
		if (clients !== null)
		{
			let filtered_clients = []
			const with_stay = this.state.form2.with_stay
			const without_stay = this.state.form2.without_stay
			const with_user = this.state.form2.with_user
			const without_user = this.state.form2.without_user
			const on_site = this.state.form2.on_site
			const now = tools.getTimestamp()
			for (let i = 0; i < clients.length; i++)
			{
				if ((with_stay === false && clients[i].stays.length > 0) ||
					(without_stay === false && clients[i].stays.length === 0) ||
					(with_user === false && clients[i].users.length > 0) ||
					(without_user === false && clients[i].users.length === 0) ||
					(on_site === true && clients[i].stays.length === 0))
					continue
				if (on_site === true)
				{
					let found = false
					for (let j = 0; j < clients[i].stays.length; j++)
					{
						if (clients[i].stays[j].arrival_time < now && clients[i].stays[j].departure_time > now)
						{
							found = clients[i].stays[j]
							break
						}
					}
					if (found === false)
						continue
				}
				filtered_clients.push(clients[i])
			}
			this.setState({ filtered_clients: filtered_clients })
		}
	}

	buildList = () => {
		if (this.state.filtered_clients !== null)
		{
			let clients = this.state.filtered_clients
			let stays = this.state.stays
			if (this.state.form2.sortBy === 'client_asc' && clients.length > 0)
			{
				clients.sort((a, b) => {
					return a.client_number - b.client_number
				})
			}
			else if (this.state.form2.sortBy === 'client_desc' && clients.length > 0)
			{
				clients.sort((a, b) => {
					return b.client_number - a.client_number
				})
			}
			else if (this.state.form2.sortBy === 'edited_time_asc' && clients.length > 0)
			{
				clients = clients.map((client) => {
					client.time = (client.edited_time === null) ? client.created_time : client.edited_time
					return client
				})
				clients.sort((a, b) => {
					return a.time - b.time
				})
			}
			else if (this.state.form2.sortBy === 'edited_time_desc' && clients.length > 0)
			{
				clients = clients.map((client) => {
					client.time = (client.edited_time === null) ? client.created_time : client.edited_time
					return client
				})
				clients.sort((a, b) => {
					return b.time - a.time
				})
			}
			else if (this.state.form2.sortBy === 'arrival_time_asc' ||
					this.state.form2.sortBy === 'arrival_time_desc' ||
					this.state.form2.sortBy === 'departure_time_asc' ||
					this.state.form2.sortBy === 'departure_time_desc' ||
					this.state.form2.sortBy === 'location_asc' ||
					this.state.form2.sortBy === 'location_desc')
			{
				clients = []
				if (this.state.form2.sortBy === 'arrival_time_asc' && stays.length > 0)
				{
					stays.sort((a, b) => {
						return a.arrival_time - b.arrival_time
					})
				}
				else if (this.state.form2.sortBy === 'arrival_time_desc' && stays.length > 0)
				{
					stays.sort((a, b) => {
						return b.arrival_time - a.arrival_time
					})
				}
				else if (this.state.form2.sortBy === 'departure_time_asc' && stays.length > 0)
				{
					stays.sort((a, b) => {
						return a.departure_time - b.departure_time
					})
				}
				else if (this.state.form2.sortBy === 'departure_time_desc' && stays.length > 0)
				{
					stays.sort((a, b) => {
						return b.departure_time - a.departure_time
					})
				}
				else if (this.state.form2.sortBy === 'location_asc' && stays.length > 0)
				{
					stays.sort((a, b) => {
						return a.location.toLowerCase().localeCompare(b.location.toLowerCase(), undefined, { numeric: true })
					})
				}
				else if (this.state.form2.sortBy === 'location_desc' && stays.length > 0)
				{
					stays.sort((a, b) => {
						return b.location.toLowerCase().localeCompare(a.location.toLowerCase(), undefined, { numeric: true })
					})
				}
				let inserted_clients = []
				for (let j = 0; j < stays.length; j++)
				{
					for (let i = 0; i < this.state.filtered_clients.length; i++)
					{
						if (this.state.filtered_clients[i].client_number === stays[j].client_number && inserted_clients.includes(stays[j].client_number) === false)
						{
							clients.push(this.state.filtered_clients[i])
							inserted_clients.push(stays[j].client_number)
							break
						}
					}
				}
				for (let i = 0; i < this.state.filtered_clients.length; i++)
				{
					if (this.state.filtered_clients[i].stays.length === 0)
						clients.push(this.state.filtered_clients[i])
				}
			}
			let list = []
			let c = 0
			for (let i = this.state.form2.nbByPage * (this.state.page - 1); i < clients.length && c < this.state.form2.nbByPage; i++)
			{
				list.push(clients[i])
				c++
			}
			this.setState({ list: list })
		}
	}

	buildPages = () => {
		if (this.state.filtered_clients !== null)
		{
			let c = 1
			let pages = []
			for (let i = 0; i < this.state.filtered_clients.length; i += this.state.form2.nbByPage)
			{
				pages.push(c)
				c++
			}
			this.setState({ pages: pages })
		}
	}

	handleLink = (id) => {
		this.getClient(id)
		this.setState({ id: id })
	}

	handlePage = (page) => {
		if (Number.isInteger(page))
			this.setState({ page: page })
	}

	handleClient = (event) => {
		const form1 = {
			...this.state.form1,
			client_number: event.target.value
		}
		this.setState({ form1: form1 })
	}

	handleLastname = (event) => {
		const form1 = {
			...this.state.form1,
			lastname: event.target.value
		}
		this.setState({ form1: form1 })
	}

	handleFirstname = (event) => {
		const form1 = {
			...this.state.form1,
			firstname: event.target.value
		}
		this.setState({ form1: form1 })
	}

	handleEmail = (event) => {
		const form1 = {
			...this.state.form1,
			email: event.target.value
		}
		this.setState({ form1: form1 })
	}

	handlePhone = (event) => {
		const form1 = {
			...this.state.form1,
			phone: event.target.value
		}
		this.setState({ form1: form1 })
	}

	handleLocation = (event) => {
		const form1 = {
			...this.state.form1,
			location: event.target.value
		}
		this.setState({ form1: form1 })
	}

	handleClientType = (event) => {
		const form1 = {
			...this.state.form1,
			client_type: event.target.value
		}
		this.setState({ form1: form1 })
	}

	ArrivalStartDay = (event) => {
		const form1 = {
			...this.state.form1,
			arrival: {
				...this.state.form1.arrival,
				start_day: event.target.value,
			}
		}
		this.setState({ form1: form1 })
	}

	ArrivalStartMonth = (event) => {
		const form1 = {
			...this.state.form1,
			arrival: {
				...this.state.form1.arrival,
				start_month: event.target.value,
			}
		}
		this.setState({ form1: form1 })
	}

	ArrivalStartYear = (event) => {
		const form1 = {
			...this.state.form1,
			arrival: {
				...this.state.form1.arrival,
				start_year: event.target.value,
			}
		}
		this.setState({ form1: form1 })
	}

	ArrivalEndDay = (event) => {
		const form1 = {
			...this.state.form1,
			arrival: {
				...this.state.form1.arrival,
				end_day: event.target.value,
			}
		}
		this.setState({ form1: form1 })
	}

	ArrivalEndMonth = (event) => {
		const form1 = {
			...this.state.form1,
			arrival: {
				...this.state.form1.arrival,
				end_month: event.target.value,
			}
		}
		this.setState({ form1: form1 })
	}

	ArrivalEndYear = (event) => {
		const form1 = {
			...this.state.form1,
			arrival: {
				...this.state.form1.arrival,
				end_year: event.target.value,
			}
		}
		this.setState({ form1: form1 })
	}

	DepartureStartDay = (event) => {
		const form1 = {
			...this.state.form1,
			departure: {
				...this.state.form1.departure,
				start_day: event.target.value,
			}
		}
		this.setState({ form1: form1 })
	}

	DepartureStartMonth = (event) => {
		const form1 = {
			...this.state.form1,
			departure: {
				...this.state.form1.departure,
				start_month: event.target.value,
			}
		}
		this.setState({ form1: form1 })
	}

	DepartureStartYear = (event) => {
		const form1 = {
			...this.state.form1,
			departure: {
				...this.state.form1.departure,
				start_year: event.target.value,
			}
		}
		this.setState({ form1: form1 })
	}

	DepartureEndDay = (event) => {
		const form1 = {
			...this.state.form1,
			departure: {
				...this.state.form1.departure,
				end_day: event.target.value,
			}
		}
		this.setState({ form1: form1 })
	}

	DepartureEndMonth = (event) => {
		const form1 = {
			...this.state.form1,
			departure: {
				...this.state.form1.departure,
				end_month: event.target.value,
			}
		}
		this.setState({ form1: form1 })
	}

	DepartureEndYear = (event) => {
		const form1 = {
			...this.state.form1,
			departure: {
				...this.state.form1.departure,
				end_year: event.target.value,
			}
		}
		this.setState({ form1: form1 })
	}

	UploadStartDay = (event) => {
		const form1 = {
			...this.state.form1,
			upload: {
				...this.state.form1.upload,
				start_day: event.target.value,
			}
		}
		this.setState({ form1: form1 })
	}

	UploadStartMonth = (event) => {
		const form1 = {
			...this.state.form1,
			upload: {
				...this.state.form1.upload,
				start_month: event.target.value,
			}
		}
		this.setState({ form1: form1 })
	}

	UploadStartYear = (event) => {
		const form1 = {
			...this.state.form1,
			upload: {
				...this.state.form1.upload,
				start_year: event.target.value,
			}
		}
		this.setState({ form1: form1 })
	}

	UploadEndDay = (event) => {
		const form1 = {
			...this.state.form1,
			upload: {
				...this.state.form1.upload,
				end_day: event.target.value,
			}
		}
		this.setState({ form1: form1 })
	}

	UploadEndMonth = (event) => {
		const form1 = {
			...this.state.form1,
			upload: {
				...this.state.form1.upload,
				end_month: event.target.value,
			}
		}
		this.setState({ form1: form1 })
	}

	UploadEndYear = (event) => {
		const form1 = {
			...this.state.form1,
			upload: {
				...this.state.form1.upload,
				end_year: event.target.value,
			}
		}
		this.setState({ form1: form1 })
	}

	handleSubmit = (event) => {
		event.preventDefault()
		this.fetchAll()
	}

	displayPages = (pages) => {
		let first = (this.state.page > 3) ? false : true
		let end = (this.state.page < pages.length - 1) ? false : true
		return pages.map((nb) => {
			if ((this.state.page > 3 && nb === 1) ||
				(this.state.page < pages.length - 2 && nb === pages.length) ||
				(nb < this.state.page + 2 && nb > this.state.page - 2) ||
				(this.state.page < 4 && nb < 4) ||
				(this.state.page > pages.length - 3 && nb > pages.length - 3))
			{
				return (
					<p onClick={this.handlePage.bind(this, nb)} key={nb}>
						{nb}
					</p>
				)
			}
			else if (nb > 1 && first === false)
			{
				first = true
				return (<p key={nb}>...</p>)
			}
			else if (nb > this.state.page + 1 && nb < pages.length && end === false)
			{
				end = true
				return (<p key={nb}>...</p>)
			}
			return null
		})
	}

	resetFilters = () => {
		const form1 = {
			client_number: '',
			lastname: '',
			firstname: '',
			email: '',
			phone: '',
			location: '',
			client_type: '',
			arrival: {
				start_day: '',
				start_month: '',
				start_year: '',
				end_day: '',
				end_month: '',
				end_year: ''
			},
			departure: {
				start_day: '',
				start_month: '',
				start_year: '',
				end_day: '',
				end_month: '',
				end_year: ''
			},
			upload: {
				start_day: '',
				start_month: '',
				start_year: '',
				end_day: '',
				end_month: '',
				end_year: ''
			}
		}
		this.setState({ form1: form1 })
	}

	handleNbByPage = (event) => {
		const form2 = {
			...this.state.form2,
			nbByPage: Number(event.target.value)
		}
		this.setState({ page: 1, form2: form2 })
	}

	handleSortBy = (event) => {
		const form2 = {
			...this.state.form2,
			sortBy: event.target.value
		}
		this.setState({ form2: form2 })
	}

	handleWithStay = () => {
		const form2 = {
			...this.state.form2,
			with_stay: (this.state.form2.with_stay === true) ? false : true
		}
		if (form2.with_stay === false)
			form2.without_stay = true
		this.setState({ form2: form2 })
	}

	handleWithoutStay = () => {
		const form2 = {
			...this.state.form2,
			without_stay: (this.state.form2.without_stay === true) ? false : true
		}
		if (form2.without_stay === false)
			form2.with_stay = true
		this.setState({ form2: form2 })
	}

	handleWithUser = () => {
		const form2 = {
			...this.state.form2,
			with_user: (this.state.form2.with_user === true) ? false : true
		}
		if (form2.with_user === false)
			form2.without_user = true
		this.setState({ form2: form2 })
	}

	handleWithoutUser = () => {
		const form2 = {
			...this.state.form2,
			without_user: (this.state.form2.without_user === true) ? false : true
		}
		if (form2.without_user === false)
			form2.with_user = true
		this.setState({ form2: form2 })
	}

	handleOnSite = () => {
		const form2 = {
			...this.state.form2,
			on_site: (this.state.form2.on_site === true) ? false : true
		}
		if (form2.on_site === false)
			form2.with_user = true
		this.setState({ form2: form2 })
	}

	handleCheck = (client_number) => {
		const clients = this.state.clients
		for (let i = 0; i < clients.length; i++)
		{
			if (client_number === clients[i].client_number)
			{
				clients[i].checked = (clients[i].checked === true) ? false : true
				break
			}
		}
		this.setState({ clients: clients })
	}

	selectAll = () => {
		const clients = this.state.clients
		for (let i = 0; i < this.state.list.length; i++)
		{
			for (let j = 0; j < clients.length; j++)
			{
				if (this.state.list[i].client_number === clients[j].client_number)
				{
					clients[j].checked = true
					break
				}
			}
		}
		this.setState({ clients: clients })
	}

	unselectAll = () => {
		const clients = this.state.clients
		for (let i = 0; i < this.state.list.length; i++)
		{
			for (let j = 0; j < clients.length; j++)
			{
				if (this.state.list[i].client_number === clients[j].client_number)
				{
					clients[j].checked = false
					break
				}
			}
		}
		this.setState({ clients: clients })
	}

	selectAllPages = () => {
		const filtered_clients = this.state.filtered_clients
		for (let i = 0; i < filtered_clients.length; i++)
		{
			filtered_clients[i].checked = true
		}
		this.setState({ filtered_clients: filtered_clients })
	}

	unselectAllPages = () => {
		const filtered_clients = this.state.filtered_clients
		for (let i = 0; i < filtered_clients.length; i++)
		{
			filtered_clients[i].checked = false
		}
		this.setState({ filtered_clients: filtered_clients })
	}

	/* MODAL KOBAN MES TRAVELS */

	compareStrings = (param, chevron) => {
		let modal_participants = this.state.modal_participants
		if (chevron === "up")
		{
			modal_participants.sort((a, b) => {
				let compare_a = a[param]
				let compare_b = b[param]
				if (param.indexOf('.') !== -1)
				{
					const params = param.split('.')
					compare_a = a[params[0]][params[1]]
					compare_b = b[params[0]][params[1]]
				}
				if (!compare_a && !compare_b)
					return 0
				else if (!compare_a && compare_b)
					return 1
				else if (compare_a && !compare_b)
					return -1
				else if (compare_a.localeCompare)
					return compare_a.localeCompare(compare_b) * -1
				else
					return (compare_a - compare_b) * -1
			})
		}
		else if (chevron === "down")
		{
			modal_participants.sort((a, b) => {
				let compare_a = a[param]
				let compare_b = b[param]
				if (param.indexOf('.') !== -1)
				{
					const params = param.split('.')
					compare_a = a[params[0]][params[1]]
					compare_b = b[params[0]][params[1]]
				}
				if (!compare_a && !compare_b)
					return 0
				else if (!compare_a && compare_b)
					return -1
				else if (compare_a && !compare_b)
					return 1
				else if (compare_a.localeCompare)
					return compare_a.localeCompare(compare_b)
				else
					return compare_a - compare_b
			})
		}
		return modal_participants
	}

	compareNumbers = (param, chevron) => {
		let modal_participants = this.state.modal_participants
		if (chevron === "up")
		{
			modal_participants.sort((a, b) => {
				let compare_a = a[param]
				let compare_b = b[param]
				if (param.indexOf('.') !== -1)
				{
					const params = param.split('.')
					compare_a = a[params[0]][params[1]]
					compare_b = b[params[0]][params[1]]
				}
				return (compare_a - compare_b) * -1
			})
		}
		else if (chevron === "down")
		{
			modal_participants.sort((a, b) => {
				let compare_a = a[param]
				let compare_b = b[param]
				if (param.indexOf('.') !== -1)
				{
					const params = param.split('.')
					compare_a = a[params[0]][params[1]]
					compare_b = b[params[0]][params[1]]
				}
				return compare_a - compare_b
			})
		}
		return modal_participants
	}

	handleChevron = (param) => {
		const sort = {
			...this.state.sort
		}
		let index = param.indexOf('.')
		if (index !== -1)
		{
			index++
			param = param.substring(index)
		}
		console.log('param', param)
		const order = (sort[param] !== "down") ? "down" : "up"
		sort[param] = order
		this.setState({ sort: sort })
		return order
	}

	filter = (param) => {
		let modal_participants = this.state.modal_participants
		const chevron = this.handleChevron(param)
		if (param === "stay_number" || param === "created_time")
			modal_participants = this.compareNumbers(param, chevron)
		else
			modal_participants = this.compareStrings(param, chevron)
		this.setState({ modal_participants: modal_participants, sortStatus: chevron })
	}

	compareListingKoban = (client_number, stays, users) => {
		// console.log("afficher roomlist", client_number, stays, users)
		let participants = []
		for (let i = 0; i < stays.length; i++)
		{
			const stay = stays[i]
			if (stay.participants && stay.participants.length > 0)
			{
				for (let j = 0; j < stay.participants.length; j++)
				{
					participants.push({
						stay_number: stay.stay_number,
						lastname: stay.participants[j].lastname,
						firstname: stay.participants[j].firstname,
						email: stay.participants[j].email.trim().toLowerCase(),
						location: stay.location + " - " + stay.location_category,
						download: false,
						created_date: null,
						created_time: null
					})
				}
			}
		}
		for (let i = 0; i < participants.length; i++)
		{
			for (let j = 0; j < users.length; j++)
			{
				if (participants[i].email === users[j].email)
				{
					participants[i].download = true
					participants[i].created_date = parseDate(users[j].created_time, true)
					participants[i].created_time = users[j].created_time
					break
				}
			}
		}
		console.log("participants", participants)
		this.setState({ open_modal: true, modal_participants: participants })
	}

	closeModal = () => {
		this.setState({ open_modal: false, modal_participants: null })
	}

	/* FIN MODAL MES TRAVELS */

	render() {
		const list = this.state.list
		const permissions = this.props.user.permissions
		if (!permissions || !permissions.users || permissions.users === 0)
			return (<Redirect to="/" />)
		let chevronStatus = <MinusIcon className="h-5 w-5" aria-hidden="true" />
		if (this.state.sort.download === "up")
			chevronStatus = <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
		else if (this.state.sort.download === "down")
			chevronStatus = <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
		let chevronLastname = <MinusIcon className="h-5 w-5" aria-hidden="true" />
		if (this.state.sort.lastname === "up")
			chevronLastname = <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
		else if (this.state.sort.lastname === "down")
			chevronLastname = <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
		let chevronFirstname = <MinusIcon className="h-5 w-5" aria-hidden="true" />
		if (this.state.sort.firstname === "up")
			chevronFirstname = <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
		else if (this.state.sort.firstname === "down")
			chevronFirstname = <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
		let chevronLocation = <MinusIcon className="h-5 w-5" aria-hidden="true" />
		if (this.state.sort.location === "up")
			chevronLocation = <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
		else if (this.state.sort.location === "down")
			chevronLocation = <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
		let chevronEmail = <MinusIcon className="h-5 w-5" aria-hidden="true" />
		if (this.state.sort.email === "up")
			chevronEmail = <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
		else if (this.state.sort.email === "down")
			chevronEmail = <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
		let chevronCreatedTime = <MinusIcon className="h-5 w-5" aria-hidden="true" />
		if (this.state.sort.created_time === "up")
			chevronCreatedTime = <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
		else if (this.state.sort.created_time === "down")
			chevronCreatedTime = <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
		// let chevronStay = <MinusIcon className="h-5 w-5" aria-hidden="true" />
		// if (this.state.sort.stay_number === "up")
		// 	chevronStay = <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
		// else if (this.state.sort.stay_number === "down")
		// 	chevronStay = <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
		if (list === null)
		{
			return (
				<div className="loader">
					<Loader />
				</div>
			)
		}
		else
		{
			let days = []
			for (let i = 1; i <= 31; i++)
			{
				let text = i
				if (text < 10)
					text = '0' + text
				days[i - 1] = {
					value: i,
					text: text
				}
			}
			let months = []
			for (let i = 1; i <= 12; i++)
			{
				let text = i
				if (text < 10)
					text = '0' + text
				months[i - 1] = {
					value: i - 1,
					text: text
				}
			}
			let i = 0
			let years = []
			const actual_year = new Date().getFullYear()
			for (let year = 2019; year <= actual_year; year++)
			{
				years[i] = {
					value: year
				}
				i++
			}
			let nbByPages = [5, 10, 25, 50, 100, 200, 500]
			let sortBy = [
				{
					value: 'client_asc',
					text: 'N° Client (croissant)'
				},
				{
					value: 'client_desc',
					text: 'N° Client (décroissant)'
				},
				{
					value: 'edited_time_asc',
					text: 'Date de mise à jour (croissant)'
				},
				{
					value: 'edited_time_desc',
					text: 'Date de mise à jour (décroissant)'
				},
				{
					value: 'arrival_time_asc',
					text: 'Date d\'arrivée (croissant)',
				},
				{
					value: 'arrival_time_desc',
					text: 'Date d\'arrivée (décroissant)',
				},
				{
					value: 'departure_time_asc',
					text: 'Date de départ (croissant)'
				},
				{
					value: 'departure_time_desc',
					text: 'Date de départ (décroissant)'
				},
				{
					value: 'location_asc',
					text: 'Emplacement (croissant)'
				},
				{
					value: 'location_desc',
					text: 'Emplacement (décroissant)'
				}
			]
			let client_k = -1
			const search = 'Liste de toutes les fiches clients'
			let allPages = null
			if (this.state.filtered_clients.length > this.state.form2.nbByPage)
			{
				allPages = (
					<span>
						<button className="waves-effect waves-light btn-small margin-left" onClick={this.selectAllPages}>Sélectionner toutes les pages</button>
						<button className="waves-effect waves-light btn-small margin-left red lighten-1" onClick={this.unselectAllPages}>Désélectionner toutes les pages</button>
					</span>
				)
			}
			let select_clients = []
			for (let i = 0; i < this.state.clients.length; i++)
			{
				if (this.state.clients[i].checked === true)
					select_clients.push(this.state.clients[i].client_number)
			}
			let nb_stays = 0
			let nb_users = 0
			let nb_notifs = 0
			for (let i = 0; i < this.state.filtered_clients.length; i++)
			{
				nb_stays += this.state.filtered_clients[i].stays.length
				nb_users += this.state.filtered_clients[i].users.length
				for (let j = 0; j < this.state.filtered_clients[i].users.length; j++)
					if (this.state.filtered_clients[i].users[j].notification === true)
						nb_notifs++
			}
			let importForm = null
			if (this.state.config_pms.name === "koban")
			{
				importForm = (
					<ImportKoban
						clients={this.state.clients}
						insertRoomlist={this.props.insertRoomlist}
					/>
				)
			}
			else if (this.state.config_pms.name === "amenitiz")
			{
				importForm = (
					<ImportAmenitiz
						insertRoomlist={this.props.insertRoomlist}
					/>
				)
			}
			let count_jsx = null
			if (this.state.modal_participants)
			{
				count_jsx = (
					<tr>
						<td colspan="5">
							Total : {this.state.modal_participants.length}
						</td>
					</tr>
				)
			}
			const modalJSX = (
				<Transition appear show={this.state.open_modal} as={Fragment}>
					<Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={this.closeModal}>
						<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0"
								enterTo="opacity-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100"
								leaveTo="opacity-0"
							>
								<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
							</Transition.Child>
							<span
								className="hidden sm:inline-block sm:align-middle sm:h-screen"
								aria-hidden="true"
							>
								&#8203;
							</span>
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 scale-95"
								enterTo="opacity-100 scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 scale-100"
								leaveTo="opacity-0 scale-95"
							>
								<div className="overflow-scroll inline-block align-bottom bg-white rounded-lg text-left shadow-xl transform transition-all sm:align-middle lg:w-full sm:w-full lg:h-4/5-screen">
									<table className="table-auto divide-y divide-gray-200">
										<thead className="bg-white">
											<tr>
												<th scope="col" className="px-6 py-3 text-left text-base font-medium text-gray-500 uppercase tracking-wider">
													<div onClick={this.filter.bind(this, 'lastname')} className="group inline-flex">
														Nom
														<span className="ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300">
															{chevronLastname}
														</span>
													</div>
												</th>
												<th scope="col" className="px-6 py-3 text-left text-base font-medium text-gray-500 uppercase tracking-wider">
													<div onClick={this.filter.bind(this, 'firstname')} className="group inline-flex">
														Prénom
														<span className="ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300">
															{chevronFirstname}
														</span>
													</div>
												</th>
												<th scope="col" className="px-6 py-3 text-left text-base font-medium text-gray-500 uppercase tracking-wider">
													<div onClick={this.filter.bind(this, 'email')} className="group inline-flex">
														E-mail
														<span className="ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300">
															{chevronEmail}
														</span>
													</div>
												</th>
												<th scope="col" className="px-6 py-3 text-left text-base font-medium text-gray-500 uppercase tracking-wider">
													<div onClick={this.filter.bind(this, 'stay_number')} className="group inline-flex">
														N° Séjour
														<span className="ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300">
															{chevronLocation}
														</span>
													</div>
												</th>
												<th scope="col" className="px-6 py-3 text-left text-base font-medium text-gray-500 uppercase tracking-wider">
													<div onClick={this.filter.bind(this, 'location')} className="group inline-flex">
														Emplacement
														<span className="ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300">
															{chevronLocation}
														</span>
													</div>
												</th>
												<th scope="col" className="px-6 py-3 text-left text-base font-medium text-gray-500 uppercase tracking-wider">
													<div onClick={this.filter.bind(this, 'download')} className="group inline-flex">
														Téléchargé
														<span className="ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300">
															{chevronStatus}
														</span>
													</div>
												</th>
												<th scope="col" className="px-6 py-3 text-left text-base font-medium text-gray-500 uppercase tracking-wider">
													<div onClick={this.filter.bind(this, 'created_time')} className="group inline-flex">
														Création
														<span className="ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300">
															{chevronCreatedTime}
														</span>
													</div>
												</th>
											</tr>
										</thead>
										<tbody className="divide-y divide-gray-200">
											{this.state.modal_participants && this.state.modal_participants.map(({ lastname, firstname, email, location, download, stay_number, created_date }) => {
												let statusStyle = 'px-6 py-4 whitespace-nowrap text-base font-medium text-red-500'
												let status = null
												if (download === true)
												{
													status = "Oui"
													statusStyle = 'px-6 py-4 whitespace-nowrap text-base font-medium text-green-600'
												}
												else if (download === false)
												{
													status = "Non"
													statusStyle = 'px-6 py-4 whitespace-nowrap text-base font-medium text-gray-400'
												}
												return (
													<tr>
														<td>{lastname}</td>
														<td>{firstname}</td>
														<td>{email}</td>
														<td>{stay_number}</td>
														<td>{location}</td>
														<td className={statusStyle}>{status}</td>
														<td>{created_date}</td>
													</tr>
												)
											})}
											{count_jsx}
										</tbody>
									</table>
								</div>
							</Transition.Child>
						</div>
					</Dialog>				
				</Transition>
			)
			return (
				<div className="wrapper-content">
					{modalJSX}
					<div className="row">
						{importForm}
						<div className="module col s12">
							<h2 className="title-description">Fonctions de tri</h2>
							<form className="module-form">
								<p className="column-20">Trier par :</p>
								<div className="column-20">
									<select value={this.state.form2.sortBy} onChange={this.handleSortBy}>
										{sortBy.map((sort) => {
											return (<option value={sort.value} key={sort.value}>{sort.text}</option>)
										})}
									</select>
								</div>
								<div className="clear"></div>
								<p className="column-20">Nombre de résultats par page :</p>
								<div className="column-20">
									<select value={this.state.form2.nbByPage} onChange={this.handleNbByPage}>
										{nbByPages.map((nb) => {
											return (<option value={nb} key={nb}>{nb}</option>)
										})}
									</select>
								</div>
								<div className="clear"></div>
								<label>
									<p className="column-20">Avec séjours :</p>
									<div className="column-20">
										<input type="checkbox" checked={this.state.form2.with_stay} onChange={this.handleWithStay} /><span></span>
									</div>
								</label>
								<label>
									<p className="column-20">Sans séjours :</p>
									<div className="column-20">
										<input type="checkbox" checked={this.state.form2.without_stay} onChange={this.handleWithoutStay} /><span></span>
									</div>
								</label>
								<label>
									<p className="column-20">Avec utilisateurs :</p>
									<div className="column-20">
										<input type="checkbox" checked={this.state.form2.with_user} onChange={this.handleWithUser} /><span></span>
									</div>
								</label>
								<label>
									<p className="column-20">Sans utilisateurs :</p>
									<div className="column-20">
										<input type="checkbox" checked={this.state.form2.without_user} onChange={this.handleWithoutUser} /><span></span>
									</div>
								</label>
								<label>
									<p className="column-20">Séjours sur place uniquement :</p>
									<div className="column-20">
										<input type="checkbox" checked={this.state.form2.on_site} onChange={this.handleOnSite} /><span></span>
									</div>
								</label>
							</form>
						</div>
					</div>
					<div className="row">
						<div className="module col s12">
							<h2 className="title-description">Effectuer une recherche</h2>
							<form className="module-form" onSubmit={this.handleSubmit}>
								<p className="title-group">Filtres de recherche</p>
								<div className="form-group">
									<label className="short-label">
										<p className="column-20">Numéro client :</p>
										<input type="text" value={this.state.form1.client_number} onChange={this.handleClient} />
									</label>
									<label className="short-label">
										<p className="column-20">Nom :</p>
										<input type="text" value={this.state.form1.lastname} onChange={this.handleLastname} />
									</label>
									<label className="short-label">
										<p className="column-20">Prénom :</p>
										<input type="text" value={this.state.form1.firstname} onChange={this.handleFirstname} />
									</label>
									<label className="short-label">
										<p className="column-20">E-mail :</p>
										<input type="text" value={this.state.form1.email} onChange={this.handleEmail} />
									</label>
									<label className="short-label">
										<p className="column-20">Téléphone :</p>
										<input type="text" value={this.state.form1.phone} onChange={this.handlePhone} />
									</label>
									<label className="short-label">
										<p className="column-20">Emplacement :</p>
										<input type="text" value={this.state.form1.location} onChange={this.handleLocation} />
									</label>
									<label className="short-label">
										<p className="column-20">Type de client :</p>
										<select value={this.state.form1.client_type} onChange={this.handleClientType}>
											<option value=""></option>
											<option value="tourist">Client</option>
											<option value="resident">Résident</option>
										</select>
									</label>
								</div>
								<p className="title-group">Rechercher par date d'arrivée</p>
								<div className="form-group">
									<label>
										<p className="column-5">Après le :</p>
										<div className="column-10">
											<select value={this.state.form1.arrival.start_day} onChange={this.ArrivalStartDay}>
												<option value="">Jour</option>
												{days.map((day) => {
													return (<option value={day.value} key={day.value}>{day.text}</option>)
												})}
											</select>
										</div>
										<div className="sep"><p>/</p></div>
										<div className="column-10">
											<select value={this.state.form1.arrival.start_month} onChange={this.ArrivalStartMonth}>
												<option value="">Mois</option>
												{months.map((month) => {
													return (<option value={month.value} key={month.value}>{month.text}</option>)
												})}
											</select>
										</div>
										<div className="sep"><p>/</p></div>
										<div className="column-10 right60">
											<select value={this.state.form1.arrival.start_year} onChange={this.ArrivalStartYear}>
												<option value="">Année</option>
												{years.map((year) => {
													return (<option value={year.value} key={year.value}>{year.value}</option>)
												})}
											</select>
										</div>
										<div className="clear"></div>
										<p className="column-5">Avant le :</p>
										<div className="column-10">
											<select value={this.state.form1.arrival.end_day} onChange={this.ArrivalEndDay}>
												<option value="">Jour</option>
												{days.map((day) => {
													return (<option value={day.value} key={day.value}>{day.text}</option>)
												})}
											</select>
										</div>
										<div className="sep"><p>/</p></div>
										<div className="column-10">
											<select value={this.state.form1.arrival.end_month} onChange={this.ArrivalEndMonth}>
												<option value="">Mois</option>
												{months.map((month) => {
													return (<option value={month.value} key={month.value}>{month.text}</option>)
												})}
											</select>
										</div>
										<div className="sep"><p>/</p></div>
										<div className="column-10 right60">
											<select value={this.state.form1.arrival.end_year} onChange={this.ArrivalEndYear}>
												<option value="">Année</option>
												{years.map((year) => {
													return (<option value={year.value} key={year.value}>{year.value}</option>)
												})}
											</select>
										</div>
									</label>
									<div className="clear"></div>
								</div>
								<p className="title-group">Rechercher par date de départ</p>
								<div className="form-group">
									<label>
										<p className="column-5">Après le :</p>
										<div className="column-10">
											<select value={this.state.form1.departure.start_day} onChange={this.DepartureStartDay}>
												<option value="">Jour</option>
												{days.map((day) => {
													return (<option value={day.value} key={day.value}>{day.text}</option>)
												})}
											</select>
										</div>
										<div className="sep"><p>/</p></div>
										<div className="column-10">
											<select value={this.state.form1.departure.start_month} onChange={this.DepartureStartMonth}>
												<option value="">Mois</option>
												{months.map((month) => {
													return (<option value={month.value} key={month.value}>{month.text}</option>)
												})}
											</select>
										</div>
										<div className="sep"><p>/</p></div>
										<div className="column-10 right60">
											<select value={this.state.form1.departure.start_year} onChange={this.DepartureStartYear}>
												<option value="">Année</option>
												{years.map((year) => {
													return (<option value={year.value} key={year.value}>{year.value}</option>)
												})}
											</select>
										</div>
										<div className="clear"></div>
										<p className="column-5">Avant le :</p>
										<div className="column-10">
											<select value={this.state.form1.departure.end_day} onChange={this.DepartureEndDay}>
												<option value="">Jour</option>
												{days.map((day) => {
													return (<option value={day.value} key={day.value}>{day.text}</option>)
												})}
											</select>
										</div>
										<div className="sep"><p>/</p></div>
										<div className="column-10">
											<select value={this.state.form1.departure.end_month} onChange={this.DepartureEndMonth}>
												<option value="">Mois</option>
												{months.map((month) => {
													return (<option value={month.value} key={month.value}>{month.text}</option>)
												})}
											</select>
										</div>
										<div className="sep"><p>/</p></div>
										<div className="column-10 right60">
											<select value={this.state.form1.departure.end_year} onChange={this.DepartureEndYear}>
												<option value="">Année</option>
												{years.map((year) => {
													return (<option value={year.value} key={year.value}>{year.value}</option>)
												})}
											</select>
										</div>
									</label>
									<div className="clear"></div>
								</div>
								<p className="title-group">Rechercher par date de mise à jour</p>
								<div className="form-group">
									<label>
										<p className="column-5">Après le :</p>
										<div className="column-10">
											<select value={this.state.form1.upload.start_day} onChange={this.UploadStartDay}>
												<option value="">Jour</option>
												{days.map((day) => {
													return (<option value={day.value} key={day.value}>{day.text}</option>)
												})}
											</select>
										</div>
										<div className="sep"><p>/</p></div>
										<div className="column-10">
											<select value={this.state.form1.upload.start_month} onChange={this.UploadStartMonth}>
												<option value="">Mois</option>
												{months.map((month) => {
													return (<option value={month.value} key={month.value}>{month.text}</option>)
												})}
											</select>
										</div>
										<div className="sep"><p>/</p></div>
										<div className="column-10 right60">
											<select value={this.state.form1.upload.start_year} onChange={this.UploadStartYear}>
												<option value="">Année</option>
												{years.map((year) => {
													return (<option value={year.value} key={year.value}>{year.value}</option>)
												})}
											</select>
										</div>
										<div className="clear"></div>
										<p className="column-5">Avant le :</p>
										<div className="column-10">
											<select value={this.state.form1.upload.end_day} onChange={this.UploadEndDay}>
												<option value="">Jour</option>
												{days.map((day) => {
													return (<option value={day.value} key={day.value}>{day.text}</option>)
												})}
											</select>
										</div>
										<div className="sep"><p>/</p></div>
										<div className="column-10">
											<select value={this.state.form1.upload.end_month} onChange={this.UploadEndMonth}>
												<option value="">Mois</option>
												{months.map((month) => {
													return (<option value={month.value} key={month.value}>{month.text}</option>)
												})}
											</select>
										</div>
										<div className="sep"><p>/</p></div>
										<div className="column-10 right60">
											<select value={this.state.form1.upload.end_year} onChange={this.UploadEndYear}>
												<option value="">Année</option>
												{years.map((year) => {
													return (<option value={year.value} key={year.value}>{year.value}</option>)
												})}
											</select>
										</div>
									</label>
									<div className="clear"></div>
								</div>
								<div className="clear"></div>
								<button className="waves-effect waves-light btn-small" type="submit">Rechercher</button>
								<button className="waves-effect waves-light btn-small margin-left red lighten-1" type="submit" onClick={this.resetFilters}>Réinitialiser</button>
							</form>
						</div>
					</div>
					<div className="row">
						<div className="module col s12">
							<h2 className="title-description">{search}</h2>
							<p className="left">
								Résultats par page : {this.state.form2.nbByPage}<br />
								Nombre de d'utilisateurs : {nb_users}<br />
								Nombre de notifications activées : {nb_notifs}<br />
								Nombre de séjours : {nb_stays}<br />
								Nombre de fiches clients : {this.state.filtered_clients.length}<br />
								Page actuelle : {this.state.page}
							</p>
							<div className="pages-nb">
								{this.displayPages(this.state.pages)}
							</div>
							<div className="clear"></div>
							<div className="select-buttons">
								<button className="waves-effect waves-light btn-small" onClick={this.selectAll}>Tout sélectionner</button>
								<button className="waves-effect waves-light btn-small margin-left red lighten-1" onClick={this.unselectAll}>Tout désélectionner</button>
								{allPages}
							</div>
							<Link
								to={{
									pathname: '/administration/send-emails',
									clients_numbers: select_clients
								}}
							>
								<button className="waves-effect waves-light btn">Envoyer un mail</button>
							</Link>
							<Link
								to={{
									pathname: '/administration/notifications',
									clients_numbers: select_clients
								}}
							>
								<button className="waves-effect waves-light btn">Envoyer une notification</button>
							</Link>
							{list.map(({ activation_code, birthday, civility, client_number, created_time, edited_time, email1, email2, firstname, langage, lastname, phone1, phone2, phone3, stays, users, client_type, checked }) => {
								client_k++
								let created = parseDate(created_time)
								let edited = (edited_time) ? (<span>Éditée le {parseDate(edited_time)}</span>) : null
								phone1 = (phone1) ? (<span>{phone1}<br /></span>) : null
								phone2 = (phone2) ? (<span>{phone2}<br /></span>) : null
								email1 = (email1) ? (<span>{email1}<br /></span>) : null
								let staysJSX = null
								if (stays.length > 0)
								{
									let stay_k = -1
									staysJSX = (
										<span>
											<p className="subtitle">Liste des séjours :</p>
											<table className="infos">
												<thead>
													<tr>
														<td>N° Séjour</td>
														<td>Arrivée</td>
														<td>Départ</td>
														<td>Emplacement</td>
														<td>Hébergement</td>
														<td>Création</td>
														<td>Édition</td>
													</tr>
												</thead>
												<tbody>
													{stays.map(({ stay_number, arrival, departure, location, location_category, created_time, edited_time }) => {
														let s_created = parseDate(created_time)
														let s_edited = parseDate(edited_time)
														stay_k++
														return (
															<tr key={stay_k}>
																<td>{stay_number}</td>
																<td>{arrival}</td>
																<td>{departure}</td>
																<td>{location}</td>
																<td>{location_category}</td>
																<td>{s_created}</td>
																<td>{s_edited}</td>
															</tr>
														)
													})}
												</tbody>
											</table>
										</span>
									)
								}
								let usersJSX = null
								if (users.length > 0)
								{
									let user_k = -1
									usersJSX = (
										<span>
											<p className="subtitle">Liste des utilisateurs :</p>
											<table className="infos">
												<thead>
													<tr>
														<td>Notifications</td>
														<td>Nom</td>
														<td>Prénom</td>
														<td>E-mail</td>
														<td>Civilité</td>
														<td>Date de naissance</td>
														<td>Personne</td>
														<td>Création</td>
													</tr>
												</thead>
												<tbody>
													{users.map(({ birthday, civility, email, firstname, lastname, notification, user_type, participant_type, created_time }) => {
														let u_created = parseDate(created_time)
														// ipb
														if (civility === "female")
															civility = "Femme"
														else if (civility === "male")
															civility = "Homme"
														if (user_type === "participant")
														{
															if (participant_type === "mother")
																user_type = "Mère"
															else if (participant_type === "father")
																user_type = "Père"
															else
																user_type = "Accompagnant"
														}
														else if (user_type === "primary")
															user_type = "Candidat"
														// end ipb
														user_k++
														let notif_img = null
														if (notification === true)
															notif_img = (<img className="notification" src={bell} alt="Notification activée" />)
														return (
															<tr key={user_k}>
																<td>{notif_img}</td>
																<td>{lastname}</td>
																<td>{firstname}</td>
																<td>{email}</td>
																<td>{civility}</td>
																<td>{birthday}</td>
																<td>{user_type}</td>
																<td>{u_created}</td>
															</tr>
														)
													})}
												</tbody>
											</table>
										</span>
									)
								}
								let compare = null
								if (this.state.config_pms.name === "koban")
									compare = (<p><button className="waves-effect waves-light btn-small" onClick={this.compareListingKoban.bind(this, client_number, stays, users)}>Afficher la roomlist</button></p>)
								if (client_type === "tourist")
									client_type = "Client"
								else if (client_type === "resident")
									client_type = "Résident"
								return (
									<div className="object-2" key={client_k}>
										{compare}
										<span className="activation_code">Code : {activation_code}</span>
										<span className="client_number" title="N° Client">#{client_number}</span>
										<table className="infos">
											<thead>
												<tr>
													<td>Nom</td>
													<td>Prénom</td>
													<td>Civilité</td>
													<td>Date d'anniversaire</td>
													<td>Langue</td>
													<td>E-mails</td>
													<td>Téléphones</td>
													<td>Type</td>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td>{lastname}</td>
													<td>{firstname}</td>
													<td>{civility}</td>
													<td>{birthday}</td>
													<td>{langage}</td>
													<td>{email1}{email2}</td>
													<td>{phone1}{phone2}{phone3}</td>
													<td>{client_type}</td>
												</tr>
											</tbody>
										</table>
										<p>
											Fiche client créée le {created}<br />
											{edited}
										</p>
										{staysJSX}
										{usersJSX}
										<div className="user-checkbox">
											<span className="text">Sélectionner :</span>
											<label>
												<input type="checkbox" checked={checked} onChange={this.handleCheck.bind(this, client_number)} /><span></span>
											</label>
											<div className="clear"></div>
										</div>
									</div>
								)
							})}
							<div className="select-buttons">
								<button className="waves-effect waves-light btn-small" onClick={this.selectAll}>Tout sélectionner</button>
								<button className="waves-effect waves-light btn-small margin-left red lighten-1" onClick={this.unselectAll}>Tout désélectionner</button>
								{allPages}
							</div>
							<div className="clear"></div>
							<p className="left">
								Résultats par page : {this.state.form2.nbByPage}<br />
								Nombre de résultats : {this.state.filtered_clients.length}<br />
								Page actuelle : {this.state.page}
							</p>
							<div className="pages-nb">
								{this.displayPages(this.state.pages)}
							</div>
							<div className="clear"></div>
						</div>
					</div>
				</div>
			)
		}
	}
}

export default NewClients
import React, { Component } from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import CustomBuild from '@ckeditor/ckeditor5-build-classic'
import CKEditorUploadAdapterPlugin from './CKEditorUploadAdapterPlugin'

class CustomEditor extends Component {
	render() {
		let config = {}
		if (!this.props.toolbar)
		{
			config.toolbar = {
				items: [
					'bold',
					'italic',
					'specialCharacters',
					'|',
					'fontColor',
					'|',
					'bulletedList',
					'|',
					'undo',
					'redo'
				]
			}
		}
		else if (this.props.toolbar === "rubrics")
		{
			config.toolbar = {
				items: [
					'bold',
					'italic',
					'underline',
					'|',
					'link',
					'specialCharacters',
					'|',
					'alignment',
					'fontColor',
					'|',
					'bulletedList',
					'numberedList',
					'|',
					'undo',
					'redo'
				]
			}
		}
		else if (this.props.toolbar === "full")
		{
			config.toolbar = {
				items: [
					'heading',
					'|',
					'bold',
					'italic',
					'underline',
					'strikethrough',
					'specialCharacters',
					'|',
					'link',
					'imageInsert',
					// 'CKFinder',
					'|',
					'alignment',
					'fontColor',
					'fontBackgroundColor',
					'fontSize',
					'fontFamily',
					'|',
					'bulletedList',
					'numberedList',
					'indent',
					'outdent',
					'|',
					'subscript',
					'superscript',
					'|',
					'undo',
					'redo'
				]
			}
			config.extraPlugins = [CKEditorUploadAdapterPlugin]
		}
		return (
			<CKEditor
				editor={CustomBuild}
				data={this.props.data}
				config={config}
				onChange={(event, editor) => {
					this.props.updateContent(editor.getData())
				}}
			/>
		)
	}
}

export default CustomEditor
import React, { Component } from 'react'
import { CheckCircleIcon } from '@heroicons/react/outline'
import { Fragment } from 'react'
import { Transition } from '@headlessui/react'

class BannerInfo extends Component {
	/*
	BannerInfo:
		-> this.state.showBanner : affiche la bannière (true/false)
		-> dans les fonctions setSuccessMsg() et setErrorMsg(), ajouter au setState { bannerContent: msg }
		-> dans les fonctions setSuccessMsg() et setErrorMsg(), ajouter au setState { showBanner: true }
		->this.closeBanner() pour fermer la banière
	*/ 

	setOpen = () => {
		this.setState({ open: false })
	}

	render () {
		return (			
			<div
				aria-live="assertive"
				className="pointer-events-none fixed z-20 inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6"
			>
				<div className="flex w-full flex-col items-center space-y-4 sm:items-end">
					<Transition
					show={this.props.show}
					as={Fragment}
					enter="transform ease-out duration-300 transition"
					enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
					enterTo="translate-y-0 opacity-100 sm:translate-x-0"
					leave="transition ease-in duration-100"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
					>
					<div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
						<div className="p-4">
							<div className="flex items-start">
								<div className="flex-shrink-0">
									<CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />
								</div>
								<div className="ml-3 w-0 flex-1 pt-0.5">
									<p className="text-sm font-medium text-gray-900">{this.props.content}</p>
									{/* <p className="mt-1 text-sm text-gray-500">{this.props.content}</p> */}
								</div>
								<div className="ml-4 flex flex-shrink-0">
									<button
									type="button"
									className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
									onClick={this.props.closeBanner}
									>
										<span className="sr-only">Close</span>
										<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
											<path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
										</svg>
									</button>
								</div>
							</div>
						</div>
					</div>
					</Transition>
				</div>
			</div>
		)
	}
}

export default BannerInfo
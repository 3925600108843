import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import CKEditor from '../UI/CKEditor'
import M from 'materialize-css'
import Modal from '../UI/Modal'
import Loader from '../UI/Loader'
import LangSelector from '../UI/LangSelector'
import tools from '../../tools'
import '../Core/Main.css'
import EmptyFolder from '../UI/EmptyFolder'
import BannerInfo from '../UI/BannerInfo'

class Animations extends Component {
	constructor(props) {
		super(props)
		this.state = this._initState()
		this.length_max = 40
		this.props.updateNavbarTitle('Animations')
	}

	componentDidMount() {
		this.loadAnimations()
		this.props.fetchAnimationCategories()
		if (this.props.features === "premium")
			this.loadServices()
	}

	componentDidUpdate(prevProps, prevState) {
		const elems = document.querySelectorAll('select')
		M.FormSelect.init(elems)
		if (prevProps.animations !== this.props.animations || prevProps.categories !== this.props.categories)
			this.loadAnimations(true)
		if (prevProps.location.key !== this.props.location.key)
		{
			this.setState(this._initState())
			this.loadAnimations()
			this.props.fetchAnimationCategories()
		}
	}

	_initState = () => {
		return {
			animation: null,
			animations: null,
			edit: false,
			modal_delete: false,
			modal_edit: false,
			modal_msg: null,
			delete: null,
			attempt_submit: false,
			form1: {
				category: '',
				cover: null,
				front: false,
				translations: [],
				service_benefit: ''
			},
			service_benefits: null,
			current_lang: this.props.lang_default,
			show_banner: false,
			banner_content: null,
			error: this._initErrors(),
			success: null
		}
	}

	_initErrors = () => {
		return {
			global: null,
			name: null,
			category: null,
			content: null,
			cover: null,
			front: null
		}
	}

	setErrorMsg = (key, msg, id = null) => {
		if (key === "error_create_animation")
		{
			key = "global"
			msg = tools.default_error_msg("Impossible de créer une animation", msg)
		}
		else if (key === "error_update_animation")
		{
			key = "global"
			msg = tools.default_error_msg("Impossible de mettre à jour une animation", msg)
		}
		else if (key === "error_delete_animation")
		{
			key = "global"
			msg = tools.default_error_msg("Impossible de supprimer une animation", msg)
		}
		else if (key === "error_fetch_animations")
		{
			key = "global"
			msg = tools.default_error_msg("Impossible de récupérer les animations", msg)
		}
		else if (key === "error_fetch_services")
		{
			key = "global"
			msg = tools.default_error_msg("Impossible de récupérer les services", msg)
		}
		let error = this._initErrors()
		if (id !== null)
			error[key][id] = msg
		else
			error[key] = msg
		this.setState({ success: null, error: error, show_banner: true, banner_content: msg })
	}

	setSuccessMsg = (msg) => {
		this.clearFlash()
		this.setState({ error: this._initErrors(), success: msg, banner_content: msg, show_banner: true })
	}

	clearFlash = () => {
		this.setState({ error: this._initErrors(), success: null })
	}

	closeBanner = () => {
		this.setState({ show_banner: false })
	}

	loadAnimations = (fromProps = false) => {
		if (fromProps === true && this.props.categories && this.props.animations)
		{
			const categories = this.props.categories
			const animations = tools.clone(this.props.animations)
			let animation = this.state.animation
			for (let i = 0; i < animations.length; i++)
			{
				for (let j = 0; j < categories.length; j++)
				{
					if (animations[i].category === categories[j]._id)
					{
						animations[i].category_obj = categories[j]
						break
					}
				}
			}
			for (let i = 0; i < animations.length; i++)
			{
				if (animation !== null && animation._id === animations[i]._id)
				{
					animation = animations[i]
					break
				}
			}
			this.setState({ animation: animation, animations: animations })
		}
		else if (this.props.categories)
		{
			const categories = this.props.categories
			this.props.fetchAnimations()
			.then(ret => {
				let animations = tools.clone(ret)
				let animation = this.state.animation
				for (let i = 0; i < animations.length; i++)
				{
					for (let j = 0; j < categories.length; j++)
					{
						if (animations[i].category === categories[j]._id)
						{
							animations[i].category_obj = categories[j]
							break
						}
					}
					if (animation !== null && animation._id === animations[i]._id)
					{
						animation = animations[i]
						break
					}
				}
				this.setState({ animation: animation, animations: animations })
			})
			.catch(err => {
				this.setErrorMsg("error_fetch_animations", 1)
				console.log(err)
			})
		}
	}

	loadServices = () => {
		this.props.fetchServices()
		.then(services => {
			this.props.fetchServiceBenefits()
			.then(service_benefits => {
				for (let i = 0; i < service_benefits.length; i++)
				{
					for (let j = 0; j < services.length; j++)
					{
						if (service_benefits[i].service === services[j]._id)
						{
							service_benefits[i].service = services[j]
							break
						}
					}
				}
				service_benefits.sort((a, b) => {
					let title_a = ""
					let subtitle_a = ""
					if (a.services)
					{
						for (let i = 0; i < a.service.translations.length; i++)
						{
							if (a.service.translations[i].lang === this.props.lang_default)
							{
								title_a = a.service.translations[i].title
								break
							}
						}
					}
					for (let i = 0; i < a.translations.length; i++)
					{
						if (a.translations[i].lang === this.props.lang_default)
						{
							subtitle_a = a.translations[i].subtitle
							break
						}
					}
					let title_b = ""
					let subtitle_b = ""
					if (b.service && b.service.translations)
					{
						for (let i = 0; i < b.service.translations.length; i++)
						{
							if (b.service.translations[i].lang === this.props.lang_default)
							{
								title_b = b.service.translations[i].title
								break
							}
						}
					}
					for (let i = 0; i < b.translations.length; i++)
					{
						if (b.translations[i].lang === this.props.lang_default)
						{
							subtitle_b = b.translations[i].subtitle
							break
						}
					}
					if (title_a === title_b)
						return subtitle_a.localeCompare(subtitle_b)
					else
						return title_a.localeCompare(title_b)
				})
				this.setState({ service_benefits: service_benefits })
			})
			.catch(err => {
				this.setErrorMsg("error_fetch_services", 2)
				console.log(err)
			})
		})
		.catch(err => {
			this.setErrorMsg("error_fetch_services", 1)
			console.log(err)
		})
	}

	handleName = (event) => {
		let found = false
		const translations = this.state.form1.translations
		for (let i = 0; i < translations.length; i++)
		{
			if (translations[i].lang === this.state.current_lang)
			{
				found = true
				translations[i].name = event.target.value
				break
			}
		}
		if (!found)
		{
			translations.push({
				lang: this.state.current_lang,
				name: event.target.value,
				content: ''
			})
		}
		const form1 = {
			...this.state.form1,
			translations: translations
		}
		this.setState({ form1: form1 })
	}

	handleCategory = (event) => {
		const form1 = {
			...this.state.form1,
			category: event.target.value
		}
		this.setState({ form1: form1 })
	}

	handleContent = (content) => {
		let found = false
		const translations = this.state.form1.translations
		for (let i = 0; i < translations.length; i++)
		{
			if (translations[i].lang === this.state.current_lang)
			{
				found = true
				translations[i].content = content
				break
			}
		}
		if (!found)
		{
			translations.push({
				lang: this.state.current_lang,
				name: '',
				content: content
			})
		}
		const form1 = {
			...this.state.form1,
			translations: translations
		}
		this.setState({ form1: form1 })
	}

	handleCover = (event) => {
		const reader = new FileReader()
		const file = event.target.files[0]
		reader.addEventListener("load", () => {
			const form1 = {
				...this.state.form1,
				cover: reader.result
			}
			this.setState({ form1: form1 })
		}, false)
		if (file)
			reader.readAsDataURL(file)
	}

	handleFront = (event) => {
		const form1 = {
			...this.state.form1,
			front: (this.state.form1.front === true) ? false : true
		}
		this.setState({ form1: form1 })
	}

	handleServiceBenefit = (event) => {
		const form1 = {
			...this.state.form1,
			service_benefit: event.target.value
		}
		this.setState({ form1: form1 })
	}

	clickCreate = () => {
		const form1 = {
			category: '',
			cover: null,
			front: false,
			translations: [],
			service_benefit: null
		}
		this.clearFlash()
		this.setState({ form1: form1, animation: null, edit: true })
	}

	clickEdit = (id) => {
		this.props.fetchAnimationByID(id)
		.then(animation => {
			const form1 = {
				category: animation.category,
				cover: animation.cover,
				front: animation.front,
				translations: animation.translations,
				service_benefit: animation.service_benefit
			}
			this.clearFlash()
			this.setState({ form1: form1, animation: animation, edit: true })
		})
		.catch(err => {
			this.setErrorMsg("error_fetch_animations", 2)
			console.log(err)
		})
	}

	clickDelete = (id) => {
		console.log("delete animation", id)
		this.setState({ delete: id, modal_delete: true })
	}

	_deleteAnimation = () => {
		let animation = null
		for (let i = 0; i < this.state.animations.length; i++)
		{
			if (this.state.animations[i]._id === this.state.delete)
			{
				animation = this.state.animations[i]
				break
			}
		}
		if (animation !== null)
		{
			this.props.deleteAnimation(this.state.delete)
			.then(ret => {
				if (ret === true)
				{
					const form1 = {
						name: '',
						category: '',
						content: '',
						cover: null,
						front: false,
						service_benefit: null
					}
					this.setSuccessMsg("L'animation a bien été supprimée")
					this.setState({ form1: form1, animation: null, modal_delete: false, edit: false })
				}
				else
				{
					this.setState({ modal_delete: false })
					this.setErrorMsg('error_delete_animation', 1)
				}
			})
			.catch(err => {
				this.setState({ modal_delete: false })
				this.setErrorMsg('error_delete_animation', 2)
				console.log(err)
			})
		}
	}

	unEdit = () => {
		this.setState({ edit: false })
	}

	_validateForm1 = () => {
		let error = false
		let error_obj = this._initErrors()
		const category = this.state.form1.category
		const cover = this.state.form1.cover
		const front = this.state.form1.front
		const translations = this.state.form1.translations
		if (category.length === 0)
		{
			error = true
			error_obj.category = "Veuillez sélectionner une catégorie"
		}
		if (front === true && cover === null)
		{
			error = true
			error_obj.cover = "Veuillez insérer une photo de couverture"
		}
		let found_default_name = false
		let found_default_content = false
		for (let i = 0; i < translations.length; i++)
		{
			const lang = translations[i].lang
			const name = translations[i].name
			if (name.length === 0 && lang === this.props.lang_default)
			{
				error = true
				error_obj.name = "Vous devez indiquer le titre (version " + lang.toUpperCase() + ")"
			}
			else if (name.length > this.length_max)
			{
				error = true
				error_obj.name = "Le titre ne doit pas dépasser " + this.length_max + " caractères (version " + lang.toUpperCase() + ")"
			}
			else if (lang === this.props.lang_default)
				found_default_name = true
			const content = translations[i].content
			if (content.length === 0 && lang === this.props.lang_default)
			{
				error = true
				error_obj.content = "Veuillez insérer un contenu (version " + lang.toUpperCase() + ")"
			}
			else if (lang === this.props.lang_default)
				found_default_content = true
		}
		if (!found_default_name && !error_obj.name)
		{
			error = true
			error_obj.name = 'Vous devez indiquer le titre (version ' + this.props.lang_default.toUpperCase() + ')'
		}
		if (!found_default_content && !error_obj.content)
		{
			error = true
			error_obj.content = 'Veuillez insérer un contenu (version ' + this.props.lang_default.toUpperCase() + ')'
		}
		if (error === true)
		{
			error_obj.global = "Il y a une ou plusieurs erreur(s) dans le formulaire"
			this.setState({ success: null, error: error_obj, attempt_submit: false })
		}
		return (!error) ? true : false
	}

	submitAnimation = (event) => {
		event.preventDefault()
		this.setState({ attempt_submit: true })
		if (this._validateForm1())
		{
			const translations = this.state.form1.translations
			let langs_not_found = []
			for (let i = 0; i < this.props.langs.length; i++)
			{
				let found = false
				for (let j = 0; j < translations.length; j++)
				{
					if (this.props.langs[i] === translations[j].lang && translations[j].name.length > 0 && translations[j].content.length > 0)
					{
						found = true
						break
					}
				}
				if (!found)
					langs_not_found.push(this.props.langs[i])
			}
			if (langs_not_found.length > 0)
			{
				let msg = ""
				for (let i = 0; i < langs_not_found.length; i++)
				{
					if (i > 0)
						msg += ', '
					msg += langs_not_found[i].toUpperCase()
				}
				this.setState({ modal_msg: msg, modal_edit: true })
			}
			else
				this._insertAnimation()
		}
	}

	_insertAnimation = () => {
		const category = this.state.form1.category
		const cover = this.state.form1.cover
		const front = (this.state.form1.front === true) ? true : false
		const translations = this.state.form1.translations
		const service_benefit = (this.state.form1.service_benefit !== "") ? this.state.form1.service_benefit : null
		if (this.state.animation === null)
		{
			const animation = {
				category: category,
				cover: cover,
				front: front,
				translations: translations,
				service_benefit: service_benefit
			}
			this.props.insertAnimation(animation)
			.then(ret => {
				if (ret === false)
				{
					this.setState({ attempt_submit: false, modal_edit: false })
					this.setErrorMsg('error_create_animation', 1)
				}
				else
				{
					const form1 = {
						category: '',
						cover: null,
						front: false,
						translations: [],
						service_benefit: null
					}
					this.setState({ form1: form1, animation: null, edit: false, attempt_submit: false, modal_edit: false })
					this.setSuccessMsg("L'animation a bien été créée !")
				}
			})
			.catch(err => {
				this.setState({ attempt_submit: false, modal_edit: false })
				this.setErrorMsg('error_create_animation', 2)
				console.log(err)
			})
		}
		else
		{
			const animation = {
				_id: this.state.animation._id,
				category: category,
				cover: cover,
				front: front,
				translations: translations,
				service_benefit: service_benefit
			}
			this.props.updateAnimation(animation)
			.then(ret => {
				if (ret === false)
				{
					this.setState({ attempt_submit: false, modal_edit: false })
					this.setErrorMsg('error_update_animation', 1)
				}
				else
				{
					this.setState({ animation: null, edit: false, attempt_submit: false, modal_edit: false })
					this.setSuccessMsg("L'animation a bien été éditée")
				}
			})
			.catch(err => {
				this.setState({ attempt_submit: false, modal_edit: false })
				this.setErrorMsg('error_update_animation', 2)
				console.log(err)
			})
		}
	}

	answerModalEdit = (answer) => {
		if (answer === "yes")
			this._insertAnimation()
		else
			this.setState({ attempt_submit: false, modal_edit: false })
	}

	answerModalDelete = (answer) => {
		if (answer === "yes")
			this._deleteAnimation()
		else
			this.setState({ delete: false, modal_delete: false })
	}

	updateLang = (lang) => {
		this.setState({ current_lang: lang })
	}

	render() {
		const animations = this.state.animations
		const categories = this.props.categories
		const permissions = this.props.user.permissions
		if (!permissions || !permissions.activities || permissions.activities === 0)
			return (<Redirect to="/" />)
		else if (animations === null || categories === null)
		{
			return (
				<div className="loader">
					<Loader />
				</div>
			)
		}
		else if (this.state.edit === true)
		{
			let titleHeader = 'Créer une animation'
			let btn = 'Ajouter'
			let deletebtn = null
			if (this.state.animation !== null)
			{
				titleHeader = 'Modifier une animation'
				btn = 'Éditer'
				deletebtn = (<button type="button" className="waves-effect waves-light btn-small red" onClick={this.clickDelete.bind(this, this.state.animation._id)}>Supprimer</button>)
			}
			let name = ""
			let content = ""
			for (let i = 0; i < this.state.form1.translations.length; i++)
			{
				if (this.state.form1.translations[i].lang === this.state.current_lang)
				{
					name = this.state.form1.translations[i].name
					content = this.state.form1.translations[i].content
					break
				}
			}
			let prev = null
			if (this.state.form1.cover !== null)
				prev = (<div><p>Prévisualisation :</p><p><img src={this.state.form1.cover} alt="Prévisualisation" className="prev" /></p></div>)
			let sendbtn = (
				<div>
					<button type="submit" className="waves-effect waves-light btn-small">{btn}</button>
					{deletebtn}
				</div>
			)
			if (this.state.attempt_submit === true)
			{
				sendbtn = (
					<div className="center">
						<Loader />
					</div>
				)
			}
			let serviceForm = null
			if (this.state.service_benefits && this.props.features === "premium")
			{
				serviceForm = (
					<label>
						<p className="column-20">Service :</p>
						<select value={this.state.form1.service_benefit} onChange={this.handleServiceBenefit}>
							<option value="">-- Sélectionner un service --</option>
							{this.state.service_benefits && this.state.service_benefits.map(({ _id, service, translations }) => {
								let title = ""
								if (typeof service === "object" && service.translations && service.translations.length > 0)
								{
									for (let i = 0; i < service.translations.length; i++)
									{
										if (service.translations[i].lang === this.props.lang_default)
										{
											title = service.translations[i].title
											break
										}
									}
								}
								if (!title)
									return null
								let subtitle = ""
								for (let i = 0; i < translations.length; i++)
								{
									if (translations[i].lang === this.props.lang_default)
									{
										subtitle = translations[i].subtitle
										break
									}
								}
								return (<option value={_id} key={_id}>[{title}] {subtitle}</option>)
							})}
						</select>
					</label>
				)
			}
			let display_modal = false
			let content_modal = null
			let answer_modal = null
			if (this.state.modal_edit === true)
			{
				display_modal = true
				content_modal = "L'animation n'est pas traduite dans les langues suivantes : " + this.state.modal_msg
				answer_modal = this.answerModalEdit
			}
			else if (this.state.modal_delete === true)
			{
				display_modal = true
				content_modal = "Vous êtes sur le point de supprimer cette animation. Cette action est irréversible"
				answer_modal = this.answerModalDelete
			}
			return (
				<div className="wrapper-content">
					<Modal
						dismissible={false}
						content={content_modal}
						open_modal={display_modal}
						answerModal={answer_modal}
					/>
					<div className="row">
						<div className="module col s12">
							<p className="error center">{this.state.error.global}</p>
							<p className="success center">{this.state.success}</p>
							<h2><span className="uhover" onClick={this.unEdit}>Animations</span> > {titleHeader}</h2>
							<LangSelector lang={this.state.current_lang} updateLang={this.updateLang} />
							<form className="module-form" onSubmit={this.submitAnimation}>
								<label>
									<p className="error">{this.state.error.name}</p>
									<p className="column-20">Titre (version {this.state.current_lang.toUpperCase()}) :</p>
									<input type="text" value={name} onChange={this.handleName} />
								</label>
								<label>
									<p className="error">{this.state.error.category}</p>
									<p className="column-20">Catégorie :</p>
									<select value={this.state.form1.category} onChange={this.handleCategory}>
										<option value="">-- Sélectionner une catégorie --</option>
										{categories && categories.map(({ _id, translations }) => {
											let category_name = ""
											for (let i = 0; i < translations.length; i++)
											{
												if (translations[i].lang === this.props.lang_default)
												{
													category_name = translations[i].name
													break
												}
											}
											return (<option value={_id} key={_id}>{category_name}</option>)
										})}
									</select>
								</label>
								<p className="error">{this.state.error.content}</p>
								<p>Contenu (version {this.state.current_lang.toUpperCase()}) :</p>
								<CKEditor
									toolbar="rubrics"
									data={content}
									updateContent={this.handleContent}
								/>
								<label>
									<p className="error">{this.state.error.cover}</p>
									<p className="column-20">Photo de couverture :</p>
									<input type="file" onChange={this.handleCover} />
								</label>
								{prev}
								<label>
									<p className="column-20">Mettre en avant :</p>
									<input type="checkbox" checked={this.state.form1.front} onChange={this.handleFront} />
									<span></span>
								</label>
								{serviceForm}
								<div className="clear"></div>
								{sendbtn}
							</form>
						</div>
					</div>
				</div>
			)
		}
		else
		{
			const modal_msg = "Vous êtes sur le point de supprimer cette animation. Cette action est irréversible"
			let content = ""
			if (!animations || !animations.length)
			{
				content =
				(
					<EmptyFolder 
						emptyFolderSubtitle = "Pas d'animation paramétrée"
					/>
				)
			}
			else
			{
				content = (
					<div className="mt-8">
						<BannerInfo
							content={this.state.banner_content}
							show={this.state.show_banner}
							closeBanner={this.closeBanner}
							error={this.state.error.global}
							success={this.state.success}
						/>
						<div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
							<div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
								<div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
									<table className="min-w-full divide-y divide-gray-300">
										<thead className="bg-gray-50">
											<tr>
												<th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-medium text-gray-700 sm:pl-6">
													Nom
												</th>
												<th scope="col" className="px-3 py-3.5 text-left text-sm font-medium text-gray-700">
													Catégorie d'activité
												</th>
												<th scope="col" className="px-3 py-3.5 text-left text-sm font-medium text-gray-700">
													Affichage dynamique
												</th>
												<th scope="col" className="px-3 py-3.5 text-left text-sm font-medium text-gray-700">
													Photo
												</th>
												<th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
													<span className="sr-only">Modifier</span>
												</th>
												<th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
													<span className="sr-only">Supprimer</span>
												</th>
											</tr>
										</thead>
										<tbody className="divide-y divide-gray-200 bg-white">
											{animations && animations.map(({ _id, category_obj, front, translations, cover }) => {
												let name = null
												let category_name = null
												for (let i = 0; i < translations.length; i++)
												{
													if (translations[i].lang === this.state.current_lang)
													{
														if (translations[i].name)
															name = translations[i].name
														break
													}
												}
												if (category_obj && category_obj.translations)
												{
													for (let i = 0; i < category_obj.translations.length; i++)
													{
														if (category_obj.translations[i].lang === this.state.current_lang)
														{
															if (category_obj.translations[i])
																category_name = category_obj.translations[i].name
															break
														}
													}
												}
												let img = null
												let category_color = (category_obj && category_obj.color) ? category_obj.color : "e5e7eb"
												let is_cover_image = null
												if (cover)
												{
													is_cover_image = (
														<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 text-green-400">
															<path fillRule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z" clipRule="evenodd" />
														</svg>
													)
												}
												else
												{
													is_cover_image = (
														<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 text-gray-200">
															<path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.72 6.97a.75.75 0 10-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 101.06 1.06L12 13.06l1.72 1.72a.75.75 0 101.06-1.06L13.06 12l1.72-1.72a.75.75 0 10-1.06-1.06L12 10.94l-1.72-1.72z" clipRule="evenodd" />
														</svg>
													)
												}
												if (front === true)
												{
													img = (
														<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 text-yellow-400">
															<path fillRule="evenodd" d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z" clipRule="evenodd" />
														</svg>
													)
												}
												else
												{
													img = (
														<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 text-gray-200">
															<path fillRule="evenodd" d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z" clipRule="evenodd" />
														</svg>
													)
												}
												return (
													<tr key={_id}>
														<td className="whitespace-nowrap py-4 pl-4 pr-3 text-base text-gray-900 sm:pl-6">
															<button onClick={this.clickEdit.bind(this, _id)}>
																{name}<span className="sr-only"></span>
															</button>
														</td>
														<td className="whitespace-nowrap px-3 py-4 text-base text-white">
															<span style={{ backgroundColor: "#" + category_color}} className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-white">
																{category_name}
															</span>
														</td>
														<td className="whitespace-nowrap py-4 pl-4 pr-3 text-base text-gray-900 sm:pl-6">
															{img}
														</td>
														<td className="whitespace-nowrap py-4 pl-4 pr-3 text-base text-gray-900 sm:pl-6">
															{is_cover_image}
														</td>
														<td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
															<button onClick={this.clickEdit.bind(this, _id)} className="text-indigo-600 hover:text-indigo-900">
																Éditer<span className="sr-only"></span>
															</button>
														</td>
														<td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
															<button onClick={this.clickDelete.bind(this, _id)} className="text-red-300 hover:text-red-600">
																<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 test-red-300">
																	<path fillRule="evenodd" d="M16.5 4.478v.227a48.816 48.816 0 013.878.512.75.75 0 11-.256 1.478l-.209-.035-1.005 13.07a3 3 0 01-2.991 2.77H8.084a3 3 0 01-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 01-.256-1.478A48.567 48.567 0 017.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 013.369 0c1.603.051 2.815 1.387 2.815 2.951zm-6.136-1.452a51.196 51.196 0 013.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 00-6 0v-.113c0-.794.609-1.428 1.364-1.452zm-.355 5.945a.75.75 0 10-1.5.058l.347 9a.75.75 0 101.499-.058l-.346-9zm5.48.058a.75.75 0 10-1.498-.058l-.347 9a.75.75 0 001.5.058l.345-9z" clipRule="evenodd" />
																</svg>
																<span className="sr-only"></span>
															</button>
														</td>
													</tr>
												)
											})}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				)
			}	
			return (
				<>
					<Modal
						dismissible={false}
						content={modal_msg}
						open_modal={this.state.modal_delete}
						answerModal={this.answerModalDelete}
					/>
					<div className="flex flex-col px-4 sm:px-6 lg:px-8">
						<div className="sm:flex-auto mb-7">
							<h2 className="text-xl mt-0 leading-6 font-medium text-gray-900 mb-0">Vos activités</h2>
							<p className="mt-1 text-base text-gray-500">
								Retrouvez l'ensemble de vos activités.
							</p>
						</div>
						<div className="grid-cols-1">
							<button onClick={this.clickCreate} type="button" className="mb-10 inline-flex items-center px-6 py-3 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
								Créer une activité
								<svg xmlns="http://www.w3.org/2000/svg" className="ml-3 -mr-1 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
									<path strokeLinecap="round" strokeLinejoin="round" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
								</svg>
							</button>
						</div>
						<LangSelector lang={this.state.current_lang} updateLang={this.updateLang} />
						{content}
					</div>
				</>
			)
		}
	}
}

export default Animations

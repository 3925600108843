import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { HomeIcon } from '@heroicons/react/outline'
import { UserCircleIcon } from '@heroicons/react/solid'
import Loader from '../UI/Loader'

class Chat extends Component {
	constructor(props) {
		super(props)
		this.state = {
			pages: [
				{ name: 'Chat', href: '/messages/chat', current: false },
			],
			subjects: [
				"Demande d'intervention",
				"Bouteille de gaz",
				"Autre"
			],
			conversations: null,
			messages: null,
			// conversations: [
			// 	{
			// 		subject: "Demande d'intervention",
			// 		messages: [
			// 			{
			// 				// user: null,
			// 				user: {
			// 					name: 'Whitney Francis',
  			// 					email: 'whitney.francis@example.com',
			// 					imageUrl:
			// 						'https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
			// 				},
			// 				admin: null,
			// 				content: "hello world",
			// 				created_time: 1647277524,
			// 				read: false
			// 			}
			// 		],
			// 		close: false
			// 	}
			// ],
			form1: { // nouvelle conversation
				subject: "",
				content: ""
			},
			form2: { // reponse a une conversation
				content: ""
			}
		}
		this.props.updateNavbarTitle('Messages clients')
		// this.messages = [
		// 	{
		// 		id: 1,
		// 		subject: 'Velit placeat sit ducimus non sed',
		// 		sender: 'Gloria Roberston',
		// 		href: '#',
		// 		date: '7 MARS',
		// 		datetime: '2021-01-27T16:35',
		// 		preview:
		// 		  'Doloremque dolorem maiores assumenda dolorem facilis. Velit vel in a rerum natus facere. Enim rerum eaque qui facilis. Numquam laudantium sed id dolores omnis in. Eos reiciendis deserunt maiores et accusamus quod dolor.',
		// 	  },
		// 	  {
		// 		id: 2,
		// 		subject: 'Nemo mollitia repudiandae adipisci explicabo optio consequatur tempora ut nihil',
		// 		sender: 'Virginia Abshire',
		// 		href: '#',
		// 		date: '7 MARS',
		// 		datetime: '2021-01-27T16:35',
		// 		preview:
		// 		  'Doloremque dolorem maiores assumenda dolorem facilis. Velit vel in a rerum natus facere. Enim rerum eaque qui facilis. Numquam laudantium sed id dolores omnis in. Eos reiciendis deserunt maiores et accusamus quod dolor.',
		// 	  },
		// 	  {
		// 		id: 3,
		// 		subject: 'Doloremque reprehenderit et harum quas explicabo nulla architecto dicta voluptatibus',
		// 		sender: 'Kyle Gulgowski',
		// 		href: '#',
		// 		date: '7 MARS',
		// 		datetime: '2021-01-27T16:35',
		// 		preview:
		// 		  'Doloremque dolorem maiores assumenda dolorem facilis. Velit vel in a rerum natus facere. Enim rerum eaque qui facilis. Numquam laudantium sed id dolores omnis in. Eos reiciendis deserunt maiores et accusamus quod dolor.',
		// 	  },
		// 	  {
		// 		id: 4,
		// 		subject: 'Eos sequi et aut ex impedit',
		// 		sender: 'Hattie Haag',
		// 		href: '#',
		// 		date: '7 MARS',
		// 		datetime: '2021-01-27T16:35',
		// 		preview:
		// 		  'Doloremque dolorem maiores assumenda dolorem facilis. Velit vel in a rerum natus facere. Enim rerum eaque qui facilis. Numquam laudantium sed id dolores omnis in. Eos reiciendis deserunt maiores et accusamus quod dolor.',
		// 	  },
		// 	  {
		// 		id: 5,
		// 		subject: 'Quisquam veniam explicabo',
		// 		sender: 'Wilma Glover',
		// 		href: '#',
		// 		date: '7 MARS',
		// 		datetime: '2021-01-27T16:35',
		// 		preview:
		// 		  'Doloremque dolorem maiores assumenda dolorem facilis. Velit vel in a rerum natus facere. Enim rerum eaque qui facilis. Numquam laudantium sed id dolores omnis in. Eos reiciendis deserunt maiores et accusamus quod dolor.',
		// 	  },
		// 	  {
		// 		id: 6,
		// 		subject: 'Est ratione molestiae modi maiores consequatur eligendi et excepturi magni',
		// 		sender: 'Dolores Morissette',
		// 		href: '#',
		// 		date: '7 MARS',
		// 		datetime: '2021-01-27T16:35',
		// 		preview:
		// 		  'Doloremque dolorem maiores assumenda dolorem facilis. Velit vel in a rerum natus facere. Enim rerum eaque qui facilis. Numquam laudantium sed id dolores omnis in. Eos reiciendis deserunt maiores et accusamus quod dolor.',
		// 	  },
		// 	  {
		// 		id: 7,
		// 		subject: 'Commodi deserunt aut veniam rem ipsam',
		// 		sender: 'Guadalupe Walsh',
		// 		href: '#',
		// 		date: '7 MARS',
		// 		datetime: '2021-01-27T16:35',
		// 		preview:
		// 		  'Doloremque dolorem maiores assumenda dolorem facilis. Velit vel in a rerum natus facere. Enim rerum eaque qui facilis. Numquam laudantium sed id dolores omnis in. Eos reiciendis deserunt maiores et accusamus quod dolor.',
		// 	  },
		// 	  {
		// 		id: 8,
		// 		subject: 'Illo illum aut debitis earum',
		// 		sender: 'Jasmine Hansen',
		// 		href: '#',
		// 		date: '8 MARS',
		// 		datetime: '2021-01-27T16:35',
		// 		preview:
		// 		  'Doloremque dolorem maiores assumenda dolorem facilis. Velit vel in a rerum natus facere. Enim rerum eaque qui facilis. Numquam laudantium sed id dolores omnis in. Eos reiciendis deserunt maiores et accusamus quod dolor.',
		// 	  },
		// 	  {
		// 		id: 9,
		// 		subject: 'Qui dolore iste ut est cumque sed',
		// 		sender: 'Ian Volkman',
		// 		href: '#',
		// 		date: '9 MARS',
		// 		datetime: '2021-01-27T16:35',
		// 		preview:
		// 		  'Doloremque dolorem maiores assumenda dolorem facilis. Velit vel in a rerum natus facere. Enim rerum eaque qui facilis. Numquam laudantium sed id dolores omnis in. Eos reiciendis deserunt maiores et accusamus quod dolor.',
		// 	  },
		// 	  {
		// 		id: 10,
		// 		subject: 'Aut sed aut illum delectus maiores laboriosam ex',
		// 		sender: 'Rafael Klocko',
		// 		href: '#',
		// 		date: '9 MARS',
		// 		datetime: '2021-01-27T16:35',
		// 		preview:
		// 		  'Doloremque dolorem maiores assumenda dolorem facilis. Velit vel in a rerum natus facere. Enim rerum eaque qui facilis. Numquam laudantium sed id dolores omnis in. Eos reiciendis deserunt maiores et accusamus quod dolor.',
		// 	  },
		// ]
		this.comments = [
			{
				id: 1,
				name: 'Leslie Alexander',
				date: '9 MARS, 15h29',
				body: 'Ducimus quas delectus ad maxime totam doloribus reiciendis ex. Tempore dolorem maiores. Similique voluptatibus tempore non ut.',
			  },
			  {
				id: 2,
				name: 'Michael Foster',
				date: '9 MARS, 17h12',
				body: 'Et ut autem. Voluptatem eum dolores sint necessitatibus quos. Quis eum qui dolorem accusantium voluptas voluptatem ipsum. Quo facere iusto quia accusamus veniam id explicabo et aut.',
			  },
			  {
				id: 3,
				name: 'Dries Vincent',
				date: '9 MARS, 17h20',
				body: 'Expedita consequatur sit ea voluptas quo ipsam recusandae. Ab sint et voluptatem repudiandae voluptatem et eveniet. Nihil quas consequatur autem. Perferendis rerum et.',
			  },
		]
		this.user = {
			name: 'Whitney Francis',
			email: 'whitney@example.com',
			imageUrl:
			  'https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80',
		}
	}

	componentDidMount()
	{
		this.props.fetchConversations()
		.then(conversations => {
			this.setState({ conversations: conversations })
		})
		.catch(err => console.log(err))
	}

	openConversation = (conversation) => {
		this.props.fetchMessagesByConversation(conversation)
		.then(messages => {
			this.setState({ messages: messages })
		})
		.catch(err => console.log(err))
	}

	classNames = (...classes) => {
		return classes.filter(Boolean).join(' ')
	}

	render() {
		if (!this.state.conversations)
		{
			return (
				<div className="loader">
					<Loader />
				</div>
			)
		}
		else
		{
			const navStyle = "flex bg-transparent shadow-none mb-10"
			return (
				<div className=''>
					<div className="flex flex-col">
					{/* NAVIGATION TOOL */}
					<nav className={navStyle} aria-label="Breadcrumb">
						<ol className="bg-white rounded-md shadow px-6 flex space-x-4">
							<li className="flex">
								<div className="flex items-center">
									<Link to="/" className="text-gray-400 hover:text-gray-500">
										<HomeIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
										<span className="sr-only">Home</span>
									</Link>
								</div>
							</li>
							{this.state.pages.map((page) => (
							<li key={page.name} className="flex">
								<div className="flex items-center">
									<svg
										className="flex-shrink-0 w-6 h-full text-gray-200"
										viewBox="0 0 24 44"
										preserveAspectRatio="none"
										fill="currentColor"
										xmlns="http://www.w3.org/2000/svg"
										aria-hidden="true"
									>
									<path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
								</svg>
								<Link
									to={page.href}
									className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
									aria-current={page.current ? 'page' : undefined}
								>
									{page.name}
								</Link>
								</div>
							</li>
							))}
						</ol>
					</nav>
					{/* NAVIGATION TOOL */}
	
					<main className="min-w-0 flex-1 border-t border-l border-gray-200 xl:flex">
						<section
							aria-labelledby="message-heading"
							className="min-w-0 flex-1 h-full flex flex-col overflow-hidden xl:order-last"
						>
							{/* Top section */}
							<div className="flex-shrink-0 bg-white border-b border-gray-200">
								{/* Toolbar*/}
								<div className="h-16 flex flex-col justify-center">
									<div className="px-4 sm:px-6 lg:px-8">
										<div className="py-3 flex justify-between">
											<h2 id="notes-title" className="text-lg font-medium text-gray-900">
												Messages
											</h2>
										</div>
									</div>
								</div>
								{/* Message header */}
							</div>
							
							<div className="h-4/5-screen flex-1 overflow-y-auto">
								<section className="m-10">
									<div className="bg-white shadow sm:rounded-lg sm:overflow-hidden">
										<div className="divide-y divide-gray-200">
											{/* <div className="px-4 py-5 sm:px-6">
												<h2 id="notes-title" className="text-lg font-medium text-gray-900">
													Messages
												</h2>
											</div> */}
											<div className="px-4 py-6 sm:px-6">
												<ul className="space-y-8">
													{this.state.messages && this.state.messages.map((message) => {
														const name = "Franck DAVID"
														const date = "4 OCTOBRE"
														return (
															<li key={message.id}>
																<div className="flex space-x-3">
																	<div className="flex-shrink-0">
																		<UserCircleIcon
																			className="h-10 w-10 rounded-full text-gray-300"
																		/>
																	</div>
																	<div>
																		<div className="text-sm">
																			<p className="font-medium text-gray-900">
																				{name}
																			</p>
																		</div>
																		<div className="mt-1 text-sm text-gray-700">
																			<p>{message.message}</p>
																		</div>
																		<div className="mt-2 text-sm space-x-2">
																			<span className="text-gray-500 font-medium">{date}</span>{' '}
																			<span className="text-gray-500 font-medium">&middot;</span>{' '}
																			<button type="button" className="text-gray-900 font-medium">
																				Répondre
																			</button>
																		</div>
																	</div>
																</div>
															</li>
														)
													})}
												</ul>
											</div>
										</div>
										<div className="bg-gray-50 px-4 py-6 sm:px-6">
											<div className="flex space-x-3">
												<div className="min-w-0 flex-1">
													<form action="#">
														<div>
															<label htmlFor="comment" className="sr-only">
																Answer
															</label>
															<textarea
																rows={3}
																className="shadow-sm bg-white block w-full focus:ring-blue-500 focus:border-blue-500 sm:text-sm border border-gray-300 rounded-md"
																placeholder="Réponse"
																defaultValue={''}
															/>
														</div>
														<div className="mt-3 flex justify-end">
															<button
																type="submit"
																className="px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
															>
																Envoyer
															</button>
														</div>
													</form>
												</div>
											</div>
										</div>
									</div>
								</section>
							</div>
						</section>
	
						{/* Message list*/}
						<aside className="hidden xl:block xl:flex-shrink-0 xl:order-first">
							 <div className="h-4/5-screen relative flex flex-col w-96 border-r border-gray-200 bg-gray-100">
								   <div className="flex-shrink-0">
									  <div className="h-16 bg-white px-6 flex flex-col justify-center">
										<div className="flex items-baseline space-x-3">
											  <h2 className="text-lg font-medium text-gray-900">Boite de réception</h2>
											  <p className="text-sm font-medium text-gray-500">{this.state.conversations.length} messages</p>
										</div>
									  </div>
									<div className="border-t border-b border-gray-200 bg-gray-50 px-6 py-2 text-sm font-medium text-gray-500">
										Trié par date
									</div>
								   </div>
								<nav aria-label="Message list" className="min-h-0 flex-1 overflow-y-auto bg-transparent">
									  <ul className="border-b border-gray-200 divide-y divide-gray-200">
										{this.state.conversations.map((conversation) => {
											const sender = 'Franck DAVID'
											const preview = 'Doloremque dolorem maiores assumenda dolorem facilis. Velit vel in a rerum natus facere. Enim rerum eaque qui facilis. Numquam laudantium sed id dolores omnis in. Eos reiciendis deserunt maiores et accusamus quod dolor.'
											const date = "4 OCTOBRE"
											let pastille = (
												<span className="inline-flex rounded-full bg-blue-800 px-2 text-xs font-semibold leading-5 text-white">
													Demande d'intervention
												</span>
											)
											if (conversation.type === "message")
											{
												pastille = (
													<span className="inline-flex rounded-full bg-green-800 px-2 text-xs font-semibold leading-5 text-white">
														Conversation
													</span>
												)
											}
											return (
												<li
													key={conversation._id}
													onClick={this.openConversation.bind(this, conversation._id)}
													className="relative bg-white w-full py-5 px-6 hover:bg-gray-100 focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-600"
												>
													<div className="flex justify-between space-x-3">
														<div className="min-w-0 flex-1">
															<div href={conversation.href} className="block focus:outline-none">
																<span className="absolute inset-0" aria-hidden="true" />
																<p className="text-sm font-medium text-gray-900 truncate">{sender}</p>
																{pastille}
																<p className="text-sm text-gray-500 truncate">{conversation.title}</p>
															</div>
														</div>
														<time
															dateTime={conversation.datetime}
															className="flex-shrink-0 whitespace-nowrap text-sm text-gray-500"
														>
															{date}
														</time>
													</div>
													<div className="mt-1">
														<p className="line-clamp-2 text-sm text-gray-600">{preview}</p>
													</div>
												</li>
											)
										})}
									  </ul>
								</nav>
							</div>
						   </aside>
	
					</main>
					</div>
				</div>
			)
		}
	}
}

export default Chat
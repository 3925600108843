import React, { Component } from 'react'
import M from "materialize-css"

class Modal extends Component {
	constructor(props) {
		super(props)
		this.state = {
			init_modal: false,
			dismissible: (this.props.dismissible === false) ? false : true,
			open_modal: this.props.open_modal
		}
	}

	componentDidUpdate(prevProps) {
		if (this.state.init_modal === false)
		{
			const modals = document.querySelectorAll('.modal')
			if (modals.length > 0)
			{
				M.Modal.init(modals, { dismissible: this.state.dismissible })
				if (this.state.open_modal === true)
					this._openModal()
				this.setState({ init_modal: true })
			}
		}
		if (prevProps.open_modal !== this.props.open_modal)
		{
			if (this.props.open_modal === true)
				this._openModal()
			else
				this._closeModal()
		}
	}

	_openModal = () => {
		const modals = document.querySelectorAll('.modal')
		const instance = M.Modal.getInstance(modals[0])
		instance.open()
	}

	_closeModal = () => {
		const modals = document.querySelectorAll('.modal')
		const instance = M.Modal.getInstance(modals[0])
		instance.close()
	}

	answerModal = (answer) => {
		this._closeModal()
		this.props.answerModal(answer)
	}

	render() {
		const style = {
			marginLeft: '10px'
		}
		return (
			<div id="modal1" className="modal">
				<div className="modal-content">
					<h4>Êtes-vous sûrs de vouloir continuer ?</h4>
					<p>{this.props.content}</p>
				</div>
				<div className="modal-footer">
					<button type="button" className="waves-effect waves-light btn-small" onClick={this.answerModal.bind(this, "yes")}>Oui</button>
					<button type="button" className="waves-effect waves-light btn-small red" style={style} onClick={this.answerModal.bind(this, "no")}>Non</button>
				</div>
			</div>
		)
	}
}

export default Modal

import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import Loader from '../UI/Loader'
import connexion from '../../connexion'
import '../Core/Main.css'

class Stats extends Component {
	constructor(props) {
		super(props)
		this.state = {
			logs: null,
			logsByWeek: null,
			logsByComponents: {
				AnimationDays: 0,
				Animations: 0,
				Categories: 0,
				Home: 0,
				Membres: 0,
				Pages: 0
			},
			installedApp: 0,
			visitsByUser: 0,
			total: 0
		}
		this.props.updateNavbarTitle('Statistiques')
	}

	componentDidMount() {
		let countInstalledApp = 0
		let logsByUsers = []
		const logsByWeek = []
		let logsByComponents = {}
		fetch(connexion.connect.url + 'webapp/logs/list')
		.then(res => res.json())
		.then(logs => {
			logs.sort((a, b) => {
				return b.time - a.time
			})
			for (let i = 0; i < logs.length; i++)
			{
				if (logs[i].app === true)
					countInstalledApp++
				if (logsByUsers[logs[i].user] === undefined)
					logsByUsers[logs[i].user] = [logs[i]]
				else
					logsByUsers[logs[i].user].push(logs[i])
				const date = new Date(logs[i].time * 1000)
				const day = date.getDay()
				let nbDay = date.getDate()
				if (day === 0)
					nbDay -= 6
				else if (day > 1)
					nbDay -= day - 1
				const week = new Date(date.getFullYear(), date.getMonth(), nbDay, 0, 0, 0)
				nbDay = week.getDate()
				if (nbDay < 10)
					nbDay = '0' + nbDay
				let month = week.getMonth() + 1
				if (month < 10)
					month = '0' + month
				const index = nbDay + "/" + month + "/" + week.getFullYear()
				if (!logsByWeek[index])
					logsByWeek[index] = []
				logsByWeek[index].push(logs[i])
				if (logsByComponents[logs[i].component] === undefined)
					logsByComponents[logs[i].component] = 1
				else
					logsByComponents[logs[i].component]++
			}
			const countByUsers = Object.keys(logsByUsers).length
			const installedApp = (countInstalledApp > 0) ? ((countInstalledApp / logs.length) * 100).toFixed(2) : 0
			const visitsByUser = (countByUsers > 0) ? (logs.length / countByUsers).toFixed(2) : 0
			this.setState({
				logs: logs,
				installedApp: installedApp,
				visitsByUser: visitsByUser,
				logsByWeek: logsByWeek,
				logsByComponents: logsByComponents,
				total: logs.length
			})
		})
		.catch(err => {
			console.log("could not find logs", err)
		})
	}

	render() {
		const permissions = this.props.user.permissions
		if (!permissions || !permissions.stats || permissions.stats === 0)
			return (<Redirect to="/" />)
		if (this.state.logs === null)
		{
			return (
				<div className="loader">
					<Loader />
				</div>
			)
		}
		else
		{
			let countByWeek = -1
			let logsByWeek = []
			let total = this.state.total
			for (let key in this.state.logsByWeek)
			{
				let day = key.substr(0, 2)
				let month = key.substr(3, 2)
				let year = key.substr(6, 4)
				const date = new Date(year, Number(month) - 1, Number(day) + 6, 0, 0, 0)
				day = date.getDate()
				if (day < 10)
					day = '0' + day
				month = date.getMonth() + 1
				if (month < 10)
					month = '0' + month
				year = date.getYear()
				const end = day + "/" + month + "/" + date.getFullYear()
				countByWeek++
				const jsx = (
					<p className="overview" key={countByWeek}>
						<strong>{key} - {end}</strong> : {total} <em>(+{this.state.logsByWeek[key].length})</em>
					</p>
				)
				total -= this.state.logsByWeek[key].length
				logsByWeek.push(jsx)
			}
			const pages = this.state.logsByComponents["Pages"] + this.state.logsByComponents["Categories"]
			const animations = this.state.logsByComponents["Animations"] + this.state.logsByComponents["AnimationDays"]
			console.log(this.state.logsByComponents)
			return (
				<div className="wrapper-content">
					<div className="row">
						<div className="module col s6">
							<h2 className="title-description">Vue d'ensemble</h2>
							<p className="overview">
								<strong>{this.state.total}</strong> : nombre total de visites
							</p>
							<p className="overview">
								<strong>{this.state.visitsByUser}</strong> : nombre de visites / utilisateur
							</p>
							<p className="overview">
								<strong>{this.state.installedApp}%</strong> : visites depuis l'application téléchargée
							</p>
							<p className="overview">
								<strong>{this.state.logsByComponents["Home"]}</strong> : nombre de visites sur la page d'accueil
							</p>
							<p className="overview">
								<strong>{pages}</strong> : nombre de visites sur les pages
							</p>
							<p className="overview">
								<strong>{animations}</strong> : nombre de visites sur les animations
							</p>
							<p className="overview">
								<strong>{this.state.logsByComponents["Notifications"]}</strong> : nombre de visites sur la page "Notifications"
							</p>
							<p className="overview">
								<strong>{this.state.logsByComponents["Membres"]}</strong> : nombre de visites sur la page "Membres"
							</p>
						</div>
						<div className="module col s6">
							<h2 className="title-description">Nombre de visites semaine par semaine</h2>
							{logsByWeek.map((jsx) => {
								return jsx
							})}
						</div>
					</div>
				</div>
			)
		}
	}
}

export default Stats

import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'
import connexion from '../../connexion'
import tools from '../../tools'
import '../Core/Main.css'

class Users extends Component {
	constructor(props) {
		super(props)
		this.state = {
			form1: {
				client_number: '',
				lastname: '',
				firstname: '',
				email: ''
			},
			form2: {
				avatar: null,
				lastname: '',
				firstname: '',
				email: '',
				username: '',
				password: '',
				confirm: ''
			},
			form3: {
				client_number: '',
				lastname: '',
				firstname: '',
				email: '',
				phone: '',
				code: ''
			},
			form4: {
				client_number: '',
				stay_number: '',
				location: ''
			},
			form5: {
				file: null
			},
			error: null,
			success: null,
			result: null,
			redirect: false
		}
		this.props.updateNavbarTitle('Gestion des utilisateurs')
	}

	setErrorMsg = (msg) => {
		this.setState({ error: msg, success: null })
	}

	setSuccessMsg = (msg) => {
		this.setState({ error: null, success: msg })
	}

	clearFlash = () => {
		this.setState({ error: null, success: null })
	}

	webuserNumber = (event) => {
		const form1 = {
			client_number: event.target.value,
			lastname: this.state.form1.lastname,
			firstname: this.state.form1.firstname,
			email: this.state.form1.email
		}
		this.setState({ form1: form1 })
	}

	webuserLastname = (event) => {
		const form1 = {
			client_number: this.state.form1.client_number,
			lastname: event.target.value,
			firstname: this.state.form1.firstname,
			email: this.state.form1.email
		}
		this.setState({ form1: form1 })
	}

	webuserFirstname = (event) => {
		const form1 = {
			client_number: this.state.form1.client_number,
			lastname: this.state.form1.lastname,
			firstname: event.target.value,
			email: this.state.form1.email
		}
		this.setState({ form1: form1 })
	}

	webuserEmail = (event) => {
		const form1 = {
			client_number: this.state.form1.client_number,
			lastname: this.state.form1.lastname,
			firstname: this.state.form1.firstname,
			email: event.target.value
		}
		this.setState({ form1: form1 })
	}

	backuserAvatar = (event) => {
		const reader = new FileReader()
		const file = event.target.files[0]
		reader.addEventListener("load", () => {
			const form2 = {
				...this.state.form2,
				avatar: reader.result
			}
			this.setState({ form2: form2 })
		}, false)
		if (file)
			reader.readAsDataURL(file)
	}

	backuserLastname = (event) => {
		const form2 = {
			...this.state.form2,
			lastname: event.target.value
		}
		this.setState({ form2: form2 })
	}

	backuserFirstname = (event) => {
		const form2 = {
			...this.state.form2,
			firstname: event.target.value
		}
		this.setState({ form2: form2 })
	}

	backuserEmail = (event) => {
		const form2 = {
			...this.state.form2,
			email: event.target.value
		}
		this.setState({ form2: form2 })
	}

	backuserUsername = (event) => {
		const form2 = {
			...this.state.form2,
			username: event.target.value
		}
		this.setState({ form2: form2 })
	}

	backuserPassword = (event) => {
		const form2 = {
			...this.state.form2,
			password: event.target.value
		}
		this.setState({ form2: form2 })
	}

	backuserConfirm = (event) => {
		const form2 = {
			...this.state.form2,
			confirm: event.target.value
		}
		this.setState({ form2: form2 })
	}

	clientNumber = (event) => {
		const form3 = {
			client_number: event.target.value,
			lastname: this.state.form3.lastname,
			firstname: this.state.form3.firstname,
			email: this.state.form3.email,
			phone: this.state.form3.phone,
			code: this.state.form3.code
		}
		this.setState({ form3: form3 })
	}

	clientLastname = (event) => {
		const form3 = {
			client_number: this.state.form3.client_number,
			lastname: event.target.value,
			firstname: this.state.form3.firstname,
			email: this.state.form3.email,
			phone: this.state.form3.phone,
			code: this.state.form3.code
		}
		this.setState({ form3: form3 })
	}

	clientFirstname = (event) => {
		const form3 = {
			client_number: this.state.form3.client_number,
			lastname: this.state.form3.lastname,
			firstname: event.target.value,
			email: this.state.form3.email,
			phone: this.state.form3.phone,
			code: this.state.form3.code
		}
		this.setState({ form3: form3 })
	}

	clientEmail = (event) => {
		const form3 = {
			client_number: this.state.form3.client_number,
			lastname: this.state.form3.lastname,
			firstname: this.state.form3.firstname,
			email: event.target.value,
			phone: this.state.form3.phone,
			code: this.state.form3.code
		}
		this.setState({ form3: form3 })
	}

	clientPhone = (event) => {
		const form3 = {
			client_number: this.state.form3.client_number,
			lastname: this.state.form3.lastname,
			firstname: this.state.form3.firstname,
			email: this.state.form3.email,
			phone: event.target.value,
			code: this.state.form3.code
		}
		this.setState({ form3: form3 })
	}

	clientCode = (event) => {
		const form3 = {
			client_number: this.state.form3.client_number,
			lastname: this.state.form3.lastname,
			firstname: this.state.form3.firstname,
			email: this.state.form3.email,
			phone: this.state.form3.phone,
			code: event.target.value
		}
		this.setState({ form3: form3 })
	}

	stayClient = (event) => {
		const form4 = {
			client_number: event.target.value,
			stay_number: this.state.form4.stay_number,
			location: this.state.form4.location
		}
		this.setState({ form4: form4 })
	}

	stayNumber = (event) => {
		const form4 = {
			client_number: this.state.form4.client_number,
			stay_number: event.target.value,
			location: this.state.form4.location
		}
		this.setState({ form4: form4 })
	}

	stayLocation = (event) => {
		const form4 = {
			client_number: this.state.form4.client_number,
			stay_number: this.state.form4.stay_number,
			location: event.target.value
		}
		this.setState({ form4: form4 })
	}

	handleSearchWebuser = (event) => {
		event.preventDefault()
		const client_number = this.state.form1.client_number
		const lastname = this.state.form1.lastname
		const firstname = this.state.form1.firstname
		const email = this.state.form1.email
		if (client_number !== "" || lastname !== "" || firstname !== "" || email !== "")
		{
			fetch(connexion.connect.url + 'webapp/users/search', {
				method: 'POST',
				body: JSON.stringify({
					client_number: client_number,
					lastname: lastname,
					firstname: firstname,
					email: email
				})
			})
			.then(res => res.json())
			.then(users => {
				this.setState({ result: users, redirect: 1 })
			})
			.catch(err => {
				console.log(err)
			})
		}
	}

	handleBackUser = (event) => {
		event.preventDefault()
		const avatar = this.state.form2.avatar
		const lastname = this.state.form2.lastname
		const firstname = this.state.form2.firstname
		const email = this.state.form2.email
		const username = this.state.form2.username
		const password = this.state.form2.password
		const confirm = this.state.form2.confirm
		if (lastname.length === 0)
			this.setErrorMsg("Veuillez indiquer le nom")
		else if (firstname.length === 0)
			this.setErrorMsg("Veuillez indiquer le prénom")
		else if (email.length === 0)
			this.setErrorMsg("Veuillez indiquer l'adresse e-mail")
		else if (tools.testEmail(email) === false)
			this.setErrorMsg("Adresse e-mail invalide")
		else if (username.length === 0)
			this.setErrorMsg("Veuillez indiquer le nom d'utilisateur")
		else if (username.length < 4)
			this.setErrorMsg("Nom d'utilisateur trop court (4 caractères min.)")
		else if (password.length === 0)
			this.setErrorMsg("Veuillez indique le mot de passe")
		else if (confirm.length === 0)
			this.setErrorMsg("Veuillez indiquer la confirmation du mot de passe")
		else if (password.length < 8)
			this.setErrorMsg("Mot de passe trop court (8 caractères min.)")
		else if (password !== confirm)
			this.setErrorMsg("Le mot de passe et sa confirmation sont différents")
		else
		{
			fetch(connexion.connect.url + 'dashboard/users/insert', {
				method: 'POST',
				body: JSON.stringify({
					lastname: lastname,
					firstname: firstname,
					email: email,
					username: username,
					password: password,
					avatar: avatar
				})
			})
			.then(res => res.json())
			.then(ret => {
				if (ret === false)
					this.setErrorMsg("Une erreur s'est produite")
				else if (ret === 1)
					this.setErrorMsg("Un autre utilisateur avec le même identifiant existe déjà")
				else
				{
					const form2 = {
						avatar: null,
						lastname: '',
						firstname: '',
						email: '',
						username: '',
						password: '',
						confirm: ''
					}
					this.setState({ form2: form2 })
					this.setSuccessMsg("Le nouvel utilisateur à bien été créé")
				}
			})
			.catch(err => {
				this.setErrorMsg("Une erreur s'est produite")
				console.log(err)
			})
		}
	}

	handleSearchClient = (event) => {
		event.preventDefault()
		const client_number = this.state.form3.client_number
		const lastname = this.state.form3.lastname
		const firstname = this.state.form3.firstname
		const email = this.state.form3.email
		const phone = this.state.form3.phone
		const code = this.state.form3.code
		if (client_number !== "" || lastname !== "" || firstname !== "" || email !== "" || phone !== "" || code !== "")
		{
			fetch(connexion.connect.url + 'dashboard/clients/search', {
				method: 'POST',
				body: JSON.stringify({
					client_number: client_number,
					lastname: lastname,
					firstname: firstname,
					email: email,
					phone: phone,
					code: code
				})
			})
			.then(res => res.json())
			.then(clients => {
				this.setState({ result: clients, redirect: 2 })
			})
			.catch(err => {
				console.log(err)
			})
		}
	}

	handleSearchStay = (event) => {
		event.preventDefault()
		const client_number = this.state.form4.client_number
		const stay_number = this.state.form4.stay_number
		const location = this.state.form4.location
		if (client_number !== "" || stay_number !== "" || location !== "")
		{
			fetch(connexion.connect.url + 'dashboard/stays/search', {
				method: 'POST',
				body: JSON.stringify({
					client_number: client_number,
					stay_number: stay_number,
					location: location
				})
			})
			.then(res => res.json())
			.then(stays => {
				this.setState({ result: stays, redirect: 3 })
			})
			.catch(err => {
				console.log(err)
			})
		}
	}

	handleFile = (event) => {
		const reader = new FileReader()
		const file = event.target.files[0]
		reader.addEventListener("load", () => {
			const form5 = {
				file: reader.result
			}
			this.setState({ form5: form5 })
		}, false)
		if (file)
			reader.readAsText(file)
	}

	submitExtraction = (event) => {
		event.preventDefault()
		const file = this.state.form5.file
		if (file === null)
			this.setErrorMsg("Veuillez sélectionner un fichier")
		else
		{
			fetch(connexion.connect.url + 'dashboard/upload/file', {
				method: 'POST',
				body: JSON.stringify({
					file: file
				})
			})
			.then(res => res.json())
			.then(ret => {
				if (ret === false)
					this.setErrorMsg("Une erreur s'est produite")
				else
					this.setSuccessMsg("Le fichier à bien été téléchargé")
			})
			.catch(err => {
				this.setErrorMsg("Une erreur s'est produite")
				console.log(err)
			})
		}
	}

	render() {
		if (this.state.redirect === false)
		{
			let prev = null
			if (this.state.form2.avatar !== null)
				prev = (<img src={this.state.form2.avatar} className="avatar" alt="avatar" />)
			return (
				<div className="wrapper-content">
					<p className="error center">{this.state.error}</p>
					<p className="success center">{this.state.success}</p>
					<div className="row">
						<div className="module col s6">
							<h2 className="title-description">Utilisateurs Webapp</h2>
							<p><Link to="/users/webapp">Voir la liste des utilisateurs (webapp)</Link></p>
							<p className="underline">Rechercher un utilisateur :</p>
							<form className="module-form" onSubmit={this.handleSearchWebuser}>
								<label>
									<p className="column-20">Numéro client :</p>
									<input type="text" value={this.state.form1.client_number} onChange={this.webuserNumber} />
								</label>
								<label>
									<p className="column-20">Nom :</p>
									<input type="text" value={this.state.form1.lastname} onChange={this.webuserLastname} />
								</label>
								<label>
									<p className="column-20">Prénom :</p>
									<input type="text" value={this.state.form1.firstname} onChange={this.webuserFirstname} />
								</label>
								<label>
									<p className="column-20">E-mail :</p>
									<input type="text" value={this.state.form1.email} onChange={this.webuserEmail} />
								</label>
								<button type="submit" className="waves-effect waves-light btn-small">Rechercher</button>
							</form>
						</div>
						<div className="module col s6">
							<h2 className="title-description">Utilisateurs Dashboard</h2>
							<p><Link to="/users/dashboard">Voir la liste des utilisateurs (dashboard)</Link></p>
							<p className="underline">Ajouter un utilisateur :</p>
							<form className="module-form" onSubmit={this.handleBackUser}>
								<label>
									<p>Avatar :</p>
									<input type="file" onChange={this.backuserAvatar} />
								</label>
								{prev}
								<label>
									<p>Nom :</p>
									<input type="text" value={this.state.form2.lastname} onChange={this.backuserLastname} />
								</label>
								<label>
									<p>Prénom :</p>
									<input type="text" value={this.state.form2.firstname} onChange={this.backuserFirstname} />
								</label>
								<label>
									<p>E-mail :</p>
									<input type="text" value={this.state.form2.email} onChange={this.backuserEmail} />
								</label>
								<label>
									<p>Identifiant :</p>
									<input type="text" value={this.state.form2.username} onChange={this.backuserUsername} />
								</label>
								<label>
									<p>Mot de passe :</p>
									<input type="password" value={this.state.form2.password} onChange={this.backuserPassword} />
								</label>
								<label>
									<p>Confirmation du mot de passe :</p>
									<input type="password" value={this.state.form2.confirm} onChange={this.backuserConfirm} />
								</label>
								<button type="submit" className="waves-effect waves-light btn-small">Créer</button>
							</form>
						</div>
					</div>
					<div className="row">
						<div className="module col s4">
							<h2 className="title-description">Fiches clients</h2>
							<p><Link to="/users/clients">Voir la liste des fiches clients</Link></p>
							<p className="underline">Rechercher un client :</p>
							<form className="module-form" onSubmit={this.handleSearchClient}>
								<label>
									<p className="column-20">Numéro client :</p>
									<input type="text" value={this.state.form3.client_number} onChange={this.clientNumber} />
								</label>
								<label>
									<p className="column-20">Nom :</p>
									<input type="text" value={this.state.form3.lastname} onChange={this.clientLastname} />
								</label>
								<label>
									<p className="column-20">Prénom :</p>
									<input type="text" value={this.state.form3.firstname} onChange={this.clientFirstname} />
								</label>
								<label>
									<p className="column-20">E-mail :</p>
									<input type="text" value={this.state.form3.email} onChange={this.clientEmail} />
								</label>
								<label>
									<p className="column-20">Téléphone :</p>
									<input type="text" value={this.state.form3.phone} onChange={this.clientPhone} />
								</label>
								<label>
									<p className="column-20">Code d'activation :</p>
									<input type="text" value={this.state.form3.code} onChange={this.clientCode} />
								</label>
								<button type="submit" className="waves-effect waves-light btn-small">Rechercher</button>
							</form>
						</div>
						<div className="module col s4">
							<h2 className="title-description">Séjours</h2>
							<p><Link to="/users/sejours">Voir la liste des séjours</Link></p>
							<p className="underline">Rechercher un client :</p>
							<form className="module-form" onSubmit={this.handleSearchStay}>
								<label>
									<p className="column-20">Numéro client :</p>
									<input type="text" value={this.state.form4.client_number} onChange={this.stayClient} />
								</label>
								<label>
									<p className="column-20">Numéro séjour :</p>
									<input type="text" value={this.state.form4.stay_number} onChange={this.stayNumber} />
								</label>
								<label>
									<p className="column-20">Emplacement :</p>
									<input type="text" value={this.state.form4.location} onChange={this.stayLocation} />
								</label>
								<button type="submit" className="waves-effect waves-light btn-small">Rechercher</button>
							</form>
						</div>
						<div className="module col s4">
							<h2 className="title-description">Importer des fiches clients</h2>
							<form className="module-form" onSubmit={this.submitExtraction}>
								<label>
									<p>Télécharger un fichier d'extraction :</p>
									<input type="file" onChange={this.handleFile} />
								</label>
								<button className="waves-effect waves-light btn-small" type="submit">Télécharger</button>
							</form>
						</div>
					</div>
				</div>
			)
		}
		else if (this.state.redirect === 1)
		{
			let path = null
			if (this.state.result.length === 1)
				path = '/users/webapp/' + this.state.result[0]._id
			else
			{
				path = {
					pathname: '/users/webapp',
					users: this.state.result
				}
			}
			return (
				<div>
					<Redirect to={path} />
				</div>
			)
		}
		else if (this.state.redirect === 2)
		{
			let path = null
			if (this.state.result.length === 1)
				path = '/users/clients/' + this.state.result[0].client_number
			else
			{
				path = {
					pathname: '/users/clients',
					clients: this.state.result
				}
			}
			return (
				<div>
					<Redirect to={path} />
				</div>
			)
		}
		else if (this.state.redirect === 3)
		{
			let path = null
			if (this.state.result.length === 1)
				path = '/users/sejours/' + this.state.result[0].stay_number
			else
			{
				path = {
					pathname: '/users/sejours',
					stays: this.state.result
				}
			}
			return (
				<div>
					<Redirect to={path} />
				</div>
			)
		}
	}
}

export default Users

import React, { Component } from 'react'
import Loader from '../UI/Loader'
import connexion from '../../connexion'
import logo from '../../images/logo-pc-default.png'
import bgLogin from '../../images/bg-login6.jpg'
import { XCircleIcon } from '@heroicons/react/solid'

class Signin extends Component {
	constructor(props) {
		super(props)
		this.state = {
			form: {
				login: "",
				password: ""
			},
			attempt_submit: false,
			error: null,
		}
		this.updateUser = props.updateUser
	}

	handleLogin = (event) => {
		let form = {
			login: event.target.value,
			password: this.state.form.password
		}
		this.setState({ form: form })
	}

	handlePassword = (event) => {
		let form = {
			login: this.state.form.login,
			password: event.target.value
		}
		this.setState({ form: form })
	}

	handleSubmit = (event) => {
		event.preventDefault()
		this.setState({ attempt_submit: true })
		fetch(connexion.connect.url + 'dashboard/users/login', {
			method: 'POST',
			body: JSON.stringify({
				login: this.state.form.login,
				password: this.state.form.password
			})
		})
		.then(res => res.json())
		.then(user => {
			if (user !== false)
				this.updateUser(user)
			else
				this.setState({ error: 'Identifiant ou mot de passe incorrect.', attempt_submit: false })
		})
		.catch(err => {
			this.setState({ error: 'Une erreur s\'est produite.', attempt_submit: false })
			console.log(err)
		})
	}

	render() {
		const error = this.state.error
		const bgSize = {
			height: '100vh'
		}
		let sendbtn = (
			<div>
				<button
					type="submit"
					className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
				>
					Se connecter
				</button>
			</div>				
		)
		let errorMsg = null
		if (error)
		{
			errorMsg = (
				<div className="rounded-md bg-red-50 p-4">
					<div className="flex">
						<div className="flex-shrink-0">
							<XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
						</div>
						<div className="ml-3">
							<h3 className="text-sm font-medium text-red-800">{error}</h3>
						</div>
					</div>
				</div>
			)
		}
		if (this.state.attempt_submit === true)
		{
			sendbtn = (
				<div className="center">
					<Loader />
				</div>
			)
		}
		return (
			<div style={bgSize}>
				<div className="h-full bg-white flex">
					<div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
						<div className="mx-auto w-full max-w-sm lg:w-96">
							<div>
								<img
									className="h-24 w-auto"
									src={logo}
									alt="Workflow"
								/>
								<h2 className="mt-6 text-3xl font-extrabold text-gray-900">Bienvenue !</h2>
							</div>
							<div className="mt-8">
								<div className="mt-6">
									<form onSubmit={this.handleSubmit} className="space-y-6">
										<div>
											<label htmlFor="email" className="block text-sm font-medium text-gray-700">
												Identifiant
											</label>
											<div className="mt-1">
												<input
													type="text"
													required
													value={this.state.form.login} 
													onChange={this.handleLogin}
													className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
												/>
											</div>
										</div>
										<div className="space-y-1">
											<label htmlFor="password" className="block text-sm font-medium text-gray-700">
												Mot de passe
											</label>
											<div className="mt-1">
												<input
													type="password"
													value={this.state.form.password} 
													onChange={this.handlePassword}
													required
													className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
												/>
											</div>
										</div>
										{/* <div className="flex items-center justify-between">
											<div className="text-sm">
												<a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
													Mot de passe oublié ?
												</a>
											</div>
										</div> */}
										{sendbtn}
										{errorMsg}
									</form>
								</div>
							</div>
						</div>
					</div>
					<div className="hidden lg:block relative w-0 flex-1">
						<img
							className="absolute inset-0 h-full w-full object-cover"
							src={bgLogin}
							alt="palmspring"
						/>
					</div>
				</div>
    		</div>
		)}
	}

export default Signin

import React, { Component } from 'react'
import '../Core/Main.css'
import './RenderPage.css'

class RenderPage extends Component {
	printSection = (icon, translations, phone, email, website, localisation, facebook, instagram, key) => {
		let jsx = null
		let pictoJSX = null
		let titleJSX = null
		let prev = null
		const icons = this.props.icons
		const theme = this.props.theme
		for (let i = 0; i < icons.length; i++)
		{
			if (icons[i]._id === icon)
			{
				for (let j = 0; j < icons[i].fill.length; j++)
				{
					if (icons[i].fill[j].theme === theme._id)
					{
						prev = icons[i].fill[j].icon
						break
					}
				}
				break
			}
		}
		let phoneJSX = null
		let emailJSX = null
		let websiteJSX = null
		let localisationJSX = null
		let facebookJSX = null
		let instagramJSX = null
		if (phone)
		{
			const phone_number = "tel:" + phone
			phoneJSX = (
				<a href={phone_number}>
					<span className="inline-flex items-center rounded-full bg-indigo-100 px-3 py-0.5 text-sm font-medium text-indigo-800">
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="text-indigo-800 w-6 h-6">
							<path fillRule="evenodd" d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z" clipRule="evenodd" />
						</svg>
						Téléphone
					</span>
				</a>
			)
		}
		if (email)
		{
			const email_txt = "mailto:" + email
			emailJSX = (
				<a href={email_txt}>
					<span className="inline-flex items-center rounded-full bg-indigo-100 px-3 py-0.5 text-sm font-medium text-indigo-800">
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="text-indigo-800 w-6 h-6">
							<path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
							<path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
						</svg>
						E-mail
					</span>
				</a>
			)
		}
		if (website)
		{
			websiteJSX = (
				<a href={website}>
					<span className="inline-flex items-center rounded-full bg-indigo-100 px-3 py-0.5 text-sm font-medium text-indigo-800">
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="text-indigo-800 w-6 h-6">
							<path d="M21.721 12.752a9.711 9.711 0 00-.945-5.003 12.754 12.754 0 01-4.339 2.708 18.991 18.991 0 01-.214 4.772 17.165 17.165 0 005.498-2.477zM14.634 15.55a17.324 17.324 0 00.332-4.647c-.952.227-1.945.347-2.966.347-1.021 0-2.014-.12-2.966-.347a17.515 17.515 0 00.332 4.647 17.385 17.385 0 005.268 0zM9.772 17.119a18.963 18.963 0 004.456 0A17.182 17.182 0 0112 21.724a17.18 17.18 0 01-2.228-4.605zM7.777 15.23a18.87 18.87 0 01-.214-4.774 12.753 12.753 0 01-4.34-2.708 9.711 9.711 0 00-.944 5.004 17.165 17.165 0 005.498 2.477zM21.356 14.752a9.765 9.765 0 01-7.478 6.817 18.64 18.64 0 001.988-4.718 18.627 18.627 0 005.49-2.098zM2.644 14.752c1.682.971 3.53 1.688 5.49 2.099a18.64 18.64 0 001.988 4.718 9.765 9.765 0 01-7.478-6.816zM13.878 2.43a9.755 9.755 0 016.116 3.986 11.267 11.267 0 01-3.746 2.504 18.63 18.63 0 00-2.37-6.49zM12 2.276a17.152 17.152 0 012.805 7.121c-.897.23-1.837.353-2.805.353-.968 0-1.908-.122-2.805-.353A17.151 17.151 0 0112 2.276zM10.122 2.43a18.629 18.629 0 00-2.37 6.49 11.266 11.266 0 01-3.746-2.504 9.754 9.754 0 016.116-3.985z" />
						</svg>
						Site web
					</span>
				</a>
			)
		}
		if (localisation)
		{
			localisationJSX = (
				<a href={localisation}>
					<span className="inline-flex items-center rounded-full bg-indigo-100 px-3 py-0.5 text-sm font-medium text-indigo-800">
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="text-indigo-800 w-6 h-6">
							<path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM6.262 6.072a8.25 8.25 0 1010.562-.766 4.5 4.5 0 01-1.318 1.357L14.25 7.5l.165.33a.809.809 0 01-1.086 1.085l-.604-.302a1.125 1.125 0 00-1.298.21l-.132.131c-.439.44-.439 1.152 0 1.591l.296.296c.256.257.622.374.98.314l1.17-.195c.323-.054.654.036.905.245l1.33 1.108c.32.267.46.694.358 1.1a8.7 8.7 0 01-2.288 4.04l-.723.724a1.125 1.125 0 01-1.298.21l-.153-.076a1.125 1.125 0 01-.622-1.006v-1.089c0-.298-.119-.585-.33-.796l-1.347-1.347a1.125 1.125 0 01-.21-1.298L9.75 12l-1.64-1.64a6 6 0 01-1.676-3.257l-.172-1.03z" clipRule="evenodd" />
						</svg>
						Plan
					</span>
				</a>
			)
		}
		if (facebook)
		{
			facebookJSX = (
				<a href={facebook}>
					<span className="inline-flex items-center rounded-full bg-indigo-100 px-3 py-0.5 text-sm font-medium text-indigo-800">
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
							<path fillRule="evenodd" d="M5.337 21.718a6.707 6.707 0 01-.533-.074.75.75 0 01-.44-1.223 3.73 3.73 0 00.814-1.686c.023-.115-.022-.317-.254-.543C3.274 16.587 2.25 14.41 2.25 12c0-5.03 4.428-9 9.75-9s9.75 3.97 9.75 9c0 5.03-4.428 9-9.75 9-.833 0-1.643-.097-2.417-.279a6.721 6.721 0 01-4.246.997z" clipRule="evenodd" />
						</svg>
						Facebook
					</span>
				</a>
			)
		}
		if (instagram)
		{
			instagramJSX = (
				<a href={instagram}>
					<span className="inline-flex items-center rounded-full bg-indigo-100 px-3 py-0.5 text-sm font-medium text-indigo-800">
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="text-indigo-800 w-6 h-6">
							<path d="M12 9a3.75 3.75 0 100 7.5A3.75 3.75 0 0012 9z" />
							<path fillRule="evenodd" d="M9.344 3.071a49.52 49.52 0 015.312 0c.967.052 1.83.585 2.332 1.39l.821 1.317c.24.383.645.643 1.11.71.386.054.77.113 1.152.177 1.432.239 2.429 1.493 2.429 2.909V18a3 3 0 01-3 3h-15a3 3 0 01-3-3V9.574c0-1.416.997-2.67 2.429-2.909.382-.064.766-.123 1.151-.178a1.56 1.56 0 001.11-.71l.822-1.315a2.942 2.942 0 012.332-1.39zM6.75 12.75a5.25 5.25 0 1110.5 0 5.25 5.25 0 01-10.5 0zm12-1.5a.75.75 0 100-1.5.75.75 0 000 1.5z" clipRule="evenodd" />
						</svg>
						Instagram
					</span>
				</a>
			)
		}
		let title = ""
		let html = ""
		for (let i = 0; i < translations.length; i++)
		{
			if (translations[i].lang === this.props.current_lang)
			{
				title = translations[i].title
				html = translations[i].html
				break
			}
		}
		if (title !== null)
		{
			let alt = "picto " + title
			pictoJSX = (icon !== undefined) ? <img src={prev} alt={alt} className="picto" /> : null
			titleJSX = <p className="title">{title}</p>
			jsx = (
				<div className="page" key={key}>
					{pictoJSX} {titleJSX}
					{phoneJSX}{emailJSX}{websiteJSX}{localisationJSX}{facebookJSX}{instagramJSX}
					<div dangerouslySetInnerHTML={{ __html: html }}></div>
				</div>
			)
		}
		else
		{
			jsx = (
				<div className="page" key={key}>
					<div dangerouslySetInnerHTML={{ __html: html }}></div>
				</div>
			)
		}
		return (jsx)
	}

	render() {
		const page = (this.props.page) ? this.props.page : null
		if (page === null || (page.cover === null && (!page.sections || page.sections.length === 0)))
		{
			return (
				<div className="progress loader-image">
					<div className="indeterminate"></div>
				</div>
			)
		}
		else
		{
			const image = page.cover
			const sections = page.sections
			let section_k = -1
			let img = null
			if (page.cover !== null)
			{
				img = (
					<div className="photoWrap">
						<img src={image} alt="Couverture" />
					</div>
				)
			}
			return (
				<div className="renderPage">
					<div className="contentWrapper">
						{img}
						{
							sections.map(({ icon, translations, phone, email, website, localisation, facebook, instagram }) => {
								section_k++
								let ret = this.printSection(icon, translations, phone, email, website, localisation, facebook, instagram, section_k)
								return (ret)
							})
						}
					</div>
				</div>
			)
		}
	}
}

export default RenderPage

import React, { Component } from 'react'
import './RenderPage.css'

class RenderList extends Component {
	printContent = (style, icon, key, translations) => {
		let prev = null
		const icons = this.props.icons
		for (let i = 0; i < icons.length; i++)
		{
			if (icons[i]._id === icon)
			{
				for (let j = 0; j < icons[i].lineal.length; j++)
				{
					if (icons[i].lineal[j].theme === "blank")
					{
						prev = icons[i].lineal[j].icon
						break						
					}
				}
				break
			}
		}
		let text = ""
		for (let i = 0; i < translations.length; i++)
		{
			if (translations[i].lang === this.props.current_lang)
			{
				text = translations[i].text
				break
			}
		}
		return (
			<div className="content" style={style} key={key}>
				<img src={prev} alt="Icône" />
				<p style={{ 'paddingTop': '11%' }}>{text}</p>
			</div>
		)
	}

	render() {
		let colors = null
		if (this.props.theme)
		{
			const theme = this.props.theme
			colors = [theme.color1, theme.color2, theme.color3, theme.color4]
		}
		const list = (this.props.list) ? this.props.list : null
		if (list === null || colors === null || (!list.cover && (!list.elements || list.elements.length === 0)))
		{
			return (
				<div className="progress loader-image">
					<div className="indeterminate"></div>
				</div>
			)
		}
		else
		{
			const image = list.cover
			let img = null
			if (list.cover !== null)
			{
				img = (
					<div className="photoWrap">
						<img src={image} alt="Couverture" />
					</div>
				)
			}
			const elements = list.elements
			let elements_k = -1
			return (
				<div className="renderPage">
					<div className="contentWrapper">
						{img}
						{
							elements.map(({ icon, translations }) => {
								elements_k++
								return this.printContent({ backgroundColor: '#' + colors[elements_k % 4] }, icon, elements_k, translations)
							})
						}
					</div>
				</div>
			)
		}
	}
}

export default RenderList

import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import Modal from '../UI/Modal'
import Loader from '../UI/Loader'
import LangSelector from '../UI/LangSelector'
import M from "materialize-css"
import tools from '../../tools'
import '../Core/Main.css'
import EmptyFolder from '../UI/EmptyFolder'

class Template extends Component {
	constructor(props) {
		super(props)
		this.state = this.initState()
		this.question_max = 10
		this.length_max = 50
		this.introduction_length = 200
		this.qcm_length_max = 25
		this.qcm_max_answers = 5
		this.notification_title_length = 40
		this.notification_content_length = 140
		this.props.updateNavbarTitle('Gestion des avis clients')
	}

	initState = () => {
		return {
			create: false,
			edit: null,
			review_models: undefined,
			attempt_submit: false,
			init_dropdown: false,
			answerModal: null,
			open_modal: false,
			modal_msg: null,
			delete: false,
			form1: {
				name: '',
				type: '',
				notification_time: 'instant',
				questions: [{
					type: 'text',
					mandatory: true,
					translations: []
				}],
				translations: [],
				nb_questions: 1
			},
			current_lang: this.props.lang_default,
			error: this._initErrors(),
			success: null
		}
	}

	_initErrors = () => {
		let error = {
			global: null,
			button: null,
			add_question: null,
			remove_question: [null],
			name: null,
			title: null,
			introduction: null,
			notification_title: null,
			notification_content: null,
			subtitles: [],
			select_list: []
		}
		const nb_questions = (this.state) ? this.state.form1.nb_questions : 1
		for (let i = 0; i < nb_questions; i++)
		{
			error.remove_question.push(null)
			error.subtitles.push(null)
			error.select_list.push(null)
		}
		return error
	}

	setErrorMsg = (key, msg, id = null) => {
		if (key === "error_fetch_review_models")
		{
			key = "global"
			msg = tools.default_error_msg("Impossible de récupérer les formulaires d'avis", msg)
		}
		else if (key === "error_create_review_model")
		{
			key = "global"
			msg = tools.default_error_msg("Impossible de créer un formulaire d'avis", msg)
		}
		else if (key === "error_update_review_model")
		{
			key = "global"
			msg = tools.default_error_msg("Impossible de modifier un formulaire d'avis", msg)
		}
		else if (key === "error_delete_review_model")
		{
			key = "global"
			msg = tools.default_error_msg("Impossible de supprimer un formulaire d'avis", msg)
		}
		let error = this._initErrors()
		if (id !== null)
			error[key][id] = msg
		else
			error[key] = msg
		this.setState({ success: null, error: error })
	}

	setSuccessMsg = (msg) => {
		this.clearFlash()
		this.setState({ success: msg, error: this._initErrors() })
	}

	clearFlash = () => {
		this.setState({ success: null, error: this._initErrors() })
	}

	componentDidMount() {
		this.loadReviewModels()
	}
	
	componentDidUpdate (prevProps) {
		const elems = document.querySelectorAll('select')
		M.FormSelect.init(elems)
		if (this.state.init_dropdown === false)
		{
			const dropdowns = document.querySelectorAll('.dropdown-trigger')
			if (dropdowns.length > 0)
			{
				M.Dropdown.init(dropdowns)
				this.setState({ init_dropdown: true })
			}
		}
		if (prevProps.review_models !== this.props.review_models)
			this.loadReviewModels(true)
		if (prevProps.location.key !== this.props.location.key)
		{
			this.setState(this.initState())
			this.loadReviewModels()
		}
	}

	loadReviewModels = (fromProps = false) => {
		if (fromProps === true)
			this.setState({ review_models: this.props.review_models })
		else
		{
			this.props.fetchReviewModels()
			.then(review_models => {
				this.setState({ review_models: review_models })
			})
			.catch(err => {
				this.setErrorMsg("error_fetch_review_models", 1)
				console.log(err)
			})
		}
	}

	clickCreate = () => {
		this.clearFlash()
		const form1 = {
			name: '',
			type: '',
			notification_time: 'instant',
			questions: [{
				type: 'text',
				mandatory: true,
				translations: []
			}],
			translations: [],
			nb_questions: 1
		}
		this.setState({ edit: null, form1: form1, create: true })
	}
	
	handleName = (event) => {
		const form1 = {
			...this.state.form1,
			name: event.target.value
		}
		this.setState({ form1: form1 })
	}

	handleTitle = (event) => {
		let found = false
		const translations = this.state.form1.translations
		for (let i = 0; i < translations.length; i++)
		{
			if (translations[i].lang === this.state.current_lang)
			{
				found = true
				translations[i].title = event.target.value
				break
			}
		}
		if (!found)
		{
			translations.push({
				lang: this.state.current_lang,
				title: event.target.value,
				introduction: '',
				notification_title: '',
				notification_content: ''
			})
		}
		const form1 = {
			...this.state.form1,
			translations: translations
		}
		this.setState({ form1: form1 })
	}

	handleIntroduction = (event) => {
		let found = false
		const translations = this.state.form1.translations
		for (let i = 0; i < translations.length; i++)
		{
			if (translations[i].lang === this.state.current_lang)
			{
				found = true
				translations[i].introduction = event.target.value
				break
			}
		}
		if (!found)
		{
			translations.push({
				lang: this.state.current_lang,
				title: '',
				introduction: event.target.value,
				notification_title: '',
				notification_content: ''
			})
		}
		const form1 = {
			...this.state.form1,
			translations: translations
		}
		this.setState({ form1: form1 })
	}

	handleType = (event) => {
		const form1 = {
			...this.state.form1,
			type: event.target.value
		}
		this.setState({ form1: form1 })
	}

	handleTitleNotification = (event) => {
		let found = false
		const translations = this.state.form1.translations
		for (let i = 0; i < translations.length; i++)
		{
			if (translations[i].lang === this.state.current_lang)
			{
				found = true
				translations[i].notification_title = event.target.value
				break
			}
		}
		if (!found)
		{
			translations.push({
				lang: this.state.current_lang,
				title: '',
				introduction: '',
				notification_title: event.target.value,
				notification_content: ''
			})
		}
		const form1 = {
			...this.state.form1,
			translations: translations
		}
		this.setState({ form1: form1 })
	}

	handleContentNotification = (event) => {
		let found = false
		const translations = this.state.form1.translations
		for (let i = 0; i < translations.length; i++)
		{
			if (translations[i].lang === this.state.current_lang)
			{
				found = true
				translations[i].notification_content = event.target.value
				break
			}
		}
		if (!found)
		{
			translations.push({
				lang: this.state.current_lang,
				title: '',
				introduction: '',
				notification_title: '',
				notification_content: event.target.value
			})
		}
		const form1 = {
			...this.state.form1,
			translations: translations
		}
		this.setState({ form1: form1 })
	}

	handleTimeNotification = (event) => {
		const form1 = {
			...this.state.form1,
			notification_time: event.target.value
		}
		this.setState({ form1: form1 })
	}

	handleAddQuestion = () => {
		this.clearFlash()
		if (this.state.form1.nb_questions >= this.question_max)
			this.setErrorMsg('add_question', 'Vous ne pouvez pas ajouter plus de ' + this.question_max + ' questions')
		else
		{
			let questions = this.state.form1.questions
			questions.push({
				type: 'text',
				mandatory: true,
				translations: []
			})
			const form1 = {
				...this.state.form1,
				nb_questions: this.state.form1.nb_questions + 1,
				questions: questions
			}
			this.setState({ form1: form1 })
		}
	}

	handleRemoveQuestion = (question_id) => {
		this.clearFlash()
		if (this.state.form1.nb_questions <= 1)
			this.setErrorMsg('remove_question', 'Vous devez au moins avoir 1 question', question_id - 1)
		else
		{
			let questions = this.state.form1.questions
			questions.splice(question_id - 1, 1)
			const form1 = {
				...this.state.form1,
				nb_questions: this.state.form1.nb_questions - 1,
				questions: questions
			}
			this.setState({ form1: form1 })
		}
	}

	handleQuestionTitle = (question_id, event) => {
		const questions = this.state.form1.questions
		let found = false
		const question = questions[question_id - 1]
		for (let i = 0; i < question.translations.length; i++)
		{
			if (question.translations[i].lang === this.state.current_lang)
			{
				found = true
				question.translations[i].title = event.target.value
				break
			}
		}
		if (!found)
		{
			question.translations.push({
				lang: this.state.current_lang,
				title: event.target.value,
				select_list: ''
			})
		}
		questions[question_id - 1] = question
		const form1 = {
			...this.state.form1,
			questions: questions
		}
		this.setState({ form1: form1 })
	}

	handleQuestionType = (question_id, event) => {
		let questions = this.state.form1.questions
		const type = event.target.value
		const question = questions[question_id - 1]
		questions[question_id - 1] = {
			type: type,
			mandatory: question.mandatory,
			translations: question.translations
		}
		let translations = []
		for (let i = 0; i < question.translations.length; i++)
		{
			if (type === "select")
			{
				translations.push({
					lang: question.translations[i].lang,
					title: question.translations[i].title,
					select_list: ''
				})
			}
			else
			{
				translations.push({
					lang: question.translations[i].lang,
					title: question.translations[i].title
				})
			}
		}
		questions[question_id - 1].translations = translations
		const form1 = {
			...this.state.form1,
			questions: questions
		}
		this.setState({ form1: form1 })
	}

	handleQuestionMandatory = (question_id, event) => {
		let questions = this.state.form1.questions
		questions[question_id - 1].mandatory = (questions[question_id - 1].mandatory) ? false : true
		const form1 = {
			...this.state.form1,
			questions: questions
		}
		this.setState({ form1: form1 })
	}

	handleQuestionSelectList = (question_id, event) => {
		const questions = this.state.form1.questions
		let found = false
		const question = questions[question_id - 1]
		for (let i = 0; i < question.translations.length; i++)
		{
			if (question.translations[i].lang === this.state.current_lang)
			{
				found = true
				question.translations[i].select_list = event.target.value
				break
			}
		}
		if (!found)
		{
			question.translations.push({
				lang: this.state.current_lang,
				title: '',
				select_list: event.target.value
			})
		}
		questions[question_id - 1] = question
		const form1 = {
			...this.state.form1,
			questions: questions
		}
		this.setState({ form1: form1 })
	}

	_validateForm1 = () => {
		let error_obj = {
			global: null,
			add_question: null,
			remove_question: [],
			name: null,
			title: null,
			introduction: null,
			notification_title: null,
			notification_content: null,
			subtitles: [],
			select_list: []
		}
		for (let i = 0; i < this.state.form1.nb_questions; i++)
		{
			error_obj.remove_question.push(null)
			error_obj.subtitles.push(null)
			error_obj.select_list.push(null)
		}
		const name = this.state.form1.name
		const translations = this.state.form1.translations
		let error = false
		if (name.length === 0)
		{
			error = true
			error_obj.name = 'Le nom ne doit pas être vide'
		}
		else if (name.length > this.length_max)
		{
			error = true
			error_obj.name = 'Le nom ne doit pas dépasser ' + this.length_max + ' caractères'
		}
		let found_default_title = false
		let found_default_introduction = false
		let found_default_notification_title = false
		let found_default_notification_content = false
		for (let i = 0; i < translations.length; i++)
		{
			const lang = translations[i].lang
			const title = translations[i].title
			if (title.length === 0 && lang === this.props.lang_default)
			{
				error = true
				error_obj.title = "Le titre ne doit pas être vide (version " + lang.toUpperCase() + ")"
			}
			else if (title.length > this.length_max)
			{
				error = true
				error_obj.title = "Le titre ne doit pas dépasser " + this.length_max + " caractères (version " + lang.toUpperCase() + ")"
			}
			else if (lang === this.props.lang_default)
				found_default_title = true
			const introduction = translations[i].introduction
			if (introduction.length === 0 && lang === this.props.lang_default)
			{
				error = true
				error_obj.introduction = "Le texte d'introduction ne doit pas être vide (version " + lang.toUpperCase() + ")"
			}
			else if (introduction.length > this.introduction_length)
			{
				error = true
				error_obj.introduction = "Le texte d'introduction ne doit pas dépasser " + this.introduction_length + " caractères (version " + lang.toUpperCase() + ")"
			}
			else if (lang === this.props.lang_default)
				found_default_introduction = true
			const notification_title = translations[i].notification_title
			if (notification_title.length === 0 && lang === this.props.lang_default)
			{
				error = true
				error_obj.notification_title = "Le titre de la notification ne doit pas être vide (version " + lang.toUpperCase() + ")"
			}
			else if (notification_title.length > this.notification_title_length)
			{
				error = true
				error_obj.notification_title = "Le titre de la notification ne doit pas dépasser " + this.notification_title_length + " caractères (version " + lang.toUpperCase() + ")"
			}
			else if (lang === this.props.lang_default)
				found_default_notification_title = true
			const notification_content = translations[i].notification_content
			if (notification_content.length === 0 && lang === this.props.lang_default)
			{
				error = true
				error_obj.notification_content = "Le contenu de la notification ne doit pas être vide (version " + lang.toUpperCase() + ")"
			}
			else if (notification_content.length > this.notification_content_length)
			{
				error = true
				error_obj.notification_content = "Le contenu de la notification ne doit pas dépasser " + this.notification_content_length + " caractères (version " + lang.toUpperCase() + ")"
			}
			else if (lang === this.props.lang_default)
				found_default_notification_content = true
		}
		if (!found_default_title && !error_obj.title)
		{
			error = true
			error_obj.title = 'Le titre ne doit pas être vide (version ' + this.props.lang_default.toUpperCase() + ')'
		}
		if (!found_default_introduction && !error_obj.introduction)
		{
			error = true
			error_obj.introduction = 'Le texte d\'introduction ne doit pas être vide (version ' + this.props.lang_default.toUpperCase() + ')'
		}
		if (!found_default_notification_title && !error_obj.notification_title)
		{
			error = true
			error_obj.notification_title = 'Le titre de la notification ne doit pas être vide (version ' + this.props.lang_default.toUpperCase() + ')'
		}
		if (!found_default_notification_content && !error_obj.notification_content)
		{
			error = true
			error_obj.notification_content = 'Le contenu de la notification ne doit pas être vide (version ' + this.props.lang_default.toUpperCase() + ')'
		}
		for (let i = 0; i < this.state.form1.nb_questions; i++)
		{
			const question = this.state.form1.questions[i]
			const translations = question.translations
			let found_default_title = false
			let found_default_select_list = false
			for (let j = 0; j < translations.length; j++)
			{
				const lang = translations[j].lang
				const title = translations[j].title
				if (title.length === 0 && lang === this.props.lang_default)
				{
					error = true
					error_obj.subtitles[i] = "La question ne doit pas être vide (version " + lang.toUpperCase() + ")"
				}
				else if (title.length > this.length_max)
				{
					error = true
					error_obj.subtitles[i] = "La question ne doit pas dépasser " + this.length_max + " caractères (version " + lang.toUpperCase() + ")"
				}
				else if (lang === this.props.lang_default)
					found_default_title = true
				if (question.type === 'select')
				{
					const lang = translations[j].lang
					const select_list = translations[j].select_list
					if (select_list && select_list.length === 0 && lang === this.props.lang_default)
					{
						error = true
						error_obj.select_list[i] = "Vous devez spécifier des réponses au QCM (version " + lang.toUpperCase() + ")"
					}
					else if (select_list && select_list.length > 0)
					{
						if (lang === this.props.lang_default)
							found_default_select_list = true
						let qcm = select_list.split(',')
						let k = 0
						while (k < qcm.length)
						{
							if (qcm[k].trim().length === 0)
							{
								qcm.splice(k, 1)
								continue
							}
							k++
						}
						if (qcm.length === 0)
						{
							error = true
							error_obj.select_list[i] = 'Vous devez spécifier des réponses au QCM (version ' + lang.toUpperCase() + ")"
						}
						else if (qcm.length < 2)
						{
							error = true
							error_obj.select_list[i] = 'Vous devez spécifier au moins 2 réponses possibles (version ' + lang.toUpperCase() + ")"
						}
						else if (qcm.length > this.qcm_max_answers)
						{
							error = true
							error_obj.select_list[i] = 'Vous devez spécifier au maximum ' + this.qcm_max_answers + ' réponses possibles (version ' + lang.toUpperCase() + ")"
						}
						else
						{
							for (let j = 0; j < qcm.length; j++)
							{
								if (qcm[j].trim().length > this.qcm_length_max)
								{
									error = true
									error_obj.select_list[i] = 'Chaque réponse ne peut pas dépasser ' + this.qcm_length_max + ' caractères (version ' + lang.toUpperCase() + ")"
								}
							}
						}
					}
				}
			}
			if (!found_default_title && !error_obj.subtitles[i])
			{
				error = true
				error_obj.subtitles[i] = 'La question ne doit pas être vide (version ' + this.props.lang_default.toUpperCase() + ')'
			}
			if (question.type === "select" && !found_default_select_list && !error_obj.select_list[i])
			{
				error = true
				error_obj.select_list[i] = 'Vous devez spécifier des réponses au QCM (version ' + this.props.lang_default.toUpperCase() + ')'
			}
		}
		if (error === true)
		{
			error_obj.button = 'Il y a une ou plusieurs erreur(s) dans le formulaire'
			this.setState({ success: null, error: error_obj, attempt_submit: false })
		}
		return (!error) ? true : false
	}

	handleSubmit = (event) => {
		event.preventDefault()
		this.setState({ attempt_submit: true })
		if (this._validateForm1())
		{
			const translations = this.state.form1.translations
			let langs_not_found = []
			for (let i = 0; i < this.props.langs.length; i++)
			{
				let found = false
				for (let j = 0; j < translations.length; j++)
				{
					if (this.props.langs[i] === translations[j].lang && translations[j].title.length > 0 && translations[j].introduction.length > 0 &&
						translations[j].notification_title.length > 0 && translations[j].notification_content.length > 0)
					{
						found = true
						break
					}
				}
				if (!found)
					langs_not_found.push(this.props.langs[i])
			}
			if (langs_not_found.length > 0)
			{
				let msg = ""
				for (let i = 0; i < langs_not_found.length; i++)
				{
					if (i > 0)
						msg += ', '
					msg += langs_not_found[i].toUpperCase()
				}
				msg = "Le formulaire d'avis n'est pas traduit dans les langues suivantes : " + msg
				this.setState({ modal_msg: msg, open_modal: true, answerModal: this.answerModalEdit })
			}
			else
				this._insertReviewModel()
		}
	}

	_insertReviewModel = () => {
		if (this.state.create === true)
		{
			let time = 0
			const notification_time = this.state.form1.notification_time
			if (notification_time === '1day_after')
				time = 86400
			else if (notification_time === '2days_after')
				time = 86400 * 2
			else if (notification_time === '3days_after')
				time = 86400 * 3
			else if (notification_time === '4days_after')
				time = 86400 * 4
			else if (notification_time === '5days_after')
				time = 86400 * 5
			const review_model = {
				name: this.state.form1.name,
				type: this.state.form1.type,
				notification_time: time,
				questions: this.state.form1.questions,
				translations: this.state.form1.translations,
				user: this.props.user._id
			}
			this.props.insertReviewModel(review_model)
			.then(ret => {
				if (!ret)
				{
					this.setErrorMsg("error_create_review_model", 1)
					this.setState({ attempt_submit: false })
				}
				else if (ret.err_code === 1)
				{
					this.setErrorMsg('global', ret.err_message)
					this.setState({ attempt_submit: false })
				}
				else
				{
					const form1 = {
						name: '',
						notification_time: 'instant',
						questions: [{
							title: '',
							type: 'text',
							select_list: '',
							mandatory: true
						}],
						translations: [],
						nb_questions: 1
					}
					this.setSuccessMsg('Le modèle d\'avis a bien été créé')
					this.setState({ form1: form1, create: false, attempt_submit: false, open_modal: false })
				}
			})
			.catch(err => {
				this.setState({ attempt_submit: false, open_modal: false })
				this.setErrorMsg('error_create_review_model', 2)
				console.log(err)
			})
		}
		else if (this.state.edit !== null)
		{
			let time = 0
			const notification_time = this.state.form1.notification_time
			if (notification_time === '1day_after')
				time = 86400
			else if (notification_time === '2days_after')
				time = 86400 * 2
			else if (notification_time === '3days_after')
				time = 86400 * 3
			else if (notification_time === '4days_after')
				time = 86400 * 4
			else if (notification_time === '5days_after')
				time = 86400 * 5
			const review_model = {
				_id: this.state.edit._id,
				name: this.state.form1.name,
				type: this.state.form1.type,
				notification_time: time,
				questions: this.state.form1.questions,
				translations: this.state.form1.translations,
				user: this.props.user._id
			}
			this.props.updateReviewModel(review_model)
			.then(ret => {
				if (!ret)
				{
					this.setErrorMsg('error_update_review_model', 1)
					this.setState({ attempt_submit: false, open_modal: false })
				}
				else if (ret.err_code === 1)
				{
					this.setErrorMsg('global', ret.err_message)
					this.setState({ attempt_submit: false, open_modal: false })
				}
				else
				{
					const form1 = {
						name: '',
						notification_time: 'instant',
						questions: [{
							title: '',
							type: 'text',
							select_list: '',
							mandatory: true
						}],
						translations: [],
						nb_questions: 1
					}
					this.setSuccessMsg('Le modèle d\'avis a bien été mis à jour')
					this.setState({ form1: form1, edit: null, attempt_submit: false, open_modal: false })
				}
			})
			.catch(err => {
				this.setState({ attempt_submit: false, open_modal: false })
				this.setErrorMsg('error_update_review_model', 2)
				console.log(err)
			})
		}
	}

	clickEdit = (id) => {
		let edit = null
		let form1 = this.state.form1
		this.clearFlash()
		for (let i = 0; i < this.state.review_models.length; i++)
		{
			if (this.state.review_models[i]._id === id)
			{
				edit = this.state.review_models[i]
				form1 = { 
					name: edit.name,
					type: edit.type,
					notification_time: edit.notification_time,
					questions: edit.questions,
					translations: edit.translations,
					nb_questions: edit.questions.length
				}
				break
			}
		}
		this.setState({ edit: edit, form1: form1 })
	}

	clickDelete = (id) => {
		const msg = "Vous êtes sur le point de supprimer ce formulaire d'avis. Cette action est irréversible"
		this.setState({ delete: id, modal_msg: msg, open_modal: true, answerModal: this.answerModalDelete })
	}

	_deleteReviewModel = () => {
		this.props.deleteReviewModel(this.state.delete)
		.then(ret => {
			if (ret)
			{
				this.setState({ delete: false, open_modal: false })
				this.setSuccessMsg("Le modèle d'avis a bien été supprimé")
			}
			else
			{
				this.setState({ delete: false, open_modal: false })
				this.setErrorMsg('error_delete_review_model', 1)
			}
		})
		.catch(err => {
			this.setState({ delete: false, open_modal: false })
			console.log(err)
			this.setErrorMsg('error_delete_review_model', 2)
		})
	}

	answerModalEdit = (answer) => {
		if (answer === "yes")
			this._insertReviewModel()
		else
			this.setState({ attempt_submit: false, open_modal: false })
	}

	answerModalDelete = (answer) => {
		if (answer === "yes")
			this._deleteReviewModel()
		else
			this.setState({ delete: false, open_modal: false })
	}

	updateLang = (lang) => {
		this.setState({ current_lang: lang })
	}

	render() {
		const review_models = this.state.review_models
		const permissions = this.props.user.permissions
		if (!permissions || !permissions.reviews || permissions.reviews === 0)
			return (<Redirect to="/" />)
		else if (review_models === undefined)
		{
			return (
				<div className="loader">
					<Loader />
				</div>
			)
		}
		else if (this.state.create === true || this.state.edit !== null)
		{
			let btn = (this.state.create) ? "Enregistrer" : "Mettre à jour"
			let question_id = 0
			const questions = this.state.form1.questions
			let sendbtn = (
				<div className="center" style={{ marginTop: '20px' }}>
					<p className="error">{this.state.error.button}</p>
					<button type="submit" className="waves-effect waves-light btn-small">{btn}</button>
				</div>
			)
			if (this.state.attempt_submit === true)
			{
				sendbtn = (
					<div className="center">
						<Loader />
					</div>
				)
			}
			let printMsg = null
			if (this.state.error.global || this.state.success)
			{
				printMsg = (
					<div className="center">
						<p className="error">{this.state.error.global}</p>
						<p className="success">{this.state.success}</p>
					</div>
				)
			}
			let title = ""
			let introduction = ""
			let notification_title = ""
			let notification_content = ""
			for (let i = 0; i < this.state.form1.translations.length; i++)
			{
				if (this.state.form1.translations[i].lang === this.state.current_lang)
				{
					title = this.state.form1.translations[i].title
					introduction = this.state.form1.translations[i].introduction
					notification_title = this.state.form1.translations[i].notification_title
					notification_content = this.state.form1.translations[i].notification_content
					break
				}
			}
			return (
				<div className="wrapper-content">
					<Modal
						dismissible={false}
						content={this.state.modal_msg}
						open_modal={this.state.open_modal}
						answerModal={this.state.answerModal}
					/>
					<div className="row">
						<div className="module col s12">
							<h2 className="title-description">Paramétrer un questionnaire</h2>
							{printMsg}
							<LangSelector lang={this.state.current_lang} updateLang={this.updateLang} />
							<form className="module-form" onSubmit={this.handleSubmit}>
								<label>
									<p className="error">{this.state.error.name}</p>
									<p>Nom :</p>
									<input type="text" value={this.state.form1.name} onChange={this.handleName} />
								</label>
								<label>
									<p className="error">{this.state.error.title}</p>
									<p>Titre (version {this.state.current_lang.toUpperCase()}) :</p>
									<input type="text" value={title} onChange={this.handleTitle} />
								</label>
								<label>
									<p className="error">{this.state.error.introduction}</p>
									<p>Texte d'introduction (version {this.state.current_lang.toUpperCase()}) :</p>
									<input type="text" value={introduction} onChange={this.handleIntroduction} />
								</label>
								<label>
									<p>Type d'avis :</p>
									<select value={this.state.form1.type} onChange={this.handleType}>
										<option value="">Désactivé</option>
										<option value="global">Avis de fin de séjour</option>
									</select>
								</label>
								<label>
									<p className="error">{this.state.error.notification_title}</p>
									<p>Titre de la notification (version {this.state.current_lang.toUpperCase()}) :</p>
									<input type="text" value={notification_title} onChange={this.handleTitleNotification} />
								</label>
								<label>
									<p className="error">{this.state.error.notification_content}</p>
									<p>Contenu de la notification (version {this.state.current_lang.toUpperCase()}) :</p>
									<input type="text" value={notification_content} onChange={this.handleContentNotification} />
								</label>
								<label>
									<p>Envoyer la notification automatiquement :</p>
									<select value={this.state.form1.notification_time} onChange={this.handleTimeNotification}>
										<option value="instant">Au moment de l'évènement</option>
										<option value="1day_after">1 jour après</option>
										<option value="2days_after">2 jours après</option>
										<option value="3days_after">3 jours après</option>
										<option value="4days_after">4 jours après</option>
										<option value="5days_after">5 jours après</option>
									</select>
								</label>
								<LangSelector lang={this.state.current_lang} updateLang={this.updateLang} />
								{questions.map(({ type, mandatory, translations }) => {
									let title = ""
									let select_list = ""
									for (let i = 0; i < translations.length; i++)
									{
										if (translations[i].lang === this.state.current_lang)
										{
											title = translations[i].title
											select_list = translations[i].select_list
											break
										}
									}
									question_id++
									let question_txt = 'Question ' + question_id
									let selectListJSX = null
									if (type === "select")
									{
										selectListJSX = (
											<label>
												<p className="error">{this.state.error.select_list[question_id - 1]}</p>
												<p>
													Réponses possibles (pour le QCM) (version {this.state.current_lang.toUpperCase()}) : <span className="info">(Chaque réponse doit être séparée par une virgule)</span>
												</p>
												<input type="text" value={select_list} onChange={this.handleQuestionSelectList.bind(this, question_id)} />
											</label>
										)
									}
									return (
										<fieldset key={question_id}>
											<legend>{question_txt}</legend>
											<div className="center">
												<p className="error">{this.state.error.remove_question[question_id - 1]}</p>
												<button type="button" className="waves-effect waves-light btn red" onClick={this.handleRemoveQuestion.bind(this, question_id)}>Supprimer</button>
											</div>
											<div className="row">
												<div className="col s5">
													<label>
														<p className="error">{this.state.error.subtitles[question_id - 1]}</p>
														<p>Question (version {this.state.current_lang.toUpperCase()}) :</p>
														<input type="text" value={title} onChange={this.handleQuestionTitle.bind(this, question_id)} />
													</label>
												</div>
												<div className="col s5 offset-s2">
													<label>
														<p>Réponse obligatoire :</p>
														<input type="checkbox" checked={mandatory} onChange={this.handleQuestionMandatory.bind(this, question_id)} /><span></span>
													</label>
												</div>
											</div>
											<div className="row">
												<div className="col s5">
													<label>
														<p>Type de réponse :</p>
														<select value={type} onChange={this.handleQuestionType.bind(this, question_id)}>
															<option value="text">Texte</option>
															<option value="notation">Note (1 à 5)</option>
															<option value="select">QCM</option>
														</select>
													</label>
												</div>
												<div className="col s5 offset-s2">
													{selectListJSX}
												</div>
											</div>
										</fieldset>
									)
								})}
								<div className="center" style={{ marginTop: '20px' }}>
									<p className="error">{this.state.error.add_question}</p>
									<button type="button" className="waves-effect waves-light btn-small" onClick={this.handleAddQuestion}>Ajouter une question</button>
								</div>
								{sendbtn}
							</form>
						</div>
					</div>
				</div>
			)
		}
		else
		{
			let printMsg = null
			let empty_folder = ""
			if (this.state.error.global || this.state.success)
			{
				printMsg = (
					<div className="center">
						<p className="error">{this.state.error.global}</p>
						<p className="success">{this.state.success}</p>
					</div>
				)
			}
			if (!review_models || !review_models.length)
			{
				empty_folder = (
					<EmptyFolder 
						emptyFolderSubtitle="Pas de questionnaire paramétré"
					/>
				)
			}
			return (
				<div className="wrapper-content">
					<Modal
						dismissible={false}
						content={this.state.modal_msg}
						open_modal={this.state.open_modal}
						answerModal={this.state.answerModal}
					/>
					<div className="row">
						<div className="module transparent col s12">
							{printMsg}
							<button className="waves-effect waves-light btn" onClick={this.clickCreate}>Créer un questionnaire</button>
							<div className="clear"></div>
							{empty_folder}
							{review_models.map(({ _id, name, display }) => {
								return (
									<div className="vignette" key={_id}>
										<div className="title">
											<p onClick={this.clickEdit.bind(this, _id)}>{name}</p>
										</div>
										<div className="sep">
											<span className="more">
												<i className="dropdown-trigger material-icons" data-target={_id}>more_horiz</i>
												<ul id={_id} className="dropdown-content menu">
													<li onClick={this.clickEdit.bind(this, _id)}>Éditer</li>
													<li className="delete" onClick={this.clickDelete.bind(this, _id)}>Supprimer</li>
													<li className="sep-ul">Fermer</li>
												</ul>
											</span>
										</div>
									</div>
								)
							})}
						</div>
					</div>
				</div>
			)
		}
	}
}

export default Template

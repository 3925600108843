import React, { Component } from 'react'
import Loader from '../UI/Loader'
import IconPicker from '../UI/IconPicker'
import M from 'materialize-css'
import connexion from '../../connexion'
import '../Core/Main.css'

class Menu extends Component {
	constructor(props) {
		super(props)
		this.state = {
			// loaded: false,
			loaded: true,
			edit: false,
			icons: null,
			groups: null,
			menu: null,
			theme: null,
			form1: {
				logo: null
			},
			form2: {
				title: '',
				type: '',
				rubric: '',
				icon: null,
				prev: null
			},
			error: null,
			success: null
		}
		this.props.updateNavbarTitle('Administrer le menu')
	}

	componentDidMount() {
		this.loadMenu()
		this.loadColors()
		this.loadGroups()
		if (this.state.loaded === false)
		{
			fetch(connexion.connect.url + 'webapp/config/logo')
			.then(res => res.json())
			.then(logo => {
				const form1 = {
					logo: logo
				}
				this.setState({ form1: form1, loaded: true })
			})
			.catch(err => {
				console.log(err)
			})
		}
	}

	componentDidUpdate(prevProps) {
		const elems = document.querySelectorAll('select')
		M.FormSelect.init(elems)
		if (prevProps.groups !== this.props.groups)
			this.loadGroups()
		if (prevProps.menu !== this.props.menu)
			this.loadMenu()
	}

	loadMenu = () => {
		if (this.props.menu)
			this.setState({ menu: this.props.menu })
		// else
		// 	this.props.fetchMenu()
	}

	loadColors = () => {
		this.props.fetchTheme()
		.then(theme => {
			this.setState({ theme: theme })
		})
		.catch(err => console.log("Could not fetch theme", err))
	}

	loadGroups = () => {
		if (this.props.groups)
		{
			const groups = this.props.groups
			groups.sort((a, b) => {
				let name_a = null
				let name_b = null
				for (let i = 0; i < a.translations.length; i++)
				{
					if (a.translations[i].lang === this.state.current_lang)
					{
						name_a = a.translations[i].name
						break
					}
				}
				for (let i = 0; i < b.translations.length; i++)
				{
					if (b.translations[i].lang === this.state.current_lang)
					{
						name_b = b.translations[i].name
						break
					}
				}
				if (!name_a && !name_b)
					return 0
				else if (!name_a)
					return 1
				else if (!name_b)
					return -1
				else
					return name_a.localeCompare(name_b)
			})
			this.props.fetchIcons()
			.then(icons => {
				for (let k = 0; k < groups.length; k++)
				{
					for (let i = 0; i < icons.length; i++)
					{
						if (icons[i]._id === groups[k].icon)
						{
							for (let j = 0; j < icons[i].lineal.length; j++)
							{
								if (icons[i].lineal[j].theme === "blank")
								{
									groups[k].prev = icons[i].lineal[j].icon
									break
								}
							}
							break
						}
					}
				}
				this.setState({ icons: icons, groups: groups })
			})
			.catch(err => console.log(err))
		}
		else
			this.props.fetchGroups()
	}

	setErrorMsg = (msg) => {
		this.setState({ error: msg, success: null })
	}

	setSuccessMsg = (msg) => {
		this.setState({ error: null, success: msg })
	}

	clearFlash = () => {
		this.setState({ error: null, success: null })
	}

	handleLogo = (event) => {
		const reader = new FileReader()
		const file = event.target.files[0]
		reader.addEventListener("load", () => {
			const form1 = {
				logo: reader.result
			}
			this.setState({ form1: form1 })
		}, false)
		if (file)
			reader.readAsDataURL(file)
	}

	submitLogo = (event) => {
		event.preventDefault()
		fetch(connexion.connect.url + 'webapp/config/logo/update', {
			method: 'POST',
			body: JSON.stringify({
				logo: this.state.form1.logo
			})
		})
		.then(res => res.json())
		.then(ret => {
			if (ret === false)
				this.setErrorMsg("Erreur: impossible de mettre à jour le logo")
			else
				this.setSuccessMsg("Le logo à été mis à jour")
		})
		.catch(err => {
			this.setErrorMsg("Erreur: impossible de mettre à jour le logo")
			console.log(err)
		})
	}

	handleTitle = (event) => {
		const form2 = {
			...this.state.form2,
			title: event.target.value
		}
		this.setState({ form2: form2 })
	}

	handleType = (event) => {
		const form2 = {
			...this.state.form2,
			type: event.target.value
		}
		this.setState({ form2: form2 })
	}

	handleRubric = (event) => {
		const form2 = {
			...this.state.form2,
			rubric: event.target.value
		}
		this.setState({ form2: form2 })
	}

	handleIcon = (icon) => {
		const icons = this.state.icons
		let prev = null
		for (let i = 0; i < icons.length; i++)
		{
			if (icons[i]._id === icon)
			{
				for (let j = 0; j < icons[i].lineal.length; j++)
				{
					if (icons[i].lineal[j].theme === this.state.theme._id)
					{
						prev = icons[i].lineal[j].icon
						break
					}
				}
				break
			}
		}
		const form2 = {
			...this.state.form2,
			icon: icon,
			prev: prev
		}
		this.setState({ form2: form2 })
	}

	submitMenu = (event) => {
		event.preventDefault()
		const title = this.state.form2.title
		const type = this.state.form2.type
		const rubric = this.state.form2.rubric
		const icon = this.state.form2.icon
		if (title.length === 0)
			this.setErrorMsg("Veuillez indiquer le titre")
		else if (type.length === 0)
			this.setErrorMsg("Veuillez indiquer le type")
		else if (type === "rubric" && rubric.length === 0)
			this.setErrorMsg("Veuillez indiquer une rubrique")
		else if (icon === null)
			this.setErrorMsg("Veuillez sélectionner un icône")
		else
		{
			if (this.state.edit === false)
			{
				fetch(connexion.connect.url + 'webapp/config/menu/insert', {
					method: 'POST',
					body: JSON.stringify({
						title: title,
						type: type,
						rubric: rubric,
						icon: icon
					})
				})
				.then(res => res.json())
				.then(menu => {
					if (menu === false)
						this.setErrorMsg("Une erreur s'est produite")
					else
					{
						const form2 = {
							title: '',
							type: '',
							rubric: '',
							icon: null,
							prev: null
						}
						this.props.updateMenu(menu.values)
						this.setState({ form2: form2 })
						this.setSuccessMsg("Le sous-menu à bien été créé")
					}
				})
				.catch(err => {
					this.setErrorMsg("Une erreur s'est produite")
					console.log(err)
				})
			}
			else
			{
				fetch(connexion.connect.url + 'webapp/config/menu/update', {
					method: 'POST',
					body: JSON.stringify({
						nb: this.state.edit,
						title: title,
						type: type,
						rubric: rubric,
						icon: icon
					})
				})
				.then(res => res.json())
				.then(menu => {
					if (menu === false)
						this.setErrorMsg("Une erreur s'est produite")
					else
					{
						const form2 = {
							title: '',
							type: '',
							rubric: '',
							icon: null,
							prev: null
						}
						this.props.updateMenu(menu.values)
						this.setState({ form2: form2, edit: false })
						this.setSuccessMsg("Le sous-menu à bien été mis à jour")
					}
				})
				.catch(err => {
					this.setErrorMsg("Une erreur s'est produite")
					console.log(err)
				})
			}
		}
	}

	handleUp = (order) => {
		const nb = order - 1
		let menu = this.state.menu
		if (nb > 0)
		{
			const tmp = menu[nb]
			menu[nb] = menu[nb - 1]
			menu[nb - 1] = tmp
			fetch(connexion.connect.url + 'webapp/config/menu/order', {
				method: 'POST',
				body: JSON.stringify({
					menu: menu
				})
			})
			.then(res => res.json())
			.then(menu => {
				if (menu === false)
					this.setErrorMsg("Une erreur s'est produite")
				else
				{
					this.props.updateMenu(menu.values)
					this.clearFlash()
				}
			})
			.catch(err => {
				this.setErrorMsg("Une erreur s'est produite")
				console.log(err)
			})
		}
	}

	handleDown = (order) => {
		const nb = order - 1
		let menu = this.state.menu
		if (nb + 1 < menu.length)
		{
			const tmp = menu[nb]
			menu[nb] = menu[nb + 1]
			menu[nb + 1] = tmp
			fetch(connexion.connect.url + 'webapp/config/menu/order', {
				method: 'POST',
				body: JSON.stringify({
					menu: menu
				})
			})
			.then(res => res.json())
			.then(menu => {
				if (menu === false)
					this.setErrorMsg("Une erreur s'est produite")
				else
				{
					this.props.updateMenu(menu.values)
					this.clearFlash()
				}
			})
			.catch(err => {
				this.setErrorMsg("Une erreur s'est produite")
				console.log(err)
			})
		}
	}

	handleEdit = (nb) => {
		if (this.state.menu[nb - 1])
		{
			const menu = this.state.menu[nb - 1]
			let prev = null
			if (menu.icon)
			{
				for (let i = 0; i < this.state.icons.length; i++)
				{
					if (this.state.icons[i]._id === menu.icon)
					{
						for (let j = 0; j < this.state.icons[i].lineal.length; j++)
						{
							if (this.state.icons[i].lineal[j].theme === this.state.theme._id)
							{
								prev = this.state.icons[i].lineal[j].icon
								break
							}
						}
						break
					}
				}
			}
			const form2 = {
				title: menu.title,
				type: menu.type,
				rubric: menu.rubric,
				icon: menu.icon,
				prev: prev
			}
			this.setState({ form2: form2, edit: nb - 1 })
		}
	}

	handleDelete = (nb) => {
		if (this.state.menu[nb - 1])
		{
			fetch(connexion.connect.url + 'webapp/config/menu/delete', {
				method: 'POST',
				body: JSON.stringify({
					nb: nb - 1
				})
			})
			.then(res => res.json())
			.then(menu => {
				if (menu === false)
					this.setErrorMsg("Une erreur s'est produite")
				else
				{
					const form2 = {
						title: '',
						type: '',
						rubric: '',
						icon: null,
						prev: null
					}
					this.props.updateMenu(menu.values)
					this.setState({ form2: form2, edit: false })
					this.setSuccessMsg("Le sous-menu à bien été supprimé")
				}
			})
			.catch(err => {
				this.setErrorMsg("Une erreur s'est produite")
				console.log(err)
			})
		}
	}

	render() {
		if (this.state.loaded === false || this.state.groups === null || this.state.icons === null || /*this.state.menu === null ||*/ this.state.theme === null)
		{
			return (
				<div className="loader">
					<Loader />
				</div>
			)
		}
		else
		{
			let prev = null
			if (this.state.form1.logo)
				prev = (<img src={this.state.form1.logo} alt="logo" />)
			let rubricJSX = null
			if (this.state.form2.type === "rubric")
			{
				rubricJSX = (
					<label>
						<p>Rubrique :</p>
						<select value={this.state.form2.rubric} onChange={this.handleRubric}>
							<option value="">Sélectionnez une rubrique</option>
							{this.props.groups.map(({ _id, name }) => {
								return (<option value={_id} key={_id}>{name}</option>)
							})}
						</select>
					</label>
				)
			}
			let prevIcon = null
			if (this.state.form2.prev !== null)
				prevIcon = (<img className="icon" src={this.state.form2.prev} alt="Icône menu" />)
			// let menu_k = 0
			let btn = 'Ajouter'
			let title = 'Créer un sous-menu'
			if (this.state.edit !== false)
			{
				btn = 'Mettre à jour'
				title = 'Éditer un sous-menu'
			}
			return (
				<div className="wrapper-content">
					<p className="center error">{this.state.error}</p>
					<p className="center success">{this.state.success}</p>
					<div className="row">
						<div className="module col s4">
							<h2>Logo</h2>
							{prev}
							<form className="module-form" onSubmit={this.submitLogo}>
								<label>
									<p className="column-30">Changer le logo :</p>
									<input type="file" onChange={this.handleLogo} />
								</label>
								<button type="submit" className="waves-effect waves-light btn-small">Mettre à jour le logo</button>
							</form>
						</div>
						<div className="module col s4">
							<h2>Liste du menu</h2>
							{/*this.state.menu.map(({ title, type, rubric, icon }) => {
								menu_k++
								let prev = null
								if (icon !== null)
								{
									for (let i = 0; i < this.state.icons.length; i++)
									{
										if (this.state.icons[i]._id === icon)
										{
											for (let j = 0; j < this.state.icons[i].lineal.length; j++)
											{
												if (this.state.icons[i].lineal[j].theme === this.state.theme._id)
												{
													prev = this.state.icons[i].lineal[j].icon
													break
												}
											}
											break
										}
									}
								}
								let rubricTxt = null
								for (let i = 0; i < this.state.groups.length; i++)
								{
									if (this.state.groups[i]._id === rubric)
									{
										rubricTxt = this.state.groups[i].name
										break
									}
								}
								let rubricJSX = null
								if (rubric)
									rubricJSX = (<span><b>Rubrique :</b> {rubricTxt}<br /></span>)
								let typeTxt = (type === "rubric") ? "Rubriques" : "Animations"
								return (
									<p key={menu_k}>
										<b>#{menu_k}</b><br />
										<b>Titre :</b> {title}<br />
										<b>Type :</b> {typeTxt}<br />
										{rubricJSX}
										<img className="icon small" src={prev} alt="Icône menu" /><br />
										<button type="button" className="waves-effect waves-light btn-small" onClick={this.handleUp.bind(this, menu_k)}>Monter</button>&nbsp;&nbsp;
										<button type="button" className="waves-effect waves-light btn-small" onClick={this.handleDown.bind(this, menu_k)}>Descendre</button><br />
										<button type="button" className="waves-effect waves-light btn-small" onClick={this.handleEdit.bind(this, menu_k)}>Éditer</button>&nbsp;&nbsp;
										<button type="button" className="waves-effect waves-light btn-small red" onClick={this.handleDelete.bind(this, menu_k)}>Supprimer</button>
									</p>
								)
							})*/}
						</div>
						<div className="module col s4">
							<h2>{title}</h2>
							<form className="module-form" onSubmit={this.submitMenu}>
								<label>
									<p>Titre :</p>
									<input type="text" value={this.state.form2.title} onChange={this.handleTitle} />
								</label>
								<label>
									<p>Type :</p>
									<select value={this.state.form2.type} onChange={this.handleType}>
										<option value="">Sélectionnez un type</option>
										<option value="animation">Animations</option>
										<option value="rubric">Rubriques</option>
									</select>
								</label>
								{rubricJSX}
								<div className="label">
									<p>Icône :</p>
									<IconPicker theme={this.state.theme._id} type="lineal" icons={this.state.icons} onChange={this.handleIcon} />
									{prevIcon}
								</div>
								<button type="submit" className="waves-effect waves-light btn-small"><i className="material-icons right">send</i>{btn}</button>
							</form>
						</div>
					</div>
				</div>
			)
		}
	}
}

export default Menu

import React, { Component } from 'react'
// import Loader from '../UI/Loader'
import LangSelector from '../UI/LangSelector'
import M from 'materialize-css'
import tools from '../../tools'
import '../Core/Main.css'
import EmptyFolder from '../UI/EmptyFolder'

class ServicePrestation extends Component {
	constructor(props) {
		super(props)
		this.state = this._initState()
		this.length_max = 50
		this.props.updateNavbarTitle('Services')
	}

	componentDidUpdate(prevProps, prevState) {
		const elems = document.querySelectorAll('select')
		M.FormSelect.init(elems)
		if (this.state.init_dropdown === false)
		{
			const dropdowns = document.querySelectorAll('.dropdown-trigger')
			if (dropdowns.length > 0)
			{
				M.Dropdown.init(dropdowns)
				this.setState({ init_dropdown: true })
			}
		}
	}

	loadIcons = () => {
		this.props.fetchIcons()
		.then(icons => {
			this.setState({ icons: icons })
		})
		.catch(err => {
			this.setErrorMsg("error_fetch_services", 1)
			console.log(err)
		})
	}

	loadTheme = () => {
		this.props.fetchTheme()
		.then(theme => {
			this.setState({ theme: theme })
		})
		.catch(err => {
			this.setErrorMsg("error_fetch_services", 2)
			console.log(err)
		})
	}

	_initState = () => {
		return {
			edit: null,
			create: false,
			click_modal: 0,
			attempt_submit: false,
			services: undefined,
			init_dropdown: false,
			delete: null,
			pages: [
				{ name: 'Services', href: '/services', current: false }
			],
			show_banner: false,
			banner_content: null,
			error: this._initErrors(),
			success: this._initSuccess()
		}
	}

	_initSuccess = () => {
		const success = {
			global: null,
			benefits: null,
			workers: null
		}
		return success
	}

	_initErrors = () => {
		const errors = {
			global: null,
			service: null,
			name: null,
			title: null,
			cover: null,
			type: null,
			benefits: null,
			workers: null
		}
		return errors
	}

	setErrorMsg = (key, msg, id = null) => {
		if (key === "error_fetch_service_benefits")
		{
			key = "global"
			msg = tools.default_error_msg("Impossible de récupérer les prestations", msg)
		}
		else if (key === "error_delete_service_benefit")
		{
			key = "benefits"
			msg = tools.default_error_msg("Impossible de supprimer une prestation", msg)
		}
		else if (key === "error_fetch_service_workers")
		{
			key = "global"
			msg = tools.default_error_msg("Impossible de récupérer les prestataires", msg)
		}
		else if (key === "error_delete_service_worker")
		{
			key = "workers"
			msg = tools.default_error_msg("Impossible de supprimer un prestataire", msg)
		}
		let error = this._initErrors()
		if (id !== null)
			error[key][id] = msg
		else
			error[key] = msg
		this.setState({ success: this._initSuccess(), error: error, show_banner: true, banner_content: msg })
	}

	setSuccessMsg = (key, msg) => {
		const error = this._initErrors()
		let success = this.state.success
		success[key] = msg
		this.setState({ success: success, error: error, show_banner: true, banner_content: msg })
	}

	clearFlash = () => {
		const error = this._initErrors()
		const success = this._initSuccess()
		this.setState({ error: error, success: success })
	}

	closeBanner = () => {
		this.setState({ show_banner: false })
	}

	classNames = (...classes) => {
		return classes.filter(Boolean).join(' ')
	}

	render() {
		// let sendbtn = (<button type="submit" className="waves-effect waves-light btn">Enregistrer</button>)
		// if (this.state.attempt_submit === true)
		// {
		// 	sendbtn = (
		// 		<div className="center">
		// 			<Loader />
		// 		</div>
		// 	)
		// }
		const benefits = (this.props.service_benefits) ? this.props.service_benefits : []
		const workers = (this.props.service_workers) ? this.props.service_workers : []
		let prestation_content = ""
		if (!benefits || !benefits.length)
		(
			prestation_content = (
				<div>
					<EmptyFolder
						emptyFolderSubtitle="Il n'y pas encore de prestation"
					/>
					<button type="button" onClick={this.props.createModal.bind(this, 'benefit')} className="waves-effect waves-light btn">Configurer une prestation</button>
				</div>
			)
		)
		else 
		{
			prestation_content = (
				<div>
					<LangSelector lang={this.props.current_lang} updateLang={this.props.updateLang} />
					<p className="success center">{this.state.success.benefits}</p>
					<p className="error center">{this.state.error.benefits}</p>
					<table className="reviews striped responsive-table">
						<thead>
							<tr>
								<td>Titre</td>
								<td>Prix</td>
								<td>Actions</td>
							</tr>
						</thead>
						<tbody>
							{benefits.map(({ _id, price, translations }) => {
								let subtitle = ""
								for (let i = 0; i < translations.length; i++)
								{
									if (translations[i].lang === this.props.current_lang)
									{
										subtitle = translations[i].subtitle
										break
									}
								}
								return (
									<tr key={_id}>
										<td>{subtitle}</td>
										<td>{price} €</td>
										<td>
											<button type="button" onClick={this.props.editModal.bind(this, 'benefit', _id)} className="btn-floating waves-effect waves-light teal"><i className="material-icons">mode_edit</i></button>
											<button type="button" onClick={this.props.deleteObject.bind(this, 'benefit', _id)} className="btn-floating waves-effect waves-light red accent-2"><i className="material-icons">clear</i></button>
										</td>
									</tr>
								)
							})}
						</tbody>
					</table>
					<button type="button" onClick={this.props.createModal.bind(this, 'benefit')} className="waves-effect waves-light btn">Ajouter une prestation</button>
				</div>
			)
		}
		let workers_content = ""
		if (!workers || !workers.length)
		{
			workers_content = (
				<div>
					<EmptyFolder
						emptyFolderSubtitle="Il n'y pas encore de prestataire"
					/>
					<button type="button" onClick={this.props.createModal.bind(this, 'worker')} className="waves-effect waves-light btn">Configurer un prestataire</button>
				</div>
			)
		}
		else 
		{
			workers_content = (
				<div>
					<table className="reviews striped responsive-table">
						<thead>
							<tr>
								<td>Nom</td>
								<td>Prénom</td>
								<td>Actions</td>
							</tr>
						</thead>
						<tbody>
							{workers.map(({ _id, lastname, firstname }) => {
								return (
									<tr key={_id}>
										<td>{lastname}</td>
										<td>{firstname}</td>
										<td>
											<button type="button" onClick={this.props.editModal.bind(this, 'worker', _id)} className="btn-floating waves-effect waves-light teal"><i className="material-icons">mode_edit</i></button>
											<button type="button" onClick={this.props.deleteObject.bind(this, 'worker', _id)} className="btn-floating waves-effect waves-light red accent-2"><i className="material-icons">clear</i></button>
										</td>
									</tr>
								)
							})}
						</tbody>
					</table>
					<button type="button" onClick={this.props.createModal.bind(this, 'worker')} className="waves-effect waves-light btn">Ajouter un prestataire</button>
				</div>
			)
		}
		return (
			<div className="row">
				<div className="module col s6">
					<h2>Paramètres des prestations</h2>
					{prestation_content}
				</div>
				<div className="module col s6">
					<h2>Paramètres des prestataires</h2>
					<p className="success center">{this.state.success.workers}</p>
					<p className="error center">{this.state.error.workers}</p>
					{workers_content}
				</div>
			</div>
		)
	}
}

export default ServicePrestation

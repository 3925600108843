import React, { Component } from 'react'
import Pages from './Pages'
import Lists from './Lists'
import IconPicker from '../UI/IconPicker'
import Modal from '../UI/Modal'
import Loader from '../UI/Loader'
import LangSelector from '../UI/LangSelector'
import M from 'materialize-css'
import tools from '../../tools'
import '../Core/Main.css'
import { Link } from 'react-router-dom'
import { HomeIcon } from '@heroicons/react/solid'

class Categories extends Component {
	constructor(props) {
		super(props)
		this.defaultGroup = (this.props.group) ? this.props.group._id : ''
		this.state = {
			edit: false,
			categories: null,
			category: null,
			pages: null,
			lists: null,
			answerModal: null,
			modal_edit: false,
			modal_delete: false,
			modal_duplicate: false,
			modal_msg: null,
			delete: false,
			duplicate: false,
			attempt_submit: false,
			form1: {
				group: this.defaultGroup,
				type: '',
				icon: null,
				prev: null,
				translations: []
			},
			navigation: [
				{ name: 'Rubriques', href: '/Rubriques', current: false },
				{ name: '...', href: '#', current: true }			
			],
			error: this._initErrors(),
			success: null
		}
		this.length_max = 40
	}

	componentDidMount() {
		this.loadCategories()
	}

	componentDidUpdate(prevProps) {
		const elems = document.querySelectorAll('select')
		M.FormSelect.init(elems)
		if (prevProps.categories !== this.props.categories)
			this.loadCategories(true)
	}

	loadCategories = (fromProps = false) => {
		if (fromProps === true && this.props.categories && this.props.group)
		{
			const group = this.props.group._id
			let categories = []
			for (let i = 0; i < this.props.categories.length; i++)
			{
				if (this.props.categories[i].group === group)
					categories.push(this.props.categories[i])
			}
			categories.sort((a, b) => {
				return a.order - b.order
			})
			for (let i = 0; i < categories.length; i++)
			{
				for (let j = 0; j < this.props.icons.length; j++)
				{
					if (categories[i].icon === this.props.icons[j]._id)
					{
						for (let k = 0; k < this.props.icons[j].lineal.length; k++)
						{
							if (this.props.icons[j].lineal[k].theme === "blank")
							{
								categories[i].prev = this.props.icons[j].lineal[k].icon
								break
							}
						}
						break
					}
				}
			}
			this.setState({ categories: categories })
		}
		else if (this.props.group)
		{
			const group = this.props.group._id
			this.props.fetchCategoriesByGroup(group)
			.then(cats => {
				let categories = []
				for (let i = 0; i < cats.length; i++)
				{
					if (cats[i].group === group)
						categories.push(cats[i])
				}
				categories.sort((a, b) => {
					return a.order - b.order
				})
				for (let i = 0; i < categories.length; i++)
				{
					for (let j = 0; j < this.props.icons.length; j++)
					{
						if (categories[i].icon === this.props.icons[j]._id)
						{
							for (let k = 0; k < this.props.icons[j].lineal.length; k++)
							{
								if (this.props.icons[j].lineal[k].theme === "blank")
								{
									categories[i].prev = this.props.icons[j].lineal[k].icon
									break
								}
							}
							break
						}
					}
				}
				this.setState({ categories: categories })
			})
			.catch(err => {
				this.setErrorMsg("error_fetch_categories", 1)
				console.log(err)
			})
		}
	}

	setErrorMsg = (key, msg, id = null) => {
		if (key === "error_fetch_categories")
		{
			key = "global"
			msg = tools.default_error_msg("Impossible de récupérer les catégories", msg)
		}
		else if (key === "error_create_page")
		{
			key = "global"
			msg = tools.default_error_msg("Impossible de créer une page", msg)
		}
		else if (key === "error_delete_page")
		{
			key = "global"
			msg = tools.default_error_msg("Impossible de supprimer une page", msg)
		}
		else if (key === "error_create_list")
		{
			key = "global"
			msg = tools.default_error_msg("Impossible de créer une liste", msg)
		}
		else if (key === "error_delete_list")
		{
			key = "global"
			msg = tools.default_error_msg("Impossible de supprimer une liste", msg)
		}
		else if (key === "error_create_category")
		{
			key = "global"
			msg = tools.default_error_msg("Impossible de créer une catégorie", msg)
		}
		else if (key === "error_update_category")
		{
			key = "global"
			msg = tools.default_error_msg("Impossible de modifier une catégorie", msg)
		}
		else if (key === "error_delete_category")
		{
			key = "global"
			msg = tools.default_error_msg("Impossible de supprimer une catégorie", msg)
		}
		else if (key === "error_duplicate_category")
		{
			key = "global"
			msg = tools.default_error_msg("Impossible de dupliquer une catégorie", msg)
		}
		else if (key === "error_order_categories")
		{
			key = "global"
			msg = tools.default_error_msg("Impossible de réordonner les catégories", msg)
		}
		let error = this._initErrors()
		if (id !== null)
			error[key][id] = msg
		else
			error[key] = msg
		this.setState({ success: null, error: error })
	}

	setSuccessMsg = (msg) => {
		this.setState({ error: this._initErrors(), success: msg })
	}

	clearFlash = () => {
		this.setState({ error: this._initErrors(), success: null })
	}

	_initErrors = () => {
		return {
			global: null,
			name: null,
			group: null,
			type: null,
			icon: null
		}
	}

	deletePage = (category) => {
		this.props.deletePageByCategory(category)
		.then(ret => {
			if (ret === false)
				this.setErrorMsg('error_delete_page', 1)
		})
		.catch(err => {
			this.setErrorMsg('error_delete_page', 1)
			console.log(err)
		})
	}

	createPage = (category) => {
		let translations = []
		for (let i = 0; i < category.translations.length; i++)
		{
			translations.push({
				lang: category.translations[i].lang,
				name: category.translations[i].name,
				category_name: null
			})
		}
		const page = {
			cover: null,
			sections: [],
			category: category._id,
			translations: translations
		}
		this.props.insertPage(page)
		.then(ret => {
			if (ret === false)
				this.setErrorMsg('error_create_page', 1)
		})
		.catch(err => {
			this.setErrorMsg('error_create_page', 2)
			console.log(err)
		})
	}

	deleteList = (category) => {
		this.props.deleteListByCategory(category)
		.then(ret => {
			if (ret === false)
				this.setErrorMsg('error_delete_list', 1)
		})
		.catch(err => {
			this.setErrorMsg('error_delete_list', 1)
			console.log(err)
		})
	}

	createList = (category) => {
		let translations = []
		for (let i = 0; i < category.translations; i++)
		{
			translations.push({
				lang: category.translations[i].lang,
				name: category.translations[i].name
			})
		}
		const list = {
			elements: [],
			category: category._id,
			translations: translations
		}
		this.props.insertList(list)
		.then(ret => {
			if (ret === false)
				this.setErrorMsg('error_create_list', 1)
		})
		.catch(err => {
			this.setErrorMsg('error_create_list', 2)
			console.log(err)
		})
	}

	handleTitle = (event) => {
		let found = false
		const translations = this.state.form1.translations
		for (let i = 0; i < translations.length; i++)
		{
			if (translations[i].lang === this.props.current_lang)
			{
				found = true
				translations[i].name = event.target.value
				break
			}
		}
		if (!found)
		{
			translations.push({
				lang: this.props.current_lang,
				name: event.target.value
			})
		}
		const form1 = {
			...this.state.form1,
			translations: translations
		}
		this.setState({ form1: form1 })
	}

	handleGroup = (event) => {
		const form1 = {
			...this.state.form1,
			group: event.target.value
		}
		this.setState({ form1: form1 })
	}

	handleIcon = (icon) => {
		const icons = this.props.icons
		let prev = null
		for (let i = 0; i < icons.length; i++)
		{
			if (icons[i]._id === icon)
			{
				for (let j = 0; j < icons[i].lineal.length; j++)
				{
					if (icons[i].lineal[j].theme === "blank")
					{
						prev = icons[i].lineal[j].icon
						break
					}
				}
				break
			}
		}
		const form1 = {
			...this.state.form1,
			icon: icon,
			prev: prev
		}
		this.setState({ form1: form1 })
	}

	handleList = () => {
		const form1 = {
			...this.state.form1,
			type: 'list'
		}
		this.setState({ form1: form1 })
	}

	handlePage = () => {
		const form1 = {
			...this.state.form1,
			type: 'page'
		}
		this.setState({ form1: form1 })
	}

	_validateForm1 = () => {
		let error = false
		let error_obj = this._initErrors()
		const group = this.state.form1.group
		const type = this.state.form1.type
		const icon = this.state.form1.icon
		const translations = this.state.form1.translations
		if (group.length === 0)
		{
			error = true
			error_obj.group = "Veuillez sélectionner une rubrique"
		}
		if (type.length === 0)
		{
			error = true
			error_obj.type = "Veuillez sélectionner le type de catégorie"
		}
		if (icon === null)
		{
			error = true
			error_obj.icon = "Vous devez choisir une icône"
		}
		let found_default_name = false
		for (let i = 0; i < translations.length; i++)
		{
			const lang = translations[i].lang
			const name = translations[i].name
			if (name.length === 0 && lang === this.props.lang_default)
			{
				error = true
				error_obj.name = "Vous devez indiquer le titre (version " + lang.toUpperCase() + ")"
			}
			else if (name.length > this.length_max)
			{
				error = true
				error_obj.name = "Le titre ne doit pas dépasser " + this.length_max + " caractères (version " + lang.toUpperCase() + ")"
			}
			else if (lang === this.props.lang_default)
				found_default_name = true
		}
		if (!found_default_name && !error_obj.name)
		{
			error = true
			error_obj.name = 'Vous devez indiquer le titre (version ' + this.props.lang_default.toUpperCase() + ')'
		}
		if (error === true)
		{
			error_obj.global = "Il y a une ou plusieurs erreur(s) dans le formulaire"
			this.setState({ success: null, error: error_obj, attempt_submit: false })
		}
		return (!error) ? true : false
	}

	submitEdit = (event) => {
		event.preventDefault()
		this.setState({ attempt_submit: true })
		if (this._validateForm1())
		{
			const translations = this.state.form1.translations
			let langs_not_found = []
			for (let i = 0; i < this.props.langs.length; i++)
			{
				let found = false
				for (let j = 0; j < translations.length; j++)
				{
					if (this.props.langs[i] === translations[j].lang && translations[j].name.length > 0)
					{
						found = true
						break
					}
				}
				if (!found)
					langs_not_found.push(this.props.langs[i])
			}
			if (langs_not_found.length > 0)
			{
				let msg = ""
				for (let i = 0; i < langs_not_found.length; i++)
				{
					if (i > 0)
						msg += ', '
					msg += langs_not_found[i].toUpperCase()
				}
				msg = "La catégorie n'est pas traduite dans les langues suivantes : " + msg
				this.setState({ modal_msg: msg, modal_edit: true, answerModal: this.answerModalEdit })
			}
			else
				this._insertCategory()
		}
	}

	_insertCategory = () => {
		if (this.state.category !== null)
		{
			if (this.state.form1.type !== this.state.category.type)
			{
				const msg = 'Vous êtes sur le point de changer le type de la catégorie ce qui entraînera la perte de tout le contenu qui y est rattaché. Cette action est irréversible.'
				this.setState({ modal_edit: true, modal_msg: msg, answerModal: this.answerModalType })
			}
			else
				this._updateCategory()
		}
		else
		{
			const type = this.state.form1.type
			const icon = this.state.form1.icon
			const translations = this.state.form1.translations
			const category = {
				icon: icon,
				type: type,
				group: this.props.group._id,
				translations: translations
			}
			this.props.insertCategory(category)
			.then(ret => {
				if (ret === false)
				{
					this.setState({ attempt_submit: false, modal_edit: false })
					this.setErrorMsg('error_create_category', 1)
				}
				else if (ret === 1)
				{
					this.setState({ attempt_submit: false, modal_edit: false })
					this.setErrorMsg("global", "Une autre page du même nom existe déjà")
				}
				else
				{
					const form1 = {
						group: this.defaultGroup,
						type: '',
						icon: null,
						prev: null,
						translations: []
					}
					if (type === "page")
						this.createPage(ret)
					else if (type === "list")
						this.createList(ret)
					this.setSuccessMsg("La catégorie a bien été créée")
					this.setState({ form1: form1, edit: false, attempt_submit: false, modal_edit: false })
				}
			})
			.catch(err => {
				this.setState({ attempt_submit: false, modal_edit: false })
				this.setErrorMsg('error_create_category', 2)
				console.log(err)
			})
		}
	}

	_updateCategory = () => {
		const group = this.state.form1.group
		const type = this.state.form1.type
		const icon = this.state.form1.icon
		const translations = this.state.form1.translations
		const category = {
			_id: this.state.category._id,
			icon: icon,
			type: type,
			group: group,
			translations: translations
		}
		this.props.updateCategory(category)
		.then(ret => {
			if (ret === false)
			{
				this.setState({ attempt_submit: false, modal_edit: false })
				this.setErrorMsg('error_update_category', 1)
			}
			else if (ret === 1)
			{
				this.setState({ attempt_submit: false, modal_edit: false })
				this.setErrorMsg("global", "Une autre page du même nom existe déjà")
			}
			else
			{
				if (ret.type !== this.state.category.type)
				{
					if (ret.type === "list")
					{
						this.deletePage(ret)
						this.createList(ret)
					}
					else if (ret.type === "page")
					{
						this.deleteList(ret)
						this.createPage(ret)
					}
				}
				this.setState({ edit: false, attempt_submit: false, modal_edit: false })
				this.setSuccessMsg("La catégorie a bien été mise à jour")
			}
		})
		.catch(err => {
			this.setState({ attempt_submit: false, modal_edit: false })
			this.setErrorMsg('error_update_category', 2)
			console.log(err)
		})
	}

	clickCreate = () => {
		const form1 = {
			group: this.defaultGroup,
			type: '',
			icon: null,
			prev: null,
			translations: []
		}
		this.clearFlash()
		this.setState({ category: null, edit: true, form1: form1 })
	}

	clickSelect = (id) => {
		let category = null
		for (let i = 0; i < this.state.categories.length; i++)
		{
			if (this.state.categories[i]._id === id)
			{
				category = this.state.categories[i]
				break
			}
		}
		const show = (category) ? true : false
		this.setState({ show: show, category: category })
	}

	clickEdit = (id) => {
		let category = null
		let form1 = {
			group: this.defaultGroup,
			type: '',
			icon: null,
			prev: null,
			translations: []
		}
		for (let i = 0; i < this.state.categories.length; i++)
		{
			if (this.state.categories[i]._id === id)
			{
				category = this.state.categories[i]
				form1 = {
					group: category.group,
					type: category.type,
					icon: category.icon,
					prev: category.prev,
					translations: category.translations
				}
				break
			}
		}
		// const li = { name: 'Éditer une catégorie', href: '#', current: false }
		// const navigation = this.state.navigation
		// navigation.push(li)
		let edit = (category) ? true : false
		this.clearFlash()
		this.setState({ edit: edit, category: category, form1: form1 })
	}

	clickDelete = (id) => {
		this.setState({ delete: id, modal_delete: true })
	}

	_deleteCategory = () => {
		let type = null
		for (let i = 0; i < this.state.categories.length; i++)
		{
			if (this.state.categories[i]._id === this.state.delete)
			{
				type = this.state.categories[i].type
				break
			}
		}
		this.props.deleteCategory(this.state.delete)
		.then(ret => {
			if (ret === false)
			{
				this.setState({ modal_delete: false })
				this.setErrorMsg('error_delete_category', 1)
			}
			else
			{
				if (type === "page")
					this.deletePage(this.state.delete)
				else if (type === "list")
					this.deleteList(this.state.delete)
				this.setState({ modal_delete: false })
				this.setSuccessMsg("La catégorie a bien été supprimée")
			}
		})
		.catch(err => {
			this.setState({ modal_delete: false })
			this.setErrorMsg('error_delete_category', 2)
			console.log(err)
		})
	}

	clickUp = (id) => {
		let i = 0
		let update = false
		let categories = this.state.categories
		while (i < categories.length)
		{
			if (categories[i]._id === id)
			{
				if (i > 0)
				{
					let order_tmp = categories[i - 1].order
					categories[i - 1].order = categories[i].order
					categories[i].order = order_tmp
					let category_tmp = categories[i - 1]
					categories[i - 1] = categories[i]
					categories[i] = category_tmp
					update = true
				}
				break
			}
			i++
		}
		if (update === true)
		{
			this.props.updateCategoriesOrder(categories)
			.then(ret => {
				if (ret === false)
					this.setErrorMsg('error_order_categories', 1)
			})
			.catch(err => {
				this.setErrorMsg('error_order_categories', 2)
				console.log(err)
			})
		}
	}

	clickDown = (id) => {
		let i = 0
		let update = false
		let categories = this.state.categories
		while (i < categories.length)
		{
			if (categories[i]._id === id)
			{
				if (i + 1 < categories.length)
				{
					let order_tmp = categories[i + 1].order
					categories[i + 1].order = categories[i].order
					categories[i].order = order_tmp
					let category_tmp = categories[i + 1]
					categories[i + 1] = categories[i]
					categories[i] = category_tmp
					update = true
				}
				break
			}
			i++
		}
		if (update === true)
		{
			this.props.updateCategoriesOrder(categories)
			.then(ret => {
				if (ret === false)
					this.setErrorMsg('error_order_categories', 3)
			})
			.catch(err => {
				this.setErrorMsg('error_order_categories', 4)
				console.log(err)
			})
		}
	}

	clickDuplicate = (id) => {
		this.setState({ duplicate: id, modal_duplicate: true })
	}

	_duplicateCategory = () => {
		const category = {
			_id: this.state.duplicate
		}
		this.props.updateCategoryDuplicate(category)
		.then(ret => {
			if (ret === false)
			{
				this.setState({ modal_duplicate: false, duplicate: false })
				this.setErrorMsg('error_duplicate_category', 1)
			}
			else
			{
				this.setState({ modal_duplicate: false, duplicate: false })
				this.setSuccessMsg("La catégorie a bien été dupliquée")
			}
		})
		.catch(err => {
			this.setState({ modal_duplicate: false, duplicate: false })
			this.setErrorMsg('error_duplicate_category', 2)
			console.log(err)
		})
		// let category = null
		// let form1 = {
		// 	group: this.defaultGroup,
		// 	type: '',
		// 	icon: null,
		// 	prev: null,
		// 	translations: []
		// }
		// for (let i = 0; i < this.state.categories.length; i++)
		// {
		// 	if (this.state.categories[i]._id === id)
		// 	{
		// 		category = this.state.categories[i]
		// 		form1 = {
		// 			group: category.group,
		// 			type: category.type,
		// 			icon: category.icon,
		// 			prev: category.prev,
		// 			translations: category.translations
		// 		}
		// 		break
		// 	}
		// }
		// // const li = { name: 'Éditer une catégorie', href: '#', current: false }
		// // const navigation = this.state.navigation
		// // navigation.push(li)
		// let edit = (category) ? true : false
		// this.clearFlash()
		// this.setState({ edit: edit, category: category, form1: form1 })
	}

	answerModalEdit = (answer) => {
		if (answer === "yes")
		{
			this.setState({ modal_edit: false })
			setTimeout(() => {
				this._insertCategory()
			}, 10)
		}
		else
			this.setState({ attempt_submit: false, modal_edit: false })
	}

	answerModalType = (answer) => {
		if (answer === "yes")
			this._updateCategory()
		else
			this.setState({ attempt_submit: false, modal_edit: false })
	}

	answerModalDelete = (answer) => {
		if (answer === "yes")
			this._deleteCategory()
		else
			this.setState({ delete: false, modal_delete: false })
	}

	answerModalDuplicate = (answer) => {
		if (answer === "yes")
			this._duplicateCategory()
		else
			this.setState({ duplicate: false, modal_duplicate: false })
	}

	unShow = () => {
		this.setState({ show: false })
	}

	unEdit = () => {
		this.setState({ edit: false })
	}

	render() {
		if (this.state.categories === null)
		{
			return (
				<div className="loader">
					<Loader />
				</div>
			)
		}
		else if (this.state.edit === true)
		{
			let name = ""
			for (let i = 0; i < this.state.form1.translations.length; i++)
			{
				if (this.state.form1.translations[i].lang === this.props.current_lang)
				{
					name = this.state.form1.translations[i].name
					break
				}
			}
			let prev = null
			let group = null
			let url = null
			if (this.state.form1.prev)
				prev = (<img className="icon blank" src={this.state.form1.prev} alt="Prévisualisation" />)
			if (this.state.category !== null)
			{
				let prefix = ""
				for (let i = 0; i < this.props.group.translations.length; i++)
				{
					if (this.props.group.translations[i].lang === this.props.current_lang)
					{
						prefix = this.props.group.translations[i].prefix
						break
					}
				}
				let suffix = ""
				for (let i = 0; i < this.state.category.translations.length; i++)
				{
					if (this.state.category.translations[i].lang === this.props.current_lang)
					{
						suffix = this.state.category.translations[i].suffix
						break
					}
				}
				group = (
					<label>
						<p className="error">{this.state.error.group}</p>
						<p>Rubrique :</p>
						<select value={this.state.form1.group} onChange={this.handleGroup}>
							{this.props.groups.map(({ _id, translations }) => {
								let name = ""
								for (let i = 0; i < translations.length; i++)
								{
									if (translations[i].lang === this.props.lang_default)
									{
										name = translations[i].name
										break
									}
								}
								return (<option value={_id} key={_id}>{name}</option>)
							})}
						</select>
					</label>
				)
				url = (<p className="bigger">URL : {prefix}/{suffix}</p>)
			}
			let sendbtn = (<button type="submit" className="waves-effect waves-light btn">Enregistrer</button>)
			if (this.state.attempt_submit === true)
			{
				sendbtn = (
					<div className="center">
						<Loader />
					</div>
				)
			}
			if (this.state.navigation)
			{
				const navigation = {
					...this.state.navigation
				}
				for (let i = 0; i < this.props.group.translations.length; i++)
					if (this.props.group.translations[i].lang === this.props.current_lang)
						navigation[1].name = this.props.group.translations[i].name
					}
			// const currList = navigation[1].name
			const list = (this.state.form1.type === "list") ? true : false
			const page = (this.state.form1.type === "page") ? true : false
			const navStyle = "flex bg-transparent shadow-none mb-10"
			return (
				<div className="wrapper-content">
					<nav className={navStyle} aria-label="Breadcrumb">
						<ol className="bg-white rounded-md shadow px-6 flex space-x-4">
							<li className="flex">
								<div className="flex items-center">
									<Link to="/" className="text-gray-400 hover:text-gray-500">
										<HomeIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
										<span className="sr-only">Home</span>
									</Link>
								</div>
							</li>
							{this.state.navigation.map((page) => {
								const func = (page.href === '#') ? this.unEdit : this.props.unShow
								return (
									<li key={page.name} className="flex">
										<div className="flex items-center">
											<svg
												className="flex-shrink-0 w-6 h-full text-gray-200"
												viewBox="0 0 24 44"
												preserveAspectRatio="none"
												fill="currentColor"
												xmlns="http://www.w3.org/2000/svg"
												aria-hidden="true"
											>
												<path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
											</svg>
											<button
												onClick={func.bind(this, page)}
												className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
												aria-current={page.current ? 'page' : undefined}
											>
												{page.name}
											</button>
										</div>
									</li>
								)
							})}
						</ol>
					</nav>
					<div className="mb-5 w-1/2">
						<h2 className="text-xl leading-6 font-medium text-gray-900 mb-0">Éditer une catégorie</h2>
						<p className="mt-1 text-base text-gray-500">Modifier le titre, l'icône ou le type d'affichage de votre catégorie.</p>
					</div>
					<p className="center success">{this.state.success}</p>
					<Modal
						dismissible={false}
						content={this.state.modal_msg}
						open_modal={this.state.modal_edit}
						answerModal={this.state.answerModal}
					/>
					<div className="row">
						<div className="module col s12">
							<LangSelector lang={this.props.current_lang} updateLang={this.props.updateLang} />
							<form onSubmit={this.submitEdit}>
								<label>
									<p className="error">{this.state.error.name}</p>
									<p>Titre (version {this.props.current_lang.toUpperCase()}) :</p>
									<input type="text" value={name} onChange={this.handleTitle} />
								</label>
								{group}
								<p className="error">{this.state.error.type}</p>
								<p>Type :</p>
								<label>
									<input className="with-gap" type="radio" name="type" value="list" checked={list} onChange={this.handleList} /> <span>Liste</span><br />
								</label>
								<label>
									<input className="with-gap" type="radio" name="type" value="page" checked={page} onChange={this.handlePage} /> <span>Page</span>
								</label>
								<div className="label">
									<p className="error">{this.state.error.icon}</p>
									<p>Icône :</p>
									<IconPicker type="lineal" theme="blank" icons={this.props.icons} onChange={this.handleIcon} />
									{prev}
								</div>
								{url}
								<p className="error">{this.state.error.global}</p>
								{sendbtn}
							</form>
						</div>
					</div>
				</div>
			)
		}
		else if (this.state.show === true)
		{
			if (this.state.category.type === "page")
			{
				return (
					<Pages
						category={this.state.category}
						navigation={this.state.navigation}
						current_lang={this.props.current_lang}
						features={this.props.features}
						icons={this.props.icons}
						langs={this.props.langs}
						lang_default={this.props.lang_default}
						pages={this.props.pages}
						services={this.props.services}
						theme={this.props.theme}
						fetchPageByCategory={this.props.fetchPageByCategory}
						fetchServices={this.props.fetchServices}
						insertPageSection={this.props.insertPageSection}
						updatePageCover={this.props.updatePageCover}
						updatePageDuplicateSection={this.props.updatePageDuplicateSection}
						updatePageOrderSections={this.props.updatePageOrderSections}
						updatePageSection={this.props.updatePageSection}
						updatePageService={this.props.updatePageService}
						deletePageSection={this.props.deletePageSection}
						deletePageService={this.props.deletePageService}
						updateLang={this.props.updateLang}
						unShowGroup={this.props.unShow}
						unShowCategory={this.unShow}
					/>
				)
			}
			else
			{
				return (
					<Lists
						navigation={this.state.navigation}
						category={this.state.category}
						current_lang={this.props.current_lang}
						features={this.props.features}
						icons={this.props.icons}
						langs={this.props.langs}
						lang_default={this.props.lang_default}
						lists={this.props.lists}
						pages={this.props.pages}
						services={this.props.services}
						theme={this.props.theme}
						fetchPageByID={this.props.fetchPageByID}
						fetchListByCategory={this.props.fetchListByCategory}
						fetchServices={this.props.fetchServices}
						insertListElement={this.props.insertListElement}
						insertPage={this.props.insertPage}
						insertPageSection={this.props.insertPageSection}
						updateListCover={this.props.updateListCover}
						updateListElement={this.props.updateListElement}
						updateListOrderElements={this.props.updateListOrderElements}
						updateListTransformSubcategory={this.props.updateListTransformSubcategory}
						updatePageCover={this.props.updatePageCover}
						updatePageDuplicateSection={this.props.updatePageDuplicateSection}
						updatePageName={this.props.updatePageName}
						updatePageOrderSections={this.props.updatePageOrderSections}
						updatePageSection={this.props.updatePageSection}
						updatePageService={this.props.updatePageService}
						deleteListElement={this.props.deleteListElement}
						deletePage={this.props.deletePage}
						deletePageSection={this.props.deletePageSection}
						deletePageService={this.props.deletePageService}
						updateLang={this.props.updateLang}
						unShowGroup={this.props.unShow}
						unShowCategory={this.unShow}
					/>
				)
			}
		}
		else
		{
			const categories = this.state.categories
			const navStyle = "flex bg-transparent shadow-none mb-10"
			if (this.state.navigation)
			{
				const navigation = {
					...this.state.navigation
				}
				for (let i = 0; i < this.props.group.translations.length; i++)
				if (this.props.group.translations[i].lang === this.props.current_lang)
				navigation[1].name = this.props.group.translations[i].name
			}
			let modal_msg = null
			let modal_open = false
			let answerModal = null
			if (this.state.modal_delete)
			{
				modal_msg = "Vous supprimerez cette catégorie et tout le contenu qui s'y trouve. Cette action est irréversible"
				modal_open = true
				answerModal = this.answerModalDelete
			}
			else if (this.state.modal_duplicate)
			{
				modal_msg = "Vous dupliquerez cette catégorie et tout le contenu qui s'y trouve."
				modal_open = true
				answerModal = this.answerModalDuplicate
			}
			return (
				<div className="wrapper-content">
					<nav className={navStyle} aria-label="Breadcrumb">
						<ol className="bg-white rounded-md shadow px-6 flex space-x-4">
							<li className="flex">
							<div className="flex items-center">
								<Link to="/" className="text-gray-400 hover:text-gray-500">
									<HomeIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
									<span className="sr-only">Home</span>
								</Link>
							</div>
							</li>
							{this.state.navigation.map((page) => {
								const func = (page.href === '#') ? this.unEdit : this.props.unShow
								return (
									<li key={page.name} className="flex">
										<div className="flex items-center">
											<svg
												className="flex-shrink-0 w-6 h-full text-gray-200"
												viewBox="0 0 24 44"
												preserveAspectRatio="none"
												fill="currentColor"
												xmlns="http://www.w3.org/2000/svg"
												aria-hidden="true"
											>
												<path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
											</svg>
											<button
												onClick={func.bind(this, page)}
												className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
												aria-current={page.current ? 'page' : undefined}
											>
												{page.name}
											</button>
										</div>
									</li>
								)
							})}
						</ol>
					</nav>
					<div className="mb-5 w-1/2">
						<h2 className="text-xl leading-6 font-medium text-gray-900 mb-0">Mes catégories</h2>
						<p className="mt-1 text-base text-gray-500">Retrouvez ici les pages de vos rubriques. Ce sont les blocs clicables sur votre application.</p>
					</div>
					<p className="center error">{this.state.error.global}</p>
					<p className="center success">{this.state.success}</p>
					<Modal
						dismissible={false}
						content={modal_msg}
						open_modal={modal_open}
						answerModal={answerModal}
					/>
					<div className="row">
						<div className="module col s12">
							<button type="button" className="waves-effect waves-light btn-large" onClick={this.clickCreate}>Ajouter une catégorie</button>
							<LangSelector lang={this.props.current_lang} updateLang={this.props.updateLang} />
							{categories.map(({ _id, translations, prev }) => {
								let name = ""
								for (let i = 0; i < translations.length; i++)
								{
									if (translations[i].lang === this.props.current_lang)
									{
										name = translations[i].name
										break
									}
								}
								let image = null
								if (prev)
									image = (<div className="icon"><img src={prev} alt="Icône catégorie" /></div>)
								return (
									<div className="object full-width" key={_id}>
										<div className="title">{image}<p onClick={this.clickSelect.bind(this, _id)}>{name}</p></div>
										<div className="buttons">
											<button type="button" className="waves-effect waves-light btn" onClick={this.clickUp.bind(this, _id)}>Monter</button>
											<button type="button" className="waves-effect waves-light btn" onClick={this.clickDown.bind(this, _id)}>Descendre</button>
											<button type="button" className="waves-effect waves-light btn" onClick={this.clickEdit.bind(this, _id)}>Éditer</button>
											<button type="button" className="waves-effect waves-light btn" onClick={this.clickDuplicate.bind(this, _id)}>Dupliquer</button>
											<button type="button" className="waves-effect waves-light btn red" onClick={this.clickDelete.bind(this, _id)}>Supprimer</button>
										</div>
										<div className="clear"></div>
									</div>
								)
							})}
						</div>
					</div>
				</div>
			)
		}
	}
}

export default Categories

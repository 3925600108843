import React, { Component } from 'react'
import tools from '../../tools'
import '../Core/Main.css'
import star_fill from '../../images/cstar_fill.png'
import star_lineal from '../../images/cstar_lineal.png'
import EmptyFolder from '../UI/EmptyFolder'
import { Fragment } from 'react'
import { Redirect } from 'react-router-dom'
import { Dialog, Transition, Listbox } from '@headlessui/react'
import { 
	CameraIcon, 
	CheckCircleIcon, 
	CheckIcon,
	XCircleIcon, 
	QuestionMarkCircleIcon, 
	InformationCircleIcon,
	ChevronDownIcon,
	ChevronUpIcon,
	MinusIcon,
 } from '@heroicons/react/solid'
import { HomeIcon } from '@heroicons/react/outline'
import OnlyPremiumEmpty from '../UI/OnlyPremiumEmpty'
import PremiumAlert from '../UI/PremiumAlert'
import Loader from '../UI/Loader'
import connexion from '../../connexion'

class Inventories extends Component {
	constructor(props) {
		super(props)
		this.default_select_text = "Sélectionnez un filtre"
		this.state = {
			error: this._initErrors(),
			success: null,
			is_open: false,
			attempt_open: false,
			is_search_open: false,
			inventories: null,
			filtered_inventories: null,
			warning_arrival: null,
			warning_departure: null,
			toggle_all: false,
			edit_mode: false,
			wich_mode: null,
			config_inventory: null,
			checkin_inventory: null,
			checkout_inventory: null,
			stay: null,
			stay_number: '',
			tabs: [
				{ name: 'Inventaire d\'entrée', current: true },
				{ name: 'Inventaire de sortie', current: false },
				{ name: 'Photos', current: false },
				{ name: 'Commentaires', current: false },
				{ name: 'Détails séjour', current: false },
			],
			curr_tab: {
				name: 'Inventaire d\'entrée', 
				current: true
			},
			sort: {
				client_number: null,
				stay_number: null,
				location: null,
				location_category: null,
				firstname: null,
				lastname: null,
				checkin_status: null,
				checkout_status: null,
				arrival_time: null,
				departure_time: null,
				deposit_date: "up"
			},
			form2: {
				staffComment: "",
				date: "",
			},
			form5: {
				comment_checkin: "",
				comment_checkout: ""
			},
			search: {
				text: '',
				select: this.default_select_text,
				checkin: false,
				checkout: false
			},
			missing_objects: null,
		}
		this.props.updateNavbarTitle('Inventaires clients')
	}

	componentDidMount()
	{
		this.loadInventories()
		this.loadConfigInventory()
	}

	componentDidUpdate(prevProps, prevState)
	{
		if (prevProps.inventories !== this.props.inventories)
			this.loadInventories(true)
		if (prevState.is_open !== this.state.is_open && this.state.is_open === false)
		{
			setTimeout(() => {
				const element = document.getElementsByTagName("html")[0]
				element.style.overflow = "auto"
				element.style.paddingRight = "0"
			}, 300)
		}
	}

	loadConfigInventory = (fromProps = false) => {
		if (fromProps === true)
			this.setState({ config_inventory: this.props.config_inventory, form2: this.props.config_inventory })
		else
		{
			this.props.fetchConfigInventory()
			.then(config => {
				const config_inventory = {
					arrival_access: {
						staff: config.arrival_staff,
						user: config.arrival_user
					},
					departure_access: {
						staff: config.departure_staff,
						user: config.departure_user
					}
				}
				this.setState({ config_inventory: config_inventory })
			})
			.catch(err => {
				this.setErrorMsg("error_fetch_config", 1)
				console.log(err)
			})
		}
	}

	classNames = (...classes) => {
		return classes.filter(Boolean).join(' ')
	}

	_initErrors = () => {
		const errors = {
			global: null,
			stay_number: null,
			inventory_setup: null
		}
		return errors
	}

	setErrorMsg = (key, msg, id = null) => {
		if (key === 'error_fetch_stay_number')
		{
			key = 'global'
			msg = tools.default_error_msg('Nous n\'avons pas trouvé le numéro de séjour indiqué')
		}
		if (key === "error_fetch_accommodations")
		{
			key = "global"
			msg = tools.default_error_msg("Impossible de récupérer les catégories d'hébergement", msg)
		}
		let error = this._initErrors()
		if (id !== null)
			error[key][id] = msg
		else
			error[key] = msg
		this.setState({ success: null, error: error })
	}

	clearFlash = () => {
		this.setState({ success: null, error: this._initErrors() })
	}

	_loadInventories = (inv) => {
		let inventories = []
		for (let i = 0; i < inv.length; i++)
		{
			let found = false
			let type = null
			// if (inv[i].create_time < 1704063600) // quickfix problèmes n° séjours (1er janvier 2024)
			// 	continue
			if (inv[i].type === "arrival")
				type = "checkin_inventory"
			else if (inv[i].type === "departure")
				type = "checkout_inventory"
			for (let j = 0; j < inventories.length; j++)
			{
				if (inventories[j].stay_number === inv[i].stay_number)
				{
					found = j
					break
				}
			}
			if (found !== false)
				inventories[found][type] = inv[i]
			else
			{
				found = inventories.length
				inventories.push({
					stay: inv[i].stay,
					stay_number: inv[i].stay_number,
					client_number: (inv[i].client) ? inv[i].client.client_number : null,
					client: inv[i].client,
					checkin_inventory: null,
					checkout_inventory: null
				})
				inventories[found][type] = inv[i]
			}
		}
		if (inventories.length > 0)
		{
			for (let i = 0; i < inventories.length; i++)
			{
				const inventory = inventories[i]
				let deposit_date = null
				let checkin_time = null
				if (inventory.checkin_inventory)
				{
					checkin_time = (inventory.checkin_inventory.update_time) ? inventory.checkin_inventory.update_time : inventory.checkin_inventory.create_time
					deposit_date = checkin_time
				}
				let checkout_time = null
				if (inventory.checkout_inventory)
					checkout_time = (inventory.checkout_inventory.update_time) ? inventory.checkout_inventory.update_time : inventory.checkout_inventory.create_time
				if (inventory.checkout_inventory && (deposit_date === null || deposit_date < checkout_time))
					deposit_date = checkout_time
				inventories[i].deposit_date = deposit_date
			}
			inventories.sort((a, b) => {
				return b.deposit_date - a.deposit_date
			})
		}
		this.setState({ inventories: inventories })
	}

	loadInventories = (fromProps = false) => {
		if (fromProps === true)
			this._loadInventories(tools.clone(this.props.inventories))
		else
		{
			this.props.fetchInventories()
			.then(inv => {
				this._loadInventories(inv)
			})
			.catch(err => {
				this.setErrorMsg("error_fetch_accommodations", 1)
				console.log(err)
			})
		}
	}
	
	closeModal = () => {
		this.setState({ is_open: false, checkin_inventory: null, checkout_inventory: null, toggle_all: false, stay: null, client: null })
	}

	closeSearchModal = () => {
		this.setState({ is_search_open: false })
	}

	handleTabs = (param) => {
		let tab = this.state.tabs
		let curr = this.state.curr_tab
		tab.forEach((elem) => {
			elem.current = false
		})
		for (let i = 0; i < tab.length; i++)
		{
			if (tab[i].name === param.name)
			{
				tab[i].current = true
				curr = tab[i]
				break
			}
		}
		this.setState({ tabs: tab, curr_tab: curr })
	}

	compareStrings = (param, chevron) => {
		let inventories = this.state.inventories
		if (chevron === "up")
		{
			inventories.sort((a, b) => {
				let compare_a = null
				let compare_b = null
				if (param.indexOf('.') !== -1)
				{
					const params = param.split('.')
					compare_a = (a[params[0]]) ? a[params[0]][params[1]] : null
					compare_b = (b[params[0]]) ? b[params[0]][params[1]] : null
				}
				if (!compare_a && !compare_b)
					return 0
				else if (!compare_a && compare_b)
					return -1
				else if (compare_a && !compare_b)
					return 1
				else
					return compare_a.localeCompare(compare_b) * -1
			})
		}
		else if (chevron === "down")
		{
			inventories.sort((a, b) => {
				let compare_a = null
				let compare_b = null
				if (param.indexOf('.') !== -1)
				{
					const params = param.split('.')
					compare_a = (a[params[0]]) ? a[params[0]][params[1]] : null
					compare_b = (b[params[0]]) ? b[params[0]][params[1]] : null
				}
				if (!compare_a && !compare_b)
				{
					console.log('0')
					return 0
				}
				else if (!compare_a && compare_b)
				{
					console.log('-1')
					return 1
				}
				else if (compare_a && !compare_b)
				{
					console.log('1')
					return -1
				}
				else
					return compare_a.localeCompare(compare_b)
			})
		}
		return inventories
	}

	compareNumbers = (param, chevron) => {
		let inventories = this.state.inventories
		if (param === 'arrival_time')
			param = 'stay.arrival_time'
		else if (param === 'departure_time')
			param = 'stay.departure_time'
		else if (param === "inventory.deposit_date")
			param = 'deposit_date'
		if (chevron === "up")
		{
			inventories.sort((a, b) => {
				let compare_a = a[param]
				let compare_b = b[param]
				if (param.indexOf('.') !== -1)
				{
					const params = param.split('.')
					compare_a = a[params[0]][params[1]]
					compare_b = b[params[0]][params[1]]
				}
				return (compare_a - compare_b) * -1
			})
		}
		else if (chevron === "down")
		{
			inventories.sort((a, b) => {
				let compare_a = a[param]
				let compare_b = b[param]
				if (param.indexOf('.') !== -1)
				{
					const params = param.split('.')
					compare_a = a[params[0]][params[1]]
					compare_b = b[params[0]][params[1]]
				}
				return compare_a - compare_b
			})
		}
		return inventories
	}

	handleChevron = (param) => {
		if (param === "checkin_inventory.status")
			param = "checkin_status"
		else if (param === "checkout_inventory.status")
			param = "checkout_status"
		const sort = {
			...this.state.sort
		}
		let index = param.indexOf('.')
		if (index !== -1)
		{
			index++
			param = param.substring(index)
		}
		const order = (sort[param] !== "down") ? "down" : "up"
		sort[param] = order
		this.setState({ sort: sort })
		return order
	}

	filter = (param) => {
		let inventories = this.state.inventories
		const chevron = this.handleChevron(param)
		if (param === "client.client_number" || param === "stay_number" || param === "stay.arrival_time" || param === "stay.departure_time" || param === "inventory.deposit_date")
			inventories = this.compareNumbers(param, chevron)
		else
			inventories = this.compareStrings(param, chevron)
		this.setState({ inventories: inventories })
	}

	_setChevron = (status) => {
		let chevron = <MinusIcon className="h-5 w-5 rounded text-gray-400 group-hover:text-gray-900 group-focus:text-gray-900 group-hover:bg-gray-300 group-focus:bg-gray-300" aria-hidden="true" />
		if (status === "up")
			chevron = <ChevronUpIcon className="h-5 w-5 rounded text-gray-400 group-hover:text-gray-900 group-focus:text-gray-900 group-hover:bg-gray-300 group-focus:bg-gray-300" aria-hidden="true" />
		else if (status === "down")
			chevron = <ChevronDownIcon className="h-5 w-5 rounded text-gray-400 group-hover:text-gray-900 group-focus:text-gray-900 group-hover:bg-gray-300 group-focus:bg-gray-300" aria-hidden="true" />
		return chevron
	}

	toggleAll = (display_inventory, wich) => {
		let toggle_all = this.state.toggle_all
		if (toggle_all === false)
		{
			for (let i = 0; i < display_inventory.inventory.length; i++)
			{
				for (let j = 0; j < display_inventory.inventory[i].objects.length; j++)
				{
					display_inventory.inventory[i].objects[j].count = display_inventory.inventory[i].objects[j].quantity
					display_inventory.inventory[i].objects[j].checked = true
				}
			}
			toggle_all = true
		}
		else
		{
			for (let i = 0; i < display_inventory.inventory.length; i++)
			{
				for (let j = 0; j < display_inventory.inventory[i].objects.length; j++)
				{
					display_inventory.inventory[i].objects[j].count = ""
					display_inventory.inventory[i].objects[j].checked = false
				}
			}
			toggle_all = false
		}
		if (wich === "checkout")
			this.setState({ toggle_all: toggle_all, checkout_inventory: display_inventory })
		else if (wich === "checkin")
			this.setState({ toggle_all: toggle_all, checkin_inventory: display_inventory })
	}

	handleCheckbox = (display_inventory, wich, obj_id, inv_id) => {
		const checkin_inventory = this.state.checkin_inventory
		// const checkout_inventory = this.state.checkout_inventory
		if (display_inventory.inventory[inv_id].objects[obj_id].checked === true)
		{
			display_inventory.inventory[inv_id].objects[obj_id].checked = false
			display_inventory.inventory[inv_id].objects[obj_id].count = ''
		}
		else
		{
			display_inventory.inventory[inv_id].objects[obj_id].checked = true
			display_inventory.inventory[inv_id].objects[obj_id].count = checkin_inventory.inventory[inv_id].objects[obj_id].quantity
		}
		if (wich === "checkin")
			this.setState({ checkin_inventory: display_inventory })
		else if (wich === "checkout")
			this.setState({ checkout_inventory: display_inventory })
	}

	handleCount = (display_inventory, wich, obj_id, inv_id, event) => {
		// const checkin_inventory = this.state.checkin_inventory
		// const checkout_inventory = this.state.checkout_inventory
		display_inventory.inventory[inv_id].objects[obj_id].count = (event.target.value !== "") ? parseInt(event.target.value) : ""
		if (Number(display_inventory.inventory[inv_id].objects[obj_id].count) === Number(display_inventory.inventory[inv_id].objects[obj_id].quantity))
			display_inventory.inventory[inv_id].objects[obj_id].checked = true
		else
			display_inventory.inventory[inv_id].objects[obj_id].checked = false
		if (wich === "checkin")
			this.setState({ checkin_inventory: display_inventory })
		else if (wich === "checkout")
			this.setState({ checkout_inventory: display_inventory })
	}

	countMissingObj = (wich) => {
		let missing_obj = 0
		let inventory = null
		if (wich === "checkin")
			inventory = this.state.checkin_inventory.inventory
		else if (wich === "checkout")
			inventory = this.state.checkout_inventory.inventory
		for (let i = 0; i < inventory.length; i++)
		{
			for (let j = 0; j < inventory[i].objects.length; j++)
			{
				const count = inventory[i].objects[j].count
				const quantity = inventory[i].objects[j].quantity
				if (count !== "NC" && count !== "" && Number(quantity) !== Number(count))
			 		missing_obj += 1
			}
		}
		return (missing_obj > 0) ? missing_obj : ""
	}

	// PICTURES

	addCheckinPicture = (event) => {
		const reader = new FileReader()
		const file = event.target.files[0]
		reader.addEventListener("load", () => {
			this.props.updateInventoryPictures({
				type: "arrival",
				inventory: this.state.checkin_inventory.inventory,
				user: this.props.user._id,
				stay_number: (this.state.stay) ? this.state.stay.stay_number : this.state.stay_number,
				name: file.name,
				cover: reader.result
			})
			.then(inventory => {
				this.setState({ checkin_inventory: inventory })
			})
			.catch(err => console.log("can not insert picture", err))
		}, false)
		if (file)
			reader.readAsDataURL(file)
	}

	addCheckoutPicture = (event) => {
		const reader = new FileReader()
		const file = event.target.files[0]
		reader.addEventListener("load", () => {
			this.props.updateInventoryPictures({
				type: "departure",
				inventory: this.state.checkout_inventory.inventory,
				user: this.props.user._id,
				stay_number: (this.state.stay) ? this.state.stay.stay_number : this.state.stay_number,
				name: file.name,
				cover: reader.result
			})
			.then(inventory => {
				this.setState({ checkout_inventory: inventory })
			})
			.catch(err => console.log("can not insert picture", err))
		}, false)
		if (file)
			reader.readAsDataURL(file)
	}

	deleteCheckinPic = (picture, inventory) => {
		this.props.deleteInventoryPicture({
			picture: picture,
			inventory: inventory
		})
		.then(inventory => {
			const inventories = this.state.inventories
			for (let i = 0; i < inventories.length; i++)
			{
				if (!inventories[i].checkin_inventory)
					continue
				else if (inventories[i].checkin_inventory._id === inventory._id)
				{
					console.log("reset inventory", inventories[i])
					inventories[i].checkin_inventory = inventory
				}
			}
			this.setState({ inventories: inventories, checkin_inventory: inventory })
		})
		.catch(err => console.log(err))
	}

	deleteCheckoutPic = (picture, inventory) => {
		this.props.deleteInventoryPicture({
			picture: picture,
			inventory: inventory
		})
		.then(inventory => {
			const inventories = this.state.inventories
			for (let i = 0; i < inventories.length; i++)
			{
				if (!inventories[i].checkout_inventory)
					continue
				else if (inventories[i].checkout_inventory._id === inventory._id)
					inventories[i].checkout_inventory = inventory
			}
			this.setState({ inventories: inventories, checkout_inventory: inventory })
		})
		.catch(err => console.log(err))
	}

	// COMMENTS

	handleCheckinComment = (event) => {
		const form5 = {
			...this.state.form5,
			comment_checkin: event.target.value,
		}
		this.setState({ form5: form5 })
	}

	handleCheckoutComment = (event) => {
		const form5 = {
			...this.state.form5,
			comment_checkout: event.target.value,
		}
		this.setState({ form5: form5 })
	}

	submitCommentCheckin = (event) => {
		event.preventDefault()
		this.props.updateInventoryComment({
			type: "arrival",
			inventory: this.state.checkin_inventory.inventory,
			user: this.props.user._id,
			stay_number: (this.state.stay) ? this.state.stay.stay_number : this.state.stay_number,
			comment: this.state.form5.comment_checkin
		})
		.then(inventory => {
			const form5 = {
				comment_checkin: "",
				comment_checkout: ""
			}
			this.setState({ checkin_inventory: inventory, form5: form5 })
		})
		.catch(err => console.log("can not insert comment", err))
	}

	submitCommentCheckout = (event) => {
		event.preventDefault()
		this.props.updateInventoryComment({
			type: "departure",
			inventory: this.state.checkout_inventory.inventory,
			user: this.props.user._id,
			stay_number: (this.state.stay) ? this.state.stay.stay_number : this.state.stay_number,
			comment: this.state.form5.comment_checkout
		})
		.then(inventory => {
			const form5 = {
				comment_checkin: "",
				comment_checkout: ""
			}
			this.setState({ checkout_inventory: inventory, form5: form5 })
		})
		.catch(err => console.log("can not insert comment", err))
	}

	submitInventory = (event) => {
		event.preventDefault()
		/*
		let stay_number = null
		if (this.state.checkout_inventory.stay_number)
			stay_number = this.state.checkout_inventory.stay_number
		else if (this.state.checkin_inventory.stay_number)
			stay_number = this.state.checkin_inventory.stay_number
		else if (this.state.stay_number)
			stay_number = this.state.stay_number
		const inventory = {
			answers: this.state.checkout_inventory.inventory,
			type: "departure",
			stay_number: stay_number
		}
		this.props.insertInventory(inventory)
		.then(inventory => {
			this.closeModal()
		})
		.catch(err => {
			console.log("can not post inventory", err)
		})
		*/
		let inv = null
		let type = null
		if (this.state.wich_mode === "checkin")
		{
			inv = this.state.checkin_inventory.inventory
			type = "arrival"
		}
		else if (this.state.wich_mode === "checkout")
		{
			inv = this.state.checkout_inventory.inventory
			type = "departure"
		}
		if (!inv)
			this.closeModal()
		else
		{
			const inventory = {
				answers: inv,
				type: type,
				stay_number: this.state.stay_number
			}
			this.props.insertInventory(inventory)
			.then(inventory => {
				this.closeModal()
			})
			.catch(err => {
				console.log("can not post inventory", err)
			})
		}
	}

	openModalSearch = () => {
		let is_search_open = false
		if (this.state.is_search_open === true)
			is_search_open = false
		else
			is_search_open = true
		this.setState({ is_search_open: is_search_open, stay_number: '' })
	}

	handleStayNumber = (event) => {
		this.setState({ stay_number: event.target.value })
	}

	_copyInventory = (input_inventory) => {
		const output_inventory = {
			inventory: []
		}
		for (let i = 0; i < input_inventory.inventory.length; i++)
		{
			output_inventory.inventory[i] = {
				name: input_inventory.inventory[i].name,
				translations: input_inventory.inventory[i].translations,
				objects: []
			}
			for (let j = 0; j < input_inventory.inventory[i].objects.length; j++)
			{
				output_inventory.inventory[i].objects[j] = {
					name: input_inventory.inventory[i].objects[j].name,
					translations: input_inventory.inventory[i].objects[j].translations,
					quantity: input_inventory.inventory[i].objects[j].quantity,
					count: "",
					checked: false
				}
			}
		}
		return output_inventory
	}

	_setStateInventories = (inventories) => {
		if (inventories && inventories.length > 0)
		{
			let stay = null
			let client = null
			let checkin_inventory = null
			let checkout_inventory = null
			for (let i = 0; i < inventories.length; i++)
			{
				if (inventories[i].type === "arrival")
				{
					checkin_inventory = inventories[i]
					stay = inventories[i].stay
					client = inventories[i].client
				}
				else if (inventories[i].type === "departure")
				{
					checkout_inventory = inventories[i]
					stay = inventories[i].stay
					client = inventories[i].client
				}
			}
			if (checkout_inventory === null)
				checkout_inventory = this._copyInventory(checkin_inventory)
			if (checkin_inventory !== null)
			{
				for (let i = 0; i < checkout_inventory.inventory.length; i++)
					for (let j = 0; j < checkout_inventory.inventory[i].objects.length; j++)
						checkout_inventory.inventory[i].objects[j].client_count = checkin_inventory.inventory[i].objects[j].count
			}
			else
			{
				checkin_inventory = this._copyInventory(checkout_inventory)
			}
			let warning_arrival = []
			for (let i = 0; i < checkin_inventory.inventory.length; i++)
			{
				for (let j = 0; j < checkin_inventory.inventory[i].objects.length; j++)
				{
					if (Number(checkin_inventory.inventory[i].objects[j].quantity) !== Number(checkin_inventory.inventory[i].objects[j].count))
					{
						warning_arrival.push({ 
							name: checkin_inventory.inventory[i].objects[j].name,
							count: checkin_inventory.inventory[i].objects[j].count,
							quantity: checkin_inventory.inventory[i].objects[j].quantity,
						})
					}
				}
			}
			let warning_departure = []
			for (let i = 0; i < checkout_inventory.inventory.length; i++)
			{
				for (let j = 0; j < checkout_inventory.inventory[i].objects.length; j++)
				{
					if (Number(checkout_inventory.inventory[i].objects[j].quantity) !== Number(checkout_inventory.inventory[i].objects[j].count))
					{
						warning_departure.push({ 
							name: checkout_inventory.inventory[i].objects[j].name,
							count: checkout_inventory.inventory[i].objects[j].count,
							quantity: checkout_inventory.inventory[i].objects[j].quantity,
						})
					}
				}
			}
			this.setState({
				is_open: true,
				checkin_inventory: checkin_inventory,
				checkout_inventory: checkout_inventory,
				stay: stay,
				stay_number: stay.stay_number,
				client: client,
				warning_arrival: warning_arrival,
				warning_departure: warning_departure,
				edit_mode: false,
				toggle_all: false
			})
		}
	}

	openModalInfo = (stay_number) => {
		//...
		this.props.fetchInventoryByStayNb(stay_number)
		.then(inventories => {
			//...
			this._setStateInventories(inventories)
		})
		.catch(err => {
			console.log("can not fetch inventory", err)
		})
	}

	submitSearch = (event) => {
		event.preventDefault()
		let error_obj = this._initErrors()
		let error = false
		this.props.fetchInventoryBySearch(this.state.stay_number)
		.then(inventories => {
				if (inventories === 1)
				{
					error_obj.stay_number = 'Nous n\'avons pas trouvé le numéro de séjour indiqué.'
					error = true
				}
				else if (inventories === 2)
				{
					error_obj.inventory_setup = 'Il n\'y a pas d\'inventaire paramétré pour ce type d\'hébergement'
					error = true
				}
				else if (Array.isArray(inventories)) // checkin ou checkout réalisé
					this._setStateInventories(inventories)
				else if (typeof inventories === 'object') // non réalisé
				{
					const categories = inventories.inventory.categories
					const default_inventory = {
						inventory: []
					}
					for (let h = 0; h < categories.length; h++)
					{
						const inventory = inventories.inventory.categories[h]
						for (let i = 0; i < inventory.translations.length; i++)
						{
							if (inventory.translations[i].lang !== this.props.lang_default)
								continue
							const cat_name = inventory.translations[i].name
							const category = (categories[h]) ? categories[h] : null
							default_inventory.inventory[h] = {
								name: cat_name,
								translations: inventory.translations,
								objects: []
							}
							if (category === null)
								continue
							for (let j = 0; j < category.objects.length; j++)
							{
								const object = category.objects[j]
								for (let k = 0; k < object.translations.length; k++)
								{
									if (object.translations[k].lang !== this.props.lang_default)
										continue
									default_inventory.inventory[h].objects[j] = {
										name: object.translations[k].name,
										translations: object.translations,
										quantity: object.quantity,
										count: "NC",
										checked: false
									}
									break
								}
							}
							break
						}
					}
					this.setState({ is_open: true, checkin_inventory: default_inventory, checkout_inventory: default_inventory, edit_mode: false, toggle_all: false })
				}
				if (error === true)
					this.setState({ success: null, error: error_obj, attempt_submit: false, edit_mode: false, toggle_all: false })
			})
			.catch(err => {
				this.setErrorMsg("error_fetch_stay_number", 1)
				console.log(err)
			})
	}

	handlePhoneTabs = (event) => {
		let tab = this.state.tabs
		let curr = this.state.curr_tab
		tab.forEach((elem) => {
			elem.current = false
		})
		for (let i = 0; i < tab.length; i++)
		{
			if (event.target.value === tab[i].name)
			{
				tab[i].current = true
				curr = tab[i]
				break
			}
		}
		this.setState({ tabs: tab, curr_tab: curr })
	}

	_inventoryStatusIcon = (inventory) => {
		let status = null
		if (!inventory || inventory.status === "uncompleted")
			status = <QuestionMarkCircleIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
		else if (inventory.status === "completed")
			status = <CheckCircleIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400" aria-hidden="true" />
		else if (inventory.status === "warning")
		{
			status = (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5 text-yellow-400">
				<path fillRule="evenodd" d="M9.401 4.504c1.155-2.001 4.043-2.001 5.197 0l7.355 12.747c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 4.504zM12 9.75a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V10.5a.75.75 0 01.75-.75zM12 18a.75.75 0 100-1.5.75.75 0 000 1.5z" clipRule="evenodd" />
			</svg>)
		}
		else if (inventory.status === "infos")
			status = (<InformationCircleIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-blue-400" aria-hidden="true" />)
		else if (inventory.status === "error")
			status = <XCircleIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-red-400" aria-hidden="true" />
		return status
	}

	_searchFilters = (search) => {
		let inventories = []
		if (search.checkin !== false || search.checkout !== false)
		{
			console.log("search", search)
			for (let i = 0; i < this.state.inventories.length; i++)
			{
				if (search.checkin === true && search.checkout === true && this.state.inventories[i].checkin_inventory && this.state.inventories[i].checkout_inventory)
					inventories.push(this.state.inventories[i])
				else if (search.checkin === true && search.checkout === false && this.state.inventories[i].checkin_inventory)
					inventories.push(this.state.inventories[i])
				else if (search.checkout === true && search.checkin === false && this.state.inventories[i].checkout_inventory)
					inventories.push(this.state.inventories[i])
			}
		}
		else
			inventories = this.state.inventories
		let filtered_inventories = []
		if (search.text !== '' && search.select !== this.default_select_text)
		{
			for (let i = 0; i < inventories.length; i++)
			{
				if (search.select === "N. séjour" && Number(inventories[i].stay_number) === Number(search.text))
					filtered_inventories.push(inventories[i])
				else if (search.select === "N. client" && Number(inventories[i].client_number) === Number(search.text))
					filtered_inventories.push(inventories[i])
				else if (search.select === "N. emplacement" &&  inventories[i].stay && inventories[i].stay.location.trim() === search.text.trim())
					filtered_inventories.push(inventories[i])
			}
		}
		else
			filtered_inventories = inventories
		this.setState({ filtered_inventories: filtered_inventories, search: search })
	}

	searchText = (event) => {
		const search = this.state.search
		search.text = event.target.value
		this._searchFilters(search)
	}
	
	searchSelect = (event) => {
		const search = this.state.search
		search.select = event.name
		this._searchFilters(search)
	}

	searchCheckin = () => {
		const search = this.state.search
		search.checkin = (search.checkin) ? false : true
		this._searchFilters(search)
	}

	searchCheckout = () => {
		const search = this.state.search
		search.checkout = (search.checkout) ? false : true
		this._searchFilters(search)
	}

	editMode = (edit_mode, wich) => {
		const edit = (edit_mode === true) ? false : true
		this.setState({ edit_mode: edit, wich_mode: wich })
	}

	_displayInventory = (display_inventory, wich) => {
		const inventory_done = (display_inventory._id) ? true : false
		let create_user = {
			firstname: "",
			lastname: "",
		}
		let update_user = {
			firstname: "",
			lastname: "",
		}
		let create_info = ""
		let update_info = ""
		if (!display_inventory.create_user)
			create_user.firstname = "Établissement"
		else
		{
			create_user.firstname = display_inventory.create_user.firstname
			create_user.lastname = display_inventory.create_user.lastname
		}
		if (display_inventory.create_time)
		{
			create_info = (
				<div>
					<p className="text-sm font-medium text-gray-600">Réalisé par {create_user.firstname} {create_user.lastname}</p>
					<p className="text-sm font-medium text-gray-600">{tools.parseDate(display_inventory.create_time)}</p>
				</div>
			)
		}
		if (!display_inventory.update_user)
			update_user.firstname = "Établissement"
		else
		{
			update_user.firstname = display_inventory.update_user.firstname
			update_user.lastname = display_inventory.update_user.lastname
		}
		if (display_inventory.update_time)
		{
			update_info = (
				<div>
					<p className="text-sm font-medium text-gray-600">Modifié par {update_user.firstname} {update_user.lastname}</p>
					<p className="text-sm font-medium text-gray-600">{tools.parseDate(display_inventory.update_time)}</p>
				</div>
			)
		}
		let warning_obj = ""
		const warning_arrival_nb = (this.state.warning_arrival) ? this.state.warning_arrival.length : 0
		if (display_inventory.status === "warning")
		{
			const warning_array = (wich === "checkin") ? this.state.warning_arrival : this.state.warning_departure
			warning_obj = (
				<div className="rounded-lg overflow-hidden shadow mt-8 w-1/3">
					<div className="bg-yellow-50 p-6">
						<div className="sm:flex sm:items-center sm:justify-between">
							<div className="sm:flex sm:space-x-5">
								<div className="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
									<h3 className="text-xl font-semibold text-yellow-800 mt-0 mb-2">{warning_arrival_nb} éléments remontés</h3>
									<ul className="">
										{warning_array.map((elem, id) => {
											return (
												<li className="flex space-x-1.5 space-y-1.5 items-center" key={id}>
													<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="text-red-700 w-4 h-4">
														<path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
													</svg>
													<span className="text-base font-medium text-yellow-800">{elem.name}: <span className="font-semibold">{elem.count}/{elem.quantity}</span></span>
												</li>
											)
										})}
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			)
		}
		const text_button = (this.state.edit_mode === false) ? "Mode édition" : "Mode lecture"
		// Je n'arrive pas à changer la couleur du bouton pour le mettre en mode disabled
		// ...
		//
		// let disabled = true
		// let class_button = "mb-2 mt-5 inline-flex items-center px-6 py-3 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
		// if ((wich === "checkin" && this.state.config_inventory.arrival_access.staff === true) ||
		// (wich === "checkout" && this.state.config_inventory.departure_access.staff === true))
		// {
		// 	class_button = "mb-2 mt-5 inline-flex items-center px-6 py-3 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
		// 	disabled = false
		// }
		let disabled = false
		let class_button = "mb-2 mt-5 inline-flex items-center px-6 py-3 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
		let edit_button = (
			<button onClick={this.editMode.bind(this, this.state.edit_mode, wich)} disabled={disabled} type="button" className={class_button}>
				{text_button}
				<svg xmlns="http://www.w3.org/2000/svg" className="ml-3 -mr-1 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
					<path strokeLinecap="round" strokeLinejoin="round" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
				</svg>
			</button>
		)
		if ((wich === "checkin" && this.state.config_inventory.arrival_access.staff === false) ||
			(wich === "checkout" && this.state.config_inventory.departure_access.staff === false)) // supprimer lorsque le commentaire au-dessus sera corrigé
			edit_button = null
		let checkbox_toggle_all = (
			<input
				type="checkbox"
				className="-mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6"
				checked={this.state.toggle_all}
				onChange={this.toggleAll.bind(this, display_inventory, wich)}
				style={{ opacity: '100', pointerEvents: 'auto'}}
			/>
		)
		let send_button = (
			<div className="mt-10 px-4 py-3 text-right">
				<button
					type="submit"
					className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
				>
					Enregistrer
				</button>
			</div>
		)
		if (inventory_done === true)
			edit_button = null
		if (this.state.edit_mode === false)
		{
			checkbox_toggle_all = null
			send_button = (
				<div className="mt-10 px-4 py-3 text-right">
					<button
						type="button"
						className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
						onClick={this.closeModal}
					>
						Fermer
					</button>
				</div>
			)
		}
		let inventories_k = -1
		const total_name = (wich === "checkin") ? "Entrée" : "Sortie"
		let titleObjects = null
		let titleReviews = null
		let objectsJSX = null
		let reviewsJSX = null
		let show_objects = false
		let show_reviews = false
		for (let i = 0; i < display_inventory.inventory.length; i++)
		{
			const inventory = display_inventory.inventory[i]
			if (inventory.objects && inventory.objects.length > 0)
				show_objects = true
			if (inventory.reviews && inventory.reviews.length > 0)
				show_reviews = true
			if (show_objects === true && show_reviews === true)
				break
		}
		if (show_objects === true)
		{
			objectsJSX = (
				<table className="bg-white table-auto table-auto divide-y divide-gray-200 rounded-lg">
					<thead>
						<tr>
							<th scope="col" className="px-3 py-3.5 pr-3 text-left text-base font-semibold text-gray-900">
								{checkbox_toggle_all}
							</th>
							<th scope="col" className="px-3 py-3.5 pr-3 text-left text-base font-semibold text-gray-900">
								Désignation
							</th>
							<th scope="col" className="px-3 py-3.5 text-left text-base font-semibold text-gray-900">
								{total_name}
							</th>
							<th scope="col" className="px-3 py-3.5 text-left text-base font-semibold text-gray-900">
								Attendu
							</th>
							<th scope="col" className="px-3 py-3.5 text-left text-base font-semibold text-gray-900">
								Conforme
							</th>
						</tr>
					</thead>
					<tbody className="divide-y divide-gray-200">
						{display_inventory.inventory.map((inventory, inv_id) => {
							inventories_k++
							return (
								<Fragment key={inventories_k}>
									<tr className="border-t border-gray-200 bg-gray-100">
										{/* <td className="whitespace-nowrap px-3 py-4 text-base text-gray-500"></td> */}
											<th
												colSpan={6}
												scope="colgroup"
												className="px-4 py-2 text-left text-base font-bold text-gray-900 sm:px-6"
											>
												{inventory.name}
											</th>
									</tr>
									{inventory.objects.map((obj, obj_id) => {
										let obj_count = (obj.count !== "NC" && obj.count !== "") ? Number(obj.count) : obj.count
										const obj_quantity = Number(obj.quantity)
										let statut = (obj_count === obj_quantity) ? <CheckCircleIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400" aria-hidden="true" /> : <XCircleIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-red-400" aria-hidden="true" />
										if (obj_count === "" || obj_count === "NC")
											statut = <QuestionMarkCircleIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
										let user_count = obj_count
										let is_missing = this.classNames('border-gray-300')
										if ((obj_count === "" || !obj_count) && obj_count !== 0)
											obj_count = "NC"
										if ((obj_count !== "NC") && (obj_quantity !== obj_count))
											is_missing = this.classNames('border-t bg-yellow-50')
										let checkbox_obj = (
											<input
												id={obj_id}
												type="checkbox"
												className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6"
												style={{ opacity: '100', pointerEvents: 'auto'}}
												onChange={this.handleCheckbox.bind(this, display_inventory, wich, obj_id, inv_id)}
												checked={obj.checked}
											/>
										)
										let count_input_style = "border border-gray-300 bg-white rounded-md px-3 shadow-sm focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600"
										let input_error = ""
										if (obj_count === "")
										{
											count_input_style = "border bg-white border-red-300 ring-1 ring-red-300 rounded-md px-3 shadow-sm"
											input_error = (<p className="text-red-400 text-sm font-semibold mt-2">Indiquez le nombre d'objets présents</p>)
										}
										let count_obj = (
											<div className={count_input_style}>
												<input
													id={'number' + obj_id}
													type="number"
													min="0"
													className="block w-24 border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 text-sm disabled:placeholder-pink-500 disabled:border-pink-200"
													value={obj_count}
													onChange={this.handleCount.bind(this, display_inventory, wich, obj_id, inv_id)}
													style={{ borderBottom: 0, margin: 0 }}
												/>
												{input_error}
											</div>
										)
										if (this.state.edit_mode === false)
										{
											checkbox_obj = null
											count_obj = user_count
										}
										return (
											<tr
												key={obj_id}
												className={is_missing}
											>
												<td className="relative w-12 px-6 sm:w-16 sm:px-8">
													{checkbox_obj}
												</td>
												<td className="py-4 pl-4 pr-3 text-base font-medium text-gray-900 sm:pl-4">
													{obj.name}
												</td>
												<td className="px-3 py-4 text-base text-gray-500">{count_obj}</td>
												<td className="px-3 py-4 text-base text-gray-500">{obj_quantity}</td>
												<td 
													className={(obj_count === obj_quantity ? 'font-medium text-green-500 ' : 'font-semibold text-red-400 ') + 'whitespace-nowrap px-3 py-4 text-base'}
												>
													{statut}
												</td>
										</tr>
										)
									})}
								</Fragment>
							)
						})}
					</tbody>
				</table>
			)
		}
		if (show_reviews === true)
		{
			reviewsJSX = (
				<table className="bg-white table-auto table-auto divide-y divide-gray-200 rounded-lg">
					<thead>
						<tr>
							<th scope="col" className="px-3 py-3.5 pr-3 text-left text-base font-semibold text-gray-900">
								Désignation
							</th>
							<th scope="col" className="px-3 py-3.5 text-center text-base font-semibold text-gray-900">
								Propreté
							</th>
							<th scope="col" className="px-3 py-3.5 text-center text-base font-semibold text-gray-900">
								État Général
							</th>
						</tr>
					</thead>
					<tbody className="divide-y divide-gray-200">
						{display_inventory.inventory.map((inventory, inv_id) => {
							inventories_k++
							return (
								<Fragment key={inventories_k}>
									<tr className="border-t border-gray-200 bg-gray-100">
										<th
											colSpan={6}
											scope="colgroup"
											className="px-4 py-2 text-left text-base font-bold text-gray-900 sm:px-6"
										>
											{inventory.name}
										</th>
									</tr>
									{inventory.reviews.map((review, rev_id) => {
										let star_condition = []
										for (let i = 0; i < 5; i++)
										{
											if (i < review.condition)
												star_condition.push(star_fill)
											else
												star_condition.push(star_lineal)
										}
										let star_cleanliness = []
										for (let i = 0; i < 5; i++)
										{
											if (i < review.cleanliness)
												star_cleanliness.push(star_fill)
											else
												star_cleanliness.push(star_lineal)
										}
										return (
											<tr
												key={rev_id}
												className="border-t border-gray-200"
											>
												<td className="px-3 py-4 text-base font-medium text-gray-900">
													{review.name}
												</td>
												<td className="px-3 py-4 text-base text-gray-500">
													<p className="flex justify-center space-x-3">
														<img className="w-8" src={star_cleanliness[0]} alt="note 1"/* onClick={this.clickCleanlinessNotation.bind(this, 1, itemIdx)}*/ />
														<img className="w-8" src={star_cleanliness[1]} alt="note 2"/* onClick={this.clickCleanlinessNotation.bind(this, 2, itemIdx)}*/ />
														<img className="w-8" src={star_cleanliness[2]} alt="note 3"/* onClick={this.clickCleanlinessNotation.bind(this, 3, itemIdx)}*/ />
														<img className="w-8" src={star_cleanliness[3]} alt="note 4"/* onClick={this.clickCleanlinessNotation.bind(this, 4, itemIdx)}*/ />
														<img className="w-8" src={star_cleanliness[4]} alt="note 5"/* onClick={this.clickCleanlinessNotation.bind(this, 5, itemIdx)}*/ />
													</p>
												</td>
												<td className="px-3 py-4 text-base text-gray-500">
													<p className="flex justify-center space-x-3">
														<img className="w-8" src={star_condition[0]} alt="note 1"/* onClick={this.clickConditionNotation.bind(this, 1, itemIdx)}*/ />
														<img className="w-8" src={star_condition[1]} alt="note 2"/* onClick={this.clickConditionNotation.bind(this, 2, itemIdx)}*/ />
														<img className="w-8" src={star_condition[2]} alt="note 3"/* onClick={this.clickConditionNotation.bind(this, 3, itemIdx)}*/ />
														<img className="w-8" src={star_condition[3]} alt="note 4"/* onClick={this.clickConditionNotation.bind(this, 4, itemIdx)}*/ />
														<img className="w-8" src={star_condition[4]} alt="note 5"/* onClick={this.clickConditionNotation.bind(this, 5, itemIdx)}*/ />
													</p>
												</td>
											</tr>
										)
									})}
								</Fragment>
							)
						})}
					</tbody>
				</table>
			)
		}
		if (show_objects === true && show_reviews === true)
		{
			titleObjects = (
				<div className="mt-8 mb-8">
					<h3 className="pl-2 pt-4 text-2xl font-semibold text-gray-800">Fournitures</h3>
				</div>
			)
			titleReviews = (
				<div className="mt-8 mb-8">
					<h3 className="pl-2 text-2xl font-bold text-gray-800">État des lieux</h3>
				</div>
			)
		}
		console.log("titles", titleObjects, titleReviews)
		return (
			<div className="px-4 sm:px-6 lg:px-8 overflow-scroll h-full bg-gray-50 pb-32">
				<div className="mt-8 sm:items-center">
					<div className="sm:flex sm:space-x-5 ">
						<div className="mt-4 sm:mt-0 sm:pt-1 sm:text-left space-y-1">
							<div className="flex space-x-2 items-center">
								<HomeIcon className="h-6 w-6 flex-shrink-0 text-gray-800" />
								<span className="text-xl font-semibold text-gray-800">Emplacement {(this.state.stay) ? this.state.stay.location : ""}</span>
							</div>
							<p className="text-sm font-semibold text-gray-800">{(this.state.stay) ? this.state.stay.location_category : ""}</p>
							{create_info}
							{update_info}
						</div>
					</div>
				</div>
				{edit_button}
				{warning_obj}
				<div className="mt-8 flex flex-col">
					<div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
						<div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
							<form onSubmit={this.submitInventory}>
								<div className="relative shadow rounded-lg">
									{titleObjects}
									{objectsJSX}
									{titleReviews}
									{reviewsJSX}
								</div>
								{send_button}
							</form>
						</div>
					</div>
				</div>
			</div>
		)
	}

	render() {
		const checkin_inventory = this.state.checkin_inventory
		const checkout_inventory = this.state.checkout_inventory
		let content = null
		let modal_content = null
		let error_staynumberJSX = null
		let error_inventory_setupJSX = null
		if (this.state.error.stay_number)
		{
			error_staynumberJSX = (
				<div className="rounded-md bg-red-50 p-4">
					<div className="flex">
						<div className="flex-shrink-0">
							<XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
						</div>
						<div className="ml-3">
							<h3 className="text-sm my-0 font-medium text-red-800">{this.state.error.stay_number}</h3>
							<div className="mt-2 text-sm text-red-700">
								<ul className="list-disc space-y-1 pl-5">
									<li>Il y a une erreur dans le numéro de séjour,</li>
									<li>ou ce séjour n'a peut-être pas encore été importé dans le dashboard.</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			)
		}
		if (this.state.error.inventory_setup)
		{
			error_inventory_setupJSX = (
				<div className="rounded-md bg-red-50 p-4">
					<div className="flex">
						<div className="flex-shrink-0">
							<XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
						</div>
						<div className="ml-3">
							<h3 className="text-sm my-0 font-medium text-red-800">{this.state.error.inventory_setup}</h3>
						</div>
					</div>
				</div>
			)
		}
		const permissions = this.props.user.permissions
		if (!permissions || !permissions.inventories || permissions.inventories === 0)
			return (<Redirect to="/" />)
		else if (this.props.features !== "premium")
		{
			return (
				<>
					<OnlyPremiumEmpty 
						title="Inventaires"
						subtitle="Retrouvez les inventaires remplis par vos clients, effectuez les inventaires de sortie."
						emptyFolderSubtitle="Pas d'inventaires"
						navName="Inventaires"
						navPath="/inventaires"
					/>
					<div className="mt-10">
						<PremiumAlert
							content="Mettez votre abonnement à niveau avec la formule premium pour accéder aux inventaires !"
							button_text="En savoir plus !"
						/>
					</div>
				</>
			)
		}
		else if (!this.state.inventories || !this.state.inventories.length)
		{
			const content_empty_folder = "Si vous souhaitez réaliser un inventaire avec vos équipes, cliquez sur le bouton \"Faire un inventaire\""
			let inv_content = ""
			if (!this.state.inventories) 
			{
				inv_content = (
					<div className="loader">
						<Loader />
					</div>
				)
			}
			else if (!this.state.inventories.length)
			{
				inv_content = ( 
					<EmptyFolder 
						emptyFolderSubtitle = "Pas d'inventaire réalisé pour le moment."
						content = {content_empty_folder}
					/>
				)
			}
			content = (
				<div className="flex flex-col">
					<div className="mb-7">
						<h2 className="text-xl mt-0 leading-6 font-medium text-gray-900 mb-0">Inventaires</h2>
						<p className="mt-1 text-base text-gray-500">
							Retrouvez les inventaires remplis par vos clients, effectuez les inventaires de sortie.
						</p>
					</div>
					<div className="grid-cols-1">
						<button onClick={this.openModalSearch} type="button" className="mb-10 inline-flex items-center px-6 py-3 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
							Faire un inventaire
							<svg xmlns="http://www.w3.org/2000/svg" className="ml-3 -mr-1 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
								<path strokeLinecap="round" strokeLinejoin="round" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
							</svg>
						</button>
					</div>
					{inv_content}
				</div>
			)
		}
		else if (this.state.inventories && this.state.inventories.length)
		{
			const inventories = (this.state.filtered_inventories) ? this.state.filtered_inventories : this.state.inventories
			const filter = [
				{ id: 1, name: 'N. séjour' },
				{ id: 2, name: 'N. emplacement' },
				{ id: 3, name: 'N. client' },
			]
			const style = {
				border: 'solid',
				borderWidth: '1px',
				fontSize: '0.875rem',
				lineHeight: '1.25rem',
				borderRadius: '0.375rem',
				borderColor: 'rgba(209, 213, 219, var(--tw-border-opacity))',
				opacity: 1,
				boxShadow: 'none',
				padding: '0 0.75rem',
				marginBottom: '0'
			}
			content = (
				<div className="flex flex-col">
					<div className="mb-7">
						<h2 className="text-xl mt-0 leading-6 font-medium text-gray-900 mb-0">Inventaires</h2>
						<p className="mt-1 text-base text-gray-500">
							Retrouvez les inventaires remplis par vos clients, effectuez les inventaires de sortie.
						</p>
					</div>
					<div className="grid-cols-1">
						<button onClick={this.openModalSearch} type="button" className="mb-10 inline-flex items-center px-6 py-3 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
							Faire un inventaire
							<svg xmlns="http://www.w3.org/2000/svg" className="ml-3 -mr-1 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
								<path strokeLinecap="round" strokeLinejoin="round" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
							</svg>
						</button>
					</div>
					<p className="mb-4">
						{inventories.length} résultats
					</p>
					<div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
						<div className="py-2 align-middle inline-block min-w-fit sm:px-6 lg:px-8">
							<div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
								<div className="bg-white border-b border-gray-200 py-4 px-5">
									<form className="flex justify-between sm:items-center">
										<div className="flex">
											<div className="mr-5 w-fulltext-sm sm:max-w-xs">
												<label htmlFor="email" className="sr-only">
													Search
												</label>
												<input
													style={style}
													type="text"
													className="text-sm mx-4 block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
													placeholder="N° client, séjour ou emplacement."
													onChange={this.searchText}
													value={this.state.search.text}
												/>
											</div>
											<Listbox value={this.state.search.select} onChange={this.searchSelect}>
												{({ open }) => (
													<>
													<div className="relative ml-3">
														<Listbox.Button className="relative w-44 cursor-default rounded-md border border-gray-300 bg-white py-3.5 pl-3 pr-10 ml-5 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
															<span className="block truncate">{this.state.search.select}</span>
															<span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
																<ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
															</span>
														</Listbox.Button>
														<Transition
															show={open}
															as={Fragment}
															leave="transition ease-in duration-100"
															leaveFrom="opacity-100"
															leaveTo="opacity-0"
														>
														<Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
															{filter.map((person) => (
																<Listbox.Option
																	key={person.id}
																	className={({ active }) =>
																		this.classNames(
																			active ? 'text-white bg-indigo-600' : 'text-gray-900',
																			'relative cursor-default select-none py-2 pl-3 pr-9'
																		)
																	}
																	value={person}
																>
																	{({ selected, active }) => (
																		<>
																			<span className={this.classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
																				{person.name}
																			</span>
																			{selected ? (
																				<span
																					className={this.classNames(
																					active ? 'text-white' : 'text-indigo-600',
																					'absolute inset-y-0 right-0 flex items-center pr-4'
																					)}
																				>
																					<CheckIcon className="h-5 w-5" aria-hidden="true" />
																				</span>
																			) : null}
																		</>
																	)}
																</Listbox.Option>
															))}
														</Listbox.Options>
														</Transition>
													</div>
													</>
												)}
											</Listbox>
										</div>
										{/* --------- CHECKBOX ICI ------------ */}
										<div className="flex">
											<div className="flex">
												<div className="relative w-8 sm:w-6 sm:px-6">
													<input
														type="checkbox"
														onChange={this.searchCheckin}
														className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6"
														style={{ opacity: '100', pointerEvents: 'auto'}}
														checked={this.state.search.checkin}
													/>
												</div>
												<div>Inventaires d'entrée</div>
											</div>
											<div className="flex">
												<div className="relative w-8 sm:w6 sm:px-6">
													<input
														type="checkbox"
														onChange={this.searchCheckout}
														className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6"
														style={{ opacity: '100', pointerEvents: 'auto'}}
														checked={this.state.search.checkout}
													/>
												</div>
												<div>Inventaires de sortie</div>
											</div>
										</div>
										{/* --------- CHECKBOX ICI ------------ */}
									</form>
								</div>
								<table className="table-auto min-w-fit divide-y divide-gray-200">
									<thead className="bg-gray-50">
										<tr>
											<th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
												<div onClick={this.filter.bind(this, 'client.client_number')} className="group cursor-pointer inline-flex text-indigo-500">
													client n°
													<span className="ml-2 flex-none rounded">
														{this._setChevron(this.state.sort.client_number)}
													</span>
												</div>
											</th>
											<th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
												<div onClick={this.filter.bind(this, 'stay_number')} className="group cursor-pointer inline-flex text-indigo-500">
													séjour n°
													<span className="ml-2 flex-none rounded">
														{this._setChevron(this.state.sort.stay_number)}
													</span>
												</div>
											</th>
											<th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
												<div onClick={this.filter.bind(this, 'stay.location')} className="group cursor-pointer inline-flex text-indigo-500">
													emplacement
													<span className="ml-2 flex-none rounded">
														{this._setChevron(this.state.sort.location)}
													</span>
												</div>
											</th>
											<th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
												<div onClick={this.filter.bind(this, 'stay.location_category')} className="group cursor-pointer inline-flex text-indigo-500">
													hébergement
													<span className="ml-2 flex-none rounded">
														{this._setChevron(this.state.sort.location_category)}
													</span>
												</div>
											</th>
											<th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
												<div onClick={this.filter.bind(this, 'client.lastname')} className="group cursor-pointer inline-flex text-indigo-500">
													nom
													<span className="ml-2 flex-none rounded">
														{this._setChevron(this.state.sort.lastname)}
													</span>
												</div>
											</th>
											<th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
												<div onClick={this.filter.bind(this, 'checkin_inventory.status')} className="group cursor-pointer inline-flex text-indigo-500">
													entrée
													<span className="ml-2 flex-none rounded">
														{this._setChevron(this.state.sort.checkin_status)}
													</span>
												</div>
											</th>
											<th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
												<div onClick={this.filter.bind(this, 'checkout_inventory.status')} className="group cursor-pointer inline-flex text-indigo-500">
													sortie
													<span className="ml-2 flex-none rounded">
														{this._setChevron(this.state.sort.checkout_status)}
													</span>
												</div>
											</th>
											<th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
												<div onClick={this.filter.bind(this, 'stay.arrival_time')} className="group cursor-pointer inline-flex text-indigo-500">
													arrivée
													<span className="ml-2 flex-none rounded">
														{this._setChevron(this.state.sort.arrival_time)}
													</span>
												</div>
											</th>
											<th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
												<div onClick={this.filter.bind(this, 'stay.departure_time')} className="group cursor-pointer inline-flex text-indigo-500">
													départ
													<span className="ml-2 flex-none rounded">
														{this._setChevron(this.state.sort.departure_time)}
													</span>
												</div>
											</th>
											<th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
												<div onClick={this.filter.bind(this, 'inventory.deposit_date')} className="group cursor-pointer inline-flex text-indigo-500">
													dépôt
													<span className="ml-2 flex-none rounded">
														{this._setChevron(this.state.sort.deposit_date)}
													</span>
												</div>
											</th>
											<th scope="col" className="relative px-6 py-3">
												<span className="sr-only">Modifier</span>
											</th>
										</tr>
									</thead>
									<tbody>
										{inventories.map((inventory, inventory_k) => {
											let inv = inventory.checkout_inventory
											if (inventory.checkin_inventory)
												inv = inventory.checkin_inventory
											let checkin_status = this._inventoryStatusIcon(inventory.checkin_inventory)
											let checkout_status = this._inventoryStatusIcon(inventory.checkout_inventory)
											return (
												<tr key={inventory_k} className={inventory_k % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
													<td className="px-6 py-4 text-sm text-gray-900">{(inventory.client_number) ? inventory.client_number : "-"}</td>
													<td className="px-6 py-4 text-sm text-gray-900">{inventory.stay_number}</td>
													<td className="px-6 py-4 text-sm text-gray-900">{(inventory.stay) ? inventory.stay.location : "-"}</td>
													<td className="px-6 py-4 text-sm text-gray-900">{(inventory.stay) ? inventory.stay.location_category : "-"}</td>
													<td className="px-6 py-4 text-sm font-medium text-gray-900">{(inv.client) ? inv.client.firstname : ""} {(inv.client) ? inv.client.lastname : "-"} </td>
													<td className="px-6 py-4 text-sm text-gray-900">{checkin_status}</td>
													<td className="px-6 py-4 text-sm text-gray-900">{checkout_status}</td>
													<td className="px-6 py-4 text-sm text-gray-900">{(inventory.stay) ? tools.parseDate(inventory.stay.arrival_time, false) : "-"}</td>
													<td className="px-6 py-4 text-sm text-gray-900">{(inventory.stay) ? tools.parseDate(inventory.stay.departure_time, false) : "-"}</td>
													<td className="px-6 py-4 text-sm text-gray-900">{(inventory.deposit_date) ? tools.parseDate(inventory.deposit_date, false) : "-"}</td>
													<td className="px-6 py-4 text-right text-sm font-medium">
														<button onClick={this.openModalInfo.bind(this, inventory.stay_number)} className="text-indigo-600 hover:text-indigo-900">
															consulter
														</button>
													</td>
												</tr>
											)
										})}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			)
		}
		if (this.state.curr_tab.name === 'Détails séjour' && checkin_inventory) // MODAL: SÉJOUR
		{
			// const date = tools.parseDate(checkin_inventory.create_time, false)
			console.log("client", this.state.inventories.client)
			modal_content = (
				<div>
					<div className="border-t border-gray-200">
						<dl>
							<div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 w-screen min-w-full">
								<dt className="text-base font-medium text-gray-500">Nom</dt>
								<dd className="mt-1 text-base text-gray-900 sm:mt-0 sm:col-span-2">{this.state.client.firstname} {this.state.client.lastname}</dd>
							</div>
							<div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 w-screen min-w-full">
								<dt className="text-base font-medium text-gray-500">Adresse e-mail</dt>
								<dd className="mt-1 text-base text-gray-900 sm:mt-0 sm:col-span-2">{this.state.client.email1}</dd>
							</div>
							<div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 w-screen min-w-full">
								<dt className="text-base font-medium text-gray-500">Emplacement</dt>
								<dd className="mt-1 text-base text-gray-900 sm:mt-0 sm:col-span-2">{this.state.stay.location}</dd>
							</div>
							<div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 w-screen min-w-full">
								<dt className="text-base font-medium text-gray-500">Hébergement</dt>
								<dd className="mt-1 text-base text-gray-900 sm:mt-0 sm:col-span-2">{this.state.stay.location_category}</dd>
							</div>
							{/* <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 w-screen min-w-full">
								<dt className="text-base font-medium text-gray-500">Réalisé le</dt>
								<dd className="mt-1 text-base text-gray-900 sm:mt-0 sm:col-span-2">{date}</dd>
							</div> */}
							<div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 w-screen min-w-full">
								<dt className="text-base font-medium text-gray-500">Numéro de séjour</dt>
								<dd className="mt-1 text-base text-gray-900 sm:mt-0 sm:col-span-2">{this.state.stay.stay_number}</dd>
							</div>
						</dl>
					</div>
					<div className="mt-10 px-4 py-3 text-right sm:px-6">
						<button
							type="submit"
							className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
							onClick={this.closeModal}
						>
							Fermer
						</button>
					</div>
				</div>
			)
		}
		else if (this.state.curr_tab.name === 'Inventaire d\'entrée' && checkin_inventory) // MODAL: ENTRÉE
			modal_content = this._displayInventory(checkin_inventory, "checkin")
		else if (this.state.curr_tab.name === 'Inventaire de sortie' && checkout_inventory) // MODAL: SORTIE
			modal_content = this._displayInventory(checkout_inventory, "checkout")
		else if (this.state.curr_tab.name === 'Photos' && checkin_inventory) // MODAL: PHOTOS
		{
			let user_pictures_checkin = (<p className="text-base text-gray-700 mt-5 mb-10 italic">Pas de photos postées.</p>)
			let user_pictures_checkout = (<p className="text-base text-gray-700 mt-5 mb-10 italic">Pas de photos postées.</p>)
			// let staff_pictures_checkin = (<p className="text-base text-gray-700 mt-5 mb-10 italic">Pas de photos postées.</p>)
			// let staff_pictures_checkout = (<p className="text-base text-gray-700 mt-5 mb-10 italic">Pas de photos postées.</p>)
			if (checkin_inventory.pictures && checkin_inventory.pictures.length) // CHECKIN: USER 
			{
				let create_user = {
					firstname: "",
					lastname: ""
				}					
				user_pictures_checkin = (
					<ul className="grid grid-cols-2 gap-x-4 gap-y-8 my-5 sm:grid-cols-3 sm:gap-x-6 md:grid-cols-4 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8">
						{checkin_inventory.pictures.map((picture, picture_id) => {
							if (!picture.user)
								create_user.firstname = "Établissement"
							else
							{
								create_user.firstname = picture.user.firstname
								create_user.lastname = picture.user.lastname

							}
							return (
								<li key={picture_id} className="relative">
									<a
										href={connexion.connect.url + 'dashboard/files/get-image/' + String(picture._id)}
										className={this.classNames(
											picture.current
											? 'ring-2 ring-offset-2 ring-indigo-500'
											: 'focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500',
											'group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 overflow-hidden'
										)}
										target="_blank"
										rel="noopener noreferrer"
									>
										<img
											src={connexion.connect.url + 'dashboard/files/get-image/' + String(picture._id) + '/720'}
											alt=""
											className={this.classNames(
											picture.current ? '' : 'group-hover:opacity-75',
											'object-cover pointer-events-none'
											)}
										/>
									</a>
									{/*
										** Commenté en attendant d'ajouter un avertissement avant de supprimer la photo

										<span className="absolute top-0 right-0 block h-6 w-6 rounded-full ring-2 ring-white bg-gray-700" onClick={this.deleteCheckinPic.bind(this, picture_id, checkin_inventory._id)}>
											<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="white">
												<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
											</svg>
										</span>
									*/}
									<p className="pointer-events-none mt-2 block truncate text-sm font-medium text-gray-900">{create_user.firstname} {create_user.lastname}</p>
									<p className="pointer-events-none block text-sm font-medium text-gray-500">{picture.name}</p>
									<p className="pointer-events-none block text-sm font-medium text-gray-500">{tools.parseDate(picture.created_time)}</p>
								</li>
							)
						})}
					</ul>
				)
			}
			if (this.state.checkout_inventory && this.state.checkout_inventory.pictures) // CHECKOUT: USER
			{
				let create_user = {
					firstname: "",
					lastname: ""
				}
				user_pictures_checkout = (
					<>
						<ul className="grid grid-cols-2 gap-x-4 gap-y-8 my-5 sm:grid-cols-3 sm:gap-x-6 md:grid-cols-4 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8">
							{this.state.checkout_inventory.pictures.map((picture, picture_id) => {
								if (!picture.user)
									create_user.firstname = "Établissement"
								else
								{
									create_user.firstname = picture.user.firstname
									create_user.lastname = picture.user.lastname

								} 
								return (
									<li key={picture_id} className="relative">
										<div
											className={this.classNames(
												picture.current
												? 'ring-2 ring-offset-2 ring-indigo-500'
												: 'focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500',
												'group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 overflow-hidden'
											)}
										>
											<img
												src={picture.cover}
												alt=""
												className={this.classNames(
												picture.current ? '' : 'group-hover:opacity-75',
												'object-cover pointer-events-none'
												)}
											/>
											<button type="button" className="absolute inset-0 focus:outline-none">
												<span className="sr-only">View details for {picture.name}</span>
											</button>
										</div>
										<span className="absolute top-0 right-0 block h-6 w-6 rounded-full ring-2 ring-white bg-gray-700" onClick={this.deleteCheckoutPic.bind(this, picture_id, checkout_inventory._id)}>
											<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="white">
												<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
											</svg>
										</span>
										<p className="pointer-events-none mt-2 block truncate text-sm font-medium text-gray-900">{create_user.firstname} {create_user.lastname}</p>
										<p className="pointer-events-none block text-sm font-medium text-gray-500">{tools.parseDate(picture.timestamp)}</p>
									</li>
								)
							})}
						</ul>
					</>
				)
			}
			modal_content = (
				<div className="px-4 sm:px-6 lg:px-8 overflow-scroll h-full pb-32 divide-y divide-gray-300"> 
					<div>
						<h1 className="text-xl font-semibold text-gray-900">Entrée</h1>
						<div>
							<label htmlFor="picture_checkin" className="mt-0 mb-5 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
								<CameraIcon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
								Ajouter une photo
							</label>
							<input
								className="opacity-0 w-0.5 h-0.5 absolute"
								type="file"
								onChange={this.addCheckinPicture}
								id="picture_checkin"
							/>
						</div>
						{user_pictures_checkin}
					</div>
					<div>
						<h1 className="text-xl font-semibold text-gray-900">Sortie</h1>	
						<div>
							<label htmlFor="picture_checkout" className="mt-0 mb-5 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
								<CameraIcon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
								Ajouter une photo
							</label>
							<input
								className="opacity-0 w-0.5 h-0.5 absolute"
								type="file"
								onChange={this.addCheckoutPicture}
								id="picture_checkout"
							/>
						</div>
						{user_pictures_checkout}
					</div>
				</div>
			)
		}
		else if (this.state.curr_tab.name === 'Commentaires' && checkin_inventory) // MODAL: COMMENTAIRES
		{
			// let edit_staff_checkout_comment = ""
			// let edit_staff_checkin_comment = ""
			let user_checkin_comment = ""
			let user_checkout_comment = ""
			let staff_checkin_comment = ""
			let staff_checkout_comment = ""
			
			//-- CHECKIN --
			staff_checkin_comment = (
				<div className="flex items-start space-x-4 mt-5">
					<div className="min-w-0 flex-1">
						<form className="relative" onSubmit={this.submitCommentCheckin}>
							<div className="border border-gray-300 bg-white rounded-lg shadow-sm overflow-hidden focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500">
								<label htmlFor="comment" className="sr-only"></label>
								<textarea
									rows={10}
									name="comment"
									id="comment"
									className="block w-full h-32 py-3 border-0 resize-none focus:ring-0 sm:text-base"
									placeholder="Écrivez ici..."
									onChange={this.handleCheckinComment}
									value={this.state.form5.comment_checkin}
								/>
							</div>
							<div className="mt-2 flex justify-end">
								<button
									type="submit"
									className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
									// onClick={this.showCheckinCommentPreview}
								>
									Enregistrer
								</button>
							</div>
						</form>
					</div>
				</div>
			)
			//-- CHECKIN/STAFF --
			if (checkin_inventory.comment && Array.isArray(checkin_inventory.comment) && checkin_inventory.comment.length > 0)
			{
				user_checkin_comment = checkin_inventory.comment.map((comment, comment_k) => {
					let name = "Établissement"
					if (comment.user && comment.user.firstname && comment.user.lastname)
						name = comment.user.firstname + " " + comment.user.lastname
					const date = tools.parseDate(comment.timestamp)
					return (
						<div key={comment_k} className="bg-white px-4 py-6 mb-5 shadow sm:p-8 sm:rounded-lg">
							<div className="flex space-x-3">
								<div className="flex-shrink-0">
									<span className="inline-block h-10 w-10 rounded-full overflow-hidden bg-gray-100">
										<svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
											<path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
										</svg>
									</span>
								</div>
								<div className="min-w-0 flex-1">
									<p className="text-base font-medium text-gray-900">
										{name}
									</p>
									<p className="text-base text-gray-500">
										<time>{date}</time>
									</p>
								</div>
							</div>
							<div className="mt-2 text-base text-gray-700 space-y-4">
								<p>{comment.comment}</p>
							</div>
						</div>
					)
				})
			}
			//-- CHECKOUT --
			staff_checkout_comment = (
				<div className="flex items-start space-x-4 mt-5">
					<div className="min-w-0 flex-1">
						<form className="relative" onSubmit={this.submitCommentCheckout}>
							<div className="border border-gray-300 bg-white rounded-lg shadow-sm overflow-hidden focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500">
								<label htmlFor="comment" className="sr-only"></label>
								<textarea
									rows={10}
									name="comment"
									id="comment"
									className="block w-full h-32 py-3 border-0 resize-none focus:ring-0 sm:text-base"
									placeholder="Écrivez ici..."
									onChange={this.handleCheckoutComment}
									value={this.state.form5.comment_checkout}
								/>
							</div>
							<div className="mt-2 flex justify-end">
								<button
									type="submit"
									className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
									// onClick={this.submitCommentCheckout}
								>
									Enregistrer
								</button>
							</div>
						</form>
					</div>
				</div>
			)
			//-- CHECKOUT/USER --
			if (checkout_inventory.comment && Array.isArray(checkout_inventory.comment) && checkout_inventory.comment.length > 0)
			{
				user_checkout_comment = checkout_inventory.comment.map((comment, comment_k) => {
					let name = "Établissement"
					if (comment.user && comment.user.firstname && comment.user.lastname)
						name = comment.user.firstname + " " + comment.user.lastname
					const date = tools.parseDate(comment.timestamp)
					return (
						<div key={comment_k} className="bg-white px-4 py-6 mb-5 shadow sm:p-8 sm:rounded-lg">
							<div className="flex space-x-3">
								<div className="flex-shrink-0">
									<span className="inline-block h-10 w-10 rounded-full overflow-hidden bg-gray-100">
										<svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
											<path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
										</svg>
									</span>
								</div>
								<div className="min-w-0 flex-1">
									<p className="text-base font-medium text-gray-900">
										{name}
									</p>
									<p className="text-base text-gray-500">
										<time>{date}</time>
									</p>
								</div>
							</div>
							<div className="mt-2 text-base text-gray-700 space-y-4">
								<p>{comment.comment}</p>
							</div>
						</div>
					)
				})
			}
			const show_staff_checkin_comment = (
				<>
					<div className="flex flex-row justify-between">
						<h3 className="text-sm italic font-normal text-gray-700 my-0">Laissez un commentaire</h3>
						{/* {edit_staff_checkin_comment} */}
					</div>
					{staff_checkin_comment}
				</>
			)
			const show_staff_checkout_comment = (
				<>
					<div className="flex flex-row justify-between">
						<h3 className="text-sm italic font-normal text-gray-700 my-0">Laissez un commentaire</h3>
						{/* {edit_staff_checkout_comment} */}
					</div>
					{staff_checkout_comment}
				</>
			)
			modal_content = (
				<div className="h-full bg-gray-100 pt-10 px-4 lg:px-8 overflow-scroll h-full pb-32">
					<h1 className="text-xl font-semibold text-gray-900 my-0">Espace commentaire</h1>
					<p className="mt-2 text-base text-gray-700 mb-10">Votre commentaire sera visible par le client.</p>
					<div className="max-w-lg mx-auto">
						<div className="divide-y divide-gray-300">
							<div className="mb-10">
								<h2 className="text-lg font-medium text-gray-800 mt-10">Entrée</h2>
								{user_checkin_comment}
								{show_staff_checkin_comment}
							</div>
							<div className="mb-10">
								<h2 className="text-lg font-medium text-gray-800 mt-10">Sortie</h2>
								{user_checkout_comment}
								{show_staff_checkout_comment}
							</div>
						</div>
					</div>
				</div>
			)
		}
		return (
			<>
				<Transition appear show={this.state.is_open} as={Fragment}>
					<Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={this.closeModal}>
						<div className="flex justify-center text-center sm:block sm:p-0">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0"
								enterTo="opacity-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100"
								leaveTo="opacity-0"
							>
								<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
							</Transition.Child>
							<span
								className="hidden sm:inline-block sm:align-middle sm:h-screen"
								aria-hidden="true"
							>
								&#8203;
							</span>
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 scale-95"
								enterTo="opacity-100 scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 scale-100"
								leaveTo="opacity-0 scale-95"
							>
								<div className="overflow-hidden inline-block align-bottom bg-white rounded-lg text-left shadow-xl transform transition-all sm:align-middle lg:max-w-5xl sm:w-full lg:h-4/5-screen">
									<div className="sticky top-0 bg-white z-10">
										{/* <div className="px-4 py-5 sm:px-6">
											<h3 className="text-xl leading-6 my-0 font-medium text-gray-900"></h3>
										</div> */}
										{/* Tabs */}
										<div>
											<div className="sm:hidden">
												<label htmlFor="tabs" className="sr-only">
													Select a tab
												</label>
												{/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
												<select
													id="tabs"
													name="tabs"
													className="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
													onChange={this.handlePhoneTabs}
													// value={this.state.curr_tab.name}
												>
												{this.state.tabs.map((tab) => (
													<option key={tab.name}>{tab.name}</option>
												))}
												</select>
											</div>
											<div className="hidden sm:block">
												<div className="border-b border-gray-200">
													<div className="-mb-px flex" aria-label="Tabs">
														{checkin_inventory && this.state.tabs.map((tab) => {
															let badge = null
															if (tab.name === "Inventaire d'entrée")
																badge = this.countMissingObj("checkin")
															else if (tab.name === "Inventaire de sortie")
																badge = this.countMissingObj("checkout")
															else if (tab.name === "Photos")
																badge = (!this.state.checkin_inventory.pictures) ? null : this.state.checkin_inventory.pictures.length
															else if (tab.name === "Commentaires")
															{
																if (this.state.checkin_inventory.comment && this.state.checkin_inventory.comment.length > 0)
																	badge = this.state.checkin_inventory.comment.length
															}
															else if (tab.name === "Détails séjour")
																badge = null
															return (
																<button
																	key={tab.name}
																	onClick={this.handleTabs.bind(this, tab)}
																	className={this.classNames(
																		tab.current
																			? 'border-indigo-500 text-indigo-600'
																			: 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
																		'w-1/4 py-4 px-1 text-center border-b-2 font-medium text-sm'
																	)}
																	style={{ backgroundColor: 'transparent'}}
																	aria-current={tab.current ? 'page' : undefined}
																>
																	<span>{tab.name}</span>
																	<span
																		className={this.classNames(
																		badge ? 'bg-purple-100 text-purple-600' : 'hidden',
																		'hidden ml-2 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block'
																		)}
																	>
																		{badge}
																	</span>
																</button>
															)
														})}
													</div>
												</div>
											</div>
										</div>
									</div>
									{modal_content}
								</div>
							</Transition.Child>
						</div>
					</Dialog>				
				</Transition>
				
				{/* SEARCH MODAL */}
				<Transition appear show={this.state.is_search_open} as={Fragment}>
	  				<Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={this.closeSearchModal}>
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
						</Transition.Child>
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<div className="h-full z-10 overflow-y-auto">
								<div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
									<div className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-xl sm:w-full sm:p-6">
										<div className="px-4 py-5 sm:p-6">
											<h3 className="text-lg my-0 leading-6 font-medium text-gray-900">Inventaire</h3>
											<div className="mt-2 max-w-xl text-sm text-gray-500">
												<p>Veuillez indiquer le numéro de séjour</p>
											</div>
											<form className="mt-5 sm:flex sm:items-center" onSubmit={this.submitSearch}>
												<div className="w-full sm:max-w-xs">
													<label htmlFor="stay_number" className="sr-only">
														Numéro de séjour
													</label>
													<input
														type="text"
														name="stay_number"
														id="stay_number"
														className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
														placeholder="Exemple: 2390"
														value={this.state.stay_number}
														onChange={this.handleStayNumber}
													/>
												</div>
												<button
													type="submit"
													className="mt-3 w-full inline-flex items-center justify-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
												>
													Rechercher
												</button>
											</form>
											{error_staynumberJSX}
											{error_inventory_setupJSX}
										</div>
									</div>
								</div>
							</div>
						</Transition.Child>
					</Dialog>
				</Transition>
			{content}
		  </>
		)
	}
}

export default Inventories
import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import connexion from '../../connexion'
import sha1 from 'sha1'
import Loader from '../UI/Loader'
import '../Core/Main.css'
import EmptyFolder from '../UI/EmptyFolder'

class Answers extends Component {
	constructor(props) {
		super(props)
		this.state = {
			questions: null,
			reviews: undefined,
			page: 0
		}
		this.reviews_by_page = 100
		this.props.updateNavbarTitle('Lire les avis')
	}

	componentDidMount() {
		this.loadReviews()
	}

	loadReviews = () => {
		this.props.fetchWebappUsers()
		.then(users => {
			this.props.fetchClients()
			.then(clients => {
				this.props.fetchReviewsByType("global")
				.then(reviews => {
					for (let i = 0; i < reviews.length; i++)
					{
						if (reviews[i].user)
						{
							for (let j = 0; j < users.length; j++)
							{
								if (reviews[i].user === users[j]._id)
								{
									reviews[i].user = users[j]
									break
								}
							}
						}
						else
						{
							for (let j = 0; j < clients.length; j++)
							{
								if (reviews[i].client_number === clients[j].client_number)
								{
									reviews[i].client = clients[j]
									break
								}
							}
						}
					}
					let questions = []
					let inserted = []
					for (let i = 0; i < reviews.length; i++)
					{
						for (let j = 0; j < reviews[i].questions.length; j++)
						{
							const json = JSON.stringify(reviews[i].questions[j])
							const hash = sha1(JSON.stringify(json))
							if (inserted.indexOf(hash) === -1)
							{
								inserted.push(hash)
								if (reviews[i].questions[j].translations)
								{
									for (let k = 0; k < reviews[i].questions[j].translations.length; k++)
									{
										if (reviews[i].questions[j].translations[k].lang === this.props.lang_default)
										{
											reviews[i].questions[j].translations[k].type = reviews[i].questions[j].type
											questions.push(reviews[i].questions[j].translations[k])
											break
										}
									}
								}
							}
						}
					}
					reviews.sort((a, b) => {
						return b.completed - a.completed
					})
					this.setState({ reviews: reviews, questions: questions })
				})
				.catch(err => console.log("could not fetch models", err))
			})
			.catch(err => console.log("could not fetch clients", err))
		})
		.catch(err => console.log("could not fetch users"))
	}

	handleExtractSubmit = (event) => {
		event.preventDefault()
		const reviews = this.state.reviews
		let review_id_list = []
		for (let i = 0; i < reviews.length; i++)
			review_id_list.push(reviews[i]._id)
		this.props.postExtractReviews(review_id_list)
		.then(ret => {
			window.open(connexion.connect.url + 'webapp/reviews/extract_reviews.csv', '_blank').focus()
		})
		.catch(err => console.log("could not extract reviews", err))
	}

	goToPage = (page) => {
		if (page < 0)
			page = 0
		this.setState({ page: page })
	}

	render() {
		let reviews = this.state.reviews
		const permissions = this.props.user.permissions
		if (!permissions || !permissions.reviews || permissions.reviews === 0)
			return (<Redirect to="/" />)
		else if (reviews === undefined)
		{
			return (
				<div className="loader">
					<Loader />
				</div>
			)
		}
		else
		{
			const path = connexion.connect.url + 'webapp/reviews/extract_reviews.csv'
			const review_questions = this.state.questions
			let content = ""
			if (!reviews || !reviews.length)
			{
				content = (
					<EmptyFolder 
						emptyFolderSubtitle="Pas d'avis récoltés pour le moment"
					/>
				)
			}
			else if (reviews && reviews.length)
			{
				let nb_reviews = reviews.length
				let nb_pages = Math.floor(reviews.length / this.reviews_by_page)
				let page = (this.state.page) >= 0 ? this.state.page : 0
				let prev = null
				let next = null
				if (page > 0)
				{
					prev = (
						<button onClick={this.goToPage.bind(this, page - 1)} class="bg-gray-800 text-white px-3 py-1 rounded-l-md hover:bg-gray-700">
							<svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
							<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"></path>
							</svg>
						</button>
					)
				}
				if (page < nb_pages)
				{
					next = (
						<button onClick={this.goToPage.bind(this, page + 1)} class="bg-gray-800 text-white px-3 py-1 rounded-r-md hover:bg-gray-700">
							<svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
							<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path>
							</svg>
						</button>
					)
				}
				let page_buttons = []
				let i = page - 2
				if (i < 0)
					i = 0
				while (i < page)
				{
					page_buttons.push(
						<button onClick={this.goToPage.bind(this, i)} id={"page-" + i} class="bg-gray-800 text-white px-3 py-1 hover:bg-gray-700">{i + 1}</button>
					)
					i++
				}
				page_buttons.push(
					<button onClick={this.goToPage.bind(this, page)} id={"page-" + page} class="bg-blue-500 text-white px-3 py-1 hover:bg-gray-700">{page + 1}</button>
				)
				for (let i = page + 1; i < page + 3 && i <= nb_pages; i++)
				{
					page_buttons.push(
						<button onClick={this.goToPage.bind(this, i)} id={"page-" + i} class="bg-gray-800 text-white px-3 py-1 hover:bg-gray-700">{i + 1}</button>
					)
				}
				let module_pagination = (
					<div id="pagination-controls" class="mt-6 mb-6 flex justify-center space-x-1">
						{prev}
						{page_buttons.map((button) => {
							return button
						})}
						{next}
					</div>
				)
				let trim_begin = page * this.reviews_by_page
				reviews = reviews.slice(trim_begin, trim_begin + this.reviews_by_page)
				if (reviews.length > this.reviews_by_page)
					reviews = reviews.slice(0, this.reviews_by_page)
				content = (
					<div className="module col full">
						{/* <form onSubmit={this.handleExtractSubmit}>
							<button type="submit" className="waves-effect waves-light btn">Extraire</button>
						</form> */}
						<a href={path} className="waves-effect waves-light btn">Extraire</a>
						{/* Pagination Controls */}
						<p className="text-center">Nb d'avis : {nb_reviews}</p>
						<p className="text-center">Nb de pages : {nb_pages + 1}</p>
						{module_pagination}
						{/* Table */}
						<table class="min-w-full bg-white border border-gray-200">
							<thead>
								<tr class="bg-gray-800 text-white uppercase text-sm leading-normal">
									<th class="py-3 px-6 text-left">Nom</th>
									<th class="py-3 px-6 text-left">Date</th>
									<th class="py-3 px-6 text-left">E-mail</th>
									<th class="py-3 px-6 text-left">Questions et réponses</th>
								</tr>
							</thead>
							<tbody class="text-gray-700 text-sm">
								{reviews.map(({ _id, completed, questions, answers, user, client }) => {
									const time = new Date(completed * 1000)
									let day = time.getDate()
									if (day < 10)
										day = '0' + day
									let month = time.getMonth() + 1
									if (month < 10)
										month = '0' + month
									const time_txt = day + '/' + month + '/' + time.getFullYear()
									let ans = Array(questions.length).fill(undefined)
									let email = undefined
									let name = undefined
									let subscribed = undefined
									if (user)
									{
										email = user.email
										name = user.firstname + " " + user.lastname
										subscribed = "Oui"
									}
									else if (client)
									{
										email = (client.email1) ? client.email1 : client.email2
										name = client.firstname + " " + client.lastname
										subscribed = "Non"
									}
									for (let i = 0; i < review_questions.length; i++)
									{
										for (let j = 0; j < questions.length; j++)
										{
											const type = questions[j].type
											let title = null
											if (questions[j].translations)
											{
												for (let k = 0; k < questions[j].translations.length; k++)
												{
													if (questions[j].translations[k].lang === this.props.lang_default)
													{
														title = questions[j].translations[k].title
														break
													}
												}
											}
											else
												title = questions[j].title
											if (title === review_questions[i].title)
											{
												if (type === "notation")
													ans[i] = answers[j] + "/5"
												else
													ans[i] = answers[j]
												break
											}
										}
									}
									console.log("questions", questions)
									let questionsJSX = (
										<ul class="space-y-2">
											{review_questions.map((question, index) => {
												return (
													<>
														<li key={index}>
															<strong>Question {index + 1} :</strong> {question.title}
															<br />
															<em>Réponse :</em> {ans[index]}
														</li>
													</>
												)
											})}
										</ul>
									)
									return (
										<tr key={_id} class="border-b border-gray-200 hover:bg-gray-100">
											<td class="py-3 px-6 text-left">{name}</td>
											<td class="py-3 px-6 text-left">{time_txt}</td>
											<td class="py-3 px-6 text-left">{email}</td>
											<td class="py-3 px-6 text-left">{questionsJSX}</td>
										</tr>
									)
								})}
							</tbody>
						</table>
						{/* Pagination Controls */}
						{module_pagination}
						<p className="text-center">Nb d'avis : {nb_reviews}</p>
						<p className="text-center">Nb de pages : {nb_pages + 1}</p>
					</div>
				)
				// content = (
				// 	<div className="module col full">
				// 		<p>Nombre d'avis : {reviews.length}</p>
				// 		{/* <form onSubmit={this.handleExtractSubmit}>
				// 			<button type="submit" className="waves-effect waves-light btn">Extraire</button>
				// 		</form> */}
				// 		<a href={path} className="waves-effect waves-light btn">Extraire</a>
				// 		<table className="reviews striped responsive-table">
				// 			<thead>
				// 				<tr>
				// 					<td style={{ minWidth: '50px' }}>Date</td>
				// 					<td>Client</td>
				// 					<td>E-mail</td>
				// 					<td>Utilisateur inscrit</td>
				// 					{review_questions.map(({ title, type, average }) => {
				// 						if (type === "notation")
				// 							title += ' (' + average.toPrecision(2) + ')'
				// 						return (
				// 							<td key={title}>{title}</td>
				// 						)
				// 					})}
				// 				</tr>
				// 			</thead>
				// 			<tbody>
				// 				{reviews.map(({ _id, completed, questions, answers, user, client }) => {
				// 					const time = new Date(completed * 1000)
				// 					let day = time.getDate()
				// 					if (day < 10)
				// 						day = '0' + day
				// 					let month = time.getMonth() + 1
				// 					if (month < 10)
				// 						month = '0' + month
				// 					const time_txt = day + '/' + month + '/' + time.getFullYear()
				// 					let ans = Array(questions.length).fill(undefined)
				// 					let email = undefined
				// 					let name = undefined
				// 					let subscribed = undefined
				// 					if (user)
				// 					{
				// 						email = user.email
				// 						name = user.firstname + " " + user.lastname
				// 						subscribed = "Oui"
				// 					}
				// 					else if (client)
				// 					{
				// 						email = (client.email1) ? client.email1 : client.email2
				// 						name = client.firstname + " " + client.lastname
				// 						subscribed = "Non"
				// 					}
				// 					for (let i = 0; i < review_questions.length; i++)
				// 					{
				// 						for (let j = 0; j < questions.length; j++)
				// 						{
				// 							const type = questions[j].type
				// 							let title = null
				// 							if (questions[j].translations)
				// 							{
				// 								for (let k = 0; k < questions[j].translations.length; k++)
				// 								{
				// 									if (questions[j].translations[k].lang === this.props.lang_default)
				// 									{
				// 										title = questions[j].translations[k].title
				// 										break
				// 									}
				// 								}
				// 							}
				// 							else
				// 								title = questions[j].title
				// 							if (title === review_questions[i].title)
				// 							{
				// 								if (type === "notation")
				// 									ans[i] = answers[j] + "/5"
				// 								else
				// 									ans[i] = answers[j]
				// 								break
				// 							}
				// 						}
				// 					}
				// 					let ans_k = -1
				// 					return (
				// 						<tr key={_id}>
				// 							<td>{time_txt}</td>
				// 							<td>{name}</td>
				// 							<td>{email}</td>
				// 							<td>{subscribed}</td>
				// 							{ans.map((value) => {
				// 								ans_k++
				// 								return (<td key={ans_k}>{value}</td>)
				// 							})}
				// 						</tr>
				// 					)
				// 				})}
				// 			</tbody>
				// 		</table>
				// 	</div>
				// )
			}
			return (
				<div className="wrapper-content">
					<div className="row">
						{content}
					</div>
				</div>
			)
		}
	}
}

export default Answers

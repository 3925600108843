import React, { Component } from 'react'
import connexion from '../../connexion'
import { parseDate } from '../../tools'
import Loader from '../UI/Loader'
import '../Core/Main.css'
import tools from '../../tools'

class BackUsers extends Component {
	constructor(props) {
		super(props)
		this.search = (this.props.location.users !== undefined) ? true : false
		this.state = {
			user: null,
			id: (this.props.match.params.id !== undefined) ? this.props.match.params.id : null,
			users: (this.props.location.users !== undefined) ? this.props.location.users : null,
			error: null,
			success: null
		}
		this.props.updateNavbarTitle('Utilisateurs (dashboard)')
	}

	componentDidMount() {
		if (this.state.users === null)
			this.getUserList()
		this.getUser(this.state.id)
	}

	getUserList = () => {
		fetch(connexion.connect.url + 'dashboard/users/list')
			.then(res => res.json())
			.then(users => {
				this.setState({ users: users })
			})
			.catch(err => console.log(err))
	}

	getUser = (id) => {
		if (id !== null)
		{
			const requrl = tools.queryString(connexion.connect.url + 'dashboard/users/find', {
				id: id
			})
			fetch(requrl)
			.then(res => res.json())
			.then(user => {
				this.setState({ user: user })
			})
			.catch(err => console.log(err))
		}
	}

	setSuccess = (msg) => {
		this.setState({ success: msg, error: null })
	}

	setError = (msg) => {
		this.setState({ error: msg, success: null })
	}

	resetFlash = () => {
		this.setState({ error: null, success: null })
	}

	handleLink = (id) => {
		this.getUser(id)
		this.resetFlash()
		this.setState({ id: id })
	}

	render() {
		const id = this.state.id
		const user = this.state.user
		const users = this.state.users
		if ((id !== null && user === null) || (id === null && users === null))
		{
			return (
				<div className="loader">
					<Loader />
				</div>
			)
		}
		else if (id !== null)
		{
			const avatar = (user.avatar) ? (<span><br /><b>Avatar :</b><br /><img src={user.avatar} alt="avatar" className="avatar" /></span>) : null
			const create = parseDate(user.created_time)
			const edited = parseDate(user.edited_time)
			const editedTxt = (user.edited_time) ? (<span><br /><b>Édition :</b> {edited}</span>) : null
			return (
				<div className="wrapper-content">
					<p className="error center">{this.state.error}</p>
					<p className="success center">{this.state.success}</p>
					<p><button type="button" className="waves-effect waves-light btn-small" onClick={this.handleLink.bind(this, null)}>Retour à la liste</button></p>
					<div className="row">
						<div className="module col s4">
							<h2 className="title-description">Détails</h2>
							<p>
								<b>Nom :</b> {user.lastname}<br />
								<b>Prénom :</b> {user.firstname}<br />
								<b>Adresse e-mail :</b> {user.email}<br />
								<b>Identifiant :</b> {user.username}<br />
								<b>Création :</b> {create}
								{editedTxt}
								{avatar}
							</p>
						</div>
					</div>
				</div>
			)
		}
		else
		{
			return (
				<div className="wrapper-content">
					<div className="row">
						<div className="module col s12">
							<h2 className="title-description">Liste de tous les utilisateurs</h2>
							<p>Nombre de lignes : {users.length}</p>
							<table>
								<thead>
									<tr>
										<td>Détails</td>
										<td>Nom</td>
										<td>Prénom</td>
										<td>E-mail</td>
										<td>Identifiant</td>
										<td>Avatar</td>
										<td>Création</td>
										<td>Édition</td>
									</tr>
								</thead>
								<tbody>
									{users.map(({ _id, email, firstname, lastname, username, avatar, created_time, edited_time }) => {
										const image = (avatar) ? (<img src={avatar} className="avatar" alt="avatar" />) : null
										const create = parseDate(created_time)
										const edited = parseDate(edited_time)
										return (
											<tr key={_id}>
												<td><button type="button" className="waves-effect waves-light btn-small" onClick={this.handleLink.bind(this, _id)}>Voir la fiche</button></td>
												<td>{ lastname }</td>
												<td>{ firstname }</td>
												<td>{ email }</td>
												<td>{ username }</td>
												<td>{ image }</td>
												<td>{ create }</td>
												<td>{ edited }</td>
											</tr>
										)
									})}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			)
		}
	}
}

export default BackUsers
